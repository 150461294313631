.LayoutsHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #FFFFFF;
}
@media (max-width: 759px) {
  .LayoutsHeader {
    margin-top: 12.5vw;
    display: block;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHeader {
    width: 100%;
    height: 51.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHeader {
    width: 100%;
    height: 44.44444444vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHeader {
    width: 100%;
    height: 853.33333333px;
  }
}
.LayoutsHeader-wrapper {
  position: relative;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHeader-wrapper {
    width: 100%;
    height: 51.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHeader-wrapper {
    width: 100%;
    height: 44.44444444vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHeader-wrapper {
    width: 100%;
    height: 853.33333333px;
  }
}
.LayoutsHeader-image {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .LayoutsHeader-image {
    height: 100vw;
  }
}
.LayoutsHeader-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: auto;
  min-width: 100%;
}
.LayoutsHeader-imageShadow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, rgba(255, 255, 255, 0)), to(rgba(0, 0, 0, 0.5)));
  background: linear-gradient(rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.5));
}
@media (max-width: 759px) {
  .LayoutsHeader-imageShadow {
    display: none;
  }
}
.LayoutsHeader-titleWrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}
@media (max-width: 759px) {
  .LayoutsHeader-titleWrapper {
    width: 87.5vw;
    bottom: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHeader-titleWrapper {
    width: 61.29032258vw;
    bottom: 7.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHeader-titleWrapper {
    width: 52.77777778vw;
    bottom: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHeader-titleWrapper {
    width: 1013.33333333px;
    bottom: 120px;
  }
}
.LayoutsHeader-office {
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .LayoutsHeader-office {
    color: #FFFFFF;
    text-transform: inherit;
    padding-top: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHeader-office {
    padding-bottom: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHeader-office {
    padding-bottom: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHeader-office {
    padding-bottom: 13.33333333px;
  }
}
.LayoutsHeader-title {
  color: #FFFFFF;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .LayoutsHeader-title {
    text-transform: inherit;
    border-top: unset;
    padding-top: unset;
    padding-bottom: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHeader-title {
    padding-top: 1.61290323vw;
    padding-bottom: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHeader-title {
    padding-top: 1.38888889vw;
    padding-bottom: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHeader-title {
    padding-top: 26.66666667px;
    padding-bottom: 13.33333333px;
  }
}
.LayoutsHeader-description {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .LayoutsHeader-description {
    letter-spacing: -0.28px;
  }
}
