@media (min-width: 1920px) {
  .CurrentSubscriptions {
    padding-top:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions {
    padding-top:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions {
    padding-top:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions {
    padding-top:  5.3125vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .CurrentSubscriptions-title {
    font-size:  24px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-title {
    font-size:  1.25vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-title {
    font-size:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .CurrentSubscriptions-title {
    font-size:  5.625vw;
  }
}
.CurrentSubscriptions-description {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .CurrentSubscriptions-description {
    margin-top:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-description {
    margin-top:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-description {
    margin-top:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-description {
    margin-top:  5vw;
  }
}
@media (min-width: 1920px) {
  .CurrentSubscriptions-subscriptions {
    margin-top:  57.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-subscriptions {
    margin-top:  2.986111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-subscriptions {
    margin-top:  3.467741935483871vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscriptions {
    margin-top:  13.4375vw;
  }
}
.CurrentSubscriptions-subscription {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #E7E7E7;
}
@media (min-width: 1920px) {
  .CurrentSubscriptions-subscription {
    padding-bottom:  46.666666666666664px;
    margin-top:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-subscription {
    padding-bottom:  2.430555555555556vw;
    margin-top:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-subscription {
    padding-bottom:  2.82258064516129vw;
    margin-top:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscription {
    padding-bottom:  10.9375vw;
    margin-top:  8.125vw;
  }
}
.CurrentSubscriptions-subscription:first-child {
  margin-top: 0;
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscription {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.CurrentSubscriptions-subscriptionInfo {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 52.6%;
          flex: 0 0 52.6%;
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscriptionInfo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .CurrentSubscriptions-subscriptionInfo {
    margin-bottom:  26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-subscriptionInfo {
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-subscriptionInfo {
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .CurrentSubscriptions-subscriptionInfo {
    margin-bottom:  6.25vw;
  }
}
.CurrentSubscriptions-subscriptionDescription {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .CurrentSubscriptions-subscriptionDescription {
    font-size:  14.666666666666666px;
    margin-top:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-subscriptionDescription {
    font-size:  0.763888888888889vw;
    margin-top:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-subscriptionDescription {
    font-size:  0.8870967741935484vw;
    margin-top:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscriptionDescription {
    font-size:  3.4375vw;
    margin-top:  4.6875vw;
  }
}
.CurrentSubscriptions-subscriptionLanguage {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 21%;
          flex: 0 0 21%;
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscriptionLanguage {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 42.8%;
            flex: 0 0 42.8%;
  }
}
.CurrentSubscriptions-subscriptionLanguageTitle {
  text-transform: uppercase;
  color: #6F7380;
}
@media (min-width: 1920px) {
  .CurrentSubscriptions-subscriptionLanguageTitle {
    margin-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-subscriptionLanguageTitle {
    margin-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-subscriptionLanguageTitle {
    margin-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscriptionLanguageTitle {
    margin-bottom:  3.125vw;
  }
}
.CurrentSubscriptions-subscriptionTriggerTitle {
  display: none;
}
@media (max-width: 759px) {
  .CurrentSubscriptions-subscriptionTriggerTitle {
    display: block;
    text-transform: uppercase;
    color: #6F7380;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .CurrentSubscriptions-subscriptionTriggerTitle {
    margin-bottom:  13.333333333333332px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-subscriptionTriggerTitle {
    margin-bottom:  0.6944444444444444vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-subscriptionTriggerTitle {
    margin-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .CurrentSubscriptions-subscriptionTriggerTitle {
    margin-bottom:  3.125vw;
  }
}
.CurrentSubscriptions-saveWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .CurrentSubscriptions-saveWrapper {
    margin-top:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-saveWrapper {
    margin-top:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-saveWrapper {
    margin-top:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-saveWrapper {
    margin-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .CurrentSubscriptions-saveWrapper {
    display: block;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .CurrentSubscriptions-cancel {
    width:  346.66666666666663px;
    margin-top:  45.33333333333333px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .CurrentSubscriptions-cancel {
    width:  18.055555555555557vw;
    margin-top:  2.361111111111111vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .CurrentSubscriptions-cancel {
    width:  20.967741935483872vw;
    margin-top:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .CurrentSubscriptions-cancel {
    width:  81.25vw;
    margin-top:  10.625vw;
  }
}
