.ProductsFilter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
.ProductsFilter-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductsFilter-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.ProductsFilter-title {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #111112;
  width: 50%;
}
@media (min-width: 1920px) {
  .ProductsFilter-title {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-title {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-title {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-title {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .ProductsFilter-title {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.brand--thorn .ProductsFilter-title {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductsFilter-title {
    padding-left:  106.66666666666666px;
    padding-top:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-title {
    padding-left:  5.555555555555555vw;
    padding-top:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-title {
    padding-left:  6.451612903225806vw;
    padding-top:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-title {
    padding-left:  25vw;
    padding-top:  4.6875vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-title {
    padding-left: 186.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-title {
    padding: 0 6.25vw 0;
    width: 100%;
  }
}
.ProductsFilter-filter {
  position: relative;
  width: 50%;
}
.ProductsFilter-filterWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterWrapper {
    width:  746.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterWrapper {
    width:  38.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterWrapper {
    width:  37.90322580645161vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterWrapper {
    width:  175vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filter {
    width: 100%;
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filter {
    padding-left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filter {
    padding-left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filter {
    padding-left: 26.66666667px;
  }
}
.ProductsFilter-filterMobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductsFilter-filterMobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0 6.25vw;
    margin-top: 6.25vw;
  }
}
.ProductsFilter-filterItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsFilter-filterItem:nth-child(3n + 1) {
  margin-left: 0;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItem {
    width:  213.33333333333331px;
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItem {
    width:  11.11111111111111vw;
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItem {
    width:  10.483870967741936vw;
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterItem {
    width:  50vw;
    margin-left:  12.5vw;
  }
}
.ProductsFilter-filterItemTitle {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemTitle {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemTitle {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemTitle {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemTitle {
    font-size: 16px;
  }
}
.ProductsFilter-filterItemSelect {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  width: 100%;
}
.ProductsFilter-filterItemSelectText {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemSelectText {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemSelectText {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemSelectText {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemSelectText {
    font-size: 16px;
  }
}
.brand--thorn .ProductsFilter-filterItemSelectText {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemSelectText {
    padding-bottom: 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemSelectText {
    padding-bottom: 0.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemSelectText {
    padding-bottom: 0.13888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemSelectText {
    padding-bottom: 2.66666667px;
  }
}
.filterItemTwoLine .ProductsFilter-filterItemSelectText {
  line-height: 1.25;
}
@media (max-width: 759px) {
  .filterItemTwoLine .ProductsFilter-filterItemSelectText {
    padding-bottom: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .filterItemTwoLine .ProductsFilter-filterItemSelectText {
    padding-bottom: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .filterItemTwoLine .ProductsFilter-filterItemSelectText {
    padding-bottom: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .filterItemTwoLine .ProductsFilter-filterItemSelectText {
    padding-bottom: 6.66666667px;
  }
}
.filterItemTwoLine .ProductsFilter-filterItemSelectText p {
  width: 88%;
  text-overflow: unset;
  overflow: hidden;
  white-space: pre-wrap;
}
.isDesktop .ProductsFilter-filterItemSelectText:hover:after,
.isNotDesktop .ProductsFilter-filterItemSelectText:active:after {
  width: 0;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.isDesktop .ProductsFilter-filterItemSelectText:hover:before,
.isNotDesktop .ProductsFilter-filterItemSelectText:active:before {
  width: 100%;
  -webkit-transition: 0.15s width 0.12s ease-out;
  transition: 0.15s width 0.12s ease-out;
}
.ProductsFilter-filterItemSelectText:after {
  content: '';
  background: #111112;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.brand--thorn .ProductsFilter-filterItemSelectText:after {
  background: #FFFFFF;
}
.ProductsFilter-filterItemSelectText:before {
  content: '';
  background: #111112;
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 0;
  left: 0;
}
.brand--thorn .ProductsFilter-filterItemSelectText:before {
  background: #FFFFFF;
}
.ProductsFilter-filterItemSelectText > p {
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ProductsFilter-filterItemSelectText span {
  position: absolute;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemSelectText span {
    right: 1.875vw;
    border-left: 1.25vw solid transparent;
    border-right: 1.25vw solid transparent;
    border-top: 1.5625vw solid #111112;
    top: 4.375vw;
  }
  .filterItemTwoLine .ProductsFilter-filterItemSelectText span {
    top: 3.75vw;
  }
  .brand--thorn .ProductsFilter-filterItemSelectText span {
    border-top: 1.5625vw solid #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemSelectText span {
    right: 0.48387097vw;
    border-left: 0.32258065vw solid transparent;
    border-right: 0.32258065vw solid transparent;
    border-top: 0.40322581vw solid #111112;
    top: 1.12903226vw;
  }
  .filterItemTwoLine .ProductsFilter-filterItemSelectText span {
    top: 0.96774194vw;
  }
  .brand--thorn .ProductsFilter-filterItemSelectText span {
    border-top: 0.40322581vw solid #FFFFFF;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemSelectText span {
    right: 0.41666667vw;
    border-left: 0.27777778vw solid transparent;
    border-right: 0.27777778vw solid transparent;
    border-top: 0.34722222vw solid #111112;
    top: 0.97222222vw;
  }
  .filterItemTwoLine .ProductsFilter-filterItemSelectText span {
    top: 0.83333333vw;
  }
  .brand--thorn .ProductsFilter-filterItemSelectText span {
    border-top: 0.34722222vw solid #FFFFFF;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemSelectText span {
    right: 8px;
    border-left: 5.33333333px solid transparent;
    border-right: 5.33333333px solid transparent;
    border-top: 6.66666667px solid #111112;
    top: 18.66666667px;
  }
  .filterItemTwoLine .ProductsFilter-filterItemSelectText span {
    top: 16px;
  }
  .brand--thorn .ProductsFilter-filterItemSelectText span {
    border-top: 6.66666667px solid #FFFFFF;
  }
}
.active .ProductsFilter-filterItemSelectText span {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ProductsFilter-filterItemSelectMenu {
  position: absolute;
  top: calc(100% - 1px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: #111112;
  cursor: default;
  z-index: 30;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
}
.brand--thorn .ProductsFilter-filterItemSelectMenu {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemSelectMenu {
    left: -6.25vw;
    right: -6.25vw;
    -webkit-transform: translateY(-6.25vw);
        -ms-transform: translateY(-6.25vw);
            transform: translateY(-6.25vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemSelectMenu {
    left: -1.61290323vw;
    right: -1.61290323vw;
    -webkit-transform: translateY(-1.61290323vw);
        -ms-transform: translateY(-1.61290323vw);
            transform: translateY(-1.61290323vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemSelectMenu {
    left: -1.38888889vw;
    right: -1.38888889vw;
    -webkit-transform: translateY(-1.38888889vw);
        -ms-transform: translateY(-1.38888889vw);
            transform: translateY(-1.38888889vw);
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemSelectMenu {
    left: -26.66666667px;
    right: -26.66666667px;
    -webkit-transform: translateY(-26.66666667px);
        -ms-transform: translateY(-26.66666667px);
            transform: translateY(-26.66666667px);
  }
}
.ProductsFilter-filterItemSelect.active .ProductsFilter-filterItemSelectMenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
}
.ProductsFilter-filterItemSelectMenuVal {
  text-decoration: none;
  color: inherit;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 2.85714286;
  -webkit-transition: 0.15s background-color ease-out;
  transition: 0.15s background-color ease-out;
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemSelectMenuVal {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemSelectMenuVal {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemSelectMenuVal {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemSelectMenuVal {
    font-size: 16px;
  }
}
.ProductsFilter-filterItemSelectMenuVal:first-child {
  border-top: 0;
}
.brand--thorn .ProductsFilter-filterItemSelectMenuVal {
  color: #111112;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}
@media (max-width: 759px) {
  .ProductsFilter-filterItemSelectMenuVal {
    line-height: 3.63636364;
    padding: 1.25vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterItemSelectMenuVal {
    padding: 0.32258065vw 1.61290323vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterItemSelectMenuVal {
    padding: 0.27777778vw 1.38888889vw 0;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterItemSelectMenuVal {
    padding: 5.33333333px 26.66666667px 0;
  }
}
.isDesktop .ProductsFilter-filterItemSelectMenuVal:hover,
.isNotDesktop .ProductsFilter-filterItemSelectMenuVal:active {
  background: #38383B;
}
.brand--thorn.isDesktop .ProductsFilter-filterItemSelectMenuVal:hover,
.brand--thorn.isNotDesktop .ProductsFilter-filterItemSelectMenuVal:active {
  background: rgba(0, 0, 0, 0.3);
}
.ProductsFilter-filterItemSelectMenuVal--active {
  cursor: default;
  pointer-events: none;
  color: #6F7380;
}
.brand--thorn .ProductsFilter-filterItemSelectMenuVal--active {
  color: #6F7380;
}
.isDesktop .ProductsFilter-filterItemSelectMenuVal--active:hover,
.isNotDesktop .ProductsFilter-filterItemSelectMenuVal--active:active {
  background: #111112;
}
.ProductsFilter-filterClear {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #111112;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media (max-width: 759px) {
  .ProductsFilter-filterClear {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterClear {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterClear {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterClear {
    font-size: 16px;
  }
}
.brand--thorn .ProductsFilter-filterClear {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterClear {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterClear {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterClear {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterClear {
    margin-top:  9.375vw;
  }
}
.isDesktop .ProductsFilter-filterClear:hover,
.isNotDesktop .ProductsFilter-filterClear:active {
  text-decoration: underline;
}
.ProductsFilter-filterClear--hide {
  display: none;
}
.ProductsFilter-filterHideItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.ProductsFilter-filterHideItems.isHidden {
  display: none;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterHideItems {
    width:  746.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterHideItems {
    width:  38.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterHideItems {
    width:  45.16129032258064vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterHideItems {
    width:  175vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterHideItems {
    display: none;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterHideItems .ProductsFilter-filterItem {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterHideItems .ProductsFilter-filterItem {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterHideItems .ProductsFilter-filterItem {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterHideItems .ProductsFilter-filterItem {
    margin-top:  9.375vw;
  }
}
.isHidden .ProductsFilter-filterHideItems .ProductsFilter-filterItem {
  display: none;
}
.ProductsFilter-filterMore {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 759px) {
  .ProductsFilter-filterMore {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterMore {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterMore {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsFilter-filterMore {
    font-size: 16px;
  }
}
.brand--thorn .ProductsFilter-filterMore {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterMore {
    left:  800px;
    top:  50.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterMore {
    left:  41.66666666666667vw;
    top:  2.638888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterMore {
    left:  41.12903225806451vw;
    top:  2.9838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterMore {
    left:  187.5vw;
    top:  11.875vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterMore {
    display: none;
  }
}
.ProductsFilter-filterMore.isOpenFilters .ProductsFilter-filterMoreIconItem:first-child {
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transition: 0.15s width, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s opacity, 0.15s transform;
  transition: 0.15s width, 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.isDesktop .ProductsFilter-filterMore:hover,
.isNotDesktop .ProductsFilter-filterMore:active {
  text-decoration: underline;
}
@media (min-width: 1920px) {
  .isDesktop .ProductsFilter-filterMore:hover .ProductsFilter-filterMoreIconItem,
  .isNotDesktop .ProductsFilter-filterMore:active .ProductsFilter-filterMoreIconItem {
    width:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ProductsFilter-filterMore:hover .ProductsFilter-filterMoreIconItem,
  .isNotDesktop .ProductsFilter-filterMore:active .ProductsFilter-filterMoreIconItem {
    width:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ProductsFilter-filterMore:hover .ProductsFilter-filterMoreIconItem,
  .isNotDesktop .ProductsFilter-filterMore:active .ProductsFilter-filterMoreIconItem {
    width:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .ProductsFilter-filterMore:hover .ProductsFilter-filterMoreIconItem,
  .isNotDesktop .ProductsFilter-filterMore:active .ProductsFilter-filterMoreIconItem {
    width:  6.25vw;
  }
}
.ProductsFilter-filterMore span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterMore span {
    padding-top:  5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterMore span {
    padding-top:  0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterMore span {
    padding-top:  0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterMore span {
    padding-top:  1.25vw;
  }
}
.ProductsFilter-filterMoreIcon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterMoreIcon {
    width:  26.666666666666664px;
    height:  26.666666666666664px;
    margin-right:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterMoreIcon {
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
    margin-right:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterMoreIcon {
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
    margin-right:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterMoreIcon {
    width:  6.25vw;
    height:  6.25vw;
    margin-right:  2.5vw;
  }
}
.ProductsFilter-filterMoreIconItem {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  top: 50%;
  height: 2px;
  background: #111112;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transition: 0.15s width, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s opacity, 0.15s transform;
  transition: 0.15s width, 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.brand--thorn .ProductsFilter-filterMoreIconItem {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductsFilter-filterMoreIconItem {
    width:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsFilter-filterMoreIconItem {
    width:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsFilter-filterMoreIconItem {
    width:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .ProductsFilter-filterMoreIconItem {
    width:  5vw;
  }
}
.ProductsFilter-filterMoreIconItem:first-child {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(90deg);
          transform: translate3d(-50%, -50%, 0) rotate(90deg);
  -webkit-transition: 0.15s width, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s opacity, 0.15s transform;
  transition: 0.15s width, 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
