.CommonBlock-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .CommonBlock-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.CommonBlock-blockContent {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
@media (min-width: 1920px) {
  .CommonBlock-blockContent {
    margin-top:  -6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonBlock-blockContent {
    margin-top:  -0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonBlock-blockContent {
    margin-top:  -0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CommonBlock-blockContent {
    margin-top:  -1.5625vw;
  }
}
@media (max-width: 759px) {
  .CommonBlock-blockContent {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin-top: 0;
  }
}
@media (min-width: 1920px) {
  .CommonBlock-blockContentImage {
    margin-top:  40px;
    margin-bottom:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonBlock-blockContentImage {
    margin-top:  2.0833333333333335vw;
    margin-bottom:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonBlock-blockContentImage {
    margin-top:  2.4193548387096775vw;
    margin-bottom:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CommonBlock-blockContentImage {
    margin-top:  9.375vw;
    margin-bottom:  9.375vw;
  }
}
.CommonBlock-blockContentImage:first-child {
  margin-top: 0;
}
.CommonBlock-blockContentImage:last-child {
  margin-bottom: 0;
}
.CommonBlock-downloads {
  border-top: 1px solid #C6C6C6;
}
@media (min-width: 1920px) {
  .CommonBlock-downloads {
    padding-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonBlock-downloads {
    padding-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonBlock-downloads {
    padding-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CommonBlock-downloads {
    padding-top:  9.375vw;
  }
}
.CommonBlock-downloadsTitle {
  color: #111112;
}
.CommonBlock-downloadsContent {
  width: 100%;
}
@media (min-width: 1920px) {
  .CommonBlock-downloadsContent {
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonBlock-downloadsContent {
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonBlock-downloadsContent {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CommonBlock-downloadsContent {
    margin-top:  15.625vw;
  }
}
.CommonBlock-downloads .DownloadLinks-downloads {
  width: 100%;
}
