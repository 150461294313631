.LinkList {
  position: relative;
}
@media (min-width: 1920px) {
  .LinkList {
    padding:  0 186.66666666666666px;
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkList {
    padding:  0vw 9.722222222222223vw;
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkList {
    padding:  0vw 11.29032258064516vw;
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .LinkList {
    padding:  0vw 6.25vw;
    margin-top:  12.5vw;
  }
}
.LinkList-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  border-top: 1px solid #DEDEDE;
}
.LinkList-link:last-of-type {
  border-bottom: 1px solid #DEDEDE;
}
.isDesktop .LinkList-link:hover .LinkList-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (min-width: 1920px) {
  .LinkList-link {
    padding:  26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkList-link {
    padding:  1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkList-link {
    padding:  1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .LinkList-link {
    padding:  3.125vw 0vw;
  }
}
.LinkList-linkUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.LinkList-linkArrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  overflow: hidden;
}
@media (min-width: 1920px) {
  .LinkList-linkArrow {
    width:  44px;
    height:  21.333333333333332px;
    right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkList-linkArrow {
    width:  2.291666666666667vw;
    height:  1.1111111111111112vw;
    right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkList-linkArrow {
    width:  2.661290322580645vw;
    height:  1.2903225806451613vw;
    right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .LinkList-linkArrow {
    width:  8.75vw;
    height:  4.375vw;
    right:  0vw;
  }
}
.LinkList-linkArrow svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-15%, 0, 0);
          transform: translate3d(-15%, 0, 0);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
