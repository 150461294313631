.ProductFamilySection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
.ProductFamilySection.hide {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilySection {
    margin-top: 12.5vw;
    padding: 0 6.25vw;
    border-top: 1px solid #111112;
    padding-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection {
    margin-top: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection {
    margin-top: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection {
    margin-top: 133.33333333px;
  }
}
.ProductFamilySection:first-child {
  margin-top: 0;
}
@media (max-width: 759px) {
  .ProductFamilySection:first-child {
    border-top: 0;
    padding-top: 0;
  }
}
.ProductFamilySection-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .ProductFamilySection-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.ProductFamilySection-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
}
@media (min-width: 1920px) {
  .ProductFamilySection-image {
    top:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-image {
    top:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-image {
    top:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilySection-image {
    top:  43.75vw;
  }
}
@media (min-width: 1920px) {
  .isOpenFilter .ProductFamilySection-image {
    top:  397.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isOpenFilter .ProductFamilySection-image {
    top:  20.694444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isOpenFilter .ProductFamilySection-image {
    top:  24.032258064516128vw;
  }
}
@media (max-width: 759px) {
  .isOpenFilter .ProductFamilySection-image {
    top:  93.125vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilySection-image {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.ProductFamilySection-image:after {
  content: '';
  padding-top: 100%;
  display: block;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilySection-image {
    width: 37.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-image {
    width: 12.90322581vw;
    margin-right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-image {
    width: 11.11111111vw;
    margin-right: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection-image {
    width: 213.33333333px;
    margin-right: 53.33333333px;
  }
}
.ProductFamilySection-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.ProductFamilySection-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .ProductFamilySection-info {
    width: 100%;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-info {
    width: 61.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-info {
    width: 52.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection-info {
    width: 1013.33333333px;
  }
}
.ProductFamilySection-infoHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .ProductFamilySection-infoHeader {
    height: 37.5vw;
  }
}
.ProductFamilySection-infoTitle {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductFamilySection-infoTitle {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-infoTitle {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-infoTitle {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection-infoTitle {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilySection-infoTitle {
    width: 50vw;
    font-size: 3.4375vw;
    letter-spacing: -0.04272727em;
    line-height: 1.36363636;
    margin-top: 2.1875vw;
  }
}
.ProductFamilySection-infoDescr {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilySection-infoDescr {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-infoDescr {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-infoDescr {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection-infoDescr {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductFamilySection-infoDescr {
    margin-top: 4.6875vw;
    width: 34.375vw;
    line-height: 1.66666667;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-infoDescr {
    margin-top: 2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-infoDescr {
    margin-top: 1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection-infoDescr {
    margin-top: 33.33333333px;
  }
}
.ProductFamilySection-infoItems {
  position: relative;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilySection-infoItems {
    margin-top: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySection-infoItems {
    margin-top: 1.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySection-infoItems {
    margin-top: 1.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySection-infoItems {
    margin-top: 24px;
  }
}
