.PressLandingHead {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 96vh;
}
@media (min-width: 1920px) {
  .PressLandingHead {
    padding:  0 186.66666666666666px 93.33333333333333px;
    margin-bottom:  -133.33333333333331px;
    min-height:  933.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PressLandingHead {
    padding:  0vw 9.722222222222223vw 4.861111111111112vw;
    margin-bottom:  -6.944444444444445vw;
    min-height:  48.611111111111114vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PressLandingHead {
    padding:  0vw 11.29032258064516vw 5.64516129032258vw;
    margin-bottom:  -8.064516129032258vw;
    min-height:  56.45161290322581vw;
  }
}
@media (max-width: 759px) {
  .PressLandingHead {
    padding:  0vw 6.25vw;
    min-height:  125vw;
  }
}
.PressLandingHead-flexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (max-width: 759px) {
  .PressLandingHead-flexSpacer {
    display: none;
  }
}
@media (min-width: 1920px) {
  .PressLandingHead-title {
    margin-top:  248px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PressLandingHead-title {
    margin-top:  12.916666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PressLandingHead-title {
    margin-top:  15vw;
  }
}
@media (max-width: 759px) {
  .PressLandingHead-title {
    margin-top:  58.125vw;
  }
}
@media (max-width: 759px) {
  .PressLandingHead-title {
    margin: 0;
  }
}
.PressLandingHead-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #111112;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 2;
}
.PressLandingHead-overlay.show {
  opacity: 0.8;
}
