@media (min-width: 1920px) {
  .CareersLandingLocations {
    padding:  200px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations {
    padding:  10.416666666666668vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations {
    padding:  12.096774193548386vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations {
    padding:  0vw;
    margin-top:  31.25vw;
  }
}
.CareersLandingLocations-titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersLandingLocations-titleBlock {
    padding:  49.33333333333333px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-titleBlock {
    padding:  2.5694444444444446vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-titleBlock {
    padding:  2.9838709677419355vw 0vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-titleBlock {
    padding:  0vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-titleBlock {
    display: block;
    border-top: 0;
  }
}
.CareersLandingLocations-title,
.CareersLandingLocations-subtitle {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .CareersLandingLocations-title {
    padding-top: 6.25vw;
    border-top: 1px solid #FFFFFF;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-subtitle {
    margin-top: 7.8125vw;
  }
}
.CareersLandingLocations-cards {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.CareersLandingLocations-cards.isSlider {
  display: block;
}
@media (min-width: 1920px) {
  .CareersLandingLocations-cards {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-cards {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-cards {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-cards {
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-cards {
    display: none;
  }
  .CareersLandingLocations-cards.isSlider {
    display: none;
  }
}
.CareersLandingLocations-cards_mobile {
  display: none;
}
@media (max-width: 759px) {
  .CareersLandingLocations-cards_mobile {
    display: block;
  }
}
.CareersLandingLocations-cardsArrowRight,
.CareersLandingLocations-cardsArrowLeft {
  display: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
.isSlider .CareersLandingLocations-cardsArrowRight,
.isSlider .CareersLandingLocations-cardsArrowLeft {
  display: block;
}
.CareersLandingLocations-cardsArrowRight.slick-disabled,
.CareersLandingLocations-cardsArrowLeft.slick-disabled {
  display: none !important;
}
@media (max-width: 759px) {
  .CareersLandingLocations-cardsArrowRight,
  .CareersLandingLocations-cardsArrowLeft {
    display: none !important;
  }
}
@media (min-width: 1920px) {
  .CareersLandingLocations-cardsArrowRight {
    right:  -93.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-cardsArrowRight {
    right:  -4.861111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-cardsArrowRight {
    right:  -5.64516129032258vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-cardsArrowRight {
    right:  -21.875vw;
  }
}
.CareersLandingLocations-cardsArrowLeft {
  -webkit-transform: rotateY(180deg) translate3d(0, -50%, 0);
          transform: rotateY(180deg) translate3d(0, -50%, 0);
}
@media (min-width: 1920px) {
  .CareersLandingLocations-cardsArrowLeft {
    left:  -93.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-cardsArrowLeft {
    left:  -4.861111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-cardsArrowLeft {
    left:  -5.64516129032258vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-cardsArrowLeft {
    left:  -21.875vw;
  }
}
@media (min-width: 1920px) {
  .CareersLandingLocations-cardsWrapperInner .slick-slide {
    margin:  0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-cardsWrapperInner .slick-slide {
    margin:  0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-cardsWrapperInner .slick-slide {
    margin:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-cardsWrapperInner .slick-slide {
    margin:  0vw 6.25vw;
  }
}
.CareersLandingLocations-cardsWrapperInner .slick-list {
  overflow: visible;
}
@media (min-width: 1920px) {
  .CareersLandingLocations-cardsWrapperInner .slick-list {
    margin:  0 -26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-cardsWrapperInner .slick-list {
    margin:  0vw -1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-cardsWrapperInner .slick-list {
    margin:  0vw -1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-cardsWrapperInner .slick-list {
    margin:  0vw -6.25vw;
  }
}
.CareersLandingLocations-card {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 32%;
          flex: 0 0 32%;
}
@media (min-width: 1920px) {
  .CareersLandingLocations-map,
  .CareersLandingLocations-map_mobile {
    margin-top:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingLocations-map,
  .CareersLandingLocations-map_mobile {
    margin-top:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingLocations-map,
  .CareersLandingLocations-map_mobile {
    margin-top:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-map,
  .CareersLandingLocations-map_mobile {
    margin-top:  25vw;
  }
}
.CareersLandingLocations-mapArrowRight,
.CareersLandingLocations-map_mobileArrowRight,
.CareersLandingLocations-mapArrowLeft,
.CareersLandingLocations-map_mobileArrowLeft {
  display: none;
}
.CareersLandingLocations-map img,
.CareersLandingLocations-map_mobile img {
  width: 100%;
}
@media (max-width: 759px) {
  .CareersLandingLocations-map,
  .CareersLandingLocations-map_mobile {
    overflow: hidden;
    position: relative;
  }
  .CareersLandingLocations-mapArrowRight,
  .CareersLandingLocations-map_mobileArrowRight,
  .CareersLandingLocations-mapArrowLeft,
  .CareersLandingLocations-map_mobileArrowLeft {
    position: absolute;
    top: 50%;
    display: block;
  }
  .CareersLandingLocations-mapArrowRight.hide,
  .CareersLandingLocations-map_mobileArrowRight.hide,
  .CareersLandingLocations-mapArrowLeft.hide,
  .CareersLandingLocations-map_mobileArrowLeft.hide {
    display: none;
  }
  .CareersLandingLocations-mapArrowRight,
  .CareersLandingLocations-map_mobileArrowRight {
    right: 20px;
  }
  .CareersLandingLocations-mapArrowLeft,
  .CareersLandingLocations-map_mobileArrowLeft {
    left: 20px;
  }
  .CareersLandingLocations-map img,
  .CareersLandingLocations-map_mobile img {
    width: 300%;
    max-width: none;
    -webkit-transform: translate3d(-30%, 0, 0);
            transform: translate3d(-30%, 0, 0);
    -webkit-transition: 0.5s -webkit-transform;
    transition: 0.5s -webkit-transform;
    transition: 0.5s transform;
    transition: 0.5s transform, 0.5s -webkit-transform;
  }
}
@media (max-width: 759px) {
  .CareersLandingLocations-map {
    display: none;
  }
}
.CareersLandingLocations-map_mobile {
  display: none;
}
@media (max-width: 759px) {
  .CareersLandingLocations-map_mobile {
    display: block;
  }
  .CareersLandingLocations-map_mobile img {
    margin-left: 15%;
    width: 200%;
    pointer-events: none;
  }
}
