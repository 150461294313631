@media (min-width: 1920px) {
  .CampusHighlight {
    margin-top:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight {
    margin-top:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight {
    margin-top:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight {
    margin-top:  23.75vw;
  }
}
.CampusHighlight:first-of-type {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .CampusHighlight-titleWrapper {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-titleWrapper {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-titleWrapper {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-titleWrapper {
    padding:  0vw 6.25vw;
  }
}
.CampusHighlight-title {
  color: #FFFFFF;
  border-top: 1px solid #FFFFFF;
  position: relative;
}
@media (min-width: 1920px) {
  .CampusHighlight-title {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-title {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-title {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-title {
    padding-top:  4.6875vw;
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-title.isLive {
    padding-left:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-title.isLive {
    padding-left:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-title.isLive {
    padding-left:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-title.isLive {
    padding-left:  9.375vw;
  }
}
.CampusHighlight-title.isLive:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  left: 0;
  background: #EA0101;
}
@media (min-width: 1920px) {
  .CampusHighlight-title.isLive:before {
    width:  53.33333333333333px;
    height:  53.33333333333333px;
    bottom:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-title.isLive:before {
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
    bottom:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-title.isLive:before {
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
    bottom:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-title.isLive:before {
    width:  6.25vw;
    height:  6.25vw;
    top:  6.5625vw;
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-video .Video {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-video .Video {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-video .Video {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-video .Video {
    margin-top:  5.3125vw;
  }
}
.CampusHighlight-video .Video-title {
  color: #FFFFFF;
}
.CampusHighlight-video .Video-duration {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .CampusHighlight-video .Video-duration,
  .CampusHighlight-video .Video-title {
    padding-left: 0;
  }
}
.CampusHighlight-event {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 41%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.25);
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (max-width: 759px) {
  .CampusHighlight-event {
    padding-bottom: 50%;
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-event {
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-event {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-event {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-event {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
    margin-top:  5.3125vw;
  }
}
.isDesktop .CampusHighlight-event:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .CampusHighlight-event:hover .CampusHighlight-eventDescriptionLink {
  text-decoration: underline;
}
.isDesktop .CampusHighlight-event:hover .CampusHighlight-eventDescriptionLink:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isDesktop .CampusHighlight-event:hover .CampusHighlight-eventBackground {
  -webkit-transform: translate3d(0, 0, 0) scale(1.05);
          transform: translate3d(0, 0, 0) scale(1.05);
}
.CampusHighlight-eventBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.CampusHighlight-eventBackground img {
  -o-object-fit: cover;
     object-fit: cover;
  min-width: 100%;
  width: auto;
  height: 100%;
}
.CampusHighlight-eventUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.CampusHighlight-eventDescription {
  color: #FFFFFF;
  position: absolute;
}
@media (min-width: 1920px) {
  .CampusHighlight-eventDescription {
    bottom:  26.666666666666664px;
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-eventDescription {
    bottom:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-eventDescription {
    bottom:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescription {
    bottom:  3.125vw;
    left:  3.125vw;
    right:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-eventDescriptionTitle {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-eventDescriptionTitle {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-eventDescriptionTitle {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionTitle {
    margin-top:  0vw;
  }
}
.CampusHighlight-eventDescriptionLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-eventDescriptionLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-eventDescriptionLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-eventDescriptionLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .CampusHighlight-eventDescriptionLink:hover,
.isNotDesktop .CampusHighlight-eventDescriptionLink:active {
  text-decoration: underline;
}
.isDesktop .CampusHighlight-eventDescriptionLink:hover:before,
.isNotDesktop .CampusHighlight-eventDescriptionLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CampusHighlight-eventDescriptionLink:hover:before,
  .isNotDesktop .CampusHighlight-eventDescriptionLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionLink {
    letter-spacing: 0;
  }
}
.CampusHighlight-eventDescriptionLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CampusHighlight-eventDescriptionLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-eventDescriptionLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-eventDescriptionLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-eventDescriptionLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CampusHighlight-eventDescriptionLink {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHighlight-eventDescriptionLink {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHighlight-eventDescriptionLink {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionLink {
    margin-top:  3.125vw;
  }
}
@media (max-width: 759px) {
  .CampusHighlight-eventDescriptionLink {
    line-height: 1;
  }
}
.CampusHighlight-eventDescriptionLink:visited {
  color: #FFFFFF;
}
