.ProductFamilyFilter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
.ProductFamilyFilter-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 6.25vw 0 12.5vw;
  }
}
.ProductFamilyFilter-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.ProductFamilyFilter-filterWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterWrapper {
    display: none;
  }
}
.ProductFamilyFilter-filterActiveItems {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterActiveItems {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2.5vw 6.25vw 0;
  }
}
.ProductFamilyFilter-filterActiveItems.isHidden {
  display: none;
}
.ProductFamilyFilter-filterActiveItemsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterActiveItemsWrapper {
    max-width: 100%;
    margin-top: 6.25vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterActiveItemsWrapper {
    max-width: 53.22580645vw;
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterActiveItemsWrapper {
    max-width: 45.83333333vw;
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterActiveItemsWrapper {
    max-width: 880px;
    margin-top: 53.33333333px;
  }
}
.ProductFamilyFilter-filterActiveItem {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  color: #FFFFFF;
  text-transform: uppercase;
  background: #111112;
  cursor: pointer;
  margin-right: 2px;
  margin-top: 2px;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterActiveItem {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterActiveItem {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterActiveItem {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterActiveItem {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterActiveItem {
    padding: 1.875vw 3.75vw 0.9375vw;
    border-radius: 6.25vw;
    width: 100%;
    margin-right: 0;
    margin-top: 3.125vw;
  }
  .ProductFamilyFilter-filterActiveItem:first-child {
    margin-top: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterActiveItem {
    padding: 0.48387097vw 3.87096774vw 0.32258065vw 0.80645161vw;
    border-radius: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterActiveItem {
    padding: 0.41666667vw 3.33333333vw 0.27777778vw 0.69444444vw;
    border-radius: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterActiveItem {
    padding: 8px 64px 5.33333333px 13.33333333px;
    border-radius: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:after,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:after,
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:before,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:after,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:after,
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:before,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:after,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:after,
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:before,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:after,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:after,
  .isDesktop .ProductFamilyFilter-filterActiveItem:hover .ProductFamilyFilter-filterActiveItemIcon:before,
  .isNotDesktop .ProductFamilyFilter-filterActiveItem:active .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 26.66666667px;
  }
}
.ProductFamilyFilter-filterActiveItem span {
  color: #A6A8B3;
}
.ProductFamilyFilter-filterActiveItemIcon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterActiveItemIcon {
    right: 2.5vw;
    width: 6.25vw;
    height: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterActiveItemIcon {
    right: 0.64516129vw;
    width: 1.61290323vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterActiveItemIcon {
    right: 0.55555556vw;
    width: 1.38888889vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterActiveItemIcon {
    right: 10.66666667px;
    width: 26.66666667px;
    height: 26.66666667px;
  }
}
.ProductFamilyFilter-filterActiveItemIcon:after,
.ProductFamilyFilter-filterActiveItemIcon:before {
  content: '';
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  top: 50%;
  left: 50%;
  height: 2px;
  background: #FFFFFF;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterActiveItemIcon:after,
  .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterActiveItemIcon:after,
  .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterActiveItemIcon:after,
  .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterActiveItemIcon:after,
  .ProductFamilyFilter-filterActiveItemIcon:before {
    width: 21.33333333px;
  }
}
.ProductFamilyFilter-filterActiveItemIcon:before {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
.ProductFamilyFilter-filterHideItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterHideItems {
    width: 100%;
    display: none;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterHideItems .ProductFamilyFilter-filterItem {
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterHideItems .ProductFamilyFilter-filterItem {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterHideItems .ProductFamilyFilter-filterItem {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterHideItems .ProductFamilyFilter-filterItem {
    margin-top: 40px;
  }
}
.isHidden.ProductFamilyFilter-filterHideItems .ProductFamilyFilter-filterItem {
  display: none;
}
.ProductFamilyFilter-filterMobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0 6.25vw;
  }
}
.ProductFamilyFilter-filterItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductFamilyFilter-filterItem:nth-child(5n + 1) {
  margin-left: 0;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItem {
    width: 50vw;
    margin-left: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItem {
    width: 12.90322581vw;
    margin-left: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItem {
    width: 11.11111111vw;
    margin-left: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItem {
    width: 213.33333333px;
    margin-left: 53.33333333px;
  }
}
.ProductFamilyFilter-filterItemTitle {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemTitle {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemTitle {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemTitle {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemTitle {
    font-size: 16px;
  }
}
.ProductFamilyFilter-filterItemSelect {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  width: 100%;
}
.ProductFamilyFilter-filterItemSelectText {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectText {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectText {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectText {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectText {
    font-size: 16px;
  }
}
.brand--thorn .ProductFamilyFilter-filterItemSelectText {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 0.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 0.13888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 2.66666667px;
  }
}
.filterItemTwoLine .ProductFamilyFilter-filterItemSelectText {
  line-height: 1.25;
}
@media (max-width: 759px) {
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText {
    padding-bottom: 6.66666667px;
  }
}
.filterItemTwoLine .ProductFamilyFilter-filterItemSelectText p {
  width: 88%;
  text-overflow: unset;
  overflow: hidden;
  white-space: pre-wrap;
}
.isDesktop .ProductFamilyFilter-filterItemSelectText:hover:after,
.isNotDesktop .ProductFamilyFilter-filterItemSelectText:active:after {
  width: 0;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.isDesktop .ProductFamilyFilter-filterItemSelectText:hover:before,
.isNotDesktop .ProductFamilyFilter-filterItemSelectText:active:before {
  width: 100%;
  -webkit-transition: 0.15s width 0.12s ease-out;
  transition: 0.15s width 0.12s ease-out;
}
.ProductFamilyFilter-filterItemSelectText:after {
  content: '';
  background: #111112;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.brand--thorn .ProductFamilyFilter-filterItemSelectText:after {
  background: #FFFFFF;
}
.ProductFamilyFilter-filterItemSelectText:before {
  content: '';
  background: #111112;
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 0;
  left: 0;
}
.brand--thorn .ProductFamilyFilter-filterItemSelectText:before {
  background: #FFFFFF;
}
.ProductFamilyFilter-filterItemSelectText > p {
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ProductFamilyFilter-filterItemSelectText span {
  position: absolute;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectText span {
    right: 1.875vw;
    border-left: 1.25vw solid transparent;
    border-right: 1.25vw solid transparent;
    border-top: 1.5625vw solid #111112;
    top: 4.375vw;
  }
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText span {
    top: 3.75vw;
  }
  .brand--thorn .ProductFamilyFilter-filterItemSelectText span {
    border-top: 1.5625vw solid #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectText span {
    right: 0.48387097vw;
    border-left: 0.32258065vw solid transparent;
    border-right: 0.32258065vw solid transparent;
    border-top: 0.40322581vw solid #111112;
    top: 1.12903226vw;
  }
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText span {
    top: 0.96774194vw;
  }
  .brand--thorn .ProductFamilyFilter-filterItemSelectText span {
    border-top: 0.40322581vw solid #FFFFFF;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectText span {
    right: 0.41666667vw;
    border-left: 0.27777778vw solid transparent;
    border-right: 0.27777778vw solid transparent;
    border-top: 0.34722222vw solid #111112;
    top: 0.97222222vw;
  }
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText span {
    top: 0.83333333vw;
  }
  .brand--thorn .ProductFamilyFilter-filterItemSelectText span {
    border-top: 0.34722222vw solid #FFFFFF;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectText span {
    right: 8px;
    border-left: 5.33333333px solid transparent;
    border-right: 5.33333333px solid transparent;
    border-top: 6.66666667px solid #111112;
    top: 18.66666667px;
  }
  .filterItemTwoLine .ProductFamilyFilter-filterItemSelectText span {
    top: 16px;
  }
  .brand--thorn .ProductFamilyFilter-filterItemSelectText span {
    border-top: 6.66666667px solid #FFFFFF;
  }
}
.brand--thorn .ProductFamilyFilter-filterItemSelectText {
  color: #111112;
}
.brand--thorn .ProductFamilyFilter-filterItemSelectText:after {
  background-color: #111112;
}
.brand--thorn .ProductFamilyFilter-filterItemSelectText span {
  border-top-color: #111112;
}
.isActive .ProductFamilyFilter-filterItemSelectText span {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ProductFamilyFilter-filterItemSelectMenu {
  position: absolute;
  top: calc(100% - 1px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: #111112;
  cursor: default;
  z-index: 30;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #111112;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    left:  -26.666666666666664px;
    right:  -26.666666666666664px;
    padding-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    left:  -1.3888888888888888vw;
    right:  -1.3888888888888888vw;
    padding-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    left:  -1.6129032258064515vw;
    right:  -1.6129032258064515vw;
    padding-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    left:  -6.25vw;
    right:  -6.25vw;
    padding-top:  3.125vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    -webkit-transform: translateY(-6.25vw);
        -ms-transform: translateY(-6.25vw);
            transform: translateY(-6.25vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    -webkit-transform: translateY(-1.61290323vw);
        -ms-transform: translateY(-1.61290323vw);
            transform: translateY(-1.61290323vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    -webkit-transform: translateY(-1.38888889vw);
        -ms-transform: translateY(-1.38888889vw);
            transform: translateY(-1.38888889vw);
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenu {
    -webkit-transform: translateY(-26.66666667px);
        -ms-transform: translateY(-26.66666667px);
            transform: translateY(-26.66666667px);
  }
}
.ProductFamilyFilter-filterItemSelect.isActive .ProductFamilyFilter-filterItemSelectMenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
}
.ProductFamilyFilter-filterItemSelectMenuCheckbox {
  position: relative;
  width: 100%;
}
.ProductFamilyFilter-filterItemSelectMenuCheckbox > input {
  display: none;
}
.ProductFamilyFilter-filterItemSelectMenuCheckbox > input:checked + label:before {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > input:checked + label:before {
    background: #111112;
  }
}
.ProductFamilyFilter-filterItemSelectMenuCheckbox > label {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label {
    font-size: 3.75vw;
    line-height: 1;
    color: #111112;
  }
}
.isDesktop .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:hover:before,
.isNotDesktop .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:active:before {
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:hover:before,
  .isNotDesktop .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:active:before {
    border: 1px solid #111112;
  }
}
.ProductFamilyFilter-filterItemSelectMenuCheckbox > label:before {
  content: '';
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #6F7380;
  -webkit-box-shadow: inset 0px 0px 0px 2px #111112;
          box-shadow: inset 0px 0px 0px 2px #111112;
  -webkit-transition: 0.15s background ease-out, 0.15s border ease-out;
  transition: 0.15s background ease-out, 0.15s border ease-out;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:before {
    width: 6.25vw;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 6.25vw;
            flex: 0 0 6.25vw;
    margin-right: 6.25vw;
    height: 6.25vw;
    border: 1px solid #111112;
    -webkit-box-shadow: inset 0px 0px 0px 2px #FFFFFF;
            box-shadow: inset 0px 0px 0px 2px #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:before {
    width: 1.61290323vw;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 1.61290323vw;
            flex: 0 0 1.61290323vw;
    margin-right: 0.80645161vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:before {
    width: 1.38888889vw;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 1.38888889vw;
            flex: 0 0 1.38888889vw;
    margin-right: 0.69444444vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox > label:before {
    width: 26.66666667px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 26.66666667px;
            flex: 0 0 26.66666667px;
    margin-right: 13.33333333px;
    height: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox {
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox {
    margin-top: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox {
    margin-top: 26.66666667px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuCheckbox:first-child {
  margin-top: 0;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox:last-child {
    margin-bottom: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox:last-child {
    margin-bottom: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox:last-child {
    margin-bottom: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuCheckbox:last-child {
    margin-bottom: 13.33333333px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuSeparator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1px;
  background: #6F7380;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuSeparator {
    width: 3.125vw;
    margin: 0 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuSeparator {
    width: 0.80645161vw;
    margin: 0 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuSeparator {
    width: 0.69444444vw;
    margin: 0 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuSeparator {
    width: 13.33333333px;
    margin: 0 6.66666667px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuNumber {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuNumber {
    margin-top: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuNumber {
    margin-top: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuNumber {
    margin-top: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuNumber {
    margin-top: 13.33333333px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuInputWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuInputWrapper {
    padding:  0 26.666666666666664px 0;
    margin-top:  26.666666666666664px;
    margin-bottom:  26.666666666666664px;
    max-height:  333.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuInputWrapper {
    padding:  0vw 1.3888888888888888vw 0vw;
    margin-top:  1.3888888888888888vw;
    margin-bottom:  1.3888888888888888vw;
    max-height:  17.36111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuInputWrapper {
    padding:  0vw 1.6129032258064515vw 0vw;
    margin-top:  1.6129032258064515vw;
    margin-bottom:  1.6129032258064515vw;
    max-height:  20.161290322580644vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuInputWrapper {
    padding:  0vw 6.25vw 0vw;
    margin-top:  6.25vw;
    margin-bottom:  6.25vw;
    max-height:  78.125vw;
  }
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  -webkit-border-radius: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  text-transform: none;
  line-height: 3.33333333;
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    font-size: 16px;
  }
}
.isIE .ProductFamilyFilter-filterItemSelectMenuInputNumber {
  line-height: 1;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    width: 21.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    width: 5.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    width: 4.86111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuInputNumber {
    width: 93.33333333px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-webkit-inner-spin-button,
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-moz-inner-spin-button,
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-moz-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-ms-inner-spin-button,
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-ms-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-webkit-input-placeholder {
  color: #6F7380;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber::-moz-placeholder {
  color: #6F7380;
  opacity: 1;
  -moz-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber:-ms-input-placeholder {
  color: #6F7380;
  -ms-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber:focus::-webkit-input-placeholder {
  color: transparent;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.ProductFamilyFilter-filterItemSelectMenuInputNumber:focus:-ms-input-placeholder {
  color: transparent;
}
.ProductFamilyFilter-filterItemSelectMenuApply {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  width: 100%;
  background-color: #FFFFFF;
  color: #111112;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    font-size: 16px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuApply:visited {
  color: #111112;
}
.isDesktop .ProductFamilyFilter-filterItemSelectMenuApply:hover,
.isNotDesktop .ProductFamilyFilter-filterItemSelectMenuApply:active {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterItemSelectMenuApply {
    height: 53.33333333px;
  }
}
.ProductFamilyFilter-filterItemSelectMenuApply.isDisabled {
  display: none;
}
.ProductFamilyFilter-filterMore {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 auto;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMore {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterMore {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterMore {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterMore {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterMore {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterMore {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterMore {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMore {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMore {
    display: none;
  }
}
.ProductFamilyFilter-filterMore.isOpenFilters .ProductFamilyFilter-filterMoreIconItem:first-child {
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
}
.isDesktop .ProductFamilyFilter-filterMore:hover,
.isNotDesktop .ProductFamilyFilter-filterMore:active {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyFilter-filterMore:hover .ProductFamilyFilter-filterMoreIconItem,
  .isNotDesktop .ProductFamilyFilter-filterMore:active .ProductFamilyFilter-filterMoreIconItem {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ProductFamilyFilter-filterMore:hover .ProductFamilyFilter-filterMoreIconItem,
  .isNotDesktop .ProductFamilyFilter-filterMore:active .ProductFamilyFilter-filterMoreIconItem {
    width: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ProductFamilyFilter-filterMore:hover .ProductFamilyFilter-filterMoreIconItem,
  .isNotDesktop .ProductFamilyFilter-filterMore:active .ProductFamilyFilter-filterMoreIconItem {
    width: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .ProductFamilyFilter-filterMore:hover .ProductFamilyFilter-filterMoreIconItem,
  .isNotDesktop .ProductFamilyFilter-filterMore:active .ProductFamilyFilter-filterMoreIconItem {
    width: 26.66666667px;
  }
}
.ProductFamilyFilter-filterMore span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMore span {
    padding-top: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterMore span {
    padding-top: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterMore span {
    padding-top: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterMore span {
    padding-top: 5.33333333px;
  }
}
.ProductFamilyFilter-filterMoreIcon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMoreIcon {
    width: 6.25vw;
    height: 6.25vw;
    margin-right: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterMoreIcon {
    width: 1.61290323vw;
    height: 1.61290323vw;
    margin-right: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterMoreIcon {
    width: 1.38888889vw;
    height: 1.38888889vw;
    margin-right: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterMoreIcon {
    width: 26.66666667px;
    height: 26.66666667px;
    margin-right: 16px;
  }
}
.ProductFamilyFilter-filterMoreIconItem {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  top: 50%;
  height: 2px;
  background: #111112;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out, 0.15s -webkit-transform ease-out;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterMoreIconItem {
    width: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterMoreIconItem {
    width: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterMoreIconItem {
    width: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterMoreIconItem {
    width: 21.33333333px;
  }
}
.ProductFamilyFilter-filterMoreIconItem:first-child {
  -webkit-transform: translate(-50%) rotate(90deg);
      -ms-transform: translate(-50%) rotate(90deg);
          transform: translate(-50%) rotate(90deg);
}
.ProductFamilyFilter-filterClear {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterClear {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterClear {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterClear {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterClear {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter-filterClear {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter-filterClear {
    margin-left: 1.61290323vw;
    margin-top: 3.9516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter-filterClear {
    margin-left: 1.38888889vw;
    margin-top: 3.40277778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFilter-filterClear {
    margin-left: 26.66666667px;
    margin-top: 65.33333333px;
  }
}
.isDesktop .ProductFamilyFilter-filterClear:hover,
.isNotDesktop .ProductFamilyFilter-filterClear:active {
  text-decoration: underline;
}
.ProductFamilyFilter-filterClear--hide {
  display: none;
}
@media (min-width: 1920px) {
  .ProductFamilyFilter .Checkbox {
    padding-bottom:  14.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFilter .Checkbox {
    padding-bottom:  0.763888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFilter .Checkbox {
    padding-bottom:  0.8870967741935484vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFilter .Checkbox {
    padding-bottom:  3.4375vw;
  }
}
.ProductFamilyFilter .Checkbox label {
  text-transform: uppercase;
  color: #111112;
}
