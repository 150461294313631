.ProductFinder {
  position: relative;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 759px) {
  .ProductFinder {
    overflow: hidden;
  }
}
.ProductFinder.disabled .ProductFinder-expandCrossItem--1 {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(90deg);
          transform: translate3d(-50%, -50%, 0) rotate(90deg);
}
.ProductFinder.isAccordion .ProductFinder-titleWrapper {
  cursor: pointer;
}
.ProductFinder.isAccordion .ProductFinder-titleWrapper:hover .ProductFinder-title {
  color: rgba(17, 17, 18, 0.7);
}
.ProductFinder.isAccordion .ProductFinder-titleWrapper:hover .ProductFinder-expandCrossItem {
  width: 120%;
}
.ProductFinder.isAccordion .ProductFinder-expandCross {
  display: block;
}
.ProductFinder.isAccordion.disabled .ProductFinder-accordionWrapper {
  max-height: 0 !important;
}
@media (min-width: 1920px) {
  .ProductFinder {
    margin-top:  82.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder {
    margin-top:  4.305555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder {
    margin-top:  5vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder {
    margin-top:  6.25vw;
  }
}
.ProductFinder--1 {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .ProductFinder--1 .ProductFinder-titleWrapper {
    padding:  80px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder--1 .ProductFinder-titleWrapper {
    padding:  4.166666666666667vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder--1 .ProductFinder-titleWrapper {
    padding:  4.838709677419355vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder--1 .ProductFinder-titleWrapper {
    padding:  9.375vw 6.25vw 0vw;
  }
}
.ProductFinder--1:before {
  display: none;
}
.ProductFinder:before {
  content: '';
  position: absolute;
  top: 0;
  height: 1px;
  background: #111112;
}
@media (min-width: 1920px) {
  .ProductFinder:before {
    left:  186.66666666666666px;
    right:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder:before {
    left:  9.722222222222223vw;
    right:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder:before {
    left:  11.29032258064516vw;
    right:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder:before {
    left:  6.25vw;
    right:  6.25vw;
  }
}
.ProductFinder.show {
  opacity: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  pointer-events: all;
}
.ProductFinder-accordionWrapper {
  -webkit-transition: 0.35s max-height;
  transition: 0.35s max-height;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ProductFinder-titleWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .ProductFinder-titleWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 1920px) {
  .ProductFinder-titleWrapper {
    padding:  80px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-titleWrapper {
    padding:  4.166666666666667vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-titleWrapper {
    padding:  4.838709677419355vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-titleWrapper {
    padding:  6.25vw 6.25vw 0vw;
  }
}
.ProductFinder-expandCross {
  position: absolute;
  display: none;
}
@media (min-width: 1920px) {
  .ProductFinder-expandCross {
    right:  186.66666666666666px;
    width:  53.33333333333333px;
    height:  53.33333333333333px;
    bottom:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-expandCross {
    right:  9.722222222222223vw;
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
    bottom:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-expandCross {
    right:  11.29032258064516vw;
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
    bottom:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-expandCross {
    right:  6.25vw;
    width:  6.25vw;
    height:  6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-expandCross {
    bottom: auto;
  }
}
.ProductFinder-expandCrossItem {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  height: 4px;
  left: 50%;
  background: #111112;
  -webkit-transition: 0.15s width, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s width;
  transition: 0.15s transform, 0.15s width, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFinder-expandCrossItem {
    height: 3px;
  }
}
.ProductFinder-expandCrossItem--1 {
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transition: 0.15s width, 0.15s -webkit-transform;
  transition: 0.15s width, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s width;
  transition: 0.15s transform, 0.15s width, 0.15s -webkit-transform;
}
.ProductFinder-title {
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
  color: #111112;
}
.ProductFinder-image {
  width: 100%;
  text-align: center;
}
@media (min-width: 1920px) {
  .ProductFinder-image {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-image {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-image {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-image {
    padding:  0vw 43.75vw;
  }
}
.ProductFinder-image img {
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 759px) {
  .ProductFinder-image {
    padding: 0;
  }
}
.ProductFinder-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 1920px) {
  .ProductFinder-content {
    padding:  0 186.66666666666666px 120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-content {
    padding:  0vw 9.722222222222223vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-content {
    padding:  0vw 11.29032258064516vw 7.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-content {
    padding:  0vw 43.75vw 28.125vw;
  }
}
@media (min-width: 1920px) {
  .ProductFinder-content.withSlider {
    padding:  0 0 120px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-content.withSlider {
    padding:  0vw 0vw 6.25vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-content.withSlider {
    padding:  0vw 0vw 7.258064516129032vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-content.withSlider {
    padding:  0vw 0vw 28.125vw 43.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-content {
    display: none;
  }
}
.ProductFinder-content_mobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductFinder-content_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 0 0 0 6.25vw;
  }
}
.ProductFinder-optionsList {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 13.8%;
          flex: 0 0 13.8%;
  position: relative;
}
.ProductFinder-option {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .ProductFinder-option {
    padding-top:  20px;
    padding-bottom:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-option {
    padding-top:  1.0416666666666667vw;
    padding-bottom:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-option {
    padding-top:  1.2096774193548387vw;
    padding-bottom:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-option {
    padding-top:  4.6875vw;
    padding-bottom:  4.375vw;
  }
}
.ProductFinder-option.last + .ProductFinder-itemOptionLine {
  display: none;
}
.ProductFinder-buttonWrapper {
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .ProductFinder-buttonWrapper {
    width:  320px;
    padding-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-buttonWrapper {
    width:  16.666666666666668vw;
    padding-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-buttonWrapper {
    width:  19.35483870967742vw;
    padding-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-buttonWrapper {
    margin-top:  12.5vw;
    width:  75vw;
  }
}
.ProductFinder-allOptions {
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid transparent;
}
.ProductFinder-allOptions svg {
  fill: #FFFFFF;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .ProductFinder-allOptions svg {
    width:  16px;
    height:  16px;
    margin-left:  20px;
    padding-bottom:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-allOptions svg {
    width:  0.8333333333333334vw;
    height:  0.8333333333333334vw;
    margin-left:  1.0416666666666667vw;
    padding-bottom:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-allOptions svg {
    width:  0.967741935483871vw;
    height:  0.967741935483871vw;
    margin-left:  1.2096774193548387vw;
    padding-bottom:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-allOptions svg {
    width:  3.75vw;
    height:  3.75vw;
    margin-left:  4.6875vw;
    margin-bottom:  0.625vw;
  }
}
.isDesktop .ProductFinder-allOptions:hover svg,
.isNotDesktop .ProductFinder-allOptions:active svg {
  fill: #111112;
}
.ProductFinder-allOptions span {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .ProductFinder-allOptions span {
    margin-left:  65.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-allOptions span {
    margin-left:  3.402777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-allOptions span {
    margin-left:  3.9516129032258065vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-allOptions span {
    margin-left:  15.3125vw;
    padding-top:  0.625vw;
    padding-bottom:  0.3125vw;
  }
}
.ProductFinder-itemsList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 1920px) {
  .ProductFinder-itemsList {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-itemsList {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-itemsList {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-itemsList {
    margin-left:  12.5vw;
  }
}
.ProductFinder-itemsList.slider {
  margin-left: 0;
}
@media (min-width: 1920px) {
  .ProductFinder-itemsList.slider {
    padding-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-itemsList.slider {
    padding-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-itemsList.slider {
    padding-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-itemsList.slider {
    padding-left:  12.5vw;
  }
}
.ProductFinder-item {
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.ProductFinder-item.withTitle {
  border-top: 1px solid #111112;
}
.ProductFinder-itemOption {
  text-transform: none;
}
.ProductFinder-itemOption.last + .ProductFinder-itemOptionLine {
  display: none;
}
@media (min-width: 1920px) {
  .ProductFinder-itemOption {
    padding-top:  20px;
    padding-bottom:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-itemOption {
    padding-top:  1.0416666666666667vw;
    padding-bottom:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-itemOption {
    padding-top:  1.2096774193548387vw;
    padding-bottom:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-itemOption {
    padding-top:  4.6875vw;
    padding-bottom:  4.375vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-itemOption {
    padding-top: 0;
  }
}
.ProductFinder-itemOptionLine {
  position: absolute;
  left: 0;
  width: 100%;
  background: rgba(17, 17, 18, 0.1);
  height: 1px;
}
@media (max-width: 759px) {
  .ProductFinder-itemOptionLine {
    left: 3.125vw;
    width: calc(100% - 3.125vw);
  }
}
.ProductFinder-itemOption:last-of-type .ProductFinder-itemOptionLine {
  display: none;
}
.ProductFinder-itemOptionTitle {
  padding-top: 4.6875vw;
  color: #6F7380;
}
.ProductFinder-itemTitle {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: inline-block;
}
@media (max-width: 759px) {
  .ProductFinder-itemTitle {
    font-size: 9.375vw;
  }
}
.ProductFinder-item--1 {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.ProductFinder-item--2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
.ProductFinder-item--3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 31%;
          flex: 0 0 31%;
}
.ProductFinder-item--4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 23%;
          flex: 0 0 23%;
}
@media (min-width: 1920px) {
  .ProductFinder-item--4 .ProductFinder-itemTitle {
    font-size:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-item--4 .ProductFinder-itemTitle {
    font-size:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-item--4 .ProductFinder-itemTitle {
    font-size:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-item--4 .ProductFinder-itemTitle {
    font-size:  9.375vw;
  }
}
.ProductFinder-item--5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 18%;
          flex: 0 0 18%;
}
@media (min-width: 1920px) {
  .ProductFinder-item--5 .ProductFinder-itemTitle {
    font-size:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-item--5 .ProductFinder-itemTitle {
    font-size:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-item--5 .ProductFinder-itemTitle {
    font-size:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-item--5 .ProductFinder-itemTitle {
    font-size:  9.375vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-item--1,
  .ProductFinder-item--2,
  .ProductFinder-item--3,
  .ProductFinder-item--4,
  .ProductFinder-item--5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
}
.ProductFinder-itemTitle {
  white-space: nowrap;
}
@media (min-width: 1920px) {
  .ProductFinder-itemTitle.letter {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-itemTitle.letter {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-itemTitle.letter {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-itemTitle.letter {
    font-size:  12.5vw;
  }
}
@media (min-width: 1920px) {
  .ProductFinder-itemTitle {
    padding-top:  26.666666666666664px;
    margin-bottom:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-itemTitle {
    padding-top:  1.3888888888888888vw;
    margin-bottom:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-itemTitle {
    padding-top:  1.6129032258064515vw;
    margin-bottom:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-itemTitle {
    padding-top:  6.25vw;
    margin-bottom:  9.375vw;
  }
}
.ProductFinder-item--placeholder {
  position: absolute;
  pointer-events: none;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ProductFinder-item--placeholder .responsive {
  left: 20px;
}
.ProductFinder-item--placeholder.withTitle {
  border-top: none;
}
.ProductFinder-item--placeholder .ProductFinder-itemTitle,
.ProductFinder-item--placeholder .ProductFinder-itemOption,
.ProductFinder-item--placeholder .ProductFinder-itemOptionLine {
  opacity: 0;
}
.ProductFinder .CardsSlider {
  overflow: hidden;
}
.ProductFinder .CardsSlider.productFinderMobile {
  overflow: visible;
}
.ProductFinder .CardsSlider-objects {
  padding: 0;
}
.ProductFinder .CardsSlider-objectsArrowRight,
.ProductFinder .CardsSlider-objectsArrowLeft {
  display: none;
}
.ProductFinder .CardsSlider-objectsInner {
  position: unset;
}
@media (max-width: 759px) {
  .ProductFinder .CardsSlider-objectsInner {
    margin-left: 6.25vw;
  }
}
.ProductFinder .CardsSlider-objects.show .CardsSlider-object {
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .ProductFinder .CardsSlider-objects {
    margin-top: 0;
    padding-right: 6.25vw;
    margin-left: -9.375vw;
  }
  .ProductFinder .CardsSlider-objects .ProductFinder-itemOptionTitle {
    opacity: 0;
  }
}
.ProductFinder .CardsSlider-object {
  overflow: visible;
  position: unset;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ProductFinder .CardsSlider-object:last-of-type {
  margin-right: 0;
}
.ProductFinder-arrowRight,
.ProductFinder-arrowLeft,
.ProductFinder-arrowRight_mobile,
.ProductFinder-arrowLeft_mobile {
  background: center / cover url('/static/img/arrow-card-slider.svg') no-repeat;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 4;
  background: center / cover url('/static/img/arrow-slider-dark.svg') no-repeat;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 759px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    height: 9.375vw;
    width: 6.5625vw;
    top: 70.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width: 3.38709677vw;
    height: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width: 2.91666667vw;
    height: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width: 56px;
    height: 80px;
  }
}
.ProductFinder-arrowRight[data-tp="prev"],
.ProductFinder-arrowLeft[data-tp="prev"],
.ProductFinder-arrowRight_mobile[data-tp="prev"],
.ProductFinder-arrowLeft_mobile[data-tp="prev"] {
  background: center / cover url('/static/img/arrow-card-slider-left.svg') no-repeat;
}
.isDesktop .ProductFinder-arrowRight:hover,
.isDesktop .ProductFinder-arrowLeft:hover,
.isDesktop .ProductFinder-arrowRight_mobile:hover,
.isDesktop .ProductFinder-arrowLeft_mobile:hover,
.isNotDesktop .ProductFinder-arrowRight:active,
.isNotDesktop .ProductFinder-arrowLeft:active,
.isNotDesktop .ProductFinder-arrowRight_mobile:active,
.isNotDesktop .ProductFinder-arrowLeft_mobile:active {
  opacity: 0.7;
}
.ProductFinder-arrowRight--hidden,
.ProductFinder-arrowLeft--hidden,
.ProductFinder-arrowRight_mobile--hidden,
.ProductFinder-arrowLeft_mobile--hidden {
  opacity: 0 !important;
  cursor: default;
}
@media (min-width: 1920px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width:  29.333333333333332px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width:  1.527777777777778vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width:  1.7741935483870968vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-arrowRight,
  .ProductFinder-arrowLeft,
  .ProductFinder-arrowRight_mobile,
  .ProductFinder-arrowLeft_mobile {
    width:  3.125vw;
    height:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductFinder-arrowRight {
    right:  22.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-arrowRight {
    right:  1.1805555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-arrowRight {
    right:  1.3709677419354838vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-arrowRight {
    right:  3.125vw;
  }
}
.ProductFinder-arrowLeft {
  -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
          transform: scaleX(-1);
}
@media (min-width: 1920px) {
  .ProductFinder-arrowLeft {
    left:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-arrowLeft {
    left:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-arrowLeft {
    left:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-arrowLeft {
    left:  6.25vw;
  }
}
.ProductFinder-arrowLeft_mobile {
  -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
          transform: scaleX(-1);
}
@media (min-width: 1920px) {
  .ProductFinder-arrowLeft_mobile {
    left:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-arrowLeft_mobile {
    left:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-arrowLeft_mobile {
    left:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-arrowLeft_mobile {
    left:  0.9375vw;
  }
}
@media (min-width: 1920px) {
  .ProductFinder-arrowRight_mobile {
    right:  22.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFinder-arrowRight_mobile {
    right:  1.1805555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFinder-arrowRight_mobile {
    right:  1.3709677419354838vw;
  }
}
@media (max-width: 759px) {
  .ProductFinder-arrowRight_mobile {
    right:  1.5625vw;
  }
}
