@media (min-width: 1920px) {
  .InspirationCaseGallery {
    padding-bottom: 106.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery {
    padding-bottom: 5.55555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery {
    padding-bottom: 6.4516129vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery {
    padding-bottom: 12.5vw;
  }
}
.InspirationCaseGallery-imageBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .InspirationCaseGallery-imageBlock {
    display: block;
  }
}
.InspirationCaseGallery-image {
  position: relative;
}
.isDesktop .InspirationCaseGallery-image:hover .InspirationCaseGallery-imageDownload {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
}
.isDesktop .InspirationCaseGallery-image:hover .InspirationCaseGallery-imageDownload:hover {
  background-color: #FFFFFF;
}
.isDesktop .InspirationCaseGallery-image:hover .InspirationCaseGallery-imageDownload:hover svg path#rect {
  fill: #111112;
}
.isDesktop .InspirationCaseGallery-image:hover .InspirationCaseGallery-imageDownload:hover svg path#arrow {
  stroke: #111112;
}
.isNotDesktop .InspirationCaseGallery-image:active .InspirationCaseGallery-imageDownload {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
}
.isNotDesktop .InspirationCaseGallery-image:active .InspirationCaseGallery-imageDownload:active {
  background-color: #FFFFFF;
}
.isNotDesktop .InspirationCaseGallery-image:active .InspirationCaseGallery-imageDownload:active svg path#rect {
  fill: #111112;
}
.isNotDesktop .InspirationCaseGallery-image:active .InspirationCaseGallery-imageDownload:active svg path#arrow {
  stroke: #111112;
}
.InspirationCaseGallery-image.video {
  cursor: pointer;
}
.InspirationCaseGallery-image.video .InspirationCaseGallery-imageDownload {
  display: none;
}
.isDesktop .InspirationCaseGallery-image.video:hover .InspirationCaseGallery-videoPlay,
.isNotDesktop .InspirationCaseGallery-image.video:active .InspirationCaseGallery-videoPlay {
  opacity: 0.7;
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 26.66666667px);
            flex: 0 0 calc(50% - 26.66666667px);
    margin-top: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 1.38888889vw);
            flex: 0 0 calc(50% - 1.38888889vw);
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 1.61290323vw);
            flex: 0 0 calc(50% - 1.61290323vw);
    margin-top: 3.22580645vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-top: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-image:nth-child(2) {
    margin-left: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-image:nth-child(2) {
    margin-left: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-image:nth-child(2) {
    margin-left: 3.22580645vw;
  }
}
.InspirationCaseGallery-image:only-child {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
.InspirationCaseGallery-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
.InspirationCaseGallery-imageDownload {
  position: absolute;
  background-color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-imageDownload {
    width: 53.33333333px;
    height: 53.33333333px;
    top: 26.66666667px;
    right: 26.66666667px;
  }
  .InspirationCaseGallery-imageDownload svg {
    width: 21.33333333px;
    height: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-imageDownload {
    width: 2.77777778vw;
    height: 2.77777778vw;
    top: 1.38888889vw;
    right: 1.38888889vw;
  }
  .InspirationCaseGallery-imageDownload svg {
    width: 1.11111111vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-imageDownload {
    width: 3.22580645vw;
    height: 3.22580645vw;
    top: 1.61290323vw;
    right: 1.61290323vw;
  }
  .InspirationCaseGallery-imageDownload svg {
    width: 1.29032258vw;
    height: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-imageDownload {
    display: none;
  }
}
.InspirationCaseGallery-imageDownload svg path#rect {
  fill: #FFFFFF;
}
.InspirationCaseGallery-imageDownload svg path#arrow {
  stroke: #FFFFFF;
}
.InspirationCaseGallery-downloadAll {
  position: relative;
  margin: 0 auto;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-transform: uppercase;
}
.InspirationCaseGallery-downloadAll a {
  border: 1px solid transparent;
}
.isDesktop .InspirationCaseGallery-downloadAll:hover svg path#rect,
.isNotDesktop .InspirationCaseGallery-downloadAll:active svg path#rect {
  fill: #111112;
}
.isDesktop .InspirationCaseGallery-downloadAll:hover svg path#arrow,
.isNotDesktop .InspirationCaseGallery-downloadAll:active svg path#arrow {
  stroke: #111112;
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-downloadAll {
    margin-top: 53.33333333px;
    width: 373.33333333px;
    height: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-downloadAll {
    margin-top: 2.77777778vw;
    width: 19.44444444vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-downloadAll {
    margin-top: 3.22580645vw;
    width: 22.58064516vw;
    height: 3.22580645vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-downloadAll {
    margin-top: 11.25vw;
    height: 12.5vw;
    width: 100%;
  }
}
.InspirationCaseGallery-downloadAll span {
  width: 100%;
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-downloadAll span {
    margin-left: 40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-downloadAll span {
    margin-left: 2.08333333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-downloadAll span {
    margin-left: 2.41935484vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-downloadAll span {
    margin-left: 9.375vw;
  }
}
.InspirationCaseGallery-downloadAll svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.InspirationCaseGallery-downloadAll svg path#rect {
  fill: #FFFFFF;
}
.InspirationCaseGallery-downloadAll svg path#arrow {
  stroke: #FFFFFF;
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-downloadAll svg {
    width: 21.33333333px;
    height: 26.66666667px;
    margin-left: 16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-downloadAll svg {
    width: 1.11111111vw;
    height: 1.38888889vw;
    margin-left: 0.83333333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-downloadAll svg {
    width: 1.29032258vw;
    height: 1.61290323vw;
    margin-left: 0.96774194vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-downloadAll svg {
    width: 5vw;
    height: 6.25vw;
    margin-left: 3.75vw;
  }
}
.InspirationCaseGallery-videoPlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  background-color: #FFFFFF;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-videoPlay {
    width: 160px;
    height: 106.66666667px;
    left: 53.33333333px;
    bottom: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-videoPlay {
    width: 8.33333333vw;
    height: 5.55555556vw;
    left: 2.77777778vw;
    bottom: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-videoPlay {
    width: 9.67741935vw;
    height: 6.4516129vw;
    left: 3.22580645vw;
    bottom: 3.22580645vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-videoPlay {
    width: 18.75vw;
    height: 12.5vw;
    left: 50%;
    top: 50%;
    bottom: unset;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
@media (min-width: 1920px) {
  .InspirationCaseGallery-videoPlay svg {
    width: 42.66666667px;
    height: 42.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseGallery-videoPlay svg {
    width: 2.22222222vw;
    height: 2.22222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseGallery-videoPlay svg {
    width: 2.58064516vw;
    height: 2.58064516vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseGallery-videoPlay svg {
    width: 5.625vw;
    height: 5.625vw;
  }
}
