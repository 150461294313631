.InspirationCaseRelated {
  border-top: 1px solid #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
.InspirationCaseRelated:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
  z-index: 2;
}
@media (max-width: 759px) {
  .InspirationCaseRelated {
    margin-top: 10vw;
  }
}
.InspirationCaseRelated-item {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
  height: 0;
  padding-bottom: 148%;
}
.isDesktop .InspirationCaseRelated-item:hover,
.isNotDesktop .InspirationCaseRelated-item:active {
  background-color: #F6F6F6;
}
.isDesktop .InspirationCaseRelated-item:hover .InspirationCaseRelated-itemTitle,
.isNotDesktop .InspirationCaseRelated-item:active .InspirationCaseRelated-itemTitle {
  text-decoration: underline;
}
.InspirationCaseRelated-item:visited {
  text-decoration: none;
}
.InspirationCaseRelated-item.discontinued {
  pointer-events: none;
}
.InspirationCaseRelated-item.discontinued .InspirationCaseRelated-itemTitle,
.InspirationCaseRelated-item.discontinued .InspirationCaseRelated-itemDiscontinued {
  color: #A6A8B3;
}
.InspirationCaseRelated-itemWrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  border-bottom: 1px solid #E7E7E7;
}
@media (min-width: 1920px) {
  .InspirationCaseRelated-itemWrapper {
    padding: 26.66666667px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseRelated-itemWrapper {
    padding: 1.38888889vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseRelated-itemWrapper {
    padding: 1.61290323vw 0;
  }
}
@media (max-width: 759px) {
  .InspirationCaseRelated-itemWrapper {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    padding: 6.25vw 0;
  }
}
.InspirationCaseRelated-itemImage {
  position: relative;
  width: 100%;
  display: block;
  height: 0;
  padding-bottom: 100%;
}
.InspirationCaseRelated-itemImage img {
  width: 100%;
}
.InspirationCaseRelated-itemTitle {
  color: #111112;
  text-align: center;
  display: block;
}
@media (min-width: 1920px) {
  .InspirationCaseRelated-itemTitle {
    margin-top: 5.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseRelated-itemTitle {
    margin-top: 0.27777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseRelated-itemTitle {
    margin-top: 0.32258065vw;
  }
}
.InspirationCaseRelated-itemDiscontinued {
  display: block;
  text-align: center;
}
