.ProductFamilyDownloads {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads {
    min-height:  960px;
    padding:  86.66666666666666px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads {
    min-height:  50vw;
    padding:  4.513888888888889vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads {
    min-height:  58.064516129032256vw;
    padding:  5.241935483870968vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDownloads {
    min-height:  225vw;
    padding:  20.3125vw 43.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDownloads {
    padding: 10.3125vw 6.25vw;
    min-height: 0;
  }
}
.ProductFamilyDownloads-contentBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-contentBlock {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-contentBlock {
    padding-bottom: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-contentBlock {
    padding-bottom: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-contentBlock {
    padding-bottom: 133.33333333px;
  }
}
.ProductFamilyDownloads-title {
  color: #111112;
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-titleLinks {
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-titleLinks {
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-titleLinks {
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-titleLinks {
    margin-top:  18.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloads {
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-downloads {
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-downloads {
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-downloads {
    margin-top: 46.66666667px;
  }
}
.ProductFamilyDownloads-downloadsItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  letter-spacing: normal;
  color: #111112;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloadsItem {
    padding-bottom: 3.125vw;
    padding-top: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-downloadsItem {
    padding-bottom: 1.29032258vw;
    padding-top: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-downloadsItem {
    padding-bottom: 1.11111111vw;
    padding-top: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-downloadsItem {
    padding-bottom: 21.33333333px;
    padding-top: 18.66666667px;
  }
}
.ProductFamilyDownloads-downloadsItem:last-of-type {
  border-bottom-color: #FFFFFF;
}
.isDesktop .ProductFamilyDownloads-downloadsItem:hover,
.isNotDesktop .ProductFamilyDownloads-downloadsItem:active {
  border-bottom: 1px solid #111112;
}
.isDesktop .ProductFamilyDownloads-downloadsItem:hover .ProductFamilyDownloads-downloadsItemLink svg,
.isNotDesktop .ProductFamilyDownloads-downloadsItem:active .ProductFamilyDownloads-downloadsItemLink svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ProductFamilyDownloads-downloadsItem:visited {
  text-decoration: none;
}
.ProductFamilyDownloads-downloadsItemTitle {
  color: #111112;
  text-decoration: none;
  text-transform: none;
}
.ProductFamilyDownloads-downloadsItemTitle:visited {
  color: #111112;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloadsItemTitle {
    font-size: 4.375vw;
    line-height: normal;
  }
}
.ProductFamilyDownloads-downloadsItemDownload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloadsItemDownload {
    width: 10.9375vw;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    padding-bottom: 0.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-downloadsItemDownload {
    width: 3.62903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-downloadsItemDownload {
    width: 3.125vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-downloadsItemDownload {
    width: 60px;
  }
}
.ProductFamilyDownloads-downloadsItemDownloadExtension {
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloadsItemDownloadExtension {
    font-size: 2.8125vw;
    line-height: normal;
  }
}
.ProductFamilyDownloads-downloadsItemDownloadIcon {
  position: relative;
  display: block;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloadsItemDownloadIcon {
    width: 3.75vw;
    height: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-downloadsItemDownloadIcon {
    width: 1.29032258vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-downloadsItemDownloadIcon {
    width: 1.11111111vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-downloadsItemDownloadIcon {
    width: 21.33333333px;
    height: 26.66666667px;
  }
}
.ProductFamilyDownloads-downloadsItemDownloadIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ProductFamilyDownloads-downloadsItemLink {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-downloadsItemLink {
    width:  40px;
    height:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-downloadsItemLink {
    width:  2.0833333333333335vw;
    height:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-downloadsItemLink {
    width:  2.4193548387096775vw;
    height:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-downloadsItemLink {
    width:  9.375vw;
    height:  6.25vw;
  }
}
.ProductFamilyDownloads-downloadsItemLink svg {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  -webkit-transform: translate3d(-10%, 0, 0);
          transform: translate3d(-10%, 0, 0);
}
.ProductFamilyDownloads-image {
  height: 100%;
  position: absolute;
  width: 40%;
  bottom: 0;
}
@media (max-width: 759px) {
  .ProductFamilyDownloads-image {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDownloads-image {
    right: 9.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDownloads-image {
    right: 8.33333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDownloads-image {
    right: 160px;
  }
}
.ProductFamilyDownloads-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
