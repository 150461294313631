.ProductFamilyVideos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #DEDEDE;
}
@media (max-width: 759px) {
  .ProductFamilyVideos {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 10.9375vw;
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyVideos {
    padding-bottom: 7.25806452vw;
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyVideos {
    padding-bottom: 6.25vw;
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyVideos {
    padding-bottom: 120px;
    margin-top: 46.66666667px;
  }
}
.ProductFamilyVideos-titleBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          flex: 0 0 35%;
}
.ProductFamilyVideos-title {
  color: #111112;
}
.ProductFamilyVideos-contentBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 65%;
          flex: 0 0 65%;
}
@media (max-width: 759px) {
  .ProductFamilyVideos-contentBlock {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 14.0625vw;
  }
}
.ProductFamilyVideos-video {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
  cursor: pointer;
}
.ProductFamilyVideos-video:only-of-type {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
.ProductFamilyVideos-video .Video {
  margin-top: 0;
}
.ProductFamilyVideos-video:nth-child(1),
.ProductFamilyVideos-video:nth-child(2) {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .ProductFamilyVideos-video {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyVideos-video {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyVideos-video {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyVideos-video {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyVideos-video {
    margin-bottom: 5vw;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    width: 100%;
    margin-top: 0;
  }
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyVideos-video:hover .ProductFamilyVideos-videoCover,
  .isMobile .ProductFamilyVideos-video:active .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
            box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ProductFamilyVideos-video:hover .ProductFamilyVideos-videoCover,
  .isMobile .ProductFamilyVideos-video:active .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.4);
            box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ProductFamilyVideos-video:hover .ProductFamilyVideos-videoCover,
  .isMobile .ProductFamilyVideos-video:active .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.4);
            box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 1920px) {
  .isDesktop .ProductFamilyVideos-video:hover .ProductFamilyVideos-videoCover,
  .isMobile .ProductFamilyVideos-video:active .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
            box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
  }
}
.isDesktop .ProductFamilyVideos-video:hover .ProductFamilyVideos-videoCover img,
.isMobile .ProductFamilyVideos-video:active .ProductFamilyVideos-videoCover img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.ProductFamilyVideos-videoCover {
  position: relative;
  -webkit-transition: 0.15s -webkit-box-shadow ease;
  transition: 0.15s -webkit-box-shadow ease;
  transition: 0.15s box-shadow ease;
  transition: 0.15s box-shadow ease, 0.15s -webkit-box-shadow ease;
  overflow: hidden;
}
@media (max-width: 759px) {
  .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.2);
            box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.3);
            box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.3);
            box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 1920px) {
  .ProductFamilyVideos-videoCover {
    -webkit-box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
            box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
  }
}
.ProductFamilyVideos-videoCover img {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyVideos-videoCover img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: 'object-fit: cover; object-position: center';
    width: 100%;
  }
}
.ProductFamilyVideos-videoPlayButton {
  background-color: #FFFFFF;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductFamilyVideos-videoPlayButton {
    width: 12.5vw;
    height: 8.125vw;
    bottom: 6.25vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyVideos-videoPlayButton {
    width: 4.83870968vw;
    height: 3.22580645vw;
    bottom: 1.61290323vw;
    left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyVideos-videoPlayButton {
    width: 4.16666667vw;
    height: 2.77777778vw;
    bottom: 1.38888889vw;
    left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyVideos-videoPlayButton {
    width: 80px;
    height: 53.33333333px;
    bottom: 26.66666667px;
    left: 26.66666667px;
  }
}
.ProductFamilyVideos-videoPlayButton svg {
  width: 50%;
  height: 50%;
}
@media (max-width: 759px) {
  .ProductFamilyVideos-videoDescription {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 1.25vw;
  }
}
.ProductFamilyVideos-videoTitle {
  color: #111112;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyVideos-videoTitle {
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyVideos-videoTitle {
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyVideos-videoTitle {
    margin-top: 20px;
  }
}
.ProductFamilyVideos-videoTiming {
  color: #6F7380;
}
