.History {
  min-height: 100vh;
}
@media (max-width: 759px) {
  .History {
    min-height: 0;
  }
}
.History-content {
  overflow: hidden;
}
@media (min-width: 1920px) {
  .History-content {
    padding: 210.66666667px 0 133.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-content {
    padding: 10.97222222vw 0 6.94444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-content {
    padding: 12.74193548vw 0 8.06451613vw;
  }
}
@media (max-width: 759px) {
  .History-content {
    padding: 0 0 11.25vw;
  }
}
.History-objects {
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.History-objects.show .History-object {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}
@media (min-width: 1920px) {
  .History-objects {
    margin: 0 0 0 -26.66666667px;
    padding-left: 186.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-objects {
    margin: 0 0 0 -1.38888889vw;
    padding-left: 9.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-objects {
    margin: 0 0 0 -1.61290323vw;
    padding-left: 11.29032258vw;
  }
}
@media (max-width: 759px) {
  .History-objects {
    margin: 12.5vw 0 0 -6.25vw;
    padding-left: 21.25vw;
  }
}
.History-objectsInner {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  will-change: transform;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
.History-objectsArrowRight,
.History-objectsArrowLeft {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
}
@media (min-width: 1920px) {
  .History-objectsArrowRight svg,
  .History-objectsArrowLeft svg {
    width: 26.66666667px;
    height: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-objectsArrowRight svg,
  .History-objectsArrowLeft svg {
    width: 1.38888889vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-objectsArrowRight svg,
  .History-objectsArrowLeft svg {
    width: 1.61290323vw;
    height: 3.22580645vw;
  }
}
@media (max-width: 759px) {
  .History-objectsArrowRight svg,
  .History-objectsArrowLeft svg {
    width: 4.6875vw;
    height: 7.8125vw;
  }
}
@media (min-width: 1920px) {
  .History-objectsArrowRight {
    right: 22.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-objectsArrowRight {
    right: 1.18055556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-objectsArrowRight {
    right: 1.37096774vw;
  }
}
@media (max-width: 759px) {
  .History-objectsArrowRight {
    right: 3.125vw;
  }
}
@media (min-width: 1920px) {
  .History-objectsArrowLeft {
    left: 58.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-objectsArrowLeft {
    left: 3.05555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-objectsArrowLeft {
    left: 3.5483871vw;
  }
}
@media (max-width: 759px) {
  .History-objectsArrowLeft {
    left: 9.375vw;
  }
}
.History-object {
  position: relative;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 5%, 0);
          transform: translate3d(0, 5%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s transform;
  transition: 0.4s opacity, 0.4s transform, 0.4s -webkit-transform;
}
.History-object:nth-child(20) {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
.History-object:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}
.History-object:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}
.History-object:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}
.History-object:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
.History-object:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.History-object:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.History-object:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.History-object:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.History-object:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.History-object:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.History-object:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
.History-object:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.History-object:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
.History-object:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.History-object:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.History-object:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.History-object:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.History-object:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.History-object:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
@media (min-width: 1920px) {
  .History-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 346.66666667px;
            flex: 0 0 346.66666667px;
    margin: 0 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 18.05555556vw;
            flex: 0 0 18.05555556vw;
    margin: 0 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.53225806vw;
            flex: 0 0 16.53225806vw;
    margin: 0 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .History-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 64.0625vw;
            flex: 0 0 64.0625vw;
    margin: 0 3.125vw;
  }
}
@media (max-width: 759px) {
  .History-object:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-object:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-object:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .History-object:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
.History-object img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
@media (max-width: 759px) {
  .History-object img {
    -webkit-box-shadow: 0 3.125vw 12.5vw 3.125vw rgba(0, 0, 0, 0.25);
            box-shadow: 0 3.125vw 12.5vw 3.125vw rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-object img {
    -webkit-box-shadow: 0 0.80645161vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.80645161vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-object img {
    -webkit-box-shadow: 0 0.69444444vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.69444444vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1920px) {
  .History-object img {
    -webkit-box-shadow: 0 13.33333333px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.5);
            box-shadow: 0 13.33333333px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.5);
  }
}
.History-objectTitle {
  color: #FFFFFF;
  will-change: transform;
  -webkit-transform: translate3d(-37%, 3%, 0) rotate(-90deg);
          transform: translate3d(-37%, 3%, 0) rotate(-90deg);
  line-height: 235%;
  letter-spacing: 0;
}
@media (min-width: 1920px) {
  .History-objectTitle {
    font-size: 160px;
    letter-spacing: -6.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-objectTitle {
    font-size: 8.33333333vw;
    letter-spacing: -0.34722222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-objectTitle {
    font-size: 9.67741935vw;
    -webkit-transform: translate3d(-33%, 10%, 0) rotate(-90deg);
            transform: translate3d(-33%, 10%, 0) rotate(-90deg);
    line-height: 250%;
    letter-spacing: -0.40322581vw;
  }
}
@media (max-width: 759px) {
  .History-objectTitle {
    font-size: 18.75vw;
    line-height: 18.75vw;
    letter-spacing: -1.5625vw;
    -webkit-transform: translate3d(-2%, 0, 0);
            transform: translate3d(-2%, 0, 0);
    margin: 3.125vw 0 3.125vw;
  }
}
.History-objectDescription {
  letter-spacing: normal;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .History-objectDescription {
    line-height:  26.666666666666664px;
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-objectDescription {
    line-height:  1.3888888888888888vw;
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-objectDescription {
    line-height:  1.6129032258064515vw;
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .History-objectDescription {
    line-height:  6.25vw;
    margin-top:  3.125vw;
  }
}
.History-navLines {
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none;
}
@media (max-width: 759px) {
  .History-navLines {
    bottom: -10.9375vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
  }
  .isDesktop .History-navLines {
    width: 96.25vw;
  }
}
.History-navLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .History-navLine {
    height: 9.375vw;
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-navLine {
    height: 2.41935484vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-navLine {
    height: 2.08333333vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .History-navLine {
    height: 40px;
    width: 77.33333333px;
  }
}
.isDesktop .History-navLine:hover:after,
.isNotDesktop .History-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .History-navLine:hover:after,
  .isNotDesktop .History-navLine:active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .History-navLine:hover:after,
  .isNotDesktop .History-navLine:active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .History-navLine:hover:after,
  .isNotDesktop .History-navLine:active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .History-navLine:hover:after,
  .isNotDesktop .History-navLine:active:after {
    height: 5.33333333px;
  }
}
.History-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.History-navLine.active {
  pointer-events: none;
}
.History-navLine.active:after {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .History-navLine.active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .History-navLine.active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .History-navLine.active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .History-navLine.active:after {
    height: 5.33333333px;
  }
}
.History-navLine:first-child {
  margin-left: 0;
}
.History.dark .History-objectTitle {
  color: #111112;
}
.History.dark .History-objectDescription {
  color: #111112;
}
