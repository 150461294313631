.IndexMission {
  position: relative;
  width: 100%;
}
.isIE .IndexMission {
  z-index: 4;
}
.IndexMission-wrapper {
  min-height: 100vh;
  margin: 0 auto;
  display: table;
}
@media (max-width: 759px) {
  .IndexMission-wrapper {
    width: 87.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-wrapper {
    width: 93.5483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-wrapper {
    width: 80.55555556vw;
  }
}
@media (min-width: 1920px) {
  .IndexMission-wrapper {
    width: 1546.66666667px;
  }
}
@media (max-width: 759px) {
  .IndexMission-wrapper p {
    margin-bottom: 16.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-wrapper p {
    margin-bottom: 4.91935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-wrapper p {
    margin-bottom: 4.23611111vw;
  }
}
@media (min-width: 1920px) {
  .IndexMission-wrapper p {
    margin-bottom: 81.33333333px;
  }
}
.IndexMission-wrapper p span:nth-of-type(20) {
  -webkit-transition: 300ms opacity ease 1330ms, 300ms -webkit-transform ease 1330ms;
  transition: 300ms opacity ease 1330ms, 300ms -webkit-transform ease 1330ms;
  transition: 300ms opacity ease 1330ms, 300ms transform ease 1330ms;
  transition: 300ms opacity ease 1330ms, 300ms transform ease 1330ms, 300ms -webkit-transform ease 1330ms;
}
.IndexMission-wrapper p span:nth-of-type(19) {
  -webkit-transition: 300ms opacity ease 1260ms, 300ms -webkit-transform ease 1260ms;
  transition: 300ms opacity ease 1260ms, 300ms -webkit-transform ease 1260ms;
  transition: 300ms opacity ease 1260ms, 300ms transform ease 1260ms;
  transition: 300ms opacity ease 1260ms, 300ms transform ease 1260ms, 300ms -webkit-transform ease 1260ms;
}
.IndexMission-wrapper p span:nth-of-type(18) {
  -webkit-transition: 300ms opacity ease 1190ms, 300ms -webkit-transform ease 1190ms;
  transition: 300ms opacity ease 1190ms, 300ms -webkit-transform ease 1190ms;
  transition: 300ms opacity ease 1190ms, 300ms transform ease 1190ms;
  transition: 300ms opacity ease 1190ms, 300ms transform ease 1190ms, 300ms -webkit-transform ease 1190ms;
}
.IndexMission-wrapper p span:nth-of-type(17) {
  -webkit-transition: 300ms opacity ease 1120ms, 300ms -webkit-transform ease 1120ms;
  transition: 300ms opacity ease 1120ms, 300ms -webkit-transform ease 1120ms;
  transition: 300ms opacity ease 1120ms, 300ms transform ease 1120ms;
  transition: 300ms opacity ease 1120ms, 300ms transform ease 1120ms, 300ms -webkit-transform ease 1120ms;
}
.IndexMission-wrapper p span:nth-of-type(16) {
  -webkit-transition: 300ms opacity ease 1050ms, 300ms -webkit-transform ease 1050ms;
  transition: 300ms opacity ease 1050ms, 300ms -webkit-transform ease 1050ms;
  transition: 300ms opacity ease 1050ms, 300ms transform ease 1050ms;
  transition: 300ms opacity ease 1050ms, 300ms transform ease 1050ms, 300ms -webkit-transform ease 1050ms;
}
.IndexMission-wrapper p span:nth-of-type(15) {
  -webkit-transition: 300ms opacity ease 980ms, 300ms -webkit-transform ease 980ms;
  transition: 300ms opacity ease 980ms, 300ms -webkit-transform ease 980ms;
  transition: 300ms opacity ease 980ms, 300ms transform ease 980ms;
  transition: 300ms opacity ease 980ms, 300ms transform ease 980ms, 300ms -webkit-transform ease 980ms;
}
.IndexMission-wrapper p span:nth-of-type(14) {
  -webkit-transition: 300ms opacity ease 910ms, 300ms -webkit-transform ease 910ms;
  transition: 300ms opacity ease 910ms, 300ms -webkit-transform ease 910ms;
  transition: 300ms opacity ease 910ms, 300ms transform ease 910ms;
  transition: 300ms opacity ease 910ms, 300ms transform ease 910ms, 300ms -webkit-transform ease 910ms;
}
.IndexMission-wrapper p span:nth-of-type(13) {
  -webkit-transition: 300ms opacity ease 840ms, 300ms -webkit-transform ease 840ms;
  transition: 300ms opacity ease 840ms, 300ms -webkit-transform ease 840ms;
  transition: 300ms opacity ease 840ms, 300ms transform ease 840ms;
  transition: 300ms opacity ease 840ms, 300ms transform ease 840ms, 300ms -webkit-transform ease 840ms;
}
.IndexMission-wrapper p span:nth-of-type(12) {
  -webkit-transition: 300ms opacity ease 770ms, 300ms -webkit-transform ease 770ms;
  transition: 300ms opacity ease 770ms, 300ms -webkit-transform ease 770ms;
  transition: 300ms opacity ease 770ms, 300ms transform ease 770ms;
  transition: 300ms opacity ease 770ms, 300ms transform ease 770ms, 300ms -webkit-transform ease 770ms;
}
.IndexMission-wrapper p span:nth-of-type(11) {
  -webkit-transition: 300ms opacity ease 700ms, 300ms -webkit-transform ease 700ms;
  transition: 300ms opacity ease 700ms, 300ms -webkit-transform ease 700ms;
  transition: 300ms opacity ease 700ms, 300ms transform ease 700ms;
  transition: 300ms opacity ease 700ms, 300ms transform ease 700ms, 300ms -webkit-transform ease 700ms;
}
.IndexMission-wrapper p span:nth-of-type(10) {
  -webkit-transition: 300ms opacity ease 630ms, 300ms -webkit-transform ease 630ms;
  transition: 300ms opacity ease 630ms, 300ms -webkit-transform ease 630ms;
  transition: 300ms opacity ease 630ms, 300ms transform ease 630ms;
  transition: 300ms opacity ease 630ms, 300ms transform ease 630ms, 300ms -webkit-transform ease 630ms;
}
.IndexMission-wrapper p span:nth-of-type(9) {
  -webkit-transition: 300ms opacity ease 560ms, 300ms -webkit-transform ease 560ms;
  transition: 300ms opacity ease 560ms, 300ms -webkit-transform ease 560ms;
  transition: 300ms opacity ease 560ms, 300ms transform ease 560ms;
  transition: 300ms opacity ease 560ms, 300ms transform ease 560ms, 300ms -webkit-transform ease 560ms;
}
.IndexMission-wrapper p span:nth-of-type(8) {
  -webkit-transition: 300ms opacity ease 490ms, 300ms -webkit-transform ease 490ms;
  transition: 300ms opacity ease 490ms, 300ms -webkit-transform ease 490ms;
  transition: 300ms opacity ease 490ms, 300ms transform ease 490ms;
  transition: 300ms opacity ease 490ms, 300ms transform ease 490ms, 300ms -webkit-transform ease 490ms;
}
.IndexMission-wrapper p span:nth-of-type(7) {
  -webkit-transition: 300ms opacity ease 420ms, 300ms -webkit-transform ease 420ms;
  transition: 300ms opacity ease 420ms, 300ms -webkit-transform ease 420ms;
  transition: 300ms opacity ease 420ms, 300ms transform ease 420ms;
  transition: 300ms opacity ease 420ms, 300ms transform ease 420ms, 300ms -webkit-transform ease 420ms;
}
.IndexMission-wrapper p span:nth-of-type(6) {
  -webkit-transition: 300ms opacity ease 350ms, 300ms -webkit-transform ease 350ms;
  transition: 300ms opacity ease 350ms, 300ms -webkit-transform ease 350ms;
  transition: 300ms opacity ease 350ms, 300ms transform ease 350ms;
  transition: 300ms opacity ease 350ms, 300ms transform ease 350ms, 300ms -webkit-transform ease 350ms;
}
.IndexMission-wrapper p span:nth-of-type(5) {
  -webkit-transition: 300ms opacity ease 280ms, 300ms -webkit-transform ease 280ms;
  transition: 300ms opacity ease 280ms, 300ms -webkit-transform ease 280ms;
  transition: 300ms opacity ease 280ms, 300ms transform ease 280ms;
  transition: 300ms opacity ease 280ms, 300ms transform ease 280ms, 300ms -webkit-transform ease 280ms;
}
.IndexMission-wrapper p span:nth-of-type(4) {
  -webkit-transition: 300ms opacity ease 210ms, 300ms -webkit-transform ease 210ms;
  transition: 300ms opacity ease 210ms, 300ms -webkit-transform ease 210ms;
  transition: 300ms opacity ease 210ms, 300ms transform ease 210ms;
  transition: 300ms opacity ease 210ms, 300ms transform ease 210ms, 300ms -webkit-transform ease 210ms;
}
.IndexMission-wrapper p span:nth-of-type(3) {
  -webkit-transition: 300ms opacity ease 140ms, 300ms -webkit-transform ease 140ms;
  transition: 300ms opacity ease 140ms, 300ms -webkit-transform ease 140ms;
  transition: 300ms opacity ease 140ms, 300ms transform ease 140ms;
  transition: 300ms opacity ease 140ms, 300ms transform ease 140ms, 300ms -webkit-transform ease 140ms;
}
.IndexMission-wrapper p span:nth-of-type(2) {
  -webkit-transition: 300ms opacity ease 70ms, 300ms -webkit-transform ease 70ms;
  transition: 300ms opacity ease 70ms, 300ms -webkit-transform ease 70ms;
  transition: 300ms opacity ease 70ms, 300ms transform ease 70ms;
  transition: 300ms opacity ease 70ms, 300ms transform ease 70ms, 300ms -webkit-transform ease 70ms;
}
.IndexMission-wrapper p span:nth-of-type(1) {
  -webkit-transition: 300ms opacity ease 0ms, 300ms -webkit-transform ease 0ms;
  transition: 300ms opacity ease 0ms, 300ms -webkit-transform ease 0ms;
  transition: 300ms opacity ease 0ms, 300ms transform ease 0ms;
  transition: 300ms opacity ease 0ms, 300ms transform ease 0ms, 300ms -webkit-transform ease 0ms;
}
.IndexMission-wrapper p span {
  position: relative;
  display: inline-block;
}
.IndexMission-wrapper a {
  position: relative;
  display: inline-block;
  -webkit-transition: 300ms opacity ease, 300ms -webkit-transform ease;
  transition: 300ms opacity ease, 300ms -webkit-transform ease;
  transition: 300ms opacity ease, 300ms transform ease;
  transition: 300ms opacity ease, 300ms transform ease, 300ms -webkit-transform ease;
}
.IndexMission-cell {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 759px) {
  .IndexMission-cell {
    padding: 31.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-cell {
    padding: 8.06451613vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-cell {
    padding: 6.94444444vw 0;
  }
}
@media (min-width: 1920px) {
  .IndexMission-cell {
    padding: 133.33333333px 0;
  }
}
.IndexMission-cell-text--intial span {
  opacity: 0;
}
@media (max-width: 759px) {
  .IndexMission-cell-text--intial span {
    -webkit-transform: translateY(7.5vw);
        -ms-transform: translateY(7.5vw);
            transform: translateY(7.5vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-cell-text--intial span {
    -webkit-transform: translateY(1.93548387vw);
        -ms-transform: translateY(1.93548387vw);
            transform: translateY(1.93548387vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-cell-text--intial span {
    -webkit-transform: translateY(1.66666667vw);
        -ms-transform: translateY(1.66666667vw);
            transform: translateY(1.66666667vw);
  }
}
@media (min-width: 1920px) {
  .IndexMission-cell-text--intial span {
    -webkit-transform: translateY(32px);
        -ms-transform: translateY(32px);
            transform: translateY(32px);
  }
}
.IndexMission-cell-link--intial {
  opacity: 0;
}
@media (max-width: 759px) {
  .IndexMission-cell-link--intial {
    -webkit-transform: translateY(7.5vw);
        -ms-transform: translateY(7.5vw);
            transform: translateY(7.5vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-cell-link--intial {
    -webkit-transform: translateY(1.93548387vw);
        -ms-transform: translateY(1.93548387vw);
            transform: translateY(1.93548387vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-cell-link--intial {
    -webkit-transform: translateY(1.66666667vw);
        -ms-transform: translateY(1.66666667vw);
            transform: translateY(1.66666667vw);
  }
}
@media (min-width: 1920px) {
  .IndexMission-cell-link--intial {
    -webkit-transform: translateY(32px);
        -ms-transform: translateY(32px);
            transform: translateY(32px);
  }
}
.IndexMission-arrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .IndexMission-arrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-arrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-arrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .IndexMission-arrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .IndexMission-arrow:hover,
.isNotDesktop .IndexMission-arrow:active {
  text-decoration: underline;
}
.isDesktop .IndexMission-arrow:hover:before,
.isNotDesktop .IndexMission-arrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .IndexMission-arrow:hover:before,
  .isNotDesktop .IndexMission-arrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .IndexMission-arrow {
    letter-spacing: 0;
  }
}
.IndexMission-arrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .IndexMission-arrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .IndexMission-arrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .IndexMission-arrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexMission-arrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexMission-arrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexMission-arrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
