.CardsSlider-objects {
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.CardsSlider-objects.show .CardsSlider-object {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}
@media (min-width: 1920px) {
  .CardsSlider-objects {
    margin: 0 0 0 -26.66666667px;
    padding-left: 186.66666667px;
    padding-right: 186.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-objects {
    margin: 0 0 0 -1.38888889vw;
    padding-left: 9.72222222vw;
    padding-right: 9.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-objects {
    margin: 0 0 0 -1.61290323vw;
    padding-left: 11.29032258vw;
    padding-right: 11.29032258vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-objects {
    margin: 12.5vw 0 0 -6.25vw;
    padding-left: 21.25vw;
    padding-right: 21.25vw;
  }
}
.CardsSlider-objectsInner {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  will-change: transform;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
.CardsSlider-objectsArrowRight,
.CardsSlider-objectsArrowLeft {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
}
@media (min-width: 1920px) {
  .CardsSlider-objectsArrowRight svg,
  .CardsSlider-objectsArrowLeft svg {
    width: 26.66666667px;
    height: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-objectsArrowRight svg,
  .CardsSlider-objectsArrowLeft svg {
    width: 1.38888889vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-objectsArrowRight svg,
  .CardsSlider-objectsArrowLeft svg {
    width: 1.61290323vw;
    height: 3.22580645vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-objectsArrowRight svg,
  .CardsSlider-objectsArrowLeft svg {
    width: 4.6875vw;
    height: 7.8125vw;
  }
}
@media (min-width: 1920px) {
  .CardsSlider-objectsArrowRight {
    right: 22.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-objectsArrowRight {
    right: 1.18055556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-objectsArrowRight {
    right: 1.37096774vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-objectsArrowRight {
    right: 3.125vw;
  }
}
@media (min-width: 1920px) {
  .CardsSlider-objectsArrowLeft {
    left: 49.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-objectsArrowLeft {
    left: 2.56944444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-objectsArrowLeft {
    left: 2.98387097vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-objectsArrowLeft {
    left: 6.25vw;
  }
}
.CardsSlider-object {
  position: relative;
  opacity: 0;
  -webkit-transform: translate3d(0, 5%, 0);
          transform: translate3d(0, 5%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s transform;
  transition: 0.4s opacity, 0.4s transform, 0.4s -webkit-transform;
}
.CardsSlider-object:nth-child(20) {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
.CardsSlider-object:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}
.CardsSlider-object:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}
.CardsSlider-object:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}
.CardsSlider-object:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
.CardsSlider-object:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.CardsSlider-object:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.CardsSlider-object:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.CardsSlider-object:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.CardsSlider-object:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.CardsSlider-object:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.CardsSlider-object:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
.CardsSlider-object:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.CardsSlider-object:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
.CardsSlider-object:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.CardsSlider-object:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.CardsSlider-object:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.CardsSlider-object:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.CardsSlider-object:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.CardsSlider-object:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
@media (min-width: 1920px) {
  .CardsSlider-object {
    width: 346.66666667px;
    margin: 0 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object {
    width: 18.05555556vw;
    margin: 0 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object {
    width: 16.53225806vw;
    margin: 0 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object {
    width: 64.0625vw;
    margin: 0 3.125vw;
  }
  .isDesktop .CardsSlider-object {
    width: 64.0625vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CardsSlider-object:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
.CardsSlider-navLines {
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none;
}
@media (max-width: 759px) {
  .CardsSlider-navLines {
    bottom: -10.9375vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
  }
  .isDesktop .CardsSlider-navLines {
    width: 96.25vw;
  }
}
.CardsSlider-navLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .CardsSlider-navLine {
    height: 9.375vw;
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-navLine {
    height: 2.41935484vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-navLine {
    height: 2.08333333vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .CardsSlider-navLine {
    height: 40px;
    width: 77.33333333px;
  }
}
.isDesktop .CardsSlider-navLine:hover:after,
.isNotDesktop .CardsSlider-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .CardsSlider-navLine:hover:after,
  .isNotDesktop .CardsSlider-navLine:active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .CardsSlider-navLine:hover:after,
  .isNotDesktop .CardsSlider-navLine:active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .CardsSlider-navLine:hover:after,
  .isNotDesktop .CardsSlider-navLine:active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .CardsSlider-navLine:hover:after,
  .isNotDesktop .CardsSlider-navLine:active:after {
    height: 5.33333333px;
  }
}
.CardsSlider-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.CardsSlider-navLine.active {
  pointer-events: none;
}
.CardsSlider-navLine.active:after {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .CardsSlider-navLine.active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-navLine.active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-navLine.active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .CardsSlider-navLine.active:after {
    height: 5.33333333px;
  }
}
.CardsSlider-navLine:first-child {
  margin-left: 0;
}
.CardsSlider.dark .CardsSlider-objectTitle {
  color: #111112;
}
.CardsSlider.dark .CardsSlider-objectDescription {
  color: #111112;
}
