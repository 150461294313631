@media (max-width: 759px) {
  .AwardsPopup .Popup-close {
    background-color: #111112;
  }
  .AwardsPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.AwardsPopup-content {
  background: #FFFFFF;
  width: 100%;
}
.AwardsPopup-items {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .AwardsPopup-items {
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsPopup-items {
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsPopup-items {
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .AwardsPopup-items {
    padding-bottom:  12.5vw;
  }
}
.AwardsPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .AwardsPopup-titleWrapper {
    padding:  100px 133.33333333333331px 0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsPopup-titleWrapper {
    padding:  5.208333333333334vw 6.944444444444445vw 0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsPopup-titleWrapper {
    padding:  6.048387096774193vw 8.064516129032258vw 0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .AwardsPopup-titleWrapper {
    padding:  25vw 6.25vw 0vw;
  }
}
.AwardsPopup-description {
  line-height: 1;
  color: #6F7380;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .AwardsPopup-description {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsPopup-description {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsPopup-description {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .AwardsPopup-description {
    margin-top:  3.125vw;
  }
}
@media (min-width: 1920px) {
  .AwardsPopup-background {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsPopup-background {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsPopup-background {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .AwardsPopup-background {
    margin-top:  6.25vw;
  }
}
