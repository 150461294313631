.SearchResultNewsItem {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
@media (min-width: 1920px) {
  .SearchResultNewsItem {
    height:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem {
    height:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem {
    height:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem {
    height:  25vw;
  }
}
.SearchResultNewsItem a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.SearchResultNewsItem-month {
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.isDesktop .SearchResultNewsItem-month {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.SearchResultNewsItem-month span {
  display: none;
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-month {
    top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-month {
    top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-month {
    top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-month {
    top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-month {
    width: 10.9375vw;
    left: 0;
    top: 15vw;
    text-align: center;
  }
  .SearchResultNewsItem-month span {
    display: inline;
  }
}
.SearchResultNewsItem-year {
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.isDesktop .SearchResultNewsItem-year {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-year {
    top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-year {
    top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-year {
    top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-year {
    top:  18.75vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-year {
    width: 10.9375vw;
    left: 6.25vw;
    top: 15vw;
    text-align: center;
  }
}
.SearchResultNewsItem-day {
  position: absolute;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05em;
  color: #000000;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  -moz-text-stroke: 1px rgba(0, 0, 0, 0);
  text-stroke: 1px rgba(0, 0, 0, 0);
  -webkit-text-fill-color: #000000;
  -moz-text-fill-color: #000000;
  text-fill-color: #000000;
}
.isIE .SearchResultNewsItem-day {
  color: rgba(0, 0, 0, 0.5);
}
.isDesktop .SearchResultNewsItem-day {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-day {
    left:  53.33333333333333px;
    top:  32px;
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-day {
    left:  2.7777777777777777vw;
    top:  1.6666666666666667vw;
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-day {
    left:  3.225806451612903vw;
    top:  1.935483870967742vw;
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-day {
    left:  2.1875vw;
    top:  3.75vw;
    font-size:  9.375vw;
  }
}
.SearchResultNewsItem-title {
  position: absolute;
  overflow: hidden;
}
.isDesktop .SearchResultNewsItem-title {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-title[data-lines="1"] {
    top:  61.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-title[data-lines="1"] {
    top:  3.1944444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-title[data-lines="1"] {
    top:  3.7096774193548385vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-title[data-lines="1"] {
    top:  7.5vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-title[data-lines="2"] {
    top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-title[data-lines="2"] {
    top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-title[data-lines="2"] {
    top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-title[data-lines="2"] {
    top:  4.375vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-title[data-lines="3"] {
    top:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-title[data-lines="3"] {
    top:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-title[data-lines="3"] {
    top:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-title[data-lines="3"] {
    top:  2.5vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultNewsItem-title {
    left:  186.66666666666666px;
    right:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem-title {
    left:  9.722222222222223vw;
    right:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem-title {
    left:  11.29032258064516vw;
    right:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-title {
    left:  18.75vw;
    right:  0vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem-title {
    line-height: 1.42857143;
    letter-spacing: -0.00783333em;
  }
}
.SearchResultNewsItem:last-child {
  border-bottom: none;
}
.isDesktop .SearchResultNewsItem:hover .SearchResultNewsItem-day,
.SearchResultNewsItem:active .SearchResultNewsItem-day {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #000000;
  -moz-text-stroke: 1px #000000;
  text-stroke: 1px #000000;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
}
.isDesktop .SearchResultNewsItem:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.SearchResultNewsItem:active .SearchResultNewsItem-month,
.SearchResultNewsItem:active .SearchResultNewsItem-day,
.SearchResultNewsItem:active .SearchResultNewsItem-title {
  opacity: 0.6049;
}
.SearchResultNewsItem:active:before {
  opacity: 1;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .SearchResultNewsItem:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .isDesktop .SearchResultNewsItem:active:before {
    -webkit-transform: translate3d(2.5vw, 0, 0) !important;
            transform: translate3d(2.5vw, 0, 0) !important;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .SearchResultNewsItem:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .SearchResultNewsItem:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .SearchResultNewsItem:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.SearchResultNewsItem:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  background: url('/static/img/arrow-dark.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .SearchResultNewsItem:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (min-width: 1920px) {
  .SearchResultNewsItem:before {
    width:  34.666666666666664px;
    height:  24px;
    right:  10.666666666666666px;
    background-size:  34.666666666666664px 24px;
    margin-top:  -13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem:before {
    width:  1.8055555555555556vw;
    height:  1.25vw;
    right:  0.5555555555555556vw;
    background-size:  1.8055555555555556vw 1.25vw;
    margin-top:  -0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem:before {
    width:  2.096774193548387vw;
    height:  1.4516129032258065vw;
    right:  0.6451612903225806vw;
    background-size:  2.096774193548387vw 1.4516129032258065vw;
    margin-top:  -0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem:before {
    width:  8.125vw;
    height:  5.625vw;
    right:  2.5vw;
    background-size:  8.125vw 5.625vw;
    margin-top:  -3.125vw;
  }
}
@media (max-width: 759px) {
  .SearchResultNewsItem:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultNewsItem:before {
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultNewsItem:before {
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultNewsItem:before {
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
