@media (max-width: 759px) {
  .LayoutsDownloads-title {
    width: 100%;
    position: relative;
  }
}
@media (max-width: 759px) {
  .LayoutsDownloads-downloads {
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsDownloads-downloads {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsDownloads-downloads {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsDownloads-downloads {
    margin-top: 40px;
  }
}
.LayoutsDownloads-downloadsItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  letter-spacing: normal;
  color: #111112;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .LayoutsDownloads-downloadsItem {
    padding-bottom: 3.125vw;
    padding-top: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsDownloads-downloadsItem {
    padding-bottom: 1.29032258vw;
    padding-top: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsDownloads-downloadsItem {
    padding-bottom: 1.11111111vw;
    padding-top: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsDownloads-downloadsItem {
    padding-bottom: 21.33333333px;
    padding-top: 18.66666667px;
  }
}
.LayoutsDownloads-downloadsItem:last-of-type {
  border-bottom-color: #FFFFFF;
}
.LayoutsDownloads-downloadsItem:first-of-type {
  border-bottom-color: #E7E7E7;
}
.isDesktop .LayoutsDownloads-downloadsItem:hover,
.isNotDesktop .LayoutsDownloads-downloadsItem:active {
  border-bottom: 1px solid #111112;
}
.LayoutsDownloads-downloadsItem:visited {
  text-decoration: none;
}
.LayoutsDownloads-downloadsItemTitle {
  color: #111112;
  text-decoration: none;
  text-transform: none;
}
.LayoutsDownloads-downloadsItemTitle:visited {
  color: #111112;
}
@media (max-width: 759px) {
  .LayoutsDownloads-downloadsItemTitle {
    font-size: 4.375vw;
    line-height: normal;
    width: 70.3125vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.LayoutsDownloads-downloadsItemDownload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .LayoutsDownloads-downloadsItemDownload {
    width: 10.9375vw;
    padding-bottom: 0.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsDownloads-downloadsItemDownload {
    width: 3.62903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsDownloads-downloadsItemDownload {
    width: 3.125vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsDownloads-downloadsItemDownload {
    width: 60px;
  }
}
.LayoutsDownloads-downloadsItemDownloadExtension {
  text-decoration: none;
  color: #111112;
  margin-top: 4px;
}
@media (max-width: 759px) {
  .LayoutsDownloads-downloadsItemDownloadExtension {
    font-size: 2.8125vw;
    line-height: normal;
  }
}
.LayoutsDownloads-downloadsItemDownloadIcon {
  position: relative;
  display: block;
}
@media (max-width: 759px) {
  .LayoutsDownloads-downloadsItemDownloadIcon {
    width: 3.75vw;
    height: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsDownloads-downloadsItemDownloadIcon {
    width: 1.29032258vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsDownloads-downloadsItemDownloadIcon {
    width: 1.11111111vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsDownloads-downloadsItemDownloadIcon {
    width: 21.33333333px;
    height: 26.66666667px;
  }
}
.LayoutsDownloads-downloadsItemDownloadIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
