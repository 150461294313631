@media (max-width: 759px) {
  .PressDownloads-title {
    padding:  0vw 6.25vw;
  }
}
@media (min-width: 1920px) {
  .PressDownloads-filter {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PressDownloads-filter {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PressDownloads-filter {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .PressDownloads-filter {
    margin-top:  8.4375vw;
  }
}
@media (max-width: 759px) {
  .PressDownloads-cards {
    padding:  0vw 6.25vw;
  }
}
