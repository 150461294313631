.ContactTechnicalSupport {
  width: 100%;
  height: 100%;
}
.ContactTechnicalSupport-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactTechnicalSupport-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactTechnicalSupport-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactTechnicalSupport-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactTechnicalSupport-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactTechnicalSupport-text {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactTechnicalSupport-text {
    margin-top: 6.875vw;
    padding: 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactTechnicalSupport-text {
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactTechnicalSupport-text {
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactTechnicalSupport-text {
    margin-top: 29.33333333px;
  }
}
.ContactTechnicalSupport-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactTechnicalSupport-text a:hover,
.isNotDesktop .ContactTechnicalSupport-text a:active {
  text-decoration: none;
}
.ContactTechnicalSupport-text a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
