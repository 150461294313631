.ProductsMasterpieces {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
@media (max-width: 759px) {
  .ProductsMasterpieces {
    height: 100%;
  }
}
.ProductsMasterpieces-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-wrapper {
    height: 100%;
  }
}
.ProductsMasterpieces-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    height: 100%;
  }
}
.ProductsMasterpieces-headerTitle {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #111112;
  width: 50%;
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-headerTitle {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-headerTitle {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-headerTitle {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-headerTitle {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .ProductsMasterpieces-headerTitle {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-headerTitle {
    padding: 0 6.25vw;
    width: 100%;
    line-height: 2;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-headerTitle {
    padding-left: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-headerTitle {
    padding-left: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-headerTitle {
    padding-left: 186.66666667px;
  }
}
.ProductsMasterpieces-headerDescr {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  color: #6F7380;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-headerDescr {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-headerDescr {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-headerDescr {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-headerDescr {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-headerDescr {
    width: 100%;
    margin-top: 1.875vw;
    padding: 0 6.25vw;
    line-height: 1.42857143;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-headerDescr {
    width: 45.16129032vw;
    padding-left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-headerDescr {
    width: 38.88888889vw;
    padding-left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-headerDescr {
    width: 746.66666667px;
    padding-left: 26.66666667px;
  }
}
.ProductsMasterpieces-items {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-items {
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-items {
    margin-top: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-items {
    margin-top: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-items {
    margin-top: 66.66666667px;
  }
}
.ProductsMasterpieces-items-arrowLeft,
.ProductsMasterpieces-items-arrowRight {
  position: absolute;
  width: 6.25vw;
  height: 10.9375vw;
  top: 40%;
  cursor: pointer;
  z-index: 10;
  display: none;
}
@media (max-width: 759px) {
  .isDesktop .ProductsMasterpieces-items-arrowLeft,
  .isDesktop .ProductsMasterpieces-items-arrowRight {
    display: block;
  }
}
.isDesktop .ProductsMasterpieces-items-arrowLeft:hover svg,
.isDesktop .ProductsMasterpieces-items-arrowRight:hover svg,
.isNotDesktop .ProductsMasterpieces-items-arrowLeft:active svg,
.isNotDesktop .ProductsMasterpieces-items-arrowRight:active svg {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.ProductsMasterpieces-items-arrowLeft svg,
.ProductsMasterpieces-items-arrowRight svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.ProductsMasterpieces-items-arrowLeft {
  left: 3.125vw;
}
.ProductsMasterpieces-items-arrowRight {
  right: 3.125vw;
}
.ProductsMasterpieces-itemsWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemsWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
  }
  .isDesktop .ProductsMasterpieces-itemsWrapper {
    position: relative;
  }
}
.ProductsMasterpieces-itemsWrapperInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemsWrapperInner {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-transition: 0.5s -webkit-transform;
    transition: 0.5s -webkit-transform;
    transition: 0.5s transform;
    transition: 0.5s transform, 0.5s -webkit-transform;
  }
}
.ProductsMasterpieces-itemsNav {
  display: none;
  position: absolute;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0;
  left: 50%;
  width: 70%;
  z-index: 15;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemsNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.ProductsMasterpieces-itemsNavItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  height: 30px;
  cursor: pointer;
  position: relative;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemsNavItem {
    width: 11.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemsNavItem {
    width: 3.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemsNavItem {
    width: 2.63888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemsNavItem {
    width: 50.66666667px;
  }
}
.ProductsMasterpieces-itemsNavItem:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(17, 17, 18, 0.2);
}
.ProductsMasterpieces-itemsNavItem.active {
  pointer-events: none;
}
.ProductsMasterpieces-itemsNavItem.active:after {
  height: 4px;
  background: #111112;
}
.ProductsMasterpieces-itemsNavItem:first-child {
  margin-left: 0;
}
.ProductsMasterpieces-item {
  width: calc(50% - 1px);
  height: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  text-decoration: none;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-item {
    margin-left: 0;
    position: relative;
    top: 0;
    height: 100%;
    left: 0;
    z-index: 5;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 759px) {
  .isDesktop .ProductsMasterpieces-item {
    -webkit-transition: 0.5s opacity;
    transition: 0.5s opacity;
    opacity: 0;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    position: absolute;
  }
}
@media (max-width: 759px) {
  .isDesktop .ProductsMasterpieces-item.active {
    opacity: 1;
  }
  .isDesktop .ProductsMasterpieces-item.active img {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-animation: imageShow 0.5s linear forwards;
            animation: imageShow 0.5s linear forwards;
  }
  @-webkit-keyframes imageShow {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes imageShow {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.isDesktop .ProductsMasterpieces-item:hover .ProductsMasterpieces-itemBg,
.isNotDesktop .ProductsMasterpieces-item:active .ProductsMasterpieces-itemBg {
  opacity: 0.5;
}
.isDesktop .ProductsMasterpieces-item:hover .ProductsMasterpieces-itemInfoDescr,
.isNotDesktop .ProductsMasterpieces-item:active .ProductsMasterpieces-itemInfoDescr {
  color: #111112;
}
.ProductsMasterpieces-item:nth-child(2n + 1) {
  margin-left: 0;
}
.ProductsMasterpieces-item:last-child:nth-child(odd) {
  width: 100%;
  margin-left: 0;
}
.ProductsMasterpieces-item:last-child:nth-child(odd) .ProductsMasterpieces-itemInner {
  width: 100%;
}
.ProductsMasterpieces-item:last-child:nth-child(odd) .ProductsMasterpieces-itemImg {
  width: 25%;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-item:last-child:nth-child(odd) .ProductsMasterpieces-itemImg {
    width: 60%;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-item:last-child:nth-child(odd) {
    width: 100vw;
  }
}
.ProductsMasterpieces-itemWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.ProductsMasterpieces-itemInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInner {
    width: 100%;
  }
}
.ProductsMasterpieces-itemBg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: 1;
  background: -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), to(#2D3234));
  background: linear-gradient(to top, #FFFFFF, #2D3234);
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.ProductsMasterpieces-itemImg {
  width: 50%;
  position: relative;
  z-index: 2;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemImg {
    width: 60%;
  }
}
.ProductsMasterpieces-itemImg img {
  width: 100%;
}
.ProductsMasterpieces-itemInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  z-index: 2;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfo {
    width: 75vw;
    margin: 6.25vw auto 0;
    padding-bottom: 21.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfo {
    width: 27.33870968vw;
    margin: 4.19354839vw auto 0;
    padding-bottom: 5.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfo {
    width: 23.54166667vw;
    margin: 5.69444444vw auto 0;
    padding-bottom: 4.86111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfo {
    width: 452px;
    margin: 109.33333333px auto 0;
    padding-bottom: 93.33333333px;
  }
}
.ProductsMasterpieces-itemInfoName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  word-break: break-word;
  text-align: center;
}
.ProductsMasterpieces-itemInfoNameTitle {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  color: #111112;
  text-transform: none;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoNameTitle {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoNameTitle {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoNameTitle {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoNameTitle {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoNameTitle {
    font-size: 3.4375vw;
    letter-spacing: -0.04272727em;
    line-height: 1.81818182;
  }
}
.ProductsMasterpieces-itemInfoNameAuthor {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #111112;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    font-size: 16px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    margin-top: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    margin-top: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoNameAuthor {
    margin-top: 5.33333333px;
  }
}
.ProductsMasterpieces-itemInfoDescr {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #6F7380;
  text-align: center;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoDescr {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoDescr {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoDescr {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoDescr {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoDescr {
    margin: 5.625vw auto 0;
    width: 100%;
    line-height: 1.55555556;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoDescr {
    margin: 1.37096774vw auto 0;
    width: 24.19354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoDescr {
    margin: 1.18055556vw auto 0;
    width: 20.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoDescr {
    margin: 22.66666667px auto 0;
    width: 400px;
  }
}
.ProductsMasterpieces-itemInfoSoldout {
  border: 1px solid #111112;
  color: #111112;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  text-transform: uppercase;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  pointer-events: none;
  letter-spacing: normal;
  font-weight: normal;
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  z-index: 10;
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoSoldout {
    border-radius:  13.333333333333332px;
    font-size:  12px;
    max-width:  200px;
    padding:  6.666666666666666px 13.333333333333332px 5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoSoldout {
    border-radius:  0.6944444444444444vw;
    font-size:  0.625vw;
    max-width:  10.416666666666668vw;
    padding:  0.3472222222222222vw 0.6944444444444444vw 0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoSoldout {
    border-radius:  0.8064516129032258vw;
    font-size:  0.7258064516129032vw;
    max-width:  12.096774193548386vw;
    padding:  0.4032258064516129vw 0.8064516129032258vw 0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoSoldout {
    border-radius:  3.125vw;
    font-size:  2.8125vw;
    max-width:  46.875vw;
    padding:  1.5625vw 3.125vw 1.25vw;
  }
}
.brand--thorn .ProductsMasterpieces-itemInfoSoldout {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductsMasterpieces-itemInfoSoldout {
    bottom:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsMasterpieces-itemInfoSoldout {
    bottom:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsMasterpieces-itemInfoSoldout {
    bottom:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoSoldout {
    bottom:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ProductsMasterpieces-itemInfoSoldout {
    width: 25vw;
    height: 4.6875vw;
    font-size: 2.8125vw;
  }
}
