.GlobalMenuMobile {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}
@media (min-width: 1920px) {
  .GlobalMenuMobile {
    top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile {
    top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile {
    top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile {
    top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.GlobalMenuMobile-model {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  visibility: hidden;
  opacity: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .GlobalMenuMobile-model {
    height:  90.66666666666666px;
    padding:  0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile-model {
    height:  4.722222222222222vw;
    padding:  0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile-model {
    height:  5.483870967741935vw;
    padding:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile-model {
    height:  21.25vw;
    padding:  0vw 6.25vw;
  }
}
.GlobalMenuMobile-model.show {
  visibility: visible;
  opacity: 1;
}
.GlobalMenuMobile-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1920px) {
  .GlobalMenuMobile-dropdown {
    padding:  0 80px 0 26.666666666666664px;
    top:  24px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile-dropdown {
    padding:  0vw 4.166666666666667vw 0vw 1.3888888888888888vw;
    top:  1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile-dropdown {
    padding:  0vw 4.838709677419355vw 0vw 1.6129032258064515vw;
    top:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile-dropdown {
    padding:  0vw 18.75vw 0vw 6.25vw;
    top:  5.625vw;
  }
}
.GlobalMenuMobile-dropdown.show {
  visibility: visible;
  opacity: 1;
}
.GlobalMenuMobile-dropdownItem {
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  word-break: break-word;
}
@media (min-width: 1920px) {
  .GlobalMenuMobile-dropdownItem {
    padding:  9.333333333333332px 33.33333333333333px 10.666666666666666px 0;
    font-size:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile-dropdownItem {
    padding:  0.48611111111111116vw 1.7361111111111112vw 0.5555555555555556vw 0vw;
    font-size:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile-dropdownItem {
    padding:  0.564516129032258vw 2.0161290322580645vw 0.6451612903225806vw 0vw;
    font-size:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile-dropdownItem {
    padding:  2.1875vw 7.8125vw 2.5vw 0vw;
    font-size:  4.375vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile-dropdownItem {
    letter-spacing: 0;
  }
}
.GlobalMenuMobile-dropdownItem:before {
  content: '';
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
}
.isDesktop .GlobalMenuMobile-dropdownItem:hover .GlobalMenuMobile-itemLink:before,
.isNotDesktop .GlobalMenuMobile-dropdownItem:active .GlobalMenuMobile-itemLink:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .GlobalMenuMobile-dropdownItem:hover .GlobalMenuMobile-itemLink:before,
  .isNotDesktop .GlobalMenuMobile-dropdownItem:active .GlobalMenuMobile-itemLink:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.isDesktop .GlobalMenuMobile-dropdownItem:hover,
.isNotDesktop .GlobalMenuMobile-dropdownItem:active,
.GlobalMenuMobile-dropdownItem.active {
  color: transparent;
  -webkit-text-stroke: 1px #FFFFFF;
  -moz-text-stroke: 1px #FFFFFF;
  text-stroke: 1px #FFFFFF;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}
@media (max-width: 759px) {
  .isDesktop .GlobalMenuMobile-dropdownItem:hover,
  .isNotDesktop .GlobalMenuMobile-dropdownItem:active,
  .GlobalMenuMobile-dropdownItem.active {
    -webkit-text-stroke: 0.5px #FFFFFF;
    -moz-text-stroke: 0.5px #FFFFFF;
    text-stroke: 0.5px #FFFFFF;
  }
}
.isDesktop .GlobalMenuMobile-dropdownItem:hover:before,
.isNotDesktop .GlobalMenuMobile-dropdownItem:active:before {
  background-color: rgba(255, 255, 255, 0.1);
}
.GlobalMenuMobile-dropdownItem.active {
  cursor: default;
  pointer-events: none;
}
.GlobalMenuMobile-dropdownItem.active .GlobalMenuMobile-dropdownItemLink {
  display: none;
}
.GlobalMenuMobile-dropdownItem:last-of-type {
  border-bottom: none;
}
.GlobalMenuMobile-dropdownItem:visited {
  color: #FFFFFF;
}
.GlobalMenuMobile-dropdownItemLink {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  pointer-events: none;
  right: 0;
  top: 50%;
}
@media (min-width: 1920px) {
  .GlobalMenuMobile-dropdownItemLink {
    width:  46.666666666666664px;
    height:  24px;
    margin-top:  -12px;
    right:  5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile-dropdownItemLink {
    width:  2.430555555555556vw;
    height:  1.25vw;
    margin-top:  -0.625vw;
    right:  0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile-dropdownItemLink {
    width:  2.82258064516129vw;
    height:  1.4516129032258065vw;
    margin-top:  -0.7258064516129032vw;
    right:  0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile-dropdownItemLink {
    width:  8.125vw;
    height:  4.375vw;
    margin-top:  -2.1875vw;
    right:  1.25vw;
  }
}
.GlobalMenuMobile-dropdownItemLink:before {
  content: '';
  position: absolute;
  will-change: transform;
  background: url('/static/img/arrow-big.svg');
}
@media (max-width: 759px) {
  .GlobalMenuMobile-dropdownItemLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .GlobalMenuMobile-dropdownItemLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .GlobalMenuMobile-dropdownItemLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile-dropdownItemLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile-dropdownItemLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .GlobalMenuMobile-dropdownItemLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.GlobalMenuMobile-arrow {
  position: absolute;
  top: 50%;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .GlobalMenuMobile-arrow {
    width:  13.333333333333332px;
    height:  8px;
    right:  32px;
    margin-top:  -4px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenuMobile-arrow {
    width:  0.6944444444444444vw;
    height:  0.4166666666666667vw;
    right:  1.6666666666666667vw;
    margin-top:  -0.20833333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenuMobile-arrow {
    width:  0.8064516129032258vw;
    height:  0.4838709677419355vw;
    right:  1.935483870967742vw;
    margin-top:  -0.24193548387096775vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenuMobile-arrow {
    width:  3.125vw;
    height:  1.875vw;
    right:  7.5vw;
    margin-top:  -0.9375vw;
  }
}
.GlobalMenuMobile-arrow svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.GlobalMenuMobile-arrow.reverse {
  -webkit-transform: rotate(180deg) translate3d(0, 0, 0);
          transform: rotate(180deg) translate3d(0, 0, 0);
}
