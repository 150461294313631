.ContactPress {
  width: 100%;
  height: 100%;
}
.ContactPress-block {
  display: none;
}
@media (max-width: 759px) {
  .ContactPress-block {
    display: block;
    height: 6.25vw;
  }
}
.ContactPress-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactPress-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactPress-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactPress-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactPress-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactPress-text {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactPress-text {
    margin-top: 12.5vw;
    padding: 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-text {
    margin-bottom: 10.48387097vw;
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-text {
    margin-bottom: 9.02777778vw;
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-text {
    margin-bottom: 173.33333333px;
    margin-top: 29.33333333px;
  }
}
.ContactPress-infoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .ContactPress-infoWrapper {
    display: block;
    margin-bottom: 23.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-infoWrapper {
    margin-bottom: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-infoWrapper {
    margin-bottom: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-infoWrapper {
    margin-bottom: 80px;
  }
}
.ContactPress-infoWrapper:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .ContactPress-infoWrapper:not(:last-child):after {
    bottom: -15.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-infoWrapper:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-infoWrapper:not(:last-child):after {
    bottom: -1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-infoWrapper:not(:last-child):after {
    bottom: -33.33333333px;
  }
}
.ContactPress-title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #111112;
  font-weight: 600;
}
@media (max-width: 759px) {
  .ContactPress-title {
    width: 59.375vw;
    font-size: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-title {
    width: 15.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-title {
    width: 13.19444444vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-title {
    width: 253.33333333px;
  }
}
.ContactPress-info {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactPress-info {
    margin-top: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-info {
    margin-left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-info {
    margin-left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-info {
    margin-left: 26.66666667px;
  }
}
.ContactPress-info a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactPress-info a:hover,
.isNotDesktop .ContactPress-info a:active {
  text-decoration: none;
}
.ContactPress-info a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ContactPress-infoName {
  color: #111112;
  font-weight: bold;
}
@media (max-width: 759px) {
  .ContactPress-infoPosition {
    font-size: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-infoPosition {
    margin-top: 0.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-infoPosition {
    margin-top: 0.41666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-infoPosition {
    margin-top: 8px;
  }
}
@media (max-width: 759px) {
  .ContactPress-infoPhone {
    font-size: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-infoPhone {
    margin-top: 0.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-infoPhone {
    margin-top: 0.41666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-infoPhone {
    margin-top: 8px;
  }
}
@media (max-width: 759px) {
  .ContactPress-infoMail {
    font-size: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactPress-infoMail {
    margin-top: 0.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactPress-infoMail {
    margin-top: 0.41666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactPress-infoMail {
    margin-top: 8px;
  }
}
