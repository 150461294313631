@media (max-width: 759px) {
  .ProductDetailsPhotometry {
    padding: 6.25vw 6.25vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsPhotometry {
    padding: 0 9.67741935vw;
    margin-top: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsPhotometry {
    padding: 0 8.33333333vw;
    margin-top: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsPhotometry {
    padding: 0 160px;
    margin-top: 133.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsPhotometry-title {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.ProductDetailsPhotometry-arrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsPhotometry-arrowBack {
    display: block;
    position: absolute;
    left: -0.625vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ProductDetailsPhotometry-arrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ProductDetailsPhotometry-titleWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ProductDetailsPhotometry-downloadButton {
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .ProductDetailsPhotometry-downloadButton {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsPhotometry-downloadButton {
    width: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsPhotometry-downloadButton {
    width: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsPhotometry-downloadButton {
    width: 186.66666667px;
  }
}
.ProductDetailsPhotometry-downloadButtonMobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsPhotometry-downloadButtonMobile {
    display: block;
    width: 62.5vw;
    margin: 15vw auto 0;
    text-transform: uppercase;
  }
}
@media (max-width: 759px) {
  .ProductDetailsPhotometry-content {
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsPhotometry-content {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsPhotometry-content {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsPhotometry-content {
    margin-top: 53.33333333px;
  }
}
.ProductDetailsPhotometry-image {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.ProductDetailsPhotometry-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  position: absolute;
  height: 100%;
}
