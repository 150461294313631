@media (max-width: 759px) {
  .PageCompanyLanding:after {
    content: '';
    position: fixed;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    bottom: 0;
    height: 75vw;
    z-index: -1;
    width: 100%;
  }
}
.PageCompanyLanding .PageLanding-blockBg_mobile:before {
  content: '';
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.PageCompanyLanding-offer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  margin: auto 0;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-offer .TextWithLink {
    padding:  400px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-offer .TextWithLink {
    padding:  20.833333333333336vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-offer .TextWithLink {
    padding:  24.193548387096772vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-offer .TextWithLink {
    padding:  62.5vw 6.25vw 15.625vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-offerReports {
    padding:  400px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-offerReports {
    padding:  20.833333333333336vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-offerReports {
    padding:  24.193548387096772vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-offerReports {
    padding:  62.5vw 0vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annual {
    padding:  0 186.66666666666666px 133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annual {
    padding:  0vw 9.722222222222223vw 6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annual {
    padding:  0vw 11.29032258064516vw 8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annual {
    padding:  0vw 6.25vw 31.25vw;
  }
}
.PageCompanyLanding-annualImg {
  width: 100%;
  color: transparent;
  overflow: hidden;
  display: block;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
.PageCompanyLanding-annualImg img {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  width: 100%;
}
.isDesktop .PageCompanyLanding-annualImg:hover,
.isNotDesktop .PageCompanyLanding-annualImg:active {
  color: #111112;
}
.isDesktop .PageCompanyLanding-annualImg:hover img,
.isNotDesktop .PageCompanyLanding-annualImg:active img {
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .isDesktop .PageCompanyLanding-annualImg:hover,
  .isNotDesktop .PageCompanyLanding-annualImg:active {
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .PageCompanyLanding-annualImg:hover,
  .isNotDesktop .PageCompanyLanding-annualImg:active {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .PageCompanyLanding-annualImg:hover,
  .isNotDesktop .PageCompanyLanding-annualImg:active {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .PageCompanyLanding-annualImg:hover,
  .isNotDesktop .PageCompanyLanding-annualImg:active {
    -webkit-box-shadow:  0vw 3.125vw 12.5vw;
            box-shadow:  0vw 3.125vw 12.5vw;
  }
}
.PageCompanyLanding-annualContent {
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualContent {
    margin-top:  66.66666666666666px;
    padding-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualContent {
    margin-top:  3.4722222222222223vw;
    padding-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualContent {
    margin-top:  4.032258064516129vw;
    padding-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualContent {
    margin-top:  15.625vw;
    padding-top:  10.9375vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annual {
    display: none;
  }
}
.PageCompanyLanding-annualMobile {
  display: none;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobile {
    padding:  0 26.666666666666664px 266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobile {
    padding:  0vw 1.3888888888888888vw 13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobile {
    padding:  0vw 1.6129032258064515vw 16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobile {
    padding:  0vw 6.25vw 62.5vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobile {
    display: block;
  }
}
.PageCompanyLanding-annualMobileImg {
  width: 100%;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobileImg {
    margin-top:  22.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobileImg {
    margin-top:  1.1805555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobileImg {
    margin-top:  1.3709677419354838vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileImg {
    margin-top:  5.3125vw;
  }
}
.PageCompanyLanding-annualMobileTitle {
  color: #FFFFFF;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobileTitle {
    padding-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobileTitle {
    padding-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobileTitle {
    padding-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileTitle {
    padding-top:  3.125vw;
  }
}
.PageCompanyLanding-annualMobileText {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileText {
    margin-top: 3.125vw;
  }
}
.PageCompanyLanding-annualMobileLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobileLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobileLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobileLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageCompanyLanding-annualMobileLink:hover,
.isNotDesktop .PageCompanyLanding-annualMobileLink:active {
  text-decoration: underline;
}
.isDesktop .PageCompanyLanding-annualMobileLink:hover:before,
.isNotDesktop .PageCompanyLanding-annualMobileLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageCompanyLanding-annualMobileLink:hover:before,
  .isNotDesktop .PageCompanyLanding-annualMobileLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileLink {
    letter-spacing: 0;
  }
}
.PageCompanyLanding-annualMobileLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageCompanyLanding-annualMobileLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobileLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobileLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobileLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobileLink {
    margin-top:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobileLink {
    margin-top:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobileLink {
    margin-top:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileLink {
    margin-top:  7.5vw;
  }
}
.PageCompanyLanding-annualMobileLink:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-annualMobileContent {
    margin-top:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-annualMobileContent {
    margin-top:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-annualMobileContent {
    margin-top:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-annualMobileContent {
    margin-top:  7.5vw;
  }
}
.PageCompanyLanding-titleTextLink {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.PageCompanyLanding-titleTextLink:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  height: 1px;
  width: 100%;
  background-color: #FFFFFF;
}
.PageCompanyLanding-titleTextLinkTitle {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-titleTextLinkTitle {
    padding-top:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-titleTextLinkTitle {
    padding-top:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-titleTextLinkTitle {
    padding-top:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkTitle {
    padding-top:  7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-titleTextLinkTitle {
    font-size: 4.03225806vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkTitle {
    padding-top: 3.125vw;
  }
}
.PageCompanyLanding-titleTextLinkText {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-titleTextLinkText {
    margin-top:  40px;
    padding-bottom:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-titleTextLinkText {
    margin-top:  2.0833333333333335vw;
    padding-bottom:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-titleTextLinkText {
    margin-top:  2.4193548387096775vw;
    padding-bottom:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkText {
    margin-top:  9.375vw;
    padding-bottom:  15.625vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkText {
    margin-top: 6.25vw;
  }
}
.PageCompanyLanding-titleTextLinkLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-titleTextLinkLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-titleTextLinkLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-titleTextLinkLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageCompanyLanding-titleTextLinkLink:hover,
.isNotDesktop .PageCompanyLanding-titleTextLinkLink:active {
  text-decoration: underline;
}
.isDesktop .PageCompanyLanding-titleTextLinkLink:hover:before,
.isNotDesktop .PageCompanyLanding-titleTextLinkLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageCompanyLanding-titleTextLinkLink:hover:before,
  .isNotDesktop .PageCompanyLanding-titleTextLinkLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkLink {
    letter-spacing: 0;
  }
}
.PageCompanyLanding-titleTextLinkLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageCompanyLanding-titleTextLinkLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageCompanyLanding-titleTextLinkLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-titleTextLinkLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-titleTextLinkLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-titleTextLinkLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageCompanyLanding-titleTextLinkLink:visited {
  color: #FFFFFF;
}
.PageCompanyLanding-fullHeight {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-fullHeight {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-fullHeight {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-fullHeight {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-fullHeight {
    padding:  0vw 6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-globalPresence {
    padding:  266.66666666666663px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-globalPresence {
    padding:  13.88888888888889vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-globalPresence {
    padding:  16.129032258064516vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-globalPresence {
    padding:  62.5vw 6.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-twoColumns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .PageCompanyLanding-twoColumns > div {
    width: 100%;
  }
  .PageCompanyLanding-twoColumns > div:nth-child(1) {
    margin-top: 23.75vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-history {
    margin-top:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-history {
    margin-top:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-history {
    margin-top:  0vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-history {
    margin-top:  46.875vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-history .History {
    padding-top:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-history .History {
    padding-top:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-history .History {
    padding-top:  0vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-history .History {
    padding-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageCompanyLanding-history .History-content {
    padding-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-history .History-content {
    padding-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-history .History-content {
    padding-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-history .History-content {
    padding-top:  0vw;
  }
}
.PageCompanyLanding-historyTitle {
  color: #FFFFFF;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCompanyLanding-historyTitle {
    margin:  0 186.66666666666666px;
    padding-top:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyLanding-historyTitle {
    margin:  0vw 9.722222222222223vw;
    padding-top:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyLanding-historyTitle {
    margin:  0vw 11.29032258064516vw;
    padding-top:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding-historyTitle {
    margin:  0vw 6.25vw;
    padding-top:  7.5vw;
  }
}
