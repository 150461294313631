.Accordion {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 3px;
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .Accordion {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .Accordion {
    margin-top:  0vw;
  }
}
.Accordion.isLandingPage {
  background: transparent;
}
.Accordion.isLandingPage:after {
  display: none;
}
@media (min-width: 1920px) {
  .Accordion.isCommonBlock .Accordion-content {
    padding:  26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion.isCommonBlock .Accordion-content {
    padding:  1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion.isCommonBlock .Accordion-content {
    padding:  1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .Accordion.isCommonBlock .Accordion-content {
    padding:  6.25vw 0vw;
  }
}
.Accordion.isCommonBlock .Accordion-wrapper:first-child {
  padding-top: 0;
}
.Accordion.isCommonBlock .Accordion-title {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 90%;
          flex: 0 0 90%;
  line-height: 1.5;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate .Accordion .Accordion-content {
    padding:  26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate .Accordion .Accordion-content {
    padding:  1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate .Accordion .Accordion-content {
    padding:  1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate .Accordion .Accordion-content {
    padding:  6.25vw 0vw;
  }
}
.PageGlobalTemplate .Accordion .Accordion-wrapper:first-child {
  padding-top: 0;
}
.Accordion:after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: #FFFFFF;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  pointer-events: none;
}
@media (max-width: 759px) {
  .Accordion {
    background: none;
  }
}
.Accordion-icon {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
}
@media (max-width: 759px) {
  .Accordion-icon {
    width: 6.25vw;
    height: 6.25vw;
    right: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-icon {
    width: 1.61290323vw;
    height: 1.61290323vw;
    right: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-icon {
    width: 1.38888889vw;
    height: 1.38888889vw;
    right: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .Accordion-icon {
    width: 26.66666667px;
    height: 26.66666667px;
    right: 13.33333333px;
  }
}
.Accordion-iconItem {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  top: 50%;
  height: 2px;
  background: #111112;
  will-change: transform;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out, 0.15s -webkit-transform ease-out;
}
.isLandingPage .Accordion-iconItem {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .Accordion-iconItem {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-iconItem {
    width: 1.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-iconItem {
    width: 1.25vw;
  }
}
@media (min-width: 1920px) {
  .Accordion-iconItem {
    width: 24px;
  }
}
.Accordion-iconItem:first-child {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(90deg);
          transform: translate3d(-50%, -50%, 0) rotate(90deg);
}
.Accordion-wrapper {
  width: 100%;
  -webkit-transition: all linear 0.15s;
  transition: all linear 0.15s;
  opacity: 1;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #111112;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.isLandingPage .Accordion-wrapper {
  color: #FFFFFF;
}
.Accordion-wrapper:after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #E7E7E7;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  pointer-events: none;
}
.isLandingPage .Accordion-wrapper:after {
  background-color: rgba(255, 255, 255, 0.25);
}
.Accordion-wrapper:nth-last-child(2):after {
  background-color: transparent;
}
@media (max-width: 759px) {
  .Accordion-wrapper {
    padding: 5.3125vw 0 5.3125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-wrapper {
    padding: 1.53225806vw 0 1.69354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-wrapper {
    padding: 1.31944444vw 0 1.45833333vw;
  }
}
@media (min-width: 1920px) {
  .Accordion-wrapper {
    padding: 25.33333333px 0 28px;
  }
}
.isDesktop .isLandingPage .Accordion-wrapper:hover:after {
  background-color: #FFFFFF;
}
.isDesktop .Accordion-wrapper:hover,
.isNotDesktop .Accordion-wrapper:active {
  text-decoration: none;
}
.isDesktop .Accordion-wrapper:hover:after,
.isNotDesktop .Accordion-wrapper:active:after {
  background-color: #111112;
  z-index: 2;
}
.isLandingPage .isDesktop .Accordion-wrapper:hover:after,
.isLandingPage .isNotDesktop .Accordion-wrapper:active:after {
  background-color: #FFFFFF;
}
.isDesktop .Accordion-wrapper:hover .Accordion-subtitle,
.isNotDesktop .Accordion-wrapper:active .Accordion-subtitle {
  color: #111112;
}
.isLandingPage .isDesktop .Accordion-wrapper:hover .Accordion-subtitle,
.isLandingPage .isNotDesktop .Accordion-wrapper:active .Accordion-subtitle {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .Accordion-wrapper:hover .Accordion-iconItem,
  .isNotDesktop .Accordion-wrapper:active .Accordion-iconItem {
    width: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .Accordion-wrapper:hover .Accordion-iconItem,
  .isNotDesktop .Accordion-wrapper:active .Accordion-iconItem {
    width: 2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .Accordion-wrapper:hover .Accordion-iconItem,
  .isNotDesktop .Accordion-wrapper:active .Accordion-iconItem {
    width: 1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .Accordion-wrapper:hover .Accordion-iconItem,
  .isNotDesktop .Accordion-wrapper:active .Accordion-iconItem {
    width: 33.33333333px;
  }
}
.Accordion-wrapper.active .Accordion-iconItem:first-child {
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.Accordion-wrapper.active:after {
  opacity: 0;
}
.Accordion-titleWrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media (max-width: 759px) {
  .Accordion-titleWrapper {
    display: block;
  }
}
.Accordion-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
@media (max-width: 759px) {
  .Accordion-title {
    display: block;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    margin: auto 0;
  }
}
.Accordion-subtitle {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1;
  color: #6F7380;
}
@media (max-width: 759px) {
  .Accordion-subtitle {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-subtitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-subtitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .Accordion-subtitle {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) {
  .Accordion-subtitle {
    font-size: 3.4375vw;
  }
}
.Accordion-content {
  display: none;
  position: relative;
}
.Accordion-content:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #E7E7E7;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  pointer-events: none;
}
.isLandingPage .Accordion-content:last-child:after {
  display: none;
}
@media (min-width: 1920px) {
  .Accordion-content {
    padding:  26.666666666666664px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-content {
    padding:  1.3888888888888888vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-content {
    padding:  1.6129032258064515vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Accordion-content {
    padding:  6.25vw 3.125vw;
  }
}
@media (max-width: 759px) {
  .Accordion-content {
    padding: 0 0 6.25vw 0;
  }
}
@media (min-width: 1920px) {
  .Accordion-content .DownloadLinks {
    margin-top:  26.666666666666664px;
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-content .DownloadLinks {
    margin-top:  1.3888888888888888vw;
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-content .DownloadLinks {
    margin-top:  1.6129032258064515vw;
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .Accordion-content .DownloadLinks {
    margin-top:  0vw;
    margin-bottom:  0vw;
  }
}
.Accordion-accordionContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .Accordion-accordionContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .isLandingPage .Accordion-accordionContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.Accordion-accordionContentText {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
}
.isLandingPage .Accordion-accordionContentText {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
@media (max-width: 759px) {
  .isLandingPage .Accordion-accordionContentText {
    margin-top: 0;
  }
}
@media (max-width: 759px) {
  .Accordion-accordionContentText {
    margin-top: 6.25vw;
    font-size: 4.375vw;
  }
}
.Accordion-accordionContentText .CommonText-text {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
}
@media (max-width: 759px) {
  .Accordion-accordionContentText .CommonText-text {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-accordionContentText .CommonText-text {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-accordionContentText .CommonText-text {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .Accordion-accordionContentText .CommonText-text {
    font-size: 18.66666667px;
  }
}
.isLandingPage .Accordion-accordionContentText .CommonText-text {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .isLandingPage .Accordion-accordionContentText .CommonText-text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isLandingPage .Accordion-accordionContentText .CommonText-text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isLandingPage .Accordion-accordionContentText .CommonText-text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .isLandingPage .Accordion-accordionContentText .CommonText-text {
    font-size: 26.66666667px;
  }
}
.Accordion-accordionContentImage {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
.isLandingPage .Accordion-accordionContentImage {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
@media (max-width: 759px) and (min-width: 1920px) {
  .isLandingPage .Accordion-accordionContentImage {
    margin-top:  26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .isLandingPage .Accordion-accordionContentImage {
    margin-top:  1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .isLandingPage .Accordion-accordionContentImage {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isLandingPage .Accordion-accordionContentImage {
    margin-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .Accordion-link {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Accordion-link {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Accordion-link {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .Accordion-link {
    margin-top:  3.125vw;
  }
}
.Accordion:first-child {
  margin-top: 0;
}
