.NewsLandingHead {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .NewsLandingHead {
    padding:  0 186.66666666666666px 106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingHead {
    padding:  0vw 9.722222222222223vw 5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingHead {
    padding:  0vw 11.29032258064516vw 6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingHead {
    padding:  0vw 6.25vw 25vw;
  }
}
.NewsLandingHead-flexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (max-width: 759px) {
  .NewsLandingHead-flexSpacer {
    display: none;
  }
}
@media (min-width: 1920px) {
  .NewsLandingHead-title {
    margin-top:  248px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingHead-title {
    margin-top:  12.916666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingHead-title {
    margin-top:  15vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingHead-title {
    margin-top:  0vw;
  }
}
.NewsLandingHead-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #111112;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 2;
}
.NewsLandingHead-overlay.show {
  opacity: 0.8;
}
@media (max-width: 759px) {
  .NewsLandingHead .GlobalPageTitle {
    margin-top: 16vh;
  }
}
