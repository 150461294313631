.ProductDetailsCard {
  background-color: #E9EAEE;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.brand--thorn .ProductDetailsCard {
  background-color: #0D1B28;
  padding-bottom: 0;
}
@media (min-width: 1920px) {
  .isIE .ProductDetailsCard {
    top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isIE .ProductDetailsCard {
    top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isIE .ProductDetailsCard {
    top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .isIE .ProductDetailsCard {
    top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard.js-is-sticky {
    top:  26.666666666666664px;
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard.js-is-sticky {
    top:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard.js-is-sticky {
    top:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsCard.js-is-sticky {
    top:  6.25vw;
    left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard.js-is-sticky {
    width: calc(50% - 26.66666667px);
    height: calc(100% - 53.33333333px);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard.js-is-sticky {
    width: calc(50% - 1.38888889vw);
    height: calc(100% - 2.77777778vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard.js-is-sticky {
    width: calc(50% - 1.61290323vw);
    height: calc(100% - 3.22580645vw);
  }
}
.ProductDetailsCard.js-is-stuck {
  bottom: 0;
  position: absolute;
  width: 100%;
  top: auto;
}
@media (min-width: 1920px) {
  .ProductDetailsCard {
    min-height:  666.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard {
    min-height:  34.72222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard {
    min-height:  40.32258064516129vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsCard {
    min-height:  156.25vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsCard {
    height: auto;
    position: relative;
    min-height: unset;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard {
    height: calc(100vh - 3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard {
    height: calc(100vh - 2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard {
    height: calc(100vh - 53.33333333px);
  }
}
.ProductDetailsCard-img {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
@media (max-width: 759px) {
  .ProductDetailsCard-img {
    width: 55%;
    height: 55%;
  }
}
.ProductDetailsCard-imgWrapper {
  width: 100%;
  height: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}
@media (max-width: 759px) {
  .ProductDetailsCard-imgWrapper {
    width: 100vw;
    height: 100vw;
  }
}
.ProductDetailsCard-img img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
@media (max-width: 759px) {
  .ProductDetailsCard-details {
    padding: 0 6.25vw;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard-details {
    padding: 2.82258065vw 6.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard-details {
    padding: 2.43055556vw 5.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard-details {
    padding: 46.66666667px 109.33333333px;
  }
}
.ProductDetailsCard-buyWrapper {
  border-bottom: 1px solid #C6C6C6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductDetailsCard-buyWrapper {
    padding-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard-buyWrapper {
    padding-bottom: 1.37096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard-buyWrapper {
    padding-bottom: 1.18055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard-buyWrapper {
    padding-bottom: 22.66666667px;
  }
}
.brand--thorn .ProductDetailsCard-buyWrapper {
  border-color: #21384A;
}
.ProductDetailsCard-price {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  color: #111112;
  letter-spacing: 0;
}
.brand--thorn .ProductDetailsCard-price {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductDetailsCard-price {
    font-size: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard-price {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard-price {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard-price {
    font-size: 26.66666667px;
  }
}
.ProductDetailsCard-technicalDetailsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductDetailsCard-technicalDetailsWrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsCard-technicalDetailsWrapper {
    padding-top: 2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsCard-technicalDetailsWrapper {
    padding-top: 1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsCard-technicalDetailsWrapper {
    padding-top: 33.33333333px;
  }
}
.ProductDetailsCard-technicalDetail {
  display: block;
}
@media (max-width: 759px) {
  .ProductDetailsCard-technicalDetail {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    border-bottom: 1px solid #C6C6C6;
    padding-bottom: 4.0625vw;
    margin-bottom: 4.0625vw;
  }
  .ProductDetailsCard-technicalDetail:nth-child(3),
  .ProductDetailsCard-technicalDetail:nth-child(4) {
    border-bottom: 0;
  }
}
.ProductDetailsCard-technicalDetailTitle {
  color: #6F7380;
  text-transform: none;
  letter-spacing: normal;
}
.ProductDetailsCard-technicalDetailValue {
  color: #111112;
  text-transform: none;
  letter-spacing: normal;
}
.brand--thorn .ProductDetailsCard-technicalDetailValue {
  color: #FFFFFF;
}
