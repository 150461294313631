.PageNotFound-content {
  overflow: hidden;
  height: 100vh;
  z-index: 1;
  position: relative;
}
@media (max-width: 759px) {
  .PageNotFound-content {
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    min-height: stretch;
    max-height: -webkit-fill-available;
    max-height: -moz-available;
    max-height: stretch;
  }
}
.PageNotFound-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.PageNotFound-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (max-width: 759px) {
  .PageNotFound-background {
    display: none;
  }
}
.PageNotFound-background_mobile {
  display: none;
}
@media (max-width: 759px) {
  .PageNotFound-background_mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: block;
  }
  .PageNotFound-background_mobile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: 'object-fit: cover; object-position: center';
  }
}
.PageNotFound-contentWrapper {
  position: absolute;
  z-index: 1;
  top: 49%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (min-width: 1920px) {
  .PageNotFound-contentWrapper {
    width: 640px;
    right: 170.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageNotFound-contentWrapper {
    width: 33.33333333vw;
    right: 8.88888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageNotFound-contentWrapper {
    width: 38.70967742vw;
    right: 10.32258065vw;
  }
}
@media (max-width: 759px) {
  .PageNotFound-contentWrapper {
    padding: 0 6.25vw;
    top: 65%;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.PageNotFound-title {
  color: #FFFFFF;
  letter-spacing: 0;
}
@media (max-width: 759px) {
  .PageNotFound-title {
    text-align: center;
  }
}
.PageNotFound-text {
  color: #FFFFFF;
  letter-spacing: 0;
}
@media (min-width: 1920px) {
  .PageNotFound-text {
    margin-top: 42.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageNotFound-text {
    margin-top: 2.22222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageNotFound-text {
    margin-top: 2.58064516vw;
  }
}
@media (max-width: 759px) {
  .PageNotFound-text {
    text-align: center;
    font-size: 4.375vw;
    font-weight: 600;
    letter-spacing: -0.0875vw;
    margin-top: 6.25vw;
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    line-height: 6.25vw;
  }
  .PageNotFound-text br {
    display: none;
  }
}
.PageNotFound-text a {
  text-decoration: underline;
  color: #FFFFFF;
  cursor: pointer;
}
.PageNotFound-text a:visited {
  color: #FFFFFF;
}
.isDesktop .PageNotFound-text a:hover,
.isNotDesktop .PageNotFound-text a:active {
  text-decoration: none;
}
