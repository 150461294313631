.PageProducts-content {
  background: #F6F6F6;
}
.brand--thorn .PageProducts-content {
  background: #06121D;
}
@media (max-width: 759px) {
  .PageProducts-content {
    width: 100%;
  }
}
.PageProducts-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.PageProducts-filter {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .PageProducts-filter {
    padding-top: 20.3125vw;
    padding-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-filter {
    padding-top: 8.46774194vw;
    padding-bottom: 6.0483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-filter {
    padding-top: 7.29166667vw;
    padding-bottom: 5.20833333vw;
  }
}
@media (min-width: 1920px) {
  .PageProducts-filter {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}
.PageProducts-items {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.PageProducts-masterpieces {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .PageProducts-masterpieces {
    margin-top: 31.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-masterpieces {
    margin-top: 14.27419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-masterpieces {
    margin-top: 12.29166667vw;
  }
}
@media (min-width: 1920px) {
  .PageProducts-masterpieces {
    margin-top: 236px;
  }
}
.PageProducts-masterpieces.hide {
  display: none;
}
.PageProducts-marketing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  max-width: 100%;
}
@media (max-width: 759px) {
  .PageProducts-marketing {
    display: block;
    margin-top: 12.5vw;
  }
}
.PageProducts-marketingItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #FFFFFF;
  position: relative;
  margin-left: 2px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
}
.brand--thorn .PageProducts-marketingItem {
  background-color: #0E1C29;
}
.PageProducts-marketingItem:first-of-type {
  margin-left: 0;
}
@media (max-width: 759px) {
  .PageProducts-marketingItem {
    margin-left: 0;
  }
}
.isDesktop .PageProducts-marketingItem:hover .PageProducts-marketingItemLink:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (min-width: 1920px) {
  .PageProducts-marketingItem {
    min-height:  746.6666666666666px;
    padding-bottom:  101.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItem {
    min-height:  38.88888888888889vw;
    padding-bottom:  5.277777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItem {
    min-height:  40.32258064516129vw;
    padding-bottom:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItem {
    min-height:  0vw;
    padding-bottom:  7.8125vw;
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItem {
    margin-top: 2px;
  }
}
.PageProducts-marketingItemInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .PageProducts-marketingItemInfo {
    padding:  0 80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItemInfo {
    padding:  0vw 4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemInfo {
    padding:  0vw 4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItemInfo {
    padding:  0vw 6.25vw;
  }
}
.PageProducts-marketingItem[data-count="1"] .PageProducts-marketingItemImage {
  width: 40%;
  padding-bottom: 23%;
}
@media (max-width: 759px) {
  .PageProducts-marketingItem[data-count="1"] .PageProducts-marketingItemImage {
    width: 80%;
    padding-bottom: 50%;
  }
}
.PageProducts-marketingItem[data-count="2"] .PageProducts-marketingItemImage {
  width: 80%;
  padding-bottom: 45%;
}
@media (max-width: 759px) {
  .PageProducts-marketingItem[data-count="2"] .PageProducts-marketingItemImage {
    width: 80%;
    padding-bottom: 50%;
  }
}
.PageProducts-marketingItemImage {
  width: 100%;
  padding-bottom: 70%;
  height: 0;
  position: relative;
}
@media (max-width: 759px) {
  .PageProducts-marketingItemImage {
    width: 80%;
    padding-bottom: 50%;
  }
}
.PageProducts-marketingItemImage img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.PageProducts-marketingItemTitle {
  text-align: center;
}
.brand--thorn .PageProducts-marketingItemTitle {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageProducts-marketingItemTitle {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    letter-spacing: -0.04666667em;
    line-height: 1;
    text-transform: uppercase;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageProducts-marketingItemTitle {
    font-size:  40px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItemTitle {
    font-size:  2.0833333333333335vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemTitle {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageProducts-marketingItemTitle {
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemTitle {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.PageProducts-marketingItemDescription {
  color: #6F7380;
  text-align: center;
}
@media (min-width: 1920px) {
  .PageProducts-marketingItemDescription {
    margin-top:  26.666666666666664px;
    max-width:  480px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItemDescription {
    margin-top:  1.3888888888888888vw;
    max-width:  25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemDescription {
    margin-top:  1.6129032258064515vw;
    max-width:  29.032258064516128vw;
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItemDescription {
    margin-top:  3.125vw;
  }
}
.PageProducts-marketingItemLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .PageProducts-marketingItemLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItemLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageProducts-marketingItemLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageProducts-marketingItemLink:hover,
.isNotDesktop .PageProducts-marketingItemLink:active {
  text-decoration: underline;
}
.isDesktop .PageProducts-marketingItemLink:hover:before,
.isNotDesktop .PageProducts-marketingItemLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageProducts-marketingItemLink:hover:before,
  .isNotDesktop .PageProducts-marketingItemLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItemLink {
    letter-spacing: 0;
  }
}
.PageProducts-marketingItemLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageProducts-marketingItemLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageProducts-marketingItemLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageProducts-marketingItemLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItemLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageProducts-marketingItemLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageProducts-marketingItemLink {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProducts-marketingItemLink {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProducts-marketingItemLink {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItemLink {
    margin-top:  7.5vw;
  }
}
@media (max-width: 759px) {
  .PageProducts-marketingItemLink {
    line-height: 1;
  }
}
.brand--thorn .PageProducts-marketingItemLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .brand--thorn .PageProducts-marketingItemLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .PageProducts-marketingItemLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .PageProducts-marketingItemLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .brand--thorn .PageProducts-marketingItemLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .brand--thorn .PageProducts-marketingItemLink:hover,
.isNotDesktop .brand--thorn .PageProducts-marketingItemLink:active {
  text-decoration: underline;
}
.isDesktop .brand--thorn .PageProducts-marketingItemLink:hover:before,
.isNotDesktop .brand--thorn .PageProducts-marketingItemLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .brand--thorn .PageProducts-marketingItemLink:hover:before,
  .isNotDesktop .brand--thorn .PageProducts-marketingItemLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .brand--thorn .PageProducts-marketingItemLink {
    letter-spacing: 0;
  }
}
.brand--thorn .PageProducts-marketingItemLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .brand--thorn .PageProducts-marketingItemLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .brand--thorn .PageProducts-marketingItemLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .brand--thorn .PageProducts-marketingItemLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .PageProducts-marketingItemLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .PageProducts-marketingItemLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .brand--thorn .PageProducts-marketingItemLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageProducts-marketingItemUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
}
