.CommonListLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (min-width: 1920px) {
  .CommonListLinks {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonListLinks {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonListLinks {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CommonListLinks {
    margin-top:  9.375vw;
  }
}
@media (min-width: 1920px) {
  .CommonListLinks:first-child {
    margin-top:  8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonListLinks:first-child {
    margin-top:  0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonListLinks:first-child {
    margin-top:  0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CommonListLinks:first-child {
    margin-top:  1.875vw;
  }
}
.CommonListLinks-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .CommonListLinks-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonListLinks-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonListLinks-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CommonListLinks-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .CommonListLinks-link:hover,
.isNotDesktop .CommonListLinks-link:active {
  text-decoration: underline;
}
.isDesktop .CommonListLinks-link:hover:before,
.isNotDesktop .CommonListLinks-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CommonListLinks-link:hover:before,
  .isNotDesktop .CommonListLinks-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CommonListLinks-link {
    letter-spacing: 0;
  }
}
.CommonListLinks-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .CommonListLinks-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CommonListLinks-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CommonListLinks-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonListLinks-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonListLinks-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CommonListLinks-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CommonListLinks-link:first-child {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .CommonListLinks-link {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonListLinks-link {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonListLinks-link {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonListLinks-link {
    margin-top:  12.5vw;
  }
}
