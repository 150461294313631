@media (max-width: 759px) {
  .ProductDetailsSpecifications {
    padding: 6.25vw 6.25vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications {
    padding: 0 9.67741935vw;
    margin-top: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications {
    padding: 0 8.33333333vw;
    margin-top: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications {
    padding: 0 160px;
    margin-top: 133.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-title {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.ProductDetailsSpecifications-text {
  color: #6F7380;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-text {
    margin-bottom: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-text {
    margin-bottom: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-text {
    margin-bottom: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-text {
    margin-bottom: 46.66666667px;
  }
}
.ProductDetailsSpecifications-arrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-arrowBack {
    display: block;
    position: absolute;
    left: -0.625vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ProductDetailsSpecifications-arrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-content {
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-content {
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-content {
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-content {
    margin-top: 46.66666667px;
  }
}
.ProductDetailsSpecifications-contentItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #E7E7E7;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItem {
    display: block;
    padding-bottom: 3.75vw;
    padding-top: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-contentItem {
    padding-bottom: 1.29032258vw;
    padding-top: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-contentItem {
    padding-bottom: 1.11111111vw;
    padding-top: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-contentItem {
    padding-bottom: 21.33333333px;
    padding-top: 18.66666667px;
  }
}
.ProductDetailsSpecifications-contentItem:last-of-type {
  border-bottom: none;
}
.ProductDetailsSpecifications-contentItem.isDropdownItem {
  border-bottom: none;
  position: relative;
  padding-bottom: 0;
}
.ProductDetailsSpecifications-contentItem.isDropdownItem:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #E7E7E7;
  bottom: 0;
  left: 0;
}
.ProductDetailsSpecifications-contentItemName {
  color: #6F7380;
  letter-spacing: normal;
  text-transform: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemName {
    font-size: 3.75vw;
    line-height: normal;
  }
}
@media (max-width: 759px) {
  .isDropdownItem .ProductDetailsSpecifications-contentItemName {
    padding-bottom: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDropdownItem .ProductDetailsSpecifications-contentItemName {
    padding-bottom: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDropdownItem .ProductDetailsSpecifications-contentItemName {
    padding-bottom: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .isDropdownItem .ProductDetailsSpecifications-contentItemName {
    padding-bottom: 21.33333333px;
  }
}
.ProductDetailsSpecifications-contentItemValue {
  letter-spacing: normal;
  text-transform: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemValue {
    font-size: 3.75vw;
    margin-top: 2.5vw;
    line-height: normal;
  }
  .ProductDetailsSpecifications-contentItemValue a {
    pointer-events: none;
    text-decoration: none;
    color: #111112;
  }
}
.ProductDetailsSpecifications-contentItemDropdown {
  border-bottom: 1px solid #000000;
  z-index: 2;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  position: relative;
  cursor: pointer;
}
.ProductDetailsSpecifications-contentItemDropdown .Dropdown-select {
  height: 100%;
}
.ProductDetailsSpecifications-contentItemDropdown .Dropdown-selectModel:after,
.ProductDetailsSpecifications-contentItemDropdown .Dropdown-selectModel:before {
  height: 0;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemDropdown .Dropdown-selectModel {
    padding-bottom: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-contentItemDropdown .Dropdown-selectModel {
    padding-bottom: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-contentItemDropdown .Dropdown-selectModel {
    padding-bottom: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-contentItemDropdown .Dropdown-selectModel {
    padding-bottom: 21.33333333px;
  }
}
.ProductDetailsSpecifications-contentItemDropdownModel {
  letter-spacing: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemDropdownModel {
    font-size: 3.75vw;
    line-height: normal;
  }
}
.isDesktop .ProductDetailsSpecifications-contentItemDropdownModel:hover,
.isDesktop .ProductDetailsSpecifications-contentItemDropdownModel:active {
  opacity: 0.7;
}
.ProductDetailsSpecifications-contentItemDropdownModel:after {
  content: '';
  width: 0;
  height: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemDropdownModel:after {
    border-left: 1.5625vw solid transparent;
    border-right: 1.5625vw solid transparent;
    border-top: 1.875vw solid #111112;
    margin-left: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-contentItemDropdownModel:after {
    border-left: 0.40322581vw solid transparent;
    border-right: 0.40322581vw solid transparent;
    border-top: 0.48387097vw solid #111112;
    margin-left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-contentItemDropdownModel:after {
    border-left: 0.34722222vw solid transparent;
    border-right: 0.34722222vw solid transparent;
    border-top: 0.41666667vw solid #111112;
    margin-left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-contentItemDropdownModel:after {
    border-left: 6.66666667px solid transparent;
    border-right: 6.66666667px solid transparent;
    border-top: 8px solid #111112;
    margin-left: 13.33333333px;
  }
}
.ProductDetailsSpecifications-contentItemDropdownModel.active:after {
  -webkit-transform: rotate(180deg) translate3d(0, 0, 0);
          transform: rotate(180deg) translate3d(0, 0, 0);
}
.ProductDetailsSpecifications-contentItemDropdownOptions {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  width: 120%;
  left: -10%;
  background-color: #111112;
  border: 1px solid #E7E7E7;
  will-change: transform;
  -webkit-transition: 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
  transition: 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
  transition: 0.15s transform ease-in-out, 0.15s opacity ease-in-out;
  transition: 0.15s transform ease-in-out, 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -3.125vw, 0);
            transform: translate3d(0, -3.125vw, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -0.80645161vw, 0);
            transform: translate3d(0, -0.80645161vw, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -0.69444444vw, 0);
            transform: translate3d(0, -0.69444444vw, 0);
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -13.33333333px, 0);
            transform: translate3d(0, -13.33333333px, 0);
  }
}
.ProductDetailsSpecifications-contentItemDropdownOption {
  color: #FFFFFF;
  letter-spacing: normal;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 2.85714286;
  -webkit-transition: 0.15s background-color ease-out;
  transition: 0.15s background-color ease-out;
}
.ProductDetailsSpecifications-contentItemDropdownOption:first-child {
  border-top: 0;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-contentItemDropdownOption {
    line-height: 3.63636364;
    padding: 1.25vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-contentItemDropdownOption {
    padding: 0.32258065vw 1.61290323vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-contentItemDropdownOption {
    padding: 0.27777778vw 1.38888889vw 0;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-contentItemDropdownOption {
    padding: 5.33333333px 26.66666667px 0;
  }
}
.isDesktop .ProductDetailsSpecifications-contentItemDropdownOption:hover,
.isNotDesktop .ProductDetailsSpecifications-contentItemDropdownOption:active {
  background: #38383B;
}
.ProductDetailsSpecifications-contentItemDropdownModel.active + .ProductDetailsSpecifications-contentItemDropdownOptions {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ProductDetailsSpecifications-standards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-standards {
    margin-top: 9.375vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-standards {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-standards {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-standards {
    margin-top: 40px;
  }
}
.ProductDetailsSpecifications-standard {
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-standard {
    width: 10.9375vw;
    height: 10.9375vw;
    margin-right: 12.5vw;
  }
  .ProductDetailsSpecifications-standard:nth-child(4n) {
    margin-right: 0;
  }
  .ProductDetailsSpecifications-standard:nth-child(n + 5) {
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-standard {
    width: 2.82258065vw;
    height: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-standard {
    width: 2.43055556vw;
    height: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-standard {
    width: 46.66666667px;
    height: 46.66666667px;
  }
}
.ProductDetailsSpecifications-standard svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ProductDetailsSpecifications-footnote {
  text-transform: none;
  letter-spacing: normal;
}
@media (min-width: 1920px) {
  .ProductDetailsSpecifications-footnote {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsSpecifications-footnote {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsSpecifications-footnote {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsSpecifications-footnote {
    margin-top:  12.5vw;
  }
}
.ProductDetailsSpecifications-footnote p {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}
