.PendulumLamp {
  background-color: #FFD500;
  height: 100vh;
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 759px) {
  .PendulumLamp {
    height: 75vh;
  }
}
.PendulumLamp svg {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
}
.PendulumLamp canvas {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
