.InvestorsFinancialCalendar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.InvestorsFinancialCalendar-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.InvestorsFinancialCalendar-calendarItem {
  position: relative;
  background: #F6F6F6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1px;
  width: calc(100% / 3 - 2px);
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.InvestorsFinancialCalendar-calendarItem:before {
  content: '';
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  background-color: #ffffff;
}
a.InvestorsFinancialCalendar-calendarItem {
  cursor: pointer;
}
.isDesktop a.InvestorsFinancialCalendar-calendarItem:hover:before,
.isNotDesktop a.InvestorsFinancialCalendar-calendarItem:active:before {
  opacity: 0.7;
}
.isDesktop a.InvestorsFinancialCalendar-calendarItem:hover .InvestorsFinancialCalendar-calendarItemMoreInfo,
.isNotDesktop a.InvestorsFinancialCalendar-calendarItem:active .InvestorsFinancialCalendar-calendarItemMoreInfo {
  text-decoration: underline;
}
.isDesktop a.InvestorsFinancialCalendar-calendarItem:hover .InvestorsFinancialCalendar-calendarItemMoreInfo:before,
.isNotDesktop a.InvestorsFinancialCalendar-calendarItem:active .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop a.InvestorsFinancialCalendar-calendarItem:hover .InvestorsFinancialCalendar-calendarItemMoreInfo:before,
  .isNotDesktop a.InvestorsFinancialCalendar-calendarItem:active .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItem {
    width: calc(100% / 2 - 2px);
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-calendarItem {
    padding:  12px 17.333333333333332px 53.33333333333333px 25.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-calendarItem {
    padding:  0.625vw 0.9027777777777778vw 2.7777777777777777vw 1.3194444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-calendarItem {
    padding:  0.7258064516129032vw 1.0483870967741935vw 3.225806451612903vw 1.532258064516129vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItem {
    padding:  3.75vw 6.25vw 12.5vw;
  }
}
.InvestorsFinancialCalendar-calendarItemWrapper {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.InvestorsFinancialCalendar-calendarItemDate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.InvestorsFinancialCalendar-calendarItemDateDay {
  color: #6F7380;
}
.InvestorsFinancialCalendar-calendarItemDateMonth {
  color: #6F7380;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-calendarItemDateMonth {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-calendarItemDateMonth {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-calendarItemDateMonth {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemDateMonth {
    margin-top:  1.25vw;
  }
}
.InvestorsFinancialCalendar-calendarItemTitle {
  color: #6F7380;
  width: 100%;
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-calendarItemTitle {
    margin-top:  133.33333333333331px;
    height:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-calendarItemTitle {
    margin-top:  6.944444444444445vw;
    height:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-calendarItemTitle {
    margin-top:  8.064516129032258vw;
    height:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemTitle {
    margin-top:  11.5625vw;
    height:  25vw;
  }
}
.InvestorsFinancialCalendar-calendarItemMoreInfo {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #6F7380;
  cursor: pointer;
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    font-size: 18.66666667px;
  }
}
.isDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:hover,
.isNotDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:active {
  text-decoration: underline;
}
.isDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:hover:before,
.isNotDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:hover:before,
  .isNotDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    letter-spacing: 0;
  }
}
.InvestorsFinancialCalendar-calendarItemMoreInfo:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-gray.svg') no-repeat;
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    margin-top: 61.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    margin-top: 3.19444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    margin-top: 3.70967742vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-calendarItemMoreInfo {
    margin-top: 7.8125vw;
  }
}
.InvestorsFinancialCalendar-subscribe {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .InvestorsFinancialCalendar-subscribe {
    margin-top:  53.33333333333333px;
    width:  320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCalendar-subscribe {
    margin-top:  2.7777777777777777vw;
    width:  16.666666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCalendar-subscribe {
    margin-top:  3.225806451612903vw;
    width:  19.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-subscribe {
    margin-top:  12.5vw;
    width:  75vw;
  }
}
.InvestorsFinancialCalendar-subscribeWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (max-width: 759px) {
  .InvestorsFinancialCalendar-subscribeWrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
