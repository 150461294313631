.CardsSlider-objects {
  padding-left: 9.375vw;
  padding-right: 6.25vw;
}
@media (max-width: 759px) {
  .CardsSlider-objectsArrowLeft,
  .CardsSlider-objectsArrowRight {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
}
.CardsSlider-object.careers-landing-cards {
  height: auto;
  width: 81.25vw;
}
