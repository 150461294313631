@media (max-width: 759px) {
  .HistoryPopup .Popup-close {
    background-color: #111112;
  }
  .HistoryPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.HistoryPopup-content {
  background: #FFFFFF;
  width: 100%;
}
.HistoryPopup-items {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .HistoryPopup-items {
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .HistoryPopup-items {
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .HistoryPopup-items {
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .HistoryPopup-items {
    padding-bottom:  12.5vw;
  }
}
.HistoryPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.HistoryPopup-title {
  text-transform: none;
}
@media (min-width: 1920px) {
  .HistoryPopup-title {
    padding:  100px 133.33333333333331px 0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .HistoryPopup-title {
    padding:  5.208333333333334vw 6.944444444444445vw 0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .HistoryPopup-title {
    padding:  6.048387096774193vw 8.064516129032258vw 0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .HistoryPopup-title {
    padding:  25vw 6.25vw 0vw;
  }
}
@media (min-width: 1920px) {
  .HistoryPopup-image {
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .HistoryPopup-image {
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .HistoryPopup-image {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .HistoryPopup-image {
    margin-top:  9.375vw;
  }
}
