.CommonGallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
}
.CommonGallery-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 100%;
}
.CommonGallery-items {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 759px) {
  .CommonGallery-items {
    height: 62.5vw;
  }
}
.CommonGallery-items-arrowLeft,
.CommonGallery-items-arrowRight {
  position: absolute;
  width: 3.125vw;
  height: 10.9375vw;
  top: 40%;
  cursor: pointer;
  z-index: 10;
  display: none;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.isDesktop .CommonGallery-items-arrowLeft,
.isDesktop .CommonGallery-items-arrowRight {
  display: block;
}
.CommonGallery-items-arrowLeft svg,
.CommonGallery-items-arrowRight svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-filter: drop-shadow(0px 4px 10px #000000);
          filter: drop-shadow(0px 4px 10px #000000);
}
.CommonGallery-items-arrowLeft {
  left: 3.125vw;
}
.isDesktop .CommonGallery-items-arrowLeft:hover {
  -webkit-transform: translate3d(-10%, 0, 0);
          transform: translate3d(-10%, 0, 0);
}
.CommonGallery-items-arrowRight {
  right: 3.125vw;
}
.CommonGallery-items-arrowRight:hover {
  -webkit-transform: translate3d(10%, 0, 0);
          transform: translate3d(10%, 0, 0);
}
.CommonGallery-itemsWrapper {
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}
.CommonGallery-itemsWrapperInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
.isNotDesktop .CommonGallery-itemsWrapperInner {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
@media (max-width: 759px) {
  .CommonGallery-itemsWrapperInner {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    height: 100%;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonGallery-itemsWrapperInner {
    height: 51.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonGallery-itemsWrapperInner {
    height: 44.44444444vw;
  }
}
@media (min-width: 1920px) {
  .CommonGallery-itemsWrapperInner {
    height: 853.33333333px;
  }
}
.CommonGallery-itemsNav {
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
}
@media (max-width: 759px) {
  .CommonGallery-itemsNav {
    margin: 5px auto 0;
  }
}
.CommonGallery-itemsNavItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  cursor: pointer;
  position: relative;
}
@media (max-width: 759px) {
  .CommonGallery-itemsNavItem {
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonGallery-itemsNavItem {
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonGallery-itemsNavItem {
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .CommonGallery-itemsNavItem {
    width: 77.33333333px;
  }
}
.isDesktop .CommonGallery-itemsNavItem:hover:after,
.isNotDesktop .CommonGallery-itemsNavItem:active:after {
  height: 4px;
  background: #111112;
}
.CommonGallery-itemsNavItem:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(17, 17, 18, 0.2);
}
.CommonGallery-itemsNavItem.active {
  pointer-events: none;
}
.CommonGallery-itemsNavItem.active:after {
  height: 4px;
  background: #111112;
}
.CommonGallery-itemsNavItem:first-child {
  margin-left: 0;
}
.CommonGallery-item {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .CommonGallery-item {
    margin-left: 0;
    position: relative;
    top: 0;
    height: 100%;
    left: 0;
    z-index: 5;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.isDesktop .CommonGallery-item {
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  opacity: 0;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  position: absolute;
}
.isDesktop .CommonGallery-item.active {
  opacity: 1;
}
.isDesktop .CommonGallery-item.active img {
  -webkit-animation: imageShow 0.5s linear forwards;
          animation: imageShow 0.5s linear forwards;
}
@-webkit-keyframes imageShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes imageShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CommonGallery-item:nth-child(2n + 1) {
  margin-left: 0;
}
.CommonGallery-item:last-child:nth-child(odd) {
  width: 100%;
  margin-left: 0;
}
@media (max-width: 759px) {
  .CommonGallery-item:last-child:nth-child(odd) {
    width: 100vw;
  }
}
.CommonGallery-item:last-child:nth-child(odd) .CommonGallery-itemWrapper {
  padding-top: 38.88888889%;
}
@media (max-width: 759px) {
  .CommonGallery-item:last-child:nth-child(odd) .CommonGallery-itemWrapper {
    height: 100%;
    padding-top: 0;
  }
}
@media (max-width: 759px) {
  .CommonGallery-item:last-child:nth-child(odd) .CommonGallery-itemImg {
    padding-top: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonGallery-item:last-child:nth-child(odd) .CommonGallery-itemImg {
    height: 53.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonGallery-item:last-child:nth-child(odd) .CommonGallery-itemImg {
    height: 45.83333333vw;
  }
}
@media (min-width: 1920px) {
  .CommonGallery-item:last-child:nth-child(odd) .CommonGallery-itemImg {
    height: 880px;
  }
}
.CommonGallery-itemWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .CommonGallery-itemWrapper {
    height: 100%;
  }
}
.CommonGallery-itemInner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.CommonGallery-itemImg {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 2;
}
.CommonGallery-itemImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center';
}
@media (min-width: 1920px) {
  .CommonGallery-descriptionWrapper {
    margin-top:  26.666666666666664px;
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonGallery-descriptionWrapper {
    margin-top:  1.3888888888888888vw;
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonGallery-descriptionWrapper {
    margin-top:  1.6129032258064515vw;
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CommonGallery-descriptionWrapper {
    padding:  0vw 6.25vw;
    margin-top:  9.375vw;
  }
}
.CommonGallery-descriptionItem {
  display: none;
  color: #6F7380;
}
.CommonGallery-descriptionItem.active {
  display: block;
}
@media (max-width: 759px) {
  .CommonGallery-descriptionItem {
    text-align: center;
  }
}
