.ContactsPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1300;
  -webkit-transition: visibility 0.3s step-start;
  transition: visibility 0.3s step-start;
}
@media (min-width: 1920px) {
  .ContactsPopup {
    top:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup {
    top:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup {
    top:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup {
    top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup {
    z-index: 1000;
  }
}
.ContactsPopup--hide {
  visibility: hidden;
  -webkit-transition: visibility 0.3s step-end;
  transition: visibility 0.3s step-end;
}
@media (max-width: 759px) {
  .ContactsPopup--hide {
    position: fixed;
  }
}
.ContactsPopup--hide .ContactsPopup-bg {
  opacity: 0;
}
.ContactsPopup--hide .ContactsPopup-inputWrapper {
  opacity: 0;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0);
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0);
  -webkit-transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
}
@media (max-width: 759px) {
  .ContactsPopup--hide .ContactsPopup-inputWrapper {
    -webkit-transform: translateY(6.25vw);
        -ms-transform: translateY(6.25vw);
            transform: translateY(6.25vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup--hide .ContactsPopup-inputWrapper {
    -webkit-transform: translateY(3.22580645vw);
        -ms-transform: translateY(3.22580645vw);
            transform: translateY(3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup--hide .ContactsPopup-inputWrapper {
    -webkit-transform: translateY(2.77777778vw);
        -ms-transform: translateY(2.77777778vw);
            transform: translateY(2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .ContactsPopup--hide .ContactsPopup-inputWrapper {
    -webkit-transform: translateY(53.33333333px);
        -ms-transform: translateY(53.33333333px);
            transform: translateY(53.33333333px);
  }
}
.ContactsPopup--hide .ContactsPopup-close {
  opacity: 0;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup .Filters--regionalOffices,
  .ContactsPopup .Filters--qualified {
    width: 53.79032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup .Filters--regionalOffices,
  .ContactsPopup .Filters--qualified {
    width: 46.31944444vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup .Filters--regionalOffices,
  .ContactsPopup .Filters--qualified {
    width: 889.33333333px;
  }
}
.ContactsPopup-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(21, 25, 40, 0.5);
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
}
@media (max-width: 759px) {
  .ContactsPopup-bg {
    position: fixed;
    background: #F6F6F6;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-bg {
    top: -6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-bg {
    top: -5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-bg {
    top: -106.66666667px;
  }
}
.ContactsPopup-inputWrapper {
  position: relative;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  background: #FFFFFF;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 1920px) {
  .ContactsPopup-inputWrapper {
    padding:  0 306.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-inputWrapper {
    padding:  0vw 15.972222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-inputWrapper {
    padding:  0vw 10.483870967741936vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-inputWrapper {
    padding:  0vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-inputWrapper {
    -webkit-box-shadow: none;
            box-shadow: none;
    overflow-y: scroll;
    display: block;
  }
}
.ContactsPopup-titleWrapper {
  position: relative;
}
@media (min-width: 1920px) {
  .ContactsPopup-titleWrapper {
    padding-top:  60px;
    padding-left:  9.333333333333332px;
    margin-bottom:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-titleWrapper {
    padding-top:  3.125vw;
    padding-left:  0.48611111111111116vw;
    margin-bottom:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-titleWrapper {
    padding-top:  3.629032258064516vw;
    padding-left:  0.564516129032258vw;
    margin-bottom:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-titleWrapper {
    padding-top:  14.0625vw;
    padding-left:  2.1875vw;
    margin-bottom:  9.375vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-titleWrapper {
    background: #F6F6F6;
    margin-top: 0;
    margin-bottom: 0;
    padding: 9.375vw 6.25vw 0 6.25vw;
  }
}
.ContactsPopup-close {
  position: fixed;
  float: right;
  cursor: pointer;
  z-index: 99;
  opacity: 1;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
@media (max-width: 759px) {
  .isDesktop .ContactsPopup-close:hover .ContactsPopup-closeItem,
  .isNotDesktop .ContactsPopup-close:active .ContactsPopup-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ContactsPopup-close:hover .ContactsPopup-closeItem,
  .isNotDesktop .ContactsPopup-close:active .ContactsPopup-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ContactsPopup-close:hover .ContactsPopup-closeItem,
  .isNotDesktop .ContactsPopup-close:active .ContactsPopup-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .ContactsPopup-close:hover .ContactsPopup-closeItem,
  .isNotDesktop .ContactsPopup-close:active .ContactsPopup-closeItem {
    width: 42.66666667px;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-close {
    position: fixed;
    top: 14.0625vw;
    right: 2.1875vw;
    width: 8.125vw;
    height: 8.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-close {
    width: 3.06451613vw;
    height: 3.06451613vw;
    right: 0.56451613vw;
    top: 8.46774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-close {
    width: 2.63888889vw;
    height: 2.63888889vw;
    right: 0.48611111vw;
    top: 7.29166667vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-close {
    width: 50.66666667px;
    height: 50.66666667px;
    right: 9.33333333px;
    top: 140px;
  }
}
.ContactsPopup-closeItem {
  position: absolute;
  height: 2px;
  background: #111112;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.ContactsPopup-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.ContactsPopup-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (max-width: 759px) {
  .ContactsPopup-closeItem {
    height: 2.3px;
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-closeItem {
    width: 2.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-closeItem {
    width: 1.80555556vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-closeItem {
    width: 34.66666667px;
  }
}
.ContactsPopup-tabContent {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (min-width: 1920px) {
  .ContactsPopup-tabContent {
    padding-top:  173.33333333333331px;
    width:  746.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-tabContent {
    padding-top:  9.027777777777779vw;
    width:  38.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-tabContent {
    padding-top:  10.483870967741936vw;
    width:  45.16129032258064vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-tabContent {
    padding-top:  40.625vw;
    width:  175vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-tabContent {
    padding-top: 0;
    width: 100%;
  }
}
.ContactsPopup-tabContentWrapper {
  position: absolute;
  -webkit-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  opacity: 0;
  visibility: hidden;
  display: none;
  width: 100%;
}
.ContactsPopup-tabContentWrapper.active {
  opacity: 1;
  visibility: visible;
  display: block;
  -webkit-animation: tab-fade 0.3s;
          animation: tab-fade 0.3s;
}
.ContactsPopup-searchResultWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 1920px) {
  .ContactsPopup-searchResultWrapper {
    padding-bottom:  133.33333333333331px;
    padding-left:  173.33333333333331px;
    width:  1240px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-searchResultWrapper {
    padding-bottom:  6.944444444444445vw;
    padding-left:  9.027777777777779vw;
    width:  64.58333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-searchResultWrapper {
    padding-bottom:  8.064516129032258vw;
    padding-left:  10.483870967741936vw;
    width:  75vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-searchResultWrapper {
    padding-bottom:  31.25vw;
    padding-left:  40.625vw;
    width:  290.625vw;
  }
}
@media (max-width: 759px) {
  .ContactsPopup-searchResultWrapper {
    padding: 0;
    width: auto;
  }
}
.ContactsPopup-accordionWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .ContactsPopup-accordionWrapper {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-accordionWrapper {
    width: 22.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-accordionWrapper {
    width: 19.44444444vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-accordionWrapper {
    width: 373.33333333px;
  }
}
.ContactsPopup-accordion,
.ContactsPopup-accordion * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ContactsPopup-accordionSectionContentLink {
  text-decoration: none;
  color: #111112;
  display: block;
  position: relative;
  opacity: 1;
}
@media (max-width: 759px) {
  .ContactsPopup-accordionSectionContentLink {
    margin-top: 2.5vw;
    padding: 2.5vw 3.75vw 1.5625vw 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-accordionSectionContentLink {
    margin-top: 0.64516129vw;
    padding: 0.64516129vw 0.80645161vw 0.3251821vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-accordionSectionContentLink {
    margin-top: 0.55555556vw;
    padding: 0.55555556vw 0.69444444vw 0.24112654vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-accordionSectionContentLink {
    margin-top: 10.66666667px;
    padding: 10.66666667px 13.33333333px 6.66666667px 13.33333333px;
  }
}
.ContactsPopup-accordionSectionContentLink.active {
  font-weight: normal;
  background-color: #111112;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ContactsPopup-accordionSectionContentLink.active {
    width: 93.75vw;
    border-radius: 15.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-accordionSectionContentLink.active {
    border-radius: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-accordionSectionContentLink.active {
    border-radius: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-accordionSectionContentLink.active {
    border-radius: 66.66666667px;
  }
}
.isDesktop .ContactsPopup-accordionSectionContentLink:hover,
.isNotDesktop .ContactsPopup-accordionSectionContentLink:active {
  color: #A6A8B3;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.ContactsPopup-accordionSectionContentLink:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #E7E7E7;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactsPopup-accordionSectionContentLink:not(:last-child):after {
    width: 20.96774194vw;
    bottom: -0.40322581vw;
    left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactsPopup-accordionSectionContentLink:not(:last-child):after {
    width: 18.05555556vw;
    bottom: -0.34722222vw;
    left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ContactsPopup-accordionSectionContentLink:not(:last-child):after {
    width: 346.66666667px;
    bottom: -6.66666667px;
    left: 13.33333333px;
  }
}
.ContactsPopup-accordionSectionContentLink span {
  color: #6F7380;
  pointer-events: none;
}
.ContactsPopup-mobileAnchors {
  display: none;
  width: 100%;
}
@media (max-width: 759px) {
  .ContactsPopup-mobileAnchors {
    display: block;
    padding: 6.25vw;
    background-color: #F6F6F6;
  }
}
.ContactsPopup-mobileAnchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  position: relative;
  padding: 4.6875vw 0 4.6875vw;
  border-bottom: 1px solid #E7E7E7;
}
.ContactsPopup-mobileAnchor:last-of-type {
  border-bottom: none;
}
.ContactsPopup-mobileAnchorIcon {
  width: 6.25vw;
  height: 6.25vw;
  position: relative;
}
.ContactsPopup-mobileAnchorIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ContactsPopup-mobileAnchorArrow {
  width: 5.9375vw;
  height: 3.75vw;
  position: absolute;
  right: 0;
}
.ContactsPopup-mobileAnchorArrow svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ContactsPopup-mobileAnchorTitle {
  line-height: normal;
}
@media (max-width: 759px) {
  .ContactsPopup-headquartersWrapper,
  .ContactsPopup-regionalOfficesWrapper,
  .ContactsPopup-fileAComplaintWrapper,
  .ContactsPopup-technicalSupportWrapper,
  .ContactsPopup-servicesWrapper,
  .ContactsPopup-pressWrapper,
  .ContactsPopup-investorWrapper,
  .ContactsPopup-hrAndJobsWrapper,
  .ContactsPopup-corporateWrapper,
  .ContactsPopup-eshopAccountWrapper,
  .ContactsPopup-lightForumWrapper,
  .ContactsPopup-qualifiedWrapper,
  .ContactsPopup-supplierWrapper,
  .ContactsPopup-problemsWrapper {
    display: none;
  }
  .ContactsPopup-headquartersWrapper.active,
  .ContactsPopup-regionalOfficesWrapper.active,
  .ContactsPopup-fileAComplaintWrapper.active,
  .ContactsPopup-technicalSupportWrapper.active,
  .ContactsPopup-servicesWrapper.active,
  .ContactsPopup-pressWrapper.active,
  .ContactsPopup-investorWrapper.active,
  .ContactsPopup-hrAndJobsWrapper.active,
  .ContactsPopup-corporateWrapper.active,
  .ContactsPopup-eshopAccountWrapper.active,
  .ContactsPopup-lightForumWrapper.active,
  .ContactsPopup-qualifiedWrapper.active,
  .ContactsPopup-supplierWrapper.active,
  .ContactsPopup-problemsWrapper.active {
    display: block;
    position: fixed;
    left: 0;
    top: 12.5vw;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: #FFFFFF;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.ContactsPopup-leftWrapper {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
}
@media (max-width: 759px) {
  .ContactsPopup-leftWrapper {
    position: relative;
  }
}
