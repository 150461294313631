@media (max-width: 759px) {
  .NewsStoryPopup .Popup-close {
    background-color: #111112;
  }
  .NewsStoryPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.NewsStoryPopup-content {
  background: #FFFFFF;
  width: 100%;
}
@media (min-width: 1920px) {
  .NewsStoryPopup-content {
    padding-bottom:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-content {
    padding-bottom:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-content {
    padding-bottom:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-content {
    padding-bottom:  15.625vw;
  }
}
.NewsStoryPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.NewsStoryPopup-title {
  color: #111112;
}
@media (min-width: 1920px) {
  .NewsStoryPopup-title {
    padding:  100px 133.33333333333331px 0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-title {
    padding:  5.208333333333334vw 6.944444444444445vw 0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-title {
    padding:  6.048387096774193vw 8.064516129032258vw 0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-title {
    padding:  2.8125vw 6.25vw 0vw;
    font-size:  5.625vw;
  }
}
.NewsStoryPopup-date {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .NewsStoryPopup-date {
    padding:  0 0 0 186.66666666666666px;
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-date {
    padding:  0vw 0vw 0vw 9.722222222222223vw;
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-date {
    padding:  0vw 0vw 0vw 11.29032258064516vw;
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-date {
    padding:  0vw 0vw 0vw 43.75vw;
    margin-top:  9.375vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-date {
    display: none;
  }
}
.NewsStoryPopup-date_mobile {
  display: none;
}
@media (max-width: 759px) {
  .NewsStoryPopup-date_mobile {
    display: block;
    padding-top: 25vw;
    padding-left: 6.25vw;
    color: #6F7380;
  }
}
.NewsStoryPopup-image {
  width: 100%;
}
@media (min-width: 1920px) {
  .NewsStoryPopup-image {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-image {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-image {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-image {
    margin-top:  9.375vw;
  }
}
.NewsStoryPopup-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
@media (max-width: 759px) {
  .NewsStoryPopup-footer {
    width: 87.5vw;
    margin: 0 auto;
    padding-top: unset;
    padding-bottom: unset;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-footer {
    width: 61.29032258vw;
    margin: 0 auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-footer {
    width: 52.77777778vw;
    margin: 0 auto;
  }
}
@media (min-width: 1920px) {
  .NewsStoryPopup-footer {
    width: 1013.33333333px;
    margin: 0 auto;
  }
}
@media (min-width: 1920px) {
  .NewsStoryPopup-blockWrapper {
    padding:  0 186.66666666666666px 53.33333333333333px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-blockWrapper {
    padding:  0vw 9.722222222222223vw 2.7777777777777777vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-blockWrapper {
    padding:  0vw 11.29032258064516vw 3.225806451612903vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-blockWrapper {
    padding:  0vw 6.25vw 6.25vw;
    margin-top:  6.25vw;
  }
}
.NewsStoryPopup-blockWrapper.no-padding {
  padding: 0;
}
.NewsStoryPopup-blockWrapper .CommonText-quote {
  margin: 0;
}
@media (min-width: 1920px) {
  .NewsStoryPopup-blockWrapper .CommonGallery {
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-blockWrapper .CommonGallery {
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-blockWrapper .CommonGallery {
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-blockWrapper .CommonGallery {
    margin-bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .NewsStoryPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsStoryPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsStoryPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .NewsStoryPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  4.375vw;
  }
}
