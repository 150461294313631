.PageNewsLanding .PageLanding-blockBg_mobile:before {
  content: '';
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.PageNewsLanding-rowFilter {
  overflow: visible;
}
@media (min-width: 1920px) {
  .PageNewsLanding-rowFilter {
    margin-bottom:  40px;
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageNewsLanding-rowFilter {
    margin-bottom:  2.0833333333333335vw;
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageNewsLanding-rowFilter {
    margin-bottom:  2.4193548387096775vw;
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageNewsLanding-rowFilter {
    padding:  0vw;
    margin-bottom:  9.375vw;
  }
}
@media (min-width: 1920px) {
  .PageNewsLanding-items {
    padding:  0 186.66666666666666px 133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageNewsLanding-items {
    padding:  0vw 9.722222222222223vw 6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageNewsLanding-items {
    padding:  0vw 11.29032258064516vw 8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageNewsLanding-items {
    padding:  0vw 6.25vw;
  }
}
