.ProductFamilyInspiration {
  position: relative;
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.ProductFamilyInspiration.hide {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration {
    height: auto;
    padding: 10.3125vw 0 15.625vw;
  }
  .ProductFamilyInspiration.singleItem {
    padding: 10.3125vw 0 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration {
    padding: 5.24193548vw 0 7.25806452vw;
  }
  .ProductFamilyInspiration.singleItem {
    padding: 5.24193548vw 0 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration {
    padding: 4.51388889vw 0 7.25806452vw;
  }
  .ProductFamilyInspiration.singleItem {
    padding: 4.51388889vw 0 0;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration {
    padding: 86.66666667px 0 120px;
  }
  .ProductFamilyInspiration.singleItem {
    padding: 86.66666667px 0 0;
  }
}
.ProductFamilyInspiration-title {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-title {
    padding: 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-title {
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-title {
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-title {
    padding: 0 186.66666667px;
  }
}
.ProductFamilyInspiration-wrapper.show .ProductFamilyInspiration-item {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.25s color ease, 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.25s color ease, 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.25s color ease, 0.5s transform, 0.5s opacity;
  transition: 0.25s color ease, 0.5s transform, 0.5s opacity, 0.5s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-wrapper {
    padding: 0 6.25vw;
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-wrapper {
    margin-top: 2.82258065vw;
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-wrapper {
    margin-top: 2.43055556vw;
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-wrapper {
    margin-top: 46.66666667px;
    padding: 0 186.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-wrapper.singleItem {
    padding: 0 15.625vw;
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-wrapper.singleItem {
    margin-top: 6.4516129vw;
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-wrapper.singleItem {
    margin-top: 5.55555556vw;
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-wrapper.singleItem {
    margin-top: 106.66666667px;
    padding: 0 186.66666667px;
  }
}
.ProductFamilyInspiration-wrapperInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-wrapperInner {
    margin-left:  -26.666666666666664px;
    margin-right:  -26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-wrapperInner {
    margin-left:  -1.3888888888888888vw;
    margin-right:  -1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-wrapperInner {
    margin-left:  -1.6129032258064515vw;
    margin-right:  -1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-wrapperInner {
    margin-left:  -6.25vw;
    margin-right:  -6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-wrapperInner.singleItem {
    width: unset !important;
    height: unset !important;
  }
}
.ProductFamilyInspiration-item {
  position: relative;
  display: block;
  opacity: 0;
  -webkit-transform: translate3d(0, 5%, 0);
          transform: translate3d(0, 5%, 0);
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-item {
    margin-left:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-item {
    margin-left:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-item {
    margin-left:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-item {
    margin-left:  6.25vw;
    margin-right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-item:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-item:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-item:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-item:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-item {
    width: 87.5vw;
    height: auto;
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-item {
    margin-bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-item {
    margin-bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-item {
    margin-bottom: 53.33333333px;
  }
}
.ProductFamilyInspiration-itemBg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  will-change: opacity;
  -webkit-transition: 0.4s opacity;
  transition: 0.4s opacity;
  opacity: 0;
  max-width: unset;
  overflow: hidden;
}
.ProductFamilyInspiration-itemBg.active {
  opacity: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.ProductFamilyInspiration-itemBgWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #000000;
}
.ProductFamilyInspiration-itemBg-inner {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: length;
     object-position: length;
  font-family: 'object-fit: cover; object-position: length';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
}
.ProductFamilyInspiration-itemBg-inner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: length;
     object-position: length;
  font-family: 'object-fit: cover; object-position: length';
}
.isDesktop .ProductFamilyInspiration-item:hover .ProductFamilyInspiration-itemBg,
.isNotDesktop .ProductFamilyInspiration-item:active .ProductFamilyInspiration-itemBg {
  opacity: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.ProductFamilyInspiration-itemImage {
  position: relative;
  display: block;
}
.ProductFamilyInspiration-itemImage.singleItem img {
  max-width: unset;
  max-height: unset;
}
.ProductFamilyInspiration-itemImage img {
  max-height: 33vh;
  max-width: 38.88888889vw;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-itemImage img {
    max-width: unset;
    max-height: unset;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: 'object-fit: cover; object-position: center';
    width: 100%;
  }
}
.ProductFamilyInspiration-itemTitleName,
.ProductFamilyInspiration-itemTitleCountry,
.ProductFamilyInspiration-itemTitleCategory {
  color: #FFFFFF;
  letter-spacing: normal;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-itemTitleName,
  .ProductFamilyInspiration-itemTitleCountry {
    font-size: 3.125vw;
  }
}
.ProductFamilyInspiration-itemTitleCategory {
  display: block;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-itemTitleCategory {
    margin-top: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-itemTitleCategory {
    margin-top: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-itemTitleCategory {
    margin-top: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-itemTitleCategory {
    margin-top: 6.66666667px;
  }
}
.ProductFamilyInspiration-itemTitleCountry {
  text-transform: none;
}
.ProductFamilyInspiration-itemTitleWrapper {
  position: absolute;
  display: block;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-itemTitleWrapper {
    bottom: 4.0625vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-itemTitleWrapper {
    bottom: 1.0483871vw;
    left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-itemTitleWrapper {
    bottom: 0.90277778vw;
    left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-itemTitleWrapper {
    bottom: 17.33333333px;
    left: 26.66666667px;
  }
}
.ProductFamilyInspiration-arrowLeft,
.ProductFamilyInspiration-arrowRight {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 10;
}
.isMobile .ProductFamilyInspiration-arrowLeft,
.isMobile .ProductFamilyInspiration-arrowRight {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-arrowLeft,
  .ProductFamilyInspiration-arrowRight {
    width: 6.25vw;
    height: 10.9375vw;
    top: 45%;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-arrowLeft,
  .ProductFamilyInspiration-arrowRight {
    width: 3.22580645vw;
    height: 5.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-arrowLeft,
  .ProductFamilyInspiration-arrowRight {
    width: 2.77777778vw;
    height: 4.86111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-arrowLeft,
  .ProductFamilyInspiration-arrowRight {
    width: 53.33333333px;
    height: 93.33333333px;
  }
}
.isDesktop .ProductFamilyInspiration-arrowLeft:hover svg,
.isDesktop .ProductFamilyInspiration-arrowRight:hover svg,
.isNotDesktop .ProductFamilyInspiration-arrowLeft:active svg,
.isNotDesktop .ProductFamilyInspiration-arrowRight:active svg {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.ProductFamilyInspiration-arrowLeft svg,
.ProductFamilyInspiration-arrowRight svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-arrowLeft {
    left: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-arrowLeft {
    left: 1.37096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-arrowLeft {
    left: 1.18055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-arrowLeft {
    left: 22.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-arrowRight {
    right: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-arrowRight {
    right: 1.37096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-arrowRight {
    right: 1.18055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-arrowRight {
    right: 22.66666667px;
  }
}
.ProductFamilyInspiration-navLines,
.ProductFamilyInspiration-navLinesMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-navLines,
  .ProductFamilyInspiration-navLinesMobile {
    bottom: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-navLines,
  .ProductFamilyInspiration-navLinesMobile {
    bottom: 3.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-navLines,
  .ProductFamilyInspiration-navLinesMobile {
    bottom: 2.63888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-navLines,
  .ProductFamilyInspiration-navLinesMobile {
    bottom: 50.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-navLines {
    display: none;
  }
}
.ProductFamilyInspiration-navLinesMobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-navLinesMobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.ProductFamilyInspiration-navLine {
  height: 30px;
  position: relative;
}
@media (max-width: 759px) {
  .ProductFamilyInspiration-navLine {
    width: 5.625vw;
    margin-right: 2px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyInspiration-navLine {
    margin-right: 0.16129032vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyInspiration-navLine {
    margin-right: 0.13888889vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyInspiration-navLine {
    margin-right: 2.66666667px;
    width: 77.33333333px;
  }
}
.isDesktop .ProductFamilyInspiration-navLine:hover:after,
.isNotDesktop .ProductFamilyInspiration-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
.ProductFamilyInspiration-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.ProductFamilyInspiration-navLine.active {
  pointer-events: none;
}
.ProductFamilyInspiration-navLine.active:after {
  height: 4px;
  background: #FFFFFF;
}
.ProductFamilyInspiration-navLine:last-of-type {
  margin-right: 0;
}
