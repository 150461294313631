.IndexApplications {
  position: relative;
  width: 100%;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.IndexApplications.show .IndexApplications-card {
  -webkit-transition: 0.25s color ease, 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.25s color ease, 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.25s color ease, 0.5s transform, 0.5s opacity;
  transition: 0.25s color ease, 0.5s transform, 0.5s opacity, 0.5s -webkit-transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}
.IndexApplications.show .IndexApplications-card:nth-child(20) {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
.IndexApplications.show .IndexApplications-card:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}
.IndexApplications.show .IndexApplications-card:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}
.IndexApplications.show .IndexApplications-card:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}
.IndexApplications.show .IndexApplications-card:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
.IndexApplications.show .IndexApplications-card:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.IndexApplications.show .IndexApplications-card:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.IndexApplications.show .IndexApplications-card:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.IndexApplications.show .IndexApplications-card:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.IndexApplications.show .IndexApplications-card:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.IndexApplications.show .IndexApplications-card:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.IndexApplications.show .IndexApplications-card:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
.IndexApplications.show .IndexApplications-card:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.IndexApplications.show .IndexApplications-card:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
.IndexApplications.show .IndexApplications-card:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.IndexApplications.show .IndexApplications-card:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.IndexApplications.show .IndexApplications-card:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.IndexApplications.show .IndexApplications-card:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.IndexApplications.show .IndexApplications-card:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.IndexApplications.show .IndexApplications-card:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.isIE .IndexApplications {
  z-index: 4;
}
@media (max-width: 759px) {
  .IndexApplications {
    padding-bottom: 25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications {
    padding-bottom: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications {
    padding-bottom: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications {
    padding-bottom: 106.66666667px;
  }
}
.IndexApplications-inner-wrapper {
  position: relative;
}
.IndexApplications-wrapper {
  margin: 0 auto;
}
@media (max-width: 759px) {
  .IndexApplications-wrapper {
    width: 75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-wrapper {
    width: 93.5483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-wrapper {
    width: 80.55555556vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-wrapper {
    width: 1546.66666667px;
  }
}
.IndexApplications-cards {
  position: relative;
  white-space: nowrap;
  will-change: transform;
  -webkit-transition: 0.5s -webkit-transform ease;
  transition: 0.5s -webkit-transform ease;
  transition: 0.5s transform ease;
  transition: 0.5s transform ease, 0.5s -webkit-transform ease;
}
@media (max-width: 759px) {
  .IndexApplications-cards[data-pos="20"] {
    -webkit-transform: translate3d(-1625vw, 0, 0);
            transform: translate3d(-1625vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="19"] {
    -webkit-transform: translate3d(-1543.75vw, 0, 0);
            transform: translate3d(-1543.75vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="18"] {
    -webkit-transform: translate3d(-1462.5vw, 0, 0);
            transform: translate3d(-1462.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="17"] {
    -webkit-transform: translate3d(-1381.25vw, 0, 0);
            transform: translate3d(-1381.25vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="16"] {
    -webkit-transform: translate3d(-1300vw, 0, 0);
            transform: translate3d(-1300vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="15"] {
    -webkit-transform: translate3d(-1218.75vw, 0, 0);
            transform: translate3d(-1218.75vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="14"] {
    -webkit-transform: translate3d(-1137.5vw, 0, 0);
            transform: translate3d(-1137.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="13"] {
    -webkit-transform: translate3d(-1056.25vw, 0, 0);
            transform: translate3d(-1056.25vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="12"] {
    -webkit-transform: translate3d(-975vw, 0, 0);
            transform: translate3d(-975vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="11"] {
    -webkit-transform: translate3d(-893.75vw, 0, 0);
            transform: translate3d(-893.75vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="10"] {
    -webkit-transform: translate3d(-812.5vw, 0, 0);
            transform: translate3d(-812.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="9"] {
    -webkit-transform: translate3d(-731.25vw, 0, 0);
            transform: translate3d(-731.25vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="8"] {
    -webkit-transform: translate3d(-650vw, 0, 0);
            transform: translate3d(-650vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="7"] {
    -webkit-transform: translate3d(-568.75vw, 0, 0);
            transform: translate3d(-568.75vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="6"] {
    -webkit-transform: translate3d(-487.5vw, 0, 0);
            transform: translate3d(-487.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="5"] {
    -webkit-transform: translate3d(-406.25vw, 0, 0);
            transform: translate3d(-406.25vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="4"] {
    -webkit-transform: translate3d(-325vw, 0, 0);
            transform: translate3d(-325vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="3"] {
    -webkit-transform: translate3d(-243.75vw, 0, 0);
            transform: translate3d(-243.75vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="2"] {
    -webkit-transform: translate3d(-162.5vw, 0, 0);
            transform: translate3d(-162.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="1"] {
    -webkit-transform: translate3d(-81.25vw, 0, 0);
            transform: translate3d(-81.25vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="0"] {
    -webkit-transform: translate3d(0vw, 0, 0);
            transform: translate3d(0vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-cards[data-pos="20"] {
    -webkit-transform: translate3d(-483.87096774vw, 0, 0);
            transform: translate3d(-483.87096774vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="19"] {
    -webkit-transform: translate3d(-459.67741935vw, 0, 0);
            transform: translate3d(-459.67741935vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="18"] {
    -webkit-transform: translate3d(-435.48387097vw, 0, 0);
            transform: translate3d(-435.48387097vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="17"] {
    -webkit-transform: translate3d(-411.29032258vw, 0, 0);
            transform: translate3d(-411.29032258vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="16"] {
    -webkit-transform: translate3d(-387.09677419vw, 0, 0);
            transform: translate3d(-387.09677419vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="15"] {
    -webkit-transform: translate3d(-362.90322581vw, 0, 0);
            transform: translate3d(-362.90322581vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="14"] {
    -webkit-transform: translate3d(-338.70967742vw, 0, 0);
            transform: translate3d(-338.70967742vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="13"] {
    -webkit-transform: translate3d(-314.51612903vw, 0, 0);
            transform: translate3d(-314.51612903vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="12"] {
    -webkit-transform: translate3d(-290.32258065vw, 0, 0);
            transform: translate3d(-290.32258065vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="11"] {
    -webkit-transform: translate3d(-266.12903226vw, 0, 0);
            transform: translate3d(-266.12903226vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="10"] {
    -webkit-transform: translate3d(-241.93548387vw, 0, 0);
            transform: translate3d(-241.93548387vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="9"] {
    -webkit-transform: translate3d(-217.74193548vw, 0, 0);
            transform: translate3d(-217.74193548vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="8"] {
    -webkit-transform: translate3d(-193.5483871vw, 0, 0);
            transform: translate3d(-193.5483871vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="7"] {
    -webkit-transform: translate3d(-169.35483871vw, 0, 0);
            transform: translate3d(-169.35483871vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="6"] {
    -webkit-transform: translate3d(-145.16129032vw, 0, 0);
            transform: translate3d(-145.16129032vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="5"] {
    -webkit-transform: translate3d(-120.96774194vw, 0, 0);
            transform: translate3d(-120.96774194vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="4"] {
    -webkit-transform: translate3d(-96.77419355vw, 0, 0);
            transform: translate3d(-96.77419355vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="3"] {
    -webkit-transform: translate3d(-72.58064516vw, 0, 0);
            transform: translate3d(-72.58064516vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="2"] {
    -webkit-transform: translate3d(-48.38709677vw, 0, 0);
            transform: translate3d(-48.38709677vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="1"] {
    -webkit-transform: translate3d(-24.19354839vw, 0, 0);
            transform: translate3d(-24.19354839vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="0"] {
    -webkit-transform: translate3d(0vw, 0, 0);
            transform: translate3d(0vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-cards[data-pos="20"] {
    -webkit-transform: translate3d(-416.66666667vw, 0, 0);
            transform: translate3d(-416.66666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="19"] {
    -webkit-transform: translate3d(-395.83333333vw, 0, 0);
            transform: translate3d(-395.83333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="18"] {
    -webkit-transform: translate3d(-375vw, 0, 0);
            transform: translate3d(-375vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="17"] {
    -webkit-transform: translate3d(-354.16666667vw, 0, 0);
            transform: translate3d(-354.16666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="16"] {
    -webkit-transform: translate3d(-333.33333333vw, 0, 0);
            transform: translate3d(-333.33333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="15"] {
    -webkit-transform: translate3d(-312.5vw, 0, 0);
            transform: translate3d(-312.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="14"] {
    -webkit-transform: translate3d(-291.66666667vw, 0, 0);
            transform: translate3d(-291.66666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="13"] {
    -webkit-transform: translate3d(-270.83333333vw, 0, 0);
            transform: translate3d(-270.83333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="12"] {
    -webkit-transform: translate3d(-250vw, 0, 0);
            transform: translate3d(-250vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="11"] {
    -webkit-transform: translate3d(-229.16666667vw, 0, 0);
            transform: translate3d(-229.16666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="10"] {
    -webkit-transform: translate3d(-208.33333333vw, 0, 0);
            transform: translate3d(-208.33333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="9"] {
    -webkit-transform: translate3d(-187.5vw, 0, 0);
            transform: translate3d(-187.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="8"] {
    -webkit-transform: translate3d(-166.66666667vw, 0, 0);
            transform: translate3d(-166.66666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="7"] {
    -webkit-transform: translate3d(-145.83333333vw, 0, 0);
            transform: translate3d(-145.83333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="6"] {
    -webkit-transform: translate3d(-125vw, 0, 0);
            transform: translate3d(-125vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="5"] {
    -webkit-transform: translate3d(-104.16666667vw, 0, 0);
            transform: translate3d(-104.16666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="4"] {
    -webkit-transform: translate3d(-83.33333333vw, 0, 0);
            transform: translate3d(-83.33333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="3"] {
    -webkit-transform: translate3d(-62.5vw, 0, 0);
            transform: translate3d(-62.5vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="2"] {
    -webkit-transform: translate3d(-41.66666667vw, 0, 0);
            transform: translate3d(-41.66666667vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="1"] {
    -webkit-transform: translate3d(-20.83333333vw, 0, 0);
            transform: translate3d(-20.83333333vw, 0, 0);
  }
  .IndexApplications-cards[data-pos="0"] {
    -webkit-transform: translate3d(0vw, 0, 0);
            transform: translate3d(0vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexApplications-cards[data-pos="20"] {
    -webkit-transform: translate3d(-8000px, 0, 0);
            transform: translate3d(-8000px, 0, 0);
  }
  .IndexApplications-cards[data-pos="19"] {
    -webkit-transform: translate3d(-7600px, 0, 0);
            transform: translate3d(-7600px, 0, 0);
  }
  .IndexApplications-cards[data-pos="18"] {
    -webkit-transform: translate3d(-7200px, 0, 0);
            transform: translate3d(-7200px, 0, 0);
  }
  .IndexApplications-cards[data-pos="17"] {
    -webkit-transform: translate3d(-6800px, 0, 0);
            transform: translate3d(-6800px, 0, 0);
  }
  .IndexApplications-cards[data-pos="16"] {
    -webkit-transform: translate3d(-6400px, 0, 0);
            transform: translate3d(-6400px, 0, 0);
  }
  .IndexApplications-cards[data-pos="15"] {
    -webkit-transform: translate3d(-6000px, 0, 0);
            transform: translate3d(-6000px, 0, 0);
  }
  .IndexApplications-cards[data-pos="14"] {
    -webkit-transform: translate3d(-5600px, 0, 0);
            transform: translate3d(-5600px, 0, 0);
  }
  .IndexApplications-cards[data-pos="13"] {
    -webkit-transform: translate3d(-5200px, 0, 0);
            transform: translate3d(-5200px, 0, 0);
  }
  .IndexApplications-cards[data-pos="12"] {
    -webkit-transform: translate3d(-4800px, 0, 0);
            transform: translate3d(-4800px, 0, 0);
  }
  .IndexApplications-cards[data-pos="11"] {
    -webkit-transform: translate3d(-4400px, 0, 0);
            transform: translate3d(-4400px, 0, 0);
  }
  .IndexApplications-cards[data-pos="10"] {
    -webkit-transform: translate3d(-4000px, 0, 0);
            transform: translate3d(-4000px, 0, 0);
  }
  .IndexApplications-cards[data-pos="9"] {
    -webkit-transform: translate3d(-3600px, 0, 0);
            transform: translate3d(-3600px, 0, 0);
  }
  .IndexApplications-cards[data-pos="8"] {
    -webkit-transform: translate3d(-3200px, 0, 0);
            transform: translate3d(-3200px, 0, 0);
  }
  .IndexApplications-cards[data-pos="7"] {
    -webkit-transform: translate3d(-2800px, 0, 0);
            transform: translate3d(-2800px, 0, 0);
  }
  .IndexApplications-cards[data-pos="6"] {
    -webkit-transform: translate3d(-2400px, 0, 0);
            transform: translate3d(-2400px, 0, 0);
  }
  .IndexApplications-cards[data-pos="5"] {
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
  .IndexApplications-cards[data-pos="4"] {
    -webkit-transform: translate3d(-1600px, 0, 0);
            transform: translate3d(-1600px, 0, 0);
  }
  .IndexApplications-cards[data-pos="3"] {
    -webkit-transform: translate3d(-1200px, 0, 0);
            transform: translate3d(-1200px, 0, 0);
  }
  .IndexApplications-cards[data-pos="2"] {
    -webkit-transform: translate3d(-800px, 0, 0);
            transform: translate3d(-800px, 0, 0);
  }
  .IndexApplications-cards[data-pos="1"] {
    -webkit-transform: translate3d(-400px, 0, 0);
            transform: translate3d(-400px, 0, 0);
  }
  .IndexApplications-cards[data-pos="0"] {
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
  }
}
.IndexApplications-card {
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  opacity: 0;
  -webkit-transform: translate3d(0, 5%, 0);
          transform: translate3d(0, 5%, 0);
}
@media (min-width: 1920px) {
  .IndexApplications-card {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-card {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-card {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .IndexApplications-card {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .IndexApplications-card:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .IndexApplications-card-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .IndexApplications-card:hover .IndexApplications-card-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .IndexApplications-card:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.IndexApplications-card:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .IndexApplications-card:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .IndexApplications-card:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .IndexApplications-card:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .IndexApplications-card:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.IndexApplications-card:active:after {
  opacity: 0.5;
}
.IndexApplications-card:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .IndexApplications-card:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .IndexApplications-card:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-card:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-card:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexApplications-card:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.IndexApplications-card:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .IndexApplications-card:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .IndexApplications-card {
    margin-right: 6.25vw;
    width: 75vw;
    height: 131.25vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-card {
    margin-right: 3.22580645vw;
    width: 20.96774194vw;
    height: 43.5483871vw;
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-card {
    margin-right: 2.77777778vw;
    width: 18.05555556vw;
    height: 37.5vw;
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-card {
    margin-right: 53.33333333px;
    width: 346.66666667px;
    height: 720px;
    margin-top: 53.33333333px;
  }
}
.IndexApplications-card:last-child {
  margin-right: 0;
}
.IndexApplications-card-image {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  will-change: transform;
  -webkit-transition: 2s -webkit-transform ease;
  transition: 2s -webkit-transform ease;
  transition: 2s transform ease;
  transition: 2s transform ease, 2s -webkit-transform ease;
}
.IndexApplications-card img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%';
  pointer-events: none;
}
.IndexApplications-card h2 {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  position: relative;
  text-align: center;
  z-index: 2;
  white-space: normal;
}
@media (max-width: 759px) {
  .IndexApplications-card h2 {
    padding: 0 5vw;
    margin-top: 100vw;
    font-size: 5.625vw;
    letter-spacing: -0.03333333em;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-card h2 {
    padding: 0 1.93548387vw;
    margin-top: 34.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-card h2 {
    padding: 0 1.66666667vw;
    margin-top: 29.30555556vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-card h2 {
    padding: 0 32px;
    margin-top: 562.66666667px;
  }
}
.IndexApplications-card p {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  position: relative;
  text-align: center;
  z-index: 2;
  white-space: normal;
}
@media (max-width: 759px) {
  .IndexApplications-card p {
    padding: 0 7.5vw;
    margin-top: 5.3125vw;
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-card p {
    padding: 0 1.93548387vw;
    margin-top: 1.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-card p {
    padding: 0 1.66666667vw;
    margin-top: 1.25vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-card p {
    padding: 0 32px;
    margin-top: 24px;
  }
}
.IndexApplications-card:before {
  left: 50%;
}
@media (max-width: 759px) {
  .IndexApplications-card:before {
    bottom: 5vw;
    margin-left: -4.0625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-card:before {
    bottom: 1.29032258vw;
    margin-left: -1.0483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-card:before {
    bottom: 1.11111111vw;
    margin-left: -0.90277778vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-card:before {
    bottom: 21.33333333px;
    margin-left: -17.33333333px;
  }
}
.isNotDesktop .IndexApplications-card:after {
  -webkit-transition: 0.15s opacity 0.15s;
  transition: 0.15s opacity 0.15s;
}
.IndexApplications-arrow {
  position: absolute;
  background: center / cover url('/static/img/index/applications/arrow.png') no-repeat;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  cursor: pointer;
  z-index: 4;
}
@media (max-width: 759px) {
  .IndexApplications-arrow {
    width: 6.5625vw;
    height: 9.375vw;
    top: 70.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-arrow {
    top: 20.96774194vw;
    width: 3.38709677vw;
    height: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-arrow {
    top: 18.05555556vw;
    width: 2.91666667vw;
    height: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-arrow {
    top: 346.66666667px;
    width: 56px;
    height: 80px;
  }
}
.IndexApplications-arrow[data-tp="prev"] {
  will-change: transform;
  -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
          transform: scaleX(-1);
}
@media (max-width: 759px) {
  .IndexApplications-arrow[data-tp="prev"] {
    left: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-arrow[data-tp="prev"] {
    left: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-arrow[data-tp="prev"] {
    left: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-arrow[data-tp="prev"] {
    left: 21.33333333px;
  }
}
@media (max-width: 759px) {
  .IndexApplications-arrow[data-tp="next"] {
    right: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexApplications-arrow[data-tp="next"] {
    right: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexApplications-arrow[data-tp="next"] {
    right: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .IndexApplications-arrow[data-tp="next"] {
    right: 21.33333333px;
  }
}
.isDesktop .IndexApplications-arrow:hover,
.isNotDesktop .IndexApplications-arrow:active {
  opacity: 0.7;
}
.IndexApplications-arrow--hidden {
  opacity: 0 !important;
  cursor: default;
}
