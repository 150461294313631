@media (max-width: 759px) {
  .ProductDetailsOverview {
    padding: 0 6.25vw;
    margin-top: 19.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsOverview {
    padding: 0 9.67741935vw;
    margin-top: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsOverview {
    padding: 0 8.33333333vw;
    margin-top: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsOverview {
    padding: 0 160px;
    margin-top: 106.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsOverview-title {
    display: none;
  }
}
@media (max-width: 759px) {
  .ProductDetailsOverview-features {
    margin-top: 13.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsOverview-features {
    margin-top: 3.38709677vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsOverview-features {
    margin-top: 2.91666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsOverview-features {
    margin-top: 56px;
  }
}
.ProductDetailsOverview-featuresTitle {
  letter-spacing: normal;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ProductDetailsOverview-featuresTitle {
    font-size: 4.375vw;
    line-height: normal;
  }
}
@media (max-width: 759px) {
  .ProductDetailsOverview-featuresList {
    margin-top: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsOverview-featuresList {
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsOverview-featuresList {
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsOverview-featuresList {
    margin-top: 20px;
  }
}
.ProductDetailsOverview-featuresListItem {
  color: #6F7380;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsOverview-featuresListItem {
    font-size: 4.375vw;
    padding-left: 6.5625vw;
    margin-left: 3.4375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsOverview-featuresListItem {
    padding-left: 1.69354839vw;
    margin-left: 0.88709677vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsOverview-featuresListItem {
    padding-left: 1.45833333vw;
    margin-left: 0.76388889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsOverview-featuresListItem {
    padding-left: 28px;
    margin-left: 14.66666667px;
  }
}
.ProductDetailsOverview-featuresListItem:before {
  content: '•';
  left: 0;
  border-radius: 50%;
  position: absolute;
}
