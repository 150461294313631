.CampusHead {
  height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 600px;
}
@media (max-width: 759px) {
  .CampusHead {
    position: relative;
    min-height: 0;
  }
}
@media (min-width: 1920px) {
  .CampusHead {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CampusHead {
    padding:  0vw 6.25vw;
  }
}
.CampusHead-title {
  color: #FFFFFF;
  line-height: 1;
}
.CampusHead-description {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CampusHead-description {
    width:  613.3333333333333px;
    margin-top:  42.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-description {
    width:  31.944444444444446vw;
    margin-top:  2.2222222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-description {
    width:  37.096774193548384vw;
    margin-top:  2.5806451612903225vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-description {
    width:  143.75vw;
    margin-top:  10vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-description {
    width: 100%;
  }
}
.CampusHead-link {
  background: #FFFFFF;
  color: #111112;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .CampusHead-link {
    width:  346.66666666666663px;
    height:  186.66666666666666px;
    padding:  26.666666666666664px;
    margin-top:  42.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-link {
    width:  18.055555555555557vw;
    height:  9.722222222222223vw;
    padding:  1.3888888888888888vw;
    margin-top:  2.2222222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-link {
    width:  20.967741935483872vw;
    height:  11.29032258064516vw;
    padding:  1.6129032258064515vw;
    margin-top:  2.5806451612903225vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-link {
    width:  81.25vw;
    height:  43.75vw;
    padding:  6.25vw;
    margin-top:  10vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-link {
    width: auto;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 6.25vw;
    right: 6.25vw;
  }
}
.isDesktop .CampusHead-link:hover .CampusHead-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.CampusHead-linkTitle {
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 759px) {
  .CampusHead-linkTitle {
    font-size: 6.25vw;
    line-height: 1;
  }
}
.CampusHead-linkDescription {
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .CampusHead-linkDescription {
    font-size: 3.75vw;
  }
}
.CampusHead-linkUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.CampusHead-linkArrow {
  overflow: hidden;
  position: relative;
  margin-top: auto;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .CampusHead-linkArrow {
    width:  93.33333333333333px;
    height:  48px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-linkArrow {
    width:  4.861111111111112vw;
    height:  2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-linkArrow {
    width:  5.64516129032258vw;
    height:  2.903225806451613vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-linkArrow {
    width:  21.875vw;
    height:  11.25vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-linkArrow {
    display: none;
  }
}
.CampusHead-linkArrow svg {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  stroke: #111112;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1920px) {
  .CampusHead-linkArrow svg {
    -webkit-transform: translate3d(-14.66666667px, 0, 0);
            transform: translate3d(-14.66666667px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-linkArrow svg {
    -webkit-transform: translate3d(-0.76388889vw, 0, 0);
            transform: translate3d(-0.76388889vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-linkArrow svg {
    -webkit-transform: translate3d(-0.88709677vw, 0, 0);
            transform: translate3d(-0.88709677vw, 0, 0);
  }
}
@media (max-width: 759px) {
  .CampusHead-linkArrow svg {
    -webkit-transform: translate3d(-3.4375vw, 0, 0);
            transform: translate3d(-3.4375vw, 0, 0);
  }
}
.CampusHead-linkArrow_mobile {
  display: none;
}
@media (min-width: 1920px) {
  .CampusHead-linkArrow_mobile {
    width:  25.333333333333332px;
    height:  16px;
    right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-linkArrow_mobile {
    width:  1.3194444444444444vw;
    height:  0.8333333333333334vw;
    right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-linkArrow_mobile {
    width:  1.532258064516129vw;
    height:  0.967741935483871vw;
    right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-linkArrow_mobile {
    width:  5.9375vw;
    height:  3.75vw;
    right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-linkArrow_mobile {
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  .CampusHead-linkArrow_mobile svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 1920px) {
  .CampusHead-smallLinksWrapper {
    margin-top:  44px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-smallLinksWrapper {
    margin-top:  2.291666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-smallLinksWrapper {
    margin-top:  2.661290322580645vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-smallLinksWrapper {
    margin-top:  10.3125vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-smallLinksWrapper {
    position: absolute;
    bottom: 0;
    left: 6.25vw;
    right: 6.25vw;
  }
}
.CampusHead-smallLink {
  background: #FFFFFF;
  position: relative;
}
@media (min-width: 1920px) {
  .CampusHead-smallLink {
    width:  346.66666666666663px;
    padding:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-smallLink {
    width:  18.055555555555557vw;
    padding:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-smallLink {
    width:  20.967741935483872vw;
    padding:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-smallLink {
    padding:  3.125vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-smallLink {
    width: 100%;
  }
}
.CampusHead-smallLink .CampusHead-linkTitle {
  font-weight: 600;
}
@media (min-width: 1920px) {
  .CampusHead-smallLink .CampusHead-linkTitle {
    padding-right:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-smallLink .CampusHead-linkTitle {
    padding-right:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-smallLink .CampusHead-linkTitle {
    padding-right:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-smallLink .CampusHead-linkTitle {
    padding-right:  9.375vw;
  }
}
.isDesktop .CampusHead-smallLink:hover .CampusHead-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.CampusHead-smallLink_live {
  background: #EA0101;
  cursor: pointer;
}
.CampusHead-smallLink_live .CampusHead-linkTitle {
  color: #FFFFFF;
}
.CampusHead-smallLink_live .CampusHead-linkArrow svg {
  stroke: #FFFFFF;
}
.CampusHead-smallLink .CampusHead-linkArrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (min-width: 1920px) {
  .CampusHead-smallLink .CampusHead-linkArrow {
    width:  36px;
    height:  26.666666666666664px;
    right:  16px;
    margin-top:  1.3333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusHead-smallLink .CampusHead-linkArrow {
    width:  1.875vw;
    height:  1.3888888888888888vw;
    right:  0.8333333333333334vw;
    margin-top:  0.06944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusHead-smallLink .CampusHead-linkArrow {
    width:  2.1774193548387095vw;
    height:  1.6129032258064515vw;
    right:  0.967741935483871vw;
    margin-top:  0.08064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CampusHead-smallLink .CampusHead-linkArrow {
    width:  8.4375vw;
    height:  6.25vw;
    right:  3.75vw;
    margin-top:  0.3125vw;
  }
}
.CampusHead-smallLink .CampusHead-linkArrow svg {
  -webkit-transform: translate3d(-20%, 0, 0);
          transform: translate3d(-20%, 0, 0);
}
@media (max-width: 759px) {
  .CampusHead-smallLink .CampusHead-linkArrow {
    display: block;
  }
}
