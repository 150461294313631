.TextWithLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.TextWithLink-titleWrapper {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.TextWithLink-titleWrapper.halfWidth {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .TextWithLink-titleWrapper.halfWidth {
    width: 100%;
  }
}
.TextWithLink-titleWrapper.isTitleClickable {
  cursor: pointer;
}
.isDesktop .TextWithLink-titleWrapper.isTitleClickable:hover,
.isNotDesktop .TextWithLink-titleWrapper.isTitleClickable:active {
  opacity: 0.7;
}
.isDesktop .TextWithLink-titleWrapper.isTitleClickable:hover .TextWithLink-link,
.isNotDesktop .TextWithLink-titleWrapper.isTitleClickable:active .TextWithLink-link {
  text-decoration: underline;
}
.isDesktop .TextWithLink-titleWrapper.isTitleClickable:hover .TextWithLink-link:before,
.isNotDesktop .TextWithLink-titleWrapper.isTitleClickable:active .TextWithLink-link:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .TextWithLink-titleWrapper.isTitleClickable:hover .TextWithLink-link:before,
  .isNotDesktop .TextWithLink-titleWrapper.isTitleClickable:active .TextWithLink-link:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.TextWithLink-title {
  color: #FFFFFF;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TextWithLink-title {
    font-size: 4.03225806vw;
  }
}
.TextWithLink-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .TextWithLink-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TextWithLink-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TextWithLink-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .TextWithLink-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .TextWithLink-link:hover,
.isNotDesktop .TextWithLink-link:active {
  text-decoration: underline;
}
.isDesktop .TextWithLink-link:hover:before,
.isNotDesktop .TextWithLink-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .TextWithLink-link:hover:before,
  .isNotDesktop .TextWithLink-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .TextWithLink-link {
    letter-spacing: 0;
  }
}
.TextWithLink-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .TextWithLink-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .TextWithLink-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .TextWithLink-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TextWithLink-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TextWithLink-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .TextWithLink-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .TextWithLink-link {
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TextWithLink-link {
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TextWithLink-link {
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .TextWithLink-link {
    margin-top:  6.25vw;
  }
}
.TextWithLink-link:visited {
  color: #FFFFFF;
}
.TextWithLink-text {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .TextWithLink-text {
    width: 100%;
  }
}
