.NewsInsights {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate .NewsInsights-news {
    margin-top:  -40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate .NewsInsights-news {
    margin-top:  -2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate .NewsInsights-news {
    margin-top:  -2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate .NewsInsights-news {
    margin-top:  0vw;
  }
}
.PageSustainabilityLanding .NewsInsights .NewsInsights-title {
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding .NewsInsights .NewsInsights-title {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding .NewsInsights .NewsInsights-title {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding .NewsInsights .NewsInsights-title {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .NewsInsights .NewsInsights-title {
    padding-top:  4.6875vw;
  }
}
.PageFlexibleLanding .NewsInsights .NewsInsights-title {
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageFlexibleLanding .NewsInsights .NewsInsights-title {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFlexibleLanding .NewsInsights .NewsInsights-title {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFlexibleLanding .NewsInsights .NewsInsights-title {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageFlexibleLanding .NewsInsights .NewsInsights-title {
    padding-top:  4.6875vw;
  }
}
.PageInvestorsLanding .NewsInsights,
.PagePressLanding .NewsInsights,
.PageSustainabilityLanding .NewsInsights,
.PageFlexibleLanding .NewsInsights,
.PageIndex .NewsInsights {
  background-color: transparent;
}
.PageInvestorsLanding .NewsInsights h2,
.PagePressLanding .NewsInsights h2,
.PageSustainabilityLanding .NewsInsights h2,
.PageFlexibleLanding .NewsInsights h2,
.PageIndex .NewsInsights h2 {
  color: #FFFFFF;
}
.PageInvestorsLanding .NewsInsights-link,
.PagePressLanding .NewsInsights-link,
.PageSustainabilityLanding .NewsInsights-link,
.PageFlexibleLanding .NewsInsights-link,
.PageIndex .NewsInsights-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageInvestorsLanding .NewsInsights-link,
  .PagePressLanding .NewsInsights-link,
  .PageSustainabilityLanding .NewsInsights-link,
  .PageFlexibleLanding .NewsInsights-link,
  .PageIndex .NewsInsights-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding .NewsInsights-link,
  .PagePressLanding .NewsInsights-link,
  .PageSustainabilityLanding .NewsInsights-link,
  .PageFlexibleLanding .NewsInsights-link,
  .PageIndex .NewsInsights-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding .NewsInsights-link,
  .PagePressLanding .NewsInsights-link,
  .PageSustainabilityLanding .NewsInsights-link,
  .PageFlexibleLanding .NewsInsights-link,
  .PageIndex .NewsInsights-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding .NewsInsights-link,
  .PagePressLanding .NewsInsights-link,
  .PageSustainabilityLanding .NewsInsights-link,
  .PageFlexibleLanding .NewsInsights-link,
  .PageIndex .NewsInsights-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageInvestorsLanding .NewsInsights-link:hover,
.isDesktop .PagePressLanding .NewsInsights-link:hover,
.isDesktop .PageSustainabilityLanding .NewsInsights-link:hover,
.isDesktop .PageFlexibleLanding .NewsInsights-link:hover,
.isDesktop .PageIndex .NewsInsights-link:hover,
.isNotDesktop .PageInvestorsLanding .NewsInsights-link:active,
.isNotDesktop .PagePressLanding .NewsInsights-link:active,
.isNotDesktop .PageSustainabilityLanding .NewsInsights-link:active,
.isNotDesktop .PageFlexibleLanding .NewsInsights-link:active,
.isNotDesktop .PageIndex .NewsInsights-link:active {
  text-decoration: underline;
}
.isDesktop .PageInvestorsLanding .NewsInsights-link:hover:before,
.isDesktop .PagePressLanding .NewsInsights-link:hover:before,
.isDesktop .PageSustainabilityLanding .NewsInsights-link:hover:before,
.isDesktop .PageFlexibleLanding .NewsInsights-link:hover:before,
.isDesktop .PageIndex .NewsInsights-link:hover:before,
.isNotDesktop .PageInvestorsLanding .NewsInsights-link:active:before,
.isNotDesktop .PagePressLanding .NewsInsights-link:active:before,
.isNotDesktop .PageSustainabilityLanding .NewsInsights-link:active:before,
.isNotDesktop .PageFlexibleLanding .NewsInsights-link:active:before,
.isNotDesktop .PageIndex .NewsInsights-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageInvestorsLanding .NewsInsights-link:hover:before,
  .isDesktop .PagePressLanding .NewsInsights-link:hover:before,
  .isDesktop .PageSustainabilityLanding .NewsInsights-link:hover:before,
  .isDesktop .PageFlexibleLanding .NewsInsights-link:hover:before,
  .isDesktop .PageIndex .NewsInsights-link:hover:before,
  .isNotDesktop .PageInvestorsLanding .NewsInsights-link:active:before,
  .isNotDesktop .PagePressLanding .NewsInsights-link:active:before,
  .isNotDesktop .PageSustainabilityLanding .NewsInsights-link:active:before,
  .isNotDesktop .PageFlexibleLanding .NewsInsights-link:active:before,
  .isNotDesktop .PageIndex .NewsInsights-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding .NewsInsights-link,
  .PagePressLanding .NewsInsights-link,
  .PageSustainabilityLanding .NewsInsights-link,
  .PageFlexibleLanding .NewsInsights-link,
  .PageIndex .NewsInsights-link {
    letter-spacing: 0;
  }
}
.PageInvestorsLanding .NewsInsights-link:before,
.PagePressLanding .NewsInsights-link:before,
.PageSustainabilityLanding .NewsInsights-link:before,
.PageFlexibleLanding .NewsInsights-link:before,
.PageIndex .NewsInsights-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageInvestorsLanding .NewsInsights-link:before,
  .PagePressLanding .NewsInsights-link:before,
  .PageSustainabilityLanding .NewsInsights-link:before,
  .PageFlexibleLanding .NewsInsights-link:before,
  .PageIndex .NewsInsights-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageInvestorsLanding .NewsInsights-link:before,
.isDesktop .PagePressLanding .NewsInsights-link:before,
.isDesktop .PageSustainabilityLanding .NewsInsights-link:before,
.isDesktop .PageFlexibleLanding .NewsInsights-link:before,
.isDesktop .PageIndex .NewsInsights-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageInvestorsLanding .NewsInsights-link:before,
  .PagePressLanding .NewsInsights-link:before,
  .PageSustainabilityLanding .NewsInsights-link:before,
  .PageFlexibleLanding .NewsInsights-link:before,
  .PageIndex .NewsInsights-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding .NewsInsights-link:before,
  .PagePressLanding .NewsInsights-link:before,
  .PageSustainabilityLanding .NewsInsights-link:before,
  .PageFlexibleLanding .NewsInsights-link:before,
  .PageIndex .NewsInsights-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding .NewsInsights-link:before,
  .PagePressLanding .NewsInsights-link:before,
  .PageSustainabilityLanding .NewsInsights-link:before,
  .PageFlexibleLanding .NewsInsights-link:before,
  .PageIndex .NewsInsights-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding .NewsInsights-link:before,
  .PagePressLanding .NewsInsights-link:before,
  .PageSustainabilityLanding .NewsInsights-link:before,
  .PageFlexibleLanding .NewsInsights-link:before,
  .PageIndex .NewsInsights-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageInvestorsLanding .NewsInsights-news-item,
.PagePressLanding .NewsInsights-news-item,
.PageSustainabilityLanding .NewsInsights-news-item,
.PageFlexibleLanding .NewsInsights-news-item,
.PageIndex .NewsInsights-news-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.isIE .PageInvestorsLanding .NewsInsights-news-item,
.isIE .PagePressLanding .NewsInsights-news-item,
.isIE .PageSustainabilityLanding .NewsInsights-news-item,
.isIE .PageFlexibleLanding .NewsInsights-news-item,
.isIE .PageIndex .NewsInsights-news-item {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.PageInvestorsLanding .NewsInsights-news-item:before,
.PagePressLanding .NewsInsights-news-item:before,
.PageSustainabilityLanding .NewsInsights-news-item:before,
.PageFlexibleLanding .NewsInsights-news-item:before,
.PageIndex .NewsInsights-news-item:before {
  background: url('/static/img/arrow.svg') no-repeat;
}
.PageInvestorsLanding .NewsInsights-news-item-month,
.PagePressLanding .NewsInsights-news-item-month,
.PageSustainabilityLanding .NewsInsights-news-item-month,
.PageFlexibleLanding .NewsInsights-news-item-month,
.PageIndex .NewsInsights-news-item-month,
.PageInvestorsLanding .NewsInsights-news-item-title,
.PagePressLanding .NewsInsights-news-item-title,
.PageSustainabilityLanding .NewsInsights-news-item-title,
.PageFlexibleLanding .NewsInsights-news-item-title,
.PageIndex .NewsInsights-news-item-title,
.PageInvestorsLanding .NewsInsights-news-item-year,
.PagePressLanding .NewsInsights-news-item-year,
.PageSustainabilityLanding .NewsInsights-news-item-year,
.PageFlexibleLanding .NewsInsights-news-item-year,
.PageIndex .NewsInsights-news-item-year {
  color: #FFFFFF;
}
.PageInvestorsLanding .NewsInsights-news-item-day,
.PagePressLanding .NewsInsights-news-item-day,
.PageSustainabilityLanding .NewsInsights-news-item-day,
.PageFlexibleLanding .NewsInsights-news-item-day,
.PageIndex .NewsInsights-news-item-day {
  color: #ffffff;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0);
  -moz-text-stroke: 1px rgba(255, 255, 255, 0);
  text-stroke: 1px rgba(255, 255, 255, 0);
  -webkit-text-fill-color: #ffffff;
  -moz-text-fill-color: #ffffff;
  text-fill-color: #ffffff;
}
.isIE .PageInvestorsLanding .NewsInsights-news-item-day,
.isIE .PagePressLanding .NewsInsights-news-item-day,
.isIE .PageSustainabilityLanding .NewsInsights-news-item-day,
.isIE .PageFlexibleLanding .NewsInsights-news-item-day,
.isIE .PageIndex .NewsInsights-news-item-day {
  color: #FFFFFF;
}
.isDesktop .PageInvestorsLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isDesktop .PagePressLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isDesktop .PageSustainabilityLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isDesktop .PageFlexibleLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isDesktop .PageIndex .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.PageInvestorsLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.PagePressLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.PageSustainabilityLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.PageFlexibleLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.PageIndex .NewsInsights-news-item:active .NewsInsights-news-item-day {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #ffffff;
  -moz-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -moz-text-fill-color: rgba(255, 255, 255, 0);
  text-fill-color: rgba(255, 255, 255, 0);
}
.isIE .isDesktop .PageInvestorsLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .isDesktop .PagePressLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .isDesktop .PageSustainabilityLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .isDesktop .PageFlexibleLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .isDesktop .PageIndex .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .PageInvestorsLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.isIE .PagePressLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.isIE .PageSustainabilityLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.isIE .PageFlexibleLanding .NewsInsights-news-item:active .NewsInsights-news-item-day,
.isIE .PageIndex .NewsInsights-news-item:active .NewsInsights-news-item-day {
  color: rgba(255, 255, 255, 0.5);
}
.isIE .PageInvestorsLanding .NewsInsights-news-item:hover,
.isIE .PagePressLanding .NewsInsights-news-item:hover,
.isIE .PageSustainabilityLanding .NewsInsights-news-item:hover,
.isIE .PageFlexibleLanding .NewsInsights-news-item:hover,
.isIE .PageIndex .NewsInsights-news-item:hover {
  opacity: 0.7;
}
.isIE .PageInvestorsLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .PagePressLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .PageSustainabilityLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .PageFlexibleLanding .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.isIE .PageIndex .NewsInsights-news-item:hover .NewsInsights-news-item-day {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .NewsInsights {
    display: block;
  }
}
.NewsInsights-wrapper {
  width: 100%;
}
@media (max-width: 759px) {
  .NewsInsights-news {
    margin-bottom: unset;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news {
    margin-bottom: 0.40322581vw;
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news {
    margin-bottom: 0.34722222vw;
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news {
    margin-bottom: 6.66666667px;
    margin-top: 46.66666667px;
  }
}
.NewsInsights-news-item {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
@media (max-width: 759px) {
  .NewsInsights-news-item {
    height: 25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item {
    height: 9.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item {
    height: 8.33333333vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item {
    height: 160px;
  }
}
.NewsInsights-news-item a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.NewsInsights-news-item-month {
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.isDesktop .NewsInsights-news-item-month {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.NewsInsights-news-item-month span {
  display: none;
}
@media (max-width: 759px) {
  .NewsInsights-news-item-month {
    width: 10.9375vw;
    left: 0;
    top: 15vw;
    text-align: center;
  }
  .NewsInsights-news-item-month span {
    display: inline;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-month {
    top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-month {
    top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-month {
    top: 53.33333333px;
  }
}
.NewsInsights-news-item-year {
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.isDesktop .NewsInsights-news-item-year {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .NewsInsights-news-item-year {
    width: 10.9375vw;
    left: 6.25vw;
    top: 15vw;
    text-align: center;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-year {
    top: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-year {
    top: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-year {
    top: 80px;
  }
}
.NewsInsights-news-item-day {
  position: absolute;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05em;
  color: #000000;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  -moz-text-stroke: 1px rgba(0, 0, 0, 0);
  text-stroke: 1px rgba(0, 0, 0, 0);
  -webkit-text-fill-color: #000000;
  -moz-text-fill-color: #000000;
  text-fill-color: #000000;
}
.isIE .NewsInsights-news-item-day {
  color: rgba(0, 0, 0, 0.5);
}
.isDesktop .NewsInsights-news-item-day {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .NewsInsights-news-item-day {
    font-size: 9.375vw;
    left: 2.1875vw;
    top: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-day {
    left: 3.22580645vw;
    top: 1.93548387vw;
    font-size: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-day {
    left: 2.77777778vw;
    top: 1.66666667vw;
    font-size: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-day {
    left: 53.33333333px;
    top: 32px;
    font-size: 80px;
  }
}
.NewsInsights-news-item-title {
  position: absolute;
  overflow: hidden;
}
.isDesktop .NewsInsights-news-item-title {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-title[data-lines="1"] {
    top:  61.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-title[data-lines="1"] {
    top:  3.1944444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-title[data-lines="1"] {
    top:  3.7096774193548385vw;
  }
}
@media (max-width: 759px) {
  .NewsInsights-news-item-title[data-lines="1"] {
    top:  7.5vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-title[data-lines="2"] {
    top:  52px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-title[data-lines="2"] {
    top:  2.7083333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-title[data-lines="2"] {
    top:  3.1451612903225805vw;
  }
}
@media (max-width: 759px) {
  .NewsInsights-news-item-title[data-lines="2"] {
    top:  4.375vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-title[data-lines="3"] {
    top:  37.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-title[data-lines="3"] {
    top:  1.9444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-title[data-lines="3"] {
    top:  2.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .NewsInsights-news-item-title[data-lines="3"] {
    top:  2.5vw;
  }
}
@media (max-width: 759px) {
  .NewsInsights-news-item-title {
    left: 18.75vw;
    right: 0vw;
    line-height: 1.42857143;
    letter-spacing: -0.00783333em;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item-title {
    left: 11.29032258vw;
    right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item-title {
    left: 9.72222222vw;
    right: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item-title {
    left: 186.66666667px;
    right: 53.33333333px;
  }
}
.NewsInsights-news-item:last-child {
  border-bottom: none;
}
.isDesktop .NewsInsights-news-item:hover .NewsInsights-news-item-day,
.NewsInsights-news-item:active .NewsInsights-news-item-day {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #000000;
  -moz-text-stroke: 1px #000000;
  text-stroke: 1px #000000;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
}
.isDesktop .NewsInsights-news-item:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.NewsInsights-news-item:active .NewsInsights-news-item-month,
.NewsInsights-news-item:active .NewsInsights-news-item-day,
.NewsInsights-news-item:active .NewsInsights-news-item-title {
  opacity: 0.6049;
}
.NewsInsights-news-item:active:before {
  opacity: 1;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .NewsInsights-news-item:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .isDesktop .NewsInsights-news-item:active:before {
    -webkit-transform: translate3d(2.5vw, 0, 0) !important;
            transform: translate3d(2.5vw, 0, 0) !important;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .NewsInsights-news-item:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .NewsInsights-news-item:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .NewsInsights-news-item:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.NewsInsights-news-item:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  background: url('/static/img/arrow-dark.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .NewsInsights-news-item:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .NewsInsights-news-item:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-news-item:before {
    margin-top: -0.80645161vw;
    width: 2.09677419vw;
    height: 1.4516129vw;
    right: 0.64516129vw;
    background-size: 2.09677419vw 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-news-item:before {
    margin-top: -0.69444444vw;
    width: 1.80555556vw;
    height: 1.25vw;
    right: 0.55555556vw;
    background-size: 1.80555556vw 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .NewsInsights-news-item:before {
    margin-top: -13.33333333px;
    width: 34.66666667px;
    height: 24px;
    right: 10.66666667px;
    background-size: 34.66666667px 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.NewsInsights-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .NewsInsights-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .NewsInsights-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .NewsInsights-link:hover,
.isNotDesktop .NewsInsights-link:active {
  text-decoration: underline;
}
.isDesktop .NewsInsights-link:hover:before,
.isNotDesktop .NewsInsights-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .NewsInsights-link:hover:before,
  .isNotDesktop .NewsInsights-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .NewsInsights-link {
    letter-spacing: 0;
  }
}
.NewsInsights-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .NewsInsights-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .NewsInsights-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .NewsInsights-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsInsights-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsInsights-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .NewsInsights-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
