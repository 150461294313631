.InspirationProducts {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.brand--thorn .InspirationProducts {
  background-color: #0E1C29;
}
@media (min-width: 1920px) {
  .InspirationProducts {
    padding-bottom: 101.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts {
    padding-bottom: 5.27777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts {
    padding-bottom: 6.12903226vw;
  }
}
@media (max-width: 759px) {
  .InspirationProducts {
    padding: 0 6.25vw 9.375vw;
  }
}
.InspirationProducts-img {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: none;
  display: block;
}
.brand--thorn .InspirationProducts-img {
  bottom: 0;
  z-index: -1;
}
@media (min-width: 1920px) {
  .InspirationProducts-img {
    width: 500px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts-img {
    width: 26.04166667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts-img {
    width: 30.24193548vw;
  }
}
@media (max-width: 759px) {
  .InspirationProducts-img {
    width: 62.5vw;
  }
  .brand--thorn .InspirationProducts-img {
    width: 87.5vw;
  }
}
.InspirationProducts-img img {
  width: 100%;
}
.InspirationProducts-title {
  margin: 0 auto;
  text-align: center;
  color: #111112;
  pointer-events: none;
  display: block;
}
@media (min-width: 1920px) {
  .InspirationProducts-title {
    padding: 300px 186.66666667px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts-title {
    padding: 15.625vw 9.72222222vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts-title {
    padding: 18.14516129vw 11.29032258vw 0;
  }
}
@media (max-width: 759px) {
  .InspirationProducts-title {
    font-size: 9.375vw;
    padding-top: 53.125vw;
    max-width: unset;
  }
}
.brand--thorn .InspirationProducts-title {
  color: #FFFFFF;
}
.InspirationProducts-description {
  margin: 0 auto;
  text-align: center;
  letter-spacing: normal;
  color: #6F7380;
  text-transform: none;
  pointer-events: none;
  line-height: 1.42857143;
  display: block;
}
@media (min-width: 1920px) {
  .InspirationProducts-description {
    width: 480px;
    margin-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts-description {
    width: 25vw;
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts-description {
    width: 29.03225806vw;
    margin-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .InspirationProducts-description {
    margin-top: 3.75vw;
    font-size: 3.4375vw;
  }
}
.InspirationProducts-viewAll {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  display: block;
  letter-spacing: normal;
  text-align: center;
  color: #111112;
}
@media (max-width: 759px) {
  .InspirationProducts-viewAll {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts-viewAll {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts-viewAll {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .InspirationProducts-viewAll {
    font-size: 18.66666667px;
  }
}
.isDesktop .InspirationProducts-viewAll:hover,
.isNotDesktop .InspirationProducts-viewAll:active {
  text-decoration: underline;
}
.isDesktop .InspirationProducts-viewAll:hover:before,
.isNotDesktop .InspirationProducts-viewAll:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .InspirationProducts-viewAll:hover:before,
  .isNotDesktop .InspirationProducts-viewAll:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .InspirationProducts-viewAll {
    letter-spacing: 0;
  }
}
.InspirationProducts-viewAll:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .InspirationProducts-viewAll:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .InspirationProducts-viewAll:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .InspirationProducts-viewAll:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts-viewAll:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts-viewAll:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InspirationProducts-viewAll:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.brand--thorn .InspirationProducts-viewAll {
  color: #FFFFFF;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: block;
}
@media (max-width: 759px) {
  .brand--thorn .InspirationProducts-viewAll {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .InspirationProducts-viewAll {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .InspirationProducts-viewAll {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .brand--thorn .InspirationProducts-viewAll {
    font-size: 18.66666667px;
  }
}
.isDesktop .brand--thorn .InspirationProducts-viewAll:hover,
.isNotDesktop .brand--thorn .InspirationProducts-viewAll:active {
  text-decoration: underline;
}
.isDesktop .brand--thorn .InspirationProducts-viewAll:hover:before,
.isNotDesktop .brand--thorn .InspirationProducts-viewAll:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .brand--thorn .InspirationProducts-viewAll:hover:before,
  .isNotDesktop .brand--thorn .InspirationProducts-viewAll:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .brand--thorn .InspirationProducts-viewAll {
    letter-spacing: 0;
  }
}
.brand--thorn .InspirationProducts-viewAll:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow.svg') no-repeat;
}
@media (max-width: 759px) {
  .brand--thorn .InspirationProducts-viewAll:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .brand--thorn .InspirationProducts-viewAll:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .brand--thorn .InspirationProducts-viewAll:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .InspirationProducts-viewAll:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .InspirationProducts-viewAll:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .brand--thorn .InspirationProducts-viewAll:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .brand--thorn .InspirationProducts-viewAll {
    margin-top: 52px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .InspirationProducts-viewAll {
    margin-top: 2.70833333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .InspirationProducts-viewAll {
    margin-top: 3.14516129vw;
  }
}
@media (max-width: 759px) {
  .brand--thorn .InspirationProducts-viewAll {
    margin-top: 9.375vw;
  }
}
.InspirationProducts-viewAll:before {
  margin-right: 0;
}
.isDesktop .InspirationProducts-viewAll:hover,
.isNotDesktop .InspirationProducts-viewAll:active {
  text-decoration: underline;
}
@media (min-width: 1920px) {
  .isDesktop .InspirationProducts-viewAll:hover:before,
  .isNotDesktop .InspirationProducts-viewAll:active:before {
    -webkit-transform: translate3d(-5.33333333px, 0, 0);
            transform: translate3d(-5.33333333px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .InspirationProducts-viewAll:hover:before,
  .isNotDesktop .InspirationProducts-viewAll:active:before {
    -webkit-transform: translate3d(-0.27777778vw, 0, 0);
            transform: translate3d(-0.27777778vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .InspirationProducts-viewAll:hover:before,
  .isNotDesktop .InspirationProducts-viewAll:active:before {
    -webkit-transform: translate3d(-0.32258065vw, 0, 0);
            transform: translate3d(-0.32258065vw, 0, 0);
  }
}
@media (max-width: 759px) {
  .isDesktop .InspirationProducts-viewAll:hover:before,
  .isNotDesktop .InspirationProducts-viewAll:active:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InspirationProducts-viewAll {
    margin-top: 52px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationProducts-viewAll {
    margin-top: 2.70833333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationProducts-viewAll {
    margin-top: 3.14516129vw;
  }
}
@media (max-width: 759px) {
  .InspirationProducts-viewAll {
    margin-top: 9.375vw;
  }
}
.InspirationProducts-link {
  text-decoration: none;
}
.isDesktop .InspirationProducts-link:hover .InspirationProducts-viewAll,
.isMobile .InspirationProducts-link:active .InspirationProducts-viewAll {
  text-decoration: underline;
}
@media (min-width: 1920px) {
  .isDesktop .InspirationProducts-link:hover .InspirationProducts-viewAll:before,
  .isMobile .InspirationProducts-link:active .InspirationProducts-viewAll:before {
    -webkit-transform: translate3d(-5.33333333px, 0, 0);
            transform: translate3d(-5.33333333px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .InspirationProducts-link:hover .InspirationProducts-viewAll:before,
  .isMobile .InspirationProducts-link:active .InspirationProducts-viewAll:before {
    -webkit-transform: translate3d(-0.27777778vw, 0, 0);
            transform: translate3d(-0.27777778vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .InspirationProducts-link:hover .InspirationProducts-viewAll:before,
  .isMobile .InspirationProducts-link:active .InspirationProducts-viewAll:before {
    -webkit-transform: translate3d(-0.32258065vw, 0, 0);
            transform: translate3d(-0.32258065vw, 0, 0);
  }
}
@media (max-width: 759px) {
  .isDesktop .InspirationProducts-link:hover .InspirationProducts-viewAll:before,
  .isMobile .InspirationProducts-link:active .InspirationProducts-viewAll:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
