.Filters-mobile {
  display: none;
}
@media (max-width: 759px) {
  .Filters-mobile {
    display: block;
  }
}
.Filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
  position: relative;
}
@media (max-width: 759px) {
  .Filters {
    display: none;
  }
}
.Filters-filterItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  letter-spacing: normal;
  width: 100%;
}
.Filters-filterItem:first-child {
  margin-left: 0;
}
@media (max-width: 759px) {
  .Filters-filterItem {
    margin-left: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Filters-filterItem {
    margin-left: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Filters-filterItem {
    margin-left: 2.77777778vw;
  }
}
.Filters-filterItemTitle {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .Filters-filterItemTitle {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Filters-filterItemTitle {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Filters-filterItemTitle {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Filters-filterItemTitle {
    font-size: 16px;
  }
}
.Filters-filterClear {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: visible;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
}
@media (max-width: 759px) {
  .Filters-filterClear {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Filters-filterClear {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Filters-filterClear {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Filters-filterClear {
    font-size: 16px;
  }
}
.brand--thorn .Filters-filterClear {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .Filters-filterClear {
    margin-left: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Filters-filterClear {
    margin-left: 3.22580645vw;
    padding-bottom: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Filters-filterClear {
    margin-left: 2.77777778vw;
    padding-bottom: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .Filters-filterClear {
    margin-left: 53.33333333px;
    padding-bottom: 13.33333333px;
  }
}
.isDesktop .Filters-filterClear:hover,
.isNotDesktop .Filters-filterClear:active {
  text-decoration: underline;
}
.Filters-filterClear--hide {
  visibility: hidden;
}
