@media (max-width: 759px) {
  .CampusCenterPopup .Popup-close {
    background-color: #111112;
  }
  .CampusCenterPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.CampusCenterPopup-content {
  background: #F6F6F6;
  width: 100%;
}
.CampusCenterPopup-items {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .CampusCenterPopup-items {
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopup-items {
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopup-items {
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopup-items {
    padding-bottom:  12.5vw;
  }
}
.CampusCenterPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.CampusCenterPopup-map {
  width: 100%;
}
@media (min-width: 1920px) {
  .CampusCenterPopup-map {
    height:  746.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopup-map {
    height:  38.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopup-map {
    height:  45.16129032258064vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopup-map {
    height:  62.5vw;
  }
}
.CampusCenterPopup .uc-embedding-container {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .CampusCenterPopup .uc-embedding-container {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopup .uc-embedding-container {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopup .uc-embedding-container {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CampusCenterPopup .uc-embedding-container {
    font-size: 26.66666667px;
  }
}
.CampusCenterPopup .uc-embedding-container .uc-embedding-wrapper .uc-embedding-buttons .uc-embedding-more-info {
  border: 1px solid #4a4d55;
  background-color: #4a4d55;
  color: #ffffff;
}
.CampusCenterPopup .uc-embedding-container .uc-embedding-wrapper .uc-embedding-buttons .uc-embedding-accept {
  border: 1px solid #d4d4d4;
  background-color: #ffffff;
  color: #111112;
}
.CampusCenterPopup .uc-embedding-container .uc-embedding-wrapper span:last-of-type {
  display: none;
}
