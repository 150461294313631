.RegionLanguagePopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1300;
  -webkit-transition: visibility 0.3s step-start;
  transition: visibility 0.3s step-start;
  visibility: visible;
}
@media (max-width: 759px) {
  .RegionLanguagePopup {
    top: 12.5vw;
    z-index: 1000;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup {
    top: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup {
    top: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup {
    top: 106.66666667px;
  }
}
.RegionLanguagePopup--hide {
  visibility: hidden;
  -webkit-transition: visibility 0.3s step-end;
  transition: visibility 0.3s step-end;
}
.RegionLanguagePopup--hide .RegionLanguagePopup-bg {
  opacity: 0;
}
.RegionLanguagePopup--hide .RegionLanguagePopup-inputWrapper {
  opacity: 0;
  -webkit-transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s;
  transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .RegionLanguagePopup--hide .RegionLanguagePopup-inputWrapper {
    -webkit-transform: translateY(6.25vw);
        -ms-transform: translateY(6.25vw);
            transform: translateY(6.25vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup--hide .RegionLanguagePopup-inputWrapper {
    -webkit-transform: translateY(3.22580645vw);
        -ms-transform: translateY(3.22580645vw);
            transform: translateY(3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup--hide .RegionLanguagePopup-inputWrapper {
    -webkit-transform: translateY(2.77777778vw);
        -ms-transform: translateY(2.77777778vw);
            transform: translateY(2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup--hide .RegionLanguagePopup-inputWrapper {
    -webkit-transform: translateY(53.33333333px);
        -ms-transform: translateY(53.33333333px);
            transform: translateY(53.33333333px);
  }
}
.RegionLanguagePopup-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(21, 25, 40, 0.5);
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
  opacity: 1;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-bg {
    top: -6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-bg {
    top: -5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-bg {
    top: -106.66666667px;
  }
}
.RegionLanguagePopup-inputWrapper {
  position: absolute;
  bottom: 0;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s opacity, 0.3s transform;
  transition: 0.3s opacity, 0.3s transform, 0.3s -webkit-transform;
  background: #111112;
  width: 100%;
  height: 100%;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-inputWrapper {
    height: 25.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-inputWrapper {
    height: 22.22222222vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-inputWrapper {
    height: 426.66666667px;
  }
}
.RegionLanguagePopup-regionLanguageWrapper {
  height: 100%;
}
.RegionLanguagePopup-selectsWrapper {
  margin: 0 auto;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-selectsWrapper {
    margin-top: 12.5vw;
    width: 87.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-selectsWrapper {
    margin-top: 4.83870968vw;
    width: 61.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-selectsWrapper {
    margin-top: 4.16666667vw;
    width: 52.77777778vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-selectsWrapper {
    margin-top: 80px;
    width: 1013.33333333px;
  }
}
@media (max-width: 759px) {
  .RegionLanguagePopup-selectTitle {
    margin-bottom: 8.4375vw;
    font-size: 5.46875vw;
  }
}
.RegionLanguagePopup-visitButton {
  -webkit-transition: 0.15s visible;
  transition: 0.15s visible;
  visibility: visible;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-visitButton {
    margin: 12.5vw auto 0;
    width: 50vw;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-visitButton {
    margin: 4.75806452vw auto 0;
    width: 12.90322581vw;
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-visitButton {
    margin: 4.09722222vw auto 0;
    width: 11.11111111vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-visitButton {
    margin: 78.66666667px auto 0;
    width: 213.33333333px;
    height: 53.33333333px;
  }
}
.RegionLanguagePopup-visitButton.hide {
  visibility: hidden;
}
.RegionLanguagePopup-selectsRegLangWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-selectsRegLangWrapper {
    display: block;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
}
@media (max-width: 759px) {
  .RegionLanguagePopup-selectRegion {
    margin-bottom: 18.75vw;
    width: 87.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-selectRegion {
    width: 29.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-selectRegion {
    width: 25vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-selectRegion {
    width: 480px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-selectRegionSelect {
    margin-top: 0.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-selectRegionSelect {
    margin-top: 0.55555556vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-selectRegionSelect {
    margin-top: 10.66666667px;
  }
}
@media (max-width: 759px) {
  .RegionLanguagePopup-selectLanguage {
    width: 87.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-selectLanguage {
    width: 29.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-selectLanguage {
    width: 25vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-selectLanguage {
    width: 480px;
  }
}
.RegionLanguagePopup-filterRegionItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  letter-spacing: normal;
  width: 100%;
}
.RegionLanguagePopup-filterRegionItem:first-child {
  margin-left: 0;
}
.RegionLanguagePopup-filterRegionItemTitle {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    line-height: 1.94444444;
    margin-bottom: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    margin-top: 0.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    margin-top: 0.55555556vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemTitle {
    margin-top: 10.66666667px;
  }
}
.RegionLanguagePopup-filterRegionItemSelect {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  width: 100%;
}
.RegionLanguagePopup-filterRegionItemSelectText {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 2.91666667;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    font-size: 16px;
  }
}
.brand--thorn .RegionLanguagePopup-filterRegionItemSelectText {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 0.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 0.13888889vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 2.66666667px;
  }
}
.filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText {
  line-height: 1.25;
}
@media (max-width: 759px) {
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText {
    padding-bottom: 6.66666667px;
  }
}
.filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText p {
  width: 88%;
  text-overflow: unset;
  overflow: hidden;
  white-space: pre-wrap;
}
.isDesktop .RegionLanguagePopup-filterRegionItemSelectText:hover:after,
.isNotDesktop .RegionLanguagePopup-filterRegionItemSelectText:active:after {
  width: 0;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.isDesktop .RegionLanguagePopup-filterRegionItemSelectText:hover:before,
.isNotDesktop .RegionLanguagePopup-filterRegionItemSelectText:active:before {
  width: 100%;
  -webkit-transition: 0.15s width 0.12s ease-out;
  transition: 0.15s width 0.12s ease-out;
}
.RegionLanguagePopup-filterRegionItemSelectText:after {
  content: '';
  background: #111112;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.brand--thorn .RegionLanguagePopup-filterRegionItemSelectText:after {
  background: #FFFFFF;
}
.RegionLanguagePopup-filterRegionItemSelectText:before {
  content: '';
  background: #111112;
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 0;
  left: 0;
}
.brand--thorn .RegionLanguagePopup-filterRegionItemSelectText:before {
  background: #FFFFFF;
}
.RegionLanguagePopup-filterRegionItemSelectText > p {
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.RegionLanguagePopup-filterRegionItemSelectText span {
  position: absolute;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemSelectText span {
    right: 1.875vw;
    border-left: 1.25vw solid transparent;
    border-right: 1.25vw solid transparent;
    border-top: 1.5625vw solid #111112;
    top: 4.375vw;
  }
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText span {
    top: 3.75vw;
  }
  .brand--thorn .RegionLanguagePopup-filterRegionItemSelectText span {
    border-top: 1.5625vw solid #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemSelectText span {
    right: 0.48387097vw;
    border-left: 0.32258065vw solid transparent;
    border-right: 0.32258065vw solid transparent;
    border-top: 0.40322581vw solid #111112;
    top: 1.12903226vw;
  }
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText span {
    top: 0.96774194vw;
  }
  .brand--thorn .RegionLanguagePopup-filterRegionItemSelectText span {
    border-top: 0.40322581vw solid #FFFFFF;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemSelectText span {
    right: 0.41666667vw;
    border-left: 0.27777778vw solid transparent;
    border-right: 0.27777778vw solid transparent;
    border-top: 0.34722222vw solid #111112;
    top: 0.97222222vw;
  }
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText span {
    top: 0.83333333vw;
  }
  .brand--thorn .RegionLanguagePopup-filterRegionItemSelectText span {
    border-top: 0.34722222vw solid #FFFFFF;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemSelectText span {
    right: 8px;
    border-left: 5.33333333px solid transparent;
    border-right: 5.33333333px solid transparent;
    border-top: 6.66666667px solid #111112;
    top: 18.66666667px;
  }
  .filterItemTwoLine .RegionLanguagePopup-filterRegionItemSelectText span {
    top: 16px;
  }
  .brand--thorn .RegionLanguagePopup-filterRegionItemSelectText span {
    border-top: 6.66666667px solid #FFFFFF;
  }
}
.RegionLanguagePopup-filterRegionItemSelectText > span {
  border-top-color: #FFFFFF;
}
.RegionLanguagePopup-filterRegionItemSelectText:before {
  background: #FFFFFF;
}
.RegionLanguagePopup-filterRegionItemSelectText:after {
  background: #FFFFFF;
}
.RegionLanguagePopup-filterRegionItemSelectTag {
  display: none;
}
.isMobile .RegionLanguagePopup-filterRegionItemSelectTag {
  display: block;
  opacity: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.RegionLanguagePopup-filterRegionItemSelectMenu {
  position: absolute;
  bottom: calc(100% + 3px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: #111112;
  cursor: default;
  z-index: 30;
  opacity: 0;
  visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  overflow: auto;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemSelectMenu {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemSelectMenu {
    left: -1.61290323vw;
    right: -1.61290323vw;
    -webkit-transform: translateY(1.61290323vw);
        -ms-transform: translateY(1.61290323vw);
            transform: translateY(1.61290323vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemSelectMenu {
    left: -1.38888889vw;
    right: -1.38888889vw;
    -webkit-transform: translateY(1.38888889vw);
        -ms-transform: translateY(1.38888889vw);
            transform: translateY(1.38888889vw);
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemSelectMenu {
    left: -26.66666667px;
    right: -26.66666667px;
    -webkit-transform: translateY(26.66666667px);
        -ms-transform: translateY(26.66666667px);
            transform: translateY(26.66666667px);
  }
}
.RegionLanguagePopup-filterRegionItemSelect.active .RegionLanguagePopup-filterRegionItemSelectMenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
}
.RegionLanguagePopup-filterRegionItemSelectMenuVal {
  text-decoration: none;
  color: inherit;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 2.85714286;
  -webkit-transition: 0.15s background-color ease-out;
  transition: 0.15s background-color ease-out;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    font-size: 16px;
  }
}
.RegionLanguagePopup-filterRegionItemSelectMenuVal:first-child {
  border-top: 0;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    line-height: 3.63636364;
    padding: 1.25vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    padding: 0.32258065vw 1.61290323vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    padding: 0.27777778vw 1.38888889vw 0;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterRegionItemSelectMenuVal {
    padding: 5.33333333px 26.66666667px 0;
  }
}
.isDesktop .RegionLanguagePopup-filterRegionItemSelectMenuVal:hover,
.isNotDesktop .RegionLanguagePopup-filterRegionItemSelectMenuVal:active {
  background: #38383B;
}
.RegionLanguagePopup-filterRegionItemSelectMenuVal--active {
  cursor: default;
  pointer-events: none;
  color: #6F7380;
}
.isDesktop .RegionLanguagePopup-filterRegionItemSelectMenuVal--active:hover,
.isNotDesktop .RegionLanguagePopup-filterRegionItemSelectMenuVal--active:active {
  background: #111112;
}
.RegionLanguagePopup-filterLanguageItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  letter-spacing: normal;
  width: 100%;
}
.RegionLanguagePopup-filterLanguageItem:first-child {
  margin-left: 0;
}
.RegionLanguagePopup-filterLanguageItemTitle {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    font-size: 16px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    margin-top: 0.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    margin-top: 0.55555556vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-filterLanguageItemTitle {
    margin-top: 10.66666667px;
  }
}
.RegionLanguagePopup-close {
  position: absolute;
  float: right;
  cursor: pointer;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
}
@media (max-width: 759px) {
  .isDesktop .RegionLanguagePopup-close:hover .RegionLanguagePopup-closeItem,
  .isNotDesktop .RegionLanguagePopup-close:active .RegionLanguagePopup-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .RegionLanguagePopup-close:hover .RegionLanguagePopup-closeItem,
  .isNotDesktop .RegionLanguagePopup-close:active .RegionLanguagePopup-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .RegionLanguagePopup-close:hover .RegionLanguagePopup-closeItem,
  .isNotDesktop .RegionLanguagePopup-close:active .RegionLanguagePopup-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .RegionLanguagePopup-close:hover .RegionLanguagePopup-closeItem,
  .isNotDesktop .RegionLanguagePopup-close:active .RegionLanguagePopup-closeItem {
    width: 42.66666667px;
  }
}
@media (max-width: 759px) {
  .RegionLanguagePopup-close {
    width: 3.75vw;
    height: 3.75vw;
    right: 4.375vw;
    top: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-close {
    width: 2.58064516vw;
    height: 2.09677419vw;
    right: 2.58064516vw;
    top: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-close {
    width: 2.22222222vw;
    height: 1.80555556vw;
    right: 2.22222222vw;
    top: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-close {
    width: 42.66666667px;
    height: 34.66666667px;
    right: 42.66666667px;
    top: 42.66666667px;
  }
}
.RegionLanguagePopup-closeItem {
  position: absolute;
  height: 2px;
  background: #FFFFFF;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.RegionLanguagePopup-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.RegionLanguagePopup-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (max-width: 759px) {
  .RegionLanguagePopup-closeItem {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionLanguagePopup-closeItem {
    width: 2.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionLanguagePopup-closeItem {
    width: 1.80555556vw;
  }
}
@media (min-width: 1920px) {
  .RegionLanguagePopup-closeItem {
    width: 34.66666667px;
  }
}
