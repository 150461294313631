.ContactLightForum {
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .ContactLightForum-mobileText {
    width: 46.875vw;
    line-height: 6.25vw;
  }
}
.ContactLightForum-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactLightForum-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactLightForum-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactLightForum-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    top: 7.8125vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactLightForum-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
@media (max-width: 759px) {
  .ContactLightForum-image {
    padding: 0 6.25vw;
  }
}
.ContactLightForum-image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 759px) {
  .ContactLightForum-infoWrapper {
    padding: 0 6.25vw 25vw 6.25vw;
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-infoWrapper {
    margin-bottom: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-infoWrapper {
    margin-bottom: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-infoWrapper {
    margin-bottom: 186.66666667px;
  }
}
.ContactLightForum-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .ContactLightForum-info {
    display: block;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-info {
    margin-top: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-info {
    margin-top: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-info {
    margin-top: 66.66666667px;
  }
}
.ContactLightForum-info:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .ContactLightForum-info:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-info:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-info:not(:last-child):after {
    bottom: -1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-info:not(:last-child):after {
    bottom: -33.33333333px;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-address {
    -ms-flex-preferred-size:  346.66666666666663px;
        flex-basis:  346.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-address {
    -ms-flex-preferred-size:  18.055555555555557vw;
        flex-basis:  18.055555555555557vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-address {
    -ms-flex-preferred-size:  20.967741935483872vw;
        flex-basis:  20.967741935483872vw;
  }
}
@media (max-width: 759px) {
  .ContactLightForum-address {
    -ms-flex-preferred-size:  81.25vw;
        flex-basis:  81.25vw;
  }
}
.ContactLightForum-address span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactLightForum-address span {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-contacts {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-contacts {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-contacts {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ContactLightForum-contacts {
    margin-left:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ContactLightForum-contacts {
    padding-top: 7.8125vw;
    padding-bottom: 9.375vw;
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.ContactLightForum-contacts span {
  display: block;
  color: #6F7380;
}
.ContactLightForum-contacts a {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .ContactLightForum-contacts a {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.isDesktop .ContactLightForum-contacts a:hover,
.isNotDesktop .ContactLightForum-contacts a:active {
  text-decoration: none;
}
.ContactLightForum-contacts a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ContactLightForum-icon {
  height: auto;
  width: auto;
  margin-left: auto;
  background: url('/static/svg/geo-icon.svg') no-repeat;
}
@media (max-width: 759px) {
  .ContactLightForum-icon {
    width: 6.5625vw;
    height: 10vw;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-icon {
    width: 1.85483871vw;
    height: 2.90322581vw;
    margin-right: 1.61290323vw;
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-icon {
    width: 1.59722222vw;
    height: 2.5vw;
    margin-right: 1.38888889vw;
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-icon {
    width: 30.66666667px;
    height: 48px;
    margin-right: 26.66666667px;
    margin-top: 20px;
  }
}
@media (max-width: 759px) {
  .ContactLightForum-icon svg {
    width: 6.5625vw;
    height: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactLightForum-icon svg {
    width: 1.85483871vw;
    height: 2.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactLightForum-icon svg {
    width: 1.59722222vw;
    height: 2.5vw;
  }
}
@media (min-width: 1920px) {
  .ContactLightForum-icon svg {
    width: 30.66666667px;
    height: 48px;
  }
}
.ContactLightForum-icon span {
  display: none;
}
@media (max-width: 759px) {
  .ContactLightForum-icon span {
    display: block;
    height: 100%;
    margin-left: 9.6875vw;
    line-height: 9.375vw;
  }
}
.isDesktop .ContactLightForum-icon:hover,
.isNotDesktop .ContactLightForum-icon:active {
  background: url('/static/svg/geo-icon-dark.svg') no-repeat;
  -webkit-transition: background 0.15s;
  transition: background 0.15s;
  cursor: pointer;
}
