@media (min-width: 1920px) {
  .UpdateProfile {
    padding-top:  33.33333333333333px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateProfile {
    padding-top:  1.7361111111111112vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateProfile {
    padding-top:  2.0161290322580645vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .UpdateProfile {
    padding-top:  5.3125vw;
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .UpdateProfile-title {
    font-size:  24px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .UpdateProfile-title {
    font-size:  1.25vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .UpdateProfile-title {
    font-size:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .UpdateProfile-title {
    font-size:  5.625vw;
  }
}
.UpdateProfile-description {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .UpdateProfile-description {
    margin-top:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateProfile-description {
    margin-top:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateProfile-description {
    margin-top:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .UpdateProfile-description {
    margin-top:  5vw;
  }
}
.UpdateProfile-fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .UpdateProfile-fields {
    margin-top:  57.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateProfile-fields {
    margin-top:  2.986111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateProfile-fields {
    margin-top:  3.467741935483871vw;
  }
}
@media (max-width: 759px) {
  .UpdateProfile-fields {
    margin-top:  4.6875vw;
  }
}
.UpdateProfile-field {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47.3%;
          flex: 0 0 47.3%;
}
.UpdateProfile-field:first-child {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  margin-top: 0;
}
@media (max-width: 759px) {
  .UpdateProfile-field {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
@media (min-width: 1920px) {
  .UpdateProfile-field {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateProfile-field {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateProfile-field {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .UpdateProfile-field {
    margin-top:  3.125vw;
  }
}
.UpdateProfile-fieldTitle {
  text-transform: uppercase;
  color: #6F7380;
}
@media (min-width: 1920px) {
  .UpdateProfile-fieldTitle {
    margin-bottom:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateProfile-fieldTitle {
    margin-bottom:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateProfile-fieldTitle {
    margin-bottom:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .UpdateProfile-fieldTitle {
    margin-bottom:  0vw;
  }
}
