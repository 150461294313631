.ProductsSections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
.ProductsSections-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsSections-sections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsSections-sections.hide {
  display: none;
}
.ProductsSections-sectionsFiltered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsSections-sectionsFilteredItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsSections-sectionsFilteredItemsNo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 80vh;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo {
    height: 60vh;
    padding: 0 6.25vw;
  }
}
.ProductsSections-sectionsFilteredItemsNo.hide {
  display: none;
}
.ProductsSections-sectionsFilteredItemsNo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-wrapper {
    width: 178.125vw;
    min-height: 62.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsSections-sectionsFilteredItemsNo-wrapper {
    width: 45.96774194vw;
    min-height: 16.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsSections-sectionsFilteredItemsNo-wrapper {
    width: 39.58333333vw;
    min-height: 13.88888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsSections-sectionsFilteredItemsNo-wrapper {
    width: 760px;
    min-height: 266.66666667px;
  }
}
.ProductsSections-sectionsFilteredItemsNo-title {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  color: #111112;
}
@media (min-width: 1920px) {
  .ProductsSections-sectionsFilteredItemsNo-title {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsSections-sectionsFilteredItemsNo-title {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsSections-sectionsFilteredItemsNo-title {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-title {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-title {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.brand--thorn .ProductsSections-sectionsFilteredItemsNo-title {
  color: #FFFFFF;
}
.ProductsSections-sectionsFilteredItemsNo-text {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsSections-sectionsFilteredItemsNo-text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsSections-sectionsFilteredItemsNo-text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsSections-sectionsFilteredItemsNo-text {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-text {
    text-align: center;
  }
}
.brand--thorn .ProductsSections-sectionsFilteredItemsNo-text {
  color: #FFFFFF;
}
.ProductsSections-sectionsFilteredItemsNo-clear {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  width: 100%;
  background-color: #111112;
  color: #FFFFFF;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 16px;
  }
}
.ProductsSections-sectionsFilteredItemsNo-clear:visited {
  color: #FFFFFF;
}
.isDesktop .ProductsSections-sectionsFilteredItemsNo-clear:hover,
.isNotDesktop .ProductsSections-sectionsFilteredItemsNo-clear:active {
  background-color: transparent;
  color: #111112;
  border: 1px solid #111112;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    height: 53.33333333px;
  }
}
.ProductsSections-sectionsFilteredItemsNo-clear.disabled {
  pointer-events: none;
  background-color: #A6A8B3;
  color: #FFFFFF;
  border: 1px solid #A6A8B3;
}
.brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  width: 100%;
  background-color: #FFFFFF;
  color: #111112;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
}
@media (max-width: 759px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    font-size: 16px;
  }
}
.brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear:visited {
  color: #111112;
}
.isDesktop .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear:hover,
.isNotDesktop .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear:active {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear {
    height: 53.33333333px;
  }
}
.brand--thorn .ProductsSections-sectionsFilteredItemsNo-clear:hover {
  border-color: #FFFFFF;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    width: 75vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    width: 19.35483871vw;
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    width: 16.66666667vw;
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductsSections-sectionsFilteredItemsNo-clear {
    width: 320px;
    margin-top: 53.33333333px;
  }
}
.ProductsSections-sectionsFiltered.hide {
  display: none;
}
