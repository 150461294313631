.CareersLandingHead {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 96vh;
}
@media (min-width: 1920px) {
  .CareersLandingHead {
    padding:  0 186.66666666666666px 93.33333333333333px;
    min-height:  933.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingHead {
    padding:  0vw 9.722222222222223vw 4.861111111111112vw;
    min-height:  48.611111111111114vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingHead {
    padding:  0vw 11.29032258064516vw 5.64516129032258vw;
    min-height:  56.45161290322581vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingHead {
    padding:  0vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingHead {
    height: 100vh;
    height: -webkit-fill-available;
    height: -moz-available;
    height: fill-available;
  }
}
.CareersLandingHead-flexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (max-width: 759px) {
  .CareersLandingHead-flexSpacer {
    display: none;
  }
}
.CareersLandingHead-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #111112;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 2;
}
.CareersLandingHead-overlay.show {
  opacity: 0.8;
}
.CareersLandingHead-facts {
  margin-top: auto;
  opacity: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .CareersLandingHead-facts {
    margin-top: 0;
    position: absolute;
    bottom: -18.75vw;
    left: 6.25vw;
    right: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .CareersLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  5.64516129032258vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  9.375vw;
  }
}
.CareersLandingHead-facts.hide {
  opacity: 0;
}
.CareersLandingHead-arrow {
  -webkit-transform: translate3d(-50%, 50%, 0);
          transform: translate3d(-50%, 50%, 0);
  -webkit-animation: 3s arrow-jump-sustainability infinite ease-in-out;
          animation: 3s arrow-jump-sustainability infinite ease-in-out;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  position: absolute;
  cursor: pointer;
  left: 50%;
  will-change: transform;
  opacity: 0;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@-webkit-keyframes arrow-jump-sustainability {
  0% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  10% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  20% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  30% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  40% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
}
@keyframes arrow-jump-sustainability {
  0% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  10% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  20% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  30% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  40% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
}
.CareersLandingHead-arrow.show {
  opacity: 1;
  -webkit-transform: translate3d(-50%, 50%, 0);
          transform: translate3d(-50%, 50%, 0);
}
.CareersLandingHead-arrow.hide {
  opacity: 0;
  -webkit-transform: scale(0) translate3d(0, 0, 0);
          transform: scale(0) translate3d(0, 0, 0);
  visibility: hidden;
  -webkit-transition: 0.15s opacity, visibility 0.15s, 0.15s -webkit-transform;
  transition: 0.15s opacity, visibility 0.15s, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform, visibility 0.15s;
  transition: 0.15s opacity, 0.15s transform, visibility 0.15s, 0.15s -webkit-transform;
}
.CareersLandingHead-arrow:hover {
  opacity: 0.7;
}
@media (max-width: 759px) {
  .CareersLandingHead-arrow {
    display: none;
  }
}
@media (min-width: 1920px) {
  .CareersLandingHead-arrow {
    width:  69.33333333333333px;
    height:  66.66666666666666px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingHead-arrow {
    width:  3.611111111111111vw;
    height:  3.4722222222222223vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingHead-arrow {
    width:  4.193548387096774vw;
    height:  4.032258064516129vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingHead-arrow {
    width:  16.25vw;
    height:  15.625vw;
    bottom:  6.25vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingHead .GlobalPageTitle {
    margin-top: 16vh;
  }
}
