@media (min-width: 1920px) {
  .InvestorsFinancialCards {
    padding:  80px 186.66666666666666px 53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialCards {
    padding:  4.166666666666667vw 9.722222222222223vw 2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialCards {
    padding:  4.838709677419355vw 11.29032258064516vw 3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCards {
    padding:  10.9375vw 0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialCards-cards {
    padding:  0vw 6.25vw;
  }
}
