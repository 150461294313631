.PageFaq-head {
  width: 100%;
  position: relative;
}
@media (min-width: 1920px) {
  .PageFaq-head {
    height:  426.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-head {
    height:  22.22222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-head {
    height:  25.806451612903224vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-head {
    height:  25vw;
    margin-top:  12.5vw;
  }
}
.PageFaq-headBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.PageFaq-headBackground img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.PageFaq-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 50vh;
}
@media (min-width: 1920px) {
  .PageFaq-content {
    padding:  106.66666666666666px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-content {
    padding:  5.555555555555555vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-content {
    padding:  6.451612903225806vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-content {
    padding:  25vw 43.75vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-content {
    display: none;
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentTabs {
    width:  373.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentTabs {
    width:  19.444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentTabs {
    width:  22.58064516129032vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentTabs {
    width:  87.5vw;
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentLinks {
    width:  880px;
    margin-left:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLinks {
    width:  45.833333333333336vw;
    margin-left:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLinks {
    width:  53.225806451612904vw;
    margin-left:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentLinks {
    width:  206.25vw;
    margin-left:  9.375vw;
  }
}
.PageFaq-contentLink {
  color: #111112;
  text-decoration: none;
  font-weight: 600;
  border-top: 1px solid #E7E7E7;
  width: 100%;
  display: block;
  position: relative;
}
.PageFaq-contentLink.hide {
  display: none;
}
.PageFaq-contentLink:last-child {
  border-bottom: 1px solid #E7E7E7;
}
.PageFaq-contentLink:visited {
  color: #111112;
}
@media (min-width: 1920px) {
  .PageFaq-contentLink {
    padding:  26.666666666666664px 80px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLink {
    padding:  1.3888888888888888vw 4.166666666666667vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLink {
    padding:  1.6129032258064515vw 4.838709677419355vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentLink {
    padding:  6.25vw 18.75vw 6.25vw 0vw;
  }
}
.isDesktop .PageFaq-contentLink:hover {
  text-decoration: underline;
}
.isDesktop .PageFaq-contentLink:hover .PageFaq-contentLinkArrow:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.PageFaq-contentLinkArrow {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  top: 50%;
}
@media (max-width: 759px) {
  .PageFaq-contentLinkArrow {
    margin-top: 0;
    right: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLinkArrow {
    margin-top: -0.80645161vw;
    right: 0.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLinkArrow {
    margin-top: -0.69444444vw;
    right: 0.55555556vw;
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentLinkArrow {
    margin-top: -13.33333333px;
    right: 10.66666667px;
  }
}
.PageFaq-contentLinkArrow:before {
  content: '';
  display: inline-block;
  position: relative;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
  z-index: 2;
  will-change: transform;
}
@media (max-width: 759px) {
  .PageFaq-contentLinkArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageFaq-contentLinkArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageFaq-contentLinkArrow:before {
    width: 7.8125vw;
    height: 5vw;
    background-size: 8.125vw 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLinkArrow:before {
    width: 2.01612903vw;
    height: 1.29032258vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLinkArrow:before {
    width: 1.73611111vw;
    height: 1.11111111vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentLinkArrow:before {
    width: 33.33333333px;
    height: 21.33333333px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageFaq-inputWrapper {
  position: absolute;
  will-change: transform;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  border-bottom: 1px solid #FFFFFF;
  top: 50%;
}
@media (min-width: 1920px) {
  .PageFaq-inputWrapper {
    right:  186.66666666666666px;
    left:  186.66666666666666px;
    height:  160px;
    margin-top:  -80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-inputWrapper {
    right:  9.722222222222223vw;
    left:  9.722222222222223vw;
    height:  8.333333333333334vw;
    margin-top:  -4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-inputWrapper {
    right:  11.29032258064516vw;
    left:  11.29032258064516vw;
    height:  9.67741935483871vw;
    margin-top:  -4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-inputWrapper {
    height:  12.5vw;
    right:  6.25vw;
    left:  6.25vw;
    top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-inputWrapper {
    background-color: #FFFFFF;
    border: 1px solid #E7E7E7;
  }
}
.PageFaq-icon {
  position: absolute;
  left: 0;
  background-image: url('/static/svg/faq-search-icon.svg');
}
@media (min-width: 1920px) {
  .PageFaq-icon {
    top:  69.33333333333333px;
    width:  53.33333333333333px;
    height:  53.33333333333333px;
    background-size:  53.33333333333333px 53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-icon {
    top:  3.611111111111111vw;
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
    background-size:  2.7777777777777777vw 2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-icon {
    top:  4.193548387096774vw;
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
    background-size:  3.225806451612903vw 3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-icon {
    top:  16.25vw;
    width:  12.5vw;
    height:  12.5vw;
    background-size:  12.5vw 12.5vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-icon {
    display: none;
  }
}
.PageFaq-icon-mobile {
  display: none;
}
@media (max-width: 759px) {
  .PageFaq-icon-mobile {
    display: block;
    position: absolute;
    top: 3.125vw;
    left: 3.125vw;
    width: 6.875vw;
    height: 6.875vw;
    background-size: 6.875vw 6.875vw;
    background-image: url('/static/svg/icon-search-dark.svg');
  }
}
.PageFaq-input {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  line-height: 1.5;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  border: none;
  outline: none;
  text-transform: none;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageFaq-input {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-input {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-input {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-input {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .PageFaq-input {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .PageFaq-input {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    letter-spacing: -0.04666667em;
    line-height: 1;
    text-transform: uppercase;
    text-transform: none;
    line-height: 1.628;
    top: 1.25vw;
    left: 12.5vw;
    width: calc(100% - 21.875vw);
    height: 9.375vw;
    font-size: 9.375vw;
    color: #111112;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageFaq-input {
    font-size:  40px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-input {
    font-size:  2.0833333333333335vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-input {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageFaq-input {
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-input {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-input {
    top: 1.85483871vw;
    left: 4.83870968vw;
    width: calc(100% - 14.51612903vw);
    height: 6.85483871vw;
    font-size: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-input {
    top: 1.59722222vw;
    left: 4.16666667vw;
    width: calc(100% - 12.5vw);
    height: 5.90277778vw;
    font-size: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .PageFaq-input {
    top: 30.66666667px;
    left: 80px;
    width: calc(100% - 240px);
    height: 113.33333333px;
    font-size: 80px;
  }
}
.PageFaq-input::-webkit-input-placeholder {
  color: #FFFFFF;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
@media (max-width: 759px) {
  .PageFaq-input::-webkit-input-placeholder {
    color: #111112;
  }
}
.PageFaq-input::-moz-placeholder {
  color: #FFFFFF;
  opacity: 1;
  -moz-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
@media (max-width: 759px) {
  .PageFaq-input::-moz-placeholder {
    color: #111112;
  }
}
.PageFaq-input:-ms-input-placeholder {
  color: #FFFFFF;
  -ms-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
@media (max-width: 759px) {
  .PageFaq-input:-ms-input-placeholder {
    color: #111112;
  }
}
.PageFaq-input:focus::-webkit-input-placeholder {
  color: transparent;
}
.PageFaq-input:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.PageFaq-input:focus:-ms-input-placeholder {
  color: transparent;
}
.PageFaq-content_mobile {
  display: none;
  min-height: 90vh;
}
@media (max-width: 759px) {
  .PageFaq-content_mobile {
    display: block;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageFaq-content_mobile {
    padding:  0 26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-content_mobile {
    padding:  0vw 1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-content_mobile {
    padding:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageFaq-content_mobile {
    padding:  0vw 6.25vw;
  }
}
.PageFaq-contentCategory_mobile {
  color: #111112;
  text-decoration: none;
  text-transform: uppercase;
  border-top: 1px solid #E7E7E7;
  width: 100%;
  display: block;
  position: relative;
}
@media (min-width: 1920px) {
  .PageFaq-contentCategory_mobile {
    padding-top:  20px;
    padding-bottom:  21.333333333333332px;
    font-size:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentCategory_mobile {
    padding-top:  1.0416666666666667vw;
    padding-bottom:  1.1111111111111112vw;
    font-size:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentCategory_mobile {
    padding-top:  1.2096774193548387vw;
    padding-bottom:  1.2903225806451613vw;
    font-size:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentCategory_mobile {
    padding-top:  4.6875vw;
    padding-bottom:  5vw;
    font-size:  3.75vw;
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentCategory_mobile .PageFaq-contentLinkArrow {
    top:  24px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentCategory_mobile .PageFaq-contentLinkArrow {
    top:  1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentCategory_mobile .PageFaq-contentLinkArrow {
    top:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentCategory_mobile .PageFaq-contentLinkArrow {
    top:  5.625vw;
  }
}
.PageFaq-contentLinksTitle_mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 1920px) {
  .PageFaq-contentLinksTitle_mobile {
    padding-top:  26.666666666666664px;
    padding-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLinksTitle_mobile {
    padding-top:  1.3888888888888888vw;
    padding-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLinksTitle_mobile {
    padding-top:  1.6129032258064515vw;
    padding-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentLinksTitle_mobile {
    padding-top:  6.25vw;
    padding-bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentLinksTitleText_mobile {
    margin-left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLinksTitleText_mobile {
    margin-left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLinksTitleText_mobile {
    margin-left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentLinksTitleText_mobile {
    margin-left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageFaq-contentLinksTitleBack {
    width:  33.33333333333333px;
    height:  18.666666666666664px;
    margin-top:  4px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-contentLinksTitleBack {
    width:  1.7361111111111112vw;
    height:  0.9722222222222223vw;
    margin-top:  0.20833333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-contentLinksTitleBack {
    width:  2.0161290322580645vw;
    height:  1.129032258064516vw;
    margin-top:  0.24193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-contentLinksTitleBack {
    width:  7.8125vw;
    height:  4.375vw;
    margin-top:  0.9375vw;
  }
}
.PageFaq-contentLinksTitleBack svg {
  width: 100%;
  height: 100%;
}
.PageFaq-contentLinksNoResults {
  display: none;
  color: #E7E7E7;
  width: 100%;
  text-align: center;
}
.PageFaq-contentLinksNoResults.show {
  display: block;
}
@media (max-width: 759px) {
  .PageFaq-contentLinksNoResults {
    margin-top: 25vh;
  }
}
.PageFaq-close {
  position: absolute;
  top: 55%;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
}
.PageFaq-close.show {
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 759px) {
  .isDesktop .PageFaq-close:hover .PageFaq-closeItem,
  .isNotDesktop .PageFaq-close:active .PageFaq-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .PageFaq-close:hover .PageFaq-closeItem,
  .isNotDesktop .PageFaq-close:active .PageFaq-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .PageFaq-close:hover .PageFaq-closeItem,
  .isNotDesktop .PageFaq-close:active .PageFaq-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .PageFaq-close:hover .PageFaq-closeItem,
  .isNotDesktop .PageFaq-close:active .PageFaq-closeItem {
    width: 42.66666667px;
  }
}
@media (min-width: 1920px) {
  .PageFaq-close {
    width:  42.666666666666664px;
    height:  34.666666666666664px;
    right:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-close {
    width:  2.2222222222222223vw;
    height:  1.8055555555555556vw;
    right:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-close {
    width:  2.5806451612903225vw;
    height:  2.096774193548387vw;
    right:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-close {
    right:  5.625vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-close {
    top: 52%;
  }
}
.PageFaq-closeItem {
  position: absolute;
  height: 2px;
  background: #FFFFFF;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.PageFaq-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.PageFaq-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (min-width: 1920px) {
  .PageFaq-closeItem {
    width:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageFaq-closeItem {
    width:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageFaq-closeItem {
    width:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-closeItem {
    width:  4.375vw;
  }
}
@media (max-width: 759px) {
  .PageFaq-closeItem {
    background: #C6C6C6;
  }
}
