.RowFilter {
  width: 100%;
  position: relative;
}
.RowFilter-wrapper {
  position: relative;
}
@media (min-width: 1920px) {
  .RowFilter-wrapper {
    padding:  20px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RowFilter-wrapper {
    padding:  1.0416666666666667vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RowFilter-wrapper {
    padding:  1.2096774193548387vw 0vw;
  }
}
@media (max-width: 759px) {
  .RowFilter-wrapper {
    padding:  4.6875vw 0vw;
  }
}
.RowFilter-wrapper::-webkit-scrollbar {
  display: none;
}
@media (max-width: 759px) {
  .RowFilter-wrapper {
    overflow: scroll;
    scrollbar-width: none;
  }
}
.RowFilter-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .RowFilter-inner {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.RowFilter-item {
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.15s background-color, 0.15s color;
  transition: 0.15s background-color, 0.15s color;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}
@media (min-width: 1920px) {
  .RowFilter-item {
    padding:  14.666666666666666px 26.666666666666664px 12px;
    border-radius:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RowFilter-item {
    padding:  0.763888888888889vw 1.3888888888888888vw 0.625vw;
    border-radius:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RowFilter-item {
    padding:  0.8870967741935484vw 1.6129032258064515vw 0.7258064516129032vw;
    border-radius:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .RowFilter-item {
    padding:  3.4375vw 7.8125vw 2.8125vw;
    border-radius:  9.375vw;
  }
}
.dark .RowFilter-item {
  color: #111112;
}
.light .RowFilter-item {
  color: #FFFFFF;
}
.isDesktop .dark .RowFilter-item:hover,
.dark .RowFilter-item.active {
  color: #FFFFFF;
  background-color: #111112;
}
.isDesktop .light .RowFilter-item:hover,
.light .RowFilter-item.active {
  color: #111112;
  background-color: #FFFFFF;
}
.RowFilter .ps__rail-x,
.RowFilter .ps__rail-y {
  display: none;
}
.RowFilter-lineTop,
.RowFilter-lineBottom {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
}
.light .RowFilter-lineTop,
.light .RowFilter-lineBottom {
  background-color: #FFFFFF;
}
.dark .RowFilter-lineTop,
.dark .RowFilter-lineBottom {
  background-color: #111112;
}
@media (max-width: 759px) {
  .RowFilter-lineTop,
  .RowFilter-lineBottom {
    left: 6.25vw;
    right: 6.25vw;
    width: auto;
  }
}
.RowFilter-lineTop {
  top: 0;
}
.RowFilter-lineBottom {
  bottom: 0;
}
