.Popup {
  position: fixed;
  z-index: 25;
  visibility: hidden;
  opacity: 0;
}
.Popup.show {
  -webkit-animation: 0.35s popupPlayIn forwards linear;
          animation: 0.35s popupPlayIn forwards linear;
}
@-webkit-keyframes popupPlayIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes popupPlayIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.Popup.hide {
  -webkit-animation: 0.35s popupPlayOut forwards linear;
          animation: 0.35s popupPlayOut forwards linear;
}
@-webkit-keyframes popupPlayOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes popupPlayOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@media (min-width: 1920px) {
  .Popup.FullPagePopup {
    top:  26.666666666666664px;
    left:  26.666666666666664px;
    right:  26.666666666666664px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Popup.FullPagePopup {
    top:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
    right:  1.3888888888888888vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Popup.FullPagePopup {
    top:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
    right:  1.6129032258064515vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .Popup.FullPagePopup {
    top:  0vw;
    left:  0vw;
    bottom:  0vw;
    right:  0vw;
  }
}
.Popup.HalfPagePopup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 25;
}
.Popup-bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  position: absolute;
}
.Popup-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 759px) {
  .Popup-wrapper {
    overflow: auto;
  }
}
.FullPagePopup .Popup-wrapper {
  width: 100%;
  height: 100%;
}
.HalfPagePopup .Popup-wrapper {
  height: 100%;
  margin-left: auto;
  background-color: #0D1B28;
  will-change: transform;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: 0.35s -webkit-transform;
  transition: 0.35s -webkit-transform;
  transition: 0.35s transform;
  transition: 0.35s transform, 0.35s -webkit-transform;
}
@media (min-width: 1920px) {
  .HalfPagePopup .Popup-wrapper {
    width:  1386.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .HalfPagePopup .Popup-wrapper {
    width:  72.22222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .HalfPagePopup .Popup-wrapper {
    width:  83.87096774193549vw;
  }
}
@media (max-width: 759px) {
  .HalfPagePopup .Popup-wrapper {
    width:  325vw;
  }
}
@media (max-width: 759px) {
  .HalfPagePopup .Popup-wrapper {
    width: 100%;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .HalfPagePopup .Popup-wrapper {
    -webkit-box-shadow: 0 0.80645161vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.80645161vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .HalfPagePopup .Popup-wrapper {
    -webkit-box-shadow: 0 0.69444444vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.69444444vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1920px) {
  .HalfPagePopup .Popup-wrapper {
    -webkit-box-shadow: 0 13.33333333px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.5);
            box-shadow: 0 13.33333333px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.5);
  }
}
.HalfPagePopup.show .Popup-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.35s -webkit-transform;
  transition: 0.35s -webkit-transform;
  transition: 0.35s transform;
  transition: 0.35s transform, 0.35s -webkit-transform;
  will-change: transform;
}
@media (max-width: 759px) {
  .HalfPagePopup.show .Popup-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}
.HalfPagePopup.hide .Popup-wrapper {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: 0.35s -webkit-transform;
  transition: 0.35s -webkit-transform;
  transition: 0.35s transform;
  transition: 0.35s transform, 0.35s -webkit-transform;
}
@media (max-width: 759px) {
  .HalfPagePopup.hide .Popup-wrapper {
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.Popup-close {
  position: absolute;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 27;
}
@media (min-width: 1920px) {
  .Popup-close {
    top:  21.333333333333332px;
    right:  21.333333333333332px;
    width:  34.666666666666664px;
    height:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Popup-close {
    top:  1.1111111111111112vw;
    right:  1.1111111111111112vw;
    width:  1.8055555555555556vw;
    height:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Popup-close {
    top:  1.2903225806451613vw;
    right:  1.2903225806451613vw;
    width:  2.096774193548387vw;
    height:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .Popup-close {
    top:  14.0625vw;
    right:  2.1875vw;
    width:  8.125vw;
    height:  8.125vw;
  }
}
@media (max-width: 759px) {
  .Popup-close {
    position: fixed;
  }
}
.closeWithBackground .Popup-close {
  top: 0;
  right: 0;
  background-color: #FFFFFF;
}
@media (min-width: 1920px) {
  .closeWithBackground .Popup-close {
    width:  53.33333333333333px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .closeWithBackground .Popup-close {
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .closeWithBackground .Popup-close {
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .closeWithBackground .Popup-close {
    width:  12.5vw;
    height:  12.5vw;
  }
}
@media (max-width: 759px) {
  .closeWithBackground .Popup-close {
    position: fixed;
    top: 14.0625vw;
    right: 1.5625vw;
    width: 9.375vw;
    height: 9.375vw;
    background-color: #111112;
  }
  .isDesktop .closeWithBackground .Popup-close:hover .Popup-closeItem,
  .isNotDesktop .closeWithBackground .Popup-close:active .Popup-closeItem {
    background-color: #FFFFFF;
  }
  .isDesktop .closeWithBackground .Popup-close:hover .Popup-closeItem--1,
  .isNotDesktop .closeWithBackground .Popup-close:active .Popup-closeItem--1 {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
  .isDesktop .closeWithBackground .Popup-close:hover .Popup-closeItem--2,
  .isNotDesktop .closeWithBackground .Popup-close:active .Popup-closeItem--2 {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
  .closeWithBackground .Popup-close .Popup-closeItem {
    background-color: #FFFFFF;
  }
  .closeWithBackground .Popup-close .Popup-closeItem--1 {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
  .closeWithBackground .Popup-close .Popup-closeItem--2 {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
}
.HalfPagePopup .Popup-close {
  background-color: #FFFFFF;
}
@media (min-width: 1920px) {
  .HalfPagePopup .Popup-close {
    top:  26.666666666666664px;
    right:  26.666666666666664px;
    width:  53.33333333333333px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .HalfPagePopup .Popup-close {
    top:  1.3888888888888888vw;
    right:  1.3888888888888888vw;
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .HalfPagePopup .Popup-close {
    top:  1.6129032258064515vw;
    right:  1.6129032258064515vw;
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .HalfPagePopup .Popup-close {
    top:  14.0625vw;
    right:  2.1875vw;
    width:  8.125vw;
    height:  8.125vw;
  }
}
.ProductFamilyPopup .Popup-close {
  background: transparent;
}
@media (min-width: 1920px) {
  .ProductFamilyPopup .Popup-close {
    top:  13.333333333333332px;
    right:  13.333333333333332px;
    width:  53.33333333333333px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyPopup .Popup-close {
    top:  0.6944444444444444vw;
    right:  0.6944444444444444vw;
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyPopup .Popup-close {
    top:  0.8064516129032258vw;
    right:  0.8064516129032258vw;
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyPopup .Popup-close {
    top:  14.0625vw;
    right:  2.1875vw;
    width:  8.125vw;
    height:  8.125vw;
  }
}
.isDesktop .Popup-close:hover .Popup-closeItem--1,
.isMobile .Popup-close:active .Popup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
}
@media (min-width: 1920px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--1,
  .isMobile .Popup-close:active .Popup-closeItem--1 {
    width:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--1,
  .isMobile .Popup-close:active .Popup-closeItem--1 {
    width:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--1,
  .isMobile .Popup-close:active .Popup-closeItem--1 {
    width:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--1,
  .isMobile .Popup-close:active .Popup-closeItem--1 {
    width:  10.625vw;
  }
}
.isDesktop .Popup-close:hover .Popup-closeItem--2,
.isMobile .Popup-close:active .Popup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
}
@media (min-width: 1920px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--2,
  .isMobile .Popup-close:active .Popup-closeItem--2 {
    width:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--2,
  .isMobile .Popup-close:active .Popup-closeItem--2 {
    width:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--2,
  .isMobile .Popup-close:active .Popup-closeItem--2 {
    width:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .Popup-close:hover .Popup-closeItem--2,
  .isMobile .Popup-close:active .Popup-closeItem--2 {
    width:  10.625vw;
  }
}
.Popup-closeItem {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #111112;
}
@media (min-width: 1920px) {
  .Popup-closeItem {
    width:  33.33333333333333px;
    height:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Popup-closeItem {
    width:  1.7361111111111112vw;
    height:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Popup-closeItem {
    width:  2.0161290322580645vw;
    height:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .Popup-closeItem {
    width:  6.25vw;
    height:  0.625vw;
  }
}
.Popup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.Popup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
@media (max-width: 759px) {
  .withoutBackgroundOnMobile .Popup-close {
    background: none;
  }
}
