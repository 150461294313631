@media (min-width: 1920px) {
  .PageSitemap-content {
    padding:  156px 453.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSitemap-content {
    padding:  8.125vw 23.61111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSitemap-content {
    padding:  9.435483870967742vw 27.419354838709676vw;
  }
}
@media (max-width: 759px) {
  .PageSitemap-content {
    padding:  21.875vw 6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageSitemap-items {
    margin-top:  96px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSitemap-items {
    margin-top:  5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSitemap-items {
    margin-top:  5.806451612903226vw;
  }
}
@media (max-width: 759px) {
  .PageSitemap-items {
    margin-top:  6.25vw;
  }
}
.PageSitemap-item {
  color: #111112;
  display: block;
}
.PageSitemap-item:visited {
  color: #111112;
}
@media (min-width: 1920px) {
  .PageSitemap-item--2 {
    padding-left:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSitemap-item--2 {
    padding-left:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSitemap-item--2 {
    padding-left:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .PageSitemap-item--2 {
    padding-left:  7.8125vw;
  }
}
.isDesktop .PageSitemap-item:hover,
.isNotDesktop .PageSitemap-item:active {
  text-decoration: none;
}
