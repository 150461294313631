@media (max-width: 759px) {
  .LayoutsHowItWorks-title {
    width: 100%;
    position: relative;
  }
}
.LayoutsHowItWorks-description {
  color: #6F7380;
}
@media (max-width: 759px) {
  .LayoutsHowItWorks-description {
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHowItWorks-description {
    margin-top: 1.61290323vw;
    margin-bottom: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHowItWorks-description {
    margin-top: 1.38888889vw;
    margin-bottom: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHowItWorks-description {
    margin-top: 26.66666667px;
    margin-bottom: 80px;
  }
}
.LayoutsHowItWorks-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .LayoutsHowItWorks-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHowItWorks-item {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHowItWorks-item {
    margin-top: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHowItWorks-item {
    margin-top: 80px;
  }
}
.LayoutsHowItWorks-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (max-width: 759px) {
  .LayoutsHowItWorks-image img {
    width: 87.5vw;
    height: 50vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHowItWorks-image img {
    height: 16.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHowItWorks-image img {
    height: 13.88888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHowItWorks-image img {
    height: 266.66666667px;
  }
}
.LayoutsHowItWorks-text {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
}
@media (max-width: 759px) {
  .LayoutsHowItWorks-text {
    margin-top: 6.25vw;
    width: 87.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHowItWorks-text {
    width: 29.03225806vw;
    margin: auto 0 60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHowItWorks-text {
    width: 25vw;
    margin: auto 0 60px;
  }
}
@media (min-width: 1920px) {
  .LayoutsHowItWorks-text {
    width: 480px;
    margin: auto 0 80px;
  }
}
.LayoutsHowItWorks-textTitle {
  color: #6F7380;
  text-transform: inherit;
}
@media (max-width: 759px) {
  .LayoutsHowItWorks-textTitle {
    line-height: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsHowItWorks-textTitle {
    line-height: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsHowItWorks-textTitle {
    line-height: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsHowItWorks-textTitle {
    line-height: 40px;
  }
}
.LayoutsHowItWorks-textDescription {
  color: #6F7380;
}
@media (max-width: 759px) {
  .LayoutsHowItWorks-textDescription {
    line-height: 6.25vw;
  }
}
