.IndexInsights {
  position: relative;
  width: 100%;
}
.isIE .IndexInsights {
  z-index: 4;
}
@media (max-width: 759px) {
  .IndexInsights {
    padding-bottom: 31.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights {
    padding-bottom: 9.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights {
    padding-bottom: 8.33333333vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights {
    padding-bottom: 160px;
  }
}
.IndexInsights-wrapper {
  margin: 0 auto;
  white-space: nowrap;
}
@media (max-width: 759px) {
  .IndexInsights-wrapper {
    width: 87.5vw;
    white-space: normal;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-wrapper {
    width: 93.5483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-wrapper {
    width: 80.55555556vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-wrapper {
    width: 1546.66666667px;
  }
}
.IndexInsights-left-column {
  display: inline-block;
  width: 50%;
  white-space: normal;
  vertical-align: top;
}
@media (max-width: 759px) {
  .IndexInsights-left-column {
    width: 100%;
  }
}
@media (max-width: 759px) {
  .IndexInsights-left-column h2 {
    font-size: 9.375vw;
    margin-bottom: 5.3125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-left-column h2 {
    margin-bottom: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-left-column h2 {
    margin-bottom: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-left-column h2 {
    margin-bottom: 46.66666667px;
  }
}
.IndexInsights-right-column {
  display: inline-block;
  width: 50%;
  white-space: normal;
  vertical-align: top;
}
@media (max-width: 759px) {
  .IndexInsights-right-column {
    width: 100%;
    padding-left: 0;
    margin-top: 17.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-right-column {
    padding-left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-right-column {
    padding-left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-right-column {
    padding-left: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-right-column h2 {
    font-size: 9.375vw;
    margin-bottom: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-right-column h2 {
    margin-bottom: 2.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-right-column h2 {
    margin-bottom: 2.5vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-right-column h2 {
    margin-bottom: 48px;
  }
}
.IndexInsights-inspiration-item {
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
  position: relative;
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .IndexInsights-inspiration-item:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .IndexInsights-inspiration-item-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .IndexInsights-inspiration-item:hover .IndexInsights-inspiration-item-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .IndexInsights-inspiration-item:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.IndexInsights-inspiration-item:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .IndexInsights-inspiration-item:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .IndexInsights-inspiration-item:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .IndexInsights-inspiration-item:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .IndexInsights-inspiration-item:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.IndexInsights-inspiration-item:active:after {
  opacity: 0.5;
}
.IndexInsights-inspiration-item:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .IndexInsights-inspiration-item:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.IndexInsights-inspiration-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .IndexInsights-inspiration-item:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.IndexInsights-inspiration-item a {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  text-decoration: none;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item:last-child {
    margin-bottom: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item:last-child {
    margin-bottom: 3.62903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item:last-child {
    margin-bottom: 3.125vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item:last-child {
    margin-bottom: 60px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item {
    margin-bottom: 6.25vw;
    width: 87.5vw;
    height: 50vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item {
    margin-bottom: 3.22580645vw;
    width: 45.16129032vw;
    height: 25.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item {
    margin-bottom: 2.77777778vw;
    width: 38.88888889vw;
    height: 22.22222222vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item {
    margin-bottom: 53.33333333px;
    width: 746.66666667px;
    height: 426.66666667px;
  }
}
.IndexInsights-inspiration-item:last-child {
  margin-right: 0;
}
.IndexInsights-inspiration-item-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
}
.IndexInsights-inspiration-item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%';
}
.IndexInsights-inspiration-item-texts {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: left;
  pointer-events: none;
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item-texts {
    bottom: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item-texts {
    bottom: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item-texts {
    bottom: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item-texts {
    bottom: 20px;
  }
}
.IndexInsights-inspiration-item h3 {
  position: relative;
  z-index: 2;
  text-transform: none;
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item h3 {
    font-size: 3.4375vw;
    line-height: 1.81818182;
    padding: 0 18.75vw 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item h3 {
    padding: 0 4.83870968vw 0 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item h3 {
    padding: 0 4.16666667vw 0 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item h3 {
    padding: 0 80px 0 26.66666667px;
  }
}
.IndexInsights-inspiration-item p {
  position: relative;
  z-index: 2;
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item p {
    margin-top: 0;
    padding: 0 18.75vw 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item p {
    margin-top: 0.24193548vw;
    padding: 0 4.83870968vw 0 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item p {
    margin-top: 0.20833333vw;
    padding: 0 4.16666667vw 0 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item p {
    margin-top: 4px;
    padding: 0 80px 0 26.66666667px;
  }
}
.IndexInsights-inspiration-item:before {
  pointer-events: none;
}
@media (max-width: 759px) {
  .IndexInsights-inspiration-item:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-inspiration-item:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-inspiration-item:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-inspiration-item:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-news {
    margin-bottom: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news {
    margin-bottom: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news {
    margin-bottom: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news {
    margin-bottom: 5.33333333px;
  }
}
.IndexInsights-news-item {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}
@media (max-width: 759px) {
  .IndexInsights-news-item {
    height: 25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item {
    height: 9.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item {
    height: 8.33333333vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item {
    height: 160px;
  }
}
.IndexInsights-news-item a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.IndexInsights-news-item-month {
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.isDesktop .IndexInsights-news-item-month {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.IndexInsights-news-item-month span {
  display: none;
}
@media (max-width: 759px) {
  .IndexInsights-news-item-month {
    width: 10.9375vw;
    left: -0.625vw;
    top: 15vw;
    text-align: center;
  }
  .IndexInsights-news-item-month span {
    display: inline;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-month {
    top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-month {
    top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-month {
    top: 53.33333333px;
  }
}
.IndexInsights-news-item-year {
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.isDesktop .IndexInsights-news-item-year {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .IndexInsights-news-item-year {
    width: 10.9375vw;
    left: 5.625vw;
    top: 15vw;
    text-align: center;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-year {
    top: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-year {
    top: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-year {
    top: 80px;
  }
}
.IndexInsights-news-item-day {
  position: absolute;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05em;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #ffffff;
  -moz-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -moz-text-fill-color: rgba(255, 255, 255, 0);
  text-fill-color: rgba(255, 255, 255, 0);
}
.isIE .IndexInsights-news-item-day {
  color: rgba(255, 255, 255, 0.5);
}
.isDesktop .IndexInsights-news-item-day {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .IndexInsights-news-item-day {
    font-size: 9.375vw;
    left: 0.9375vw;
    top: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-day {
    left: 3.22580645vw;
    top: 1.93548387vw;
    font-size: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-day {
    left: 2.77777778vw;
    top: 1.66666667vw;
    font-size: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-day {
    left: 53.33333333px;
    top: 32px;
    font-size: 80px;
  }
}
.IndexInsights-news-item-title {
  position: absolute;
  overflow: hidden;
}
.isDesktop .IndexInsights-news-item-title {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .IndexInsights-news-item-title[data-lines="1"] {
    top: 7.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-title[data-lines="1"] {
    top: 2.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-title[data-lines="1"] {
    top: 2.5vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-title[data-lines="1"] {
    top: 48px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-news-item-title[data-lines="2"] {
    top: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-title[data-lines="2"] {
    top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-title[data-lines="2"] {
    top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-title[data-lines="2"] {
    top: 53.33333333px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-news-item-title[data-lines="3"] {
    top: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-title[data-lines="3"] {
    top: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-title[data-lines="3"] {
    top: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-title[data-lines="3"] {
    top: 21.33333333px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-news-item-title {
    left: 18.75vw;
    right: 0vw;
    line-height: 1.42857143;
    letter-spacing: -0.00783333em;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item-title {
    left: 11.29032258vw;
    right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item-title {
    left: 9.72222222vw;
    right: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item-title {
    left: 186.66666667px;
    right: 53.33333333px;
  }
}
.IndexInsights-news-item:last-child {
  border-bottom: none;
}
.isDesktop .IndexInsights-news-item:hover .IndexInsights-news-item-day,
.IndexInsights-news-item:active .IndexInsights-news-item-day {
  color: #ffffff;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0);
  -moz-text-stroke: 1px rgba(255, 255, 255, 0);
  text-stroke: 1px rgba(255, 255, 255, 0);
  -webkit-text-fill-color: #ffffff;
  -moz-text-fill-color: #ffffff;
  text-fill-color: #ffffff;
}
.isDesktop .IndexInsights-news-item:hover:before {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.IndexInsights-news-item:active .IndexInsights-news-item-month,
.IndexInsights-news-item:active .IndexInsights-news-item-day,
.IndexInsights-news-item:active .IndexInsights-news-item-title {
  opacity: 0.6049;
}
.IndexInsights-news-item:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: transform;
}
.isDesktop .IndexInsights-news-item:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .isDesktop .IndexInsights-news-item:active:before {
    -webkit-transform: translate3d(2.5vw, 0, 0) !important;
            transform: translate3d(2.5vw, 0, 0) !important;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .IndexInsights-news-item:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .IndexInsights-news-item:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .IndexInsights-news-item:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.IndexInsights-news-item:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  background: url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .IndexInsights-news-item:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .IndexInsights-news-item:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-news-item:before {
    margin-top: -0.80645161vw;
    width: 2.09677419vw;
    height: 1.4516129vw;
    right: 0.64516129vw;
    background-size: 2.09677419vw 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-news-item:before {
    margin-top: -0.69444444vw;
    width: 1.80555556vw;
    height: 1.25vw;
    right: 0.55555556vw;
    background-size: 1.80555556vw 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexInsights-news-item:before {
    margin-top: -13.33333333px;
    width: 34.66666667px;
    height: 24px;
    right: 10.66666667px;
    background-size: 34.66666667px 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.IndexInsights-arrow-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .IndexInsights-arrow-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-arrow-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-arrow-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-arrow-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .IndexInsights-arrow-link:hover,
.isNotDesktop .IndexInsights-arrow-link:active {
  text-decoration: underline;
}
.isDesktop .IndexInsights-arrow-link:hover:before,
.isNotDesktop .IndexInsights-arrow-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .IndexInsights-arrow-link:hover:before,
  .isNotDesktop .IndexInsights-arrow-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .IndexInsights-arrow-link {
    letter-spacing: 0;
  }
}
.IndexInsights-arrow-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .IndexInsights-arrow-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .IndexInsights-arrow-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .IndexInsights-arrow-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-arrow-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-arrow-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexInsights-arrow-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.IndexInsights-search {
  position: relative;
}
.IndexInsights-search--withcontent .IndexInsights-search-inputTip,
.IndexInsights-search--isFocus .IndexInsights-search-inputTip {
  opacity: 0;
}
.IndexInsights-search.IndexInsights-search--withcontent .IndexInsights-search-go {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: transform;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
  pointer-events: all;
}
.IndexInsights-search-go {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  position: absolute;
  opacity: 0;
  z-index: 2;
  right: 0;
  outline: none;
  border: 0;
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
  cursor: pointer;
  pointer-events: none;
  will-change: transform;
  display: block;
  padding: 0;
  background: transparent;
}
@media (max-width: 759px) {
  .IndexInsights-search-go {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-go {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-go {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-go {
    font-size: 18.66666667px;
  }
}
.isDesktop .IndexInsights-search-go:hover,
.isNotDesktop .IndexInsights-search-go:active {
  text-decoration: underline;
}
.isDesktop .IndexInsights-search-go:hover:before,
.isNotDesktop .IndexInsights-search-go:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .IndexInsights-search-go:hover:before,
  .isNotDesktop .IndexInsights-search-go:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .IndexInsights-search-go {
    letter-spacing: 0;
  }
}
.IndexInsights-search-go:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .IndexInsights-search-go:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .IndexInsights-search-go:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .IndexInsights-search-go:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-go:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-go:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-go:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .IndexInsights-search-go {
    top: -2.41935484vw;
    right: -2.41935484vw;
    -webkit-transform: translate3d(-1.93548387vw, 0, 0);
            transform: translate3d(-1.93548387vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-go {
    top: 0.80645161vw;
    right: -0.80645161vw;
    width: 2.90322581vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-go {
    top: 0.69444444vw;
    right: -0.69444444vw;
    width: 2.5vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-go {
    top: 13.33333333px;
    right: -13.33333333px;
    width: 48px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .IndexInsights-search {
    margin-top: 28.125vw;
    height: 10vw;
    border-bottom: 1px solid #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search {
    margin-top: 15.72580645vw;
    height: 4.83870968vw;
    border-bottom: 0.16129032vw solid #FFFFFF;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search {
    margin-top: 13.54166667vw;
    height: 4.16666667vw;
    border-bottom: 0.13888889vw solid #FFFFFF;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search {
    margin-top: 260px;
    height: 80px;
    border-bottom: 2.66666667px solid #FFFFFF;
  }
}
.IndexInsights-search-icon {
  position: absolute;
  will-change: transform;
}
.isDesktop .IndexInsights-search-icon {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .IndexInsights-search-icon {
    top: 0vw;
    left: 0vw;
    width: 6.875vw;
    height: 6.875vw;
    background-size: 6.875vw 6.875vw;
    background-image: url('/static/svg/icon-search.svg');
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-icon {
    top: 0vw;
    left: 0vw;
    width: 3.22580645vw;
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-icon {
    top: 0vw;
    left: 0vw;
    width: 2.77777778vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-icon {
    top: 0;
    left: 0;
    width: 53.33333333px;
    height: 53.33333333px;
  }
}
.IndexInsights-search-icon svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.IndexInsights-search-icon svg circle {
  stroke: #FFFFFF;
}
.IndexInsights-search-icon svg path {
  fill: #FFFFFF;
}
@media (max-width: 759px) {
  .IndexInsights-search-icon svg {
    display: none;
  }
}
.IndexInsights-search-input {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  line-height: 1.5;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  text-transform: none;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
  color: #FFFFFF;
  padding: 0;
}
@media (min-width: 1920px) {
  .IndexInsights-search-input {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-input {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-input {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .IndexInsights-search-input {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .IndexInsights-search-input {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .IndexInsights-search-input {
    top: -4.0625vw;
    left: 9.375vw;
    width: calc(100% - 21.875vw);
    height: 13.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-input {
    top: -2.66129032vw;
    left: 4.83870968vw;
    width: calc(100% - 11.29032258vw);
    height: 6.85483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-input {
    top: -2.29166667vw;
    left: 4.16666667vw;
    width: calc(100% - 9.72222222vw);
    height: 5.90277778vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-input {
    top: -44px;
    left: 80px;
    width: calc(100% - 186.66666667px);
    height: 113.33333333px;
  }
}
.IndexInsights-search-input::-webkit-input-placeholder {
  color: #FFFFFF;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.IndexInsights-search-input::-moz-placeholder {
  color: #FFFFFF;
  opacity: 1;
  -moz-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.IndexInsights-search-input:-ms-input-placeholder {
  color: #FFFFFF;
  -ms-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.IndexInsights-search-input::-ms-clear {
  display: none;
}
.IndexInsights-search-input:focus::-webkit-input-placeholder {
  color: transparent;
}
.IndexInsights-search-input:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.IndexInsights-search-input:focus:-ms-input-placeholder {
  color: transparent;
}
.IndexInsights-search-inputTip {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  position: absolute;
  pointer-events: none;
  color: #A6A8B3;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
@media (max-width: 759px) {
  .IndexInsights-search-inputTip {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-inputTip {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-inputTip {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-inputTip {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .IndexInsights-search-inputTip {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexInsights-search-inputTip {
    bottom: 1.37096774vw;
    left: 20.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexInsights-search-inputTip {
    bottom: 1.18055556vw;
    left: 18.05555556vw;
  }
}
@media (min-width: 1920px) {
  .IndexInsights-search-inputTip {
    bottom: 22.66666667px;
    left: 346.66666667px;
  }
}
