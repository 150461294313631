@media (min-width: 1920px) {
  .PageCompanyInnovation-project {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyInnovation-project {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyInnovation-project {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyInnovation-project {
    margin-top:  12.5vw;
  }
}
.PageCompanyInnovation-project:first-child {
  margin-top: 0;
}
