.SubscribePreferences-mainWrapper {
  background: #F6F6F6;
}
@media (min-width: 1920px) {
  .SubscribePreferences-mainWrapper {
    padding:  106.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-mainWrapper {
    padding:  5.555555555555555vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-mainWrapper {
    padding:  6.451612903225806vw 0vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-mainWrapper {
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-mainWrapper {
    background: #FFFFFF;
  }
}
.SubscribePreferences-message {
  background-color: #111112;
  position: relative;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .SubscribePreferences-message {
    padding:  74.66666666666666px 186.66666666666666px 66.66666666666666px;
    width:  1120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-message {
    padding:  3.8888888888888893vw 9.722222222222223vw 3.4722222222222223vw;
    width:  58.333333333333336vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-message {
    padding:  4.516129032258064vw 8.064516129032258vw 4.032258064516129vw;
    width:  67.74193548387096vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-message {
    padding:  17.5vw 43.75vw 15.625vw;
    width:  262.5vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-message {
    color: rgba(0, 0, 0, 0.5);
    width: 100%;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SubscribePreferences-message {
    -webkit-box-shadow:  0 10.666666666666666px 26.666666666666664px 0;
            box-shadow:  0 10.666666666666666px 26.666666666666664px 0;
    padding:  30.666666666666664px 53.33333333333333px 42.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-message {
    -webkit-box-shadow:  0vw 0.5555555555555556vw 1.3888888888888888vw 0vw;
            box-shadow:  0vw 0.5555555555555556vw 1.3888888888888888vw 0vw;
    padding:  1.5972222222222223vw 2.7777777777777777vw 2.2222222222222223vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-message {
    -webkit-box-shadow:  0vw 0.6451612903225806vw 1.6129032258064515vw 0vw;
            box-shadow:  0vw 0.6451612903225806vw 1.6129032258064515vw 0vw;
    padding:  1.8548387096774193vw 3.225806451612903vw 2.5806451612903225vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SubscribePreferences-message {
    -webkit-box-shadow:  0vw 2.5vw 6.25vw 0vw;
            box-shadow:  0vw 2.5vw 6.25vw 0vw;
    padding:  7.1875vw 12.5vw 10vw;
  }
}
.SubscribePreferences-message.hide {
  display: none;
}
.SubscribePreferences-messageTitle {
  color: #FFFFFF;
  text-align: center;
}
.SubscribePreferences-messageDescription {
  color: #A6A8B3;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .SubscribePreferences-messageDescription {
    margin-top:  22.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageDescription {
    margin-top:  1.1805555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageDescription {
    margin-top:  1.3709677419354838vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageDescription {
    margin-top:  5.3125vw;
  }
}
.SubscribePreferences-messageClose {
  position: absolute;
  cursor: pointer;
}
@media (min-width: 1920px) {
  .SubscribePreferences-messageClose {
    top:  26.666666666666664px;
    right:  26.666666666666664px;
    width:  34.666666666666664px;
    height:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageClose {
    top:  1.3888888888888888vw;
    right:  1.3888888888888888vw;
    width:  1.8055555555555556vw;
    height:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageClose {
    top:  1.6129032258064515vw;
    right:  1.6129032258064515vw;
    width:  2.096774193548387vw;
    height:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageClose {
    top:  3.75vw;
    right:  3.75vw;
    width:  6.25vw;
    height:  6.25vw;
  }
}
.SubscribePreferences-messageCloseItem {
  height: 2px;
  background-color: #FFFFFF;
  position: absolute;
  top: 50%;
}
@media (min-width: 1920px) {
  .SubscribePreferences-messageCloseItem {
    width:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageCloseItem {
    width:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageCloseItem {
    width:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageCloseItem {
    width:  6.25vw;
  }
}
.SubscribePreferences-messageCloseItem--1 {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.SubscribePreferences-messageCloseItem--2 {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@media (min-width: 1920px) {
  .SubscribePreferences-content {
    padding:  0 453.3333333333333px;
    margin-top:  86.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-content {
    padding:  0vw 23.61111111111111vw;
    margin-top:  4.513888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-content {
    padding:  0vw 20.161290322580644vw;
    margin-top:  5.241935483870968vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-content {
    padding:  0vw 6.25vw;
    margin-top:  16.5625vw;
  }
}
@media (min-width: 1920px) {
  .SubscribePreferences-contentBlocks {
    margin-top:  50.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-contentBlocks {
    margin-top:  2.638888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-contentBlocks {
    margin-top:  3.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-contentBlocks {
    margin-top:  8.4375vw;
  }
}
.SubscribePreferences-title {
  color: #111112;
}
.SubscribePreferences-saveWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .SubscribePreferences-saveWrapper {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-saveWrapper {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-saveWrapper {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-saveWrapper {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-saveWrapper {
    display: block;
  }
}
.SubscribePreferences-save {
  text-transform: uppercase;
  margin-left: auto;
}
@media (min-width: 1920px) {
  .SubscribePreferences-save {
    width:  266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-save {
    width:  13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-save {
    width:  16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-save {
    width:  62.5vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-save {
    width: 100%;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SubscribePreferences-save {
    margin-top:  46.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-save {
    margin-top:  2.430555555555556vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-save {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SubscribePreferences-save {
    margin-top:  10.9375vw;
  }
}
.SubscribePreferences-messageOverlay {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.SubscribePreferences-messageOverlayWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 21;
}
.SubscribePreferences-messageOverlayWrapper.hide {
  pointer-events: none;
  opacity: 0;
}
.SubscribePreferences-messageOverlayWrapper.hide .SubscribePreferences-messageOverlay {
  -webkit-transform: translate3d(-50%, -30%, 0);
          transform: translate3d(-50%, -30%, 0);
}
@media (min-width: 1920px) {
  .SubscribePreferences-messageOverlay {
    width:  1120px;
    height:  533.3333333333333px;
    padding:  113.33333333333333px 186.66666666666666px 93.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageOverlay {
    width:  58.333333333333336vw;
    height:  27.77777777777778vw;
    padding:  5.902777777777778vw 9.722222222222223vw 4.861111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageOverlay {
    width:  67.74193548387096vw;
    height:  32.25806451612903vw;
    padding:  6.854838709677419vw 11.29032258064516vw 5.64516129032258vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageOverlay {
    width:  262.5vw;
    height:  125vw;
    padding:  26.5625vw 43.75vw 21.875vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageOverlay {
    width: 90%;
    height: auto;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SubscribePreferences-messageOverlay {
    padding:  60px 26.666666666666664px 26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageOverlay {
    padding:  3.125vw 1.3888888888888888vw 1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageOverlay {
    padding:  3.629032258064516vw 1.6129032258064515vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SubscribePreferences-messageOverlay {
    padding:  14.0625vw 6.25vw 6.25vw;
  }
}
.SubscribePreferences-messageOverlay .SubscribePreferences-messageCloseItem {
  background-color: #111112;
}
.SubscribePreferences-messageOverlayTitle {
  text-align: center;
}
.SubscribePreferences-messageOverlayDescription {
  color: #6F7380;
  text-align: center;
}
@media (min-width: 1920px) {
  .SubscribePreferences-messageOverlayDescription {
    margin-top:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageOverlayDescription {
    margin-top:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageOverlayDescription {
    margin-top:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageOverlayDescription {
    margin-top:  4.375vw;
  }
}
.SubscribePreferences-messageOverlayButton {
  margin: 0 auto;
  text-transform: uppercase;
}
.SubscribePreferences-messageOverlayButtonWrapper {
  margin-top: auto;
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SubscribePreferences-messageOverlayButtonWrapper {
    margin-top:  53.33333333333333px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageOverlayButtonWrapper {
    margin-top:  2.7777777777777777vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageOverlayButtonWrapper {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SubscribePreferences-messageOverlayButtonWrapper {
    margin-top:  12.5vw;
  }
}
@media (min-width: 1920px) {
  .SubscribePreferences-messageOverlayButton {
    width:  320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-messageOverlayButton {
    width:  16.666666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-messageOverlayButton {
    width:  19.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-messageOverlayButton {
    width:  75vw;
  }
}
.SubscribePreferences-consent {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .SubscribePreferences-consent {
    margin-top:  140px;
    font-size:  14.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SubscribePreferences-consent {
    margin-top:  7.291666666666667vw;
    font-size:  0.763888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SubscribePreferences-consent {
    margin-top:  8.46774193548387vw;
    font-size:  0.8870967741935484vw;
  }
}
@media (max-width: 759px) {
  .SubscribePreferences-consent {
    margin-top:  14.0625vw;
    font-size:  3.4375vw;
    padding-bottom:  23.4375vw;
  }
}
.SubscribePreferences-consent a {
  color: #111112;
  text-decoration: underline;
}
.SubscribePreferences-consent a:visited {
  color: #111112;
}
.isDesktop .SubscribePreferences-consent a:hover {
  text-decoration: none;
}
