.MobileSlider {
  background-color: #E9EAEE;
  height: 125vw;
  position: relative;
}
.MobileSlider-image {
  position: relative;
  width: 100vw;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.MobileSlider-image img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center';
  height: auto;
  width: auto;
  max-height: 100%;
  min-width: 100%;
}
.isDesktop .MobileSlider-image img {
  opacity: 0;
}
.MobileSlider-image--1 {
  height: 100vw;
}
.MobileSlider-image--1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center';
  width: 80%;
  height: 80%;
}
.isDesktop .MobileSlider-image--1 img {
  opacity: 0;
}
.isDesktop .MobileSlider-image {
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  opacity: 0;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  position: absolute;
}
.isDesktop .MobileSlider-image.active {
  opacity: 1;
}
.isDesktop .MobileSlider-image.active img {
  -webkit-animation: imageShow 0.5s linear forwards;
          animation: imageShow 0.5s linear forwards;
}
@-webkit-keyframes imageShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes imageShow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MobileSlider-imageWrapper {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}
.isDesktop .MobileSlider-imageWrapper {
  position: relative;
}
.MobileSlider-imageWrapperInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
.MobileSlider-buttonWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: -12.5vw;
  visibility: hidden;
}
.MobileSlider-buttonWrapper--1 {
  visibility: visible;
}
.MobileSlider-nav {
  margin: 6.25vw auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 5.625vw;
  width: 100%;
}
.MobileSlider-navLine {
  width: 5.625vw;
  height: 1px;
  background-color: #C6C6C6;
  margin-left: 0.3125vw;
  margin-right: 0.3125vw;
}
.MobileSlider-navLine:last-of-type {
  margin-right: 0;
}
.MobileSlider-navLine:first-of-type {
  margin-left: 0;
}
.MobileSlider-navLine.active {
  background-color: #111112;
  height: 4px;
}
.MobileSlider-arrowLeft,
.MobileSlider-arrowRight {
  position: absolute;
  width: 6.25vw;
  height: 10.9375vw;
  top: 40%;
  cursor: pointer;
  z-index: 2;
  display: none;
}
.isDesktop .MobileSlider-arrowLeft,
.isDesktop .MobileSlider-arrowRight {
  display: block;
}
.isDesktop .MobileSlider-arrowLeft:hover svg,
.isDesktop .MobileSlider-arrowRight:hover svg,
.isNotDesktop .MobileSlider-arrowLeft:active svg,
.isNotDesktop .MobileSlider-arrowRight:active svg {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.MobileSlider-arrowLeft svg,
.MobileSlider-arrowRight svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.MobileSlider-arrowLeft {
  left: 3.125vw;
}
.MobileSlider-arrowRight {
  right: 3.125vw;
}
