.TopNavPopup {
  position: fixed;
  top: 0;
  visibility: hidden;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1100;
  -webkit-transition: 0s visibility 0.3s ease-out;
  transition: 0s visibility 0.3s ease-out;
}
@media (max-width: 759px) {
  .TopNavPopup {
    min-height: 100%;
    height: 100%;
  }
}
.TopNavPopup.TopNavPopup--is-opened {
  visibility: visible;
  -webkit-transition: 0s visibility ease-out;
  transition: 0s visibility ease-out;
}
.TopNavPopup.TopNavPopup--is-opened .TopNavPopup-bg {
  opacity: 1;
  -webkit-transition: 0.3s opacity ease-out;
  transition: 0.3s opacity ease-out;
}
.TopNavPopup.TopNavPopup--is-opened .TopNavPopup-wrapper {
  opacity: 1;
  -webkit-transition: 0.3s opacity 0.15s ease-out;
  transition: 0.3s opacity 0.15s ease-out;
}
.TopNavPopup-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  opacity: 0;
  -webkit-transition: 0.3s opacity ease-out;
  transition: 0.3s opacity ease-out;
}
@media (max-width: 759px) {
  .TopNavPopup-wrapper {
    padding: 16.5625vw 6.25vw 0;
    min-height: unset;
    display: block;
    overflow: scroll;
    background: #111112;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-wrapper {
    padding: 8.46774194vw 11.29032258vw 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-wrapper {
    padding: 7.29166667vw 9.72222222vw 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-wrapper {
    padding: 140px 186.66666667px 133.33333333px;
  }
}
.TopNavPopup-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #A03B94;
}
@media (max-width: 759px) {
  .TopNavPopup-bg {
    display: none;
  }
}
.TopNavPopup-bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.TopNavPopup-bgBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
  -webkit-animation: BackgroundPosition 10s linear infinite;
          animation: BackgroundPosition 10s linear infinite;
}
.TopNavPopup-bgBackground_1 {
  background-image: radial-gradient(at 80% 80%, #a03b94, rgba(160, 59, 148, 0) 70.71%), radial-gradient(at 20% 20%, #ffa28f, rgba(255, 162, 143, 0) 70.71%);
}
.TopNavPopup-bgBackground_2 {
  background-image: radial-gradient(at 20% 80%, #a03b94, rgba(160, 59, 148, 0) 70.71%), radial-gradient(at 80% 20%, #ffa28f, rgba(255, 162, 143, 0) 70.71%);
  opacity: 0;
}
.TopNavPopup-bgBackground_3 {
  background-image: radial-gradient(at 20% 20%, #a03b94, rgba(160, 59, 148, 0) 70.71%), radial-gradient(at 80% 80%, #ffa28f, rgba(255, 162, 143, 0) 70.71%);
  opacity: 0;
}
.TopNavPopup-bgBackground_4 {
  background-image: radial-gradient(at 80% 20%, #a03b94, rgba(160, 59, 148, 0) 70.71%), radial-gradient(at 20% 80%, #ffa28f, rgba(255, 162, 143, 0) 70.71%);
  opacity: 0;
}
.TopNavPopup-bgBackground_5 {
  background-image: radial-gradient(at 50% 50%, #ffa28f, rgba(255, 162, 143, 0) 70.71%);
  opacity: 0;
}
.TopNavPopup-bgCircle {
  position: absolute;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
  display: none;
}
.TopNavPopup-bgCircle_1 {
  background-image: radial-gradient(50% 50%, #A03B94 0%, rgba(160, 59, 148, 0) 100%);
}
@media (min-width: 1920px) {
  .TopNavPopup-bgCircle_1 {
    width:  2160px;
    height:  2160px;
    top:  93.33333333333333px;
    left:  753.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-bgCircle_1 {
    width:  112.5vw;
    height:  112.5vw;
    top:  4.861111111111112vw;
    left:  39.236111111111114vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-bgCircle_1 {
    width:  130.6451612903226vw;
    height:  130.6451612903226vw;
    top:  5.64516129032258vw;
    left:  45.564516129032256vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-bgCircle_1 {
    width:  506.25vw;
    height:  506.25vw;
    top:  21.875vw;
    left:  176.5625vw;
  }
}
.TopNavPopup-bgCircle_2 {
  background-image: radial-gradient(50% 50%, #FFA28F 0%, rgba(254, 162, 143, 0) 100%);
}
@media (min-width: 1920px) {
  .TopNavPopup-bgCircle_2 {
    width:  2160px;
    height:  2160px;
    top:  -728px;
    left:  -801.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-bgCircle_2 {
    width:  112.5vw;
    height:  112.5vw;
    top:  -37.91666666666667vw;
    left:  -41.736111111111114vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-bgCircle_2 {
    width:  130.6451612903226vw;
    height:  130.6451612903226vw;
    top:  -44.03225806451613vw;
    left:  -48.46774193548387vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-bgCircle_2 {
    width:  506.25vw;
    height:  506.25vw;
    top:  -170.625vw;
    left:  -187.8125vw;
  }
}
.TopNavPopup-links {
  display: inline-block;
}
@media (max-width: 759px) {
  .TopNavPopup-links {
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-links {
    margin-top: 4.51612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-links {
    margin-top: 3.88888889vw;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-links {
    margin-top: 74.66666667px;
  }
}
.TopNavPopup-links:first-child {
  margin-top: 0;
}
.TopNavPopup-linksWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .TopNavPopup-linksWrapper {
    max-width:  600px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-linksWrapper {
    max-width:  31.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-linksWrapper {
    max-width:  36.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-linksWrapper {
    max-width:  140.625vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-linksWrapper {
    max-width: none;
    display: block;
  }
}
.TopNavPopup-sublinks {
  display: inline-block;
}
@media (max-width: 759px) {
  .TopNavPopup-sublinks {
    padding-left: 8.4375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-sublinks {
    padding-left: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-sublinks {
    padding-left: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-sublinks {
    padding-left: 42.66666667px;
  }
}
.TopNavPopup-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
  display: inline-block;
  overflow: hidden;
  letter-spacing: -0.01666667em;
}
@media (min-width: 1920px) {
  .TopNavPopup-link {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-link {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-link {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-link {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .TopNavPopup-link {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-link {
    padding-right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-link {
    padding-right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-link {
    padding-right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-link {
    padding-right:  3.125vw;
  }
}
.TopNavPopup-link.active {
  color: transparent;
  -webkit-text-stroke: 1px #FFFFFF;
  -moz-text-stroke: 1px #FFFFFF;
  text-stroke: 1px #FFFFFF;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  cursor: default;
  pointer-events: none;
}
.isDesktop .TopNavPopup-link:hover {
  color: transparent;
  -webkit-text-stroke: 1px #FFFFFF;
  -moz-text-stroke: 1px #FFFFFF;
  text-stroke: 1px #FFFFFF;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}
.isIE .TopNavPopup-link {
  position: relative;
  padding-bottom: 5px;
}
.isIE .TopNavPopup-link.active,
.isIE .TopNavPopup-link:hover {
  color: #FFFFFF;
}
.isIE .TopNavPopup-link.active:after,
.isIE .TopNavPopup-link:hover:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: #FFFFFF;
}
.TopNavPopup-subMenu {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .TopNavPopup-subMenu {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-subMenu {
    top: 10.80645161vw;
    right: 3.22580645vw;
    width: 12.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-subMenu {
    top: 9.30555556vw;
    right: 2.77777778vw;
    width: 11.11111111vw;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-subMenu {
    top: 178.66666667px;
    right: 53.33333333px;
    width: 213.33333333px;
  }
}
.TopNavPopup-subMenuPart {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .TopNavPopup-subMenuPart {
    width: 50%;
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .TopNavPopup-subMenuPart:last-child {
    padding-left: 6.25vw;
  }
}
.TopNavPopup-subMenu--link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  color: #FFFFFF;
  line-height: 2.85714286;
  text-transform: uppercase;
  display: inline-block;
}
@media (max-width: 759px) {
  .TopNavPopup-subMenu--link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-subMenu--link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-subMenu--link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-subMenu--link {
    font-size: 18.66666667px;
  }
}
.isDesktop .TopNavPopup-subMenu--link:hover {
  text-decoration: underline;
}
.TopNavPopup-subMenu--link.active {
  opacity: 0.5;
}
@media (max-width: 759px) {
  .TopNavPopup-subMenu--link {
    display: block;
  }
}
.TopNavPopup-subMenu_mobile {
  display: none;
}
@media (max-width: 759px) {
  .TopNavPopup-subMenu_mobile {
    display: block;
    margin-top: 10vw;
    padding-bottom: 12.5vw;
  }
}
.TopNavPopup-subMenu_mobile .TopNavPopup-subMenu--link.subitem {
  margin-left: 9.375vw;
}
.TopNavPopup-copyright {
  color: #FFFFFF;
  position: absolute;
}
@media (min-width: 1920px) {
  .TopNavPopup-copyright {
    left:  26.666666666666664px;
    bottom:  17.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-copyright {
    left:  1.3888888888888888vw;
    bottom:  0.9027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-copyright {
    left:  1.6129032258064515vw;
    bottom:  1.0483870967741935vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-copyright {
    left:  6.25vw;
    bottom:  4.0625vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-copyright {
    display: none;
  }
}
.TopNavPopup-campus {
  position: absolute;
}
@media (max-width: 759px) {
  .TopNavPopup-campus {
    position: relative;
    border-top: 1px solid rgba(246, 246, 246, 0.15);
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-campus {
    width:  213.33333333333331px;
    right:  53.33333333333333px;
    bottom:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-campus {
    width:  11.11111111111111vw;
    right:  2.7777777777777777vw;
    bottom:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-campus {
    width:  12.903225806451612vw;
    right:  3.225806451612903vw;
    bottom:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-campus {
    width:  87.5vw;
    padding-bottom:  15.625vw;
    padding-top:  12.5vw;
  }
}
.isDesktop .TopNavPopup-campus:hover .TopNavPopup-campusTitle {
  text-decoration: underline;
}
.isDesktop .TopNavPopup-campus:hover .TopNavPopup-campusArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.TopNavPopup-campus a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
}
.TopNavPopup-campusTitle,
.TopNavPopup-campusDescription {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .TopNavPopup-campusDescription {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-campusDescription {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-campusDescription {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-campusDescription {
    margin-top:  1.5625vw;
    width:  50vw;
  }
}
.TopNavPopup-campusArrow {
  position: relative;
  overflow: hidden;
}
@media (max-width: 759px) {
  .TopNavPopup-campusArrow {
    position: absolute;
    top: 45%;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
    right: 0;
  }
}
@media (min-width: 1920px) {
  .TopNavPopup-campusArrow {
    width:  36px;
    height:  24px;
    margin-top:  17.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNavPopup-campusArrow {
    width:  1.875vw;
    height:  1.25vw;
    margin-top:  0.9027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNavPopup-campusArrow {
    width:  2.1774193548387095vw;
    height:  1.4516129032258065vw;
    margin-top:  1.0483870967741935vw;
  }
}
@media (max-width: 759px) {
  .TopNavPopup-campusArrow {
    width:  8.4375vw;
    height:  5.625vw;
  }
}
.TopNavPopup-campusArrow svg {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  -webkit-transform: translate3d(-15%, 0, 0);
          transform: translate3d(-15%, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
