.DownloadCard {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  cursor: pointer;
  will-change: transform;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-decoration: none;
  position: relative;
}
@media (max-width: 759px) {
  .DownloadCard {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    opacity: 1;
  }
}
.DownloadCard:nth-child(30) {
  -webkit-transition-delay: 3s;
          transition-delay: 3s;
}
.DownloadCard:nth-child(29) {
  -webkit-transition-delay: 2.9s;
          transition-delay: 2.9s;
}
.DownloadCard:nth-child(28) {
  -webkit-transition-delay: 2.8s;
          transition-delay: 2.8s;
}
.DownloadCard:nth-child(27) {
  -webkit-transition-delay: 2.7s;
          transition-delay: 2.7s;
}
.DownloadCard:nth-child(26) {
  -webkit-transition-delay: 2.6s;
          transition-delay: 2.6s;
}
.DownloadCard:nth-child(25) {
  -webkit-transition-delay: 2.5s;
          transition-delay: 2.5s;
}
.DownloadCard:nth-child(24) {
  -webkit-transition-delay: 2.4s;
          transition-delay: 2.4s;
}
.DownloadCard:nth-child(23) {
  -webkit-transition-delay: 2.3s;
          transition-delay: 2.3s;
}
.DownloadCard:nth-child(22) {
  -webkit-transition-delay: 2.2s;
          transition-delay: 2.2s;
}
.DownloadCard:nth-child(21) {
  -webkit-transition-delay: 2.1s;
          transition-delay: 2.1s;
}
.DownloadCard:nth-child(20) {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
.DownloadCard:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}
.DownloadCard:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}
.DownloadCard:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}
.DownloadCard:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
.DownloadCard:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.DownloadCard:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.DownloadCard:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.DownloadCard:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.DownloadCard:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.DownloadCard:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.DownloadCard:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
.DownloadCard:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.DownloadCard:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
.DownloadCard:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.DownloadCard:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.DownloadCard:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.DownloadCard:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.DownloadCard:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.DownloadCard:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.DownloadCard.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .DownloadCard {
    width: 480px;
    height: 266.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCard {
    width: 25vw;
    height: 13.88888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCard {
    width: 29.03225806vw;
    height: 16.12903226vw;
  }
}
@media (max-width: 759px) {
  .DownloadCard {
    width: 87.5vw;
    height: 50vw;
  }
}
.isDesktop .DownloadCard:hover .DownloadCard-wrapper,
.isNotDesktop .DownloadCard:active .DownloadCard-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
}
.isDesktop .DownloadCard:hover:before,
.isNotDesktop .DownloadCard:active:before {
  opacity: 0;
}
.isDesktop .DownloadCard:hover:after,
.isNotDesktop .DownloadCard:active:after {
  opacity: 1;
}
.DownloadCard:before {
  content: '';
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  color: rgba(0, 0, 0, 0.25);
}
@media (min-width: 1920px) {
  .DownloadCard:before {
    -webkit-box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
            box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCard:before {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
            box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCard:before {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
            box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .DownloadCard:before {
    -webkit-box-shadow:  0vw 3.125vw 6.25vw 0vw;
            box-shadow:  0vw 3.125vw 6.25vw 0vw;
  }
}
.DownloadCard:after {
  content: '';
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  color: rgba(0, 0, 0, 0.4);
}
@media (min-width: 1920px) {
  .DownloadCard:after {
    -webkit-box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
            box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCard:after {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
            box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCard:after {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
            box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .DownloadCard:after {
    -webkit-box-shadow:  0vw 3.125vw 6.25vw 0vw;
            box-shadow:  0vw 3.125vw 6.25vw 0vw;
  }
}
.DownloadCard-backgroundLink {
  position: absolute;
  width: 100%;
  height: 100%;
}
.DownloadCard-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s background-color, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s background-color, 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s background-color, 0.15s opacity, 0.15s transform;
  transition: 0.15s background-color, 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
  -ms-pointer-events: none;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .DownloadCard-wrapper {
    padding:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCard-wrapper {
    padding:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCard-wrapper {
    padding:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .DownloadCard-wrapper {
    padding:  6.25vw;
  }
}
.DownloadCard-title {
  color: #FFFFFF;
}
.DownloadCard-description {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .DownloadCard-description {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCard-description {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCard-description {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .DownloadCard-description {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .DownloadCard-description {
    margin-top: 0;
  }
}
.DownloadCardDownloads {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-top: auto;
  margin-left: -1rem;
  margin-right: -1rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-pointer-events: auto;
  pointer-events: auto;
}
.DownloadCardDownloads-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  white-space: nowrap;
}
@media (min-width: 1920px) {
  .DownloadCardDownloads-link svg {
    width: 21.33333333px;
    height: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCardDownloads-link svg {
    width: 1.11111111vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCardDownloads-link svg {
    width: 1.29032258vw;
    height: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .DownloadCardDownloads-link svg {
    width: 5vw;
    height: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .DownloadCardDownloads-link span {
    padding-top: 8px;
    margin-left: 18.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCardDownloads-link span {
    padding-top: 0.41666667vw;
    margin-left: 0.97222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCardDownloads-link span {
    padding-top: 0.48387097vw;
    margin-left: 1.12903226vw;
  }
}
@media (max-width: 759px) {
  .DownloadCardDownloads-link span {
    padding-top: 1.875vw;
    margin-left: 4.375vw;
  }
}
.DownloadCard.disabled {
  pointer-events: none;
}
.DownloadCard.disabled .DownloadCard-title,
.DownloadCard.disabled .DownloadCard-description,
.DownloadCard.disabled .DownloadCard-link {
  opacity: 0.6;
}
.DownloadCard.withBackground .DownloadCard-wrapper {
  background: none;
}
.isDesktop .DownloadCard.withBackground:hover .DownloadCard-wrapper,
.isNotDesktop .DownloadCard.withBackground:active .DownloadCard-wrapper {
  background: none;
}
.DownloadCard-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  z-index: -1;
  overflow: hidden;
}
.DownloadCard-backgroundWrapper {
  position: absolute;
  pointer-events: none;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  min-width: calc(100% + 40px);
  min-height: calc(100% + 40px);
}
.DownloadCard-background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  height: 200%;
  min-width: 200%;
}
.DownloadCard:nth-child(4n + 1) .DownloadCard-background img {
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
}
.DownloadCard:nth-child(4n + 2) .DownloadCard-background img {
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
}
.DownloadCard:nth-child(4n + 3) .DownloadCard-background img {
  left: 0;
  top: auto;
  right: auto;
  bottom: 0;
}
.DownloadCard:nth-child(4n + 4) .DownloadCard-background img {
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
}
