.PageGlobalIndex-content {
  z-index: 2;
}
.PageGlobalIndex-background,
.PageGlobalIndex-background_mobile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s opacity, 0.5s background-color;
  transition: 0.5s opacity, 0.5s background-color;
  pointer-events: none;
}
.PageGlobalIndex-background.active,
.PageGlobalIndex-background_mobile.active {
  opacity: 1;
  pointer-events: all;
}
.PageGlobalIndex-background video,
.PageGlobalIndex-background_mobile video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: 100%;
  z-index: 0;
}
.PageGlobalIndex-background img,
.PageGlobalIndex-background_mobile img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 85vw;
  top: 0;
  z-index: 0;
}
@media (max-width: 759px) {
  .PageGlobalIndex-background img,
  .PageGlobalIndex-background_mobile img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: 'object-fit: cover; object-position: center';
    width: 100%;
  }
}
.PageGlobalIndex-backgroundInner {
  height: 100%;
  background-color: #161D8D;
  width: 76%;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-backgroundInner {
    width: 70%;
  }
}
.dark .PageGlobalIndex-backgroundInner {
  background-color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageGlobalIndex-backgroundInner {
    width: 100%;
  }
}
.PageGlobalIndex-navigation {
  position: fixed;
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigation {
    left:  26.666666666666664px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigation {
    left:  1.3888888888888888vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigation {
    left:  1.6129032258064515vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigation {
    left:  6.25vw;
    bottom:  6.25vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigation {
    left: 0;
    bottom: 32vh;
    width: 100%;
  }
}
.isIE .PageGlobalIndex-navigation {
  z-index: 10;
}
.PageGlobalIndex-navigationTitle {
  color: #FFFFFF;
}
.arrowDark .PageGlobalIndex-navigationTitle {
  color: #111112;
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationTitle {
    text-align: center;
    width: 60%;
    margin: 0 auto;
  }
}
.PageGlobalIndex-navigationArrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transform: translateX(-12%);
      -ms-transform: translateX(-12%);
          transform: translateX(-12%);
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigationArrows {
    width: 166.66666667px;
    margin-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigationArrows {
    width: 8.68055556vw;
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigationArrows {
    width: 10.08064516vw;
    margin-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrows {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    position: absolute;
    width: 100%;
    top: 0;
  }
}
.PageGlobalIndex-navigationArrowLeft {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowLeft {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigationArrowLeft {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigationArrowLeft {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigationArrowLeft {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageGlobalIndex-navigationArrowLeft:hover,
.isNotDesktop .PageGlobalIndex-navigationArrowLeft:active {
  text-decoration: underline;
}
.isDesktop .PageGlobalIndex-navigationArrowLeft:hover:before,
.isNotDesktop .PageGlobalIndex-navigationArrowLeft:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageGlobalIndex-navigationArrowLeft:hover:before,
  .isNotDesktop .PageGlobalIndex-navigationArrowLeft:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowLeft {
    letter-spacing: 0;
  }
}
.PageGlobalIndex-navigationArrowLeft:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowLeft:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageGlobalIndex-navigationArrowLeft:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowLeft:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigationArrowLeft:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigationArrowLeft:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigationArrowLeft:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.arrowDark .PageGlobalIndex-navigationArrowLeft {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft {
    font-size: 18.66666667px;
  }
}
.isDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:hover,
.isNotDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:active {
  text-decoration: underline;
}
.isDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:hover:before,
.isNotDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:hover:before,
  .isNotDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft {
    letter-spacing: 0;
  }
}
.arrowDark .PageGlobalIndex-navigationArrowLeft:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/svg/arrow-right-icon-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .arrowDark .PageGlobalIndex-navigationArrowLeft:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .arrowDark .PageGlobalIndex-navigationArrowLeft:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageGlobalIndex-navigationArrowRight {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowRight {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigationArrowRight {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigationArrowRight {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigationArrowRight {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageGlobalIndex-navigationArrowRight:hover,
.isNotDesktop .PageGlobalIndex-navigationArrowRight:active {
  text-decoration: underline;
}
.isDesktop .PageGlobalIndex-navigationArrowRight:hover:before,
.isNotDesktop .PageGlobalIndex-navigationArrowRight:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageGlobalIndex-navigationArrowRight:hover:before,
  .isNotDesktop .PageGlobalIndex-navigationArrowRight:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowRight {
    letter-spacing: 0;
  }
}
.PageGlobalIndex-navigationArrowRight:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowRight:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageGlobalIndex-navigationArrowRight:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowRight:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigationArrowRight:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigationArrowRight:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigationArrowRight:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.arrowDark .PageGlobalIndex-navigationArrowRight {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight {
    font-size: 18.66666667px;
  }
}
.isDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:hover,
.isNotDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:active {
  text-decoration: underline;
}
.isDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:hover:before,
.isNotDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:hover:before,
  .isNotDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight {
    letter-spacing: 0;
  }
}
.arrowDark .PageGlobalIndex-navigationArrowRight:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/svg/arrow-right-icon-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .arrowDark .PageGlobalIndex-navigationArrowRight:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .arrowDark .PageGlobalIndex-navigationArrowRight:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-navigationArrowRight {
    margin-bottom:  5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-navigationArrowRight {
    margin-bottom:  0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-navigationArrowRight {
    margin-bottom:  0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-navigationArrowRight {
    margin-bottom:  1.25vw;
  }
}
.PageGlobalIndex-placeholder {
  height: 100vh;
}
@media (max-width: 759px) {
  .PageGlobalIndex-placeholder {
    display: none;
  }
}
.PageGlobalIndex-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #FFFFFF;
  pointer-events: none;
}
.dark .PageGlobalIndex-contentWrapper {
  color: #111112;
}
.isIE .PageGlobalIndex-contentWrapper {
  position: relative;
}
@media (min-width: 1920px) {
  .isIE .PageGlobalIndex-contentWrapper {
    padding-right:  453.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isIE .PageGlobalIndex-contentWrapper {
    padding-right:  23.61111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isIE .PageGlobalIndex-contentWrapper {
    padding-right:  27.419354838709676vw;
  }
}
@media (max-width: 759px) {
  .isIE .PageGlobalIndex-contentWrapper {
    padding-right:  0vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-contentWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
.PageGlobalIndex-textWrapper {
  pointer-events: all;
}
@media (min-width: 1920px) {
  .PageGlobalIndex-textWrapper {
    padding-bottom: 213.33333333px;
    padding-left: 186.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-textWrapper {
    padding-bottom: 11.11111111vw;
    padding-left: 9.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-textWrapper {
    padding-bottom: 12.90322581vw;
    padding-left: 9.67741935vw;
    padding-right: 6.4516129vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-textWrapper {
    padding-bottom: 25vw;
    padding-top: 16.5625vw;
    background-color: #161D8D;
  }
  .dark .PageGlobalIndex-textWrapper {
    background-color: #FFFFFF;
  }
}
.PageGlobalIndex-text {
  overflow: hidden;
}
@media (min-width: 1920px) {
  .PageGlobalIndex-text {
    letter-spacing: -4px;
    width: 1148px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-text {
    letter-spacing: -0.20833333vw;
    width: 59.79166667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-text {
    letter-spacing: -0.24193548vw;
    font-size: 4.03225806vw;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-text {
    padding: 0 6.25vw 0 6.25vw;
    font-size: 6.875vw;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-text_2 {
    margin-top: 101.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-text_2 {
    margin-top: 5.27777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-text_2 {
    margin-top: 6.12903226vw;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-text_2 {
    padding: 0 6.25vw 0 6.25vw;
    margin-top: 10.9375vw;
    font-size: 6.875vw;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
.PageGlobalIndex-link {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageGlobalIndex-link:hover,
.isNotDesktop .PageGlobalIndex-link:active {
  text-decoration: underline;
}
.isDesktop .PageGlobalIndex-link:hover:before,
.isNotDesktop .PageGlobalIndex-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageGlobalIndex-link:hover:before,
  .isNotDesktop .PageGlobalIndex-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-link {
    letter-spacing: 0;
  }
}
.PageGlobalIndex-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/svg/arrow-right-large.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageGlobalIndex-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageGlobalIndex-link.dark,
.dark .PageGlobalIndex-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageGlobalIndex-link.dark:hover,
.isDesktop .dark .PageGlobalIndex-link:hover,
.isNotDesktop .PageGlobalIndex-link.dark:active,
.isNotDesktop .dark .PageGlobalIndex-link:active {
  text-decoration: underline;
}
.isDesktop .PageGlobalIndex-link.dark:hover:before,
.isDesktop .dark .PageGlobalIndex-link:hover:before,
.isNotDesktop .PageGlobalIndex-link.dark:active:before,
.isNotDesktop .dark .PageGlobalIndex-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageGlobalIndex-link.dark:hover:before,
  .isDesktop .dark .PageGlobalIndex-link:hover:before,
  .isNotDesktop .PageGlobalIndex-link.dark:active:before,
  .isNotDesktop .dark .PageGlobalIndex-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    letter-spacing: 0;
  }
}
.PageGlobalIndex-link.dark:before,
.dark .PageGlobalIndex-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/svg/arrow-right-large-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageGlobalIndex-link.dark:before,
.isDesktop .dark .PageGlobalIndex-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.PageGlobalIndex-link.dark:visited,
.dark .PageGlobalIndex-link:visited {
  color: #111112;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    font-size: 4.03225806vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-link.dark,
  .dark .PageGlobalIndex-link {
    letter-spacing: -0.096875vw;
    font-size: 6.875vw;
  }
}
.PageGlobalIndex-link:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageGlobalIndex-link {
    margin-top: 96px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-link {
    margin-top: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link {
    margin-top: 5vw;
    font-size: 4.03225806vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-link {
    margin-left: 7.5vw;
    margin-top: 11.875vw;
    overflow: hidden;
    font-size: 6.875vw;
    letter-spacing: -0.096875vw;
  }
}
.isDesktop .PageGlobalIndex-link:hover,
.isNotDesktop .PageGlobalIndex-link:active {
  text-decoration: underline;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .PageGlobalIndex-link:hover:before,
  .isNotDesktop .PageGlobalIndex-link:active:before {
    -webkit-transform: translateX(-0.32258065vw);
        -ms-transform: translateX(-0.32258065vw);
            transform: translateX(-0.32258065vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .PageGlobalIndex-link:hover:before,
  .isNotDesktop .PageGlobalIndex-link:active:before {
    -webkit-transform: translateX(-0.27777778vw);
        -ms-transform: translateX(-0.27777778vw);
            transform: translateX(-0.27777778vw);
  }
}
@media (min-width: 1920px) {
  .isDesktop .PageGlobalIndex-link:hover:before,
  .isNotDesktop .PageGlobalIndex-link:active:before {
    -webkit-transform: translateX(-5.33333333px);
        -ms-transform: translateX(-5.33333333px);
            transform: translateX(-5.33333333px);
  }
}
@media (max-width: 759px) {
  .isDesktop .PageGlobalIndex-link:hover:before,
  .isNotDesktop .PageGlobalIndex-link:active:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (max-width: 759px) {
  .PageGlobalIndex-link:before,
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageGlobalIndex-link:before,
.isDesktop .PageGlobalIndex-link.dark:before,
.isDesktop .dark .PageGlobalIndex-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageGlobalIndex-link:before,
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 5.9375vw;
    height: 3.75vw;
    background-size: 5.9375vw 3.75vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalIndex-link:before,
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 4.59677419vw;
    height: 2.58064516vw;
    background-size: 4.59677419vw 2.58064516vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.96774194vw, 0, 0);
            transform: translate3d(-0.96774194vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalIndex-link:before,
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 3.95833333vw;
    height: 2.22222222vw;
    background-size: 3.95833333vw 2.22222222vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.83333333vw, 0, 0);
            transform: translate3d(-0.83333333vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageGlobalIndex-link:before,
  .PageGlobalIndex-link.dark:before,
  .dark .PageGlobalIndex-link:before {
    width: 76px;
    height: 42.66666667px;
    background-size: 76px 42.66666667px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-16px, 0, 0);
            transform: translate3d(-16px, 0, 0);
  }
}
