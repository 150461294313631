.VideoBackground {
  width: 100%;
  height: 100%;
}
.VideoBackground video,
.VideoBackground img {
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .VideoBackground-desktop {
    display: none;
  }
}
.VideoBackground-mobile {
  display: none;
}
@media (max-width: 759px) {
  .VideoBackground-mobile {
    display: block;
  }
}
