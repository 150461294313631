.SocialNetworkLine {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media (min-width: 1920px) {
  .SocialNetworkLine {
    padding:  53.33333333333333px 0 80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine {
    padding:  2.7777777777777777vw 0vw 4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine {
    padding:  3.225806451612903vw 0vw 4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine {
    padding:  12.5vw 0vw 18.75vw;
  }
}
.SocialNetworkLine-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
@media (max-width: 759px) {
  .SocialNetworkLine-inner {
    margin-left: -6.25vw;
  }
}
.SocialNetworkLine-item {
  text-decoration: none;
}
@media (max-width: 759px) {
  .SocialNetworkLine-item {
    margin: 0 6.25vw;
  }
}
.isDesktop .SocialNetworkLine-item:hover .SocialNetworkLine-itemCard,
.isNotDesktop .SocialNetworkLine-item:active .SocialNetworkLine-itemCard {
  color: rgba(0, 0, 0, 0.4);
}
.SocialNetworkLine-itemCard {
  background-color: rgba(17, 17, 18, 0.4);
  color: rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
  overflow: hidden;
  display: block;
}
@media (min-width: 1920px) {
  .SocialNetworkLine-itemCard {
    width:  346.66666666666663px;
    height:  346.66666666666663px;
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine-itemCard {
    width:  18.055555555555557vw;
    height:  18.055555555555557vw;
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-itemCard {
    width:  16.129032258064516vw;
    height:  16.129032258064516vw;
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-itemCard {
    width:  75vw;
    height:  75vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-itemCard {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-item img {
    -webkit-box-shadow: 0 3.125vw 12.5vw rgba(0, 0, 0, 0.2);
            box-shadow: 0 3.125vw 12.5vw rgba(0, 0, 0, 0.2);
  }
}
.SocialNetworkLine-itemText {
  color: #A6A8B3;
  text-overflow: ellipsis;
  height: 100%;
  display: block;
}
@media (min-width: 1920px) {
  .SocialNetworkLine-itemText {
    padding:  32px 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine-itemText {
    padding:  1.6666666666666667vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-itemText {
    padding:  1.6129032258064515vw 1.6129032258064515vw;
    font-size:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-itemText {
    padding:  7.5vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-itemText {
    line-height: 1.33333333;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-itemText {
    color: #FFFFFF;
    font-size: 4.375vw;
    padding: 0;
  }
}
.SocialNetworkLine-link {
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .SocialNetworkLine-link {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine-link {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-link {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-link {
    margin-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .SocialNetworkLine-link.instagram svg {
    width:  26.666666666666664px;
    height:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine-link.instagram svg {
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-link.instagram svg {
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-link.instagram svg {
    width:  6.25vw;
    height:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .SocialNetworkLine-link.twitter svg {
    width:  26.666666666666664px;
    height:  22.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine-link.twitter svg {
    width:  1.3888888888888888vw;
    height:  1.1805555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-link.twitter svg {
    width:  1.6129032258064515vw;
    height:  1.3709677419354838vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-link.twitter svg {
    width:  6.25vw;
    height:  5.3125vw;
  }
}
@media (min-width: 1920px) {
  .SocialNetworkLine-link span {
    margin-left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SocialNetworkLine-link span {
    margin-left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SocialNetworkLine-link span {
    margin-left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-link span {
    margin-left:  6.25vw;
  }
}
@media (max-width: 759px) {
  .SocialNetworkLine-link span {
    font-size: 3.75vw;
  }
}
