@media (max-width: 759px) {
  .GlobalSlider-desktop {
    display: none;
  }
}
.GlobalSlider-mobile {
  display: none;
}
@media (max-width: 759px) {
  .GlobalSlider-mobile {
    display: block;
  }
}
.GlobalSlider-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (min-width: 1920px) {
  .GlobalSlider-items {
    margin-left:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider-items {
    margin-left:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider-items {
    margin-left:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider-items {
    margin-left:  37.5vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider-items {
    display: block;
  }
}
.GlobalSlider-items.isSlider {
  display: block;
  margin-left: 0;
}
.GlobalSlider .slick-list {
  overflow: visible;
}
@media (min-width: 1920px) {
  .GlobalSlider .slick-list {
    padding-left:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider .slick-list {
    padding-left:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider .slick-list {
    padding-left:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider .slick-list {
    padding-left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .GlobalSlider .slick-slide {
    width:  473.3333333333333px;
    margin-left:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider .slick-slide {
    width:  24.65277777777778vw;
    margin-left:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider .slick-slide {
    width:  23.225806451612904vw;
    margin-left:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider .slick-slide {
    width:  81.25vw;
    margin-right:  3.125vw;
    margin-left:  3.125vw;
  }
}
@media (min-width: 1920px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  660px;
    padding-right:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  34.375vw;
    padding-right:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  34.516129032258064vw;
    padding-right:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  154.6875vw;
    padding-right:  43.75vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider .slick-slide:first-of-type {
    margin-right: 0;
    margin-left: 0;
    padding-right: 6.25vw;
    width: 87.5vw;
  }
  .GlobalSlider .slick-slide:nth-child(2) {
    margin-left: 0;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  346.66666666666663px;
    margin-right:  13.333333333333332px;
    margin-left:  13.333333333333332px;
    padding-right:  0;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  18.055555555555557vw;
    margin-right:  0.6944444444444444vw;
    margin-left:  0.6944444444444444vw;
    padding-right:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  20.967741935483872vw;
    margin-right:  0.8064516129032258vw;
    margin-left:  0.8064516129032258vw;
    padding-right:  0vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .GlobalSlider .slick-slide:last-of-type {
    width:  81.25vw;
    margin-right:  3.125vw;
    margin-left:  3.125vw;
    padding-right:  0vw;
  }
}
@media (min-width: 1920px) {
  .GlobalSlider-item.isNotSlider {
    width:  473.3333333333333px;
    margin-left:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider-item.isNotSlider {
    width:  24.65277777777778vw;
    margin-left:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider-item.isNotSlider {
    width:  28.629032258064516vw;
    margin-left:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider-item.isNotSlider {
    width:  110.9375vw;
    margin-left:  6.25vw;
    margin-right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider-item.isNotSlider {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.GlobalSlider-item .Video {
  margin-top: 0;
}
.GlobalSlider-item .Video-cover {
  width: 100%;
  height: 0;
  padding-bottom: 55%;
}
.GlobalSlider-item .Video-title,
.GlobalSlider-item .Video-duration {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .GlobalSlider-item .Video-title,
  .GlobalSlider-item .Video-duration {
    padding-left: 0;
  }
}
.GlobalSlider-arrowLeft,
.GlobalSlider-arrowRight {
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
.GlobalSlider-arrowLeft.isNotSlider,
.GlobalSlider-arrowRight.isNotSlider {
  display: none;
}
.GlobalSlider-arrowLeft.slick-disabled,
.GlobalSlider-arrowRight.slick-disabled {
  display: none !important;
}
@media (min-width: 1920px) {
  .GlobalSlider-arrowLeft {
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider-arrowLeft {
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider-arrowLeft {
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider-arrowLeft {
    left:  3.125vw;
  }
}
@media (min-width: 1920px) {
  .GlobalSlider-arrowRight {
    right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalSlider-arrowRight {
    right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalSlider-arrowRight {
    right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .GlobalSlider-arrowRight {
    right:  3.125vw;
  }
}
