.PageSustainabilityLanding .PageLanding-content {
  padding-bottom: 0;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding .FourColumnFacts-fact {
    margin-right:  26.666666666666664px;
    margin-left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding .FourColumnFacts-fact {
    margin-right:  1.3888888888888888vw;
    margin-left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding .FourColumnFacts-fact {
    margin-right:  1.6129032258064515vw;
    margin-left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .FourColumnFacts-fact {
    margin-right:  6.25vw;
    margin-left:  6.25vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .FourColumnFacts-fact {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding .FourColumnFacts {
    margin-left:  -26.666666666666664px;
    margin-right:  -26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding .FourColumnFacts {
    margin-left:  -1.3888888888888888vw;
    margin-right:  -1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding .FourColumnFacts {
    margin-left:  -1.6129032258064515vw;
    margin-right:  -1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .FourColumnFacts {
    margin-left:  -6.25vw;
    margin-right:  -6.25vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .FourColumnFacts {
    margin: 0;
  }
}
.PageSustainabilityLanding .PageLanding-blockBg_mobile:before {
  content: '';
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  opacity: 0.2;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-workingAtZumtobel {
    padding:  0 186.66666666666666px;
    margin-top:  266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-workingAtZumtobel {
    padding:  0vw 9.722222222222223vw;
    margin-top:  13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-workingAtZumtobel {
    padding:  0vw 11.29032258064516vw;
    margin-top:  16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobel {
    padding:  0vw 6.25vw;
    margin-top:  31.25vw;
  }
}
.PageSustainabilityLanding-workingAtZumtobelWrapper {
  border-top: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-workingAtZumtobelWrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-workingAtZumtobelWrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-workingAtZumtobelWrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelWrapper {
    padding-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelWrapper {
    display: block;
  }
}
.PageSustainabilityLanding-workingAtZumtobelTitle {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
.PageSustainabilityLanding-workingAtZumtobelTextBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
.PageSustainabilityLanding-workingAtZumtobelText {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelText {
    margin-top: 12.5vw;
  }
}
.PageSustainabilityLanding-workingAtZumtobelLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageSustainabilityLanding-workingAtZumtobelLink:hover,
.isNotDesktop .PageSustainabilityLanding-workingAtZumtobelLink:active {
  text-decoration: underline;
}
.isDesktop .PageSustainabilityLanding-workingAtZumtobelLink:hover:before,
.isNotDesktop .PageSustainabilityLanding-workingAtZumtobelLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageSustainabilityLanding-workingAtZumtobelLink:hover:before,
  .isNotDesktop .PageSustainabilityLanding-workingAtZumtobelLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    letter-spacing: 0;
  }
}
.PageSustainabilityLanding-workingAtZumtobelLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageSustainabilityLanding-workingAtZumtobelLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-workingAtZumtobelLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-workingAtZumtobelLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-workingAtZumtobelLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-workingAtZumtobelLink {
    margin-top:  12.5vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-ways {
    padding:  0 186.66666666666666px;
    margin-top:  200px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-ways {
    padding:  0vw 9.722222222222223vw;
    margin-top:  10.416666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-ways {
    padding:  0vw 11.29032258064516vw;
    margin-top:  12.096774193548386vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ways {
    padding:  0vw 6.25vw;
    margin-top:  31.25vw;
  }
}
.PageSustainabilityLanding-waysWrapper {
  border-top: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-waysWrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-waysWrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-waysWrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-waysWrapper {
    padding-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-waysWrapper {
    display: block;
  }
}
.PageSustainabilityLanding-waysTitle {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
.PageSustainabilityLanding-waysText {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-waysWay {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-waysWay {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-waysWay {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-waysWay {
    margin-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-waysWay:first-child {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-waysWay:first-child {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-waysWay:first-child {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-waysWay:first-child {
    margin-top:  12.5vw;
  }
}
.PageSustainabilityLanding-waysWayTitle {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-waysWayDescription {
    margin-top:  9.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-waysWayDescription {
    margin-top:  0.48611111111111116vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-waysWayDescription {
    margin-top:  0.564516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-waysWayDescription {
    margin-top:  1.5625vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-waysWayDescription {
    line-height: 1.42857143;
  }
}
.PageSustainabilityLanding-footer {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 50vh;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-footer {
    min-height: 0;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-footer {
    padding-left:  186.66666666666666px;
    padding-right:  186.66666666666666px;
    padding-top:  1466.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-footer {
    padding-left:  9.722222222222223vw;
    padding-right:  9.722222222222223vw;
    padding-top:  76.38888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-footer {
    padding-left:  11.29032258064516vw;
    padding-right:  11.29032258064516vw;
    padding-top:  84.6774193548387vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-footer {
    padding-left:  6.25vw;
    padding-right:  6.25vw;
    padding-top:  93.75vw;
  }
}
.PageSustainabilityLanding-footer .CommonText-quoteText {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText:before {
    left:  -40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText:before {
    left:  -2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText:before {
    left:  -2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText:before {
    left:  -9.375vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-footer .CommonText-quoteText:before {
    left: -4.6875vw;
  }
}
.PageSustainabilityLanding-footer .CommonText-quoteAuthor {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-footer .CommonText-quoteAuthor {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-footer .CommonText-quoteAuthor {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-footer .CommonText-quoteAuthor {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-footer .CommonText-quoteAuthor {
    font-size: 26.66666667px;
  }
}
.PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg,
.PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg_mobile {
  width: 100%;
  background: #242C36;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg,
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  2600px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg,
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  135.41666666666669vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg,
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  157.25806451612902vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg,
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  609.375vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg img,
  .PageSustainabilityLanding .PageLanding-block .PageLanding-blockBg_mobile img {
    height: 250vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-ourApproach,
  .PageSustainabilityLanding-ourGoals {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-ourApproach,
  .PageSustainabilityLanding-ourGoals {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-ourApproach,
  .PageSustainabilityLanding-ourGoals {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourApproach,
  .PageSustainabilityLanding-ourGoals {
    padding:  0vw 6.25vw;
  }
}
.PageSustainabilityLanding-ourApproachWrapper,
.PageSustainabilityLanding-ourGoalsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-ourApproachWrapper,
  .PageSustainabilityLanding-ourGoalsWrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-ourApproachWrapper,
  .PageSustainabilityLanding-ourGoalsWrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-ourApproachWrapper,
  .PageSustainabilityLanding-ourGoalsWrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourApproachWrapper,
  .PageSustainabilityLanding-ourGoalsWrapper {
    padding-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourApproachWrapper,
  .PageSustainabilityLanding-ourGoalsWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.PageSustainabilityLanding-ourApproachTitle,
.PageSustainabilityLanding-ourGoalsTitle {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
  color: #FFFFFF;
}
.PageSustainabilityLanding-ourApproachContent,
.PageSustainabilityLanding-ourGoalsContent {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-ourApproachContent,
  .PageSustainabilityLanding-ourGoalsContent {
    margin-top:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-ourApproachContent,
  .PageSustainabilityLanding-ourGoalsContent {
    margin-top:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-ourApproachContent,
  .PageSustainabilityLanding-ourGoalsContent {
    margin-top:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourApproachContent,
  .PageSustainabilityLanding-ourGoalsContent {
    margin-top:  25vw;
  }
}
.PageSustainabilityLanding-ourApproachContent .CommonText-text,
.PageSustainabilityLanding-ourGoalsContent .CommonText-text {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourApproachContent,
  .PageSustainabilityLanding-ourGoalsContent {
    margin-top: 9.375vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-ourApproach {
    margin-top:  266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-ourApproach {
    margin-top:  13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-ourApproach {
    margin-top:  16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourApproach {
    margin-top:  62.5vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-ourGoals {
    margin-top:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-ourGoals {
    margin-top:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-ourGoals {
    margin-top:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-ourGoals {
    margin-top:  25vw;
  }
}
.PageSustainabilityLanding-commitmentWrapper {
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-commitmentWrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-commitmentWrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-commitmentWrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-commitmentWrapper {
    padding-top:  4.6875vw;
  }
}
.PageSustainabilityLanding-commitmentTitleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-commitmentTitleBlock {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.PageSustainabilityLanding-commitmentTitle {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
}
.PageSustainabilityLanding-commitmentDescription {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-commitmentDescription {
    margin-top: 9.375vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-commitmentFacts {
    margin-top:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-commitmentFacts {
    margin-top:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-commitmentFacts {
    margin-top:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-commitmentFacts {
    margin-top:  15.625vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-latestReports {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-latestReports {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-latestReports {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-latestReports {
    padding:  0vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-latestReports .DownloadCard {
    margin-top:  53.33333333333333px;
    width:  453.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-latestReports .DownloadCard {
    margin-top:  2.7777777777777777vw;
    width:  23.61111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-latestReports .DownloadCard {
    margin-top:  3.225806451612903vw;
    width:  22.58064516129032vw;
    height:  14.516129032258064vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-latestReports .DownloadCard {
    margin-top:  12.5vw;
    width:  106.25vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-latestReports .DownloadCard {
    width: 87.5vw;
    height: 50vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-block {
    margin-top:  180px;
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-block {
    margin-top:  9.375vw;
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-block {
    margin-top:  10.887096774193548vw;
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-block {
    margin-top:  25vw;
    padding:  0vw 6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-block_first {
    margin-top:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-block_first {
    margin-top:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-block_first {
    margin-top:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-block_first {
    margin-top:  62.5vw;
  }
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-block_own-paddings {
    padding:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-block_own-paddings {
    padding:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-block_own-paddings {
    padding:  0vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-block_own-paddings {
    padding:  0vw;
  }
}
.PageSustainabilityLanding-block .AboutLightArchitecture {
  min-height: 0;
}
.PageSustainabilityLanding-block .AboutLightArchitecture-content {
  padding-top: 0;
}
.PageSustainabilityLanding-block.PageCompanyLanding-history {
  padding: 0;
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .PageSustainabilityLanding .NewsInsights-title {
    font-size:  66.66666666666666px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding .NewsInsights-title {
    font-size:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding .NewsInsights-title {
    font-size:  4.032258064516129vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .PageSustainabilityLanding .NewsInsights-title {
    font-size:  15.625vw;
  }
}
.PageSustainabilityLanding-compliance {
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .PageSustainabilityLanding-compliance {
    margin-top:  74.66666666666666px;
    width:  320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageSustainabilityLanding-compliance {
    margin-top:  3.8888888888888893vw;
    width:  16.666666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageSustainabilityLanding-compliance {
    margin-top:  4.516129032258064vw;
    width:  19.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .PageSustainabilityLanding-compliance {
    margin-top:  17.5vw;
    width:  75vw;
  }
}
.PageSustainabilityLanding-compliance a {
  text-transform: uppercase;
}
