.ProductsExtraItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  background: #111112;
  cursor: pointer;
  padding-top: 133.61344538%;
}
@media (max-width: 759px) {
  .ProductsExtraItem {
    padding-top: 137.97468354%;
  }
}
.isDesktop .ProductsExtraItem:hover .ProductsExtraItem-overlayBorder,
.isNotDesktop .ProductsExtraItem:active .ProductsExtraItem-overlayBorder {
  opacity: 1;
}
.ProductsExtraItem-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsExtraItem-overlayBorder {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  border: 2px solid #FFFFFF;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.ProductsExtraItem-img {
  width: 100%;
  padding-top: 67.22689076%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductsExtraItem-img {
    padding-top: 56.96202532%;
  }
}
.ProductsExtraItem-imgWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ProductsExtraItem-imgLogo {
  position: relative;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.ProductsExtraItem-imgLogo svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media (max-width: 759px) {
  .ProductsExtraItem-imgLogo[data-type="thorn"] {
    width: 25.625vw;
    height: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem-imgLogo[data-type="thorn"] {
    width: 6.61290323vw;
    height: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem-imgLogo[data-type="thorn"] {
    width: 5.69444444vw;
    height: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductsExtraItem-imgLogo[data-type="thorn"] {
    width: 109.33333333px;
    height: 21.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductsExtraItem-imgLogo[data-type="zumtobel"] {
    width: 42.5vw;
    height: 8.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem-imgLogo[data-type="zumtobel"] {
    width: 10.96774194vw;
    height: 2.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem-imgLogo[data-type="zumtobel"] {
    width: 9.44444444vw;
    height: 1.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductsExtraItem-imgLogo[data-type="zumtobel"] {
    width: 181.33333333px;
    height: 37.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductsExtraItem-imgLogo[data-type="acdc"] {
    width: 20.3125vw;
    height: 6.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem-imgLogo[data-type="acdc"] {
    width: 5.24193548vw;
    height: 1.69354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem-imgLogo[data-type="acdc"] {
    width: 4.51388889vw;
    height: 1.45833333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsExtraItem-imgLogo[data-type="acdc"] {
    width: 86.66666667px;
    height: 28px;
  }
}
.ProductsExtraItem-text {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #A6A8B3;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  text-align: center;
}
.isDesktop .needHoverArrow:hover .ProductsExtraItem-text:before,
.isNotDesktop .needHoverArrow:active .ProductsExtraItem-text:before {
  background-position: 0 0;
}
.ProductsExtraItem-text:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  background: url('/static/img/arrow-big.svg') no-repeat;
  will-change: transform;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
.isDesktop .ProductsExtraItem-text:before {
  -webkit-transition: 0.15s background-position, 0.15s opacity;
  transition: 0.15s background-position, 0.15s opacity;
}
@media (min-width: 1920px) {
  .ProductsExtraItem-text:before {
    width:  46.666666666666664px;
    height:  24px;
    background-size:  46.666666666666664px 24px;
    background-position:  -10.666666666666666px 0;
    vertical-align:  -5.333333333333333px;
    margin-left:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem-text:before {
    width:  2.430555555555556vw;
    height:  1.25vw;
    background-size:  2.430555555555556vw 1.25vw;
    background-position:  -0.5555555555555556vw 0vw;
    vertical-align:  -0.2777777777777778vw;
    margin-left:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem-text:before {
    width:  2.82258064516129vw;
    height:  1.4516129032258065vw;
    background-size:  2.82258064516129vw 1.4516129032258065vw;
    background-position:  -0.6451612903225806vw 0vw;
    vertical-align:  -0.3225806451612903vw;
    margin-left:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .ProductsExtraItem-text:before {
    width:  8.125vw;
    height:  4.375vw;
    background-size:  8.125vw 4.375vw;
    vertical-align:  -0.9375vw;
    margin-left:  1.25vw;
    background-position:  -1.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductsExtraItem-text {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem-text {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem-text {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsExtraItem-text {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductsExtraItem-text {
    padding: 0 4.6875vw;
    margin-top: 1.875vw;
    height: 32.5vw;
    line-height: 1.55555556;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem-text {
    margin-top: 0.48387097vw;
    padding: 0 1.37096774vw;
    height: 8.38709677vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem-text {
    margin-top: 0.41666667vw;
    padding: 0 1.18055556vw;
    height: 7.22222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsExtraItem-text {
    margin-top: 8px;
    padding: 0 22.66666667px;
    height: 138.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductsExtraItem--acdc .ProductsExtraItem-text {
    padding: 0 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsExtraItem--acdc .ProductsExtraItem-text {
    padding: 0 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsExtraItem--acdc .ProductsExtraItem-text {
    padding: 0 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsExtraItem--acdc .ProductsExtraItem-text {
    padding: 0 40px;
  }
}
