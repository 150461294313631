@media (max-width: 759px) {
  .ProductFamilyAccessories {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 10.9375vw;
    margin-left: -6.25vw;
    margin-right: -6.25vw;
    padding-top: 0.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories {
    padding-bottom: 7.25806452vw;
    padding-top: 5.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories {
    padding-bottom: 6.25vw;
    padding-top: 5.64516129vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories {
    padding-bottom: 120px;
    padding-top: 93.33333333px;
  }
}
.ProductFamilyAccessories-titleBlock {
  width: 100%;
}
.ProductFamilyAccessories-title {
  color: #111112;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-title {
    display: none;
  }
}
.ProductFamilyAccessories-titleMobile {
  color: #111112;
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-titleMobile {
    display: block;
    padding: 0 6.25vw;
  }
}
.ProductFamilyAccessories-contentBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-contentBlock {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 11.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-contentBlock {
    margin: 2.82258065vw -1.61290323vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-contentBlock {
    margin: 2.43055556vw -1.38888889vw 0;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-contentBlock {
    margin: 46.66666667px -26.66666667px 0;
  }
}
.ProductFamilyAccessories-item {
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  border: 2px solid transparent;
  text-decoration: none;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  opacity: 1;
}
.ProductFamilyAccessories-item.hide {
  opacity: 0;
}
.isDesktop .ProductFamilyAccessories-item:hover,
.isNotDesktop .ProductFamilyAccessories-item:active {
  border: 2px solid #111112;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 49.7%;
            flex: 0 0 49.7%;
    margin-bottom: 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-item {
    height: 29.03225806vw;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(25% - 3.22580645vw);
            flex: 0 0 calc(25% - 3.22580645vw);
    margin: 3.22580645vw 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-item {
    height: 25vw;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(25% - 2.77777778vw);
            flex: 0 0 calc(25% - 2.77777778vw);
    margin: 2.77777778vw 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-item {
    height: 480px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(25% - 53.33333333px);
            flex: 0 0 calc(25% - 53.33333333px);
    margin: 53.33333333px 26.66666667px;
  }
}
.isIE .ProductFamilyAccessories-item {
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
}
.ProductFamilyAccessories-item:nth-child(1),
.ProductFamilyAccessories-item:nth-child(2),
.ProductFamilyAccessories-item:nth-child(3),
.ProductFamilyAccessories-item:nth-child(4) {
  margin-top: 0;
}
.ProductFamilyAccessories-itemImage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-itemImage {
    height: 53.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-itemImage {
    height: 20.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-itemImage {
    height: 18.05555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-itemImage {
    height: 346.66666667px;
  }
}
.ProductFamilyAccessories-itemImage img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
.ProductFamilyAccessories-itemCategory {
  color: #6F7380;
  text-transform: uppercase;
  text-align: center;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-itemCategory {
    padding: 0 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-itemCategory {
    padding: 0 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-itemCategory {
    padding: 0 53.33333333px;
  }
}
.ProductFamilyAccessories-itemName {
  color: #111112;
  text-align: center;
  text-transform: none;
  display: block;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-itemName {
    line-height: 4.6875vw;
    padding: 0 0 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-itemName {
    padding: 0 3.22580645vw 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-itemName {
    padding: 0 2.77777778vw 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-itemName {
    padding: 0 53.33333333px 26.66666667px;
  }
}
.ProductFamilyAccessories-showMore {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.isIE .ProductFamilyAccessories-showMore {
  width: auto;
  display: inline-block;
  margin-left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMore {
    margin-top: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-showMore {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-showMore {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-showMore {
    margin-top: 40px;
  }
}
.ProductFamilyAccessories-showMoreText {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMoreText {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-showMoreText {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-showMoreText {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-showMoreText {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductFamilyAccessories-showMoreText:hover,
.isNotDesktop .ProductFamilyAccessories-showMoreText:active {
  text-decoration: underline;
}
.isDesktop .ProductFamilyAccessories-showMoreText:hover:before,
.isNotDesktop .ProductFamilyAccessories-showMoreText:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyAccessories-showMoreText:hover:before,
  .isNotDesktop .ProductFamilyAccessories-showMoreText:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMoreText {
    letter-spacing: 0;
  }
}
.ProductFamilyAccessories-showMoreText:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/svg/show-more-plus.svg') no-repeat;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMoreText:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductFamilyAccessories-showMoreText:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.isDesktop .ProductFamilyAccessories-showMoreText:hover {
  text-decoration: none;
}
.isDesktop .ProductFamilyAccessories-showMoreText:hover .ProductFamilyAccessories-showMoreTextTitle {
  text-decoration: underline;
}
.ProductFamilyAccessories-showMoreText:before {
  content: '';
  display: inline-block;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isDesktop .ProductFamilyAccessories-showMoreText:before {
  -webkit-transition: none;
  transition: none;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 4.375vw;
    height: 4.375vw;
    background-size: 4.375vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 1.4516129vw;
    height: 1.4516129vw;
    background-size: 1.4516129vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 1.31944444vw;
    height: 1.25vw;
    background-size: 1.25vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-showMoreText:before {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
  }
}
.ProductFamilyAccessories-showMoreCount {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ProductFamilyAccessories-showMoreCount {
    margin-left: 3px;
    font-weight: bold;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyAccessories-showMoreCount {
    margin-left: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyAccessories-showMoreCount {
    margin-left: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyAccessories-showMoreCount {
    margin-left: 6.66666667px;
  }
}
