.ContactRegionalOffices {
  width: 100%;
  height: 100%;
}
.ContactRegionalOffices-headWrapper {
  height: 100%;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-headWrapper {
    padding-bottom: 9.375vw;
    background: #F6F6F6;
  }
}
.ContactRegionalOffices-block {
  display: none;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-block {
    display: block;
    height: 6.25vw;
  }
}
.ContactRegionalOffices-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-mobileText {
    width: 46.875vw;
    line-height: 6.25vw;
  }
}
.ContactRegionalOffices-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    top: 7.5vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactRegionalOffices-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactRegionalOffices-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-filters {
    display: block;
    padding: 0 6.25vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-filterItem {
    width:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-filterItem {
    width:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-filterItem {
    width:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-filterItem {
    width:  50vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-filterItem {
    width: 100%;
    margin-top: 6.25vw;
  }
}
.ContactRegionalOffices-filterItemTitle {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-filterItemTitle {
    margin-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-filterItemTitle {
    margin-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-filterItemTitle {
    margin-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-filterItemTitle {
    margin-bottom:  0vw;
  }
}
.ContactRegionalOffices-clearFilters {
  position: absolute;
  cursor: pointer;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.ContactRegionalOffices-clearFilters:hover {
  opacity: 0.7;
}
.ContactRegionalOffices-clearFilters.hide {
  display: none;
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-clearFilters {
    right:  -53.33333333333333px;
    padding-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-clearFilters {
    right:  -2.7777777777777777vw;
    padding-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-clearFilters {
    right:  -3.225806451612903vw;
    padding-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-clearFilters {
    right:  -12.5vw;
    padding-bottom:  3.125vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-mainWrapper {
    padding: 0 6.25vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-infoWrapper {
    margin-top: 9.375vw;
  }
}
.ContactRegionalOffices-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-info {
    display: block;
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-info {
    margin-top: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-info {
    margin-top: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-info {
    margin-top: 66.66666667px;
  }
}
.ContactRegionalOffices-info:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-info:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-info:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-info:not(:last-child):after {
    bottom: -1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-info:not(:last-child):after {
    bottom: -33.33333333px;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-address {
    -ms-flex-preferred-size:  346.66666666666663px;
        flex-basis:  346.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-address {
    -ms-flex-preferred-size:  18.055555555555557vw;
        flex-basis:  18.055555555555557vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-address {
    -ms-flex-preferred-size:  20.967741935483872vw;
        flex-basis:  20.967741935483872vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-address {
    -ms-flex-preferred-size:  81.25vw;
        flex-basis:  81.25vw;
  }
}
.ContactRegionalOffices-address span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-address span {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-contacts {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-contacts {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-contacts {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-contacts {
    margin-left:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-contacts {
    padding-top: 7.8125vw;
    padding-bottom: 9.375vw;
    font-size: 4.375vw;
    line-height: 6.25vw;
    margin-left: 0;
  }
}
.ContactRegionalOffices-contacts span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-contacts span {
    font-size: 4.375vw;
  }
}
.ContactRegionalOffices-contacts a {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-contacts a {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.isDesktop .ContactRegionalOffices-contacts a:hover,
.isNotDesktop .ContactRegionalOffices-contacts a:active {
  text-decoration: none;
}
.ContactRegionalOffices-contacts a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ContactRegionalOffices-icon {
  height: auto;
  width: auto;
  margin-left: auto;
  background: url('/static/svg/geo-icon.svg') no-repeat;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-icon {
    width: 6.5625vw;
    height: 10vw;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-icon {
    width: 1.85483871vw;
    height: 2.90322581vw;
    margin-right: 1.61290323vw;
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-icon {
    width: 1.59722222vw;
    height: 2.5vw;
    margin-right: 1.38888889vw;
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-icon {
    width: 30.66666667px;
    height: 48px;
    margin-right: 26.66666667px;
    margin-top: 20px;
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-icon svg {
    width: 6.5625vw;
    height: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-icon svg {
    width: 1.85483871vw;
    height: 2.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-icon svg {
    width: 1.59722222vw;
    height: 2.5vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-icon svg {
    width: 30.66666667px;
    height: 48px;
  }
}
.ContactRegionalOffices-icon span {
  display: none;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-icon span {
    display: block;
    height: 100%;
    margin-left: 9.6875vw;
    line-height: 9.375vw;
  }
}
.isDesktop .ContactRegionalOffices-icon:hover,
.isNotDesktop .ContactRegionalOffices-icon:active {
  background: url('/static/svg/geo-icon-dark.svg') no-repeat;
  -webkit-transition: background 0.15s;
  transition: background 0.15s;
  cursor: pointer;
}
.ContactRegionalOffices-cardsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardsWrapper {
    display: block;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardsWrapper {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardsWrapper {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardsWrapper {
    margin-top: 53.33333333px;
  }
}
.ContactRegionalOffices-cardLink {
  background: #6F7380;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  color: #FFFFFF;
  position: relative;
}
.ContactRegionalOffices-cardLink:hover .ContactRegionalOffices-cardLink-link {
  text-decoration: underline;
}
.ContactRegionalOffices-cardLink:hover .ContactRegionalOffices-cardLink-link:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink:hover .ContactRegionalOffices-cardLink-link:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink {
    width: 100%;
    height: 50vw;
    padding: 6.5625vw;
    -webkit-box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
            box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardLink {
    width: 20.96774194vw;
    height: 22.58064516vw;
    padding: 1.69354839vw;
    -webkit-box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.35);
            box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardLink {
    width: 18.05555556vw;
    height: 19.44444444vw;
    padding: 1.45833333vw;
    -webkit-box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.35);
            box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardLink {
    width: 346.66666667px;
    height: 373.33333333px;
    padding: 28px;
    -webkit-box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.35);
            box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.35);
  }
}
.ContactRegionalOffices-cardLink a {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}
.isDesktop .ContactRegionalOffices-cardLink:hover,
.isNotDesktop .ContactRegionalOffices-cardLink:active {
  cursor: pointer;
  background: #A6A8B3;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
@media (max-width: 759px) {
  .isDesktop .ContactRegionalOffices-cardLink:hover,
  .isNotDesktop .ContactRegionalOffices-cardLink:active {
    -webkit-box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.4);
            box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ContactRegionalOffices-cardLink:hover,
  .isNotDesktop .ContactRegionalOffices-cardLink:active {
    -webkit-box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ContactRegionalOffices-cardLink:hover,
  .isNotDesktop .ContactRegionalOffices-cardLink:active {
    -webkit-box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1920px) {
  .isDesktop .ContactRegionalOffices-cardLink:hover,
  .isNotDesktop .ContactRegionalOffices-cardLink:active {
    -webkit-box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.5);
            box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.5);
  }
}
.ContactRegionalOffices-cardLink:before {
  background: none;
}
.ContactRegionalOffices-cardLink-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  position: absolute;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardLink-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardLink-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardLink-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .ContactRegionalOffices-cardLink-link:hover,
.isNotDesktop .ContactRegionalOffices-cardLink-link:active {
  text-decoration: underline;
}
.isDesktop .ContactRegionalOffices-cardLink-link:hover:before,
.isNotDesktop .ContactRegionalOffices-cardLink-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ContactRegionalOffices-cardLink-link:hover:before,
  .isNotDesktop .ContactRegionalOffices-cardLink-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink-link {
    letter-spacing: 0;
  }
}
.ContactRegionalOffices-cardLink-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ContactRegionalOffices-cardLink-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardLink-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardLink-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardLink-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardLink-link {
    margin-top: 6.25vw;
    bottom: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardLink-link {
    margin-top: 3.22580645vw;
    bottom: 1.69354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardLink-link {
    margin-top: 2.77777778vw;
    bottom: 1.45833333vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardLink-link {
    margin-top: 53.33333333px;
    bottom: 28px;
  }
}
.ContactRegionalOffices-cardContact {
  background: #6F7380;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  color: #FFFFFF;
  position: relative;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardContact {
    width: 100%;
    height: 50vw;
    margin-top: 6.25vw;
    padding: 6.5625vw;
    -webkit-box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
            box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardContact {
    width: 20.96774194vw;
    height: 22.58064516vw;
    padding: 1.69354839vw;
    -webkit-box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.35);
            box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardContact {
    width: 18.05555556vw;
    height: 19.44444444vw;
    padding: 1.45833333vw;
    -webkit-box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.35);
            box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardContact {
    width: 346.66666667px;
    height: 373.33333333px;
    padding: 28px;
    -webkit-box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.35);
            box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.35);
  }
}
.ContactRegionalOffices-cardContact a {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}
.isDesktop .ContactRegionalOffices-cardContact:hover,
.isNotDesktop .ContactRegionalOffices-cardContact:active {
  cursor: pointer;
  background: #A6A8B3;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
@media (max-width: 759px) {
  .isDesktop .ContactRegionalOffices-cardContact:hover,
  .isNotDesktop .ContactRegionalOffices-cardContact:active {
    -webkit-box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.4);
            box-shadow: 0 1.5625vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ContactRegionalOffices-cardContact:hover,
  .isNotDesktop .ContactRegionalOffices-cardContact:active {
    -webkit-box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.80645161vw 3.22580645vw 0.40322581vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ContactRegionalOffices-cardContact:hover,
  .isNotDesktop .ContactRegionalOffices-cardContact:active {
    -webkit-box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.69444444vw 2.77777778vw 0.34722222vw rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1920px) {
  .isDesktop .ContactRegionalOffices-cardContact:hover,
  .isNotDesktop .ContactRegionalOffices-cardContact:active {
    -webkit-box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.5);
            box-shadow: 0 13.33333333px 53.33333333px 6.66666667px rgba(0, 0, 0, 0.5);
  }
}
.isDesktop .ContactRegionalOffices-cardContact:hover .ContactRegionalOffices-infoPosition,
.isNotDesktop .ContactRegionalOffices-cardContact:active .ContactRegionalOffices-infoPosition {
  color: #FFFFFF;
  opacity: 0.4;
  -webkit-transition: color 0.15s, opacity 0.15s;
  transition: color 0.15s, opacity 0.15s;
}
.ContactRegionalOffices-cardContact:before {
  background: none;
}
.ContactRegionalOffices-cardContact:hover .ContactRegionalOffices-cardLink-link {
  text-decoration: underline;
}
.ContactRegionalOffices-cardContact:hover .ContactRegionalOffices-cardLink-link:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardContact:hover .ContactRegionalOffices-cardLink-link:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.ContactRegionalOffices-cardContact-contact {
  position: absolute;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-cardContact-contact {
    margin-bottom: 12.5vw;
    bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-cardContact-contact {
    margin-bottom: 7.66129032vw;
    bottom: 1.69354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-cardContact-contact {
    margin-bottom: 6.59722222vw;
    bottom: 1.45833333vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-cardContact-contact {
    margin-bottom: 126.66666667px;
    bottom: 28px;
  }
}
.ContactRegionalOffices-cardContact-contact a {
  text-decoration: underline;
}
.isDesktop .ContactRegionalOffices-cardContact-contact a:hover,
.isNotDesktop .ContactRegionalOffices-cardContact-contact a:active {
  text-decoration: none;
}
.ContactRegionalOffices-cardContact-contact a[href^="tel"] {
  color: #FFFFFF;
  text-decoration: underline;
}
.ContactRegionalOffices-infoPosition {
  color: #A6A8B3;
  opacity: 1;
  -webkit-transition: color 0.15s, opacity 0.15s;
  transition: color 0.15s, opacity 0.15s;
}
.ContactRegionalOffices-line {
  font-weight: 500;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-line {
    line-height: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-line {
    line-height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-line {
    line-height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-line {
    line-height: 26.66666667px;
  }
}
.ContactRegionalOffices-infoName {
  font-weight: 600;
}
.ContactRegionalOffices-teamTitle {
  border-top: 1px solid #111112;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-teamTitle {
    margin-top: 9.375vw;
    padding-top: 6.875vw;
    padding-bottom: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-teamTitle {
    margin-top: 8.06451613vw;
    padding-top: 2.58064516vw;
    padding-bottom: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-teamTitle {
    margin-top: 6.94444444vw;
    padding-top: 2.22222222vw;
    padding-bottom: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-teamTitle {
    margin-top: 133.33333333px;
    padding-top: 42.66666667px;
    padding-bottom: 42.66666667px;
  }
}
.ContactRegionalOffices-teamWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-teamWrapper {
    display: block;
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-teamWrapper {
    margin-bottom: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-teamWrapper {
    margin-bottom: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-teamWrapper {
    margin-bottom: 80px;
  }
}
.ContactRegionalOffices-teamMember {
  word-break: break-all;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-teamMember {
    width: 100%;
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-teamMember {
    width: 20.96774194vw;
    margin-bottom: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-teamMember {
    width: 18.05555556vw;
    margin-bottom: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-teamMember {
    width: 346.66666667px;
    margin-bottom: 80px;
  }
}
.ContactRegionalOffices-teamMember a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactRegionalOffices-teamMember a:hover,
.isNotDesktop .ContactRegionalOffices-teamMember a:active {
  text-decoration: none;
}
.ContactRegionalOffices-teamMember a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ContactRegionalOffices-teamName {
  font-weight: 600;
}
@media (max-width: 759px) {
  .ContactRegionalOffices-teamName {
    margin-top: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactRegionalOffices-teamName {
    margin-top: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactRegionalOffices-teamName {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactRegionalOffices-teamName {
    margin-top: 26.66666667px;
  }
}
.ContactRegionalOffices-teamPosition,
.ContactRegionalOffices-teamPhone {
  color: #6F7380;
}
