.AboutLightArchitecture {
  min-height: 100vh;
}
.AboutLightArchitecture-content {
  overflow: hidden;
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-content {
    padding: 240px 0 133.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-content {
    padding: 12.5vw 0 6.94444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-content {
    padding: 14.51612903vw 0 8.06451613vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-content {
    padding: 25vw 0 11.25vw;
  }
}
.AboutLightArchitecture-titleBlock {
  border-top: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-titleBlock {
    margin: 0 186.66666667px;
    padding-top: 33.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-titleBlock {
    margin: 0 9.72222222vw;
    padding-top: 1.73611111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-titleBlock {
    margin: 0 11.29032258vw;
    padding-top: 2.01612903vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-titleBlock {
    margin: 0 6.25vw;
    padding-top: 3.125vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.AboutLightArchitecture-title {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s transform;
  transition: 0.4s opacity, 0.4s transform, 0.4s -webkit-transform;
}
.AboutLightArchitecture-title.show {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}
.AboutLightArchitecture-description {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s transform;
  transition: 0.4s opacity, 0.4s transform, 0.4s -webkit-transform;
}
.AboutLightArchitecture-description.show {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-description {
    margin-top: 9.6875vw;
  }
}
.AboutLightArchitecture-objects {
  position: relative;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s transform;
  transition: 0.4s opacity, 0.4s transform, 0.4s -webkit-transform;
  opacity: 1;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(20) {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.AboutLightArchitecture-objects.show .AboutLightArchitecture-object:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-objects {
    margin: 100px 0 0 -26.66666667px;
    padding-left: 186.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-objects {
    margin: 5.20833333vw 0 0 -1.38888889vw;
    padding-left: 9.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-objects {
    margin: 6.0483871vw 0 0 -1.61290323vw;
    padding-left: 11.29032258vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-objects {
    margin: 12.5vw 0 0 -6.25vw;
    padding-left: 6.25vw;
  }
}
.AboutLightArchitecture-objectsInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  will-change: transform;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
.AboutLightArchitecture-objectsArrowRight,
.AboutLightArchitecture-objectsArrowLeft {
  position: absolute;
  top: 50%;
  will-change: transform;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  z-index: 10;
  cursor: pointer;
}
.isNotDesktop .AboutLightArchitecture-objectsArrowRight,
.isNotDesktop .AboutLightArchitecture-objectsArrowLeft {
  display: none;
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-objectsArrowRight svg,
  .AboutLightArchitecture-objectsArrowLeft svg {
    width: 26.66666667px;
    height: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-objectsArrowRight svg,
  .AboutLightArchitecture-objectsArrowLeft svg {
    width: 1.38888889vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-objectsArrowRight svg,
  .AboutLightArchitecture-objectsArrowLeft svg {
    width: 1.61290323vw;
    height: 3.22580645vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-objectsArrowRight svg,
  .AboutLightArchitecture-objectsArrowLeft svg {
    width: 4.6875vw;
    height: 7.8125vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-objectsArrowRight {
    right: 22.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-objectsArrowRight {
    right: 1.18055556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-objectsArrowRight {
    right: 1.37096774vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-objectsArrowRight {
    right: 0.625vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-objectsArrowLeft {
    left: 58.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-objectsArrowLeft {
    left: 3.05555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-objectsArrowLeft {
    left: 3.5483871vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-objectsArrowLeft {
    left: 6.875vw;
  }
}
.AboutLightArchitecture-object {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: block;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 10%, 0);
          transform: translate3d(0, 10%, 0);
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-object {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-object {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-object {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-object {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .AboutLightArchitecture-object:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .AboutLightArchitecture-object-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .AboutLightArchitecture-object:hover .AboutLightArchitecture-object-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .AboutLightArchitecture-object:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.AboutLightArchitecture-object:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .AboutLightArchitecture-object:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .AboutLightArchitecture-object:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .AboutLightArchitecture-object:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .AboutLightArchitecture-object:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.AboutLightArchitecture-object:active:after {
  opacity: 0.5;
}
.AboutLightArchitecture-object:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .AboutLightArchitecture-object:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-object:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-object:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-object:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-object:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.AboutLightArchitecture-object:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .AboutLightArchitecture-object:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .AboutLightArchitecture-object:hover img,
.isNotDesktop .AboutLightArchitecture-object:active img {
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 746.66666667px;
            flex: 0 0 746.66666667px;
    height: 426.66666667px;
    margin: 0 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 38.88888889vw;
            flex: 0 0 38.88888889vw;
    height: 22.22222222vw;
    margin: 0 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 36.12903226vw;
            flex: 0 0 36.12903226vw;
    height: 20.64516129vw;
    margin: 0 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 87.5vw;
            flex: 0 0 87.5vw;
    height: 50vw;
    margin: 0 6.25vw;
  }
  .isDesktop .AboutLightArchitecture-object {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 82.8125vw;
            flex: 0 0 82.8125vw;
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-object:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-object:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-object:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-object:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
.AboutLightArchitecture-object img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  will-change: transform;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.AboutLightArchitecture-objectTitle {
  display: inline;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.AboutLightArchitecture-objectName {
  letter-spacing: normal;
  display: inline;
}
.AboutLightArchitecture-objectLocation {
  text-transform: none;
  letter-spacing: normal;
  display: inline;
}
.AboutLightArchitecture-objectCategory {
  letter-spacing: normal;
  display: block;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-objectCategory {
    margin-top: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-objectCategory {
    margin-top: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-objectCategory {
    margin-top: 6.66666667px;
  }
}
.AboutLightArchitecture-titleWrapper {
  color: #FFFFFF;
  position: absolute;
  z-index: 1;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-titleWrapper {
    bottom: 6.25vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-titleWrapper {
    bottom: 1.61290323vw;
    left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-titleWrapper {
    bottom: 1.38888889vw;
    left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-titleWrapper {
    bottom: 22.66666667px;
    left: 26.66666667px;
  }
}
.AboutLightArchitecture-allInspiration {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-allInspiration {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-allInspiration {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-allInspiration {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-allInspiration {
    font-size: 18.66666667px;
  }
}
.isDesktop .AboutLightArchitecture-allInspiration:hover,
.isNotDesktop .AboutLightArchitecture-allInspiration:active {
  text-decoration: underline;
}
.isDesktop .AboutLightArchitecture-allInspiration:hover:before,
.isNotDesktop .AboutLightArchitecture-allInspiration:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .AboutLightArchitecture-allInspiration:hover:before,
  .isNotDesktop .AboutLightArchitecture-allInspiration:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-allInspiration {
    letter-spacing: 0;
  }
}
.AboutLightArchitecture-allInspiration:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-allInspiration:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .AboutLightArchitecture-allInspiration:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-allInspiration:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-allInspiration:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-allInspiration:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-allInspiration:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .AboutLightArchitecture-allInspiration {
    margin-left: 6.25vw;
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-allInspiration {
    margin-left: 11.29032258vw;
    margin-top: 3.5483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-allInspiration {
    margin-left: 9.72222222vw;
    margin-top: 3.05555556vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-allInspiration {
    margin-left: 186.66666667px;
    margin-top: 58.66666667px;
  }
}
.AboutLightArchitecture-navLines {
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-navLines {
    bottom: -10.9375vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
  }
  .isDesktop .AboutLightArchitecture-navLines {
    width: 96.25vw;
  }
}
.AboutLightArchitecture-navLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-navLine {
    height: 9.375vw;
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-navLine {
    height: 2.41935484vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-navLine {
    height: 2.08333333vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-navLine {
    height: 40px;
    width: 77.33333333px;
  }
}
.isDesktop .AboutLightArchitecture-navLine:hover:after,
.isNotDesktop .AboutLightArchitecture-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .AboutLightArchitecture-navLine:hover:after,
  .isNotDesktop .AboutLightArchitecture-navLine:active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .AboutLightArchitecture-navLine:hover:after,
  .isNotDesktop .AboutLightArchitecture-navLine:active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .AboutLightArchitecture-navLine:hover:after,
  .isNotDesktop .AboutLightArchitecture-navLine:active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .AboutLightArchitecture-navLine:hover:after,
  .isNotDesktop .AboutLightArchitecture-navLine:active:after {
    height: 5.33333333px;
  }
}
.AboutLightArchitecture-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.AboutLightArchitecture-navLine.active {
  pointer-events: none;
}
.AboutLightArchitecture-navLine.active:after {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .AboutLightArchitecture-navLine.active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutLightArchitecture-navLine.active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutLightArchitecture-navLine.active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .AboutLightArchitecture-navLine.active:after {
    height: 5.33333333px;
  }
}
.AboutLightArchitecture-navLine:first-child {
  margin-left: 0;
}
