.ProductsItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  background: #FFFFFF;
  cursor: pointer;
  padding-top: 133.61344538%;
}
.brand--thorn .ProductsItem {
  background: #0D1B28;
}
@media (max-width: 759px) {
  .ProductsItem {
    padding-top: 137.97468354%;
  }
}
.isDesktop .ProductsItem:hover .ProductsItem-overlayBorder,
.isNotDesktop .ProductsItem:active .ProductsItem-overlayBorder {
  opacity: 1;
}
.isDesktop .ProductsItem:hover .ProductsItem-attrs,
.isNotDesktop .ProductsItem:active .ProductsItem-attrs {
  opacity: 1;
}
.isDesktop .ProductsItem:hover .ProductsItem-img img,
.isNotDesktop .ProductsItem:active .ProductsItem-img img {
  opacity: 0;
}
.ProductsItem-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsItem-overlayBorder {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  border: 2px solid #111112;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.brand--thorn .ProductsItem-overlayBorder {
  border: 2px solid #FFFFFF;
}
.ProductsItem-mountingImages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ProductsItem-mountingImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  display: none;
}
.ProductsItem-mountingImage.show {
  display: block;
}
.ProductsItem-img {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.ProductsItem-imgInner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ProductsItem-imgInner.hide {
  display: none;
}
.ProductsItem-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.ProductsItem-imgOverlay {
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
}
.brand--thorn .ProductsItem-imgOverlay {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(14, 28, 41, 0)), to(#0e1c29));
  background: linear-gradient(to bottom, rgba(14, 28, 41, 0), #0e1c29);
}
@media (min-width: 1920px) {
  .ProductsItem-imgOverlay {
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-imgOverlay {
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-imgOverlay {
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-imgOverlay {
    height:  12.5vw;
  }
}
.ProductsItem-attrs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  opacity: 0;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.brand--thorn .ProductsItem-attrs {
  background: #0D1B28;
}
@media (min-width: 1920px) {
  .ProductsItem-attrs {
    padding:  60px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-attrs {
    padding:  3.125vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-attrs {
    padding:  3.629032258064516vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-attrs {
    padding:  14.0625vw 6.25vw 0vw;
  }
}
.ProductsItem-attr {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #E7E7E7;
  color: #6F7380;
  width: 100%;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductsItem-attr {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-attr {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-attr {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-attr {
    font-size: 16px;
  }
}
.brand--thorn .ProductsItem-attr {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}
.ProductsItem-attr:first-child {
  border-top: none;
}
@media (min-width: 1920px) {
  .ProductsItem-attr {
    padding:  13.333333333333332px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-attr {
    padding:  0.6944444444444444vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-attr {
    padding:  0.8064516129032258vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-attr {
    padding:  3.125vw 0vw;
  }
}
.ProductsItem-name {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 1920px) {
  .ProductsItem-name {
    padding:  0 26.666666666666664px;
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-name {
    padding:  0vw 1.3888888888888888vw;
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-name {
    padding:  0vw 1.6129032258064515vw;
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-name {
    padding:  0vw 6.25vw;
    margin-top:  1.5625vw;
  }
}
.ProductsItem-nameTitle {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: none;
  text-align: center;
}
@media (max-width: 759px) {
  .ProductsItem-nameTitle {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-nameTitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-nameTitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-nameTitle {
    font-size: 18.66666667px;
  }
}
.brand--thorn .ProductsItem-nameTitle {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductsItem-nameTitle {
    line-height: 6.25vw;
  }
}
.ProductsItem-nameType {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductsItem-nameType {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-nameType {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-nameType {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-nameType {
    font-size: 16px;
  }
}
.ProductsItem-extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  background: #111112;
  cursor: pointer;
  padding-top: 133.61344538%;
}
.brand--thorn .ProductsItem-extra {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductsItem-extra {
    padding-top: 137.97468354%;
  }
}
.isDesktop .ProductsItem-extra:hover .ProductsItem-extraOverlayBorder,
.isNotDesktop .ProductsItem-extra:active .ProductsItem-extraOverlayBorder {
  opacity: 1;
}
.ProductsItem-extraWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsItem-extraOverlayBorder {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  border: 2px solid #111112;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.brand--thorn .ProductsItem-extraOverlayBorder {
  border: 2px solid #FFFFFF;
}
.ProductsItem-extraImg {
  width: 100%;
  padding-top: 67.22689076%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductsItem-extraImg {
    padding-top: 56.96202532%;
  }
}
.ProductsItem-extraImgWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ProductsItem-extraImgLogo {
  position: relative;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.ProductsItem-extraImgLogo svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media (min-width: 1920px) {
  .ProductsItem-extraImgLogo[data-type="thorn"] {
    width:  109.33333333333333px;
    height:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-extraImgLogo[data-type="thorn"] {
    width:  5.694444444444445vw;
    height:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-extraImgLogo[data-type="thorn"] {
    width:  6.612903225806451vw;
    height:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraImgLogo[data-type="thorn"] {
    width:  25.625vw;
    height:  5vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-extraImgLogo[data-type="zumtobel"] {
    width:  181.33333333333331px;
    height:  37.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-extraImgLogo[data-type="zumtobel"] {
    width:  9.444444444444445vw;
    height:  1.9444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-extraImgLogo[data-type="zumtobel"] {
    width:  10.96774193548387vw;
    height:  2.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraImgLogo[data-type="zumtobel"] {
    width:  42.5vw;
    height:  8.75vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-extraImgLogo[data-type="acdc"] {
    width:  86.66666666666666px;
    height:  28px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-extraImgLogo[data-type="acdc"] {
    width:  4.513888888888889vw;
    height:  1.4583333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-extraImgLogo[data-type="acdc"] {
    width:  5.241935483870968vw;
    height:  1.6935483870967742vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraImgLogo[data-type="acdc"] {
    width:  20.3125vw;
    height:  6.5625vw;
  }
}
.ProductsItem-extraText {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #A6A8B3;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  text-align: center;
}
.isDesktop .needHoverArrow:hover .ProductsItem-extraText:before,
.isNotDesktop .needHoverArrow:active .ProductsItem-extraText:before {
  background-position: 0 0;
}
.ProductsItem-extraText:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  background: url('/static/img/arrow-big.svg') no-repeat;
  will-change: transform;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
.isDesktop .ProductsItem-extraText:before {
  -webkit-transition: 0.15s background-position, 0.15s opacity;
  transition: 0.15s background-position, 0.15s opacity;
}
@media (min-width: 1920px) {
  .ProductsItem-extraText:before {
    width:  46.666666666666664px;
    height:  24px;
    background-size:  46.666666666666664px 24px;
    background-position:  -10.666666666666666px 0;
    vertical-align:  -5.333333333333333px;
    margin-left:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-extraText:before {
    width:  2.430555555555556vw;
    height:  1.25vw;
    background-size:  2.430555555555556vw 1.25vw;
    background-position:  -0.5555555555555556vw 0vw;
    vertical-align:  -0.2777777777777778vw;
    margin-left:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-extraText:before {
    width:  2.82258064516129vw;
    height:  1.4516129032258065vw;
    background-size:  2.82258064516129vw 1.4516129032258065vw;
    background-position:  -0.6451612903225806vw 0vw;
    vertical-align:  -0.3225806451612903vw;
    margin-left:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraText:before {
    width:  8.125vw;
    height:  4.375vw;
    background-size:  8.125vw 4.375vw;
    vertical-align:  -0.9375vw;
    margin-left:  1.25vw;
    background-position:  -1.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraText {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-extraText {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-extraText {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-extraText {
    font-size: 16px;
  }
}
.brand--thorn .ProductsItem-extraText {
  position: relative;
  color: #111112;
}
.isDesktop .needHoverArrow:hover .brand--thorn .ProductsItem-extraText:before,
.isNotDesktop .needHoverArrow:active .brand--thorn .ProductsItem-extraText:before {
  background-position: 0 0;
}
.brand--thorn .ProductsItem-extraText:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
  will-change: transform;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
.isDesktop .brand--thorn .ProductsItem-extraText:before {
  -webkit-transition: 0.15s background-position, 0.15s opacity;
  transition: 0.15s background-position, 0.15s opacity;
}
@media (min-width: 1920px) {
  .brand--thorn .ProductsItem-extraText:before {
    width:  46.666666666666664px;
    height:  24px;
    background-size:  46.666666666666664px 24px;
    background-position:  -10.666666666666666px 0;
    vertical-align:  -5.333333333333333px;
    margin-left:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .ProductsItem-extraText:before {
    width:  2.430555555555556vw;
    height:  1.25vw;
    background-size:  2.430555555555556vw 1.25vw;
    background-position:  -0.5555555555555556vw 0vw;
    vertical-align:  -0.2777777777777778vw;
    margin-left:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .ProductsItem-extraText:before {
    width:  2.82258064516129vw;
    height:  1.4516129032258065vw;
    background-size:  2.82258064516129vw 1.4516129032258065vw;
    background-position:  -0.6451612903225806vw 0vw;
    vertical-align:  -0.3225806451612903vw;
    margin-left:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .brand--thorn .ProductsItem-extraText:before {
    width:  8.125vw;
    height:  4.375vw;
    background-size:  8.125vw 4.375vw;
    vertical-align:  -0.9375vw;
    margin-left:  1.25vw;
    background-position:  -1.25vw 0vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-extraText {
    margin-top:  8px;
    padding:  0 22.666666666666664px;
    height:  138.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-extraText {
    margin-top:  0.4166666666666667vw;
    padding:  0vw 1.1805555555555556vw;
    height:  7.222222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-extraText {
    margin-top:  0.4838709677419355vw;
    padding:  0vw 1.3709677419354838vw;
    height:  8.387096774193548vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraText {
    padding:  0vw 4.6875vw;
    margin-top:  1.875vw;
    height:  32.5vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-extraText {
    line-height: 1.55555556;
  }
}
@media (min-width: 1920px) {
  .ProductsItem--acdc .ProductsItem-extraText {
    padding:  0 40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem--acdc .ProductsItem-extraText {
    padding:  0vw 2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem--acdc .ProductsItem-extraText {
    padding:  0vw 2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem--acdc .ProductsItem-extraText {
    padding:  0vw 4.6875vw;
  }
}
.ProductsItem-text {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #E7E7E7;
  color: #6F7380;
  width: 100%;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductsItem-text {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-text {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-text {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsItem-text {
    font-size: 16px;
  }
}
.brand--thorn .ProductsItem-text {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}
.ProductsItem-text:first-child {
  border-top: none;
}
@media (min-width: 1920px) {
  .ProductsItem-text {
    padding:  13.333333333333332px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsItem-text {
    padding:  0.6944444444444444vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsItem-text {
    padding:  0.8064516129032258vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductsItem-text {
    padding:  3.125vw 0vw;
  }
}
