.InvestorsGovernanceTable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  overflow-x: visible;
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable {
    overflow-x: auto;
  }
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable {
    margin-top:  2.8125vw;
  }
}
.InvestorsGovernanceTable-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.InvestorsGovernanceTable-table:first-child {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .InvestorsGovernanceTable-table {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsGovernanceTable-table {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsGovernanceTable-table {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable-table {
    margin-top:  12.5vw;
    width:  206.25vw;
  }
}
.InvestorsGovernanceTable-tableRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #E7E7E7;
}
.InvestorsGovernanceTable-tableRow:first-child {
  border-top: none;
  padding-top: 0;
}
.InvestorsGovernanceTable-tableRow:first-child .InvestorsGovernanceTable-tableRowItem {
  color: #111112;
  text-transform: uppercase;
}
.InvestorsGovernanceTable-tableRow:last-child {
  padding-bottom: 0;
}
@media (min-width: 1920px) {
  .InvestorsGovernanceTable-tableRow {
    padding:  13.333333333333332px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsGovernanceTable-tableRow {
    padding:  0.6944444444444444vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsGovernanceTable-tableRow {
    padding:  0.8064516129032258vw 0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable-tableRow {
    padding:  3.125vw 0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable-tableRow {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.InvestorsGovernanceTable-tableRowItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #6F7380;
  width: 13.15789474%;
}
.InvestorsGovernanceTable-tableRowItem:nth-child(2) {
  width: 39.47368421%;
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable-tableRowItem:nth-child(2) {
    width: 45.45454545%;
  }
}
@media (max-width: 759px) {
  .InvestorsGovernanceTable-tableRowItem {
    width: 45.45454545%;
    font-size: 3.75vw;
    line-height: 1.66666667;
    letter-spacing: -0.24px;
  }
}
