@media (min-width: 1920px) {
  .AwardsNominees {
    padding:  0 186.66666666666666px 80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees {
    padding:  0vw 9.722222222222223vw 4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees {
    padding:  0vw 3.225806451612903vw 4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees {
    padding:  0vw 6.25vw 18.75vw;
  }
}
.AwardsNominees-wrapper {
  border-top: 1px solid #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .AwardsNominees-wrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-wrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-wrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-wrapper {
    padding-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-wrapper {
    display: block;
  }
}
.AwardsNominees-titleBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 34%;
          flex: 0 0 34%;
}
@media (max-width: 759px) {
  .AwardsNominees-title {
    font-size: 5.625vw;
  }
}
@media (min-width: 1920px) {
  .AwardsNominees-tabs {
    margin-top:  40px;
    width:  373.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-tabs {
    margin-top:  2.0833333333333335vw;
    width:  19.444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-tabs {
    margin-top:  2.4193548387096775vw;
    width:  22.58064516129032vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-tabs {
    margin-top:  9.375vw;
    width:  87.5vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    margin-top: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .AwardsNominees-tabWrapper {
    padding:  6.666666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-tabWrapper {
    padding:  0.3472222222222222vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-tabWrapper {
    padding:  0.4032258064516129vw 0vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-tabWrapper {
    padding:  1.5625vw 0vw;
  }
}
.AwardsNominees-tabWrapper:first-of-type {
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .AwardsNominees-tabWrapper:first-of-type {
    border: none;
  }
}
.AwardsNominees-tab {
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  width: 100%;
  color: #111112;
  line-height: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .AwardsNominees-tab {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 3.75vw;
  }
}
.isDesktop .AwardsNominees-tab:hover {
  opacity: 0.7;
}
@media (min-width: 1920px) {
  .AwardsNominees-tab {
    padding:  12px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-tab {
    padding:  0.625vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-tab {
    padding:  0.7258064516129032vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-tab {
    padding:  4.0625vw 5.625vw 3.4375vw;
  }
}
.AwardsNominees-tab:before {
  content: '';
  position: absolute;
  width: 100%;
  background: #111112;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: -1;
}
@media (min-width: 1920px) {
  .AwardsNominees-tab:before {
    border-radius:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-tab:before {
    border-radius:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-tab:before {
    border-radius:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-tab:before {
    border-radius:  6.25vw;
  }
}
.AwardsNominees-tab.active {
  pointer-events: none;
  color: #FFFFFF;
}
.AwardsNominees-tab.active:before {
  opacity: 1;
}
.AwardsNominees-items {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66%;
          flex: 0 0 66%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.AwardsNominees-item {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  position: relative;
}
.isDesktop .AwardsNominees-item:hover .AwardsNominees-itemImage {
  color: rgba(0, 0, 0, 0.4);
}
.isDesktop .AwardsNominees-item:hover .AwardsNominees-itemImage img {
  -webkit-transform: translate3d(0, 0, 0) scale(1.05);
          transform: translate3d(0, 0, 0) scale(1.05);
}
@media (min-width: 1920px) {
  .AwardsNominees-item {
    width:  480px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-item {
    width:  25vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-item {
    width:  29.032258064516128vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-item {
    width:  87.5vw;
    margin-top:  11.25vw;
  }
}
.AwardsNominees-item:nth-child(1),
.AwardsNominees-item:nth-child(2) {
  margin-top: 0;
}
@media (max-width: 759px) {
  .AwardsNominees-item:nth-child(1),
  .AwardsNominees-item:nth-child(2) {
    margin-top: 9.375vw;
  }
}
.AwardsNominees-item.active {
  opacity: 1;
  pointer-events: all;
}
.AwardsNominees-itemUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}
.AwardsNominees-itemImage {
  width: 100%;
  height: 0;
  padding-bottom: 55%;
  position: relative;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.25);
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (min-width: 1920px) {
  .AwardsNominees-itemImage {
    -webkit-box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
            box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-itemImage {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
            box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-itemImage {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
            box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-itemImage {
    -webkit-box-shadow:  0vw 3.125vw 6.25vw 0vw;
            box-shadow:  0vw 3.125vw 6.25vw 0vw;
  }
}
.AwardsNominees-itemImage img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .AwardsNominees-itemDescription {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-itemDescription {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-itemDescription {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-itemDescription {
    margin-top:  2.1875vw;
  }
}
.AwardsNominees-itemDescriptionDetails {
  line-height: 1.42857143;
}
@media (min-width: 1920px) {
  .AwardsNominees-itemDescriptionDetails {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-itemDescriptionDetails {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-itemDescriptionDetails {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-itemDescriptionDetails {
    margin-top:  0vw;
  }
}
.AwardsNominees-itemLabel {
  position: absolute;
  color: #111112;
  line-height: 1;
  text-transform: uppercase;
  z-index: 11;
}
@media (min-width: 1920px) {
  .AwardsNominees-itemLabel {
    padding:  13.333333333333332px;
    top:  26.666666666666664px;
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-itemLabel {
    padding:  0.6944444444444444vw;
    top:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-itemLabel {
    padding:  0.8064516129032258vw;
    top:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-itemLabel {
    padding:  1.875vw 3.125vw;
    top:  4.6875vw;
    left:  4.6875vw;
  }
}
.AwardsNominees-itemLabel:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  z-index: -1;
}
@media (min-width: 1920px) {
  .AwardsNominees-itemLabel:before {
    border-radius:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AwardsNominees-itemLabel:before {
    border-radius:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AwardsNominees-itemLabel:before {
    border-radius:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .AwardsNominees-itemLabel:before {
    border-radius:  6.25vw;
  }
}
