.SearchResultFaqItem {
  border-top: 1px solid #E7E7E7;
}
.SearchResultFaqItem:last-child {
  border-bottom: 1px solid #E7E7E7;
}
.SearchResultFaqItem-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 50vh;
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-content {
    padding:  106.66666666666666px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-content {
    padding:  5.555555555555555vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-content {
    padding:  6.451612903225806vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-content {
    padding:  25vw 43.75vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-content {
    display: none;
  }
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-contentTabs {
    width:  373.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-contentTabs {
    width:  19.444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-contentTabs {
    width:  22.58064516129032vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentTabs {
    width:  87.5vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-contentLinks {
    width:  880px;
    margin-left:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-contentLinks {
    width:  45.833333333333336vw;
    margin-left:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-contentLinks {
    width:  53.225806451612904vw;
    margin-left:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentLinks {
    width:  206.25vw;
    margin-left:  9.375vw;
  }
}
.SearchResultFaqItem-contentLink {
  color: #111112;
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  display: block;
  position: relative;
}
.SearchResultFaqItem-contentLink.hide {
  display: none;
}
.SearchResultFaqItem-contentLink:visited {
  color: #111112;
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-contentLink {
    padding:  26.666666666666664px 80px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-contentLink {
    padding:  1.3888888888888888vw 4.166666666666667vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-contentLink {
    padding:  1.6129032258064515vw 4.838709677419355vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentLink {
    padding:  6.25vw 18.75vw 6.25vw 0vw;
  }
}
.isDesktop .SearchResultFaqItem-contentLink:hover {
  text-decoration: underline;
}
.isDesktop .SearchResultFaqItem-contentLink:hover .SearchResultFaqItem-contentLinkArrow:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.SearchResultFaqItem-contentLinkArrow {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  top: 50%;
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-contentLinkArrow {
    margin-top:  -13.333333333333332px;
    right:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-contentLinkArrow {
    margin-top:  -0.6944444444444444vw;
    right:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-contentLinkArrow {
    margin-top:  -0.8064516129032258vw;
    right:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentLinkArrow {
    margin-top:  0vw;
    right:  2.5vw;
  }
}
.SearchResultFaqItem-contentLinkArrow:before {
  content: '';
  display: inline-block;
  position: relative;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
  z-index: 2;
  will-change: transform;
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .SearchResultFaqItem-contentLinkArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    width:  33.33333333333333px;
    height:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    width:  1.7361111111111112vw;
    height:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    width:  2.0161290322580645vw;
    height:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    width:  7.8125vw;
    height:  5vw;
  }
}
@media (max-width: 759px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    background-size: 8.125vw 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultFaqItem-contentLinkArrow:before {
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
