.RegionRibbon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #111112;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
}
@media (min-width: 1920px) {
  .RegionRibbon {
    padding:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon {
    padding:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon {
    padding:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon {
    padding:  10.9375vw 6.25vw 6.25vw;
  }
}
.RegionRibbon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .RegionRibbon-wrapper {
    width:  1280px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-wrapper {
    width:  66.66666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-wrapper {
    width:  77.41935483870968vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-wrapper {
    width:  300vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-wrapper {
    display: block;
  }
}
.RegionRibbon-form {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .RegionRibbon-form {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-form {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-form {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-form {
    margin-top:  6.25vw;
  }
}
.RegionRibbon-text {
  color: #A6A8B3;
}
@media (max-width: 759px) {
  .RegionRibbon-text {
    font-size: 2.8125vw;
  }
}
@media (min-width: 1920px) {
  .RegionRibbon-text.withForm {
    width:  746.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-text.withForm {
    width:  38.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-text.withForm {
    width:  45.16129032258064vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-text.withForm {
    width:  175vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-text.withForm {
    width: auto;
  }
}
.RegionRibbon-text a {
  color: #FFFFFF;
  text-decoration: underline;
}
.RegionRibbon-text a:visited {
  color: #FFFFFF;
}
.isDesktop .RegionRibbon-text a:hover {
  text-decoration: none;
}
@media (max-width: 759px) {
  .RegionRibbon .Dropdown {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 56.25vw;
            flex: 0 0 56.25vw;
  }
}
.RegionRibbon .Dropdown-selectModel {
  border: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .RegionRibbon .Dropdown-selectModel {
    padding-left:  21.333333333333332px;
    padding-top:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon .Dropdown-selectModel {
    padding-left:  1.1111111111111112vw;
    padding-top:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon .Dropdown-selectModel {
    padding-left:  1.2903225806451613vw;
    padding-top:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon .Dropdown-selectModel {
    padding-left:  5vw;
    padding-top:  0.625vw;
  }
}
.RegionRibbon .Dropdown-selectModel::after,
.RegionRibbon .Dropdown-selectModel::before {
  display: none !important;
}
.RegionRibbon .Dropdown-selectModel span {
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (min-width: 1920px) {
  .RegionRibbon .Dropdown-selectModel span {
    right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon .Dropdown-selectModel span {
    right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon .Dropdown-selectModel span {
    right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon .Dropdown-selectModel span {
    right:  3.125vw;
  }
}
.RegionRibbon-button {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  width: 100%;
  background-color: #FFFFFF;
  color: #111112;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .RegionRibbon-button {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-button {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-button {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .RegionRibbon-button {
    font-size: 16px;
  }
}
.RegionRibbon-button:visited {
  color: #111112;
}
.isDesktop .RegionRibbon-button:hover,
.isNotDesktop .RegionRibbon-button:active {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .RegionRibbon-button {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-button {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-button {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .RegionRibbon-button {
    height: 53.33333333px;
  }
}
@media (min-width: 1920px) {
  .RegionRibbon-button {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-button {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-button {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-button {
    margin-left:  0.625vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-button {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30.625vw;
            flex: 0 0 30.625vw;
  }
}
.RegionRibbon-close {
  position: absolute;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (min-width: 1920px) {
  .RegionRibbon-close {
    right:  21.333333333333332px;
    width:  34.666666666666664px;
    height:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-close {
    right:  1.1111111111111112vw;
    width:  1.8055555555555556vw;
    height:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-close {
    right:  1.2903225806451613vw;
    width:  2.096774193548387vw;
    height:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-close {
    right:  1.875vw;
    width:  8.125vw;
    height:  8.125vw;
    top:  2.5vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-close {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--1,
.isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
}
@media (min-width: 1920px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--1,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--1 {
    width:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--1,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--1 {
    width:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--1,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--1 {
    width:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--1,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--1 {
    width:  10.625vw;
  }
}
.isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--2,
.isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
}
@media (min-width: 1920px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--2,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--2 {
    width:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--2,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--2 {
    width:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--2,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--2 {
    width:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .RegionRibbon-close:hover .RegionRibbon-closeItem--2,
  .isMobile .RegionRibbon-close:active .RegionRibbon-closeItem--2 {
    width:  10.625vw;
  }
}
.RegionRibbon-closeItem {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .RegionRibbon-closeItem {
    width:  33.33333333333333px;
    height:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .RegionRibbon-closeItem {
    width:  1.7361111111111112vw;
    height:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .RegionRibbon-closeItem {
    width:  2.0161290322580645vw;
    height:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .RegionRibbon-closeItem {
    width:  6.25vw;
    height:  0.625vw;
  }
}
.RegionRibbon-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.RegionRibbon-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
