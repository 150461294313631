.CareersEmployeeExperience {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience {
    margin-top:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience {
    margin-top:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience {
    margin-top:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience {
    margin-top:  31.25vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-wrapper {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-wrapper {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-wrapper {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-wrapper {
    padding:  0vw 6.25vw;
  }
}
.CareersEmployeeExperience-titleBlock {
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-titleBlock {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-titleBlock {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-titleBlock {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-titleBlock {
    padding-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-titleBlock {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.CareersEmployeeExperience-title,
.CareersEmployeeExperience-description {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-title,
  .CareersEmployeeExperience-description {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-description {
    margin-top: 6.25vw;
  }
}
.CareersEmployeeExperience-videosWrapper {
  position: relative;
}
.CareersEmployeeExperience-videos {
  width: 100%;
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-videos {
    margin-top:  60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-videos {
    margin-top:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-videos {
    margin-top:  3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-videos {
    margin-top:  14.0625vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-videos {
    display: none;
  }
}
.CareersEmployeeExperience-videos_mobile {
  display: none;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-videos_mobile {
    display: block;
    margin-top: 7.8125vw;
  }
}
.CareersEmployeeExperience-videosInner_mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-videosInner_mobile {
    margin-left: -6.25vw;
    margin-right: -6.25vw;
  }
}
.CareersEmployeeExperience-video,
.CareersEmployeeExperience-video_mobile {
  width: 100%;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-video,
  .CareersEmployeeExperience-video_mobile {
    width: calc(100vw - 12.5vw);
  }
}
.CareersEmployeeExperience-video .Video,
.CareersEmployeeExperience-video_mobile .Video {
  margin-top: 0;
}
.CareersEmployeeExperience-video .Video-cover,
.CareersEmployeeExperience-video_mobile .Video-cover {
  width: 100%;
  height: 0;
  padding-bottom: 55%;
}
.CareersEmployeeExperience-video .Video-title,
.CareersEmployeeExperience-video_mobile .Video-title,
.CareersEmployeeExperience-video .Video-duration,
.CareersEmployeeExperience-video_mobile .Video-duration {
  color: #FFFFFF;
  padding-left: 0;
}
.CareersEmployeeExperience-video .Video-duration,
.CareersEmployeeExperience-video_mobile .Video-duration {
  line-height: 1;
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-video_mobile {
    margin-left:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-video_mobile {
    margin-left:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-video_mobile {
    margin-left:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-video_mobile {
    margin-left:  6.25vw;
    margin-right:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience .slick-slide {
    margin:  0 26.666666666666664px;
    width:  1013.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience .slick-slide {
    margin:  0vw 1.3888888888888888vw;
    width:  52.77777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience .slick-slide {
    margin:  0vw 1.6129032258064515vw;
    width:  61.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience .slick-slide {
    margin:  0vw 6.25vw;
    width:  237.5vw;
  }
}
.CareersEmployeeExperience-navLines {
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-navLines {
    bottom: -10.9375vw;
    width: 100vw;
  }
  .isDesktop .CareersEmployeeExperience-navLines {
    width: 96.25vw;
  }
}
.CareersEmployeeExperience-navLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-navLine {
    height: 9.375vw;
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-navLine {
    height: 2.41935484vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-navLine {
    height: 2.08333333vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-navLine {
    height: 40px;
    width: 77.33333333px;
  }
}
.isDesktop .CareersEmployeeExperience-navLine:hover:after,
.isNotDesktop .CareersEmployeeExperience-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .CareersEmployeeExperience-navLine:hover:after,
  .isNotDesktop .CareersEmployeeExperience-navLine:active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .CareersEmployeeExperience-navLine:hover:after,
  .isNotDesktop .CareersEmployeeExperience-navLine:active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .CareersEmployeeExperience-navLine:hover:after,
  .isNotDesktop .CareersEmployeeExperience-navLine:active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .CareersEmployeeExperience-navLine:hover:after,
  .isNotDesktop .CareersEmployeeExperience-navLine:active:after {
    height: 5.33333333px;
  }
}
.CareersEmployeeExperience-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.CareersEmployeeExperience-navLine.active {
  pointer-events: none;
}
.CareersEmployeeExperience-navLine.active:after {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-navLine.active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-navLine.active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-navLine.active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-navLine.active:after {
    height: 5.33333333px;
  }
}
.CareersEmployeeExperience-navLine:first-child {
  margin-left: 0;
}
.CareersEmployeeExperience-arrowLeft,
.CareersEmployeeExperience-arrowRight {
  background: center / cover url('/static/img/arrow-card-slider.svg') no-repeat;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  cursor: pointer;
  z-index: 4;
  position: absolute;
  top: 50%;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-arrowLeft,
  .CareersEmployeeExperience-arrowRight {
    height: 9.375vw;
    width: 6.5625vw;
    top: 70.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-arrowLeft,
  .CareersEmployeeExperience-arrowRight {
    width: 3.38709677vw;
    height: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-arrowLeft,
  .CareersEmployeeExperience-arrowRight {
    width: 2.91666667vw;
    height: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-arrowLeft,
  .CareersEmployeeExperience-arrowRight {
    width: 56px;
    height: 80px;
  }
}
.CareersEmployeeExperience-arrowLeft[data-tp="prev"],
.CareersEmployeeExperience-arrowRight[data-tp="prev"] {
  background: center / cover url('/static/img/arrow-card-slider-left.svg') no-repeat;
}
.isDesktop .CareersEmployeeExperience-arrowLeft:hover,
.isDesktop .CareersEmployeeExperience-arrowRight:hover,
.isNotDesktop .CareersEmployeeExperience-arrowLeft:active,
.isNotDesktop .CareersEmployeeExperience-arrowRight:active {
  opacity: 0.7;
}
.CareersEmployeeExperience-arrowLeft--hidden,
.CareersEmployeeExperience-arrowRight--hidden {
  opacity: 0 !important;
  cursor: default;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-arrowLeft,
  .CareersEmployeeExperience-arrowRight {
    display: none;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-arrowLeft {
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-arrowLeft {
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-arrowLeft {
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-arrowLeft {
    left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-arrowRight {
    right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-arrowRight {
    right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-arrowRight {
    right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-arrowRight {
    right:  6.25vw;
  }
}
.CareersEmployeeExperience-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  display: block;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .CareersEmployeeExperience-link:hover,
.isNotDesktop .CareersEmployeeExperience-link:active {
  text-decoration: underline;
}
.isDesktop .CareersEmployeeExperience-link:hover:before,
.isNotDesktop .CareersEmployeeExperience-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CareersEmployeeExperience-link:hover:before,
  .isNotDesktop .CareersEmployeeExperience-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-link {
    letter-spacing: 0;
  }
}
.CareersEmployeeExperience-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CareersEmployeeExperience-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CareersEmployeeExperience-link:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersEmployeeExperience-link {
    margin-top:  42.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersEmployeeExperience-link {
    margin-top:  2.2222222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersEmployeeExperience-link {
    margin-top:  2.5806451612903225vw;
  }
}
@media (max-width: 759px) {
  .CareersEmployeeExperience-link {
    margin-top:  10vw;
  }
}
