.AboutHead {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.AboutHead-arrow {
  -webkit-transform: translate3d(-50%, 50%, 0);
          transform: translate3d(-50%, 50%, 0);
  -webkit-animation: 3s arrow-jump-about infinite ease-in-out;
          animation: 3s arrow-jump-about infinite ease-in-out;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  position: absolute;
  cursor: pointer;
  left: 50%;
  will-change: transform;
  opacity: 0;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@-webkit-keyframes arrow-jump-about {
  0% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  10% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  20% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  30% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  40% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
}
@keyframes arrow-jump-about {
  0% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  10% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  20% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  30% {
    -webkit-transform: translate3d(-50%, 60%, 0);
            transform: translate3d(-50%, 60%, 0);
  }
  40% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
  }
}
.AboutHead-arrow.show {
  opacity: 1;
  -webkit-transform: translate3d(-50%, 50%, 0);
          transform: translate3d(-50%, 50%, 0);
}
.AboutHead-arrow.hide {
  opacity: 0;
  -webkit-transform: scale(0) transalte3d(0, 0, 0);
      -ms-transform: scale(0) transalte3d(0, 0, 0);
          transform: scale(0) transalte3d(0, 0, 0);
  visibility: hidden;
  -webkit-transition: 0.15s opacity, visibility 0.15s, 0.15s -webkit-transform;
  transition: 0.15s opacity, visibility 0.15s, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform, visibility 0.15s;
  transition: 0.15s opacity, 0.15s transform, visibility 0.15s, 0.15s -webkit-transform;
}
.AboutHead-arrow:hover {
  opacity: 0.7;
}
@media (max-width: 759px) {
  .AboutHead-arrow {
    display: none;
  }
}
@media (min-width: 1920px) {
  .AboutHead-arrow {
    width:  69.33333333333333px;
    height:  66.66666666666666px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutHead-arrow {
    width:  3.611111111111111vw;
    height:  3.4722222222222223vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutHead-arrow {
    width:  4.193548387096774vw;
    height:  4.032258064516129vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .AboutHead-arrow {
    width:  16.25vw;
    height:  15.625vw;
    bottom:  6.25vw;
  }
}
.AboutHead-overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 40%;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(20%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.7)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.7) 100%);
  opacity: 0.75;
  z-index: -1;
}
@media (min-width: 1920px) {
  .AboutHead {
    padding: 140px 186.66666667px 106.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutHead {
    padding: 7.29166667vw 9.72222222vw 5.55555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutHead {
    padding: 8.46774194vw 11.29032258vw 6.4516129vw;
  }
}
@media (max-width: 759px) {
  .AboutHead {
    padding: 23.4375vw 6.25vw 12.5vw;
  }
}
.AboutHead-title {
  color: #FFFFFF;
}
.AboutHead-facts {
  margin-top: auto;
}
