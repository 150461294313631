@media (max-width: 759px) {
  .CampusEventPopup .Popup-close {
    background-color: #111112;
  }
  .CampusEventPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.CampusEventPopup-content {
  background: #FFFFFF;
  width: 100%;
}
.CampusEventPopup-items {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .CampusEventPopup-items {
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusEventPopup-items {
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusEventPopup-items {
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .CampusEventPopup-items {
    padding-bottom:  12.5vw;
  }
}
.CampusEventPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
