@media (min-width: 1920px) {
  .CampusUpcomingEvents {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents {
    padding:  0vw 6.25vw;
  }
}
.CampusUpcomingEvents-titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-titleBlock {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-titleBlock {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-titleBlock {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-titleBlock {
    padding-top:  3.125vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-titleBlock {
    display: block;
  }
}
.isDark .CampusUpcomingEvents-titleBlock {
  border-top: 1px solid #DEDEDE;
}
.CampusUpcomingEvents-title {
  color: #FFFFFF;
}
.isDark .CampusUpcomingEvents-title {
  color: #111112;
}
.CampusUpcomingEvents-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-filters {
    display: block;
  }
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-filter {
    width:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-filter {
    width:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-filter {
    width:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-filter {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-filter {
    width: 100%;
  }
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-filter:first-child {
    margin-right:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-filter:first-child {
    margin-right:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-filter:first-child {
    margin-right:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-filter:first-child {
    margin-right:  12.5vw;
  }
}
.CampusUpcomingEvents-events {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-events {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-events {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-events {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-events {
    margin-top:  10.9375vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-events {
    display: block;
  }
}
@media (min-width: 1920px) {
  .isDark .CampusUpcomingEvents-events {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDark .CampusUpcomingEvents-events {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDark .CampusUpcomingEvents-events {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .isDark .CampusUpcomingEvents-events {
    margin-top:  6.25vw;
  }
}
.CampusUpcomingEvents-event {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
}
.CampusUpcomingEvents-event.active {
  opacity: 1;
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-event {
    width:  480px;
    min-height:  320px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-event {
    width:  25vw;
    min-height:  16.666666666666668vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-event {
    width:  23.387096774193548vw;
    min-height:  14.516129032258064vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-event {
    min-height:  62.5vw;
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-event {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.CampusUpcomingEvents-event:last-child:after {
  content: '';
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-event:nth-child(1),
  .CampusUpcomingEvents-event:nth-child(2),
  .CampusUpcomingEvents-event:nth-child(3) {
    margin-top:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-event:nth-child(1),
  .CampusUpcomingEvents-event:nth-child(2),
  .CampusUpcomingEvents-event:nth-child(3) {
    margin-top:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-event:nth-child(1),
  .CampusUpcomingEvents-event:nth-child(2),
  .CampusUpcomingEvents-event:nth-child(3) {
    margin-top:  0vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-event:nth-child(1),
  .CampusUpcomingEvents-event:nth-child(2),
  .CampusUpcomingEvents-event:nth-child(3) {
    margin-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .isDark .CampusUpcomingEvents-event {
    width:  480px;
    min-height:  320px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDark .CampusUpcomingEvents-event {
    width:  25vw;
    min-height:  16.666666666666668vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDark .CampusUpcomingEvents-event {
    width:  29.032258064516128vw;
    min-height:  19.35483870967742vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .isDark .CampusUpcomingEvents-event {
    min-height:  62.5vw;
    margin-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .isDark .CampusUpcomingEvents-event:nth-child(1),
  .isDark .CampusUpcomingEvents-event:nth-child(2) {
    margin-top:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDark .CampusUpcomingEvents-event:nth-child(1),
  .isDark .CampusUpcomingEvents-event:nth-child(2) {
    margin-top:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDark .CampusUpcomingEvents-event:nth-child(1),
  .isDark .CampusUpcomingEvents-event:nth-child(2) {
    margin-top:  0vw;
  }
}
@media (max-width: 759px) {
  .isDark .CampusUpcomingEvents-event:nth-child(1),
  .isDark .CampusUpcomingEvents-event:nth-child(2) {
    margin-top:  6.25vw;
  }
}
.CampusUpcomingEvents-showMore {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-showMore {
    width:  320px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-showMore {
    width:  16.666666666666668vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-showMore {
    width:  19.35483870967742vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-showMore {
    width:  50vw;
    margin-top:  6.25vw;
  }
}
.CampusUpcomingEvents-showMore button {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  width: 100%;
  background-color: #FFFFFF;
  color: #111112;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-showMore button {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-showMore button {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-showMore button {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-showMore button {
    font-size: 16px;
  }
}
.CampusUpcomingEvents-showMore button:visited {
  color: #111112;
}
.isDesktop .CampusUpcomingEvents-showMore button:hover,
.isNotDesktop .CampusUpcomingEvents-showMore button:active {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .CampusUpcomingEvents-showMore button {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusUpcomingEvents-showMore button {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusUpcomingEvents-showMore button {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .CampusUpcomingEvents-showMore button {
    height: 53.33333333px;
  }
}
.isDark .CampusUpcomingEvents-showMore button {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  width: 100%;
  background-color: #111112;
  color: #FFFFFF;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .isDark .CampusUpcomingEvents-showMore button {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDark .CampusUpcomingEvents-showMore button {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDark .CampusUpcomingEvents-showMore button {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .isDark .CampusUpcomingEvents-showMore button {
    font-size: 16px;
  }
}
.isDark .CampusUpcomingEvents-showMore button:visited {
  color: #FFFFFF;
}
.isDesktop .isDark .CampusUpcomingEvents-showMore button:hover,
.isNotDesktop .isDark .CampusUpcomingEvents-showMore button:active {
  background-color: transparent;
  color: #111112;
  border: 1px solid #111112;
}
@media (max-width: 759px) {
  .isDark .CampusUpcomingEvents-showMore button {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDark .CampusUpcomingEvents-showMore button {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDark .CampusUpcomingEvents-showMore button {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .isDark .CampusUpcomingEvents-showMore button {
    height: 53.33333333px;
  }
}
.isDark .CampusUpcomingEvents-showMore button.disabled {
  pointer-events: none;
  background-color: #A6A8B3;
  color: #FFFFFF;
  border: 1px solid #A6A8B3;
}
