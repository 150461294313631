.Page-popupBg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 24;
  background-color: rgba(21,25,40, .7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.HalfPagePopupShown .Page-popupBg {
  background-color: rgba(21,25,40, .5);
}
.Page-popupBg.show {
  display: block;
}
@media (max-width: 759px) {
  .Page-popupBg {
    background-color: #FFFFFF;
    opacity: 1;
  }
}
.Page-cookiesWarning {
  position: fixed;
  background-color: #111112;
  z-index: 1200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity 1s ease-out, 0s visibility 1s ease-out, 0.15s -webkit-transform 1s ease-out;
  transition: 0.15s opacity 1s ease-out, 0s visibility 1s ease-out, 0.15s -webkit-transform 1s ease-out;
  transition: 0.15s opacity 1s ease-out, 0.15s transform 1s ease-out, 0s visibility 1s ease-out;
  transition: 0.15s opacity 1s ease-out, 0.15s transform 1s ease-out, 0s visibility 1s ease-out, 0.15s -webkit-transform 1s ease-out;
}
@media (max-width: 759px) {
  .Page-cookiesWarning {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    left: 6.25vw;
    right: 6.25vw;
    bottom: 6.25vw;
    -webkit-box-shadow: 0 2.5vw 6.25vw 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0 2.5vw 6.25vw 0 rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Page-cookiesWarning {
    bottom: 1.61290323vw;
    left: 1.61290323vw;
    right: 1.61290323vw;
    -webkit-box-shadow: 0 0.64516129vw 1.61290323vw 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.64516129vw 1.61290323vw 0 rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Page-cookiesWarning {
    bottom: 1.38888889vw;
    left: 1.38888889vw;
    right: 1.38888889vw;
    -webkit-box-shadow: 0 0.55555556vw 1.38888889vw 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.55555556vw 1.38888889vw 0 rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 1920px) {
  .Page-cookiesWarning {
    bottom: 26.66666667px;
    left: 26.66666667px;
    right: 26.66666667px;
    -webkit-box-shadow: 0 10.66666667px 26.66666667px 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0 10.66666667px 26.66666667px 0 rgba(0, 0, 0, 0.5);
  }
}
.Page-cookiesWarning--isAccepted {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translate3d(0, 150%, 0);
          transform: translate3d(0, 150%, 0);
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0.15s transform ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0.15s transform ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
}
.Page-cookiesWarning--hide {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, 150%, 0);
          transform: translate3d(0, 150%, 0);
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0.15s transform ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0.15s transform ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
}
.Page-cookiesWarning span {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #A6A8B3;
}
@media (max-width: 759px) {
  .Page-cookiesWarning span {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Page-cookiesWarning span {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Page-cookiesWarning span {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Page-cookiesWarning span {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .Page-cookiesWarning span {
    padding: 7.8125vw 6.25vw 10vw;
    line-height: 1.7;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Page-cookiesWarning span {
    padding: 0 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Page-cookiesWarning span {
    padding: 0 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .Page-cookiesWarning span {
    padding: 0 26.66666667px;
  }
}
.Page-cookiesWarning span a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .Page-cookiesWarning span a {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Page-cookiesWarning span a {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Page-cookiesWarning span a {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Page-cookiesWarning span a {
    font-size: 16px;
  }
}
.isDesktop .Page-cookiesWarning span a:hover,
.isNotDesktop .Page-cookiesWarning span a:active {
  text-decoration: underline;
}
.Page-cookiesWarningAccept {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #111112;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: unset;
  border: 1px solid transparent;
  background: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .Page-cookiesWarningAccept {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Page-cookiesWarningAccept {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Page-cookiesWarningAccept {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Page-cookiesWarningAccept {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .Page-cookiesWarningAccept {
    margin: 0 auto 6.25vw;
    font-size: 3.75vw;
    height: 12.5vw;
    padding: 3.125vw 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Page-cookiesWarningAccept {
    height: 3.22580645vw;
    padding: 0.80645161vw 2.41935484vw;
    margin: 0.80645161vw 0.80645161vw 0.80645161vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Page-cookiesWarningAccept {
    height: 2.77777778vw;
    padding: 0.69444444vw 2.08333333vw;
    margin: 0.69444444vw 0.69444444vw 0.69444444vw 0;
  }
}
@media (min-width: 1920px) {
  .Page-cookiesWarningAccept {
    height: 53.33333333px;
    padding: 13.33333333px 40px;
    margin: 13.33333333px 13.33333333px 13.33333333px 0;
  }
}
.isDesktop .Page-cookiesWarningAccept {
  -webkit-transition: 0.15s color, 0.15s background, 0.15s border;
  transition: 0.15s color, 0.15s background, 0.15s border;
}
.isDesktop .Page-cookiesWarningAccept:hover,
.isNotDesktop .Page-cookiesWarningAccept:active {
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
}
