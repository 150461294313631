.ProductDetailsFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #111112;
}
.ProductDetailsFooter.light {
  background-color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductDetailsFooter {
    display: block;
    padding: 14.0625vw 6.25vw 19.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter {
    padding: 8.87096774vw 8.06451613vw 10.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter {
    padding: 7.63888889vw 6.94444444vw 9.02777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter {
    padding: 146.66666667px 160px 173.33333333px;
  }
}
.ProductDetailsFooter-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .ProductDetailsFooter-item:hover .ProductDetailsFooter-itemTitle,
.isNotDesktop .ProductDetailsFooter-item:active .ProductDetailsFooter-itemTitle {
  text-decoration: underline;
}
.isDesktop .ProductDetailsFooter-item:hover .ProductDetailsFooter-itemTitle:before,
.isNotDesktop .ProductDetailsFooter-item:active .ProductDetailsFooter-itemTitle:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .ProductDetailsFooter-item:hover .ProductDetailsFooter-itemTitle:before,
  .isNotDesktop .ProductDetailsFooter-item:active .ProductDetailsFooter-itemTitle:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (max-width: 759px) {
  .ProductDetailsFooter-item {
    margin-bottom: 12.5vw;
  }
  .ProductDetailsFooter-item:last-of-type {
    margin-bottom: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-item {
    padding: 0 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-item {
    padding: 0 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-item {
    padding: 0 26.66666667px;
  }
}
.ProductDetailsFooter-item:first-of-type {
  padding-left: 0;
}
.ProductDetailsFooter-item:last-of-type {
  padding-right: 0;
}
.ProductDetailsFooter-itemText {
  display: block;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemText {
    margin-left: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemText {
    margin-left: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemText {
    margin-left: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemText {
    margin-left: 53.33333333px;
  }
}
.ProductDetailsFooter-itemIcon {
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemIcon--contact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5vw;
            flex: 0 0 12.5vw;
    height: 16.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemIcon--contact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 5vw;
            flex: 0 0 5vw;
    height: 6.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemIcon--contact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 4.30555556vw;
            flex: 0 0 4.30555556vw;
    height: 5.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemIcon--contact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 82.66666667px;
            flex: 0 0 82.66666667px;
    height: 109.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemIcon--eshop {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5vw;
            flex: 0 0 12.5vw;
    height: 17.1875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemIcon--eshop {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 5.16129032vw;
            flex: 0 0 5.16129032vw;
    height: 6.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemIcon--eshop {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 4.44444444vw;
            flex: 0 0 4.44444444vw;
    height: 5.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemIcon--eshop {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 85.33333333px;
            flex: 0 0 85.33333333px;
    height: 112px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemIcon--faq {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5vw;
            flex: 0 0 12.5vw;
    height: 16.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemIcon--faq {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 4.83870968vw;
            flex: 0 0 4.83870968vw;
    height: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemIcon--faq {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 4.16666667vw;
            flex: 0 0 4.16666667vw;
    height: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemIcon--faq {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 80px;
            flex: 0 0 80px;
    height: 106.66666667px;
  }
}
.ProductDetailsFooter-itemIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
  fill: #FFFFFF;
  stroke: #FFFFFF;
  stroke-width: 0;
}
.light .ProductDetailsFooter-itemIcon svg {
  fill: #111112;
  stroke: #111112;
}
.ProductDetailsFooter-itemTitle {
  color: #FFFFFF;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductDetailsFooter-itemTitle:hover,
.isNotDesktop .ProductDetailsFooter-itemTitle:active {
  text-decoration: underline;
}
.isDesktop .ProductDetailsFooter-itemTitle:hover:before,
.isNotDesktop .ProductDetailsFooter-itemTitle:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductDetailsFooter-itemTitle:hover:before,
  .isNotDesktop .ProductDetailsFooter-itemTitle:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemTitle {
    letter-spacing: 0;
  }
}
.ProductDetailsFooter-itemTitle:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemTitle:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductDetailsFooter-itemTitle:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemTitle:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemTitle:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemTitle:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemTitle:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.light .ProductDetailsFooter-itemTitle {
  color: #111112;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: none;
  letter-spacing: 0;
}
@media (max-width: 759px) {
  .light .ProductDetailsFooter-itemTitle {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .light .ProductDetailsFooter-itemTitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .light .ProductDetailsFooter-itemTitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .light .ProductDetailsFooter-itemTitle {
    font-size: 18.66666667px;
  }
}
.isDesktop .light .ProductDetailsFooter-itemTitle:hover,
.isNotDesktop .light .ProductDetailsFooter-itemTitle:active {
  text-decoration: underline;
}
.isDesktop .light .ProductDetailsFooter-itemTitle:hover:before,
.isNotDesktop .light .ProductDetailsFooter-itemTitle:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .light .ProductDetailsFooter-itemTitle:hover:before,
  .isNotDesktop .light .ProductDetailsFooter-itemTitle:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .light .ProductDetailsFooter-itemTitle {
    letter-spacing: 0;
  }
}
.light .ProductDetailsFooter-itemTitle:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg');
}
@media (max-width: 759px) {
  .light .ProductDetailsFooter-itemTitle:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .light .ProductDetailsFooter-itemTitle:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .light .ProductDetailsFooter-itemTitle:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .light .ProductDetailsFooter-itemTitle:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .light .ProductDetailsFooter-itemTitle:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .light .ProductDetailsFooter-itemTitle:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .light .ProductDetailsFooter-itemTitle {
    font-size: 4.375vw;
    line-height: 1.71428571;
    letter-spacing: -0.047em;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 1.4516129vw !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 1.25vw !important;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 24px !important;
  }
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemTitle {
    font-size: 4.375vw;
    line-height: 1.71428571;
  }
}
.ProductDetailsFooter-itemDescription {
  color: #6F7380;
  text-transform: none;
  line-height: 1.3;
  letter-spacing: -0.0004em;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemDescription {
    margin-top: 0;
    font-size: 3.4375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemDescription {
    margin-top: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemDescription {
    margin-top: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemDescription {
    margin-top: 13.33333333px;
  }
}
.ProductDetailsFooter-itemArrow {
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsFooter-itemArrow {
    width: 7.8125vw;
    height: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsFooter-itemArrow {
    width: 2.01612903vw;
    height: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsFooter-itemArrow {
    width: 1.73611111vw;
    height: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsFooter-itemArrow {
    width: 33.33333333px;
    height: 21.33333333px;
  }
}
.ProductDetailsFooter-itemArrow svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
