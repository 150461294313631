@media (min-width: 1920px) {
  .companyCards .CardsSlider-objects {
    padding-left:  186.66666666666666px;
    margin-left:  -26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .companyCards .CardsSlider-objects {
    padding-left:  9.722222222222223vw;
    margin-left:  -1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .companyCards .CardsSlider-objects {
    padding-left:  11.29032258064516vw;
    margin-left:  -0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .companyCards .CardsSlider-objects {
    padding-left:  12.5vw;
    margin-left:  -3.125vw;
    margin-top:  31.25vw;
  }
}
.CardsSlider-objects.show .CardsSlider-object {
  -webkit-transition: 0.25s color ease, 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.25s color ease, 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.25s color ease, 0.5s transform, 0.5s opacity;
  transition: 0.25s color ease, 0.5s transform, 0.5s opacity, 0.5s -webkit-transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(20) {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.CardsSlider-objects.show .CardsSlider-object:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.CardsSlider-object.companyCards {
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .CardsSlider-object.companyCards:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .CardsSlider-object.companyCards-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .CardsSlider-object.companyCards:hover .CardsSlider-object.companyCards-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .CardsSlider-object.companyCards:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.CardsSlider-object.companyCards:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .CardsSlider-object.companyCards:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .CardsSlider-object.companyCards:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .CardsSlider-object.companyCards:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .CardsSlider-object.companyCards:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.CardsSlider-object.companyCards:active:after {
  opacity: 0.5;
}
.CardsSlider-object.companyCards:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .CardsSlider-object.companyCards:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CardsSlider-object.companyCards:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .CardsSlider-object.companyCards:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.CardsSlider-object.companyCards:active:after {
  display: none;
}
.isDesktop .CardsSlider-object.companyCards:hover .CompanyCards-objectImage img {
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
.CardsSlider-object.companyCards:before {
  left: 50%;
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards:before {
    bottom:  21.333333333333332px;
    margin-left:  -17.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards:before {
    bottom:  1.1111111111111112vw;
    margin-left:  -0.9027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards:before {
    bottom:  1.2903225806451613vw;
    margin-left:  -1.0483870967741935vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards:before {
    bottom:  5vw;
    margin-left:  -4.0625vw;
  }
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel {
  position: relative;
  margin: 0 auto;
  display: block;
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel svg,
.CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel {
    width:  186.66666666666666px;
    height:  34.666666666666664px;
    margin-top:  44px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel {
    width:  9.722222222222223vw;
    height:  1.8055555555555556vw;
    margin-top:  2.291666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel {
    width:  11.29032258064516vw;
    height:  2.096774193548387vw;
    margin-top:  2.661290322580645vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-zumtobel {
    width:  43.75vw;
    height:  8.125vw;
    margin-top:  10.3125vw;
  }
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn {
  position: relative;
  margin: 0 auto;
  display: block;
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn svg,
.CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn {
    width:  109.33333333333333px;
    height:  21.333333333333332px;
    margin-top:  60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn {
    width:  5.694444444444445vw;
    height:  1.1111111111111112vw;
    margin-top:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn {
    width:  6.612903225806451vw;
    height:  1.2903225806451613vw;
    margin-top:  3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn {
    width:  25.625vw;
    height:  5vw;
    margin-top:  14.0625vw;
  }
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc {
  position: relative;
  margin: 0 auto;
  display: block;
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc svg,
.CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc {
    width:  88px;
    height:  28px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc {
    width:  4.583333333333334vw;
    height:  1.4583333333333335vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc {
    width:  5.32258064516129vw;
    height:  1.6935483870967742vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-acdc {
    width:  20.625vw;
    height:  6.5625vw;
    margin-top:  12.5vw;
  }
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic {
  position: relative;
  margin: 0 auto;
  display: block;
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic svg,
.CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic {
    width:  133.33333333333331px;
    height:  21.333333333333332px;
    margin-top:  60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic {
    width:  6.944444444444445vw;
    height:  1.1111111111111112vw;
    margin-top:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic {
    width:  8.064516129032258vw;
    height:  1.2903225806451613vw;
    margin-top:  3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-tridonic {
    width:  31.25vw;
    height:  5vw;
    margin-top:  14.0625vw;
  }
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco {
  position: relative;
  margin: 0 auto;
  display: block;
}
.CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco svg,
.CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco {
    height:  21.333333333333332px;
    margin-top:  60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco {
    height:  1.1111111111111112vw;
    margin-top:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco {
    height:  1.2903225806451613vw;
    margin-top:  3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards .CompanyCards-objectLogo-thorn-eco {
    height:  5vw;
    margin-top:  14.0625vw;
  }
}
@media (min-width: 1920px) {
  .CardsSlider-object.companyCards {
    -ms-flex-preferred-size:  480px;
        flex-basis:  480px;
    margin:  0 26.666666666666664px;
    height:  720px;
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CardsSlider-object.companyCards {
    -ms-flex-preferred-size:  25vw;
        flex-basis:  25vw;
    margin:  0vw 1.3888888888888888vw;
    height:  37.5vw;
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CardsSlider-object.companyCards {
    -ms-flex-preferred-size:  24.596774193548388vw;
        flex-basis:  24.596774193548388vw;
    height:  36.854838709677416vw;
    margin:  0vw 0.8064516129032258vw;
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards {
    -ms-flex-preferred-size:  75vw;
        flex-basis:  75vw;
    height:  131.25vw;
    margin:  0vw 3.125vw;
    -webkit-box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
            box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
  }
}
@media (max-width: 759px) {
  .CardsSlider-object.companyCards {
    color: rgba(0, 0, 0, 0.25);
  }
  .isDesktop .CardsSlider-object.companyCards {
    width: 64.0625vw;
  }
}
.CompanyCards-objectImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  height: 100%;
  z-index: -1;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.CompanyCards-objectImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  will-change: transform;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.CompanyCards-objectText {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
@media (min-width: 1920px) {
  .CompanyCards-objectText {
    top:  520px;
    padding:  0 80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyCards-objectText {
    top:  27.083333333333336vw;
    padding:  0vw 4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyCards-objectText {
    bottom:  3.629032258064516vw;
    padding:  0vw 3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CompanyCards-objectText {
    bottom:  12.5vw;
    padding:  0vw 6.25vw;
  }
}
.CompanyCards-objectCategory {
  color: #FFFFFF;
  text-transform: uppercase;
  display: block;
}
@media (min-width: 1920px) {
  .CompanyCards-objectCategory {
    font-size:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyCards-objectCategory {
    font-size:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyCards-objectCategory {
    font-size:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .CompanyCards-objectCategory {
    font-size:  2.8125vw;
  }
}
.CompanyCards-objectDescription {
  letter-spacing: normal;
  color: #FFFFFF;
  text-align: center;
  width: 100%;
}
@media (min-width: 1920px) {
  .CompanyCards-objectDescription {
    line-height:  26.666666666666664px;
    margin-top:  26.666666666666664px;
    font-size:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyCards-objectDescription {
    line-height:  1.3888888888888888vw;
    margin-top:  1.3888888888888888vw;
    font-size:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyCards-objectDescription {
    line-height:  1.6129032258064515vw;
    margin-top:  1.2096774193548387vw;
    font-size:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .CompanyCards-objectDescription {
    line-height:  6.25vw;
    margin-top:  3.125vw;
    font-size:  3.4375vw;
  }
}
