.SearchResultProductItem {
  text-decoration: none;
  position: relative;
}
.SearchResultProductItem:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
@media (min-width: 1920px) {
  .SearchResultProductItem:not(:last-child) {
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem:not(:last-child) {
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem:not(:last-child) {
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem:not(:last-child) {
    margin-bottom:  10vw;
  }
}
.SearchResultProductItem-tip {
  color: #6F7380;
  text-transform: inherit;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-tip {
    margin-bottom:  44px;
    font-size:  18.666666666666664px;
    margin-top:  -6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-tip {
    margin-bottom:  2.291666666666667vw;
    font-size:  0.9722222222222223vw;
    margin-top:  -0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-tip {
    margin-bottom:  2.661290322580645vw;
    font-size:  1.129032258064516vw;
    margin-top:  -0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-tip {
    margin-bottom:  10.3125vw;
    font-size:  4.375vw;
    margin-top:  -1.5625vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-tip {
    padding: 0 6.25vw 6.25vw;
    margin-bottom: 3.125vw;
    font-size: 3.4375vw;
  }
}
.SearchResultProductItem-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-decoration: none;
  position: relative;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-link {
    height:  213.33333333333331px;
    margin-bottom:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-link {
    height:  11.11111111111111vw;
    margin-bottom:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-link {
    height:  12.903225806451612vw;
    margin-bottom:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-link {
    height:  50vw;
    margin-bottom:  5vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-link {
    height: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.isDesktop .SearchResultProductItem-link:hover .SearchResultProductItem-arrow:before,
.isNotDesktop .SearchResultProductItem-link:active .SearchResultProductItem-arrow:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .SearchResultProductItem-link:hover .SearchResultProductItem-arrow:before,
  .isNotDesktop .SearchResultProductItem-link:active .SearchResultProductItem-arrow:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.isDesktop .SearchResultProductItem-link:hover .SearchResultProductItem-image img,
.isNotDesktop .SearchResultProductItem-link:active .SearchResultProductItem-image img {
  -webkit-transform: scale(1.3) translate3d(0, 0, 0);
          transform: scale(1.3) translate3d(0, 0, 0);
}
.SearchResultProductItem-info {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
  display: block;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-info {
    width:  560px;
    padding-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-info {
    width:  29.166666666666668vw;
    padding-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-info {
    width:  33.87096774193548vw;
    padding-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-info {
    width:  57.1875vw;
  }
}
.SearchResultProductItem-image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.SearchResultProductItem-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-image {
    width:  213.33333333333331px;
    height:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-image {
    width:  11.11111111111111vw;
    height:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-image {
    width:  12.903225806451612vw;
    height:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-image {
    width:  25vw;
    height:  25vw;
  }
}
.SearchResultProductItem-title {
  color: #111112;
  display: block;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-title {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-title {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-title {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-title {
    margin-top:  1.875vw;
    font-size:  4.375vw;
  }
}
.SearchResultProductItem-description {
  color: #6F7380;
  display: block;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-description {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-description {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-description {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-description {
    margin-top:  1.5625vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-description {
    letter-spacing: -0.22px;
  }
}
.SearchResultProductItem-arrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  right: 0;
}
@media (max-width: 759px) {
  .SearchResultProductItem-arrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-arrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-arrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultProductItem-arrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .SearchResultProductItem-arrow:hover,
.isNotDesktop .SearchResultProductItem-arrow:active {
  text-decoration: underline;
}
.isDesktop .SearchResultProductItem-arrow:hover:before,
.isNotDesktop .SearchResultProductItem-arrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .SearchResultProductItem-arrow:hover:before,
  .isNotDesktop .SearchResultProductItem-arrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-arrow {
    letter-spacing: 0;
  }
}
.SearchResultProductItem-arrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .SearchResultProductItem-arrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .SearchResultProductItem-arrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .SearchResultProductItem-arrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-arrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-arrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultProductItem-arrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-arrow {
    margin-top: 1.25vw;
    right: -3.125vw;
    top: 0;
    line-height: normal;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-arrow {
    margin-left: auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-arrow {
    margin-left: auto;
  }
}
@media (min-width: 1920px) {
  .SearchResultProductItem-arrow {
    margin-left: auto;
  }
}
.SearchResultProductItem-productsWrapper {
  width: 100%;
  border-bottom: 1px solid rgba(198, 198, 198, 0.8);
}
@media (min-width: 1920px) {
  .SearchResultProductItem-productsWrapper {
    margin-bottom:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-productsWrapper {
    margin-bottom:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-productsWrapper {
    margin-bottom:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productsWrapper {
    margin-bottom:  9.375vw;
  }
}
.SearchResultProductItem-products,
.SearchResultProductItem-products * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.SearchResultProductItem-products {
  overflow: hidden;
  border-radius: 3px;
  background: #FFFFFF;
}
.SearchResultProductItem-productIcon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-productIcon {
    width:  26.666666666666664px;
    height:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-productIcon {
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-productIcon {
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productIcon {
    width:  9.375vw;
    height:  9.375vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productIcon {
    margin: auto 0;
  }
}
.SearchResultProductItem-productIconItem {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  top: 50%;
  height: 2px;
  background: #111112;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out, 0.15s -webkit-transform ease-out;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-productIconItem {
    width:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-productIconItem {
    width:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-productIconItem {
    width:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productIconItem {
    width:  6.25vw;
  }
}
.SearchResultProductItem-productIconItem_1 {
  -webkit-transform: translate(-50%) rotate(90deg);
      -ms-transform: translate(-50%) rotate(90deg);
          transform: translate(-50%) rotate(90deg);
}
.SearchResultProductItem-productTitle {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid rgba(198, 198, 198, 0.8);
  -webkit-transition: all linear 0.15s;
  transition: all linear 0.15s;
  opacity: 1;
  color: #111112;
  text-decoration: none;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-productTitle {
    padding:  26px 0px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-productTitle {
    padding:  1.3541666666666667vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-productTitle {
    padding:  1.5725806451612903vw 0vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productTitle {
    padding:  2.8125vw 0vw;
  }
}
.isDesktop .SearchResultProductItem-productTitle:hover,
.isNotDesktop .SearchResultProductItem-productTitle:active {
  opacity: 0.8;
  text-decoration: none;
}
@media (min-width: 1920px) {
  .isDesktop .SearchResultProductItem-productTitle:hover .SearchResultProductItem-productIconItem,
  .isNotDesktop .SearchResultProductItem-productTitle:active .SearchResultProductItem-productIconItem {
    width:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .SearchResultProductItem-productTitle:hover .SearchResultProductItem-productIconItem,
  .isNotDesktop .SearchResultProductItem-productTitle:active .SearchResultProductItem-productIconItem {
    width:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .SearchResultProductItem-productTitle:hover .SearchResultProductItem-productIconItem,
  .isNotDesktop .SearchResultProductItem-productTitle:active .SearchResultProductItem-productIconItem {
    width:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .SearchResultProductItem-productTitle:hover .SearchResultProductItem-productIconItem,
  .isNotDesktop .SearchResultProductItem-productTitle:active .SearchResultProductItem-productIconItem {
    width:  6.875vw;
  }
}
.isDesktop .SearchResultProductItem-productTitle:hover:before,
.isNotDesktop .SearchResultProductItem-productTitle:active:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  background: #111112;
}
.SearchResultProductItem-productTitle.active .SearchResultProductItem-productIconItem:first-child {
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
}
.isDesktop .SearchResultProductItem-productTitle.active:hover:before,
.isNotDesktop .SearchResultProductItem-productTitle.active:active:before {
  content: unset;
}
.SearchResultProductItem-productTitle--text {
  text-transform: none;
  letter-spacing: -0.42px;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-productTitle--text {
    width:  640px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-productTitle--text {
    width:  33.333333333333336vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-productTitle--text {
    width:  38.70967741935484vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productTitle--text {
    width:  150vw;
    margin-right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-productTitle--text {
    width: 100%;
    margin-right: 6.25vw;
    line-height: 6.25vw;
  }
}
.SearchResultProductItem-productTitle--count {
  text-transform: none;
  color: #6F7380;
}
@media (max-width: 759px) {
  .SearchResultProductItem-productTitle--count {
    display: none;
  }
}
.SearchResultProductItem-productTitleWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isDesktop .SearchResultProductItem-productTitle:hover,
.isNotDesktop .SearchResultProductItem-productTitle:active {
  opacity: 0.8;
  text-decoration: none;
}
.SearchResultProductItem-optionId {
  color: #6F7380;
  display: block;
}
.SearchResultProductItem-optionId:not(:last-child) {
  border-bottom: 1px solid rgba(198, 198, 198, 0.8);
}
@media (min-width: 1920px) {
  .SearchResultProductItem-optionId {
    margin-bottom:  13.333333333333332px;
    padding-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-optionId {
    margin-bottom:  0.6944444444444444vw;
    padding-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-optionId {
    margin-bottom:  0.8064516129032258vw;
    padding-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-optionId {
    margin-bottom:  3.125vw;
    padding-bottom:  3.125vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-optionId {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 4.0625vw;
  }
  .SearchResultProductItem-optionId:not(:last-child) {
    padding-bottom: 3.125vw;
  }
}
.SearchResultProductItem-optionId:before {
  content: '';
  display: block;
  position: absolute;
  background: url('/static/img/arrow-dark.svg') no-repeat;
  opacity: 0;
  right: 0;
  z-index: 2;
}
.isDesktop .SearchResultProductItem-optionId:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-optionId:before {
    width:  34.666666666666664px;
    height:  24px;
    right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-optionId:before {
    width:  1.8055555555555556vw;
    height:  1.25vw;
    right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-optionId:before {
    width:  2.096774193548387vw;
    height:  1.4516129032258065vw;
    right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-optionId:before {
    width:  8.125vw;
    height:  5.625vw;
    right:  3.125vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-optionId:before {
    opacity: 1;
    width: 3.4375vw;
    height: 3.75vw;
    right: -3.4375vw;
    top: 0.625vw;
    -webkit-transform: translateX(-2.5vw);
        -ms-transform: translateX(-2.5vw);
            transform: translateX(-2.5vw);
    background: url('/static/svg/arrow-dark.svg') no-repeat;
    background-size: contain;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-optionId:before {
    -webkit-transform: translateX(-0.64516129vw);
        -ms-transform: translateX(-0.64516129vw);
            transform: translateX(-0.64516129vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-optionId:before {
    -webkit-transform: translateX(-0.55555556vw);
        -ms-transform: translateX(-0.55555556vw);
            transform: translateX(-0.55555556vw);
  }
}
@media (min-width: 1920px) {
  .SearchResultProductItem-optionId:before {
    -webkit-transform: translateX(-10.66666667px);
        -ms-transform: translateX(-10.66666667px);
            transform: translateX(-10.66666667px);
  }
}
.isDesktop .SearchResultProductItem-optionId:hover,
.isNotDesktop .SearchResultProductItem-optionId:active {
  cursor: pointer;
  color: #111112;
}
.isDesktop .SearchResultProductItem-optionId:hover .SearchResultProductItem-optionName,
.isNotDesktop .SearchResultProductItem-optionId:active .SearchResultProductItem-optionName {
  color: #111112;
}
.isDesktop .SearchResultProductItem-optionId:hover:before,
.isNotDesktop .SearchResultProductItem-optionId:active:before {
  opacity: 1;
}
.isDesktop .SearchResultProductItem-optionId:hover:not(:last-child),
.isNotDesktop .SearchResultProductItem-optionId:active:not(:last-child) {
  border-bottom: 1px solid #111112;
}
.SearchResultProductItem-optionName {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-optionName {
    margin-left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-optionName {
    margin-left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-optionName {
    margin-left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-optionName {
    margin-left:  6.25vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-optionName {
    display: block;
    width: 62.5vw;
    margin-left: 3.125vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.SearchResultProductItem-extraResultsSection:last-child-extraResultsSectionTitle {
  border-bottom: none;
}
.SearchResultProductItem-options {
  display: none;
}
@media (min-width: 1920px) {
  .SearchResultProductItem-options {
    margin-left:  80px;
    padding-bottom:  26.666666666666664px;
    padding-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultProductItem-options {
    margin-left:  4.166666666666667vw;
    padding-bottom:  1.3888888888888888vw;
    padding-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultProductItem-options {
    margin-left:  4.838709677419355vw;
    padding-bottom:  1.6129032258064515vw;
    padding-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultProductItem-options {
    padding-top:  2.8125vw;
    padding-bottom:  6.25vw;
  }
}
.SearchResultProductItem-options:last-child {
  padding-bottom: 0;
}
