.ContactQualified {
  width: 100%;
  height: 100%;
}
.ContactQualified .Dropdown-selectOptions {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 120%;
}
.ContactQualified .Dropdown-selectOption {
  white-space: nowrap;
  width: 100%;
}
.ContactQualified-headWrapper {
  height: 100%;
}
@media (max-width: 759px) {
  .ContactQualified-headWrapper {
    padding-bottom: 9.375vw;
    background: #F6F6F6;
  }
}
.ContactQualified-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactQualified-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-mobileText {
    width: 46.875vw;
    line-height: 6.25vw;
  }
}
.ContactQualified-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactQualified-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    top: 7.5vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactQualified-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactQualified-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ContactQualified-filters {
    display: block;
    padding: 0 6.25vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-filterItem {
    width:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-filterItem {
    width:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-filterItem {
    width:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-filterItem {
    width:  50vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-filterItem {
    width: 100%;
    margin-top: 6.25vw;
  }
}
.ContactQualified-filterItem input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #111112;
  outline: none;
  background: transparent;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .ContactQualified-filterItem input {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-filterItem input {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-filterItem input {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-filterItem input {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-filterItem input {
    padding-bottom:  12px;
    margin-top:  8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-filterItem input {
    padding-bottom:  0.625vw;
    margin-top:  0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-filterItem input {
    padding-bottom:  0.7258064516129032vw;
    margin-top:  0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-filterItem input {
    padding-bottom:  2.8125vw;
    margin-top:  1.875vw;
  }
}
.ContactQualified-filterItemTitle {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .ContactQualified-filterItemTitle {
    margin-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-filterItemTitle {
    margin-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-filterItemTitle {
    margin-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-filterItemTitle {
    margin-bottom:  0vw;
  }
}
.ContactQualified-clearFilters {
  position: absolute;
  cursor: pointer;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.ContactQualified-clearFilters:hover {
  opacity: 0.7;
}
.ContactQualified-clearFilters.hide {
  display: none;
}
@media (min-width: 1920px) {
  .ContactQualified-clearFilters {
    right:  -53.33333333333333px;
    padding-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-clearFilters {
    right:  -2.7777777777777777vw;
    padding-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-clearFilters {
    right:  -3.225806451612903vw;
    padding-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-clearFilters {
    right:  -12.5vw;
    padding-bottom:  3.125vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-mainWrapper {
    padding: 0 6.25vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-infoWrapper {
    margin-top: 9.375vw;
    padding-bottom: 25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-infoWrapper {
    margin-bottom: 10.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-infoWrapper {
    margin-bottom: 9.02777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-infoWrapper {
    margin-bottom: 173.33333333px;
  }
}
.ContactQualified-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .ContactQualified-info {
    display: block;
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-info {
    margin-top: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-info {
    margin-top: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-info {
    margin-top: 66.66666667px;
  }
}
.ContactQualified-info:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .ContactQualified-info:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-info:not(:last-child):after {
    bottom: -2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-info:not(:last-child):after {
    bottom: -1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-info:not(:last-child):after {
    bottom: -33.33333333px;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-address {
    -ms-flex-preferred-size:  346.66666666666663px;
        flex-basis:  346.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-address {
    -ms-flex-preferred-size:  18.055555555555557vw;
        flex-basis:  18.055555555555557vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-address {
    -ms-flex-preferred-size:  20.967741935483872vw;
        flex-basis:  20.967741935483872vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-address {
    -ms-flex-preferred-size:  81.25vw;
        flex-basis:  81.25vw;
  }
}
.ContactQualified-address span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactQualified-address span {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-contacts {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-contacts {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-contacts {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-contacts {
    margin-left:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ContactQualified-contacts {
    padding-top: 7.8125vw;
    padding-bottom: 9.375vw;
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.ContactQualified-contacts span {
  display: block;
  color: #6F7380;
}
.ContactQualified-contacts a {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .ContactQualified-contacts a {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.isDesktop .ContactQualified-contacts a:hover,
.isNotDesktop .ContactQualified-contacts a:active {
  text-decoration: none;
}
.ContactQualified-icon {
  height: auto;
  width: auto;
  margin-left: auto;
  background: url('/static/svg/geo-icon.svg') no-repeat;
}
@media (max-width: 759px) {
  .ContactQualified-icon {
    width: 6.5625vw;
    height: 10vw;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-icon {
    width: 1.85483871vw;
    height: 2.90322581vw;
    margin-right: 1.61290323vw;
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-icon {
    width: 1.59722222vw;
    height: 2.5vw;
    margin-right: 1.38888889vw;
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-icon {
    width: 30.66666667px;
    height: 48px;
    margin-right: 26.66666667px;
    margin-top: 20px;
  }
}
@media (max-width: 759px) {
  .ContactQualified-icon svg {
    width: 6.5625vw;
    height: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-icon svg {
    width: 1.85483871vw;
    height: 2.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-icon svg {
    width: 1.59722222vw;
    height: 2.5vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-icon svg {
    width: 30.66666667px;
    height: 48px;
  }
}
.ContactQualified-icon span {
  display: none;
}
@media (max-width: 759px) {
  .ContactQualified-icon span {
    display: block;
    height: 100%;
    margin-left: 9.6875vw;
    line-height: 9.375vw;
  }
}
.isDesktop .ContactQualified-icon:hover,
.isNotDesktop .ContactQualified-icon:active {
  background: url('/static/svg/geo-icon-dark.svg') no-repeat;
  -webkit-transition: background 0.15s;
  transition: background 0.15s;
  cursor: pointer;
}
.ContactQualified-cardsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ContactQualified-cardsWrapper {
    display: block;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardsWrapper {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardsWrapper {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardsWrapper {
    margin-top: 53.33333333px;
  }
}
.ContactQualified-cardLink {
  background: #111112;
  color: #FFFFFF;
  position: relative;
}
@media (max-width: 759px) {
  .ContactQualified-cardLink {
    width: 100%;
    height: 50vw;
    padding: 6.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardLink {
    width: 20.96774194vw;
    height: 19.35483871vw;
    padding: 1.69354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardLink {
    width: 18.05555556vw;
    height: 16.66666667vw;
    padding: 1.45833333vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardLink {
    width: 346.66666667px;
    height: 320px;
    padding: 28px;
  }
}
.isDesktop .ContactQualified-cardLink:hover,
.isNotDesktop .ContactQualified-cardLink:active {
  cursor: pointer;
}
.ContactQualified-cardLink:before {
  background: none;
}
.ContactQualified-cardLink-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  position: absolute;
}
@media (max-width: 759px) {
  .ContactQualified-cardLink-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardLink-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardLink-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardLink-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .ContactQualified-cardLink-link:hover,
.isNotDesktop .ContactQualified-cardLink-link:active {
  text-decoration: underline;
}
.isDesktop .ContactQualified-cardLink-link:hover:before,
.isNotDesktop .ContactQualified-cardLink-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ContactQualified-cardLink-link:hover:before,
  .isNotDesktop .ContactQualified-cardLink-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ContactQualified-cardLink-link {
    letter-spacing: 0;
  }
}
.ContactQualified-cardLink-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .ContactQualified-cardLink-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ContactQualified-cardLink-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ContactQualified-cardLink-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardLink-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardLink-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardLink-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .ContactQualified-cardLink-link {
    margin-top: 6.25vw;
    bottom: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardLink-link {
    margin-top: 3.22580645vw;
    bottom: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardLink-link {
    margin-top: 2.77777778vw;
    bottom: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardLink-link {
    margin-top: 53.33333333px;
    bottom: 46.66666667px;
  }
}
.ContactQualified-cardContact {
  background: #111112;
  color: #FFFFFF;
  position: relative;
}
@media (max-width: 759px) {
  .ContactQualified-cardContact {
    width: 100%;
    height: 50vw;
    margin-top: 6.25vw;
    padding: 6.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardContact {
    width: 20.96774194vw;
    height: 19.35483871vw;
    padding: 1.69354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardContact {
    width: 18.05555556vw;
    height: 16.66666667vw;
    padding: 1.45833333vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardContact {
    width: 346.66666667px;
    height: 320px;
    padding: 28px;
  }
}
.isDesktop .ContactQualified-cardContact:hover,
.isNotDesktop .ContactQualified-cardContact:active {
  cursor: pointer;
}
.ContactQualified-cardContact:before {
  background: none;
}
.ContactQualified-cardContact-contact {
  position: absolute;
}
@media (max-width: 759px) {
  .ContactQualified-cardContact-contact {
    margin-top: 6.25vw;
    bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-cardContact-contact {
    margin-top: 3.22580645vw;
    bottom: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-cardContact-contact {
    margin-top: 2.77777778vw;
    bottom: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-cardContact-contact {
    margin-top: 53.33333333px;
    bottom: 46.66666667px;
  }
}
.ContactQualified-infoPosition {
  color: #6F7380;
}
.ContactQualified-line {
  font-weight: 600;
}
@media (max-width: 759px) {
  .ContactQualified-line {
    line-height: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-line {
    line-height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-line {
    line-height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-line {
    line-height: 26.66666667px;
  }
}
.ContactQualified-teamTitle {
  border-top: 1px solid #111112;
}
@media (max-width: 759px) {
  .ContactQualified-teamTitle {
    margin-top: 9.375vw;
    padding-top: 6.875vw;
    padding-bottom: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-teamTitle {
    margin-top: 8.06451613vw;
    padding-top: 2.58064516vw;
    padding-bottom: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-teamTitle {
    margin-top: 6.94444444vw;
    padding-top: 2.22222222vw;
    padding-bottom: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-teamTitle {
    margin-top: 133.33333333px;
    padding-top: 42.66666667px;
    padding-bottom: 42.66666667px;
  }
}
.ContactQualified-teamWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 759px) {
  .ContactQualified-teamWrapper {
    display: block;
  }
}
@media (max-width: 759px) {
  .ContactQualified-teamMember {
    width: 100%;
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-teamMember {
    width: 20.96774194vw;
    margin-bottom: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-teamMember {
    width: 18.05555556vw;
    margin-bottom: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-teamMember {
    width: 346.66666667px;
    margin-bottom: 80px;
  }
}
.ContactQualified-teamMember a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactQualified-teamMember a:hover,
.isNotDesktop .ContactQualified-teamMember a:active {
  text-decoration: none;
}
.ContactQualified-teamName {
  font-weight: 600;
}
@media (max-width: 759px) {
  .ContactQualified-teamName {
    margin-top: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactQualified-teamName {
    margin-top: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactQualified-teamName {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactQualified-teamName {
    margin-top: 26.66666667px;
  }
}
.ContactQualified-teamPosition,
.ContactQualified-teamPhone {
  color: #6F7380;
}
