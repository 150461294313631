.ContactHeadquarters {
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .ContactHeadquarters-image {
    padding: 0 6.25vw;
    margin-top: 6.25vw;
  }
}
.ContactHeadquarters-image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.ContactHeadquarters-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactHeadquarters-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactHeadquarters-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactHeadquarters-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactHeadquarters-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactHeadquarters-infoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ContactHeadquarters-infoWrapper {
    display: block;
    padding: 0 6.25vw 18.75vw 6.25vw;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactHeadquarters-infoWrapper {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactHeadquarters-infoWrapper {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactHeadquarters-infoWrapper {
    margin-top: 53.33333333px;
  }
}
.ContactHeadquarters-address span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactHeadquarters-address span {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
@media (max-width: 759px) {
  .ContactHeadquarters-contacts {
    margin-top: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactHeadquarters-contacts {
    margin-left: 6.0483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactHeadquarters-contacts {
    margin-left: 5.20833333vw;
  }
}
@media (min-width: 1920px) {
  .ContactHeadquarters-contacts {
    margin-left: 100px;
  }
}
.ContactHeadquarters-contacts span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactHeadquarters-contacts span {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.ContactHeadquarters-contacts a {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .ContactHeadquarters-contacts a {
    font-size: 4.375vw;
    line-height: 6.25vw;
  }
}
.isDesktop .ContactHeadquarters-contacts a:hover,
.isNotDesktop .ContactHeadquarters-contacts a:active {
  text-decoration: none;
}
.ContactHeadquarters-contacts a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ContactHeadquarters-icon {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: url('/static/svg/geo-icon.svg') no-repeat;
}
@media (max-width: 759px) {
  .ContactHeadquarters-icon {
    margin-right: 6.25vw;
    margin-top: 9.375vw;
    height: 10vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactHeadquarters-icon {
    width: 1.85483871vw;
    height: 2.90322581vw;
    margin-right: 1.61290323vw;
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactHeadquarters-icon {
    width: 1.59722222vw;
    height: 2.5vw;
    margin-right: 1.38888889vw;
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .ContactHeadquarters-icon {
    width: 30.66666667px;
    height: 48px;
    margin-right: 26.66666667px;
    margin-top: 20px;
  }
}
@media (max-width: 759px) {
  .ContactHeadquarters-icon svg {
    width: 6.5625vw;
    height: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactHeadquarters-icon svg {
    width: 1.85483871vw;
    height: 2.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactHeadquarters-icon svg {
    width: 1.59722222vw;
    height: 2.5vw;
  }
}
@media (min-width: 1920px) {
  .ContactHeadquarters-icon svg {
    width: 30.66666667px;
    height: 48px;
  }
}
.ContactHeadquarters-icon span {
  display: none;
}
@media (max-width: 759px) {
  .ContactHeadquarters-icon span {
    display: block;
    height: 100%;
    margin-left: 9.6875vw;
    line-height: 9.375vw;
  }
}
.isDesktop .ContactHeadquarters-icon:hover,
.isNotDesktop .ContactHeadquarters-icon:active {
  background: url('/static/svg/geo-icon-dark.svg') no-repeat;
  -webkit-transition: background 0.15s;
  transition: background 0.15s;
  cursor: pointer;
}
