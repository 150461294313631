@media (max-width: 759px) {
  .ProductFamilyDesigner {
    margin-top: 9.375vw;
    padding-bottom: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDesigner {
    margin-top: 2.01612903vw;
    padding-bottom: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDesigner {
    margin-top: 1.73611111vw;
    padding-bottom: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDesigner {
    margin-top: 33.33333333px;
    padding-bottom: 186.66666667px;
  }
}
.ProductFamilyDesigner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.ProductFamilyDesigner-titleBlock {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          flex: 0 0 35%;
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-titleBlock {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
}
.ProductFamilyDesigner-title {
  color: #111112;
}
.ProductFamilyDesigner-award {
  position: absolute;
  bottom: 0;
}
@media (min-width: 1920px) {
  .ProductFamilyDesigner-award {
    height:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDesigner-award {
    height:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDesigner-award {
    height:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-award {
    height:  37.5vw;
  }
}
.ProductFamilyDesigner-award img {
  height: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-award {
    display: none;
  }
}
.ProductFamilyDesigner-descriptionBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 65%;
          flex: 0 0 65%;
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-descriptionBlock {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-descriptionImage {
    margin-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDesigner-descriptionImage {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 47%;
            flex: 0 0 47%;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDesigner-descriptionImage {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 47%;
            flex: 0 0 47%;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDesigner-descriptionImage {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 47%;
            flex: 0 0 47%;
  }
}
.ProductFamilyDesigner-descriptionImage img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
.ProductFamilyDesigner-descriptionText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-descriptionText {
    margin-top: 6.25vw;
  }
}
.ProductFamilyDesigner-descriptionText a,
.ProductFamilyDesigner-descriptionText a:visited {
  color: #111112;
}
.ProductFamilyDesigner-descriptionTextName {
  color: #111112;
}
.ProductFamilyDesigner-descriptionTextPosition {
  color: #6F7380;
  text-transform: uppercase;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDesigner-descriptionTextPosition {
    margin-top: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDesigner-descriptionTextPosition {
    margin-top: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyDesigner-descriptionTextPosition {
    margin-top: 6.66666667px;
  }
}
.ProductFamilyDesigner-descriptionTextAbout {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .ProductFamilyDesigner-descriptionTextAbout {
    margin-top:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyDesigner-descriptionTextAbout {
    margin-top:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyDesigner-descriptionTextAbout {
    margin-top:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-descriptionTextAbout {
    margin-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyDesigner-descriptionTextAbout {
    font-size: 4.375vw;
  }
}
.ProductFamilyDesigner-descriptionTextFlexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
