.InspirationCard {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
}
@media (min-width: 1920px) {
  .InspirationCard {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .InspirationCard {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .InspirationCard:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .InspirationCard-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .InspirationCard:hover .InspirationCard-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .InspirationCard:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.InspirationCard:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .InspirationCard:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .InspirationCard:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .InspirationCard:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .InspirationCard:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.InspirationCard:active:after {
  opacity: 0.5;
}
.InspirationCard:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .InspirationCard:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .InspirationCard:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InspirationCard:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.InspirationCard:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .InspirationCard:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .InspirationCard {
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard {
    margin-bottom: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard {
    margin-bottom: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCard {
    margin-bottom: 26.66666667px;
  }
}
.InspirationCard a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.InspirationCard a:visited {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .InspirationCard:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCard:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
.InspirationCard:last-of-type {
  margin-bottom: 0;
}
.InspirationCard-image {
  width: 100%;
  padding-bottom: 56%;
  position: relative;
  background: #000000;
  display: block;
  cursor: pointer;
  overflow: hidden;
}
.InspirationCard-image:after {
  content: '';
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  left: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}
@media (min-width: 1920px) {
  .InspirationCard-image:after {
    height:  120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard-image:after {
    height:  6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard-image:after {
    height:  7.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .InspirationCard-image:after {
    height:  28.125vw;
  }
}
.isDesktop .InspirationCard-image:hover img,
.isNotDesktop .InspirationCard-image:active img {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.InspirationCard-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  position: absolute;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s -webkit-transform linear;
  transition: 0.15s -webkit-transform linear;
  transition: 0.15s transform linear;
  transition: 0.15s transform linear, 0.15s -webkit-transform linear;
  height: 100%;
  width: 100%;
}
.InspirationCard-text {
  position: absolute;
  pointer-events: none;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  display: block;
}
@media (max-width: 759px) {
  .InspirationCard-text {
    bottom: 6.25vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard-text {
    bottom: 1.61290323vw;
    left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard-text {
    bottom: 1.38888889vw;
    left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCard-text {
    bottom: 26.66666667px;
    left: 26.66666667px;
  }
}
.InspirationCard-title {
  color: #FFFFFF;
  letter-spacing: normal;
  pointer-events: none;
  text-transform: none;
  display: block;
}
@media (max-width: 759px) {
  .InspirationCard-title {
    font-size: 3.4375vw;
  }
}
.InspirationCard-category {
  color: #FFFFFF;
  letter-spacing: normal;
  text-transform: none;
  pointer-events: none;
}
@media (max-width: 759px) {
  .InspirationCard-category {
    font-size: 2.8125vw;
    line-height: normal;
    margin-top: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCard-category {
    margin-top: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCard-category {
    margin-top: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCard-category {
    margin-top: 6.66666667px;
  }
}
