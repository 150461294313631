.DropdownButton {
  position: relative;
}
.DropdownButton.active .DropdownButton-button:after {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.DropdownButton.active .DropdownButton-options {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
}
.DropdownButton-button {
  border: 1px solid transparent;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}
@media (min-width: 1920px) {
  .DropdownButton-button {
    margin-top:  53.33333333333333px;
    width:  320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DropdownButton-button {
    margin-top:  2.7777777777777777vw;
    width:  16.666666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DropdownButton-button {
    margin-top:  3.225806451612903vw;
    width:  19.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .DropdownButton-button {
    margin-top:  12.5vw;
    width:  75vw;
  }
}
.DropdownButton-button span {
  line-height: 1;
}
@media (min-width: 1920px) {
  .DropdownButton-button span {
    padding-top:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DropdownButton-button span {
    padding-top:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DropdownButton-button span {
    padding-top:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .DropdownButton-button span {
    padding-top:  0.625vw;
  }
}
.DropdownButton-button:after {
  content: '';
  color: #FFFFFF;
  border-left: solid transparent;
  border-right: solid transparent;
  border-bottom: solid #FFFFFF;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 1920px) {
  .DropdownButton-button:after {
    border-left-width:  6.666666666666666px;
    border-bottom-width:  6.666666666666666px;
    border-right-width:  6.666666666666666px;
    margin-left:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DropdownButton-button:after {
    border-left-width:  0.3472222222222222vw;
    border-bottom-width:  0.3472222222222222vw;
    border-right-width:  0.3472222222222222vw;
    margin-left:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DropdownButton-button:after {
    border-left-width:  0.4032258064516129vw;
    border-bottom-width:  0.4032258064516129vw;
    border-right-width:  0.4032258064516129vw;
    margin-left:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .DropdownButton-button:after {
    border-left-width:  1.5625vw;
    border-bottom-width:  1.5625vw;
    border-right-width:  1.5625vw;
    margin-left:  3.125vw;
  }
}
.isDesktop .DropdownButton-button:hover:after {
  border-left: solid transparent;
  border-right: solid transparent;
  border-bottom: solid #111112;
}
@media (min-width: 1920px) {
  .isDesktop .DropdownButton-button:hover:after {
    border-left-width:  6.666666666666666px;
    border-bottom-width:  6.666666666666666px;
    border-right-width:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .DropdownButton-button:hover:after {
    border-left-width:  0.3472222222222222vw;
    border-bottom-width:  0.3472222222222222vw;
    border-right-width:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .DropdownButton-button:hover:after {
    border-left-width:  0.4032258064516129vw;
    border-bottom-width:  0.4032258064516129vw;
    border-right-width:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .DropdownButton-button:hover:after {
    border-left-width:  1.5625vw;
    border-bottom-width:  1.5625vw;
    border-right-width:  1.5625vw;
  }
}
.DropdownButton-options {
  position: absolute;
  top: 100%;
  background-color: #111112;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transform: translate3d(0, -50%, 0) scaleY(0);
          transform: translate3d(0, -50%, 0) scaleY(0);
  opacity: 0;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  will-change: transform;
  z-index: 25;
}
@media (min-width: 1920px) {
  .DropdownButton-options {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DropdownButton-options {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DropdownButton-options {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .DropdownButton-options {
    margin-top:  1.5625vw;
  }
}
.DropdownButton-option {
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.DropdownButton-option:first-child {
  border-top: none;
}
.isDesktop .DropdownButton-option:hover {
  color: #111112;
  background-color: #FFFFFF;
}
.DropdownButton-option:visited {
  text-decoration: none;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .DropdownButton-option {
    padding:  13.333333333333332px 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DropdownButton-option {
    padding:  0.6944444444444444vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DropdownButton-option {
    padding:  0.8064516129032258vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .DropdownButton-option {
    padding:  3.125vw 6.25vw;
  }
}
