.GlobalTopNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  z-index: 10;
  will-change: transform;
  -webkit-transition: 0.15s opacity, 0.4s -webkit-transform;
  transition: 0.15s opacity, 0.4s -webkit-transform;
  transition: 0.4s transform, 0.15s opacity;
  transition: 0.4s transform, 0.15s opacity, 0.4s -webkit-transform;
}
@media (max-width: 759px) {
  .GlobalTopNav {
    display: none;
  }
}
.GlobalTopNav.hide {
  -webkit-transform: translate3d(0, -200%, 0);
          transform: translate3d(0, -200%, 0);
}
.GlobalTopNav.hideOpacity {
  opacity: 0;
}
@media (min-width: 1920px) {
  .GlobalTopNav {
    width: 1146.66666667px;
    left: 453.33333333px;
    top: 29.33333333px;
    height: 53.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalTopNav {
    width: 59.72222222vw;
    left: 23.61111111vw;
    top: 1.52777778vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalTopNav {
    width: 61.29032258vw;
    left: 24.19354839vw;
    top: 1.77419355vw;
    height: 3.22580645vw;
  }
}
.PopupShow .GlobalTopNav,
.OverlayShow .GlobalTopNav {
  opacity: 0;
  pointer-events: none;
}
.GlobalTopNav-tab {
  position: relative;
  height: 100%;
}
.GlobalTopNav-tab.active:after,
.isDesktop .GlobalTopNav-tab:hover:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #FFFFFF;
}
.isDesktop .GlobalTopNav-tab.withContent:hover:after,
.isNotDesktop .GlobalTopNav-tab.withContent:active:after {
  display: none;
}
.GlobalTopNav-tabLink {
  text-decoration: none;
  color: #FFFFFF;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.GlobalTopNav-tabLink:visited {
  color: #FFFFFF;
}
.isDesktop .GlobalTopNav-tab:hover .GlobalTopNav-subTabs,
.isNotDesktop .GlobalTopNav-tab:active .GlobalTopNav-subTabs {
  -webkit-animation: 0.15s showSubTabs ease-in-out forwards;
          animation: 0.15s showSubTabs ease-in-out forwards;
}
@-webkit-keyframes showSubTabs {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes showSubTabs {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.GlobalTopNav-subTabs {
  visibility: hidden;
  opacity: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 100%;
  background-color: #FFFFFF;
}
.GlobalTopNav-subTab {
  width: 100%;
  border-bottom: 1px solid #E7E7E7;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
  background-color: #FFFFFF;
}
.isDesktop .GlobalTopNav-subTab:hover,
.isNotDesktop .GlobalTopNav-subTab:active {
  background-color: #F7F7F8;
}
.GlobalTopNav-subTab:last-of-type {
  border-bottom: none;
}
.GlobalTopNav-subTabLink {
  text-decoration: none;
  min-width: 100%;
  color: #111112;
  white-space: nowrap;
  display: block;
}
.GlobalTopNav-subTabLink:visited {
  color: #111112;
}
@media (min-width: 1920px) {
  .GlobalTopNav-subTabLink {
    padding: 20px 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalTopNav-subTabLink {
    padding: 1.04166667vw 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalTopNav-subTabLink {
    padding: 1.20967742vw 1.61290323vw;
  }
}
