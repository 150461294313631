@media (min-width: 1920px) {
  .CampusLightCenters-head {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-head {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-head {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-head {
    padding:  0vw 6.25vw;
  }
}
.CampusLightCenters-titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .CampusLightCenters-titleBlock {
    display: block;
  }
}
@media (min-width: 1920px) {
  .CampusLightCenters-titleBlock {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-titleBlock {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-titleBlock {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-titleBlock {
    padding-top:  4.6875vw;
  }
}
.CampusLightCenters-title {
  color: #FFFFFF;
  position: relative;
}
.CampusLightCenters-description {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .CampusLightCenters-description {
    margin-top: 6.25vw;
  }
}
.CampusLightCenters-title,
.CampusLightCenters-description {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .CampusLightCenters-title,
  .CampusLightCenters-description {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeList {
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeList {
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeList {
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeList {
    margin-top:  12.5vw;
  }
}
.CampusLightCenters-place {
  position: relative;
  height: 0;
  padding-bottom: 55%;
}
@media (min-width: 1920px) {
  .CampusLightCenters-place {
    width:  473.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-place {
    width:  24.65277777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-place {
    width:  23.225806451612904vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-place {
    width:  81.25vw;
  }
}
.CampusLightCenters-place a {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.isDesktop .CampusLightCenters-place:hover .CampusLightCenters-placeLinkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isDesktop .CampusLightCenters-place:hover .CampusLightCenters-placeLink span {
  text-decoration: underline;
}
.isDesktop .CampusLightCenters-place:hover .CampusLightCenters-placeBackground img {
  -webkit-transform: translate3d(0, 0, 0) scale(1.05);
          transform: translate3d(0, 0, 0) scale(1.05);
}
.CampusLightCenters-placeBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.CampusLightCenters-placeBackground img {
  height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.CampusLightCenters-placeDescription {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeDescription {
    left:  26.666666666666664px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeDescription {
    left:  1.3888888888888888vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeDescription {
    left:  1.6129032258064515vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeDescription {
    left:  4.6875vw;
    bottom:  4.6875vw;
  }
}
.CampusLightCenters-placeTitle {
  color: #FFFFFF;
}
.CampusLightCenters-placeLink {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeLink {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeLink {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeLink {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeLink {
    margin-top:  0vw;
  }
}
.CampusLightCenters-placeLink:visited {
  color: #FFFFFF;
}
.CampusLightCenters-placeLinkArrow {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeLinkArrow {
    width:  24px;
    height:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeLinkArrow {
    width:  1.25vw;
    height:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeLinkArrow {
    width:  1.4516129032258065vw;
    height:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeLinkArrow {
    width:  5.625vw;
    height:  3.75vw;
  }
}
.CampusLightCenters-placeLinkArrow svg {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  stroke: #FFFFFF;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeLinkArrow svg {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeLinkArrow svg {
    -webkit-transform: translate3d(-0.20833333vw, 0, 0);
            transform: translate3d(-0.20833333vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeLinkArrow svg {
    -webkit-transform: translate3d(-0.24193548vw, 0, 0);
            transform: translate3d(-0.24193548vw, 0, 0);
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeLinkArrow svg {
    -webkit-transform: translate3d(-0.9375vw, 0, 0);
            transform: translate3d(-0.9375vw, 0, 0);
  }
}
.CampusLightCenters-placeLink span {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  line-height: 1;
}
@media (max-width: 759px) {
  .CampusLightCenters-placeLink span {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeLink span {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeLink span {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeLink span {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeLink span {
    line-height: 1;
  }
}
@media (min-width: 1920px) {
  .CampusLightCenters-placeLink span {
    padding-top:  4px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusLightCenters-placeLink span {
    padding-top:  0.20833333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusLightCenters-placeLink span {
    padding-top:  0.24193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CampusLightCenters-placeLink span {
    padding-top:  0vw;
  }
}
.CampusLightCenters .GlobalSlider-arrowRight {
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
.CampusLightCenters .GlobalSlider-arrowLeft {
  top: 50%;
  -webkit-transform: rotateY(180deg) translate3d(0, -50%, 0);
          transform: rotateY(180deg) translate3d(0, -50%, 0);
}
