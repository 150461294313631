.FeaturesIcons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
.FeaturesIcons-feature {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
}
.FeaturesIcons-feature .Link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .FeaturesIcons-feature .Link {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature .Link {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature .Link {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .FeaturesIcons-feature .Link {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .FeaturesIcons-feature .Link:before {
    margin-bottom:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature .Link:before {
    margin-bottom:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature .Link:before {
    margin-bottom:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-feature .Link:before {
    margin-bottom:  0.625vw;
  }
}
@media (min-width: 1920px) {
  .FeaturesIcons-feature .Link {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature .Link {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature .Link {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-feature .Link {
    margin-top:  12.5vw;
  }
}
.PageLanding .FeaturesIcons-feature {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
}
@media (min-width: 1920px) {
  .FeaturesIcons-feature {
    padding:  0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature {
    padding:  0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature {
    padding:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-feature {
    padding:  0vw 6.25vw;
  }
}
.FeaturesIcons-feature:first-of-type {
  padding-left: 0;
}
.FeaturesIcons-feature:last-of-type {
  padding-right: 0;
}
@media (min-width: 1920px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  9.375vw;
  }
}
.FeaturesIcons-feature:nth-child(6n) {
  padding-left: 0;
}
.FeaturesIcons-feature:nth-child(5n) {
  padding-right: 0;
}
@media (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  0vw;
  }
}
@media (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  0vw;
  }
}
@media (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 5) {
    margin-top:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 5) {
    margin-top:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 5) {
    margin-top:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 5) {
    margin-top:  25vw;
  }
}
@media (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(6n) {
    padding:  0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(6n) {
    padding:  0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(6n) {
    padding:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(6n) {
    padding:  0vw 6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(5n) {
    padding:  0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(5n) {
    padding:  0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(5n) {
    padding:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(5n) {
    padding:  0vw 6.25vw;
  }
}
.PageLanding .FeaturesIcons-feature:nth-child(4n) {
  padding-right: 0;
}
.PageLanding .FeaturesIcons-feature:nth-child(5n) {
  padding-left: 0;
}
@media (max-width: 759px) {
  .FeaturesIcons-feature {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
  .PageLanding .FeaturesIcons-feature {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6):first-child {
    margin-top: 0;
  }
  .FeaturesIcons-feature:nth-child(1),
  .FeaturesIcons-feature:nth-child(2) {
    margin-top: 0;
  }
  .FeaturesIcons-feature:nth-child(2n) {
    padding-left: 10px;
  }
  .PageLanding .FeaturesIcons-feature:nth-child(2n) {
    padding: 0;
  }
  .FeaturesIcons-feature:nth-child(2n + 1) {
    padding-right: 10px;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature {
    margin-top:  66.66666666666666px;
    padding:  0;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature {
    margin-top:  3.4722222222222223vw;
    padding:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature {
    margin-top:  4.032258064516129vw;
    padding:  0vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature {
    margin-top:  15.625vw;
    padding:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2) {
    margin-top:  66.66666666666666px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2) {
    margin-top:  3.4722222222222223vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2) {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2) {
    margin-top:  15.625vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .FeaturesIcons-feature {
    margin-top:  26.666666666666664px;
    padding:  0;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature {
    margin-top:  1.3888888888888888vw;
    padding:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature {
    margin-top:  1.6129032258064515vw;
    padding:  0vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .FeaturesIcons-feature {
    margin-top:  6.25vw;
    padding:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  66.66666666666666px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  3.4722222222222223vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(n + 6) {
    margin-top:  15.625vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2n + 1) {
    padding:  0;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2n + 1) {
    padding:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2n + 1) {
    padding:  0vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageLanding .FeaturesIcons-feature:nth-child(2n + 1) {
    padding:  0vw;
  }
}
.FeaturesIcons-featureIcon {
  position: relative;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .FeaturesIcons-featureIcon {
    width:  186.66666666666666px;
    height:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-featureIcon {
    width:  9.722222222222223vw;
    height:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-featureIcon {
    width:  11.29032258064516vw;
    height:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-featureIcon {
    width:  31.25vw;
    height:  31.25vw;
  }
}
@media (min-width: 1920px) {
  .isWithLinks .FeaturesIcons-featureIcon {
    width:  133.33333333333331px;
    height:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isWithLinks .FeaturesIcons-featureIcon {
    width:  6.944444444444445vw;
    height:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isWithLinks .FeaturesIcons-featureIcon {
    width:  8.064516129032258vw;
    height:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .isWithLinks .FeaturesIcons-featureIcon {
    width:  31.25vw;
    height:  31.25vw;
  }
}
.FeaturesIcons-featureIcon img {
  width: 100%;
  height: 100%;
}
.FeaturesIcons-featureTitle {
  color: #6F7380;
  text-transform: none;
}
.PageLanding .FeaturesIcons-featureTitle {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .FeaturesIcons-featureTitle {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-featureTitle {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-featureTitle {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-featureTitle {
    margin-top:  0vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-featureTitle {
    line-height: 1;
  }
}
.FeaturesIcons-featureDescription {
  color: #6F7380;
}
.PageLanding .FeaturesIcons-featureDescription {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .FeaturesIcons-featureDescription {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FeaturesIcons-featureDescription {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FeaturesIcons-featureDescription {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .FeaturesIcons-featureDescription {
    margin-top:  3.125vw;
  }
}
