.CompanyLandingHead {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 90vh;
}
@media (min-width: 1920px) {
  .CompanyLandingHead {
    padding:  0 186.66666666666666px 93.33333333333333px;
    min-height:  933.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyLandingHead {
    padding:  0vw 9.722222222222223vw 4.861111111111112vw;
    min-height:  48.611111111111114vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyLandingHead {
    padding:  0vw 11.29032258064516vw 5.64516129032258vw;
    min-height:  56.45161290322581vw;
  }
}
@media (max-width: 759px) {
  .CompanyLandingHead {
    padding:  0vw 6.25vw;
    margin-bottom:  18.75vw;
  }
}
@media (max-width: 759px) {
  .CompanyLandingHead {
    height: 100vh;
    height: -webkit-fill-available;
    height: -moz-available;
    height: fill-available;
  }
}
.CompanyLandingHead-flexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (max-width: 759px) {
  .CompanyLandingHead-flexSpacer {
    display: none;
  }
}
.CompanyLandingHead-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #111112;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 2;
}
.CompanyLandingHead-overlay.show {
  opacity: 0.8;
}
.CompanyLandingHead-facts {
  margin-top: auto;
  opacity: 1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .CompanyLandingHead-facts {
    margin-top: 0;
    position: absolute;
    bottom: -18.75vw;
    left: 6.25vw;
    right: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .CompanyLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  5.64516129032258vw;
  }
}
@media (max-width: 759px) {
  .CompanyLandingHead-facts .FourColumnFacts-factValueTitle {
    font-size:  9.375vw;
  }
}
.CompanyLandingHead-facts.hide {
  opacity: 0;
}
