.ProductFamilyPopup-wrapper {
  margin-left: auto;
  position: relative;
  width: 100%;
}
.ProductFamilyPopup-content {
  background: #FFFFFF;
}
.ProductFamilyPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
.ProductFamilyPopup-title {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  background: #F6F6F6;
}
@media (min-width: 1920px) {
  .ProductFamilyPopup-title {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyPopup-title {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyPopup-title {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyPopup-title {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .ProductFamilyPopup-title {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyPopup-title {
    padding:  33.33333333333333px 53.33333333333333px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyPopup-title {
    padding:  1.7361111111111112vw 2.7777777777777777vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyPopup-title {
    padding:  2.0161290322580645vw 3.225806451612903vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyPopup-title {
    padding:  20.3125vw 6.25vw 0vw;
  }
}
.ProductFamilyPopup-filter {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #F6F6F6;
  z-index: 10;
}
@media (min-width: 1920px) {
  .ProductFamilyPopup-filter {
    padding:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyPopup-filter {
    padding:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyPopup-filter {
    padding:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyPopup-filter {
    padding:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyPopup-filter {
    position: relative;
    padding: 0;
  }
}
.ProductFamilyPopup-items {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 7;
}
.ProductFamilyPopup-loadThreshold {
  position: fixed;
  height: 150vh;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}
