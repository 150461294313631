@media (max-width: 759px) {
  .ProductMasterpieceSpecifications {
    margin-top: 16.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications {
    margin-top: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications {
    margin-top: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications {
    margin-top: 106.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-content {
    margin-top: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications-content {
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications-content {
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications-content {
    margin-top: 46.66666667px;
  }
}
.ProductMasterpieceSpecifications-contentItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #E7E7E7;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItem {
    display: block;
    padding-bottom: 3.75vw;
    padding-top: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications-contentItem {
    padding-bottom: 1.29032258vw;
    padding-top: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications-contentItem {
    padding-bottom: 1.11111111vw;
    padding-top: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications-contentItem {
    padding-bottom: 21.33333333px;
    padding-top: 18.66666667px;
  }
}
.ProductMasterpieceSpecifications-contentItem:last-of-type {
  border-bottom: none;
}
.ProductMasterpieceSpecifications-contentItem.isDropdownItem {
  border-bottom: none;
  -webkit-box-shadow: 0 -1px 0 0 inset #E7E7E7;
          box-shadow: 0 -1px 0 0 inset #E7E7E7;
  padding-bottom: 0;
}
.ProductMasterpieceSpecifications-contentItemName {
  color: #6F7380;
  letter-spacing: normal;
  text-transform: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItemName {
    font-size: 3.75vw;
    line-height: normal;
  }
}
@media (max-width: 759px) {
  .isDropdownItem .ProductMasterpieceSpecifications-contentItemName {
    padding-bottom: 2.5vw;
    font-size: 3.75vw;
    line-height: normal;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDropdownItem .ProductMasterpieceSpecifications-contentItemName {
    padding-bottom: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDropdownItem .ProductMasterpieceSpecifications-contentItemName {
    padding-bottom: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .isDropdownItem .ProductMasterpieceSpecifications-contentItemName {
    padding-bottom: 21.33333333px;
  }
}
.ProductMasterpieceSpecifications-contentItemValue {
  letter-spacing: normal;
  text-transform: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItemValue {
    font-size: 3.75vw;
    line-height: normal;
    margin-top: 2.5vw;
  }
}
.ProductMasterpieceSpecifications-contentItemDropdown {
  border-bottom: 1px solid #000000;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItemDropdown {
    padding-bottom: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications-contentItemDropdown {
    padding-bottom: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications-contentItemDropdown {
    padding-bottom: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications-contentItemDropdown {
    padding-bottom: 21.33333333px;
  }
}
.ProductMasterpieceSpecifications-contentItemDropdownModel {
  letter-spacing: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .ProductMasterpieceSpecifications-contentItemDropdownModel:hover,
.isNotDesktop .ProductMasterpieceSpecifications-contentItemDropdownModel:active {
  opacity: 0.7;
}
.ProductMasterpieceSpecifications-contentItemDropdownModel:after {
  content: '';
  width: 0;
  height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItemDropdownModel:after {
    border-left: 1.5625vw solid transparent;
    border-right: 1.5625vw solid transparent;
    border-top: 1.875vw solid #111112;
    margin-left: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications-contentItemDropdownModel:after {
    border-left: 0.40322581vw solid transparent;
    border-right: 0.40322581vw solid transparent;
    border-top: 0.48387097vw solid #111112;
    margin-left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications-contentItemDropdownModel:after {
    border-left: 0.34722222vw solid transparent;
    border-right: 0.34722222vw solid transparent;
    border-top: 0.41666667vw solid #111112;
    margin-left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications-contentItemDropdownModel:after {
    border-left: 6.66666667px solid transparent;
    border-right: 6.66666667px solid transparent;
    border-top: 8px solid #111112;
    margin-left: 13.33333333px;
  }
}
.ProductMasterpieceSpecifications-contentItemDropdownModel.active:after {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ProductMasterpieceSpecifications-contentItemDropdownOptions {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #E7E7E7;
  -webkit-transition: 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
  transition: 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
  transition: 0.15s transform ease-in-out, 0.15s opacity ease-in-out;
  transition: 0.15s transform ease-in-out, 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -3.125vw, 0);
            transform: translate3d(0, -3.125vw, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -0.80645161vw, 0);
            transform: translate3d(0, -0.80645161vw, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -0.69444444vw, 0);
            transform: translate3d(0, -0.69444444vw, 0);
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOptions {
    -webkit-transform: translate3d(0, -13.33333333px, 0);
            transform: translate3d(0, -13.33333333px, 0);
  }
}
.ProductMasterpieceSpecifications-contentItemDropdownOption {
  border-bottom: 1px solid #E7E7E7;
  letter-spacing: normal;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .ProductMasterpieceSpecifications-contentItemDropdownOption:hover,
.isNotDesktop .ProductMasterpieceSpecifications-contentItemDropdownOption:active {
  opacity: 0.7;
}
@media (max-width: 759px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOption {
    padding: 3.125vw 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOption {
    padding: 0.80645161vw 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOption {
    padding: 0.69444444vw 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceSpecifications-contentItemDropdownOption {
    padding: 13.33333333px 6.66666667px;
  }
}
.ProductMasterpieceSpecifications-contentItemDropdownModel.active + .ProductMasterpieceSpecifications-contentItemDropdownOptions {
  visibility: visible;
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
