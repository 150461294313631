.LandingItemsBlock-wrapper {
  border-top: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .LandingItemsBlock-wrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-wrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-wrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-wrapper {
    padding-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-wrapper {
    display: block;
  }
}
.LandingItemsBlock-title {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
.LandingItemsBlock-text {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (min-width: 1920px) {
  .LandingItemsBlock-way {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-way {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-way {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-way {
    margin-top:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .LandingItemsBlock-way:first-child {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-way:first-child {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-way:first-child {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-way:first-child {
    margin-top:  12.5vw;
  }
}
@media (min-width: 1920px) {
  .withLink .LandingItemsBlock-way:first-child {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .withLink .LandingItemsBlock-way:first-child {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .withLink .LandingItemsBlock-way:first-child {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .withLink .LandingItemsBlock-way:first-child {
    margin-top:  6.25vw;
  }
}
.LandingItemsBlock-wayTitle {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .LandingItemsBlock-wayDescription {
    margin-top:  9.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-wayDescription {
    margin-top:  0.48611111111111116vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-wayDescription {
    margin-top:  0.564516129032258vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-wayDescription {
    margin-top:  1.5625vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-wayDescription {
    line-height: 1.42857143;
  }
}
.LandingItemsBlock-wayDescription a {
  color: #FFFFFF;
}
.LandingItemsBlock-wayDescription a:visited {
  color: #FFFFFF;
}
.isDesktop .LandingItemsBlock-wayDescription a:hover {
  text-decoration: none;
}
.LandingItemsBlock-wayDescription ul {
  list-style: disc;
}
@media (min-width: 1920px) {
  .LandingItemsBlock-wayDescription ul {
    margin:  13.333333333333332px 10.666666666666666px;
    padding-left:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-wayDescription ul {
    margin:  0.6944444444444444vw 0.5555555555555556vw;
    padding-left:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-wayDescription ul {
    margin:  0.8064516129032258vw 0.6451612903225806vw;
    padding-left:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-wayDescription ul {
    margin:  3.125vw 2.5vw;
    padding-left:  5vw;
  }
}
.LandingItemsBlock-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
  letter-spacing: -0.01428571em;
}
@media (max-width: 759px) {
  .LandingItemsBlock-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .LandingItemsBlock-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .LandingItemsBlock-link:hover,
.isNotDesktop .LandingItemsBlock-link:active {
  text-decoration: underline;
}
.isDesktop .LandingItemsBlock-link:hover:before,
.isNotDesktop .LandingItemsBlock-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .LandingItemsBlock-link:hover:before,
  .isNotDesktop .LandingItemsBlock-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-link {
    letter-spacing: 0;
  }
}
.LandingItemsBlock-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .LandingItemsBlock-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .LandingItemsBlock-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .LandingItemsBlock-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .LandingItemsBlock-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .LandingItemsBlock-link {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LandingItemsBlock-link {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LandingItemsBlock-link {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .LandingItemsBlock-link {
    margin-top:  6.25vw;
  }
}
.LandingItemsBlock-link:visited {
  color: #FFFFFF;
}
