.GlobalMenu {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .GlobalMenu {
    display: none;
  }
}
.GlobalMenu-item {
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  will-change: transform, opacity;
  letter-spacing: -0.01666667em;
  -webkit-transform: translate3d(-3%, 0, 0);
          transform: translate3d(-3%, 0, 0);
  opacity: 0;
  -webkit-transition: 0.35s opacity, 0.35s -webkit-transform;
  transition: 0.35s opacity, 0.35s -webkit-transform;
  transition: 0.35s transform, 0.35s opacity;
  transition: 0.35s transform, 0.35s opacity, 0.35s -webkit-transform;
  line-height: 0.88333333;
}
.GlobalMenu-item.show {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.GlobalMenu-item:before {
  content: '';
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
}
.isDesktop .GlobalMenu-item:hover .GlobalMenu-itemLink:before,
.isNotDesktop .GlobalMenu-item:active .GlobalMenu-itemLink:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .GlobalMenu-item:hover .GlobalMenu-itemLink:before,
  .isNotDesktop .GlobalMenu-item:active .GlobalMenu-itemLink:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.isDesktop .GlobalMenu-item:hover,
.GlobalMenu-item.active {
  color: transparent;
  -webkit-text-stroke: 1px #FFFFFF;
  -moz-text-stroke: 1px #FFFFFF;
  text-stroke: 1px #FFFFFF;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}
.isDesktop .GlobalMenu-item:hover:before {
  background-color: rgba(255, 255, 255, 0.1);
}
.isIE .GlobalMenu-item:hover {
  color: #FFFFFF;
}
.isIE .GlobalMenu-item:hover:before {
  background-color: rgba(255, 255, 255, 0.1);
}
.isIE .GlobalMenu-item.active {
  color: #FFFFFF;
}
.isIE .GlobalMenu-item.active:before {
  background-color: rgba(255, 255, 255, 0.2);
}
.GlobalMenu-item.active {
  cursor: default;
  pointer-events: none;
}
.GlobalMenu-item.active .GlobalMenu-itemLink {
  display: none;
}
.GlobalMenu-item:last-of-type {
  border-bottom: none;
}
@media (min-width: 1920px) {
  .GlobalMenu-item {
    padding-bottom:  8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenu-item {
    padding-bottom:  0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenu-item {
    padding-bottom:  0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenu-item {
    padding-bottom:  1.875vw;
  }
}
.GlobalMenu-item:visited {
  color: #FFFFFF;
}
.GlobalMenu-itemLink {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  pointer-events: none;
  right: 0;
  top: 50%;
}
@media (min-width: 1920px) {
  .GlobalMenu-itemLink {
    width:  46.666666666666664px;
    height:  24px;
    margin-top:  -12px;
    right:  5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenu-itemLink {
    width:  2.430555555555556vw;
    height:  1.25vw;
    margin-top:  -0.625vw;
    right:  0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenu-itemLink {
    width:  2.82258064516129vw;
    height:  1.4516129032258065vw;
    margin-top:  -0.7258064516129032vw;
    right:  0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .GlobalMenu-itemLink {
    width:  8.125vw;
    height:  4.375vw;
    margin-top:  -2.1875vw;
    right:  1.25vw;
  }
}
.GlobalMenu-itemLink:before {
  content: '';
  position: absolute;
  will-change: transform;
  background: url('/static/img/arrow-big.svg');
}
@media (max-width: 759px) {
  .GlobalMenu-itemLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .GlobalMenu-itemLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .GlobalMenu-itemLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalMenu-itemLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalMenu-itemLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .GlobalMenu-itemLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
