.PageServicesPopup-content {
  background: #F6F6F6;
  width: 100%;
}
.PageServicesPopup-contentWrapper {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageServicesPopup-contentWrapper {
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-contentWrapper {
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-contentWrapper {
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageServicesPopup-contentWrapper {
    padding-bottom:  12.5vw;
  }
}
@media (max-width: 759px) {
  .PageServicesPopup-footer {
    width: 87.5vw;
    margin: 0 auto;
    padding-top: unset;
    padding-bottom: unset;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-footer {
    width: 61.29032258vw;
    margin: 0 auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-footer {
    width: 52.77777778vw;
    margin: 0 auto;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-footer {
    width: 1013.33333333px;
    margin: 0 auto;
  }
}
.PageServicesPopup-tabContent {
  -webkit-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.PageServicesPopup-tabContent.active {
  opacity: 1;
  height: auto;
}
.PageServicesPopup-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
@media (min-width: 1920px) {
  .PageServicesPopup-tabs {
    min-height:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-tabs {
    min-height:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-tabs {
    min-height:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageServicesPopup-tabs {
    min-height:  25vw;
  }
}
.PageServicesPopup-tabs:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 1px;
  z-index: 1;
  background-color: #DEDEDE;
}
@media (max-width: 759px) {
  .PageServicesPopup-tabs {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    height: 100%;
    max-height: 100%;
    padding-right: 6.25vw;
  }
  .PageServicesPopup-tabsWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-left: 6.25vw;
  }
  .PageServicesPopup-tabsWrapper .ps__rail-x {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-tabs {
    width: 61.29032258vw;
    margin: 0 auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-tabs {
    width: 52.77777778vw;
    margin: 0 auto;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-tabs {
    width: 1013.33333333px;
    margin: 0 auto;
  }
}
.PageServicesPopup-tabWrapper {
  text-align: center;
  text-decoration: none;
  color: #111112;
  line-height: normal;
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .PageServicesPopup-tabWrapper {
    padding-top:  26.666666666666664px;
    padding-bottom:  26.666666666666664px;
    width:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-tabWrapper {
    padding-top:  1.3888888888888888vw;
    padding-bottom:  1.3888888888888888vw;
    width:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-tabWrapper {
    padding-top:  1.6129032258064515vw;
    padding-bottom:  1.6129032258064515vw;
    width:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .PageServicesPopup-tabWrapper {
    padding-top:  6.25vw;
    padding-bottom:  6.25vw;
    width:  50vw;
  }
}
.PageServicesPopup-tabWrapper span {
  text-align: center;
  height: auto;
  width: 100%;
}
@media (max-width: 759px) {
  .PageServicesPopup-tabWrapper {
    line-height: 12.5vw;
    text-align: center;
    width: 31.25vw;
    padding: 3.125vw 0;
    margin-left: 6.25vw;
  }
  .PageServicesPopup-tabWrapper:first-of-type {
    margin-left: 0;
  }
}
.isDesktop .PageServicesPopup-tabWrapper:hover:after,
.isNotDesktop .PageServicesPopup-tabWrapper:active:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 2px;
  z-index: 2;
  background-color: #111112;
}
.PageServicesPopup-tabWrapper.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 2px;
  z-index: 2;
  background-color: #111112;
}
.PageServicesPopup-tab {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
}
@media (max-width: 759px) {
  .PageServicesPopup-tab {
    font-size: 3.75vw;
  }
}
.PageServicesPopup-tabsWrapper {
  background: #FFFFFF;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
@media (max-width: 759px) {
  .PageServicesPopup-tabsWrapper {
    top: 12.5vw;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .PageServicesPopup-tabsWrapper::-webkit-scrollbar {
    display: none;
  }
}
.PageServicesPopup-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 17.24137931%;
}
@media (max-width: 759px) {
  .PageServicesPopup-menu {
    margin-right: 0;
    width: 100%;
  }
}
.PageServicesPopup-menuLogo {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  background-color: #111112;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuLogo {
    width: 42.5vw;
    height: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuLogo {
    width: 10.96774194vw;
    height: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuLogo {
    width: 9.44444444vw;
    height: 1.77419355vw;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuLogo {
    width: 181.33333333px;
    height: 29.33333333px;
  }
}
.isDesktop .PageServicesPopup-menuLogo {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.isDesktop .PageServicesPopup-menuLogo:hover,
.isNotDesktop .PageServicesPopup-menuLogo:active {
  opacity: 0.7;
}
.PageServicesPopup-menuLogo:after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.brand--zumtobel .PageServicesPopup-menuLogo:after {
  background-image: url('/static/img/logo-zumtobel.png');
}
@media (max-width: 759px) {
  .brand--zumtobel .PageServicesPopup-menuLogo:after {
    left: 0vw;
    top: 0vw;
    width: 42.5vw;
    height: 6.875vw;
    background-size: 42.5vw 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--zumtobel .PageServicesPopup-menuLogo:after {
    left: 0vw;
    top: 0vw;
    width: 10.96774194vw;
    height: 1.77419355vw;
    background-size: 10.96774194vw 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--zumtobel .PageServicesPopup-menuLogo:after {
    left: 0vw;
    top: 0vw;
    width: 9.44444444vw;
    height: 1.52777778vw;
    background-size: 9.44444444vw 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .brand--zumtobel .PageServicesPopup-menuLogo:after {
    left: 0;
    top: 0;
    width: 181.33333333px;
    height: 29.33333333px;
    background-size: 181.33333333px 29.33333333px;
  }
}
.brand--thorn .PageServicesPopup-menuLogo:after {
  background-image: url('/static/svg/logo-thorn.svg');
}
@media (max-width: 759px) {
  .brand--thorn .PageServicesPopup-menuLogo:after {
    width: 25.625vw;
    height: 5vw;
    background-size: 25.625vw 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .PageServicesPopup-menuLogo:after {
    width: 6.61290323vw;
    height: 1.29032258vw;
    background-size: 6.61290323vw 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .PageServicesPopup-menuLogo:after {
    width: 5.69444444vw;
    height: 1.11111111vw;
    background-size: 5.69444444vw 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .brand--thorn .PageServicesPopup-menuLogo:after {
    width: 109.33333333px;
    height: 21.33333333px;
    background-size: 109.33333333px 21.33333333px;
  }
}
.PageServicesPopup-menuItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuItems {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 10.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuItems {
    margin-top: 2.74193548vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuItems {
    margin-top: 2.36111111vw;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuItems {
    margin-top: 45.33333333px;
  }
}
@media (max-width: 759px) {
  .PageServicesPopup-menuItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
  }
}
.PageServicesPopup-menuItem a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  line-height: 2.85714286;
  color: #111112;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuItem a {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuItem a {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuItem a {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuItem a {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageServicesPopup-menuItem a:hover,
.isNotDesktop .PageServicesPopup-menuItem a:active {
  text-decoration: underline;
}
.PageServicesPopup-menuDropdown {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  line-height: 2.85714286;
  color: #111112;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuDropdown {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuDropdown {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuDropdown {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuDropdown {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) {
  .PageServicesPopup-menuDropdown {
    display: none;
    margin-top: 0;
    line-height: 3.63636364;
    -webkit-column-count: 1 !important;
       -moz-column-count: 1 !important;
            column-count: 1 !important;
  }
}
@media (min-width: 1920px), (min-width: 1240px) and (max-width: 1919px), (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuDropdown {
    display: none;
  }
}
.PageServicesPopup-menuDropdownSelect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 87.5vw;
  margin: 0 auto;
}
.PageServicesPopup-menuDropdownSelect:after {
  content: '';
  width: 0;
  height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuDropdownSelect:after {
    border-left: 1.5625vw solid transparent;
    border-right: 1.5625vw solid transparent;
    border-top: 1.875vw solid #111112;
    margin-left: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuDropdownSelect:after {
    border-left: 0.40322581vw solid transparent;
    border-right: 0.40322581vw solid transparent;
    border-top: 0.48387097vw solid #111112;
    margin-left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuDropdownSelect:after {
    border-left: 0.34722222vw solid transparent;
    border-right: 0.34722222vw solid transparent;
    border-top: 0.41666667vw solid #111112;
    margin-left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuDropdownSelect:after {
    border-left: 6.66666667px solid transparent;
    border-right: 6.66666667px solid transparent;
    border-top: 8px solid #111112;
    margin-left: 13.33333333px;
  }
}
.isOpenDropdown .PageServicesPopup-menuDropdownSelect:after {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.PageServicesPopup-menuDropdownMenu {
  position: absolute;
  top: 100%;
  width: 100vw;
  -webkit-column-count: 1 !important;
     -moz-column-count: 1 !important;
          column-count: 1 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: #F6F6F6;
  cursor: default;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0s visibility 0.15s ease-out, 0.15s -webkit-transform ease-out;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuDropdownMenu {
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transform: translateY(-6.25vw);
        -ms-transform: translateY(-6.25vw);
            transform: translateY(-6.25vw);
  }
}
.isOpenDropdown .PageServicesPopup-menuDropdownMenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out;
  transition: 0.15s transform ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
}
.PageServicesPopup-menuDropdownMenuItem {
  text-decoration: none;
  color: inherit;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #111112;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 2.85714286;
  -webkit-transition: 0.15s background-color ease-out;
  transition: 0.15s background-color ease-out;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuDropdownMenuItem {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuDropdownMenuItem {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuDropdownMenuItem {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuDropdownMenuItem {
    font-size: 16px;
  }
}
.PageServicesPopup-menuDropdownMenuItem:last-child {
  border-bottom: 0;
}
@media (max-width: 759px) {
  .PageServicesPopup-menuDropdownMenuItem {
    line-height: 3.63636364;
    padding: 1.25vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageServicesPopup-menuDropdownMenuItem {
    padding: 0.32258065vw 1.61290323vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageServicesPopup-menuDropdownMenuItem {
    padding: 0.27777778vw 1.38888889vw 0;
  }
}
@media (min-width: 1920px) {
  .PageServicesPopup-menuDropdownMenuItem {
    padding: 5.33333333px 26.66666667px 0;
  }
}
