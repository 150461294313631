.InvestorsFinancialItems {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.InvestorsFinancialItems-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.InvestorsFinancialItems .DownloadCard {
  width: 31.03448276%;
  margin-left: 3.44827586%;
}
@media (min-width: 1920px) {
  .InvestorsFinancialItems .DownloadCard {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems .DownloadCard {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems .DownloadCard {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialItems .DownloadCard {
    margin-top:  6.25vw;
    margin-left:  0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialItems .DownloadCard {
    width: 100%;
  }
}
.InvestorsFinancialItems .DownloadCard:nth-child(3n + 1) {
  margin-left: 0;
}
.InvestorsFinancialItems-link {
  color: #111112;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: normal;
  white-space: nowrap;
}
@media (max-width: 759px) {
  .InvestorsFinancialItems-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    letter-spacing: -0.28px;
    padding-top: 3.125vw;
  }
  .isDesktop .InvestorsFinancialItems-link:hover,
  .isNotDesktop .InvestorsFinancialItems-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialItems-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/svg/product-download-icon.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialItems-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    width: 8.125vw;
    height: 6.25vw;
    background-size: 8.125vw 6.25vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 4.03225806vw;
  }
  .isDesktop .InvestorsFinancialItems-link:hover,
  .isNotDesktop .InvestorsFinancialItems-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialItems-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialItems-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    letter-spacing: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 3.47222222vw;
  }
  .isDesktop .InvestorsFinancialItems-link:hover,
  .isNotDesktop .InvestorsFinancialItems-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialItems-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialItems-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    letter-spacing: 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialItems-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 66.66666667px;
  }
  .isDesktop .InvestorsFinancialItems-link:hover,
  .isNotDesktop .InvestorsFinancialItems-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialItems-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialItems-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 1920px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1920px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialItems-link:hover:before,
  .isNotDesktop .InvestorsFinancialItems-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialItems-link {
    letter-spacing: 0;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialItems-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 1920px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1920px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) and (min-width: 1920px) {
  .InvestorsFinancialItems-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.isDesktop .InvestorsFinancialItems-link:hover,
.isNotDesktop .InvestorsFinancialItems-link:active {
  cursor: pointer;
}
@media (min-width: 1920px) {
  .InvestorsFinancialItems-link {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialItems-link {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialItems-link {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialItems-link {
    margin-top:  3.125vw;
  }
}
