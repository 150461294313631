@media (max-width: 759px) {
  .ProductDetailsInspiration {
    padding: 6.25vw 6.25vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration {
    padding: 0 9.67741935vw;
    margin-top: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration {
    padding: 0 8.33333333vw;
    margin-top: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration {
    padding: 0 160px;
    margin-top: 133.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-title {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.ProductDetailsInspiration-arrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-arrowBack {
    display: block;
    position: absolute;
    left: -0.625vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ProductDetailsInspiration-arrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-items {
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-items {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-items {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-items {
    margin-top: 53.33333333px;
  }
}
.ProductDetailsInspiration-item {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-item {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-item {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-item {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-item {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .ProductDetailsInspiration-item:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .ProductDetailsInspiration-item-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .ProductDetailsInspiration-item:hover .ProductDetailsInspiration-item-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .ProductDetailsInspiration-item:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.ProductDetailsInspiration-item:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .ProductDetailsInspiration-item:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ProductDetailsInspiration-item:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ProductDetailsInspiration-item:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .ProductDetailsInspiration-item:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.ProductDetailsInspiration-item:active:after {
  opacity: 0.5;
}
.ProductDetailsInspiration-item:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .ProductDetailsInspiration-item:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-item:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-item:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-item:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-item:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.ProductDetailsInspiration-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .ProductDetailsInspiration-item:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-item {
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-item {
    margin-bottom: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-item {
    margin-bottom: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-item {
    margin-bottom: 26.66666667px;
  }
}
.ProductDetailsInspiration-item a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-item:before {
    bottom: 9.6875vw;
    right: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-item:before {
    bottom: 2.5vw;
    right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-item:before {
    bottom: 2.15277778vw;
    right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-item:before {
    bottom: 41.33333333px;
    right: 26.66666667px;
  }
}
.ProductDetailsInspiration-item:last-of-type {
  margin-bottom: 0;
}
.ProductDetailsInspiration-itemImage {
  width: 100%;
  padding-bottom: 56%;
  position: relative;
  background: #000000;
  display: block;
  cursor: pointer;
  overflow: hidden;
}
.isDesktop .ProductDetailsInspiration-itemImage:hover img,
.isNotDesktop .ProductDetailsInspiration-itemImage:active img {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.ProductDetailsInspiration-itemImage img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  position: absolute;
  -webkit-transition: 0.15s -webkit-transform linear;
  transition: 0.15s -webkit-transform linear;
  transition: 0.15s transform linear;
  transition: 0.15s transform linear, 0.15s -webkit-transform linear;
  height: 100%;
  width: 100%;
}
.ProductDetailsInspiration-itemText {
  position: absolute;
  pointer-events: none;
  display: block;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-itemText {
    bottom: 6.25vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-itemText {
    bottom: 1.61290323vw;
    left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-itemText {
    bottom: 1.38888889vw;
    left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-itemText {
    bottom: 26.66666667px;
    left: 26.66666667px;
  }
}
.ProductDetailsInspiration-itemTitle {
  color: #FFFFFF;
  letter-spacing: normal;
  pointer-events: none;
  display: block;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-itemTitle {
    font-size: 3.4375vw;
  }
}
.ProductDetailsInspiration-itemSubtitle {
  color: #FFFFFF;
  letter-spacing: normal;
  text-transform: none;
  pointer-events: none;
  display: block;
}
@media (max-width: 759px) {
  .ProductDetailsInspiration-itemSubtitle {
    font-size: 2.8125vw;
    line-height: normal;
    margin-top: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsInspiration-itemSubtitle {
    margin-top: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsInspiration-itemSubtitle {
    margin-top: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsInspiration-itemSubtitle {
    margin-top: 6.66666667px;
  }
}
