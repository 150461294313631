.CompanyGlobalPresence {
  min-height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence {
    padding-bottom:  290.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence {
    padding-bottom:  15.13888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence {
    padding-bottom:  17.580645161290324vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence {
    padding-bottom:  15.625vw;
  }
}
.CompanyGlobalPresence-title {
  color: #FFFFFF;
  text-align: center;
  width: 100%;
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-title {
    border-top: 1px solid #FFFFFF;
    text-align: start;
    padding-top: 3.125vw;
  }
}
.CompanyGlobalPresence-text {
  color: #FFFFFF;
  text-align: center;
  width: 100%;
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence-text {
    margin-top:  40px;
    width:  1277.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence-text {
    margin-top:  2.0833333333333335vw;
    width:  66.52777777777779vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence-text {
    margin-top:  2.4193548387096775vw;
    width:  77.25806451612902vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-text {
    margin-top:  9.375vw;
    width:  299.375vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-text {
    width: auto;
    text-align: start;
    font-size: 4.375vw;
  }
}
.CompanyGlobalPresence-linksWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence-linksWrapper {
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence-linksWrapper {
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence-linksWrapper {
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-linksWrapper {
    margin-top:  18.75vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-linksWrapper {
    display: none;
  }
}
.CompanyGlobalPresence-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .CompanyGlobalPresence-link:hover,
.isNotDesktop .CompanyGlobalPresence-link:active {
  text-decoration: underline;
}
.isDesktop .CompanyGlobalPresence-link:hover:before,
.isNotDesktop .CompanyGlobalPresence-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CompanyGlobalPresence-link:hover:before,
  .isNotDesktop .CompanyGlobalPresence-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-link {
    letter-spacing: 0;
  }
}
.CompanyGlobalPresence-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CompanyGlobalPresence-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence-link:nth-child(2) {
    margin-left:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence-link:nth-child(2) {
    margin-left:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence-link:nth-child(2) {
    margin-left:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-link:nth-child(2) {
    margin-left:  9.375vw;
  }
}
.CompanyGlobalPresence-link:visited {
  color: #FFFFFF;
}
.CompanyGlobalPresence-factsWrapper {
  width: 100%;
}
@media (min-width: 1920px) {
  .CompanyGlobalPresence-factsWrapper {
    margin-top:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyGlobalPresence-factsWrapper {
    margin-top:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyGlobalPresence-factsWrapper {
    margin-top:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-factsWrapper {
    margin-top:  43.75vw;
  }
}
@media (max-width: 759px) {
  .CompanyGlobalPresence-factsWrapper {
    margin-top: 15.625vw;
  }
}
