.ImprintPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 25;
  -webkit-transition: visibility 0.3s step-start;
  transition: visibility 0.3s step-start;
}
@media (max-width: 759px) {
  .ImprintPopup {
    top: 12.5vw;
    z-index: 1000;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup {
    top: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup {
    top: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup {
    top: 106.66666667px;
  }
}
.ImprintPopup--hide {
  visibility: hidden;
  -webkit-transition: visibility 0.3s step-end;
  transition: visibility 0.3s step-end;
}
@media (max-width: 759px) {
  .ImprintPopup--hide {
    position: fixed;
  }
}
.ImprintPopup--hide .ImprintPopup-bg {
  opacity: 0;
}
.ImprintPopup--hide .ImprintPopup-inputWrapper {
  opacity: 0;
  -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
  -webkit-transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
}
@media (max-width: 759px) {
  .ImprintPopup--hide .ImprintPopup-inputWrapper {
    -webkit-transform: translateY(6.25vw);
        -ms-transform: translateY(6.25vw);
            transform: translateY(6.25vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup--hide .ImprintPopup-inputWrapper {
    -webkit-transform: translateY(3.22580645vw);
        -ms-transform: translateY(3.22580645vw);
            transform: translateY(3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup--hide .ImprintPopup-inputWrapper {
    -webkit-transform: translateY(2.77777778vw);
        -ms-transform: translateY(2.77777778vw);
            transform: translateY(2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .ImprintPopup--hide .ImprintPopup-inputWrapper {
    -webkit-transform: translateY(53.33333333px);
        -ms-transform: translateY(53.33333333px);
            transform: translateY(53.33333333px);
  }
}
.ImprintPopup--hide .ImprintPopup-close {
  opacity: 0;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
.ImprintPopup-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(21, 25, 40, 0.5);
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-bg {
    top: -6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-bg {
    top: -5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-bg {
    top: -106.66666667px;
  }
}
.ImprintPopup-inputWrapper {
  position: relative;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  background: #FFFFFF;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .ImprintPopup-inputWrapper {
    -webkit-box-shadow: none;
            box-shadow: none;
    overflow-y: scroll;
  }
}
.ImprintPopup-mainWrapper {
  margin: 0 auto;
}
@media (max-width: 759px) {
  .ImprintPopup-mainWrapper {
    padding: 12.5vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-mainWrapper {
    width: 61.29032258vw;
    margin: 8.46774194vw auto 10.08064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-mainWrapper {
    width: 52.77777778vw;
    margin: 7.29166667vw auto 8.68055556vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-mainWrapper {
    width: 1013.33333333px;
    margin: 140px auto 166.66666667px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-title {
    margin-bottom: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-title {
    margin-bottom: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-title {
    margin-bottom: 106.66666667px;
  }
}
@media (max-width: 759px) {
  .ImprintPopup-text {
    margin-top: 10.9375vw;
    margin-bottom: 23.4375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-text {
    margin-bottom: 8.46774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-text {
    margin-bottom: 7.29166667vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-text {
    margin-bottom: 140px;
  }
}
.ImprintPopup-text p {
  color: #6F7380;
}
.ImprintPopup-text span {
  color: #6F7380;
  font-weight: 600;
}
.ImprintPopup-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ImprintPopup-text a:hover,
.isNotDesktop .ImprintPopup-text a:active {
  text-decoration: none;
  cursor: pointer;
}
.ImprintPopup-text a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ImprintPopup-footer {
  background: #F6F6F6;
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .ImprintPopup-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-footer {
    height: 16.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-footer {
    height: 13.88888889vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-footer {
    height: 266.66666667px;
  }
}
.ImprintPopup-footerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
}
@media (max-width: 759px) {
  .ImprintPopup-footerWrapper {
    display: block;
    padding: 12.5vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-footerWrapper {
    width: 61.29032258vw;
    height: 16.12903226vw;
    padding-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-footerWrapper {
    width: 52.77777778vw;
    height: 13.88888889vw;
    padding-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-footerWrapper {
    width: 1013.33333333px;
    height: 266.66666667px;
    padding-top: 53.33333333px;
  }
}
.ImprintPopup-footerAddress span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .ImprintPopup-footerAddress {
    margin-top: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-footerAddress {
    margin-left: 9.27419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-footerAddress {
    margin-left: 7.98611111vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-footerAddress {
    margin-left: 153.33333333px;
  }
}
@media (max-width: 759px) {
  .ImprintPopup-footerContacts {
    margin-top: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-footerContacts {
    margin-left: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-footerContacts {
    margin-left: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-footerContacts {
    margin-left: 186.66666667px;
  }
}
.ImprintPopup-footerContacts span {
  display: block;
  color: #6F7380;
}
.ImprintPopup-footerContacts a {
  display: block;
  text-decoration: underline;
}
.isDesktop .ImprintPopup-footerContacts a:hover,
.isNotDesktop .ImprintPopup-footerContacts a:active {
  text-decoration: none;
}
.ImprintPopup-footerContacts a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.ImprintPopup-close {
  position: fixed;
  float: right;
  cursor: pointer;
  z-index: 99;
  opacity: 1;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
@media (max-width: 759px) {
  .isDesktop .ImprintPopup-close:hover .ImprintPopup-closeItem,
  .isNotDesktop .ImprintPopup-close:active .ImprintPopup-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ImprintPopup-close:hover .ImprintPopup-closeItem,
  .isNotDesktop .ImprintPopup-close:active .ImprintPopup-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ImprintPopup-close:hover .ImprintPopup-closeItem,
  .isNotDesktop .ImprintPopup-close:active .ImprintPopup-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .ImprintPopup-close:hover .ImprintPopup-closeItem,
  .isNotDesktop .ImprintPopup-close:active .ImprintPopup-closeItem {
    width: 42.66666667px;
  }
}
@media (max-width: 759px) {
  .ImprintPopup-close {
    position: fixed;
    top: 14.0625vw;
    right: 1.5625vw;
    width: 8.125vw;
    height: 8.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-close {
    width: 3.06451613vw;
    height: 3.06451613vw;
    right: 0.56451613vw;
    top: 8.46774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-close {
    width: 2.63888889vw;
    height: 2.63888889vw;
    right: 0.48611111vw;
    top: 7.29166667vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-close {
    width: 50.66666667px;
    height: 50.66666667px;
    right: 9.33333333px;
    top: 140px;
  }
}
.ImprintPopup-closeItem {
  position: absolute;
  height: 2px;
  background: #111112;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.ImprintPopup-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.ImprintPopup-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (max-width: 759px) {
  .ImprintPopup-closeItem {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-closeItem {
    width: 2.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-closeItem {
    width: 1.80555556vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-closeItem {
    width: 34.66666667px;
  }
}
.ImprintPopup-tabContent {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-tabContent {
    width: 45.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-tabContent {
    width: 38.88888889vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-tabContent {
    width: 746.66666667px;
  }
}
.ImprintPopup-tabContentWrapper {
  position: absolute;
  -webkit-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}
.ImprintPopup-tabContentWrapper.active {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.ImprintPopup-searchResultWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-searchResultWrapper {
    width: 77.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-searchResultWrapper {
    width: 66.66666667vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-searchResultWrapper {
    width: 1280px;
  }
}
.ImprintPopup-accordionWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .ImprintPopup-accordionWrapper {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-accordionWrapper {
    width: 22.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-accordionWrapper {
    width: 19.44444444vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-accordionWrapper {
    width: 373.33333333px;
  }
}
.ImprintPopup-accordion,
.ImprintPopup-accordion * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ImprintPopup-accordionSectionContentLink {
  text-decoration: none;
  color: #111112;
  display: block;
  position: relative;
}
@media (max-width: 759px) {
  .ImprintPopup-accordionSectionContentLink {
    margin-top: 2.5vw;
    padding: 2.5vw 3.75vw 1.5625vw 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-accordionSectionContentLink {
    margin-top: 0.64516129vw;
    padding: 0.64516129vw 0.80645161vw 0.3251821vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-accordionSectionContentLink {
    margin-top: 0.55555556vw;
    padding: 0.55555556vw 0.69444444vw 0.24112654vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-accordionSectionContentLink {
    margin-top: 10.66666667px;
    padding: 10.66666667px 13.33333333px 88.88888889px;
  }
}
.ImprintPopup-accordionSectionContentLink.active {
  font-weight: normal;
  background-color: #111112;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ImprintPopup-accordionSectionContentLink.active {
    width: 93.75vw;
    border-radius: 15.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-accordionSectionContentLink.active {
    border-radius: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-accordionSectionContentLink.active {
    border-radius: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-accordionSectionContentLink.active {
    border-radius: 66.66666667px;
  }
}
.ImprintPopup-accordionSectionContentLink:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #C6C6C6;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-accordionSectionContentLink:not(:last-child):after {
    width: 20.96774194vw;
    bottom: -0.40322581vw;
    left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-accordionSectionContentLink:not(:last-child):after {
    width: 18.05555556vw;
    bottom: -0.34722222vw;
    left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-accordionSectionContentLink:not(:last-child):after {
    width: 346.66666667px;
    bottom: -6.66666667px;
    left: 13.33333333px;
  }
}
.ImprintPopup-accordionSectionContentLink span {
  color: #6F7380;
  pointer-events: none;
}
.ImprintPopup-mobileAnchors {
  display: none;
}
@media (max-width: 759px) {
  .ImprintPopup-mobileAnchors {
    display: block;
    padding: 6.25vw;
    background-color: #F6F6F6;
  }
}
.ImprintPopup-mobileAnchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  position: relative;
  padding: 4.6875vw 0 4.6875vw;
  border-bottom: 1px solid #C6C6C6;
}
.ImprintPopup-mobileAnchor:last-of-type {
  border-bottom: none;
}
.ImprintPopup-mobileAnchorIcon {
  width: 6.25vw;
  height: 6.25vw;
  position: relative;
}
.ImprintPopup-mobileAnchorIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ImprintPopup-mobileAnchorArrow {
  width: 5.9375vw;
  height: 3.75vw;
  position: absolute;
  right: 0;
}
.ImprintPopup-mobileAnchorArrow svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ImprintPopup-mobileAnchorTitle {
  line-height: normal;
}
@media (max-width: 759px) {
  .ImprintPopup-miniTitle {
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-miniTitle {
    margin-bottom: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-miniTitle {
    margin-bottom: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-miniTitle {
    margin-bottom: 40px;
  }
}
.ImprintPopup-textBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ImprintPopup-textBlock:not(:last-child) {
  border-bottom: 1px solid #C6C6C6;
}
@media (max-width: 759px) {
  .ImprintPopup-textBlock {
    display: block;
    padding-bottom: 5vw;
    margin-bottom: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-textBlock {
    padding-bottom: 0.80645161vw;
    margin-bottom: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-textBlock {
    padding-bottom: 0.69444444vw;
    margin-bottom: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-textBlock {
    padding-bottom: 13.33333333px;
    margin-bottom: 13.33333333px;
  }
}
.ImprintPopup-leftText {
  display: block;
  font-weight: 600 !important;
}
@media (max-width: 759px) {
  .ImprintPopup-leftText {
    font-size: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-leftText {
    width: 22.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-leftText {
    width: 19.44444444vw;
  }
}
@media (min-width: 1920px) {
  .ImprintPopup-leftText {
    width: 373.33333333px;
  }
}
.ImprintPopup-rightTextWrapper a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ImprintPopup-rightTextWrapper a:hover,
.isNotDesktop .ImprintPopup-rightTextWrapper a:active {
  text-decoration: none;
}
.ImprintPopup-rightText {
  display: block;
  font-weight: 400 !important;
}
@media (min-width: 1920px) {
  .ImprintPopup-rightText {
    line-height:  26.666666666666664px;
    width:  613.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImprintPopup-rightText {
    line-height:  1.3888888888888888vw;
    width:  31.944444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImprintPopup-rightText {
    line-height:  1.6129032258064515vw;
    width:  37.096774193548384vw;
  }
}
@media (max-width: 759px) {
  .ImprintPopup-rightText {
    font-size:  4.375vw;
    line-height:  6.25vw;
  }
}
.ImprintPopup-rightText .rich-text p {
  margin-bottom: 0;
}
