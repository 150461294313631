.ProductFamilyFeatures {
  position: relative;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures {
    height:  1066.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures {
    height:  55.55555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures {
    height:  64.51612903225806vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures {
    height:  250vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures {
    height: auto;
  }
}
.ProductFamilyFeatures-wrapper {
  position: relative;
}
.ProductFamilyFeatures-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #E7E7E7;
  top: -2px;
  left: 0;
  z-index: 1;
}
.ProductFamilyFeatures-wrapper,
.ProductFamilyFeatures-wrapperInner,
.ProductFamilyFeatures .slick-list,
.ProductFamilyFeatures .slick-track,
.ProductFamilyFeatures .slick-slide > div {
  height: 100%;
}
.ProductFamilyFeatures-arrowLeft,
.ProductFamilyFeatures-arrowRight {
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  cursor: pointer;
  z-index: 5;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-arrowLeft,
  .ProductFamilyFeatures-arrowRight {
    width:  26.666666666666664px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-arrowLeft,
  .ProductFamilyFeatures-arrowRight {
    width:  1.3888888888888888vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-arrowLeft,
  .ProductFamilyFeatures-arrowRight {
    width:  1.6129032258064515vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-arrowLeft,
  .ProductFamilyFeatures-arrowRight {
    width:  3.125vw;
    height:  6.25vw;
  }
}
.isMobile .ProductFamilyFeatures-arrowLeft,
.isMobile .ProductFamilyFeatures-arrowRight {
  display: none;
}
.ProductFamilyFeatures-arrowLeft svg,
.ProductFamilyFeatures-arrowRight svg {
  width: 100%;
  height: 100%;
  stroke-width: 4;
  -webkit-transition: 0.15s stroke;
  transition: 0.15s stroke;
}
.ProductFamilyFeatures-arrowLeft.dark svg,
.ProductFamilyFeatures-arrowRight.dark svg {
  stroke: #111112;
}
.ProductFamilyFeatures-arrowLeft.dark:hover svg,
.ProductFamilyFeatures-arrowRight.dark:hover svg {
  stroke: rgba(17, 17, 18, 0.5);
}
.ProductFamilyFeatures-arrowLeft.light svg,
.ProductFamilyFeatures-arrowRight.light svg {
  stroke: #FFFFFF;
}
.ProductFamilyFeatures-arrowLeft.light:hover svg,
.ProductFamilyFeatures-arrowRight.light:hover svg {
  stroke: rgba(255, 255, 255, 0.5);
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-arrowRight {
    right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-arrowRight {
    right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-arrowRight {
    right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-arrowRight {
    right:  1.5625vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-arrowLeft {
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-arrowLeft {
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-arrowLeft {
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-arrowLeft {
    left:  1.5625vw;
  }
}
.ProductFamilyFeatures-feature {
  position: relative;
  height: 100%;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-feature {
    padding:  53.33333333333333px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-feature {
    padding:  2.7777777777777777vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-feature {
    padding:  3.225806451612903vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-feature {
    padding:  12.5vw 43.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-feature {
    padding: 0;
  }
}
.ProductFamilyFeatures-feature.dark {
  background-color: #FFFFFF;
}
.ProductFamilyFeatures-feature.light {
  background-color: #111112;
}
.ProductFamilyFeatures-featureImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.ProductFamilyFeatures-featureImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureImage {
    position: relative;
    width: 100%;
    height: auto;
  }
  .ProductFamilyFeatures-featureImage img {
    position: relative;
  }
}
.ProductFamilyFeatures-featureContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-featureContent {
    width:  480px;
    padding:  53.33333333333333px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-featureContent {
    width:  25vw;
    padding:  2.7777777777777777vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-featureContent {
    width:  29.032258064516128vw;
    padding:  3.225806451612903vw 0vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureContent {
    padding:  0vw 6.25vw 12.5vw;
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureContent {
    position: relative;
    width: 100%;
  }
}
.ProductFamilyFeatures-featureContent.dark .ProductFamilyFeatures-featureTitle {
  color: #111112;
}
.ProductFamilyFeatures-featureContent.dark .ProductFamilyFeatures-featureDescription {
  color: #6F7380;
}
.ProductFamilyFeatures-featureContent.light .ProductFamilyFeatures-featureTitle {
  color: #FFFFFF;
}
.ProductFamilyFeatures-featureContent.light .ProductFamilyFeatures-featureDescription {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-featureContent.right {
    right:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-featureContent.right {
    right:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-featureContent.right {
    right:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureContent.right {
    right:  43.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureContent.right {
    right: auto;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-featureContent.left {
    left:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-featureContent.left {
    left:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-featureContent.left {
    left:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureContent.left {
    left:  43.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureContent.left {
    left: auto;
  }
}
.slick-slide-next .ProductFamilyFeatures-featureContent {
  opacity: 0;
  -webkit-transform: translate3d(20%, 0, 0);
          transform: translate3d(20%, 0, 0);
}
.slick-slide-prev .ProductFamilyFeatures-featureContent {
  opacity: 0;
  -webkit-transform: translate3d(-20%, 0, 0);
          transform: translate3d(-20%, 0, 0);
}
.slick-slide-current .ProductFamilyFeatures-featureContent {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.5s opacity, 0.5s -webkit-transform;
  transition: 0.5s transform, 0.5s opacity;
  transition: 0.5s transform, 0.5s opacity, 0.5s -webkit-transform;
}
.ProductFamilyFeatures-featureDescription {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-featureDescription ul,
  .ProductFamilyFeatures-featureDescription ol {
    margin-left:  26.666666666666664px;
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-featureDescription ul,
  .ProductFamilyFeatures-featureDescription ol {
    margin-left:  1.3888888888888888vw;
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-featureDescription ul,
  .ProductFamilyFeatures-featureDescription ol {
    margin-left:  1.6129032258064515vw;
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureDescription ul,
  .ProductFamilyFeatures-featureDescription ol {
    margin-left:  6.25vw;
    margin-top:  3.125vw;
  }
}
.ProductFamilyFeatures-featureDescription ul {
  list-style: disc;
}
.ProductFamilyFeatures-featureDescription ol {
  list-style: decimal;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-featureDescription {
    margin-top:  65.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-featureDescription {
    margin-top:  3.402777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-featureDescription {
    margin-top:  3.9516129032258065vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-featureDescription {
    margin-top:  8.4375vw;
  }
}
.ProductFamilyFeatures-navLines {
  position: absolute;
  left: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-navLines {
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-navLines {
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-navLines {
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-navLines {
    bottom:  6.25vw;
  }
}
.ProductFamilyFeatures-navLine {
  cursor: pointer;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-navLine {
    width:  77.33333333333333px;
    height:  5.333333333333333px;
    margin-left:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-navLine {
    width:  4.027777777777778vw;
    height:  0.2777777777777778vw;
    margin-left:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-navLine {
    width:  4.67741935483871vw;
    height:  0.3225806451612903vw;
    margin-left:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-navLine {
    width:  5.625vw;
    height:  1.25vw;
    margin-left:  0.625vw;
  }
}
.ProductFamilyFeatures-navLine:first-child {
  margin-left: 0;
}
.ProductFamilyFeatures-navLine.dark .ProductFamilyFeatures-navLineDisplay {
  background-color: rgba(0, 0, 0, 0.2);
}
.ProductFamilyFeatures-navLine.light .ProductFamilyFeatures-navLineDisplay {
  background-color: rgba(255, 255, 255, 0.2);
}
.ProductFamilyFeatures-navLine.active.dark .ProductFamilyFeatures-navLineDisplay {
  background-color: #111112;
}
.ProductFamilyFeatures-navLine.active.light .ProductFamilyFeatures-navLineDisplay {
  background-color: #FFFFFF;
}
.ProductFamilyFeatures-navLine.active .ProductFamilyFeatures-navLineDisplay {
  height: 100%;
}
@media (min-width: 1920px) {
  .ProductFamilyFeatures-navLineDisplay {
    height:  1.3333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyFeatures-navLineDisplay {
    height:  0.06944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyFeatures-navLineDisplay {
    height:  0.08064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyFeatures-navLineDisplay {
    height:  0.3125vw;
  }
}
