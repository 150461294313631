.RadialBackgrounds {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 759px) {
  .RadialBackgrounds {
    height: 70vh;
    width: 100vw;
  }
}
.RadialBackgrounds div {
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
