.InvestorsFinancialTable {
  width: 100%;
}
.InvestorsFinancialTable-tables {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  overflow-x: visible;
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-tables {
    overflow-x: auto;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-tables {
    margin-top:  2.8125vw;
  }
}
.InvestorsFinancialTable-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.InvestorsFinancialTable-table:first-child {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .InvestorsFinancialTable-table {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-table {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-table {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-table {
    margin-top:  12.5vw;
    width:  206.25vw;
  }
}
.InvestorsFinancialTable-tableRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #E7E7E7;
}
.InvestorsFinancialTable-tableRow:first-child {
  border-top: none;
  padding-top: 0;
}
.InvestorsFinancialTable-tableRow:last-child {
  padding-bottom: 0;
}
@media (min-width: 1920px) {
  .InvestorsFinancialTable-tableRow {
    padding:  13.333333333333332px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-tableRow {
    padding:  0.6944444444444444vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-tableRow {
    padding:  0.8064516129032258vw 0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-tableRow {
    padding:  3.125vw 0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-tableRow {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.InvestorsFinancialTable-tableRowTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #6F7380;
  width: 34.21052632%;
  margin-right: 5.26315789%;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-tableRowTitle {
    margin-right: 3.55263158%;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-tableRowTitle {
    width: 21.21212121%;
    margin-right: 6.06060606%;
    font-size: 3.75vw;
    line-height: 1.66666667;
    letter-spacing: -0.24px;
  }
}
.InvestorsFinancialTable-tableRowTitle.htRight {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.InvestorsFinancialTable-tableRowTitle.htCenter {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.InvestorsFinancialTable-tableRowItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #111112;
  width: 13.15789474%;
}
.InvestorsFinancialTable-tableRowItem:last-child {
  width: 7.89473684%;
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-tableRowItem {
    width: 15.15151515%;
    font-size: 3.75vw;
    line-height: 1.66666667;
    letter-spacing: -0.24px;
  }
}
.InvestorsFinancialTable-tableRowItem.htRight {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.InvestorsFinancialTable-tableRowItem.htCenter {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.InvestorsFinancialTable-link {
  color: #111112;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: normal;
  white-space: nowrap;
  padding-top: 0;
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    letter-spacing: -0.28px;
    padding-top: 3.125vw;
  }
  .isDesktop .InvestorsFinancialTable-link:hover,
  .isNotDesktop .InvestorsFinancialTable-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialTable-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/svg/product-download-icon.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialTable-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    width: 8.125vw;
    height: 6.25vw;
    background-size: 8.125vw 6.25vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 4.03225806vw;
  }
  .isDesktop .InvestorsFinancialTable-link:hover,
  .isNotDesktop .InvestorsFinancialTable-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialTable-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialTable-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    letter-spacing: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 3.47222222vw;
  }
  .isDesktop .InvestorsFinancialTable-link:hover,
  .isNotDesktop .InvestorsFinancialTable-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialTable-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialTable-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    letter-spacing: 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialTable-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 66.66666667px;
  }
  .isDesktop .InvestorsFinancialTable-link:hover,
  .isNotDesktop .InvestorsFinancialTable-link:active {
    text-decoration: underline;
  }
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .InvestorsFinancialTable-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .InvestorsFinancialTable-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 1920px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1920px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialTable-link {
    letter-spacing: 0;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 1920px) and (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1920px) and (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) and (min-width: 1920px) {
  .InvestorsFinancialTable-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.isDesktop .InvestorsFinancialTable-link:hover,
.isNotDesktop .InvestorsFinancialTable-link:active {
  cursor: pointer;
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-link:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (max-width: 759px) {
  .isDesktop .InvestorsFinancialTable-link:hover:before,
  .isNotDesktop .InvestorsFinancialTable-link:active:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (min-width: 1920px) {
  .InvestorsFinancialTable-link {
    margin-top:  66.66666666666666px;
    padding-top:  0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsFinancialTable-link {
    margin-top:  3.4722222222222223vw;
    padding-top:  0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsFinancialTable-link {
    margin-top:  4.032258064516129vw;
    padding-top:  0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsFinancialTable-link {
    margin-top:  10.9375vw;
    padding-top:  0vw;
  }
}
