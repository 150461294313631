.ProductFamilyHead {
  width: 100%;
  height: 80vh;
  position: relative;
}
@media (max-width: 759px) {
  .ProductFamilyHead {
    margin-top: 12.5vw;
    height: auto;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead {
    min-height: 40.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead {
    min-height: 34.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyHead {
    min-height: 666.66666667px;
  }
}
.ProductFamilyHead-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .ProductFamilyHead-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.ProductFamilyHead-bg {
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  z-index: -1;
}
.ProductFamilyHead-bg:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  bottom: 0;
  height: 37.5%;
  opacity: 0.5;
  z-index: 200;
}
@media (max-width: 759px) {
  .ProductFamilyHead-bg {
    position: relative;
    height: 55vw;
  }
}
.ProductFamilyHead-bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  opacity: 1;
  -webkit-transition: 0.8s opacity ease;
  transition: 0.8s opacity ease;
}
.ProductFamilyHead-bg img.fade-out {
  opacity: 0;
  -webkit-transition: 0.8s opacity ease;
  transition: 0.8s opacity ease;
}
.ProductFamilyHead-bg img.fade-in {
  opacity: 0;
  -webkit-transition: none;
  transition: none;
}
@media (max-width: 759px) {
  .ProductFamilyHead-bg img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: 'object-fit: cover; object-position: center';
  }
}
.ProductFamilyHead-titleWrapper {
  z-index: 1;
  width: 100%;
}
@media (min-width: 1920px) {
  .ProductFamilyHead-titleWrapper {
    padding:  0 186.66666666666666px;
    margin-bottom:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-titleWrapper {
    padding:  0vw 9.722222222222223vw;
    margin-bottom:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-titleWrapper {
    padding:  0vw 11.29032258064516vw;
    margin-bottom:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyHead-titleWrapper {
    padding:  0vw 43.75vw;
    margin-bottom:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyHead-titleWrapper {
    position: absolute;
    top: 40.625vw;
    left: 6.25vw;
    right: 6.25vw;
    padding: 0;
    margin-bottom: 0;
    width: auto;
    z-index: 10;
  }
}
.ProductFamilyHead-title {
  color: #FFFFFF;
  width: 75%;
}
@media (max-width: 759px) {
  .ProductFamilyHead-title {
    width: auto;
  }
}
.ProductFamilyHead-subtitle {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyHead-subtitle {
    margin-top: 12.5vw;
    color: #111112;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-subtitle {
    width: 20.96774194vw;
    margin-top: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-subtitle {
    width: 18.05555556vw;
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyHead-subtitle {
    width: 346.66666667px;
    margin-top: 26.66666667px;
  }
}
.ProductFamilyHead-description {
  position: absolute;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  left: 0;
  right: 0;
}
@media (max-width: 759px) {
  .ProductFamilyHead-description {
    position: relative;
    padding: 37.5vw 6.25vw 10.3125vw;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-description {
    bottom: 4.35483871vw;
    padding: 0 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-description {
    bottom: 3.75vw;
    padding: 0 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyHead-description {
    bottom: 72px;
    padding: 0 186.66666667px;
  }
}
.ProductFamilyHead-descriptionBg {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyHead-descriptionBg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    background-color: #F6F6F6;
  }
}
.ProductFamilyHead-descriptionItem {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
@media (max-width: 759px) {
  .ProductFamilyHead-descriptionItem {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
            flex: 0 0 40%;
    margin-top: 10.9375vw;
    padding-top: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-descriptionItem {
    padding-top: 2.01612903vw;
    margin: 0 1.61290323vw;
    width: 20.96774194vw;
  }
  .ProductFamilyHead-descriptionItem:nth-child(2) {
    margin-left: 0;
  }
  .ProductFamilyHead-descriptionItem:last-of-type {
    margin-right: 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-descriptionItem {
    width: 18.05555556vw;
    padding-top: 1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyHead-descriptionItem {
    width: 346.66666667px;
    padding-top: 33.33333333px;
  }
}
.ProductFamilyHead-descriptionItemTitle {
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .ProductFamilyHead-descriptionItemTitle {
    color: #111112;
  }
}
.ProductFamilyHead-descriptionItemDescription {
  color: #FFFFFF;
  text-transform: none;
}
@media (max-width: 759px) {
  .ProductFamilyHead-descriptionItemDescription {
    color: #111112;
  }
}
.ProductFamilyHead-descriptionSubtitleMobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyHead-descriptionSubtitleMobile {
    display: block;
    color: #FFFFFF;
    z-index: 1;
    padding: 7.5vw 6.25vw;
    width: 100%;
  }
}
.ProductFamilyHead-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyHead-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyHead-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductFamilyHead-link:hover,
.isNotDesktop .ProductFamilyHead-link:active {
  text-decoration: underline;
}
.isDesktop .ProductFamilyHead-link:hover:before,
.isNotDesktop .ProductFamilyHead-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyHead-link:hover:before,
  .isNotDesktop .ProductFamilyHead-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductFamilyHead-link {
    letter-spacing: 0;
  }
}
.ProductFamilyHead-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .ProductFamilyHead-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductFamilyHead-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyHead-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductFamilyHead-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.ProductFamilyHead-link:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .ProductFamilyHead-link {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-link {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-link {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyHead-link {
    margin-top:  1.5625vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyHead-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    color: #111112;
  }
  .isDesktop .ProductFamilyHead-link:hover,
  .isNotDesktop .ProductFamilyHead-link:active {
    text-decoration: underline;
  }
  .isDesktop .ProductFamilyHead-link:hover:before,
  .isNotDesktop .ProductFamilyHead-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .ProductFamilyHead-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .ProductFamilyHead-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
  .ProductFamilyHead-link:visited {
    color: #111112;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyHead-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-link {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-link {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ProductFamilyHead-link {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .ProductFamilyHead-link:hover:before,
  .isNotDesktop .ProductFamilyHead-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyHead-link {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyHead-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyHead-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyHead-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyHead-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ProductFamilyHead-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
