@media (max-width: 759px) {
  .LatestFinancialReports-titleWrapper {
    padding: 0 6.25vw;
  }
}
.LatestFinancialReports-title {
  position: relative;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .LatestFinancialReports-title {
    padding:  46.666666666666664px 0 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-title {
    padding:  2.430555555555556vw 0vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-title {
    padding:  2.82258064516129vw 0vw 0vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-title {
    padding:  10.9375vw 0vw 0vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-title {
    padding-top: 3.125vw;
  }
}
.LatestFinancialReports-title:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 1px;
  background-color: #FFFFFF;
}
.LatestFinancialReports-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 1920px) {
  .LatestFinancialReports-cards {
    margin-top:  53.33333333333333px;
    margin-left:  -26.666666666666664px;
    margin-right:  -26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-cards {
    margin-top:  2.7777777777777777vw;
    margin-left:  -1.3888888888888888vw;
    margin-right:  -1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-cards {
    margin-top:  3.225806451612903vw;
    margin-left:  -1.6129032258064515vw;
    margin-right:  -1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cards {
    margin-top:  12.5vw;
    margin-left:  -6.25vw;
    margin-right:  -6.25vw;
  }
}
@media (min-width: 1920px) {
  .LatestFinancialReports-cards .DownloadCard {
    margin:  0 26.666666666666664px;
    width:  453.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-cards .DownloadCard {
    margin:  0vw 1.3888888888888888vw;
    width:  23.61111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-cards .DownloadCard {
    width:  22.58064516129032vw;
    margin:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cards .DownloadCard {
    margin:  0vw 6.25vw;
    width:  106.25vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cards {
    display: none;
  }
}
.LatestFinancialReports-cardsMobile {
  display: none;
  position: relative;
}
@media (min-width: 1920px) {
  .LatestFinancialReports-cardsMobile {
    margin-top:  37.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-cardsMobile {
    margin-top:  1.9444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-cardsMobile {
    margin-top:  2.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cardsMobile {
    margin-top:  8.75vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cardsMobile {
    display: block;
  }
}
.LatestFinancialReports-cardsMobileInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  will-change: transform;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
@media (min-width: 1920px) {
  .LatestFinancialReports-cardsMobileInner {
    margin-left:  -13.333333333333332px;
    margin-right:  -13.333333333333332px;
    padding-left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-cardsMobileInner {
    margin-left:  -0.6944444444444444vw;
    margin-right:  -0.6944444444444444vw;
    padding-left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-cardsMobileInner {
    margin-left:  -0.8064516129032258vw;
    margin-right:  -0.8064516129032258vw;
    padding-left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cardsMobileInner {
    margin-left:  -3.125vw;
    margin-right:  -3.125vw;
    padding-left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .LatestFinancialReports-cardsMobileInner .DownloadCard {
    width:  373.3333333333333px;
    margin-right:  16px;
    margin-left:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-cardsMobileInner .DownloadCard {
    width:  19.444444444444446vw;
    margin-right:  0.8333333333333334vw;
    margin-left:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-cardsMobileInner .DownloadCard {
    width:  22.58064516129032vw;
    margin-right:  0.967741935483871vw;
    margin-left:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-cardsMobileInner .DownloadCard {
    width:  87.5vw;
    margin-right:  3.75vw;
    margin-left:  3.75vw;
  }
}
.LatestFinancialReports-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .LatestFinancialReports-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .LatestFinancialReports-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .LatestFinancialReports-link:hover,
.isNotDesktop .LatestFinancialReports-link:active {
  text-decoration: underline;
}
.isDesktop .LatestFinancialReports-link:hover:before,
.isNotDesktop .LatestFinancialReports-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .LatestFinancialReports-link:hover:before,
  .isNotDesktop .LatestFinancialReports-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-link {
    letter-spacing: 0;
  }
}
.LatestFinancialReports-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .LatestFinancialReports-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .LatestFinancialReports-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .LatestFinancialReports-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .LatestFinancialReports-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.LatestFinancialReports-link:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .LatestFinancialReports-link {
    margin-top:  60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-link {
    margin-top:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-link {
    margin-top:  3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-link {
    margin-top:  14.0625vw;
  }
}
@media (max-width: 759px) {
  .LatestFinancialReports-link {
    margin-left: 6.25vw;
  }
}
.LatestFinancialReports-navLines {
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none;
}
@media (max-width: 759px) {
  .LatestFinancialReports-navLines {
    bottom: -10.9375vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
  }
  .isDesktop .LatestFinancialReports-navLines {
    width: 96.25vw;
  }
}
.LatestFinancialReports-navLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .LatestFinancialReports-navLine {
    height: 9.375vw;
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-navLine {
    height: 2.41935484vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-navLine {
    height: 2.08333333vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .LatestFinancialReports-navLine {
    height: 40px;
    width: 77.33333333px;
  }
}
.isDesktop .LatestFinancialReports-navLine:hover:after,
.isNotDesktop .LatestFinancialReports-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .LatestFinancialReports-navLine:hover:after,
  .isNotDesktop .LatestFinancialReports-navLine:active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .LatestFinancialReports-navLine:hover:after,
  .isNotDesktop .LatestFinancialReports-navLine:active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .LatestFinancialReports-navLine:hover:after,
  .isNotDesktop .LatestFinancialReports-navLine:active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .LatestFinancialReports-navLine:hover:after,
  .isNotDesktop .LatestFinancialReports-navLine:active:after {
    height: 5.33333333px;
  }
}
.LatestFinancialReports-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.LatestFinancialReports-navLine.active {
  pointer-events: none;
}
.LatestFinancialReports-navLine.active:after {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .LatestFinancialReports-navLine.active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LatestFinancialReports-navLine.active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LatestFinancialReports-navLine.active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .LatestFinancialReports-navLine.active:after {
    height: 5.33333333px;
  }
}
.LatestFinancialReports-navLine:first-child {
  margin-left: 0;
}
