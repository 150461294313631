.consent-vimeo .Video.vimeo .Video-consent-overlay {
  display: none;
}
.consent-youtube .Video.youtube .Video-consent-overlay {
  display: none;
}
.no-consent-vimeo .Video.vimeo .Video-consent-overlay-inner {
  visibility: visible;
}
.no-consent-youtube .Video.youtube .Video-consent-overlay-inner {
  visibility: visible;
}
.Video {
  position: relative;
  cursor: pointer;
}
@media (min-width: 1920px) {
  .Video {
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Video {
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Video {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .Video {
    margin-top:  9.375vw;
  }
}
.Video-cover {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (min-width: 1920px) {
  .Video-cover {
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Video-cover {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Video-cover {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Video-cover {
    -webkit-box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
            box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
  }
}
.Video-cover img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
  will-change: transform;
  -webkit-transition: 0.15s -webkit-transform ease;
  transition: 0.15s -webkit-transform ease;
  transition: 0.15s transform ease;
  transition: 0.15s transform ease, 0.15s -webkit-transform ease;
}
.Video-title {
  color: #111112;
  line-height: 1;
}
@media (min-width: 1920px) {
  .Video-title {
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Video-title {
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Video-title {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Video-title {
    margin-top:  3.125vw;
    padding-left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .no-padding .Video-title {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .no-padding .Video-title {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .no-padding .Video-title {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .no-padding .Video-title {
    padding:  0vw 6.25vw;
  }
}
.Video-duration {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .Video-duration {
    margin-top:  8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Video-duration {
    margin-top:  0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Video-duration {
    margin-top:  0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .Video-duration {
    margin-top:  3.125vw;
    padding-left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .no-padding .Video-duration {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .no-padding .Video-duration {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .no-padding .Video-duration {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .no-padding .Video-duration {
    padding:  0vw 6.25vw;
  }
}
.Video-playButton {
  background-color: #FFFFFF;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .Video-playButton {
    width:  80px;
    height:  53.33333333333333px;
    bottom:  26.666666666666664px;
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Video-playButton {
    width:  4.166666666666667vw;
    height:  2.7777777777777777vw;
    bottom:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Video-playButton {
    width:  4.838709677419355vw;
    height:  3.225806451612903vw;
    bottom:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .Video-playButton {
    width:  12.5vw;
    height:  8.125vw;
    bottom:  6.25vw;
    left:  6.25vw;
  }
}
.Video-playButton svg {
  width: 50%;
  height: 50%;
}
.isDesktop .Video:hover .Video-cover,
.isMobile .Video:active .Video-cover {
  color: rgba(0, 0, 0, 0.25);
}
.isDesktop .Video:hover .Video-cover img,
.isMobile .Video:active .Video-cover img {
  -webkit-transform: scale(1.15) translate3d(0, 0, 0);
          transform: scale(1.15) translate3d(0, 0, 0);
  will-change: transform;
}
.Video-player {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
@media (max-width: 759px) {
  .Video-player.show {
    display: block;
  }
}
.Video-consent-overlay {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  top: 0;
  color: #000;
  text-decoration: none;
  cursor: auto;
  overflow: visible;
}
@media (max-width: 759px) {
  .Video-consent-overlay {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Video-consent-overlay {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Video-consent-overlay {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .Video-consent-overlay {
    font-size: 26.66666667px;
  }
}
.Video-consent-overlay-inner {
  visibility: hidden;
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 1.5em;
  background-color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 759px) {
  .Video-consent-overlay-inner {
    font-size: 3.75vw;
  }
}
.Video-consent-overlay-inner-title {
  font-weight: bold;
  color: #111112;
  margin-bottom: 1em;
}
.Video-consent-overlay-inner-text {
  font-size: 0.8em;
  color: #111112;
  margin-bottom: 1.2em;
}
.Video-consent-overlay-inner-buttons {
  margin: -0.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Video-consent-overlay-inner-buttons button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0.5em;
  border-width: 1px;
  border-style: solid;
  padding: 0.5em;
  font-size: 0.7em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.4em;
}
.Video-consent-overlay-inner-buttons-more {
  border-color: #4a4d55;
  background-color: #4a4d55;
  color: #ffffff;
}
.Video-consent-overlay-inner-buttons-accept {
  border-color: #d4d4d4;
  background-color: #ffffff;
  color: #111112;
}
.Video.vimeo .Video-consent-overlay-inner-title.youtube {
  display: none;
}
.Video.youtube .Video-consent-overlay-inner-title.vimeo {
  display: none;
}
