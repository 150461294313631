.DownloadCards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (min-width: 1920px) {
  .DownloadCards {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCards {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCards {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .DownloadCards {
    margin-top:  0vw;
  }
}
.DownloadCards:first-child {
  margin-top: 0;
}
@media (max-width: 759px) {
  .DownloadCards {
    display: block;
  }
}
.DownloadCards-downloads {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.DownloadCards .DownloadCard {
  width: 47.36842105%;
}
.DownloadCards .DownloadCard:first-child {
  margin-top: 0;
}
@media (min-width: 1920px) {
  .DownloadCards .DownloadCard:nth-child(n + 3) {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadCards .DownloadCard:nth-child(n + 3) {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadCards .DownloadCard:nth-child(n + 3) {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .DownloadCards .DownloadCard:nth-child(n + 3) {
    margin-top:  12.5vw;
  }
}
.DownloadCards .DownloadCard:nth-child(2n) {
  margin-left: 5.26315789%;
}
@media (max-width: 759px) {
  .DownloadCards .DownloadCard:nth-child(2n) {
    margin-left: 0;
    margin-top: 12.5vw;
  }
}
@media (max-width: 759px) {
  .DownloadCards .DownloadCard {
    width: 100%;
  }
}
