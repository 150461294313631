.PageCompanyHistory .History {
  min-height: 0;
}
@media (min-width: 1920px) {
  .PageCompanyHistory .History-content {
    padding-top:  106.66666666666666px;
    padding-bottom:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyHistory .History-content {
    padding-top:  5.555555555555555vw;
    padding-bottom:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyHistory .History-content {
    padding-top:  6.451612903225806vw;
    padding-bottom:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyHistory .History-content {
    padding-top:  12.5vw;
    padding-bottom:  0vw;
  }
}
.PageCompanyHistory .History-objects {
  margin-top: 0;
}
@media (max-width: 759px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom {
    margin: 0;
    width: 100%;
    border-top: 0;
    position: relative;
  }
  .PageCompanyHistory .PageGlobalTemplate-block_custom:before {
    content: '';
    top: 0;
    height: 1px;
    background-color: #111112;
    position: absolute;
  }
  .PageCompanyHistory .PageGlobalTemplate-block_custom .GlobalSlider-arrowLeft_mobile,
  .PageCompanyHistory .PageGlobalTemplate-block_custom .GlobalSlider-arrowRight_mobile {
    display: none !important;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom:before {
    left:  26.666666666666664px;
    width:  373.3333333333333px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom:before {
    left:  1.3888888888888888vw;
    width:  19.444444444444446vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom:before {
    left:  1.6129032258064515vw;
    width:  22.58064516129032vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom:before {
    left:  6.25vw;
    width:  87.5vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom .PageGlobalTemplate-blockTitle {
    padding:  0 26.666666666666664px;
    margin-bottom:  0;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom .PageGlobalTemplate-blockTitle {
    padding:  0vw 1.3888888888888888vw;
    margin-bottom:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom .PageGlobalTemplate-blockTitle {
    padding:  0vw 1.6129032258064515vw;
    margin-bottom:  0vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageCompanyHistory .PageGlobalTemplate-block_custom .PageGlobalTemplate-blockTitle {
    padding:  0vw 6.25vw;
    margin-bottom:  0vw;
  }
}
