.CareersLandingAreas {
  padding-top: 40vh;
}
@media (min-width: 1920px) {
  .CareersLandingAreas {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas {
    padding:  0vw 6.25vw;
  }
}
.CareersLandingAreas-title {
  width: 100%;
  color: #FFFFFF;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersLandingAreas-title {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-title {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-title {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas-title {
    padding-top:  4.6875vw;
  }
}
.CareersLandingAreas-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .CareersLandingAreas-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CareersLandingAreas-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .CareersLandingAreas-link:hover,
.isNotDesktop .CareersLandingAreas-link:active {
  text-decoration: underline;
}
.isDesktop .CareersLandingAreas-link:hover:before,
.isNotDesktop .CareersLandingAreas-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CareersLandingAreas-link:hover:before,
  .isNotDesktop .CareersLandingAreas-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas-link {
    letter-spacing: 0;
  }
}
.CareersLandingAreas-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .CareersLandingAreas-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CareersLandingAreas-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CareersLandingAreas-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CareersLandingAreas-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CareersLandingAreas-link:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersLandingAreas-link {
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-link {
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-link {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas-link {
    margin-top:  7.8125vw;
  }
}
.CareersLandingAreas-areas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 1920px) {
  .CareersLandingAreas-areas {
    margin:  46.666666666666664px -13.333333333333332px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-areas {
    margin:  2.430555555555556vw -0.6944444444444444vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-areas {
    margin:  2.82258064516129vw -0.8064516129032258vw 0vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas-areas {
    margin:  10.9375vw -3.125vw 0vw;
  }
}
.CareersLandingAreas-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
  color: #FFFFFF;
  text-decoration: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.CareersLandingAreas-area:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersLandingAreas-area {
    border-radius:  53.33333333333333px;
    padding:  0 40px;
    margin:  0 13.333333333333332px 26.666666666666664px;
    max-width:  426.66666666666663px;
    min-width:  160px;
    height:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-area {
    border-radius:  2.7777777777777777vw;
    padding:  0vw 2.0833333333333335vw;
    margin:  0vw 0.6944444444444444vw 1.3888888888888888vw;
    max-width:  22.22222222222222vw;
    min-width:  8.333333333333334vw;
    height:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-area {
    border-radius:  3.225806451612903vw;
    padding:  0vw 1.6129032258064515vw;
    margin:  0vw 0.8064516129032258vw 1.6129032258064515vw;
    max-width:  22.58064516129032vw;
    min-width:  8.064516129032258vw;
    height:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas-area {
    border-radius:  12.5vw;
    padding:  0vw 6.25vw;
    margin:  0vw 3.125vw 6.25vw;
    max-width:  87.5vw;
    min-width:  31.25vw;
    height:  12.5vw;
  }
}
.CareersLandingAreas-area:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #000000;
  opacity: 0.4;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .CareersLandingAreas-area:after {
    border-radius:  53.33333333333333px;
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersLandingAreas-area:after {
    border-radius:  2.7777777777777777vw;
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersLandingAreas-area:after {
    border-radius:  3.225806451612903vw;
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CareersLandingAreas-area:after {
    border-radius:  12.5vw;
    -webkit-box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
            box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
  }
}
.isDesktop .CareersLandingAreas-area:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.isDesktop .CareersLandingAreas-area:hover:after {
  opacity: 0.6;
}
