.ProductsCategory {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
.ProductsCategory-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .ProductsCategory-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.ProductsCategory-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.isIE .ProductsCategory-category {
  position: absolute;
  left: 0;
}
.isIE .ProductsCategory-category.js-is-stuck {
  bottom: 0;
}
@media (max-width: 759px) {
  .ProductsCategory-category {
    width: 100%;
    position: relative;
  }
}
.ProductsCategory-categoryWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}
.js-is-stuck .ProductsCategory-categoryWrapper {
  margin-top: auto;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryWrapper {
    min-height: 0;
    height: 75vh;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryWrapper {
    min-height: 46.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryWrapper {
    min-height: 40.27777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryWrapper {
    min-height: 773.33333333px;
  }
}
.ProductsCategory-categoryImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #111112;
  overflow: hidden;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryImg.isBlur img {
    -webkit-filter: blur(3.125vw) !important;
            filter: blur(3.125vw) !important;
  }
}
.ProductsCategory-categoryImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.3s -webkit-filter;
  transition: 0.3s -webkit-filter;
  transition: 0.3s filter;
  transition: 0.3s filter, 0.3s -webkit-filter;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryImg img {
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categorySlider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categorySliderWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .isDesktop .ProductsCategory-categorySliderWrapper {
    position: relative;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categorySliderInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-transition: 0.5s -webkit-transform;
    transition: 0.5s -webkit-transform;
    transition: 0.5s transform;
    transition: 0.5s transform, 0.5s -webkit-transform;
  }
}
@media (min-width: 1920px), (min-width: 1240px) and (max-width: 1919px), (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categorySliderInner {
    -webkit-transform: none !important;
        -ms-transform: none !important;
            transform: none !important;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categorySliderItem {
    padding: 0 6.25vw;
    position: relative;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.ProductsCategory-categorySliderNav {
  display: none;
  position: absolute;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 50%;
  width: 70%;
  z-index: 15;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media (max-width: 759px) {
  .ProductsCategory-categorySliderNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categorySliderNav {
    bottom: 0.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categorySliderNav {
    bottom: 0.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categorySliderNav {
    bottom: 10.66666667px;
  }
}
.ProductsCategory-categorySliderNavItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  cursor: pointer;
  position: relative;
}
.ProductsCategory-categorySliderNavItem:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
}
.ProductsCategory-categorySliderNavItem.active {
  pointer-events: none;
}
.ProductsCategory-categorySliderNavItem.active:after {
  height: 4px;
  background: #FFFFFF;
}
.ProductsCategory-categorySliderNavItem:first-child {
  margin-left: 0;
}
@media (max-width: 759px) {
  .ProductsCategory-categorySliderNavItem {
    width: 11.875vw;
    height: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categorySliderNavItem {
    width: 3.06451613vw;
    height: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categorySliderNavItem {
    width: 2.63888889vw;
    height: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categorySliderNavItem {
    width: 50.66666667px;
    height: 40px;
  }
}
.ProductsCategory-categoryInfoTitle {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoTitle {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoTitle {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoTitle {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoTitle {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .ProductsCategory-categoryInfoTitle {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoTitle {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
    margin-top: 14.0625vw;
    top: 0;
    left: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoTitle {
    padding-left: 6.4516129vw;
    padding-right: 4.83870968vw;
    margin-top: 7.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoTitle {
    padding-left: 9.72222222vw;
    padding-right: 4.16666667vw;
    margin-top: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoTitle {
    padding-left: 186.66666667px;
    padding-right: 80px;
    margin-top: 120px;
  }
}
.ProductsCategory-categoryInfoTitleCat {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  letter-spacing: 0;
  color: #FFFFFF;
  display: none;
  position: relative;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoTitleCat {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoTitleCat {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoTitleCat {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoTitleCat {
    font-size: 16px;
  }
}
.brand--thorn .ProductsCategory-categoryInfoTitleCat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ProductsCategory-categoryInfoLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoLinks {
    margin-top:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoLinks {
    margin-top:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoLinks {
    margin-top:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLinks {
    margin-top:  10.625vw;
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLinks {
    width: auto;
    margin-top: 9.375vw;
  }
  .brand--thorn .ProductsCategory-categoryInfoLinks {
    margin-top: 20.3125vw;
  }
}
.ProductsCategory-categoryInfoLinksWrapper {
  width: 100%;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoLinksWrapper {
    padding-left: 6.4516129vw;
    padding-right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoLinksWrapper {
    padding-left: 9.72222222vw;
    padding-right: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoLinksWrapper {
    padding-left: 186.66666667px;
    padding-right: 80px;
  }
}
.ProductsCategory-categoryInfoLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoLink {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 13.33333333px);
            flex: 0 0 calc(50% - 13.33333333px);
    max-width: calc(50% - 13.33333333px);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoLink {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 0.80645161vw);
            flex: 0 0 calc(50% - 0.80645161vw);
    max-width: calc(50% - 0.80645161vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoLink {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 0.80645161vw);
            flex: 0 0 calc(50% - 0.80645161vw);
    max-width: calc(50% - 0.80645161vw);
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink {
    width: 100%;
  }
}
.ProductsCategory-categoryInfoLink a {
  width: 100%;
}
.ProductsCategory-categoryInfoLink:nth-child(2) {
  border-top: 0;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink:nth-child(2) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink:nth-child(2n) {
    margin-left: 0;
  }
}
.ProductsCategory-categoryInfoLink:first-child {
  border-top: 0;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink:first-child {
    border-top: 0;
  }
}
.ProductsCategory-categoryInfoLink a {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  letter-spacing: -0.02857143em;
  color: #FFFFFF;
  padding-right: 2px;
  line-height: 2.85714286;
  white-space: nowrap;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink a {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoLink a {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoLink a {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoLink a {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductsCategory-categoryInfoLink a:hover,
.isNotDesktop .ProductsCategory-categoryInfoLink a:active {
  text-decoration: underline;
}
.isDesktop .ProductsCategory-categoryInfoLink a:hover:before,
.isNotDesktop .ProductsCategory-categoryInfoLink a:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductsCategory-categoryInfoLink a:hover:before,
  .isNotDesktop .ProductsCategory-categoryInfoLink a:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink a {
    letter-spacing: 0;
  }
}
.ProductsCategory-categoryInfoLink a:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink a:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductsCategory-categoryInfoLink a:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryInfoLink a:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryInfoLink a:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryInfoLink a:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryInfoLink a:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.ProductsCategory-categoryDescr {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescr {
    top: 0;
    margin-top: 15.625vw;
    padding: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .brand--thorn .ProductsCategory-categoryDescr {
    margin-top: 21.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryDescr {
    left: 6.4516129vw;
    width: 37.09677419vw;
    bottom: 10.48387097vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryDescr {
    width: 31.94444444vw;
    bottom: 9.02777778vw;
    left: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryDescr {
    width: 613.33333333px;
    bottom: 173.33333333px;
    left: 186.66666667px;
  }
}
.ProductsCategory-categoryDescrText {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #FFFFFF;
  text-transform: none;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescrText {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryDescrText {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryDescrText {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryDescrText {
    font-size: 26.66666667px;
  }
}
.ProductsCategory-categoryDescrLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescrLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryDescrLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryDescrLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryDescrLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductsCategory-categoryDescrLink:hover,
.isNotDesktop .ProductsCategory-categoryDescrLink:active {
  text-decoration: underline;
}
.isDesktop .ProductsCategory-categoryDescrLink:hover:before,
.isNotDesktop .ProductsCategory-categoryDescrLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductsCategory-categoryDescrLink:hover:before,
  .isNotDesktop .ProductsCategory-categoryDescrLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescrLink {
    letter-spacing: 0;
  }
}
.ProductsCategory-categoryDescrLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescrLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductsCategory-categoryDescrLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescrLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryDescrLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryDescrLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryDescrLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductsCategory-categoryDescrLink {
    margin-top: 15.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductsCategory-categoryDescrLink {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductsCategory-categoryDescrLink {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductsCategory-categoryDescrLink {
    margin-top: 40px;
  }
}
.ProductsCategory-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
}
.isIE .ProductsCategory-items {
  width: 100%;
  padding-left: 50%;
  min-height: 100vh;
}
@media (max-width: 759px) {
  .ProductsCategory-items {
    width: 100%;
  }
}
.ProductsCategory-itemsWrapper {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductsCategory-item,
.ProductsCategory-extraItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 33.33333333%;
  border: 1px solid transparent;
}
@media (max-width: 759px) {
  .ProductsCategory-item,
  .ProductsCategory-extraItem {
    width: 50%;
  }
}
.ProductsSections-sectionsFilteredItems .ProductsCategory-item,
.ProductsSections-sectionsFilteredItems .ProductsCategory-extraItem {
  width: 16.66666667%;
}
@media (max-width: 759px) {
  .ProductsSections-sectionsFilteredItems .ProductsCategory-item,
  .ProductsSections-sectionsFilteredItems .ProductsCategory-extraItem {
    width: 50%;
  }
}
.isInitialState .ProductsCategory-item,
.isInitialState .ProductsCategory-extraItem {
  opacity: 0;
}
