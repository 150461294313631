.SearchResultPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 20;
  -webkit-transition: visibility 0.3s step-start;
  transition: visibility 0.3s step-start;
}
.OverlayShow .SearchResultPopup {
  z-index: 30;
}
@media (max-width: 759px) {
  .SearchResultPopup {
    position: absolute;
    top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup {
    top: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup {
    top: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup {
    top: 106.66666667px;
  }
}
.SearchResultPopup--withcontent .SearchResultPopup-searchResultText {
  border: 1px solid #111112;
}
.SearchResultPopup--withcontent .SearchResultPopup-icon svg circle {
  stroke: #111112;
}
.SearchResultPopup--withcontent .SearchResultPopup-icon svg path {
  fill: #111112;
}
.SearchResultPopup-emptyResults {
  width: 100%;
}
@media (max-width: 759px) {
  .SearchResultPopup-emptyResults {
    padding: 0 6.25vw 37.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-emptyResults {
    width: 53.22580645vw;
    margin-top: -0.56451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-emptyResults {
    width: 45.83333333vw;
    margin-top: -0.48611111vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-emptyResults {
    width: 880px;
    margin-top: -9.33333333px;
  }
}
.SearchResultPopup-emptyResultsText {
  color: #111112;
}
.SearchResultPopup-emptyResultsDescription {
  color: #6F7380;
}
.SearchResultPopup--hide {
  visibility: hidden;
  -webkit-transition: visibility 0.3s step-end;
  transition: visibility 0.3s step-end;
}
@media (max-width: 759px) {
  .SearchResultPopup--hide {
    position: fixed;
  }
}
.SearchResultPopup--hide .SearchResultPopup-bg {
  opacity: 0;
}
.SearchResultPopup--hide .SearchResultPopup-inputWrapper {
  opacity: 0;
  -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
  -webkit-transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
}
@media (max-width: 759px) {
  .SearchResultPopup--hide .SearchResultPopup-inputWrapper {
    -webkit-transform: translateY(12.5vw);
        -ms-transform: translateY(12.5vw);
            transform: translateY(12.5vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup--hide .SearchResultPopup-inputWrapper {
    -webkit-transform: translateY(3.22580645vw);
        -ms-transform: translateY(3.22580645vw);
            transform: translateY(3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup--hide .SearchResultPopup-inputWrapper {
    -webkit-transform: translateY(2.77777778vw);
        -ms-transform: translateY(2.77777778vw);
            transform: translateY(2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup--hide .SearchResultPopup-inputWrapper {
    -webkit-transform: translateY(53.33333333px);
        -ms-transform: translateY(53.33333333px);
            transform: translateY(53.33333333px);
  }
}
.SearchResultPopup--hide .SearchResultPopup-close {
  opacity: 0;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
.SearchResultPopup-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(21, 25, 40, 0.5);
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
}
@media (max-width: 759px) {
  .SearchResultPopup-bg {
    position: fixed;
    background: #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-bg {
    top: -6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-bg {
    top: -5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-bg {
    top: -106.66666667px;
  }
}
.SearchResultPopup-inputWrapper {
  position: relative;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  background: #FFFFFF;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .SearchResultPopup-inputWrapper {
    -webkit-box-shadow: none;
            box-shadow: none;
    overflow-y: scroll;
  }
}
.SearchResultPopup-borders {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  right: 0;
  border: 6px solid white;
  overflow: hidden;
}
.SearchResultPopup-searchResultTextWrapper {
  background: #F6F6F6;
  position: relative;
}
@media (max-width: 759px) {
  .SearchResultPopup-searchResultTextWrapper {
    height: 25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-searchResultTextWrapper {
    height: 12.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-searchResultTextWrapper {
    height: 11.11111111vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-searchResultTextWrapper {
    height: 213.33333333px;
  }
}
.SearchResultPopup-searchResultText {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .SearchResultPopup-searchResultText {
    width: 87.5vw;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-searchResultText {
    width: 77.41935484vw;
    height: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-searchResultText {
    width: 66.66666667vw;
    height: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-searchResultText {
    width: 1280px;
    height: 106.66666667px;
  }
}
.SearchResultPopup-searchResultText input {
  display: inline-block;
  vertical-align: middle;
  font-size: 55px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
@media (max-width: 759px) {
  .SearchResultPopup-searchResultText input {
    font-size: 5.625vw;
    line-height: 1.8;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-searchResultText input {
    font-size: 4.43548387vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-searchResultText input {
    font-size: 3.81944444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-searchResultText input {
    font-size: 73.33333333px;
  }
}
.SearchResultPopup-searchResultText input::-webkit-input-placeholder {
  color: #A6A8B3;
}
.SearchResultPopup-searchResultText input:-moz-placeholder {
  color: #A6A8B3;
}
.SearchResultPopup-searchResultText input::-moz-placeholder {
  color: #A6A8B3;
}
.SearchResultPopup-searchResultText input:-ms-input-placeholder {
  color: #A6A8B3;
}
.SearchResultPopup-searchResultText input::-webkit-input-placeholder {
  color: #A6A8B3;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.SearchResultPopup-searchResultText input::-moz-placeholder {
  color: #A6A8B3;
  opacity: 1;
  -moz-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.SearchResultPopup-searchResultText input:-ms-input-placeholder {
  color: #A6A8B3;
  -ms-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.SearchResultPopup-searchResultText input:focus::-webkit-input-placeholder {
  color: transparent;
}
.SearchResultPopup-searchResultText input:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.SearchResultPopup-searchResultText input:focus:-ms-input-placeholder {
  color: transparent;
}
.SearchResultPopup-icon {
  position: relative;
  display: inline-block;
}
@media (max-width: 759px) {
  .SearchResultPopup-icon {
    margin-left: 6.25vw;
    width: 12.5vw;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-icon {
    margin-left: 1.61290323vw;
    margin-top: 1.69354839vw;
    width: 3.22580645vw;
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-icon {
    margin-left: 1.38888889vw;
    margin-top: 1.45833333vw;
    width: 2.77777778vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-icon {
    margin-left: 26.66666667px;
    margin-top: 28px;
    width: 53.33333333px;
    height: 53.33333333px;
  }
}
.SearchResultPopup-icon svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.SearchResultPopup-icon svg circle {
  stroke: #A6A8B3;
}
.SearchResultPopup-icon svg path {
  fill: #A6A8B3;
}
@media (max-width: 759px) {
  .SearchResultPopup-icon svg path {
    stroke: #111112;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-icon {
    display: none;
  }
}
.SearchResultPopup-icon-mobile {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopup-icon-mobile {
    display: block;
    position: absolute;
    top: 3.125vw;
    left: 3.125vw;
    width: 6.875vw;
    height: 6.875vw;
    background-size: 6.875vw 6.875vw;
    background-image: url('/static/svg/icon-search-dark.svg');
  }
}
.SearchResultPopup-inputSearch {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  line-height: 1.5;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  border: none;
  outline: none;
  text-transform: none;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
@media (min-width: 1920px) {
  .SearchResultPopup-inputSearch {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-inputSearch {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-inputSearch {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-inputSearch {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .SearchResultPopup-inputSearch {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-inputSearch {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    letter-spacing: -0.04666667em;
    line-height: 1;
    text-transform: uppercase;
    text-transform: none;
    top: 0.3125vw;
    left: 12.5vw;
    width: calc(100% - 21.875vw);
    height: 11.25vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopup-inputSearch {
    font-size:  40px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-inputSearch {
    font-size:  2.0833333333333335vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-inputSearch {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopup-inputSearch {
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-inputSearch {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-inputSearch {
    bottom: -0.40322581vw;
    left: 6.4516129vw;
    width: calc(100% - 14.51612903vw);
    height: 7.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-inputSearch {
    bottom: -0.34722222vw;
    left: 5.55555556vw;
    width: calc(100% - 12.5vw);
    height: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-inputSearch {
    bottom: -6.66666667px;
    left: 106.66666667px;
    width: calc(100% - 240px);
    height: 120px;
  }
}
.SearchResultPopup-tip {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  display: inline;
  pointer-events: none;
  color: #111112;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
  line-height: 70px;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  border: none;
  outline: none;
  text-transform: none;
}
@media (min-width: 1920px) {
  .SearchResultPopup-tip {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-tip {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-tip {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-tip {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .SearchResultPopup-tip {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-tip {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    letter-spacing: -0.04666667em;
    line-height: 1;
    text-transform: uppercase;
    text-transform: none;
    margin-left: 14.0625vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopup-tip {
    font-size:  40px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-tip {
    font-size:  2.0833333333333335vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-tip {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopup-tip {
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-tip {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-tip {
    margin-left: 1.29032258vw;
    line-height: 5.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-tip {
    margin-left: 1.11111111vw;
    line-height: 4.86111111vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-tip {
    margin-left: 21.33333333px;
    line-height: 93.33333333px;
  }
}
.SearchResultPopup-close {
  position: fixed;
  float: right;
  cursor: pointer;
  z-index: 99;
  opacity: 1;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopup-close:hover .SearchResultPopup-closeItem,
  .isNotDesktop .SearchResultPopup-close:active .SearchResultPopup-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .SearchResultPopup-close:hover .SearchResultPopup-closeItem,
  .isNotDesktop .SearchResultPopup-close:active .SearchResultPopup-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .SearchResultPopup-close:hover .SearchResultPopup-closeItem,
  .isNotDesktop .SearchResultPopup-close:active .SearchResultPopup-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .SearchResultPopup-close:hover .SearchResultPopup-closeItem,
  .isNotDesktop .SearchResultPopup-close:active .SearchResultPopup-closeItem {
    width: 42.66666667px;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-close {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-close {
    width: 3.06451613vw;
    height: 3.06451613vw;
    right: 1.0483871vw;
    top: 8.14516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-close {
    width: 2.63888889vw;
    height: 2.63888889vw;
    right: 0.90277778vw;
    top: 7.01388889vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-close {
    width: 50.66666667px;
    height: 50.66666667px;
    right: 17.33333333px;
    top: 134.66666667px;
  }
}
.SearchResultPopup-closeItem {
  position: absolute;
  height: 2px;
  background: #111112;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.SearchResultPopup-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.SearchResultPopup-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (max-width: 759px) {
  .SearchResultPopup-closeItem {
    width: 8.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-closeItem {
    width: 2.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-closeItem {
    width: 1.80555556vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-closeItem {
    width: 34.66666667px;
  }
}
.SearchResultPopup-tabContent {
  width: 100%;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-tabContent {
    width: 53.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-tabContent {
    width: 45.83333333vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-tabContent {
    width: 880px;
  }
}
.SearchResultPopup-tabContentWrapper {
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  height: 0;
  position: relative;
}
.SearchResultPopup-tabContentWrapper.active {
  opacity: 1;
  height: auto;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopup-tabContentWrapper {
    padding: 0 6.25vw;
  }
}
.SearchResultPopup-inspirationWrapper {
  display: none;
}
.SearchResultPopup-searchResultWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .SearchResultPopup-searchResultWrapper {
    margin-top:  106.66666666666666px;
    width:  1280px;
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-searchResultWrapper {
    margin-top:  5.555555555555555vw;
    width:  66.66666666666667vw;
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-searchResultWrapper {
    margin-top:  6.451612903225806vw;
    width:  77.41935483870968vw;
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-searchResultWrapper {
    margin-top:  25vw;
    width:  300vw;
    padding-bottom:  25vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-searchResultWrapper {
    display: block;
    width: 100%;
    margin-top: 0;
  }
}
.SearchResultPopup-accordionWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionWrapper {
    background: #F6F6F6;
    padding-top: 12.5vw;
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionWrapper {
    width: 22.58064516vw;
    margin-left: -0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionWrapper {
    width: 19.44444444vw;
    margin-left: -0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionWrapper {
    width: 373.33333333px;
    margin-left: -13.33333333px;
  }
}
.SearchResultPopup-allResultsCount {
  position: absolute;
  color: #6F7380;
}
@media (max-width: 759px) {
  .SearchResultPopup-allResultsCount {
    top: 1.875vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-allResultsCount {
    top: -2.66129032vw;
    left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-allResultsCount {
    top: -2.29166667vw;
    left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-allResultsCount {
    top: -44px;
    left: 13.33333333px;
  }
}
.SearchResultPopup-advice {
  position: absolute;
  color: #6F7380;
  text-transform: inherit;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
}
@media (max-width: 759px) {
  .SearchResultPopup-advice {
    position: relative;
    padding: 0 6.25vw 6.25vw;
    top: 1.875vw;
    font-size: 3.4375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-advice {
    top: -2.66129032vw;
    font-size: 1.12903226vw;
    margin-top: -0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-advice {
    top: -2.29166667vw;
    font-size: 0.97222222vw;
    margin-top: -0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-advice {
    top: -44px;
    font-size: 18.66666667px;
    margin-top: -6.66666667px;
  }
}
.SearchResultPopup-accordion,
.SearchResultPopup-accordion * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.SearchResultPopup-accordion {
  border-radius: 3px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordion {
    background: none;
  }
}
.SearchResultPopup-accordionIcon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionIcon {
    width: 6.25vw;
    height: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionIcon {
    width: 1.61290323vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionIcon {
    width: 1.38888889vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionIcon {
    width: 26.66666667px;
    height: 26.66666667px;
  }
}
.SearchResultPopup-accordionIconItem {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  top: 50%;
  height: 2px;
  background: #111112;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s -webkit-transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out;
  transition: 0.15s width ease-out, 0.15s opacity ease-out, 0.15s transform ease-out, 0.15s -webkit-transform ease-out;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionIconItem {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionIconItem {
    width: 1.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionIconItem {
    width: 1.25vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionIconItem {
    width: 24px;
  }
}
.SearchResultPopup-accordionIconItem:first-child {
  -webkit-transform: translate(-50%) rotate(90deg);
      -ms-transform: translate(-50%) rotate(90deg);
          transform: translate(-50%) rotate(90deg);
}
.SearchResultPopup-accordionSectionTitle {
  width: 100%;
  -webkit-transition: all linear 0.15s;
  transition: all linear 0.15s;
  opacity: 1;
  position: relative;
  color: #111112;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SearchResultPopup-accordionSectionTitle:after {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid #111112;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionSectionTitle {
    padding: 5.3125vw 6.25vw 5.3125vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionSectionTitle {
    padding: 1.53225806vw 0.80645161vw 1.69354839vw 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionSectionTitle {
    padding: 1.31944444vw 0.69444444vw 1.45833333vw 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionSectionTitle {
    padding: 25.33333333px 13.33333333px 28px 13.33333333px;
  }
}
.isDesktop .SearchResultPopup-accordionSectionTitle:hover,
.isNotDesktop .SearchResultPopup-accordionSectionTitle:active {
  color: #6F7380;
  text-decoration: none;
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopup-accordionSectionTitle:hover .SearchResultPopup-accordionIconItem,
  .isNotDesktop .SearchResultPopup-accordionSectionTitle:active .SearchResultPopup-accordionIconItem {
    width: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .SearchResultPopup-accordionSectionTitle:hover .SearchResultPopup-accordionIconItem,
  .isNotDesktop .SearchResultPopup-accordionSectionTitle:active .SearchResultPopup-accordionIconItem {
    width: 2.01612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .SearchResultPopup-accordionSectionTitle:hover .SearchResultPopup-accordionIconItem,
  .isNotDesktop .SearchResultPopup-accordionSectionTitle:active .SearchResultPopup-accordionIconItem {
    width: 1.73611111vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .SearchResultPopup-accordionSectionTitle:hover .SearchResultPopup-accordionIconItem,
  .isNotDesktop .SearchResultPopup-accordionSectionTitle:active .SearchResultPopup-accordionIconItem {
    width: 33.33333333px;
  }
}
.SearchResultPopup-accordionSectionTitle.active .SearchResultPopup-accordionIconItem:first-child {
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionSectionTitle.active {
    padding: 5.3125vw 6.25vw 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionSectionTitle.active {
    padding: 1.53225806vw 0.80645161vw 0 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionSectionTitle.active {
    padding: 1.31944444vw 0.69444444vw 0 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionSectionTitle.active {
    padding: 25.33333333px 13.33333333px 0 13.33333333px;
  }
}
.SearchResultPopup-accordionSectionTitleWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionSectionTitleWrapper {
    display: block;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    margin: auto 0;
  }
}
.SearchResultPopup-accordionSection:last-child-accordionSectionTitle {
  border-bottom: none;
}
.SearchResultPopup-accordionSectionContent {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionSectionContent {
    padding-bottom: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionSectionContent {
    padding-bottom: 1.61290323vw;
    padding-left: 1.61290323vw;
    padding-right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionSectionContent {
    padding-bottom: 1.38888889vw;
    padding-left: 1.38888889vw;
    padding-right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionSectionContent {
    padding-bottom: 26.66666667px;
    padding-left: 26.66666667px;
    padding-right: 26.66666667px;
  }
}
.SearchResultPopup-accordionSectionContentLink {
  text-decoration: none;
  color: #111112;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionSectionContentLink {
    margin: 1.875vw 0 1.5625vw 3.125vw;
    padding: 2.5vw 3.75vw 1.5625vw 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionSectionContentLink {
    margin: 0.80645161vw 0 0.80645161vw -1.53225806vw;
    padding: 0.64516129vw 0.80645161vw 0.40322581vw 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionSectionContentLink {
    margin: 0.69444444vw 0 0.69444444vw -1.31944444vw;
    padding: 0.55555556vw 0.69444444vw 0.34722222vw 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionSectionContentLink {
    margin: 13.33333333px 0 13.33333333px -25.33333333px;
    padding: 10.66666667px 13.33333333px 6.66666667px 13.33333333px;
  }
}
.SearchResultPopup-accordionSectionContentLink.active {
  font-weight: normal;
  background-color: #111112;
  color: #FFFFFF;
  width: 116%;
}
@media (max-width: 759px) {
  .SearchResultPopup-accordionSectionContentLink.active {
    width: 93.75vw;
    border-radius: 15.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-accordionSectionContentLink.active {
    border-radius: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-accordionSectionContentLink.active {
    border-radius: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-accordionSectionContentLink.active {
    border-radius: 66.66666667px;
  }
}
.isDesktop .SearchResultPopup-accordionSectionContentLink:hover,
.isNotDesktop .SearchResultPopup-accordionSectionContentLink:active {
  color: #6F7380;
}
.SearchResultPopup-accordionSectionContentLink span {
  color: #6F7380;
  pointer-events: none;
}
.SearchResultPopup-resultsWrapper {
  width: 100%;
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopup-results {
    margin-left: 6.25vw;
    margin-right: 6.25vw;
    padding-bottom: 0.3125vw;
  }
}
.SearchResultPopup-result {
  text-decoration: none;
  position: relative;
}
.SearchResultPopup-result:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
@media (max-width: 759px) {
  .SearchResultPopup-result:not(:last-child) {
    margin-bottom: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-result:not(:last-child) {
    margin-bottom: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-result:not(:last-child) {
    margin-bottom: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-result:not(:last-child) {
    margin-bottom: 26.66666667px;
  }
}
.SearchResultPopup-resultHref {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-decoration: none;
  position: relative;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultHref {
    height: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultHref {
    height: 12.90322581vw;
    margin-bottom: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultHref {
    height: 11.11111111vw;
    margin-bottom: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultHref {
    height: 213.33333333px;
    margin-bottom: 21.33333333px;
  }
}
.SearchResultPopup-resultHref:not(:last-child):before {
  content: '';
  width: 100%;
  position: absolute;
  bottom: -15px;
  border-bottom: 1px solid #C6C6C6;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultHref:not(:last-child):before {
    bottom: -4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultHref:not(:last-child):before {
    bottom: -1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultHref:not(:last-child):before {
    bottom: -1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultHref:not(:last-child):before {
    bottom: -20px;
  }
}
.isDesktop .SearchResultPopup-resultHref:hover .SearchResultPopup-resultArrow:before,
.isNotDesktop .SearchResultPopup-resultHref:active .SearchResultPopup-resultArrow:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopup-resultHref:hover .SearchResultPopup-resultArrow:before,
  .isNotDesktop .SearchResultPopup-resultHref:active .SearchResultPopup-resultArrow:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.isDesktop .SearchResultPopup-resultHref:hover .SearchResultPopup-resultImage img,
.isNotDesktop .SearchResultPopup-resultHref:active .SearchResultPopup-resultImage img {
  -webkit-transform: scale(1.3) translate3d(0, 0, 0);
          transform: scale(1.3) translate3d(0, 0, 0);
}
.SearchResultPopup-resultInfo {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultInfo {
    width: 57.1875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultInfo {
    width: 33.87096774vw;
    padding-left: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultInfo {
    width: 29.16666667vw;
    padding-left: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultInfo {
    width: 560px;
    padding-left: 53.33333333px;
  }
}
.SearchResultPopup-resultImage {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.SearchResultPopup-resultImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultImage {
    width: 25vw;
    height: 25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultImage {
    width: 12.90322581vw;
    height: 12.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultImage {
    width: 11.11111111vw;
    height: 11.11111111vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultImage {
    width: 213.33333333px;
    height: 213.33333333px;
  }
}
.SearchResultPopup-resultTitle {
  color: #111112;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultTitle {
    margin-top: 1.875vw;
    font-size: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultTitle {
    margin-top: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultTitle {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultTitle {
    margin-top: 26.66666667px;
  }
}
.SearchResultPopup-resultDescription {
  color: #6F7380;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultDescription {
    letter-spacing: -0.22px;
    margin-top: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultDescription {
    margin-top: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultDescription {
    margin-top: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultDescription {
    margin-top: 13.33333333px;
  }
}
.SearchResultPopup-resultArrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  right: 0;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultArrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultArrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultArrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .SearchResultPopup-resultArrow:hover,
.isNotDesktop .SearchResultPopup-resultArrow:active {
  text-decoration: underline;
}
.isDesktop .SearchResultPopup-resultArrow:hover:before,
.isNotDesktop .SearchResultPopup-resultArrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopup-resultArrow:hover:before,
  .isNotDesktop .SearchResultPopup-resultArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-resultArrow {
    letter-spacing: 0;
  }
}
.SearchResultPopup-resultArrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .SearchResultPopup-resultArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .SearchResultPopup-resultArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-resultArrow {
    margin-top: 2.5vw;
    right: -3.125vw;
    top: 0;
    line-height: normal;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-resultArrow {
    margin-left: auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-resultArrow {
    margin-left: auto;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-resultArrow {
    margin-left: auto;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-title {
    position: relative;
    width: 100%;
  }
}
.SearchResultPopup-paginationWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SearchResultPopup-page {
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  height: 0;
  display: none;
}
.SearchResultPopup-page.active {
  opacity: 1;
  height: auto;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopup-items {
    margin-left: 6.25vw;
    margin-right: 6.25vw;
  }
}
.SearchResultPopup-groupCompany {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompany {
    margin-left: 6.25vw;
    margin-right: 6.25vw;
  }
}
.SearchResultPopup-groupCompanyWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SearchResultPopup-groupCompanyWrapper:not(:last-child) {
  border-bottom: 1px solid rgba(198, 198, 198, 0.8);
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompanyWrapper {
    margin-bottom: 8.75vw;
  }
  .SearchResultPopup-groupCompanyWrapper:not(:last-child) {
    padding-bottom: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-groupCompanyWrapper {
    margin-bottom: 3.22580645vw;
  }
  .SearchResultPopup-groupCompanyWrapper:not(:last-child) {
    padding-bottom: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-groupCompanyWrapper {
    margin-bottom: 2.77777778vw;
  }
  .SearchResultPopup-groupCompanyWrapper:not(:last-child) {
    padding-bottom: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-groupCompanyWrapper {
    margin-bottom: 53.33333333px;
  }
  .SearchResultPopup-groupCompanyWrapper:not(:last-child) {
    padding-bottom: 40px;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompanyInfoWrapper {
    width: 100%;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-groupCompanyInfoWrapper {
    width: 37.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-groupCompanyInfoWrapper {
    width: 31.94444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-groupCompanyInfoWrapper {
    width: 613.33333333px;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompanyTitle {
    margin-bottom: 6.25vw;
    line-height: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-groupCompanyTitle {
    margin-bottom: 1.29032258vw;
    margin-top: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-groupCompanyTitle {
    margin-bottom: 1.11111111vw;
    margin-top: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-groupCompanyTitle {
    margin-bottom: 21.33333333px;
    margin-top: 6.66666667px;
  }
}
.SearchResultPopup-groupCompanyText {
  color: #6F7380;
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompanyText {
    margin-bottom: 6.25vw;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 12.5vw;
    line-height: 6.25vw;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-groupCompanyText {
    margin-bottom: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-groupCompanyText {
    margin-bottom: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-groupCompanyText {
    margin-bottom: 21.33333333px;
  }
}
.SearchResultPopup-groupCompanyText b {
  color: #111112;
}
.SearchResultPopup-groupCompanyLink {
  color: #111112;
  text-decoration: none;
}
.isDesktop .SearchResultPopup-groupCompanyLink:hover,
.isNotDesktop .SearchResultPopup-groupCompanyLink:active {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompanyLink {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media (max-width: 759px) {
  .SearchResultPopup-groupCompanyImage {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopup-groupCompanyImage {
    width: 12.90322581vw;
    height: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopup-groupCompanyImage {
    width: 11.11111111vw;
    height: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopup-groupCompanyImage {
    width: 213.33333333px;
    height: 133.33333333px;
  }
}
