.LayoutsFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #F6F6F6;
}
@media (max-width: 759px) {
  .LayoutsFooter {
    display: block;
    padding: 14.0625vw 0 14.0625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter {
    width: 61.29032258vw;
    margin: 0 auto;
    padding: 8.46774194vw 0 8.87096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter {
    width: 52.77777778vw;
    margin: 0 auto;
    padding: 7.29166667vw 0 7.63888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter {
    width: 1013.33333333;
    margin: 0 auto;
    padding: 140px 0 173.33333333px;
  }
}
.LayoutsFooter-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .LayoutsFooter-item:hover .LayoutsFooter-itemTitle,
.isNotDesktop .LayoutsFooter-item:active .LayoutsFooter-itemTitle {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .isDesktop .LayoutsFooter-item:hover .LayoutsFooter-itemTitle,
  .isNotDesktop .LayoutsFooter-item:active .LayoutsFooter-itemTitle {
    text-decoration: none;
  }
}
.isDesktop .LayoutsFooter-item:hover .LayoutsFooter-itemTitle:before,
.isNotDesktop .LayoutsFooter-item:active .LayoutsFooter-itemTitle:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .LayoutsFooter-item:hover .LayoutsFooter-itemTitle:before,
  .isNotDesktop .LayoutsFooter-item:active .LayoutsFooter-itemTitle:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (max-width: 759px) {
  .LayoutsFooter-item {
    margin-bottom: 15.625vw;
  }
  .LayoutsFooter-item:last-of-type {
    margin-bottom: 0;
    margin-left: 7px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-item:first-child {
    padding-right: 7.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-item:first-child {
    padding-right: 6.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-item:first-child {
    padding-right: 120px;
  }
}
.LayoutsFooter-itemText {
  display: block;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemText {
    margin-left: 11.875vw;
    margin-top: -1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemText {
    margin-left: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemText {
    margin-left: 2.43055556vw;
    width: 16.31944444vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemText {
    margin-left: 46.66666667px;
  }
}
.LayoutsFooter-itemIcon {
  position: relative;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemIcon {
    width: 19.6875vw;
    height: 17.1875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemIcon {
    width: 5.08064516vw;
    height: 7.66129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemIcon {
    width: 4.375vw;
    height: 6.59722222vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemIcon {
    width: 84px;
    height: 106.66666667px;
  }
}
.LayoutsFooter-itemIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.LayoutsFooter-itemTitle {
  color: #111112;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: normal;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemTitle {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemTitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemTitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemTitle {
    font-size: 18.66666667px;
  }
}
.isDesktop .LayoutsFooter-itemTitle:hover,
.isNotDesktop .LayoutsFooter-itemTitle:active {
  text-decoration: underline;
}
.isDesktop .LayoutsFooter-itemTitle:hover:before,
.isNotDesktop .LayoutsFooter-itemTitle:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .LayoutsFooter-itemTitle:hover:before,
  .isNotDesktop .LayoutsFooter-itemTitle:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .LayoutsFooter-itemTitle {
    letter-spacing: 0;
  }
}
.LayoutsFooter-itemTitle:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemTitle:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .LayoutsFooter-itemTitle:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemTitle:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemTitle:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemTitle:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemTitle:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .LayoutsFooter-itemTitle {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemTitle {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemTitle {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemTitle {
    font-size: 26.66666667px;
  }
}
.LayoutsFooter-itemDescription {
  color: #6F7380;
  text-transform: none;
  letter-spacing: -0.4px;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemDescription {
    margin-top: 0;
    line-height: 20px;
    letter-spacing: -0.22px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemDescription {
    margin-top: 0.80645161vw;
    font-size: 1.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemDescription {
    margin-top: 0.69444444vw;
    font-size: 1.25vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemDescription {
    margin-top: 13.33333333px;
    font-size: 24px;
  }
}
.LayoutsFooter-itemArrow {
  position: relative;
}
@media (max-width: 759px) {
  .LayoutsFooter-itemArrow {
    width: 7.8125vw;
    height: 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsFooter-itemArrow {
    width: 2.01612903vw;
    height: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsFooter-itemArrow {
    width: 1.73611111vw;
    height: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsFooter-itemArrow {
    width: 33.33333333px;
    height: 21.33333333px;
  }
}
.LayoutsFooter-itemArrow svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
