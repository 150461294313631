.Checkbox {
  position: relative;
  width: 100%;
}
.Checkbox > input {
  opacity: 0;
  position: absolute;
}
.Checkbox > input:checked + label:before {
  background: #111112;
  border-color: #111112;
}
.Checkbox > label {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-transform: none;
  color: #6F7380;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (max-width: 759px) {
  .Checkbox > label {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Checkbox > label {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Checkbox > label {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Checkbox > label {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .Checkbox > label {
    font-size: 3.75vw;
    line-height: 1;
    color: #111112;
  }
}
.isDesktop .Checkbox > label:hover,
.isNotDesktop .Checkbox > label:active {
  color: #111112;
}
.isDesktop .Checkbox > label:hover:before,
.isNotDesktop .Checkbox > label:active:before {
  border: 1px solid #111112;
}
.Checkbox > label:before {
  content: '';
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #6F7380;
  -webkit-box-shadow: inset 0 0 0 2px #FFFFFF;
          box-shadow: inset 0 0 0 2px #FFFFFF;
  -webkit-transition: 0.15s background ease-out, 0.15s border ease-out;
  transition: 0.15s background ease-out, 0.15s border ease-out;
}
@media (min-width: 1920px) {
  .Checkbox > label:before {
    width:  26.666666666666664px;
    height:  26.666666666666664px;
    margin-right:  13.333333333333332px;
    margin-top:  -2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Checkbox > label:before {
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
    margin-right:  0.6944444444444444vw;
    margin-top:  -0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Checkbox > label:before {
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
    margin-right:  0.8064516129032258vw;
    margin-top:  -0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .Checkbox > label:before {
    width:  6.25vw;
    height:  6.25vw;
    margin-right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .Checkbox > label:before {
    border: 1px solid #111112;
    -webkit-box-shadow: inset 0 0 0 2px #FFFFFF;
            box-shadow: inset 0 0 0 2px #FFFFFF;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 6.25vw;
            flex: 0 0 6.25vw;
  }
}
