.TitleCard {
  position: relative;
}
.isDesktop .TitleCard:hover .TitleCard-image {
  color: rgba(0, 0, 0, 0.25);
}
.isDesktop .TitleCard:hover .TitleCard-image img {
  -webkit-transform: translate3d(0, 0, 0) scale(1.05);
          transform: translate3d(0, 0, 0) scale(1.05);
}
.TitleCard-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.TitleCard-image {
  color: rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .TitleCard-image {
    -webkit-box-shadow:  0 6.666666666666666px 20px 6.666666666666666px;
            box-shadow:  0 6.666666666666666px 20px 6.666666666666666px;
    margin-top:  80px;
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TitleCard-image {
    -webkit-box-shadow:  0vw 0.3472222222222222vw 1.0416666666666667vw 0.3472222222222222vw;
            box-shadow:  0vw 0.3472222222222222vw 1.0416666666666667vw 0.3472222222222222vw;
    margin-top:  4.166666666666667vw;
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TitleCard-image {
    -webkit-box-shadow:  0vw 0.4032258064516129vw 1.2096774193548387vw 0.4032258064516129vw;
            box-shadow:  0vw 0.4032258064516129vw 1.2096774193548387vw 0.4032258064516129vw;
    margin-top:  4.838709677419355vw;
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .TitleCard-image {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
    margin-top:  18.75vw;
    margin-bottom:  6.25vw;
  }
}
.TitleCard-image img {
  width: 100%;
  min-height: 105%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 1920px) {
  .TitleCard-title {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TitleCard-title {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TitleCard-title {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .TitleCard-title {
    margin-top:  6.25vw;
  }
}
