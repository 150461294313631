.FaqPopup-content {
  background: #FFFFFF;
  width: 100%;
}
@media (min-width: 1920px) {
  .FaqPopup-content {
    padding-bottom:  266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FaqPopup-content {
    padding-bottom:  13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FaqPopup-content {
    padding-bottom:  16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .FaqPopup-content {
    padding-bottom:  31.25vw;
  }
}
@media (min-width: 1920px) {
  .FaqPopup-title {
    padding:  106.66666666666666px 186.66666666666666px 66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FaqPopup-title {
    padding:  5.555555555555555vw 9.722222222222223vw 3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FaqPopup-title {
    padding:  6.451612903225806vw 11.29032258064516vw 4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .FaqPopup-title {
    padding:  18.75vw 6.25vw 7.8125vw;
  }
}
@media (min-width: 1920px) {
  .FaqPopup-blockWrapper {
    padding:  66.66666666666666px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FaqPopup-blockWrapper {
    padding:  3.4722222222222223vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FaqPopup-blockWrapper {
    padding:  4.032258064516129vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .FaqPopup-blockWrapper {
    padding:  7.8125vw 6.25vw 0vw;
  }
}
@media (min-width: 1920px) {
  .FaqPopup-blockWrapper.downloads {
    padding:  93.33333333333333px 186.66666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FaqPopup-blockWrapper.downloads {
    padding:  4.861111111111112vw 9.722222222222223vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FaqPopup-blockWrapper.downloads {
    padding:  5.64516129032258vw 11.29032258064516vw 0vw;
  }
}
@media (max-width: 759px) {
  .FaqPopup-blockWrapper.downloads {
    padding:  12.5vw 6.25vw 0vw;
  }
}
.FaqPopup-blockWrapper.no-padding {
  padding: 0;
}
