.PageInvestorsFinancial .PageGlobalTemplate-block:first-child {
  border-top: 1px solid #111112;
}
@media (min-width: 1920px) {
  .PageInvestorsFinancial .PageGlobalTemplate-block.financialTable {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsFinancial .PageGlobalTemplate-block.financialTable {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsFinancial .PageGlobalTemplate-block.financialTable {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsFinancial .PageGlobalTemplate-block.financialTable {
    padding-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsFinancial .PageGlobalTemplate-block.financialTable {
    padding-top: 0;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsFinancial .PageGlobalTemplate-blockContent {
    padding-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsFinancial .PageGlobalTemplate-blockContent {
    padding-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsFinancial .PageGlobalTemplate-blockContent {
    padding-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsFinancial .PageGlobalTemplate-blockContent {
    padding-top:  0vw;
  }
}
