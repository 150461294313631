.SearchResultPopupPagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination {
    margin-left:  320px;
    padding-top:  26.666666666666664px;
    margin-bottom:  106.66666666666666px;
    width:  1280px;
    height:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination {
    margin-left:  16.666666666666668vw;
    padding-top:  1.3888888888888888vw;
    margin-bottom:  5.555555555555555vw;
    width:  66.66666666666667vw;
    height:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination {
    margin-left:  19.35483870967742vw;
    padding-top:  1.6129032258064515vw;
    margin-bottom:  6.451612903225806vw;
    width:  77.41935483870968vw;
    height:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination {
    margin-left:  75vw;
    padding-top:  6.25vw;
    margin-bottom:  25vw;
    width:  300vw;
    height:  18.75vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination {
    height: 25vw;
    width: 100%;
    padding: 12.5vw 6.25vw 9.375vw 6.25vw;
    margin: 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.SearchResultPopupPagination-prev {
  position: absolute;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prev {
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.SearchResultPopupPagination-prev.hide {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prev.hide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    pointer-events: none;
  }
}
.isDesktop .SearchResultPopupPagination-prev:hover,
.isNotDesktop .SearchResultPopupPagination-prev:active {
  text-decoration: underline;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .SearchResultPopupPagination-prev:hover .SearchResultPopupPagination-prevArrow:before,
  .isNotDesktop .SearchResultPopupPagination-prev:active .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(0.48387097vw) rotate(180deg);
        -ms-transform: translateX(0.48387097vw) rotate(180deg);
            transform: translateX(0.48387097vw) rotate(180deg);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .SearchResultPopupPagination-prev:hover .SearchResultPopupPagination-prevArrow:before,
  .isNotDesktop .SearchResultPopupPagination-prev:active .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(0.41666667vw) rotate(180deg);
        -ms-transform: translateX(0.41666667vw) rotate(180deg);
            transform: translateX(0.41666667vw) rotate(180deg);
  }
}
@media (min-width: 1920px) {
  .isDesktop .SearchResultPopupPagination-prev:hover .SearchResultPopupPagination-prevArrow:before,
  .isNotDesktop .SearchResultPopupPagination-prev:active .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(8px) rotate(180deg);
        -ms-transform: translateX(8px) rotate(180deg);
            transform: translateX(8px) rotate(180deg);
  }
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopupPagination-prev:hover .SearchResultPopupPagination-prevArrow:before,
  .isNotDesktop .SearchResultPopupPagination-prev:active .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
.SearchResultPopupPagination-prevText {
  color: #111112;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevText {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevText {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevText {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-prevText {
    font-size: 18.66666667px;
  }
}
.SearchResultPopupPagination-prevArrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .SearchResultPopupPagination-prevArrow:hover,
.isNotDesktop .SearchResultPopupPagination-prevArrow:active {
  text-decoration: underline;
}
.isDesktop .SearchResultPopupPagination-prevArrow:hover:before,
.isNotDesktop .SearchResultPopupPagination-prevArrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopupPagination-prevArrow:hover:before,
  .isNotDesktop .SearchResultPopupPagination-prevArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow {
    letter-spacing: 0;
  }
}
.SearchResultPopupPagination-prevArrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .SearchResultPopupPagination-prevArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-prevArrow {
    left:  -20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevArrow {
    left:  -1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevArrow {
    left:  -1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow {
    left:  -4.6875vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    left: 0;
  }
  .isDesktop .SearchResultPopupPagination-prevArrow:hover,
  .isNotDesktop .SearchResultPopupPagination-prevArrow:active {
    text-decoration: underline;
  }
  .isDesktop .SearchResultPopupPagination-prevArrow:hover:before,
  .isNotDesktop .SearchResultPopupPagination-prevArrow:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .SearchResultPopupPagination-prevArrow:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/svg/arrow-right-icon-dark.svg') no-repeat;
  }
  .isDesktop .SearchResultPopupPagination-prevArrow:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopupPagination-prevArrow {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .SearchResultPopupPagination-prevArrow:hover:before,
  .isNotDesktop .SearchResultPopupPagination-prevArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopupPagination-prevArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.SearchResultPopupPagination-prevArrow:before {
  content: '';
  display: inline-block;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(0) rotate(180deg);
        -ms-transform: translateX(0) rotate(180deg);
            transform: translateX(0) rotate(180deg);
  }
}
.isDesktop .SearchResultPopupPagination-prevArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-prevArrow:before {
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(1.20967742vw) rotate(180deg);
        -ms-transform: translateX(1.20967742vw) rotate(180deg);
            transform: translateX(1.20967742vw) rotate(180deg);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(1.04166667vw) rotate(180deg);
        -ms-transform: translateX(1.04166667vw) rotate(180deg);
            transform: translateX(1.04166667vw) rotate(180deg);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-prevArrow:before {
    -webkit-transform: translateX(20px) rotate(180deg);
        -ms-transform: translateX(20px) rotate(180deg);
            transform: translateX(20px) rotate(180deg);
  }
}
.SearchResultPopupPagination-pages {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-pages {
    display: none;
  }
}
.SearchResultPopupPagination-pages--mobile {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-pages--mobile {
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopupPagination-pages--mobile {
    padding-bottom:  2.6666666666666665px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-pages--mobile {
    padding-bottom:  0.1388888888888889vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-pages--mobile {
    padding-bottom:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-pages--mobile {
    padding-bottom:  0.625vw;
  }
}
.SearchResultPopupPagination-pagesTitle {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  color: #6F7380;
  font-weight: 500;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-pagesTitle {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-pagesTitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-pagesTitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-pagesTitle {
    font-size: 18.66666667px;
  }
}
.SearchResultPopupPagination-pageNumber {
  text-decoration: none;
  color: #111112;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-pageNumber {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-pageNumber {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-pageNumber {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-pageNumber {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-pageNumber {
    padding:  0px 10.666666666666666px 4px;
    margin-right:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-pageNumber {
    padding:  0vw 0.5555555555555556vw 0.20833333333333334vw;
    margin-right:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-pageNumber {
    padding:  0vw 0.6451612903225806vw 0.24193548387096775vw;
    margin-right:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-pageNumber {
    padding:  0vw 2.5vw 0.9375vw;
    margin-right:  9.375vw;
  }
}
.isDesktop .SearchResultPopupPagination-pageNumber:hover,
.isNotDesktop .SearchResultPopupPagination-pageNumber:active,
.SearchResultPopupPagination-pageNumber.active {
  background-color: #111112;
  color: #FFFFFF;
  border-radius: 50%;
}
.SearchResultPopupPagination-pageNumber--mobile {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-pageNumber--mobile {
    text-transform: none;
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    color: #6F7380;
  }
}
.SearchResultPopupPagination-next {
  position: absolute;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-next {
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.SearchResultPopupPagination-next.hide {
  display: none;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-next.hide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    pointer-events: none;
  }
}
.isDesktop .SearchResultPopupPagination-next:hover,
.isNotDesktop .SearchResultPopupPagination-next:active {
  text-decoration: underline;
}
.isDesktop .SearchResultPopupPagination-next:hover .SearchResultPopupPagination-nextArrow:before,
.isNotDesktop .SearchResultPopupPagination-next:active .SearchResultPopupPagination-nextArrow:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopupPagination-next:hover .SearchResultPopupPagination-nextArrow:before,
  .isNotDesktop .SearchResultPopupPagination-next:active .SearchResultPopupPagination-nextArrow:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.SearchResultPopupPagination-nextArrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  display: block;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .SearchResultPopupPagination-nextArrow:hover,
.isNotDesktop .SearchResultPopupPagination-nextArrow:active {
  text-decoration: underline;
}
.isDesktop .SearchResultPopupPagination-nextArrow:hover:before,
.isNotDesktop .SearchResultPopupPagination-nextArrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .SearchResultPopupPagination-nextArrow:hover:before,
  .isNotDesktop .SearchResultPopupPagination-nextArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow {
    letter-spacing: 0;
  }
}
.SearchResultPopupPagination-nextArrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .SearchResultPopupPagination-nextArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-nextArrow {
    right:  -20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-nextArrow {
    right:  -1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-nextArrow {
    right:  -1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow {
    right:  -4.6875vw;
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    right: 0;
  }
  .isDesktop .SearchResultPopupPagination-nextArrow:hover,
  .isNotDesktop .SearchResultPopupPagination-nextArrow:active {
    text-decoration: underline;
  }
  .isDesktop .SearchResultPopupPagination-nextArrow:hover:before,
  .isNotDesktop .SearchResultPopupPagination-nextArrow:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .SearchResultPopupPagination-nextArrow:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/svg/arrow-right-icon-dark.svg') no-repeat;
  }
  .isDesktop .SearchResultPopupPagination-nextArrow:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopupPagination-nextArrow {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .SearchResultPopupPagination-nextArrow:hover:before,
  .isNotDesktop .SearchResultPopupPagination-nextArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .SearchResultPopupPagination-nextArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextArrow:before {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    margin-right: 0;
    margin-left: 1.5625vw;
  }
}
.SearchResultPopupPagination-nextText {
  color: #111112;
  display: block;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .SearchResultPopupPagination-nextText {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultPopupPagination-nextText {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultPopupPagination-nextText {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .SearchResultPopupPagination-nextText {
    font-size: 18.66666667px;
  }
}
