@media (max-width: 759px) {
  .PageAbout .PageLanding-content {
    padding-bottom: 0;
  }
}
.PageAbout .PageLanding-blockContent {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
