@media (min-width: 1920px) {
  .CampusEventPopupHead-textWrapper {
    padding:  100px 133.33333333333331px 0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusEventPopupHead-textWrapper {
    padding:  5.208333333333334vw 6.944444444444445vw 0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusEventPopupHead-textWrapper {
    padding:  6.048387096774193vw 8.064516129032258vw 0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CampusEventPopupHead-textWrapper {
    padding:  31.25vw 6.25vw 0vw;
  }
}
.CampusEventPopupHead-title {
  color: #111112;
}
.CampusEventPopupHead-description {
  color: #6F7380;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .CampusEventPopupHead-description {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusEventPopupHead-description {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusEventPopupHead-description {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CampusEventPopupHead-description {
    margin-top:  4.6875vw;
  }
}
.CampusEventPopupHead-image {
  width: 100%;
}
@media (min-width: 1920px) {
  .CampusEventPopupHead-image {
    height:  746.6666666666666px;
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusEventPopupHead-image {
    height:  38.88888888888889vw;
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusEventPopupHead-image {
    height:  45.16129032258064vw;
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .CampusEventPopupHead-image {
    height:  62.5vw;
    margin-top:  6.25vw;
  }
}
.CampusEventPopupHead-image img {
  height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
