@media (max-width: 759px) {
  .IndexHead {
    height: 100vh;
    min-height: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexHead {
    height: calc(100vh - 6.4516129vw);
    min-height: 40.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexHead {
    height: calc(100vh - 5.55555556vw);
    min-height: 34.72222222vw;
  }
}
@media (min-width: 1920px) {
  .IndexHead {
    height: calc(100vh - 106.66666667px);
    min-height: 666.66666667px;
  }
}
.IndexHead-fixed {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}
@media (max-width: 759px) {
  .IndexHead-fixed {
    top: 12.5vw;
    height: calc(100vh - 12.5vw);
  }
}
.IndexHead-wrapper {
  position: relative;
  height: 100%;
}
.IndexHead-images {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  will-change: filter, transform;
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
.IndexHead-images-left,
.IndexHead-images-right {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.IndexHead-images-left.IndexHead-images-left--hover-transition img,
.IndexHead-images-left.IndexHead-images-right--hover-transition img,
.IndexHead-images-right.IndexHead-images-left--hover-transition img,
.IndexHead-images-right.IndexHead-images-right--hover-transition img {
  will-change: transform;
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.IndexHead-images-left.IndexHead-images-left--hover-transition img.fade-out,
.IndexHead-images-left.IndexHead-images-right--hover-transition img.fade-out,
.IndexHead-images-right.IndexHead-images-left--hover-transition img.fade-out,
.IndexHead-images-right.IndexHead-images-right--hover-transition img.fade-out {
  -webkit-transition: 0.8s opacity ease, 0.8s -webkit-transform ease;
  transition: 0.8s opacity ease, 0.8s -webkit-transform ease;
  transition: 0.8s opacity ease, 0.8s transform ease;
  transition: 0.8s opacity ease, 0.8s transform ease, 0.8s -webkit-transform ease;
}
.IndexHead-images-left.IndexHead-images-left--hover-transition img.fade-in,
.IndexHead-images-left.IndexHead-images-right--hover-transition img.fade-in,
.IndexHead-images-right.IndexHead-images-left--hover-transition img.fade-in,
.IndexHead-images-right.IndexHead-images-right--hover-transition img.fade-in {
  -webkit-transition: none;
  transition: none;
}
.IndexHead-images-left img,
.IndexHead-images-right img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  will-change: transform;
  -webkit-transition: 0.8s opacity ease, 0.8s -webkit-transform ease;
  transition: 0.8s opacity ease, 0.8s -webkit-transform ease;
  transition: 0.8s opacity ease, 0.8s transform ease;
  transition: 0.8s opacity ease, 0.8s transform ease, 0.8s -webkit-transform ease;
}
.IndexHead-images-left img.fade-out,
.IndexHead-images-right img.fade-out {
  opacity: 0;
  -webkit-transition: 0.8s opacity ease, 0.8s -webkit-transform ease;
  transition: 0.8s opacity ease, 0.8s -webkit-transform ease;
  transition: 0.8s opacity ease, 0.8s transform ease;
  transition: 0.8s opacity ease, 0.8s transform ease, 0.8s -webkit-transform ease;
}
.IndexHead-images-left img.fade-in,
.IndexHead-images-right img.fade-in {
  opacity: 0;
  -webkit-transition: none;
  transition: none;
}
.IndexHead-images-left {
  left: 0;
  right: 50%;
  background: #FFFFFF;
}
.brand--thorn .IndexHead-images-left {
  background: #0D1B28;
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-images-left {
    bottom: 58%;
    right: 0;
  }
}
.IndexHead-images-left.IndexHead-images-left--hover img {
  -webkit-transform: translate3d(5%, 0, 0) scale(1.02);
          transform: translate3d(5%, 0, 0) scale(1.02);
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-images-left.IndexHead-images-left--hover img {
    -webkit-transform: scale(1.02);
        -ms-transform: scale(1.02);
            transform: scale(1.02);
  }
}
.IndexHead-images-left img {
  height: auto;
  -webkit-transform: translate3d(5%, 0, 0) scale(1);
          transform: translate3d(5%, 0, 0) scale(1);
}
.IndexHead-images-left img.fade-out {
  -webkit-transform: translate3d(5%, 0, 0) scale(1.15);
          transform: translate3d(5%, 0, 0) scale(1.15);
}
.IndexHead-images-left img.fade-in {
  -webkit-transform: translate3d(5%, 0, 0) scale(0.9);
          transform: translate3d(5%, 0, 0) scale(0.9);
}
@media (max-width: 759px) {
  .IndexHead-images-left img {
    top: -9.375vw;
  }
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-images-left img {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
  }
  .IndexHead-images-left img.fade-out {
    -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
            transform: scale(1.15);
  }
  .IndexHead-images-left img.fade-in {
    -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
            transform: scale(0.9);
  }
}
.IndexHead-images-right {
  left: 50%;
  right: 0;
  background: #111112;
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-images-right {
    top: 42%;
    left: 0;
  }
}
.IndexHead-images-right.IndexHead-images-right--hover img {
  -webkit-transform: scale(1.02);
      -ms-transform: scale(1.02);
          transform: scale(1.02);
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-images-right.IndexHead-images-right--hover img {
    -webkit-transform: scale(1.02);
        -ms-transform: scale(1.02);
            transform: scale(1.02);
  }
}
.IndexHead-images-right img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 100%;
     object-position: 50% 100%;
  font-family: 'object-fit: cover; object-position: 50% 100%';
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.IndexHead-images-right img.fade-out {
  -webkit-transform: scale(1.15);
      -ms-transform: scale(1.15);
          transform: scale(1.15);
}
.IndexHead-images-right img.fade-in {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.IndexHead-texts {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  will-change: opacity;
}
.IndexHead-texts-left,
.IndexHead-texts-right {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}
.isDesktop .IndexHead-texts-left:hover h2:after,
.isDesktop .IndexHead-texts-right:hover h2:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: transform;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.IndexHead-texts-left:active h2:after,
.IndexHead-texts-right:active h2:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .IndexHead-texts-left:active h2:after,
.isDesktop .IndexHead-texts-right:active h2:after {
  will-change: transform;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .isDesktop .IndexHead-texts-left:active h2:after,
  .isDesktop .IndexHead-texts-right:active h2:after {
    -webkit-transform: translate3d(2.5vw, 0, 0) !important;
            transform: translate3d(2.5vw, 0, 0) !important;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .IndexHead-texts-left:active h2:after,
  .isDesktop .IndexHead-texts-right:active h2:after {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .IndexHead-texts-left:active h2:after,
  .isDesktop .IndexHead-texts-right:active h2:after {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .IndexHead-texts-left:active h2:after,
  .isDesktop .IndexHead-texts-right:active h2:after {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.IndexHead-texts-left {
  left: 0;
  right: 50%;
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-texts-left {
    bottom: 58%;
    right: 0;
  }
}
.IndexHead-texts-left h2:after {
  background: center / cover url('/static/img/arrow-dark.svg') no-repeat;
}
.brand--thorn .IndexHead-texts-left h2:after {
  background: center / cover url('/static/img/arrow.svg') no-repeat;
}
.IndexHead-texts-right {
  left: 50%;
  right: 0;
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-texts-right {
    top: 42%;
    left: 0;
  }
}
.IndexHead-texts-right h2:after {
  background: center / cover url('/static/img/arrow.svg') no-repeat;
}
@media (max-width: 759px) and (orientation: portrait) {
  .IndexHead-texts-right .IndexHead-text {
    top: auto;
    bottom: 31.25vw;
  }
}
.IndexHead-text {
  position: absolute;
  width: 100%;
  top: 62%;
  text-align: center;
}
@media (min-height: 500px) {
  .IndexHead-text {
    top: calc(76% - 3.81944444vw);
  }
}
@media (min-height: 700px) {
  .IndexHead-text {
    top: calc(85% - 7.98611111vw);
  }
}
@media (min-height: 880px) {
  .IndexHead-text {
    top: calc(100% - 17.36111111vw);
  }
}
@media (max-width: 759px) {
  .IndexHead-text {
    top: auto;
    bottom: 9.375vw;
    padding: 0 5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexHead-text {
    padding: 0 3.87096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexHead-text {
    padding: 0 3.33333333vw;
  }
}
@media (min-width: 1920px) {
  .IndexHead-text {
    padding: 0 64px;
  }
}
.IndexHead-text h2 {
  position: relative;
  display: inline-block;
}
.brand--thorn .IndexHead-text h2 {
  color: #FFFFFF;
}
@media (max-width: 759px) and (orientation: landscape) {
  .IndexHead-text h2 {
    font-size: 5.625vw;
  }
}
.IndexHead-text h2:after {
  content: '';
  display: block;
  position: absolute;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .IndexHead-text h2:after {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .IndexHead-text h2:after {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexHead-text h2:after {
    width: 2.17741935vw;
    height: 1.4516129vw;
    top: 2.01612903vw;
    right: -4.03225806vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexHead-text h2:after {
    width: 1.875vw;
    height: 1.25vw;
    top: 1.73611111vw;
    right: -3.47222222vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .IndexHead-text h2:after {
    width: 36px;
    height: 24px;
    top: 33.33333333px;
    right: -66.66666667px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.IndexHead-text p {
  position: relative;
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.05em;
}
.brand--thorn .IndexHead-text p {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .IndexHead-text p {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .IndexHead-text p {
    width: 29.03225806vw;
    margin-top: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .IndexHead-text p {
    width: 25vw;
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .IndexHead-text p {
    width: 480px;
    margin-top: 26.66666667px;
  }
}
.IndexHead-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  will-change: opacity;
  background: rgba(21, 25, 40, 0.5);
  opacity: 0;
}
.IndexHead-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
