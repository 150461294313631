.PageInvestorsShares .PageGlobalTemplate-block:first-child {
  border-top: 1px solid #111112;
}
.PageInvestorsShares .CommonText-text {
  width: 86.84210526%;
}
@media (max-width: 759px) {
  .PageInvestorsShares .CommonText-text {
    width: 100%;
  }
}
.PageInvestorsShares-iframe {
  position: relative;
  width: 100%;
}
@media (min-width: 1920px) {
  .PageInvestorsShares-iframe {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsShares-iframe {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsShares-iframe {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsShares-iframe {
    padding:  0vw 6.25vw;
  }
}
.PageInvestorsShares-iframeWrapper {
  position: relative;
  width: 100%;
  height: 665px;
  border-top: 1px solid #111112;
}
@media (max-width: 759px) {
  .PageInvestorsShares-iframeWrapper {
    position: relative;
    height: auto;
    min-height: 560px;
  }
  .PageInvestorsShares-iframeWrapper:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100.7518797%;
  }
}
.PageInvestorsShares-iframeWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .PageInvestorsShares-facts {
    padding:  93.33333333333333px 186.66666666666666px 120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsShares-facts {
    padding:  4.861111111111112vw 9.722222222222223vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsShares-facts {
    padding:  5.64516129032258vw 11.29032258064516vw 7.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsShares-facts {
    padding:  12.5vw 6.25vw 15.625vw;
  }
}
.PageInvestorsShares-facts.hide {
  opacity: 0;
}
