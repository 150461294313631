@media (max-width: 759px) {
  .CareersJobPopup .Popup-close {
    background-color: #111112;
  }
  .CareersJobPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.CareersJobPopup-image {
  width: 100%;
}
@media (min-width: 1920px) {
  .CareersJobPopup-image {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-image {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-image {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-image {
    margin-top:  9.375vw;
  }
}
.CareersJobPopup-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.CareersJobPopup-titleBlock {
  position: absolute;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersJobPopup-titleBlock {
    bottom:  80px;
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-titleBlock {
    bottom:  4.166666666666667vw;
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-titleBlock {
    bottom:  4.838709677419355vw;
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-titleBlock {
    padding:  0vw 6.25vw;
    bottom:  6.25vw;
  }
}
.CareersJobPopup-title {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media (min-width: 1920px) {
  .CareersJobPopup-title {
    padding:  106.66666666666666px 186.66666666666666px 46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-title {
    padding:  5.555555555555555vw 9.722222222222223vw 2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-title {
    padding:  6.451612903225806vw 11.29032258064516vw 2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-title {
    padding:  18.75vw 6.25vw 0vw;
  }
}
@media (min-width: 1920px) {
  .CareersJobPopup-description {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-description {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-description {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-description {
    font-size:  3.75vw;
    margin-top:  3.125vw;
  }
}
.CareersJobPopup-label {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .CareersJobPopup-label {
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-label {
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-label {
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-label {
    margin-bottom:  3.125vw;
  }
}
.CareersJobPopup-content {
  background: #FFFFFF;
  width: 100%;
  min-height: 100vh;
}
@media (min-width: 1920px) {
  .CareersJobPopup-content {
    padding-bottom:  200px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-content {
    padding-bottom:  10.416666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-content {
    padding-bottom:  12.096774193548386vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-content {
    padding-bottom:  31.25vw;
  }
}
.CareersJobPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .CareersJobPopup-blockWrapper {
    padding:  0 186.66666666666666px 53.33333333333333px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-blockWrapper {
    padding:  0vw 9.722222222222223vw 2.7777777777777777vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-blockWrapper {
    padding:  0vw 11.29032258064516vw 3.225806451612903vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-blockWrapper {
    padding:  0vw 6.25vw 6.25vw;
    margin-top:  6.25vw;
  }
}
.CareersJobPopup-blockWrapper.no-padding {
  padding: 0;
}
.CareersJobPopup-blockWrapper .CommonText-quote {
  margin: 0;
}
@media (min-width: 1920px) {
  .CareersJobPopup-blockWrapper .CommonGallery {
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-blockWrapper .CommonGallery {
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-blockWrapper .CommonGallery {
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-blockWrapper .CommonGallery {
    margin-bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .CareersJobPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersJobPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersJobPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CareersJobPopup-blockWrapper .DownloadLinks-downloadsItemTitle {
    font-size:  4.375vw;
  }
}
