.ProductFamilyRoom {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-desktop {
    display: none;
  }
}
.ProductFamilyRoom-mobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-mobile {
    display: block;
    width: 100%;
    height: 69.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom {
    height: 64.51612903vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom {
    height: 55.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom {
    height: 1066.66666667px;
  }
}
.ProductFamilyRoom-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  opacity: 0;
}
.ProductFamilyRoom-image.active {
  opacity: 1;
}
.ProductFamilyRoom-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-items {
    padding-left: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-items {
    padding-left: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-items {
    padding-left: 186.66666667px;
  }
}
.ProductFamilyRoom-item {
  color: #FFFFFF;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
  background-color: transparent;
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-item {
    border-radius:  66.66666666666666px;
    width:  373.3333333333333px;
    padding:  10.666666666666666px 16px 6.666666666666666px;
    margin-left:  -16px;
    margin-top:  33.33333333333333px;
    font-size:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-item {
    border-radius:  3.4722222222222223vw;
    width:  19.444444444444446vw;
    padding:  0.5555555555555556vw 0.8333333333333334vw 0.3472222222222222vw;
    margin-left:  -0.8333333333333334vw;
    margin-top:  1.7361111111111112vw;
    font-size:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-item {
    border-radius:  4.032258064516129vw;
    width:  22.58064516129032vw;
    padding:  0.6451612903225806vw 0.967741935483871vw 0.4032258064516129vw;
    margin-left:  -0.967741935483871vw;
    margin-top:  2.0161290322580645vw;
    font-size:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyRoom-item {
    border-radius:  15.625vw;
    width:  87.5vw;
    padding:  2.5vw 3.75vw 1.5625vw;
    margin-left:  -3.75vw;
    margin-top:  7.8125vw;
  }
}
.ProductFamilyRoom-item:last-of-type {
  border-bottom: 0;
}
.isDesktop .ProductFamilyRoom-item:hover,
.isNotDesktop .ProductFamilyRoom-item:active {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}
.ProductFamilyRoom-item:after {
  content: "";
  position: absolute;
  height: 1px;
  background: #C6C6C6;
  opacity: 0.4;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-item:after {
    left:  16px;
    right:  16px;
    bottom:  -20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-item:after {
    left:  0.8333333333333334vw;
    right:  0.8333333333333334vw;
    bottom:  -1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-item:after {
    left:  0.967741935483871vw;
    right:  0.967741935483871vw;
    bottom:  -1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyRoom-item:after {
    left:  3.75vw;
    right:  3.75vw;
    bottom:  -4.6875vw;
  }
}
.ProductFamilyRoom-item.active {
  font-weight: normal;
  background-color: #FFFFFF;
  color: #111112;
}
.isDesktop .ProductFamilyRoom-item.active:hover,
.isNotDesktop .ProductFamilyRoom-item.active:active {
  color: #111112;
}
.ProductFamilyRoom-wrapper {
  width: 100%;
  overflow: hidden;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-wrapper {
    height: 100%;
  }
}
.ProductFamilyRoom-wrapperInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-wrapperInner {
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyRoom-wrapperInner {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}
.ProductFamilyRoom-itemMobile {
  position: relative;
  width: 100vw;
  height: 100%;
}
.isDesktop .ProductFamilyRoom-itemMobile {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.isDesktop .ProductFamilyRoom-itemMobile.active {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  visibility: visible;
  opacity: 1;
}
.ProductFamilyRoom-itemMobileImage {
  height: 100%;
  position: relative;
}
.ProductFamilyRoom-itemMobileImage img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  height: 100%;
}
.isDesktop .ProductFamilyRoom-itemMobileImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.ProductFamilyRoom-itemMobileTitle {
  color: #FFFFFF;
  z-index: 2;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10.3125vw;
}
.ProductFamilyRoom-navLines {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-navLines {
    bottom: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-navLines {
    bottom: 2.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-navLines {
    bottom: 1.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-navLines {
    bottom: 37.33333333px;
  }
}
.ProductFamilyRoom-navLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-navLine {
    height: 9.375vw;
    width: 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-navLine {
    height: 2.41935484vw;
    width: 4.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-navLine {
    height: 2.08333333vw;
    width: 4.02777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-navLine {
    height: 40px;
    width: 77.33333333px;
  }
}
.isDesktop .ProductFamilyRoom-navLine:hover:after,
.isNotDesktop .ProductFamilyRoom-navLine:active:after {
  height: 4px;
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyRoom-navLine:hover:after,
  .isNotDesktop .ProductFamilyRoom-navLine:active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .ProductFamilyRoom-navLine:hover:after,
  .isNotDesktop .ProductFamilyRoom-navLine:active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .ProductFamilyRoom-navLine:hover:after,
  .isNotDesktop .ProductFamilyRoom-navLine:active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .ProductFamilyRoom-navLine:hover:after,
  .isNotDesktop .ProductFamilyRoom-navLine:active:after {
    height: 5.33333333px;
  }
}
.ProductFamilyRoom-navLine:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.ProductFamilyRoom-navLine.active {
  pointer-events: none;
}
.ProductFamilyRoom-navLine.active:after {
  background: #FFFFFF;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-navLine.active:after {
    height: 1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-navLine.active:after {
    height: 0.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-navLine.active:after {
    height: 0.27777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-navLine.active:after {
    height: 5.33333333px;
  }
}
.ProductFamilyRoom-navLine:first-child {
  margin-left: 0;
}
.ProductFamilyRoom-arrowLeft,
.ProductFamilyRoom-arrowRight {
  position: absolute;
  top: 45%;
  cursor: pointer;
  z-index: 10;
}
.isMobile .ProductFamilyRoom-arrowLeft,
.isMobile .ProductFamilyRoom-arrowRight {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-arrowLeft,
  .ProductFamilyRoom-arrowRight {
    width: 4.0625vw;
    height: 7.1875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-arrowLeft,
  .ProductFamilyRoom-arrowRight {
    width: 1.61290323vw;
    height: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-arrowLeft,
  .ProductFamilyRoom-arrowRight {
    width: 1.38888889vw;
    height: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-arrowLeft,
  .ProductFamilyRoom-arrowRight {
    width: 26.66666667px;
    height: 46.66666667px;
  }
}
.isDesktop .ProductFamilyRoom-arrowLeft:hover svg,
.isDesktop .ProductFamilyRoom-arrowRight:hover svg,
.isNotDesktop .ProductFamilyRoom-arrowLeft:active svg,
.isNotDesktop .ProductFamilyRoom-arrowRight:active svg {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.ProductFamilyRoom-arrowLeft svg,
.ProductFamilyRoom-arrowRight svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyRoom-arrowLeft {
    left: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-arrowLeft {
    left: 1.37096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-arrowLeft {
    left: 1.18055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-arrowLeft {
    left: 22.66666667px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyRoom-arrowRight {
    right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyRoom-arrowRight {
    right: 1.37096774vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyRoom-arrowRight {
    right: 1.18055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyRoom-arrowRight {
    right: 22.66666667px;
  }
}
