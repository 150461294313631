.NewsLandingItems-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  text-decoration: none;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: relative;
}
.NewsLandingItems-item.show {
  opacity: 1;
}
@media (max-width: 759px) {
  .NewsLandingItems-item {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.NewsLandingItems-itemLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}
.isDesktop .NewsLandingItems-item:hover .NewsLandingItems-itemReadMore {
  text-decoration: underline;
}
.isDesktop .NewsLandingItems-item:hover .NewsLandingItems-itemReadMore:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .NewsLandingItems-item:hover .NewsLandingItems-itemReadMore:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.isDesktop .NewsLandingItems-item:hover .NewsLandingItems-itemDescription {
  opacity: 1;
}
.isDesktop .NewsLandingItems-item:hover .NewsLandingItems-itemDateDay {
  color: transparent;
  -webkit-text-stroke: 1px #FFFFFF;
  -moz-text-stroke: 1px #FFFFFF;
  text-stroke: 1px #FFFFFF;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}
.isDesktop .NewsLandingItems-item:hover .NewsLandingItems-itemImage img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.isDesktop .NewsLandingItems-item:hover .NewsLandingItems-share {
  opacity: 1;
}
.isIE .NewsLandingItems-item:hover .NewsLandingItems-itemDateDay {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .NewsLandingItems-item {
    padding:  53.33333333333333px 0 46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-item {
    padding:  2.7777777777777777vw 0vw 2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-item {
    padding:  3.225806451612903vw 0vw 2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-item {
    padding:  9.375vw 0vw 7.8125vw;
  }
}
.NewsLandingItems-itemContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (min-width: 1920px) {
  .NewsLandingItems-itemContent {
    padding-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-itemContent {
    padding-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-itemContent {
    padding-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemContent {
    padding-top:  3.125vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemContent {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 78%;
            flex: 0 0 78%;
    margin-top: 2.5vw;
  }
}
.NewsLandingItems-itemDate {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 17%;
          flex: 0 0 17%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemDate {
    display: none;
  }
}
.NewsLandingItems-itemDateMonthYear {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemDateMonthYear {
    margin-top: 1.25vw;
  }
}
.NewsLandingItems-itemDateMonthYear span {
  display: block;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemDateMonthYear span {
    display: inline;
  }
}
.NewsLandingItems-itemDate_mobile {
  display: none;
}
.NewsLandingItems-itemDate_mobile .NewsLandingItems-itemDateMonthYear {
  display: block;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemDate_mobile {
    display: block;
    margin-top: 2.5vw;
  }
}
.NewsLandingItems-itemTitleBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 68%;
          flex: 0 0 68%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemTitleBlock {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}
.NewsLandingItems-itemTitleWrapper {
  overflow: hidden;
  display: block;
}
.NewsLandingItems-itemTitle {
  overflow: hidden;
  position: relative;
  display: block;
}
@media (min-width: 1920px) {
  .NewsLandingItems-itemTitle {
    max-height:  77.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-itemTitle {
    max-height:  4.027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-itemTitle {
    max-height:  4.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemTitle {
    max-height:  18.125vw;
  }
}
.NewsLandingItems-itemDescription {
  line-height: 1.42857143;
  opacity: 0.6;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  overflow: hidden;
  display: block;
}
@media (min-width: 1920px) {
  .NewsLandingItems-itemDescription {
    margin-top:  29.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-itemDescription {
    margin-top:  1.527777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-itemDescription {
    margin-top:  1.7741935483870968vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemDescription {
    margin-top:  6.875vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemDescription {
    display: none;
  }
}
.NewsLandingItems-itemImage {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 27%;
          flex: 0 0 27%;
  overflow: hidden;
}
.NewsLandingItems-itemImage img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemImage {
    display: none;
  }
}
.NewsLandingItems-itemImage_mobile {
  display: none;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemImage_mobile {
    display: block;
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
  .NewsLandingItems-itemImage_mobile img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    font-family: 'object-fit: cover; object-position: center';
    width: 100%;
  }
}
.NewsLandingItems-itemReadMore {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemReadMore {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-itemReadMore {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-itemReadMore {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .NewsLandingItems-itemReadMore {
    font-size: 18.66666667px;
  }
}
.isDesktop .NewsLandingItems-itemReadMore:hover,
.isNotDesktop .NewsLandingItems-itemReadMore:active {
  text-decoration: underline;
}
.isDesktop .NewsLandingItems-itemReadMore:hover:before,
.isNotDesktop .NewsLandingItems-itemReadMore:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .NewsLandingItems-itemReadMore:hover:before,
  .isNotDesktop .NewsLandingItems-itemReadMore:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemReadMore {
    letter-spacing: 0;
  }
}
.NewsLandingItems-itemReadMore:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemReadMore:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .NewsLandingItems-itemReadMore:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .NewsLandingItems-itemReadMore:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-itemReadMore:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-itemReadMore:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .NewsLandingItems-itemReadMore:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .NewsLandingItems-itemReadMore {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-itemReadMore {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-itemReadMore {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-itemReadMore {
    margin-top:  4.6875vw;
  }
}
.NewsLandingItems-showMore {
  text-transform: uppercase;
}
.NewsLandingItems-showMore.hide {
  display: none;
}
.NewsLandingItems-buttonWrapper {
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .NewsLandingItems-buttonWrapper {
    margin-top:  52px;
    width:  320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-buttonWrapper {
    margin-top:  2.7083333333333335vw;
    width:  16.666666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-buttonWrapper {
    margin-top:  3.1451612903225805vw;
    width:  19.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-buttonWrapper {
    margin-top:  12.1875vw;
    width:  75vw;
  }
}
.NewsLandingItems-share {
  position: absolute;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 11;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  opacity: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .NewsLandingItems-share {
    top:  160px;
    width:  173.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-share {
    top:  8.333333333333334vw;
    width:  9.027777777777779vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-share {
    top:  9.67741935483871vw;
    width:  10.483870967741936vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-share {
    top:  37.5vw;
    width:  40.625vw;
  }
}
.NewsLandingItems-share a {
  display: block;
}
.isDesktop .NewsLandingItems-share a:hover svg {
  fill: #FFFFFF;
}
.NewsLandingItems-share a svg {
  fill: rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.15s fill;
  transition: 0.15s fill;
}
@media (min-width: 1920px) {
  .NewsLandingItems-share a svg {
    width:  26.666666666666664px;
    height:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .NewsLandingItems-share a svg {
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .NewsLandingItems-share a svg {
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .NewsLandingItems-share a svg {
    width:  6.25vw;
    height:  6.25vw;
  }
}
