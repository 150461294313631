.ProductFamilyServices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #DEDEDE;
  border-top: 1px solid #DEDEDE;
}
@media (max-width: 759px) {
  .ProductFamilyServices {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 10.9375vw;
    padding-top: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyServices {
    padding-bottom: 7.25806452vw;
    padding-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyServices {
    padding-bottom: 6.25vw;
    padding-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyServices {
    padding-bottom: 120px;
    padding-top: 46.66666667px;
  }
}
.ProductFamilyServices-titleBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          flex: 0 0 35%;
}
.ProductFamilyServices-contentBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 65%;
          flex: 0 0 65%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductFamilyServices-contentBlock {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 6.25vw;
  }
}
.ProductFamilyServices-title {
  color: #111112;
}
.ProductFamilyServices-description {
  color: #6F7380;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
.ProductFamilyServices-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
@media (max-width: 759px) {
  .ProductFamilyServices-links {
    margin-top: 7.8125vw;
  }
}
.ProductFamilyServices-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #111112;
  text-decoration: none;
}
@media (max-width: 759px) {
  .ProductFamilyServices-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyServices-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyServices-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyServices-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductFamilyServices-link:hover,
.isNotDesktop .ProductFamilyServices-link:active {
  text-decoration: underline;
}
.isDesktop .ProductFamilyServices-link:hover:before,
.isNotDesktop .ProductFamilyServices-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyServices-link:hover:before,
  .isNotDesktop .ProductFamilyServices-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductFamilyServices-link {
    letter-spacing: 0;
  }
}
.ProductFamilyServices-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg');
}
@media (max-width: 759px) {
  .ProductFamilyServices-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductFamilyServices-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyServices-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyServices-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyServices-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductFamilyServices-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.ProductFamilyServices-link:visited {
  color: #111112;
}
.ProductFamilyServices-link:first-of-type {
  margin-top: 0;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyServices-link {
    margin-top: 1.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyServices-link {
    margin-top: 1.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyServices-link {
    margin-top: 24px;
  }
}
