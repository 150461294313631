.PageGlobalTemplate-head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}
.PageGlobalTemplate-head.isAlignBottom {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-head.isAlignBottom .GlobalMenu {
    padding-bottom:  100px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-head.isAlignBottom .GlobalMenu {
    padding-bottom:  5.208333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-head.isAlignBottom .GlobalMenu {
    padding-bottom:  6.048387096774193vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-head.isAlignBottom .GlobalMenu {
    padding-bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-head {
    height:  853.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-head {
    height:  44.44444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-head {
    height:  51.61290322580645vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-head {
    height:  100vw;
    padding-top:  12.5vw;
  }
}
.PageGlobalTemplate-head .GlobalMenu {
  width: 100%;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-head .GlobalMenu {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-head .GlobalMenu {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-head .GlobalMenu {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-head .GlobalMenu {
    padding:  0vw 6.25vw;
  }
}
.PageGlobalTemplate-headBg {
  background-color: #0D1B28;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
@media (max-width: 759px) {
  .PageGlobalTemplate-headBg.hideMobile {
    display: none;
  }
}
.PageGlobalTemplate-headBg.animation img,
.PageGlobalTemplate-headBg.animation video {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.8s -webkit-transform;
  transition: 0.8s -webkit-transform;
  transition: 0.8s transform;
  transition: 0.8s transform, 0.8s -webkit-transform;
  opacity: 1;
}
.PageGlobalTemplate-headBg img,
.PageGlobalTemplate-headBg video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transform: scale(1.2) translate3d(0, 0, 0);
          transform: scale(1.2) translate3d(0, 0, 0);
  will-change: transform;
  opacity: 0;
}
.PageGlobalTemplate-headBg_mobile {
  display: none;
}
.PageGlobalTemplate-headBg_mobile:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  content: '';
  background-color: #111112;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.PageGlobalTemplate-headBg_mobile.shadow:after {
  opacity: 0.6;
}
@media (max-width: 759px) {
  .PageGlobalTemplate-headBg_mobile {
    display: block;
  }
}
.PageGlobalTemplate-headBg_mobile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  will-change: transform;
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-blocksWrapper {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-blocksWrapper {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-blocksWrapper {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blocksWrapper {
    margin-top:  6.25vw;
  }
}
.PageGlobalTemplate-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #111112;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block {
    width:  1546.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block {
    width:  80.55555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block {
    width:  93.54838709677419vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block {
    width:  87.5vw;
  }
}
.PageGlobalTemplate-block.isFullWidth {
  margin: 0;
  display: block;
  width: 100%;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block.isFullWidth .PageGlobalTemplate-blockTitle {
    margin-bottom:  44px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block.isFullWidth .PageGlobalTemplate-blockTitle {
    margin-bottom:  2.291666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block.isFullWidth .PageGlobalTemplate-blockTitle {
    margin-bottom:  2.661290322580645vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block.isFullWidth .PageGlobalTemplate-blockTitle {
    margin-bottom:  10.3125vw;
  }
}
.PageGlobalTemplate-block.isFullWidth.isWithMargins {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block.isFullWidth.isWithMargins {
    width:  1546.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block.isFullWidth.isWithMargins {
    width:  80.55555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block.isFullWidth.isWithMargins {
    width:  93.54838709677419vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block.isFullWidth.isWithMargins {
    width:  87.5vw;
  }
}
.PageGlobalTemplate-block.isOnlyComponent {
  border-top: none;
  padding-top: 0;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block.isOnlyComponent {
    margin-top:  -53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block.isOnlyComponent {
    margin-top:  -2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block.isOnlyComponent {
    margin-top:  -3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block.isOnlyComponent {
    margin-top:  -12.5vw;
  }
}
.PageGlobalTemplate-block.isOnlyComponent .PageGlobalTemplate-blockContent {
  margin: 0;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block.isOnlyComponent.isGlobalSlider {
    margin-top:  -106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block.isOnlyComponent.isGlobalSlider {
    margin-top:  -5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block.isOnlyComponent.isGlobalSlider {
    margin-top:  -6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block.isOnlyComponent.isGlobalSlider {
    margin-top:  -25vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block.isOnlyComponent .LayoutsGallery {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block.isOnlyComponent .LayoutsGallery {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block.isOnlyComponent .LayoutsGallery {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block.isOnlyComponent .LayoutsGallery {
    margin-top:  0vw;
  }
}
.PageGlobalTemplate-block:first-of-type {
  border-top: none;
  padding-top: 0;
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block {
    padding-bottom:  80px;
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block {
    padding-bottom:  4.166666666666667vw;
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block {
    padding-top:  3.225806451612903vw;
    padding-bottom:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block {
    padding-top:  6.25vw;
    padding-bottom:  18.75vw;
  }
}
.PageGlobalTemplate-blockTitle {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 34%;
          flex: 0 0 34%;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  color: #111112;
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blockTitle {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-blockTitle {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-blockTitle {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-blockTitle {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-blockTitle {
    padding-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-blockTitle {
    padding-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-blockTitle {
    padding-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blockTitle {
    padding-right:  0vw;
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blockTitle {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin-bottom: 4.6875vw;
  }
}
.isIE .PageGlobalTemplate-blockTitle {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (min-width: 1920px) {
  .isIE .PageGlobalTemplate-blockTitle {
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isIE .PageGlobalTemplate-blockTitle {
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isIE .PageGlobalTemplate-blockTitle {
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .isIE .PageGlobalTemplate-blockTitle {
    margin-right:  6.25vw;
  }
}
.PageGlobalTemplate-blockContent {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66%;
          flex: 0 0 66%;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-blockContent {
    margin-top:  -6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-blockContent {
    margin-top:  -0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-blockContent {
    margin-top:  -0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blockContent {
    margin-top:  -1.5625vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blockContent {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin-top: 0;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-blockContentImage {
    margin-top:  40px;
    margin-bottom:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-blockContentImage {
    margin-top:  2.0833333333333335vw;
    margin-bottom:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-blockContentImage {
    margin-top:  2.4193548387096775vw;
    margin-bottom:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-blockContentImage {
    margin-top:  9.375vw;
    margin-bottom:  9.375vw;
  }
}
.PageGlobalTemplate-blockContentImage:first-child {
  margin-top: 0;
}
.PageGlobalTemplate-blockContentImage:last-child {
  margin-bottom: 0;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block .Video {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block .Video {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block .Video {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block .Video {
    padding-top:  12.5vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block .Video-consent-overlay {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block .Video-consent-overlay {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block .Video-consent-overlay {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block .Video-consent-overlay {
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block .Video-title,
  .PageGlobalTemplate-block .Video-duration {
    padding-left: 0;
  }
}
.PageGlobalTemplate-block .Video-title {
  color: #111112;
}
.PageGlobalTemplate-block .Video-duration {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block .slick-slide {
    width:  464px;
    margin-left:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block .slick-slide {
    width:  24.166666666666668vw;
    margin-left:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block .slick-slide {
    width:  28.064516129032256vw;
    margin-left:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block .slick-slide {
    width:  81.25vw;
    margin-right:  3.125vw;
    margin-left:  3.125vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block .slick-list {
    padding-left:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block .slick-list {
    padding-left:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block .slick-list {
    padding-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block .slick-list {
    padding-left:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-block .slick-slide:last-of-type {
    padding-right:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-block .slick-slide:last-of-type {
    padding-right:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-block .slick-slide:last-of-type {
    padding-right:  0vw;
    width:  28.064516129032256vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-block .slick-slide:last-of-type {
    padding-right:  6.25vw;
  }
}
.PageGlobalTemplate-block .CommonText:first-of-type .CommonText-quote {
  margin-top: 0;
}
.PageGlobalTemplate-block .CommonText:last-of-type .CommonText-quote {
  margin-bottom: 0;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate-button {
    width:  400px;
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate-button {
    width:  20.833333333333336vw;
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate-button {
    width:  24.193548387096772vw;
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate-button {
    width:  87.5vw;
    margin-top:  12.5vw;
  }
}
.PageGlobalTemplate-button .s-button {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate .GlobalSlider-arrowRight,
  .PageGlobalTemplate .GlobalSlider-arrowLeft {
    top:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate .GlobalSlider-arrowRight,
  .PageGlobalTemplate .GlobalSlider-arrowLeft {
    top:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate .GlobalSlider-arrowRight,
  .PageGlobalTemplate .GlobalSlider-arrowLeft {
    top:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate .GlobalSlider-arrowRight,
  .PageGlobalTemplate .GlobalSlider-arrowLeft {
    top:  43.75vw;
  }
}
.PageGlobalTemplate .GlobalSlider-arrowRight_mobile,
.PageGlobalTemplate .GlobalSlider-arrowLeft_mobile {
  display: none !important;
}
.PageGlobalTemplate-locations {
  width: 66%;
  margin-left: auto;
}
@media (max-width: 759px) {
  .PageGlobalTemplate-locations {
    width: 100%;
    margin-left: 0;
  }
}
