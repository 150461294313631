.VideoPlayer {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1400;
}
.VideoPlayer.show {
  opacity: 1;
  pointer-events: all;
}
.VideoPlayer.mobile {
  position: absolute;
  opacity: 1;
  pointer-events: all;
}
.VideoPlayer.mobile .VideoPlayer-iframe {
  position: relative;
  width: 100%;
  height: 100%;
}
.VideoPlayer-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 1920px) {
  .VideoPlayer-iframe {
    width:  1546.6666666666665px;
    height:  870.6666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-iframe {
    width:  80.55555555555556vw;
    height:  45.34722222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-iframe {
    width:  76.61290322580645vw;
    height:  43.14516129032258vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-iframe {
    width:  362.5vw;
    height:  204.0625vw;
  }
}
.VideoPlayer-iframe iframe {
  width: 100%;
  height: 100%;
}
.withDescription .VideoPlayer-iframe {
  left: auto;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (max-width: 759px) {
  .withDescription .VideoPlayer-iframe {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    position: relative;
    right: auto;
    top: auto;
  }
}
@media (min-width: 1920px) {
  .withDescription .VideoPlayer-iframe {
    width:  1413.3333333333333px;
    height:  744px;
    right:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .withDescription .VideoPlayer-iframe {
    width:  73.61111111111111vw;
    height:  38.75vw;
    right:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .withDescription .VideoPlayer-iframe {
    width:  64.51612903225806vw;
    height:  36.29032258064516vw;
    right:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .withDescription .VideoPlayer-iframe {
    width:  87.5vw;
    height:  50vw;
    margin-top:  18.75vw;
    margin-left:  6.25vw;
  }
}
.VideoPlayer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  background-color: rgba(0, 0, 0, 0.95);
  z-index: -1;
  cursor: pointer;
}
.VideoPlayer-close {
  position: absolute;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
@media (min-width: 1920px) {
  .VideoPlayer-close {
    top:  21.333333333333332px;
    right:  21.333333333333332px;
    width:  34.666666666666664px;
    height:  34.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-close {
    top:  1.1111111111111112vw;
    right:  1.1111111111111112vw;
    width:  1.8055555555555556vw;
    height:  1.8055555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-close {
    top:  1.2903225806451613vw;
    right:  1.2903225806451613vw;
    width:  2.096774193548387vw;
    height:  2.096774193548387vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-close {
    top:  6.25vw;
    right:  4.6875vw;
    width:  8.125vw;
    height:  8.125vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-close {
    position: fixed;
  }
}
.isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--1,
.isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
}
@media (min-width: 1920px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--1,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--1 {
    width:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--1,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--1 {
    width:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--1,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--1 {
    width:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--1,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--1 {
    width:  10.625vw;
  }
}
.isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--2,
.isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
}
@media (min-width: 1920px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--2,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--2 {
    width:  45.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--2,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--2 {
    width:  2.361111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--2,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--2 {
    width:  2.7419354838709675vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .VideoPlayer-close:hover .VideoPlayer-closeItem--2,
  .isMobile .VideoPlayer-close:active .VideoPlayer-closeItem--2 {
    width:  10.625vw;
  }
}
.VideoPlayer-closeItem {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .VideoPlayer-closeItem {
    width:  34.666666666666664px;
    height:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-closeItem {
    width:  1.8055555555555556vw;
    height:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-closeItem {
    width:  2.096774193548387vw;
    height:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-closeItem {
    width:  6.25vw;
    height:  0.625vw;
  }
}
.VideoPlayer-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.VideoPlayer-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.VideoPlayer-description {
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (min-width: 1920px) {
  .VideoPlayer-description {
    width:  346.66666666666663px;
    min-height:  744px;
    left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-description {
    width:  18.055555555555557vw;
    min-height:  38.75vw;
    left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-description {
    width:  20.967741935483872vw;
    min-height:  36.29032258064516vw;
    left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-description {
    margin-left:  6.25vw;
    margin-top:  6.25vw;
    width:  87.5vw;
    padding-bottom:  6.25vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-description {
    height: auto;
    overflow-y: scroll;
    position: relative;
    top: auto;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    max-height: calc(100% - 78.125vw);
  }
}
@media (min-width: 1920px) {
  .VideoPlayer-descriptionVideoTime {
    margin-top:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-descriptionVideoTime {
    margin-top:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-descriptionVideoTime {
    margin-top:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionVideoTime {
    margin-top:  1.5625vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionVideoTime {
    line-height: 1;
  }
}
.VideoPlayer-descriptionText {
  color: #A6A8B3;
}
@media (min-width: 1920px) {
  .VideoPlayer-descriptionText {
    margin-top:  53.33333333333333px;
    margin-bottom:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-descriptionText {
    margin-top:  2.7777777777777777vw;
    margin-bottom:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-descriptionText {
    margin-top:  3.225806451612903vw;
    margin-bottom:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionText {
    margin-top:  6.25vw;
  }
}
.VideoPlayer-descriptionLinks {
  margin-top: auto;
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLinks {
    margin-top: 6.25vw;
  }
}
.VideoPlayer-descriptionLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-descriptionLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-descriptionLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .VideoPlayer-descriptionLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .VideoPlayer-descriptionLink:hover,
.isNotDesktop .VideoPlayer-descriptionLink:active {
  text-decoration: underline;
}
.isDesktop .VideoPlayer-descriptionLink:hover:before,
.isNotDesktop .VideoPlayer-descriptionLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .VideoPlayer-descriptionLink:hover:before,
  .isNotDesktop .VideoPlayer-descriptionLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLink {
    letter-spacing: 0;
  }
}
.VideoPlayer-descriptionLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .VideoPlayer-descriptionLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-descriptionLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-descriptionLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .VideoPlayer-descriptionLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .VideoPlayer-descriptionLink {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .VideoPlayer-descriptionLink {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .VideoPlayer-descriptionLink {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLink {
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .VideoPlayer-descriptionLink {
    line-height: 1;
  }
}
