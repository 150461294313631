@media (min-width: 1920px) {
  .PageCareersApprenticeship .GlobalSlider-arrowRight,
  .PageCareersApprenticeship .GlobalSlider-arrowLeft {
    top:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersApprenticeship .GlobalSlider-arrowRight,
  .PageCareersApprenticeship .GlobalSlider-arrowLeft {
    top:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersApprenticeship .GlobalSlider-arrowRight,
  .PageCareersApprenticeship .GlobalSlider-arrowLeft {
    top:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .PageCareersApprenticeship .GlobalSlider-arrowRight,
  .PageCareersApprenticeship .GlobalSlider-arrowLeft {
    top:  50vw;
  }
}
.PageCareersApprenticeship .GlobalMenu,
.PageCareersApprenticeship .GlobalMenuMobile {
  display: none;
}
@media (min-width: 1920px) {
  .PageCareersApprenticeship .PageGlobalTemplate-button {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersApprenticeship .PageGlobalTemplate-button {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersApprenticeship .PageGlobalTemplate-button {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageCareersApprenticeship .PageGlobalTemplate-button {
    margin-top:  9.375vw;
  }
}
