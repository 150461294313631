.PressDownloadsItems {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.PressDownloadsItems-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.PressDownloadsItems-item {
  width: 31.03448276%;
  margin-left: 3.44827586%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  will-change: transform;
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0);
}
@media (max-width: 759px) {
  .PressDownloadsItems-item {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    width: 100%;
    margin-left: 0;
  }
}
.PressDownloadsItems-item.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.PressDownloadsItems-item:nth-child(3n + 1) {
  margin-left: 0;
}
@media (min-width: 1920px) {
  .PressDownloadsItems-item {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PressDownloadsItems-item {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PressDownloadsItems-item {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PressDownloadsItems-item {
    margin-top:  6.25vw;
    margin-bottom:  6.25vw;
  }
}
.isDesktop .PressDownloadsItems-item:hover .PressDownloadsItems-itemImgOverlay,
.isNotDesktop .PressDownloadsItems-item:active .PressDownloadsItems-itemImgOverlay {
  opacity: 1;
  pointer-events: all;
}
.isDesktop .PressDownloadsItems-item:hover .PressDownloadsItems-itemImgOverlaySepar,
.isNotDesktop .PressDownloadsItems-item:active .PressDownloadsItems-itemImgOverlaySepar {
  opacity: 1;
}
.PressDownloadsItems-itemImg {
  position: relative;
  width: 100%;
  cursor: pointer;
  -webkit-box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.4);
          box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.4);
}
.PressDownloadsItems-itemImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 77.77777778%;
}
@media (max-width: 759px) {
  .PressDownloadsItems-itemImg {
    position: relative;
    -webkit-box-shadow: 0 3.125vw 12.5vw 0px rgba(0, 0, 0, 0.4);
            box-shadow: 0 3.125vw 12.5vw 0px rgba(0, 0, 0, 0.4);
  }
  .PressDownloadsItems-itemImg:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 78.57142857%;
  }
}
.PressDownloadsItems-itemImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  z-index: 1;
}
.PressDownloadsItems-itemImgPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  background-color: #6F7380;
}
.PressDownloadsItems-itemImgPlaceholder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.PressDownloadsItems-itemImgOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  z-index: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .PressDownloadsItems-itemImgOverlay:hover .PressDownloadsItems-itemImgOverlayBg,
.isNotDesktop .PressDownloadsItems-itemImgOverlay:active .PressDownloadsItems-itemImgOverlayBg {
  opacity: 0.94;
}
.PressDownloadsItems-itemImgOverlay--full {
  width: 100%;
}
.PressDownloadsItems-itemImgOverlay--left {
  left: 0;
}
.PressDownloadsItems-itemImgOverlay--right {
  right: 0;
}
.PressDownloadsItems-itemImgOverlaySepar {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  left: 50%;
  width: 1px;
  pointer-events: none;
  z-index: 3;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.PressDownloadsItems-itemImgOverlayText {
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 2;
}
.PressDownloadsItems-itemImgOverlayBg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #151928;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.PressDownloadsItems-itemName {
  z-index: 2;
  position: relative;
}
@media (min-width: 1920px) {
  .PressDownloadsItems-itemName {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PressDownloadsItems-itemName {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PressDownloadsItems-itemName {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PressDownloadsItems-itemName {
    margin-top:  6.25vw;
  }
}
.PressDownloadsItems-itemPosition {
  z-index: 2;
  position: relative;
  color: #A6A8B3;
}
