.InspirationCases {
  background-color: #F6F6F6;
}
.brand--thorn .InspirationCases {
  background-color: #06121D;
}
.InspirationCases-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 759px) {
  .InspirationCases-wrapper {
    display: block;
  }
}
.InspirationCases-case {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.InspirationCases-case.hide {
  display: none;
}
.InspirationCases-caseBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
.InspirationCases-caseBg:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
@media (min-width: 1920px) {
  .InspirationCases-caseBg:before {
    height: 213.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseBg:before {
    height: 11.11111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseBg:before {
    height: 12.90322581vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseBg:before {
    height: 50vw;
  }
}
.InspirationCases-caseBg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
          transform: scale(1.01) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.isDesktop .InspirationCases-case:hover .InspirationCases-caseBg img,
.isNotDesktop .InspirationCases-case:active .InspirationCases-caseBg img {
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
.InspirationCases-case.download .InspirationCases-caseBg {
  background-color: #111112;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.InspirationCases-case.download .InspirationCases-caseBg:before {
  display: none;
}
.isDesktop .InspirationCases-case.download:hover .InspirationCases-caseBg,
.isNotDesktop .InspirationCases-case.download:active .InspirationCases-caseBg {
  background-color: #3D3D44;
}
.isDesktop .InspirationCases-case.download:hover .InspirationCases-caseDownloadLink,
.isNotDesktop .InspirationCases-case.download:active .InspirationCases-caseDownloadLink {
  text-decoration: underline;
}
.InspirationCases-case:nth-of-type(4n + 2),
.InspirationCases-case:nth-of-type(4n + 3),
.InspirationCases-case:nth-of-type(4n + 4),
.InspirationCases-case:nth-of-type(4n + 5) {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
}
.isIE .InspirationCases-case:nth-of-type(4n + 2),
.isIE .InspirationCases-case:nth-of-type(4n + 3),
.isIE .InspirationCases-case:nth-of-type(4n + 4),
.isIE .InspirationCases-case:nth-of-type(4n + 5) {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  max-width: 25%;
  min-width: 25%;
}
.InspirationCases-case:nth-of-type(6n + 6),
.InspirationCases-case:nth-of-type(6n + 7),
.InspirationCases-case:nth-of-type(1) {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
.isIE .InspirationCases-case:nth-of-type(6n + 6),
.isIE .InspirationCases-case:nth-of-type(6n + 7),
.isIE .InspirationCases-case:nth-of-type(1) {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  max-width: 50%;
  min-width: 50%;
}
.InspirationCases-case:nth-of-type(3n + 1) {
  border-left: none;
}
.InspirationCases-case:nth-of-type(3n + 3) {
  border-right: none;
}
@media (min-width: 1920px) {
  .InspirationCases-case {
    height: 637.33333333px;
    padding: 53.33333333px 60px 44px 52px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-case {
    height: 33.19444444vw;
    padding: 2.77777778vw 3.125vw 2.29166667vw 2.70833333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-case {
    height: 38.5483871vw;
    padding: 3.22580645vw 3.62903226vw 2.66129032vw 3.14516129vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-case {
    height: 62.5vw;
    padding: 6.25vw 6.25vw 5vw;
    border: 1px solid transparent;
  }
  .InspirationCases-case:nth-of-type(3n + 1) {
    border-left: 1px solid transparent;
  }
  .InspirationCases-case:nth-of-type(3n + 3) {
    border-right: 1px solid transparent;
  }
}
.InspirationCases-caseDownloadTitle {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .InspirationCases-caseDownloadTitle {
    padding-top: 69.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseDownloadTitle {
    padding-top: 3.61111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseDownloadTitle {
    padding-top: 4.19354839vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseDownloadTitle {
    padding-top: 9.375vw;
  }
}
.InspirationCases-caseDownloadLink {
  color: #FFFFFF;
  margin-top: auto;
  letter-spacing: normal;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isDesktop .InspirationCases-caseDownloadLink:hover,
.isNotDesktop .InspirationCases-caseDownloadLink:active {
  text-decoration: underline;
}
.InspirationCases-caseDownloadLinkIcon {
  position: relative;
  display: block;
}
@media (min-width: 1920px) {
  .InspirationCases-caseDownloadLinkIcon {
    width: 21.33333333px;
    height: 26.66666667px;
    margin-right: 18.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseDownloadLinkIcon {
    width: 1.11111111vw;
    height: 1.38888889vw;
    margin-right: 0.97222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseDownloadLinkIcon {
    width: 1.29032258vw;
    height: 1.61290323vw;
    margin-right: 1.12903226vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseDownloadLinkIcon {
    width: 5vw;
    height: 6.25vw;
    margin-right: 3.125vw;
  }
}
.InspirationCases-caseDownloadLinkIcon svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .InspirationCases-caseDownloadLinkText,
  .InspirationCases-caseDownloadLinkFormat {
    padding-top: 5.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseDownloadLinkText,
  .InspirationCases-caseDownloadLinkFormat {
    padding-top: 0.27777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseDownloadLinkText,
  .InspirationCases-caseDownloadLinkFormat {
    padding-top: 0.32258065vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseDownloadLinkText,
  .InspirationCases-caseDownloadLinkFormat {
    padding-top: 0;
    font-size: 3.75vw;
  }
}
.InspirationCases-caseLabels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: absolute;
  z-index: 11;
}
@media (min-width: 1920px) {
  .InspirationCases-caseLabels {
    top: 53.33333333px;
    left: 52px;
    right: 52px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseLabels {
    top: 2.77777778vw;
    left: 2.70833333vw;
    right: 2.70833333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseLabels {
    top: 3.22580645vw;
    left: 3.14516129vw;
    right: 3.14516129vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseLabels {
    top: 6.25vw;
    left: 6.25vw;
    right: 6.25vw;
  }
}
.InspirationCases-caseLabel {
  color: #FFFFFF;
  border-color: #FFFFFF;
  pointer-events: all;
  -webkit-transition: 0.15s background-color, 0.15s color;
  transition: 0.15s background-color, 0.15s color;
}
.isDesktop .InspirationCases-caseLabel:hover {
  background-color: #FFFFFF;
  color: #111112;
}
@media (min-width: 1920px) {
  .InspirationCases-caseLabel {
    margin-left: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseLabel {
    margin-left: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseLabel {
    margin-left: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseLabel {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2.1875vw 3.125vw 1.875vw;
    font-size: 1.875vw;
    margin-left: 3.125vw;
  }
}
.InspirationCases-caseLabel:first-of-type {
  margin-left: 0;
}
.InspirationCases-caseDescription {
  color: #FFFFFF;
  letter-spacing: normal;
  position: absolute;
  z-index: 11;
  left: 0;
  width: 100%;
}
@media (min-width: 1920px) {
  .InspirationCases-caseDescription {
    bottom:  44px;
    padding:  0 60px 0 52px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseDescription {
    bottom:  2.291666666666667vw;
    padding:  0vw 3.125vw 0vw 2.7083333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseDescription {
    bottom:  2.661290322580645vw;
    padding:  0vw 3.629032258064516vw 0vw 3.1451612903225805vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseDescription {
    bottom:  5vw;
    padding:  0vw 6.25vw;
  }
}
.InspirationCases-caseLocation {
  text-transform: none;
}
.InspirationCases-caseProductsTitle {
  text-transform: none;
}
.InspirationCases-caseProductsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 1920px) {
  .InspirationCases-caseProductsWrapper {
    margin-top: 9.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-caseProductsWrapper {
    margin-top: 0.48611111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-caseProductsWrapper {
    margin-top: 0.56451613vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-caseProductsWrapper {
    margin-top: 1.25vw;
  }
}
.InspirationCases-caseProduct {
  text-decoration: none;
  color: #FFFFFF;
}
.InspirationCases-caseProductWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  word-break: keep-all;
}
.InspirationCases-flexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  width: auto;
  height: auto;
  min-height: 0;
}
.InspirationCases-showMore {
  margin: 0 auto;
  text-transform: uppercase;
}
.InspirationCases-showMore.s-button-white {
  display: none;
}
.InspirationCases-showMore.s-button {
  display: block;
}
.brand--thorn .InspirationCases-showMore.s-button {
  display: none;
}
.brand--thorn .InspirationCases-showMore.s-button-white {
  display: block;
}
@media (min-width: 1920px) {
  .InspirationCases-showMore {
    width: 320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-showMore {
    width: 16.66666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-showMore {
    width: 19.35483871vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-showMore {
    width: 75vw;
  }
}
.InspirationCases-showMoreWrapper {
  width: 100%;
}
@media (min-width: 1920px) {
  .InspirationCases-showMoreWrapper {
    padding: 81.33333333px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-showMoreWrapper {
    padding: 4.23611111vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-showMoreWrapper {
    padding: 4.91935484vw 0;
  }
}
@media (max-width: 759px) {
  .InspirationCases-showMoreWrapper {
    padding: 12.8125vw 0;
  }
}
.InspirationCases-noItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50vh;
}
@media (max-width: 759px) {
  .InspirationCases-noItems {
    height: 60vh;
    padding: 0 6.25vw;
  }
}
.InspirationCases-noItems.hide {
  display: none;
}
.InspirationCases-noItems-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-wrapper {
    width: 100%;
    min-height: 62.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-wrapper {
    width: 45.96774194vw;
    min-height: 16.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-wrapper {
    width: 39.58333333vw;
    min-height: 13.88888889vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-wrapper {
    width: 760px;
    min-height: 266.66666667px;
  }
}
.InspirationCases-noItems-title {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  color: #111112;
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-title {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-title {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-title {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .InspirationCases-noItems-title {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .InspirationCases-noItems-title {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.brand--thorn .InspirationCases-noItems-title {
  color: #FFFFFF;
}
.InspirationCases-noItems-text {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  color: #6F7380;
  text-align: center;
  text-transform: none;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-text {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .InspirationCases-noItems-text {
    text-align: center;
  }
}
.InspirationCases-noItems-clear {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  width: 100%;
  background-color: #111112;
  color: #FFFFFF;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-clear {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-clear {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-clear {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-clear {
    font-size: 16px;
  }
}
.InspirationCases-noItems-clear:visited {
  color: #FFFFFF;
}
.isDesktop .InspirationCases-noItems-clear:hover,
.isNotDesktop .InspirationCases-noItems-clear:active {
  background-color: transparent;
  color: #111112;
  border: 1px solid #111112;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-clear {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-clear {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-clear {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-clear {
    height: 53.33333333px;
  }
}
.InspirationCases-noItems-clear.disabled {
  pointer-events: none;
  background-color: #A6A8B3;
  color: #FFFFFF;
  border: 1px solid #A6A8B3;
}
.brand--thorn .InspirationCases-noItems-clear {
  display: none;
}
.InspirationCases-noItems-clear_white {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  width: 100%;
  background-color: #FFFFFF;
  color: #111112;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
  display: none;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-clear_white {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-clear_white {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-clear_white {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-clear_white {
    font-size: 16px;
  }
}
.InspirationCases-noItems-clear_white:visited {
  color: #111112;
}
.isDesktop .InspirationCases-noItems-clear_white:hover,
.isNotDesktop .InspirationCases-noItems-clear_white:active {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-clear_white {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-clear_white {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-clear_white {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-clear_white {
    height: 53.33333333px;
  }
}
.brand--thorn .InspirationCases-noItems-clear_white {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.InspirationCases-noItems-clear,
.InspirationCases-noItems-clear_white {
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .InspirationCases-noItems-clear,
  .InspirationCases-noItems-clear_white {
    width: 75vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCases-noItems-clear,
  .InspirationCases-noItems-clear_white {
    width: 19.35483871vw;
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCases-noItems-clear,
  .InspirationCases-noItems-clear_white {
    width: 16.66666667vw;
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCases-noItems-clear,
  .InspirationCases-noItems-clear_white {
    width: 320px;
    margin-top: 53.33333333px;
  }
}
.InspirationCases-caseDownloadHref,
.InspirationCases-caseUrl {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
