.PageCareersLanding .PageLanding-content {
  padding-bottom: 0;
}
.PageCareersLanding .PageLanding-blockBg_mobile:before {
  content: '';
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  opacity: 0.2;
}
@media (min-width: 1920px) {
  .PageCareersLanding-workingAtZumtobel {
    padding:  0 186.66666666666666px;
    margin-top:  266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-workingAtZumtobel {
    padding:  0vw 9.722222222222223vw;
    margin-top:  13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-workingAtZumtobel {
    padding:  0vw 11.29032258064516vw;
    margin-top:  16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobel {
    padding:  0vw 6.25vw;
    margin-top:  31.25vw;
  }
}
.PageCareersLanding-workingAtZumtobelWrapper {
  border-top: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .PageCareersLanding-workingAtZumtobelWrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-workingAtZumtobelWrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-workingAtZumtobelWrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelWrapper {
    padding-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelWrapper {
    display: block;
  }
}
.PageCareersLanding-workingAtZumtobelTitle {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
.PageCareersLanding-workingAtZumtobelTextBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
.PageCareersLanding-workingAtZumtobelText {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelText {
    margin-top: 12.5vw;
  }
}
.PageCareersLanding-workingAtZumtobelLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-workingAtZumtobelLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-workingAtZumtobelLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-workingAtZumtobelLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .PageCareersLanding-workingAtZumtobelLink:hover,
.isNotDesktop .PageCareersLanding-workingAtZumtobelLink:active {
  text-decoration: underline;
}
.isDesktop .PageCareersLanding-workingAtZumtobelLink:hover:before,
.isNotDesktop .PageCareersLanding-workingAtZumtobelLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PageCareersLanding-workingAtZumtobelLink:hover:before,
  .isNotDesktop .PageCareersLanding-workingAtZumtobelLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelLink {
    letter-spacing: 0;
  }
}
.PageCareersLanding-workingAtZumtobelLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PageCareersLanding-workingAtZumtobelLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-workingAtZumtobelLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-workingAtZumtobelLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-workingAtZumtobelLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-workingAtZumtobelLink {
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-workingAtZumtobelLink {
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-workingAtZumtobelLink {
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobelLink {
    margin-top:  12.5vw;
  }
}
.PageCareersLanding-workingAtZumtobel .s-button-white {
  color: #111112;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .PageCareersLanding-workingAtZumtobel .s-button-white {
    margin-top:  53.33333333333333px;
    width:  320px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-workingAtZumtobel .s-button-white {
    margin-top:  2.7777777777777777vw;
    width:  16.666666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-workingAtZumtobel .s-button-white {
    margin-top:  3.225806451612903vw;
    width:  19.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-workingAtZumtobel .s-button-white {
    margin-top:  12.5vw;
  }
}
.PageCareersLanding-footer {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 759px) {
  .PageCareersLanding-footer {
    min-height: 0;
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-footer {
    padding-left:  186.66666666666666px;
    padding-right:  186.66666666666666px;
    padding-top:  1466.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-footer {
    padding-left:  9.722222222222223vw;
    padding-right:  9.722222222222223vw;
    padding-top:  76.38888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-footer {
    padding-left:  11.29032258064516vw;
    padding-right:  11.29032258064516vw;
    padding-top:  84.6774193548387vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-footer {
    padding-left:  6.25vw;
    padding-right:  6.25vw;
    padding-top:  171.875vw;
  }
}
.PageCareersLanding-footer .CommonText-quoteText {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageCareersLanding-footer .CommonText-quoteText {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-footer .CommonText-quoteText {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-footer .CommonText-quoteText {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-footer .CommonText-quoteText {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .PageCareersLanding-footer .CommonText-quoteText {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-footer .CommonText-quoteText:before {
    left:  -40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-footer .CommonText-quoteText:before {
    left:  -2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-footer .CommonText-quoteText:before {
    left:  -2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-footer .CommonText-quoteText:before {
    left:  -9.375vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-footer .CommonText-quoteText:before {
    left: -4.6875vw;
  }
}
.PageCareersLanding-footer .CommonText-quoteAuthor {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageCareersLanding-footer .CommonText-quoteAuthor {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-footer .CommonText-quoteAuthor {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-footer .CommonText-quoteAuthor {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-footer .CommonText-quoteAuthor {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .PageCareersLanding-ways {
    padding:  0 186.66666666666666px;
    margin-top:  200px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding-ways {
    padding:  0vw 9.722222222222223vw;
    margin-top:  10.416666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding-ways {
    padding:  0vw 11.29032258064516vw;
    margin-top:  12.096774193548386vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding-ways {
    padding:  0vw 6.25vw;
    margin-top:  31.25vw;
  }
}
.PageCareersLanding .PageLanding-block .PageLanding-blockBg,
.PageCareersLanding .PageLanding-block .PageLanding-blockBg_mobile {
  background: #5B5C60;
  width: 100%;
}
@media (min-width: 1920px) {
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg,
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  2600px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg,
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  135.41666666666669vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg,
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  157.25806451612902vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg,
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg_mobile {
    height:  609.375vw;
  }
}
@media (max-width: 759px) {
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg img,
  .PageCareersLanding .PageLanding-block .PageLanding-blockBg_mobile img {
    height: 312.5vw;
  }
}
