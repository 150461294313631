.PageInspiration {
  background-color: #F6F6F6;
}
.brand--thorn .PageInspiration {
  background-color: #06121D;
}
.PageInspiration-content {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.04666667em;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .PageInspiration-content {
    font-size:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInspiration-content {
    font-size:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInspiration-content {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .PageInspiration-content {
    font-size:  5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInspiration-content {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.PageInspiration-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .PageInspiration-head {
    height:  321.3333333333333px;
    width:  1546.6666666666665px;
    padding:  116px 0 113.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInspiration-head {
    height:  16.73611111111111vw;
    width:  80.55555555555556vw;
    padding:  6.041666666666667vw 0vw 5.902777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInspiration-head {
    height:  19.43548387096774vw;
    width:  93.54838709677419vw;
    padding:  7.016129032258064vw 0vw 6.854838709677419vw;
  }
}
@media (max-width: 759px) {
  .PageInspiration-head {
    padding:  21.875vw 6.25vw 12.5vw;
  }
}
@media (max-width: 759px) {
  .PageInspiration-head {
    display: block;
    width: 100%;
    margin: 0;
  }
}
.PageInspiration-title {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
.brand--thorn .PageInspiration-title {
  color: #FFFFFF;
}
.PageInspiration-filters {
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  letter-spacing: 0;
}
@media (min-width: 1920px) {
  .PageInspiration-filters {
    padding-bottom: 6.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInspiration-filters {
    padding-bottom: 0.34722222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInspiration-filters {
    padding-bottom: 0.40322581vw;
  }
}
@media (max-width: 759px) {
  .PageInspiration-filters {
    margin-top: 6.25vw;
    padding-bottom: 0;
  }
}
.PageInspiration-filters .Filters {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
