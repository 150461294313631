.DownloadLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (min-width: 1920px) {
  .DownloadLinks {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadLinks {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadLinks {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .DownloadLinks {
    margin-top:  0vw;
  }
}
.DownloadLinks:first-child {
  margin-top: 0;
}
@media (max-width: 759px) {
  .DownloadLinks {
    display: block;
  }
}
.DownloadLinks-downloads {
  width: 100%;
}
.DownloadLinks-downloadsItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  letter-spacing: normal;
  color: #111112;
  border-bottom: 1px solid #E7E7E7;
}
@media (min-width: 1920px) {
  .DownloadLinks-downloadsItem {
    padding:  18.666666666666664px 0 21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadLinks-downloadsItem {
    padding:  0.9722222222222223vw 0vw 1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadLinks-downloadsItem {
    padding:  1.129032258064516vw 0vw 1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItem {
    padding:  2.5vw 0vw 3.125vw;
  }
}
.isDesktop .DownloadLinks-downloadsItem:hover,
.isNotDesktop .DownloadLinks-downloadsItem:active {
  border-bottom: 1px solid #111112;
}
.DownloadLinks-downloadsItem:visited {
  text-decoration: none;
}
.DownloadLinks-downloadsItemTitle {
  color: #111112;
  text-decoration: none;
  text-transform: none;
  max-width: 80%;
  width: 100%;
}
.DownloadLinks-downloadsItemTitle:visited {
  color: #111112;
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItemTitle {
    font-size: 4.375vw;
    line-height: 1;
  }
}
.DownloadLinks-downloadsItemDownload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .DownloadLinks-downloadsItemDownload {
    width:  60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadLinks-downloadsItemDownload {
    width:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadLinks-downloadsItemDownload {
    width:  3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItemDownload {
    width:  14.0625vw;
  }
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItemDownload {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: auto;
  }
}
.DownloadLinks-downloadsItemDownloadExtension {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    line-height: normal;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    margin-left:  6.666666666666666px;
    font-size:  12px;
    padding-top:  5.333333333333333px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    margin-left:  0.3472222222222222vw;
    font-size:  0.625vw;
    padding-top:  0.2777777777777778vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    margin-left:  0.4032258064516129vw;
    font-size:  0.7258064516129032vw;
    padding-top:  0.3225806451612903vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .DownloadLinks-downloadsItemDownloadExtension {
    margin-left:  1.5625vw;
    font-size:  2.8125vw;
    padding-top:  1.25vw;
  }
}
.DownloadLinks-downloadsItemDownloadIcon {
  position: relative;
  display: block;
}
@media (min-width: 1920px) {
  .DownloadLinks-downloadsItemDownloadIcon {
    width:  21.333333333333332px;
    height:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .DownloadLinks-downloadsItemDownloadIcon {
    width:  1.1111111111111112vw;
    height:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .DownloadLinks-downloadsItemDownloadIcon {
    width:  1.2903225806451613vw;
    height:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .DownloadLinks-downloadsItemDownloadIcon {
    width:  3.75vw;
    height:  4.6875vw;
  }
}
.DownloadLinks-downloadsItemDownloadIcon svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
