.PageCompanyBoard-delegates {
  color: #A6A8B3;
  border-bottom: 1px solid #E7E7E7;
}
@media (min-width: 1920px) {
  .PageCompanyBoard-delegates {
    margin-top:  93.33333333333333px;
    padding-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCompanyBoard-delegates {
    margin-top:  4.861111111111112vw;
    padding-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCompanyBoard-delegates {
    margin-top:  5.64516129032258vw;
    padding-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageCompanyBoard-delegates {
    margin-top:  21.875vw;
    padding-bottom:  6.25vw;
  }
}
.PageCompanyBoard .Accordion-content {
  padding-left: 0;
  padding-right: 0;
}
