.ContactSupplier {
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .ContactSupplier-mobileText {
    width: 46.875vw;
    line-height: 6.25vw;
  }
}
.ContactSupplier-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactSupplier-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactSupplier-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactSupplier-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    top: 7.8125vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactSupplier-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactSupplier-text {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactSupplier-text {
    margin-top: 6.875vw;
    padding: 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-text {
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-text {
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-text {
    margin-top: 29.33333333px;
  }
}
.ContactSupplier-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactSupplier-text a:hover,
.isNotDesktop .ContactSupplier-text a:active {
  text-decoration: none;
}
@media (max-width: 759px) {
  .ContactSupplier-form {
    margin-top: 3.125vw;
    padding: 0 6.25vw;
  }
}
.ContactSupplier-form input,
.ContactSupplier-form textarea {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
@media (max-width: 759px) {
  .ContactSupplier-form input,
  .ContactSupplier-form textarea {
    font-size: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-form input,
  .ContactSupplier-form textarea {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-form input,
  .ContactSupplier-form textarea {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-form input,
  .ContactSupplier-form textarea {
    font-size: 26.66666667px;
  }
}
.ContactSupplier-form input:focus,
.ContactSupplier-form textarea:focus {
  outline: 0;
  border: 1px solid #111112;
}
.ContactSupplier-formInput {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  color: #111112;
}
@media (max-width: 759px) {
  .ContactSupplier-formInput {
    height: 12.5vw;
    margin-bottom: 4.375vw;
    margin-top: 1.25vw;
    padding: 0 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formInput {
    height: 3.22580645vw;
    margin-bottom: 1.12903226vw;
    margin-top: 0.32258065vw;
    padding: 0 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formInput {
    height: 2.77777778vw;
    margin-bottom: 0.97222222vw;
    margin-top: 0.27777778vw;
    padding: 0 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formInput {
    height: 53.33333333px;
    margin-bottom: 18.66666667px;
    margin-top: 5.33333333px;
    padding: 0 13.33333333px;
  }
}
.ContactSupplier-formInput:focus {
  outline: 0;
}
@media (max-width: 759px) {
  .ContactSupplier-required {
    margin-left: -3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-required {
    margin-left: -1.0483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-required {
    margin-left: -0.90277778vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-required {
    margin-left: -17.33333333px;
  }
}
.ContactSupplier-formInputTip {
  text-transform: uppercase;
  display: block;
  color: #6F7380;
}
.ContactSupplier-formMessage {
  resize: none;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  color: #111112;
}
.ContactSupplier-formMessage:focus {
  outline: 0;
}
@media (max-width: 759px) {
  .ContactSupplier-formMessage {
    height: 75vw;
    margin-bottom: 4.375vw;
    margin-top: 1.25vw;
    padding: 3.125vw 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formMessage {
    height: 19.35483871vw;
    margin-bottom: 1.12903226vw;
    margin-top: 0.32258065vw;
    padding: 0.80645161vw 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formMessage {
    height: 16.66666667vw;
    margin-bottom: 0.97222222vw;
    margin-top: 0.27777778vw;
    padding: 0.69444444vw 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formMessage {
    height: 320px;
    margin-bottom: 18.66666667px;
    margin-top: 5.33333333px;
    padding: 13.33333333px 13.33333333px;
  }
}
@media (max-width: 759px) {
  .ContactSupplier-formSelect {
    margin-bottom: 12.5vw;
    margin-top: 3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formSelect {
    margin-bottom: 3.22580645vw;
    margin-top: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formSelect {
    margin-bottom: 2.77777778vw;
    margin-top: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formSelect {
    margin-bottom: 53.33333333px;
    margin-top: 13.33333333px;
  }
}
.ContactSupplier-formContCaptcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-bottom: 10px;
}
@media (max-width: 759px) {
  .ContactSupplier-formContCaptcha {
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formContCaptcha {
    margin-bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formContCaptcha {
    margin-bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formContCaptcha {
    margin-bottom: 53.33333333px;
  }
}
.ContactSupplier-formCaptcha {
  background: #F6F6F6;
  border: 1px solid #C6C6C6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .ContactSupplier-formCaptcha {
    display: block;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 12.5vw;
    padding: 3.75vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formCaptcha {
    width: 45.16129032vw;
    height: 8.06451613vw;
    margin-top: 3.22580645vw;
    padding: 0.96774194vw 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formCaptcha {
    width: 38.88888889vw;
    height: 6.94444444vw;
    margin-top: 2.77777778vw;
    padding: 0.83333333vw 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formCaptcha {
    width: 746.66666667px;
    height: 133.33333333px;
    margin-top: 53.33333333px;
    padding: 16px 26.66666667px;
  }
}
.ContactSupplier-formInputCaptchaWrapper {
  position: absolute;
  right: 20px;
}
@media (max-width: 759px) {
  .ContactSupplier-formInputCaptchaWrapper {
    position: relative;
    width: 100%;
    right: unset;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formInputCaptchaWrapper {
    width: 19.35483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formInputCaptchaWrapper {
    width: 16.66666667vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formInputCaptchaWrapper {
    width: 320px;
  }
}
@media (max-width: 759px) {
  .ContactSupplier-buttonWrapper {
    padding-bottom: 25vw;
  }
}
.ContactSupplier-formSubmit {
  margin-left: auto;
}
@media (max-width: 759px) {
  .ContactSupplier-formSubmit {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactSupplier-formSubmit {
    width: 20.96774194vw;
    height: 3.22580645vw;
    margin-bottom: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactSupplier-formSubmit {
    width: 18.05555556vw;
    height: 2.77777778vw;
    margin-bottom: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ContactSupplier-formSubmit {
    width: 346.66666667px;
    height: 53.33333333px;
    margin-bottom: 106.66666667px;
  }
}
.ContactSupplier-responseMessage {
  color: #6F7380;
}
.ContactSupplier-responseMessage.hide {
  display: none;
}
@media (max-width: 759px) {
  .ContactSupplier-responseMessage {
    padding: 6.25vw 6.25vw 0;
    text-align: center;
  }
}
