.Tabs-tab {
  width: 100%;
  position: relative;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .Tabs-tab:hover {
  opacity: 0.7;
}
.isDesktop .Tabs-tab.active:hover {
  opacity: 1;
}
@media (min-width: 1920px) {
  .Tabs-tab {
    padding:  6.666666666666666px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Tabs-tab {
    padding:  0.3472222222222222vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Tabs-tab {
    padding:  0.4032258064516129vw 0vw;
  }
}
@media (max-width: 759px) {
  .Tabs-tab {
    padding:  1.5625vw 0vw;
  }
}
.Tabs-tab:after {
  position: absolute;
  content: '';
  bottom: 0;
  height: 1px;
  background-color: #E7E7E7;
}
@media (min-width: 1920px) {
  .Tabs-tab:after {
    left:  13.333333333333332px;
    right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Tabs-tab:after {
    left:  0.6944444444444444vw;
    right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Tabs-tab:after {
    left:  0.8064516129032258vw;
    right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Tabs-tab:after {
    left:  3.125vw;
    right:  3.125vw;
  }
}
.Tabs-tabText {
  text-transform: uppercase;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .Tabs-tabText {
    padding:  10.666666666666666px 13.333333333333332px 8px;
    border-radius:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Tabs-tabText {
    padding:  0.5555555555555556vw 0.6944444444444444vw 0.4166666666666667vw;
    border-radius:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Tabs-tabText {
    padding:  0.6451612903225806vw 0.8064516129032258vw 0.4838709677419355vw;
    border-radius:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .Tabs-tabText {
    padding:  2.5vw 3.125vw 1.875vw;
    border-radius:  9.375vw;
  }
}
.active .Tabs-tabText {
  background-color: #111112;
  color: #FFFFFF;
}
