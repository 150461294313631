.LayoutsThreeColumns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .LayoutsThreeColumns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 759px) {
  .LayoutsThreeColumns-text {
    width: 100%;
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsThreeColumns-text {
    width: 17.74193548vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsThreeColumns-text {
    width: 15.27777778vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsThreeColumns-text {
    width: 293.33333333px;
  }
}
@media (max-width: 759px) {
  .LayoutsThreeColumns .richText-title {
    font-size: 6.25vw;
    margin-bottom: 4.6875vw;
  }
}
.LayoutsThreeColumns .richText-description a {
  color: #111112;
}
.LayoutsThreeColumns .richText-description a:visited {
  color: #111112;
}
.isDesktop .LayoutsThreeColumns .richText-description a:hover {
  text-decoration: none;
}
@media (min-width: 1920px) {
  .LayoutsThreeColumns-description {
    margin-top:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsThreeColumns-description {
    margin-top:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsThreeColumns-description {
    margin-top:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .LayoutsThreeColumns-description {
    margin-top:  4.6875vw;
  }
}
@media (max-width: 759px) {
  .LayoutsThreeColumns-description span {
    line-height: 20px;
  }
}
