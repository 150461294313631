.ContactFileAComplaint {
  width: 100%;
  height: 100%;
}
.ContactFileAComplaint-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactFileAComplaint-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactFileAComplaint-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactFileAComplaint-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactFileAComplaint-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
@media (max-width: 759px) {
  .ContactFileAComplaint-mainWrapper {
    padding: 0 6.25vw;
  }
}
.ContactFileAComplaint-text {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactFileAComplaint-text {
    margin-top: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactFileAComplaint-text {
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactFileAComplaint-text {
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactFileAComplaint-text {
    margin-top: 29.33333333px;
  }
}
.ContactFileAComplaint-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactFileAComplaint-text a:hover,
.isNotDesktop .ContactFileAComplaint-text a:active {
  text-decoration: none;
}
.ContactFileAComplaint-text {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactFileAComplaint-text {
    margin-top: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactFileAComplaint-text {
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactFileAComplaint-text {
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactFileAComplaint-text {
    margin-top: 29.33333333px;
  }
}
.ContactFileAComplaint-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactFileAComplaint-text a:hover,
.isNotDesktop .ContactFileAComplaint-text a:active {
  text-decoration: none;
}
.ContactFileAComplaint-langContact {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactFileAComplaint-langContact {
    margin-top: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactFileAComplaint-langContact {
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactFileAComplaint-langContact {
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ContactFileAComplaint-langContact {
    margin-top: 46.66666667px;
  }
}
.ContactFileAComplaint-langContact a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactFileAComplaint-langContact a:hover,
.isNotDesktop .ContactFileAComplaint-langContact a:active {
  text-decoration: none;
}
.ContactFileAComplaint-langContact span {
  display: none;
}
@media (max-width: 759px) {
  .ContactFileAComplaint-selectWrapper {
    width: 100%;
    margin-top: 6.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactFileAComplaint-selectWrapper {
    width: 29.03225806vw;
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactFileAComplaint-selectWrapper {
    width: 25vw;
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactFileAComplaint-selectWrapper {
    width: 480px;
    margin-top: 29.33333333px;
  }
}
.ContactFileAComplaint-selectWrapper .ContactFileAComplaint-filterRegionItemSelectTag {
  display: none;
}
.isMobile .ContactFileAComplaint-selectWrapper .ContactFileAComplaint-filterRegionItemSelectTag {
  display: block;
  opacity: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
