.ImageTextRight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1920px) {
  .ImageTextRight {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImageTextRight {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImageTextRight {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ImageTextRight {
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .ImageTextRight {
    display: block;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ImageTextRight:first-of-type {
    margin-top:  13.333333333333332px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ImageTextRight:first-of-type {
    margin-top:  0.6944444444444444vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ImageTextRight:first-of-type {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ImageTextRight:first-of-type {
    margin-top:  3.125vw;
  }
}
.ImageTextRight-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 0;
          flex: 0 0;
}
@media (min-width: 1920px) {
  .ImageTextRight-image {
    -ms-flex-preferred-size:  480px;
        flex-basis:  480px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImageTextRight-image {
    -ms-flex-preferred-size:  25vw;
        flex-basis:  25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImageTextRight-image {
    -ms-flex-preferred-size:  29.032258064516128vw;
        flex-basis:  29.032258064516128vw;
  }
}
@media (max-width: 759px) {
  .ImageTextRight-image {
    -ms-flex-preferred-size:  112.5vw;
        flex-basis:  112.5vw;
  }
}
@media (max-width: 759px) {
  .ImageTextRight-image {
    width: 100%;
  }
}
.ImageTextRight-image img {
  width: 100%;
  min-height: 100%;
}
.ImageTextRight-text {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .ImageTextRight-text {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImageTextRight-text {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImageTextRight-text {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ImageTextRight-text {
    margin-left:  0vw;
  }
}
.ImageTextRight-textTitle {
  text-transform: none;
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ImageTextRight-textTitle {
    margin-top:  13.333333333333332px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ImageTextRight-textTitle {
    margin-top:  0.6944444444444444vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ImageTextRight-textTitle {
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ImageTextRight-textTitle {
    margin-top:  3.125vw;
  }
}
@media (min-width: 1920px) {
  .ImageTextRight-textDescription {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ImageTextRight-textDescription {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ImageTextRight-textDescription {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ImageTextRight-textDescription {
    margin-top:  1.5625vw;
  }
}
