.AboutInnovation {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media (min-width: 1920px) {
  .AboutInnovation {
    padding: 186.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutInnovation {
    padding: 9.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutInnovation {
    padding: 11.29032258vw;
  }
}
@media (max-width: 759px) {
  .AboutInnovation {
    padding: 10.3125vw 6.25vw 18.75vw;
  }
}
.AboutInnovation-content {
  margin: auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.AboutInnovation-title {
  color: #FFFFFF;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s transform, 0.4s opacity;
  transition: 0.4s transform, 0.4s opacity, 0.4s -webkit-transform;
}
.AboutInnovation-title.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.AboutInnovation-facts {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .AboutInnovation-facts {
    margin-top: 133.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutInnovation-facts {
    margin-top: 6.94444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutInnovation-facts {
    margin-top: 8.06451613vw;
  }
}
@media (max-width: 759px) {
  .AboutInnovation-facts {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 12.5vw;
  }
}
.AboutInnovation-fact {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
  border-top: 1px solid #FFFFFF;
  color: #FFFFFF;
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s transform, 0.4s opacity;
  transition: 0.4s transform, 0.4s opacity, 0.4s -webkit-transform;
}
.AboutInnovation-fact.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.AboutInnovation-fact:last-of-type {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
@media (min-width: 1920px) {
  .AboutInnovation-fact {
    padding-top: 21.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutInnovation-fact {
    padding-top: 1.11111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutInnovation-fact {
    padding-top: 1.29032258vw;
  }
}
@media (max-width: 759px) {
  .AboutInnovation-fact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    border-top: none;
    margin-top: 6.25vw;
    line-height: 6.25vw;
  }
  .AboutInnovation-fact:first-of-type {
    border-top: 1px solid #FFFFFF;
    margin-top: 0;
    padding-top: 9.0625vw;
  }
}
