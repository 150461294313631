@media (max-width: 759px) {
  .SearchCompanyItem {
    margin-left: 6.25vw;
    margin-right: 6.25vw;
  }
}
.SearchCompanyItem:last-child-wrapper {
  border-bottom: none;
}
.SearchCompanyItem-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid rgba(198, 198, 198, 0.8);
}
@media (min-width: 1920px) {
  .SearchCompanyItem-wrapper {
    padding-bottom:  53.33333333333333px;
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchCompanyItem-wrapper {
    padding-bottom:  2.7777777777777777vw;
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchCompanyItem-wrapper {
    padding-bottom:  3.225806451612903vw;
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-wrapper {
    padding-bottom:  8.75vw;
    padding-top:  8.75vw;
  }
}
@media (min-width: 1920px) {
  .SearchCompanyItem-wrapper:not(:last-child) {
    padding-bottom:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchCompanyItem-wrapper:not(:last-child) {
    padding-bottom:  2.0833333333333335vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-wrapper:not(:last-child) {
    padding-bottom:  10.9375vw;
  }
}
@media (min-width: 1920px) {
  .SearchCompanyItem-infoWrapper {
    width:  613.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchCompanyItem-infoWrapper {
    width:  31.944444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchCompanyItem-infoWrapper {
    width:  37.096774193548384vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-infoWrapper {
    width:  143.75vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-infoWrapper {
    width: 100%;
  }
}
@media (min-width: 1920px) {
  .SearchCompanyItem-title {
    margin-bottom:  21.333333333333332px;
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchCompanyItem-title {
    margin-bottom:  1.1111111111111112vw;
    margin-top:  0.3472222222222222vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-title {
    margin-bottom:  6.25vw;
    line-height:  6.25vw;
  }
}
.SearchCompanyItem-text {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .SearchCompanyItem-text {
    margin-bottom:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchCompanyItem-text {
    margin-bottom:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchCompanyItem-text {
    margin-bottom:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-text {
    margin-bottom:  5vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-text {
    margin-bottom: 6.25vw;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 12.5vw;
    line-height: 6.25vw;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.SearchCompanyItem-text b {
  color: #111112;
}
.SearchCompanyItem-link {
  color: #111112;
  text-decoration: none;
}
.isDesktop .SearchCompanyItem-link:hover,
.isNotDesktop .SearchCompanyItem-link:active {
  text-decoration: underline;
}
@media (max-width: 759px) {
  .SearchCompanyItem-link {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media (min-width: 1920px) {
  .SearchCompanyItem-image {
    width:  213.33333333333331px;
    height:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchCompanyItem-image {
    width:  11.11111111111111vw;
    height:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchCompanyItem-image {
    width:  12.903225806451612vw;
    height:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-image {
    width:  50vw;
    height:  31.25vw;
  }
}
@media (max-width: 759px) {
  .SearchCompanyItem-image {
    display: none;
  }
}
