@media (max-width: 759px) {
  .ProductMasterpiecePopup-content {
    width: 100%;
  }
}
.ProductMasterpiecePopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 759px) {
  .ProductMasterpiecePopup-contentWrapper {
    display: block;
  }
}
.ProductMasterpiecePopup-cardWrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
}
@media (max-width: 759px) {
  .ProductMasterpiecePopup-cardWrapper {
    display: none;
  }
}
.ProductMasterpiecePopup-cardWrapper.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 50%;
}
.ProductMasterpiecePopup-cardWrapper.fixed + .ProductMasterpiecePopup-description {
  margin-left: 50%;
}
@media (max-width: 759px) {
  .ProductMasterpiecePopup-cardWrapper.fixed {
    margin-left: 0;
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
  }
  .ProductMasterpiecePopup-cardWrapper.fixed + .ProductMasterpiecePopup-description {
    margin-left: 0;
  }
}
.ProductMasterpiecePopup-cardWrapper.absolute {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
}
.ProductMasterpiecePopup-cardWrapper.absolute + .ProductMasterpiecePopup-description {
  margin-left: 50%;
}
@media (max-width: 759px) {
  .ProductMasterpiecePopup-cardWrapper.absolute {
    margin-left: 0;
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
  }
  .ProductMasterpiecePopup-cardWrapper.absolute + .ProductMasterpiecePopup-description {
    margin-left: 0;
  }
}
.ProductMasterpiecePopup-description {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  width: 50%;
  bottom: 0;
  top: 0;
}
@media (max-width: 759px) {
  .ProductMasterpiecePopup-description {
    width: 100%;
  }
}
