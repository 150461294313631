.ProductMasterpieceCard {
  width: 100%;
}
@media (min-width: 1920px) {
  .ProductMasterpieceCard.js-is-sticky {
    left:  26.666666666666664px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceCard.js-is-sticky {
    left:  1.3888888888888888vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceCard.js-is-sticky {
    left:  1.6129032258064515vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceCard.js-is-sticky {
    left:  6.25vw;
    bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceCard.js-is-sticky {
    width: calc(50% - 26.66666667px);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceCard.js-is-sticky {
    width: calc(50% - 1.38888889vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceCard.js-is-sticky {
    width: calc(50% - 1.61290323vw);
  }
}
.ProductMasterpieceCard.js-is-stuck {
  width: 50%;
}
.ProductMasterpieceCard-buttonWrapper {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceCard-buttonWrapper {
    bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceCard-buttonWrapper {
    bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceCard-buttonWrapper {
    bottom: 53.33333333px;
  }
}
.ProductMasterpieceCard-head {
  background-color: #E9EAEE;
  position: relative;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceCard-head {
    min-height: 32.25806452vw;
    height: calc(100vh - 3.06451613vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceCard-head {
    min-height: 27.77777778vw;
    height: calc(100vh - 2.63888889vw);
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceCard-head {
    min-height: 533.33333333px;
    height: calc(100vh - 50.66666667px);
  }
}
.ProductMasterpieceCard-imageWrapper {
  width: 100%;
  position: relative;
  display: block;
  margin-top: -2px;
}
.ProductMasterpieceCard-imageWrapper:first-of-type {
  margin-top: 0;
}
.ProductMasterpieceCard-imageWrapper img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center';
  max-width: unset;
  width: 100%;
}
.ProductMasterpieceCard-imageWrapper.first {
  width: 80%;
  height: 60vh;
  margin: 0 auto;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceCard-imageWrapper.first {
    max-height: 40.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceCard-imageWrapper.first {
    max-height: 34.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceCard-imageWrapper.first {
    max-height: 666.66666667px;
  }
}
.ProductMasterpieceCard-imageWrapper.first img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center';
}
