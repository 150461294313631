.LayoutsRichText-text {
  position: relative;
}
.LayoutsRichText-text ul,
.LayoutsRichText-text ol {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
@media (min-width: 1920px) {
  .LayoutsRichText-text ul,
  .LayoutsRichText-text ol {
    -webkit-padding-start:  53.33333333333333px;
            padding-inline-start:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsRichText-text ul,
  .LayoutsRichText-text ol {
    -webkit-padding-start:  2.7777777777777777vw;
            padding-inline-start:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsRichText-text ul,
  .LayoutsRichText-text ol {
    -webkit-padding-start:  3.225806451612903vw;
            padding-inline-start:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .LayoutsRichText-text ul,
  .LayoutsRichText-text ol {
    -webkit-padding-start:  0vw;
            padding-inline-start:  0vw;
  }
}
.LayoutsRichText-text ol {
  list-style: decimal;
}
.LayoutsRichText-text ul {
  list-style: disc;
}
.LayoutsRichText-text li {
  line-height: 1.5;
}
@media (min-width: 1920px) {
  .LayoutsRichText-text li {
    margin-left:  33.33333333333333px;
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsRichText-text li {
    margin-left:  1.7361111111111112vw;
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsRichText-text li {
    margin-left:  2.0161290322580645vw;
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .LayoutsRichText-text li {
    margin-left:  7.8125vw;
    margin-top:  1.5625vw;
  }
}
.LayoutsRichText-text li:first-child {
  margin-top: 0;
}
.LayoutsRichText-text h3 {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: normal;
}
@media (min-width: 1920px) {
  .LayoutsRichText-text h3 {
    font-size:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsRichText-text h3 {
    font-size:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsRichText-text h3 {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .LayoutsRichText-text h3 {
    font-size:  6.25vw;
  }
}
@media (max-width: 759px) {
  .LayoutsRichText-text h3 {
    line-height: 1.2;
  }
}
.LayoutsRichText-text h2 {
  display: block;
  font-size: 1em;
  -webkit-margin-before: 0.83em;
          margin-block-start: 0.83em;
  -webkit-margin-after: 0.83em;
          margin-block-end: 0.83em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
}
.LayoutsRichText-text h4 {
  display: block;
  -webkit-margin-before: 1.33em;
          margin-block-start: 1.33em;
  -webkit-margin-after: 1.33em;
          margin-block-end: 1.33em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
}
.LayoutsRichText-text h2:first-child,
.LayoutsRichText-text h3:first-child,
.LayoutsRichText-text h4:first-child {
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
.LayoutsRichText-text a {
  color: #111112;
}
.LayoutsRichText-text a:visited {
  color: #111112;
}
.isDesktop .LayoutsRichText-text a:hover {
  text-decoration: none;
}
.LayoutsRichText .quoteText {
  quotes: "“" "”";
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsRichText .quoteText {
    line-height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsRichText .quoteText {
    line-height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsRichText .quoteText {
    line-height: 53.33333333px;
  }
}
.LayoutsRichText .quoteText:before {
  content: open-quote;
  position: absolute;
  left: -15px;
}
@media (max-width: 759px) {
  .LayoutsRichText .quoteText:before {
    left: -10px;
  }
}
.LayoutsRichText .quoteText:after {
  content: close-quote;
}
