.InvestorsStockPrice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice {
    padding:  80px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice {
    padding:  4.166666666666667vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice {
    padding:  4.838709677419355vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice {
    padding:  10.9375vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.InvestorsStockPrice.show .InvestorsStockPrice-totalTitle,
.InvestorsStockPrice.show .InvestorsStockPrice-change,
.InvestorsStockPrice.show .InvestorsStockPrice-volume {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.InvestorsStockPrice.isWhite .InvestorsStockPrice-title,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-text,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-link,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-total,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-changeTitle,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-changeName,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-volumeTitle,
.InvestorsStockPrice.isWhite .InvestorsStockPrice-volumeValue {
  color: #FFFFFF;
}
.InvestorsStockPrice.isWhite .InvestorsStockPrice-blockTextTitle {
  border-top: 1px solid #FFFFFF;
  border-bottom: 0;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  0;
    padding-top:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  0vw;
    padding-top:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  0vw;
    padding-top:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  0vw;
    padding-top:  3.75vw;
  }
}
.InvestorsStockPrice.isWhite .InvestorsStockPrice-total {
  border-top: 1px solid #FFFFFF;
  border-bottom: 0;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-total {
    padding-bottom:  0;
    padding-top:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-total {
    padding-bottom:  0vw;
    padding-top:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-total {
    padding-bottom:  0vw;
    padding-top:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-total {
    padding-bottom:  5.3125vw;
    padding-top:  0vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice.isWhite .InvestorsStockPrice-total {
    border-top: none;
    border-bottom: 1px solid #FFFFFF;
  }
}
.InvestorsStockPrice-blockText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-blockText {
    width:  1146.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-blockText {
    width:  59.72222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-blockText {
    width:  69.35483870967742vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-blockText {
    width:  268.75vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-blockText {
    width: 100%;
  }
}
.InvestorsStockPrice-blockTextTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .InvestorsStockPrice-blockTextTitle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-blockTextTitle {
    padding-bottom:  4.6875vw;
  }
}
.InvestorsStockPrice-title {
  color: #111112;
}
.InvestorsStockPrice-date {
  color: #A6A8B3;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-date {
    margin-left:  29.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-date {
    margin-left:  1.527777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-date {
    margin-left:  1.7741935483870968vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-date {
    margin-top:  3.125vw;
    margin-left:  0vw;
  }
}
.InvestorsStockPrice-text {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-text {
    width:  746.6666666666666px;
    margin-top:  48px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-text {
    width:  38.88888888888889vw;
    margin-top:  2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-text {
    width:  45.16129032258064vw;
    margin-top:  2.903225806451613vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-text {
    margin-top:  8.4375vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-text {
    width: 100%;
  }
}
.InvestorsStockPrice-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #6F7380;
}
@media (max-width: 759px) {
  .InvestorsStockPrice-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .InvestorsStockPrice-link:hover,
.isNotDesktop .InvestorsStockPrice-link:active {
  text-decoration: underline;
}
.isDesktop .InvestorsStockPrice-link:hover:before,
.isNotDesktop .InvestorsStockPrice-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .InvestorsStockPrice-link:hover:before,
  .isNotDesktop .InvestorsStockPrice-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-link {
    letter-spacing: 0;
  }
}
.InvestorsStockPrice-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .InvestorsStockPrice-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .InvestorsStockPrice-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .InvestorsStockPrice-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-link {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-link {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-link {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-link {
    margin-top:  3.125vw;
  }
}
.InvestorsStockPrice-blockNumbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-blockNumbers {
    width:  400px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-blockNumbers {
    width:  20.833333333333336vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-blockNumbers {
    width:  24.193548387096772vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-blockNumbers {
    margin-top:  15.625vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-blockNumbers {
    width: 100%;
  }
}
.InvestorsStockPrice-total {
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  border-bottom: 1px solid #E7E7E7;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-total {
    padding-bottom:  20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-total {
    padding-bottom:  1.0416666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-total {
    padding-bottom:  1.2096774193548387vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-total {
    padding-bottom:  5.3125vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-total {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.InvestorsStockPrice-totalTitle {
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.InvestorsStockPrice-change {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0);
  -webkit-transition: 0.15s opacity 0.1s, 0.15s -webkit-transform 0.1s;
  transition: 0.15s opacity 0.1s, 0.15s -webkit-transform 0.1s;
  transition: 0.15s opacity 0.1s, 0.15s transform 0.1s;
  transition: 0.15s opacity 0.1s, 0.15s transform 0.1s, 0.15s -webkit-transform 0.1s;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-change {
    margin-top:  48px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-change {
    margin-top:  2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-change {
    margin-top:  2.903225806451613vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-change {
    margin-top:  3.75vw;
    padding-bottom:  5.625vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-change {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #E7E7E7;
  }
}
.InvestorsStockPrice-changeTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  color: #6F7380;
}
.InvestorsStockPrice-changeValue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  background: #90B700;
  color: #FFFFFF;
}
.InvestorsStockPrice-changeValue.isNegative {
  background: #EA0101;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-changeValue {
    padding:  6.666666666666666px 26.666666666666664px 20px;
    border-radius:  10.666666666666666px;
    margin-top:  8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-changeValue {
    padding:  0.3472222222222222vw 1.3888888888888888vw 1.0416666666666667vw;
    border-radius:  0.5555555555555556vw;
    margin-top:  0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-changeValue {
    padding:  0.4032258064516129vw 1.6129032258064515vw 1.2096774193548387vw;
    border-radius:  0.6451612903225806vw;
    margin-top:  0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-changeValue {
    padding:  1.5625vw 6.25vw 3.125vw;
    border-radius:  2.5vw;
    margin-top:  0vw;
  }
}
.InvestorsStockPrice-volume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0);
  -webkit-transition: 0.15s opacity 0.2s, 0.15s -webkit-transform 0.2s;
  transition: 0.15s opacity 0.2s, 0.15s -webkit-transform 0.2s;
  transition: 0.15s opacity 0.2s, 0.15s transform 0.2s;
  transition: 0.15s opacity 0.2s, 0.15s transform 0.2s, 0.15s -webkit-transform 0.2s;
}
@media (min-width: 1920px) {
  .InvestorsStockPrice-volume {
    width:  400px;
    margin-top:  17.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InvestorsStockPrice-volume {
    width:  20.833333333333336vw;
    margin-top:  0.9027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InvestorsStockPrice-volume {
    width:  24.193548387096772vw;
    margin-top:  1.0483870967741935vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-volume {
    margin-top:  3.75vw;
  }
}
@media (max-width: 759px) {
  .InvestorsStockPrice-volume {
    width: 100%;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.InvestorsStockPrice-volumeTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  color: #6F7380;
}
.InvestorsStockPrice-volumeValue {
  color: #111112;
  text-transform: uppercase;
}
