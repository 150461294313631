.AboutQualityManagement {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.AboutQualityManagement-content {
  margin: auto;
  will-change: transform;
  -webkit-transform: translate3d(0, -17%, 0);
          transform: translate3d(0, -17%, 0);
}
@media (min-width: 1920px) {
  .AboutQualityManagement-content {
    width: 1040px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutQualityManagement-content {
    width: 54.16666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutQualityManagement-content {
    width: 62.90322581vw;
  }
}
@media (max-width: 759px) {
  .AboutQualityManagement-content {
    width: 87.5vw;
    -webkit-transform: translate3d(0, -2%, 0);
            transform: translate3d(0, -2%, 0);
  }
}
.AboutQualityManagement-title {
  color: #FFFFFF;
  text-align: center;
}
@media (max-width: 759px) {
  .AboutQualityManagement-title {
    text-align: start;
  }
}
.AboutQualityManagement-description {
  color: #FFFFFF;
  text-align: center;
}
@media (min-width: 1920px) {
  .AboutQualityManagement-description {
    margin-top: 69.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .AboutQualityManagement-description {
    margin-top: 3.61111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .AboutQualityManagement-description {
    margin-top: 4.19354839vw;
  }
}
@media (max-width: 759px) {
  .AboutQualityManagement-description {
    text-align: start;
    margin-top: 6.25vw;
    line-height: 6.25vw;
  }
}
