.CommonColumnsTemplate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate {
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate {
    display: block;
  }
}
.CommonColumnsTemplate:first-of-type {
  margin-top: 0;
}
.CommonColumnsTemplate--2 .CommonColumnsTemplate-item {
  width: 47.36842105%;
  margin-left: 5.26315789%;
}
.CommonColumnsTemplate--2 .CommonColumnsTemplate-item:nth-child(2n + 1) {
  margin-left: 0;
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-item:nth-child(n + 3) {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-item:nth-child(n + 3) {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-item:nth-child(n + 3) {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-item:nth-child(n + 3) {
    margin-top:  9.375vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-item {
    width: 100%;
    margin-left: 0;
    margin-top: 12.5vw;
  }
}
.CommonColumnsTemplate--2 .CommonColumnsTemplate-itemContent {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-itemContent {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-itemContent {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-itemContent {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate--2 .CommonColumnsTemplate-itemContent {
    font-size: 26.66666667px;
  }
}
.CommonColumnsTemplate--3 .CommonColumnsTemplate-item {
  width: 29.8245614%;
  margin-left: 5.26315789%;
}
.CommonColumnsTemplate--3 .CommonColumnsTemplate-item:nth-child(3n + 1) {
  margin-left: 0;
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate--3 .CommonColumnsTemplate-item:nth-child(n + 4) {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate--3 .CommonColumnsTemplate-item:nth-child(n + 4) {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate--3 .CommonColumnsTemplate-item:nth-child(n + 4) {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--3 .CommonColumnsTemplate-item:nth-child(n + 4) {
    margin-top:  9.375vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--3 .CommonColumnsTemplate-item {
    width: 100%;
    margin-left: 0;
    margin-top: 12.5vw;
  }
}
.CommonColumnsTemplate--4 .CommonColumnsTemplate-item {
  width: 21.05263158%;
  margin-left: 5.26315789%;
}
.CommonColumnsTemplate--4 .CommonColumnsTemplate-item:nth-child(4n + 1) {
  margin-left: 0;
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate--4 .CommonColumnsTemplate-item:nth-child(n + 5) {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate--4 .CommonColumnsTemplate-item:nth-child(n + 5) {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate--4 .CommonColumnsTemplate-item:nth-child(n + 5) {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--4 .CommonColumnsTemplate-item:nth-child(n + 5) {
    margin-top:  9.375vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate--4 .CommonColumnsTemplate-item {
    width: 100%;
    margin-left: 0;
    margin-top: 12.5vw;
  }
}
.CommonColumnsTemplate-item {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100% !important;
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-item {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate-item {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate-item {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate-item {
    font-size: 26.66666667px;
  }
}
.CommonColumnsTemplate-itemContent {
  width: 100%;
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate-itemContent {
    font-size:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate-itemContent {
    font-size:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate-itemContent {
    font-size:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-itemContent {
    font-size:  4.375vw;
  }
}
.CommonColumnsTemplate-itemContent iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.77777778;
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-itemContent {
    line-height: 1.42857143;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-item:first-of-type {
    margin-top: 0;
  }
}
.CommonColumnsTemplate-item .column-title {
  line-height: 1;
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate-item .column-title {
    font-size:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate-item .column-title {
    font-size:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate-item .column-title {
    font-size:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-item .column-title {
    font-size:  5.625vw;
  }
}
@media (min-width: 1920px) {
  .CommonColumnsTemplate-item .column-content {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonColumnsTemplate-item .column-content {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonColumnsTemplate-item .column-content {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-item .column-content {
    margin-top:  1.5625vw;
  }
}
.CommonColumnsTemplate-item a {
  color: #111112;
}
.isDesktop .CommonColumnsTemplate-item a:hover {
  text-decoration: none;
}
.CommonColumnsTemplate-item a:visited {
  color: #111112;
}
@media (max-width: 759px) {
  .CommonColumnsTemplate-item br.desktop-empty-line {
    display: none;
  }
}
