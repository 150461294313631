.PrivacyPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 25;
  -webkit-transition: visibility 0.3s step-start;
  transition: visibility 0.3s step-start;
}
@media (max-width: 759px) {
  .PrivacyPopup {
    top: 12.5vw;
    z-index: 1000;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup {
    top: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup {
    top: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup {
    top: 106.66666667px;
  }
}
.PrivacyPopup--hide {
  visibility: hidden;
  -webkit-transition: visibility 0.3s step-end;
  transition: visibility 0.3s step-end;
}
@media (max-width: 759px) {
  .PrivacyPopup--hide {
    position: fixed;
  }
}
.PrivacyPopup--hide .PrivacyPopup-bg {
  opacity: 0;
}
.PrivacyPopup--hide .PrivacyPopup-inputWrapper {
  opacity: 0;
  -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
  -webkit-transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
}
@media (max-width: 759px) {
  .PrivacyPopup--hide .PrivacyPopup-inputWrapper {
    -webkit-transform: translateY(6.25vw);
        -ms-transform: translateY(6.25vw);
            transform: translateY(6.25vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup--hide .PrivacyPopup-inputWrapper {
    -webkit-transform: translateY(3.22580645vw);
        -ms-transform: translateY(3.22580645vw);
            transform: translateY(3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup--hide .PrivacyPopup-inputWrapper {
    -webkit-transform: translateY(2.77777778vw);
        -ms-transform: translateY(2.77777778vw);
            transform: translateY(2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup--hide .PrivacyPopup-inputWrapper {
    -webkit-transform: translateY(53.33333333px);
        -ms-transform: translateY(53.33333333px);
            transform: translateY(53.33333333px);
  }
}
.PrivacyPopup--hide .PrivacyPopup-close {
  opacity: 0;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
.PrivacyPopup-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(21, 25, 40, 0.5);
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-bg {
    top: -6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-bg {
    top: -5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-bg {
    top: -106.66666667px;
  }
}
.PrivacyPopup-inputWrapper {
  position: relative;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  background: #FFFFFF;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .PrivacyPopup-inputWrapper {
    -webkit-box-shadow: none;
            box-shadow: none;
    overflow-y: scroll;
  }
}
.PrivacyPopup-mainWrapper {
  margin: 0 auto;
}
@media (max-width: 759px) {
  .PrivacyPopup-mainWrapper {
    padding: 12.5vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-mainWrapper {
    width: 61.29032258vw;
    margin: 8.46774194vw auto 8.46774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-mainWrapper {
    width: 52.77777778vw;
    margin: 7.29166667vw auto 7.29166667vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-mainWrapper {
    width: 1013.33333333px;
    margin: 140px auto 140px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-title {
    margin-bottom: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-title {
    margin-bottom: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-title {
    margin-bottom: 106.66666667px;
  }
}
@media (max-width: 759px) {
  .PrivacyPopup-text {
    margin-top: 10.9375vw;
  }
}
.PrivacyPopup-text p {
  color: #6F7380;
}
@media (max-width: 759px) {
  .PrivacyPopup-text p {
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-text p {
    margin-bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-text p {
    margin-bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-text p {
    margin-bottom: 53.33333333px;
  }
}
.PrivacyPopup-text span {
  color: #6F7380;
  font-weight: 600;
}
.PrivacyPopup-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .PrivacyPopup-text a:hover,
.isNotDesktop .PrivacyPopup-text a:active {
  text-decoration: none;
  cursor: pointer;
}
.PrivacyPopup-text a[href^="tel"] {
  color: #111112;
  text-decoration: underline;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] {
  margin-top: 3em;
}
@media (max-width: 759px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] h3 {
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] h3 {
    margin-bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] h3 {
    margin-bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] h3 {
    margin-bottom: 53.33333333px;
  }
}
@media (max-width: 759px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] .uc-embed-subelement {
    margin-bottom: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] .uc-embed-subelement {
    margin-bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] .uc-embed-subelement {
    margin-bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] .uc-embed-subelement {
    margin-bottom: 53.33333333px;
  }
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] .uc-embed-subelement h4 {
  color: #6F7380;
  font-weight: 600;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] .uc-embed-subelement .uc-p {
  margin-bottom: unset;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"] {
  position: relative;
  left: 1em;
  top: 0;
  z-index: 0;
  -webkit-appearance: none;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"] + label {
  position: relative;
  display: block;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 24px;
  line-height: 1.3;
  padding-left: 2.5em;
  margin-top: -1em;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"] + label:before {
  width: 2em;
  height: 1em;
  border-radius: 1em;
  border: 0.1em solid #ddd;
  background-color: #f5f5f5;
  content: "";
  margin-right: 0.5em;
  -webkit-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear;
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0.2em;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"] + label:after {
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background-color: #111112;
  content: "";
  -webkit-transition: margin 0.1s linear;
  transition: margin 0.1s linear;
  -webkit-box-shadow: 0 0 0.33em #aaa;
          box-shadow: 0 0 0.33em #aaa;
  position: absolute;
  left: 0.05em;
  top: 0.2em;
  z-index: 10;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"]:checked + label:before {
  background-color: #111112;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"]:checked + label:after {
  margin: 0 0 0 1em;
  background-color: #fff;
}
.PrivacyPopup-text.uc-embed [data-testid="uc-embed-service"] [type="checkbox"][disabled]:checked + label:before {
  background-color: #c0c1c9;
}
.PrivacyPopup-footer {
  background: #F6F6F6;
  width: 100%;
  height: 100%;
}
@media (max-width: 759px) {
  .PrivacyPopup-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-footer {
    height: 16.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-footer {
    height: 13.88888889vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-footer {
    height: 266.66666667px;
  }
}
.PrivacyPopup-footerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
}
@media (max-width: 759px) {
  .PrivacyPopup-footerWrapper {
    display: block;
    padding: 12.5vw 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-footerWrapper {
    width: 61.29032258vw;
    height: 16.12903226vw;
    padding-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-footerWrapper {
    width: 52.77777778vw;
    height: 13.88888889vw;
    padding-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-footerWrapper {
    width: 1013.33333333px;
    height: 266.66666667px;
    padding-top: 53.33333333px;
  }
}
.PrivacyPopup-footerAddress span {
  display: block;
  color: #6F7380;
}
@media (max-width: 759px) {
  .PrivacyPopup-footerAddress {
    margin-top: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-footerAddress {
    margin-left: 9.27419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-footerAddress {
    margin-left: 7.98611111vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-footerAddress {
    margin-left: 153.33333333px;
  }
}
@media (max-width: 759px) {
  .PrivacyPopup-footerContacts {
    margin-top: 7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-footerContacts {
    margin-left: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-footerContacts {
    margin-left: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-footerContacts {
    margin-left: 186.66666667px;
  }
}
.PrivacyPopup-footerContacts span {
  display: block;
  color: #6F7380;
}
.PrivacyPopup-footerContacts a {
  display: block;
  text-decoration: underline;
}
.isDesktop .PrivacyPopup-footerContacts a:hover,
.isNotDesktop .PrivacyPopup-footerContacts a:active {
  text-decoration: none;
}
.PrivacyPopup-close {
  position: fixed;
  float: right;
  cursor: pointer;
  z-index: 99;
  opacity: 1;
  -webkit-transition: 0.3s opacity 0.15s;
  transition: 0.3s opacity 0.15s;
}
@media (max-width: 759px) {
  .isDesktop .PrivacyPopup-close:hover .PrivacyPopup-closeItem,
  .isNotDesktop .PrivacyPopup-close:active .PrivacyPopup-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .PrivacyPopup-close:hover .PrivacyPopup-closeItem,
  .isNotDesktop .PrivacyPopup-close:active .PrivacyPopup-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .PrivacyPopup-close:hover .PrivacyPopup-closeItem,
  .isNotDesktop .PrivacyPopup-close:active .PrivacyPopup-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .PrivacyPopup-close:hover .PrivacyPopup-closeItem,
  .isNotDesktop .PrivacyPopup-close:active .PrivacyPopup-closeItem {
    width: 42.66666667px;
  }
}
@media (max-width: 759px) {
  .PrivacyPopup-close {
    position: fixed;
    top: 14.0625vw;
    right: 1.5625vw;
    width: 8.125vw;
    height: 8.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-close {
    width: 3.06451613vw;
    height: 3.06451613vw;
    right: 0.56451613vw;
    top: 8.46774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-close {
    width: 2.63888889vw;
    height: 2.63888889vw;
    right: 0.48611111vw;
    top: 7.29166667vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-close {
    width: 50.66666667px;
    height: 50.66666667px;
    right: 9.33333333px;
    top: 140px;
  }
}
.PrivacyPopup-closeItem {
  position: absolute;
  height: 2px;
  background: #111112;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.PrivacyPopup-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.PrivacyPopup-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (max-width: 759px) {
  .PrivacyPopup-closeItem {
    width: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-closeItem {
    width: 2.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-closeItem {
    width: 1.80555556vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-closeItem {
    width: 34.66666667px;
  }
}
.PrivacyPopup-tabContent {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-tabContent {
    width: 45.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-tabContent {
    width: 38.88888889vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-tabContent {
    width: 746.66666667px;
  }
}
.PrivacyPopup-tabContentWrapper {
  position: absolute;
  -webkit-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}
.PrivacyPopup-tabContentWrapper.active {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.PrivacyPopup-searchResultWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-searchResultWrapper {
    width: 77.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-searchResultWrapper {
    width: 66.66666667vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-searchResultWrapper {
    width: 1280px;
  }
}
.PrivacyPopup-accordionWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .PrivacyPopup-accordionWrapper {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-accordionWrapper {
    width: 22.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-accordionWrapper {
    width: 19.44444444vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-accordionWrapper {
    width: 373.33333333px;
  }
}
.PrivacyPopup-accordion,
.PrivacyPopup-accordion * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.PrivacyPopup-accordionSectionContentLink {
  text-decoration: none;
  color: #111112;
  display: block;
  position: relative;
}
@media (max-width: 759px) {
  .PrivacyPopup-accordionSectionContentLink {
    margin-top: 2.5vw;
    padding: 2.5vw 3.75vw 1.5625vw 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-accordionSectionContentLink {
    margin-top: 0.64516129vw;
    padding: 0.64516129vw 0.80645161vw 0.3251821vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-accordionSectionContentLink {
    margin-top: 0.55555556vw;
    padding: 0.55555556vw 0.69444444vw 0.24112654vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-accordionSectionContentLink {
    margin-top: 10.66666667px;
    padding: 10.66666667px 13.33333333px 88.88888889px;
  }
}
.PrivacyPopup-accordionSectionContentLink.active {
  font-weight: normal;
  background-color: #111112;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PrivacyPopup-accordionSectionContentLink.active {
    width: 93.75vw;
    border-radius: 15.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-accordionSectionContentLink.active {
    border-radius: 4.03225806vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-accordionSectionContentLink.active {
    border-radius: 3.47222222vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-accordionSectionContentLink.active {
    border-radius: 66.66666667px;
  }
}
.PrivacyPopup-accordionSectionContentLink:not(:last-child):after {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #C6C6C6;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PrivacyPopup-accordionSectionContentLink:not(:last-child):after {
    width: 20.96774194vw;
    bottom: -0.40322581vw;
    left: 0.80645161vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PrivacyPopup-accordionSectionContentLink:not(:last-child):after {
    width: 18.05555556vw;
    bottom: -0.34722222vw;
    left: 0.69444444vw;
  }
}
@media (min-width: 1920px) {
  .PrivacyPopup-accordionSectionContentLink:not(:last-child):after {
    width: 346.66666667px;
    bottom: -6.66666667px;
    left: 13.33333333px;
  }
}
.PrivacyPopup-accordionSectionContentLink span {
  color: #6F7380;
  pointer-events: none;
}
.PrivacyPopup-mobileAnchors {
  display: none;
}
@media (max-width: 759px) {
  .PrivacyPopup-mobileAnchors {
    display: block;
    padding: 6.25vw;
    background-color: #F6F6F6;
  }
}
.PrivacyPopup-mobileAnchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  position: relative;
  padding: 4.6875vw 0 4.6875vw;
  border-bottom: 1px solid #C6C6C6;
}
.PrivacyPopup-mobileAnchor:last-of-type {
  border-bottom: none;
}
.PrivacyPopup-mobileAnchorIcon {
  width: 6.25vw;
  height: 6.25vw;
  position: relative;
}
.PrivacyPopup-mobileAnchorIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.PrivacyPopup-mobileAnchorArrow {
  width: 5.9375vw;
  height: 3.75vw;
  position: absolute;
  right: 0;
}
.PrivacyPopup-mobileAnchorArrow svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.PrivacyPopup-mobileAnchorTitle {
  line-height: normal;
}
