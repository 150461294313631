.ThreeColumnFacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
@media (min-width: 1920px) {
  .ThreeColumnFacts {
    margin-top:  -60px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ThreeColumnFacts {
    margin-top:  -3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ThreeColumnFacts {
    margin-top:  -3.629032258064516vw;
  }
}
@media (max-width: 759px) {
  .ThreeColumnFacts {
    margin-top:  -9.375vw;
  }
}
.ThreeColumnFacts-fact {
  border-top: 1px solid #111112;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 29%;
          flex: 0 0 29%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.ThreeColumnFacts-fact:nth-child(1),
.ThreeColumnFacts-fact:nth-child(2),
.ThreeColumnFacts-fact:nth-child(3) {
  border-top: none;
}
@media (max-width: 759px) {
  .ThreeColumnFacts-fact:nth-child(3) {
    border-top: 1px solid #111112;
  }
}
@media (min-width: 1920px) {
  .ThreeColumnFacts-fact {
    padding-top:  17.333333333333332px;
    margin-top:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ThreeColumnFacts-fact {
    padding-top:  0.9027777777777778vw;
    margin-top:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ThreeColumnFacts-fact {
    padding-top:  1.0483870967741935vw;
    margin-top:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .ThreeColumnFacts-fact {
    padding-top:  4.0625vw;
    margin-top:  15.625vw;
  }
}
@media (max-width: 759px) {
  .ThreeColumnFacts-fact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
            flex: 0 0 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 3.4375vw;
    margin-top: 6.25vw;
  }
}
.ThreeColumnFacts-factTitle {
  color: #6F7380;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .ThreeColumnFacts-factTitle {
    line-height: 3.125vw;
  }
}
.ThreeColumnFacts-factValue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.ThreeColumnFacts-factValueTitle {
  color: #111112;
}
@media (min-width: 1920px) {
  .ThreeColumnFacts-factValueTitle {
    font-size:  120px;
    letter-spacing:  -6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ThreeColumnFacts-factValueTitle {
    font-size:  6.25vw;
    letter-spacing:  -0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ThreeColumnFacts-factValueTitle {
    font-size:  5.64516129032258vw;
    letter-spacing:  -0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .ThreeColumnFacts-factValueTitle {
    font-size:  15.625vw;
  }
}
.ThreeColumnFacts-factValueMeasure {
  display: inline;
  color: #111112;
}
@media (min-width: 1920px) {
  .ThreeColumnFacts-factValueMeasure {
    margin-left: 13.33333333px;
    margin-right: 13.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ThreeColumnFacts-factValueMeasure {
    margin-left: 0.69444444vw;
    margin-right: 0.69444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ThreeColumnFacts-factValueMeasure {
    margin-left: 0.80645161vw;
    margin-right: 0.80645161vw;
  }
}
.ThreeColumnFacts-factList {
  color: #111112;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .ThreeColumnFacts-factList {
    margin-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ThreeColumnFacts-factList {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ThreeColumnFacts-factList {
    margin-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .ThreeColumnFacts-factList {
    margin-top: 1.61290323vw;
  }
}
