.FourColumnFacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .FourColumnFacts {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.FourColumnFacts--isDark .FourColumnFacts-fact {
  border-top: 1px solid #111112;
}
.FourColumnFacts--isDark .FourColumnFacts-factValueTitle,
.FourColumnFacts--isDark .FourColumnFacts-factValueMeasure,
.FourColumnFacts--isDark .FourColumnFacts-factList {
  color: #111112;
}
.FourColumnFacts--isDark .FourColumnFacts-factTitle {
  color: #6F7380;
}
.FourColumnFacts-fact {
  border-top: 1px solid #FFFFFF;
  opacity: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 20%, 0);
          transform: translate3d(0, 20%, 0);
  -webkit-transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s -webkit-transform;
  transition: 0.4s opacity, 0.4s transform;
  transition: 0.4s opacity, 0.4s transform, 0.4s -webkit-transform;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 23%;
          flex: 0 0 23%;
  max-width: 23%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.FourColumnFacts-fact.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.FourColumnFacts-fact:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.FourColumnFacts-fact:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.FourColumnFacts-fact:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.FourColumnFacts-fact:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
@media (min-width: 1920px) {
  .FourColumnFacts-fact {
    padding-top: 17.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FourColumnFacts-fact {
    padding-top: 0.90277778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FourColumnFacts-fact {
    padding-top: 1.0483871vw;
  }
}
@media (max-width: 759px) {
  .FourColumnFacts-fact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
            flex: 0 0 45%;
    max-width: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 3.4375vw;
    margin-top: 6.25vw;
  }
}
.FourColumnFacts-factTitle {
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .FourColumnFacts-factTitle {
    line-height: 3.125vw;
  }
}
.FourColumnFacts-factValue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-top: auto;
}
.FourColumnFacts-factValueTitle {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .FourColumnFacts-factValueTitle {
    font-size: 160px;
    letter-spacing: -6.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FourColumnFacts-factValueTitle {
    font-size: 8.33333333vw;
    letter-spacing: -0.34722222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FourColumnFacts-factValueTitle {
    font-size: 8.06451613vw;
    letter-spacing: -0.40322581vw;
  }
}
.FourColumnFacts-factValueMeasure {
  display: inline;
  color: #FFFFFF;
  white-space: nowrap;
}
@media (min-width: 1920px) {
  .FourColumnFacts-factValueMeasure {
    margin-left: 13.33333333px;
    margin-right: 13.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FourColumnFacts-factValueMeasure {
    margin-left: 0.69444444vw;
    margin-right: 0.69444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FourColumnFacts-factValueMeasure {
    margin-left: 0.80645161vw;
    margin-right: 0.80645161vw;
  }
}
.FourColumnFacts-factList {
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .FourColumnFacts-factList {
    margin-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FourColumnFacts-factList {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FourColumnFacts-factList {
    margin-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .FourColumnFacts-factList {
    margin-top: 1.61290323vw;
  }
}
