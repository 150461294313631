.PageLanding {
  z-index: 1;
}
.PageLanding-content {
  overflow: hidden;
}
@media (max-width: 759px) {
  .PageLanding-content {
    padding-bottom: 18.75vw;
  }
}
.PageLanding-filterBg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}
.PageLanding-opacityBg {
  width: 110%;
  height: 110%;
  position: fixed;
  top: -5%;
  left: -5%;
  z-index: 9;
  background-color: #111112;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
}
.PageLanding-block {
  position: relative;
  z-index: 11;
}
.PageLanding-blockContent {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 11;
}
.PageLanding-blockContent img {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.PageLanding-block:last-of-type .PageLanding-blockBgWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .PageLanding-block:last-of-type .PageLanding-blockBg_mobile {
    top: 15.625vw;
    height: 150vh;
  }
  .PageLanding-block:last-of-type .PageLanding-blockBg_mobile img {
    height: auto;
  }
}
.PageLanding-blockBgWrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
}
.PageLanding-blockBgWrapper.animation img {
  -webkit-animation: 0.4s landingBgFadeIn ease-in forwards;
          animation: 0.4s landingBgFadeIn ease-in forwards;
}
@-webkit-keyframes landingBgFadeIn {
  0% {
    -webkit-transform: scale(1.1) translate3d(0, 0, 0);
            transform: scale(1.1) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes landingBgFadeIn {
  0% {
    -webkit-transform: scale(1.1) translate3d(0, 0, 0);
            transform: scale(1.1) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.PageLanding-blockBg,
.PageLanding-blockBg_mobile {
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  will-change: transform, opacity;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #111112;
}
.PageLanding-blockBg.absolute,
.PageLanding-blockBg_mobile.absolute {
  position: absolute;
}
@media (max-width: 759px) {
  .PageLanding-blockBg,
  .PageLanding-blockBg_mobile {
    display: none;
  }
}
.PageLanding-blockBg img,
.PageLanding-blockBg_mobile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  pointer-events: none;
  width: 110%;
  height: 110%;
  top: -5%;
  left: -5%;
  bottom: -5%;
  right: -5%;
  max-width: none;
  opacity: 0.9;
  will-change: transform, filter;
}
.PageLanding-blockBg iframe,
.PageLanding-blockBg_mobile iframe {
  position: absolute;
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  max-width: none;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.PageLanding-blockBg .uc-embedding-wrapper,
.PageLanding-blockBg_mobile .uc-embedding-wrapper {
  display: none;
}
.PageLanding-blockBg_mobile {
  display: none;
}
@media (max-width: 759px) {
  .PageLanding-blockBg_mobile {
    display: block;
  }
}
.PageLanding-blockPlaceholder {
  height: 60vh;
  width: 100%;
}
.PageLanding-blockPlaceholder_big {
  height: 90vh;
}
.PageLanding-blockPlaceholder_small {
  height: 30vh;
}
@media (max-width: 759px) {
  .PageLanding-blockPlaceholder {
    height: 50vh;
  }
}
.PageLanding-bgOverlay {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  opacity: 0;
}
