.CareersFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.CareersFooter-content {
  margin-top: auto;
  width: 100%;
}
@media (min-width: 1920px) {
  .CareersFooter-content {
    margin-bottom:  164px;
    margin-top:  200px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-content {
    margin-bottom:  8.541666666666668vw;
    margin-top:  10.416666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-content {
    margin-bottom:  9.919354838709678vw;
    margin-top:  12.096774193548386vw;
  }
}
@media (max-width: 759px) {
  .CareersFooter-content {
    margin-bottom:  38.4375vw;
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) {
  .CareersFooter-content {
    margin-bottom: 23.75vw;
    min-height: 0;
  }
}
.CareersFooter-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .CareersFooter-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.CareersFooter-link {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 31%;
          flex: 0 0 31%;
  border-top: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-decoration: none;
}
.CareersFooter-link:visited {
  color: #FFFFFF;
}
.isDesktop .CareersFooter-link:hover .CareersFooter-linkTitle,
.isNotDesktop .CareersFooter-link:active .CareersFooter-linkTitle {
  text-decoration: underline;
}
.isDesktop .CareersFooter-link:hover .CareersFooter-linkTitle:before,
.isNotDesktop .CareersFooter-link:active .CareersFooter-linkTitle:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .CareersFooter-link:hover .CareersFooter-linkTitle:before,
  .isNotDesktop .CareersFooter-link:active .CareersFooter-linkTitle:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
@media (min-width: 1920px) {
  .CareersFooter-link {
    padding-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-link {
    padding-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-link {
    padding-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .CareersFooter-link {
    padding-top: 6.25vw;
    margin-top: 15.625vw;
  }
  .CareersFooter-link:first-of-type {
    margin-top: 0;
  }
}
.CareersFooter-link:first-of-type {
  margin-left: 0;
}
.CareersFooter-linkTitle {
  color: #FFFFFF;
  line-height: 1.42857143;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .CareersFooter-linkTitle {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-linkTitle {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-linkTitle {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CareersFooter-linkTitle {
    font-size: 18.66666667px;
  }
}
.isDesktop .CareersFooter-linkTitle:hover,
.isNotDesktop .CareersFooter-linkTitle:active {
  text-decoration: underline;
}
.isDesktop .CareersFooter-linkTitle:hover:before,
.isNotDesktop .CareersFooter-linkTitle:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CareersFooter-linkTitle:hover:before,
  .isNotDesktop .CareersFooter-linkTitle:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CareersFooter-linkTitle {
    letter-spacing: 0;
  }
}
.CareersFooter-linkTitle:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .CareersFooter-linkTitle:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CareersFooter-linkTitle:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CareersFooter-linkTitle:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-linkTitle:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-linkTitle:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CareersFooter-linkTitle:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .CareersFooter-linkTitle {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-linkTitle {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-linkTitle {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CareersFooter-linkTitle {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .CareersFooter-linkTitle span {
    padding-bottom:  1.3333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-linkTitle span {
    padding-bottom:  0.06944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-linkTitle span {
    padding-bottom:  0.08064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CareersFooter-linkTitle span {
    padding-bottom:  0.3125vw;
  }
}
.CareersFooter-linkDescription {
  color: #FFFFFF;
  opacity: 0.6;
}
@media (min-width: 1920px) {
  .CareersFooter-linkDescription {
    margin-top: 17.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersFooter-linkDescription {
    margin-top: 0.90277778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersFooter-linkDescription {
    margin-top: 1.0483871vw;
  }
}
@media (max-width: 759px) {
  .CareersFooter-linkDescription {
    margin-top: 1.5625vw;
  }
}
