.TwoColumnsTable {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .TwoColumnsTable {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TwoColumnsTable {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TwoColumnsTable {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .TwoColumnsTable {
    margin-top:  9.375vw;
  }
}
.TwoColumnsTable:first-child {
  margin-top: 0;
}
.TwoColumnsTable-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.TwoColumnsTable-tableRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .TwoColumnsTable-tableRow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.TwoColumnsTable-tableRow:first-child {
  border-top: none;
  padding-top: 0;
}
.TwoColumnsTable-tableRow:last-child {
  padding-bottom: 0;
}
@media (min-width: 1920px) {
  .TwoColumnsTable-tableRow {
    padding:  13.333333333333332px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TwoColumnsTable-tableRow {
    padding:  0.6944444444444444vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TwoColumnsTable-tableRow {
    padding:  0.8064516129032258vw 0vw;
  }
}
@media (max-width: 759px) {
  .TwoColumnsTable-tableRow {
    padding:  3.125vw 0vw;
  }
}
.TwoColumnsTable-tableRowTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #6F7380;
  width: 52.63157895%;
  padding-right: 1.31578947%;
}
@media (max-width: 759px) {
  .TwoColumnsTable-tableRowTitle {
    width: 100%;
    padding-right: 0;
    font-size: 3.75vw;
    line-height: 1.66666667;
    letter-spacing: -0.24px;
  }
}
.TwoColumnsTable-tableRowValue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #111112;
  width: 47.36842105%;
}
@media (max-width: 759px) {
  .TwoColumnsTable-tableRowValue {
    width: 100%;
    font-size: 3.75vw;
    line-height: 1.66666667;
    letter-spacing: -0.24px;
  }
}
