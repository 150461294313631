.InspirationCasePopup-content {
  background-color: #FFFFFF;
}
.InspirationCasePopup-head {
  width: 100%;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1920px) {
  .InspirationCasePopup-head {
    height: 1146.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCasePopup-head {
    height: 59.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCasePopup-head {
    height: 69.35483871vw;
  }
}
@media (max-width: 759px) {
  .InspirationCasePopup-head {
    height: 100vw;
  }
}
.InspirationCasePopup-head img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.brand--thorn .InspirationCasePopup-label {
  border-color: #111112;
  color: #111112;
}
.InspirationCasePopup-label_mobile {
  display: none;
}
@media (max-width: 759px) {
  .InspirationCasePopup-label_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 6.25vw;
    left: 6.25vw;
    color: #FFFFFF;
    border-color: #FFFFFF;
    width: unset;
    height: unset;
    padding: 2.1875vw 3.125vw 1.875vw;
    font-size: 1.875vw;
  }
}
@media (min-width: 1920px) {
  .InspirationCasePopup-contentWrapper {
    padding: 0 160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCasePopup-contentWrapper {
    padding: 0 8.33333333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCasePopup-contentWrapper {
    padding: 0 9.67741935vw;
  }
}
@media (max-width: 759px) {
  .InspirationCasePopup-contentWrapper {
    padding: 0 6.25vw;
  }
}
.InspirationCasePopup-titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .InspirationCasePopup-titleBlock {
    margin-top: 86.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCasePopup-titleBlock {
    margin-top: 4.51388889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCasePopup-titleBlock {
    margin-top: 5.24193548vw;
  }
}
@media (max-width: 759px) {
  .InspirationCasePopup-titleBlock {
    margin-top: 10.3125vw;
  }
}
.InspirationCasePopup-titleWrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80%;
          flex: 0 0 80%;
}
@media (max-width: 759px) {
  .InspirationCasePopup-titleWrapper {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
.InspirationCasePopup-titleInfoWrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .InspirationCasePopup-titleInfoWrapper {
    padding-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCasePopup-titleInfoWrapper {
    padding-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCasePopup-titleInfoWrapper {
    padding-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .InspirationCasePopup-titleInfoWrapper {
    display: none;
  }
}
.InspirationCasePopup-buttonsWrapper .TopPageButtons {
  position: relative;
}
.InspirationCasePopup-title {
  color: #111112;
}
.InspirationCasePopup-location {
  color: #A6A8B3;
  text-transform: none;
}
.InspirationCasePopup-descriptionWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .InspirationCasePopup-descriptionWrapper {
    margin-top: 72px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCasePopup-descriptionWrapper {
    margin-top: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCasePopup-descriptionWrapper {
    margin-top: 4.35483871vw;
  }
}
@media (max-width: 759px) {
  .InspirationCasePopup-descriptionWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
.InspirationCasePopup-related {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
@media (max-width: 759px) {
  .InspirationCasePopup-related {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
.InspirationCasePopup-description {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
}
@media (max-width: 759px) {
  .InspirationCasePopup-description {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-top: 8.4375vw;
  }
}
.InspirationCasePopup-descriptionText {
  color: #6F7380;
  letter-spacing: 0;
}
@media (min-width: 1920px) {
  .InspirationCasePopup-descriptionText {
    margin-top: 80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCasePopup-descriptionText {
    margin-top: 4.16666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCasePopup-descriptionText {
    margin-top: 4.83870968vw;
  }
}
@media (max-width: 759px) {
  .InspirationCasePopup-descriptionText {
    display: none;
  }
}
.InspirationCasePopup-descriptionText_mobile {
  display: none;
  letter-spacing: 0;
}
@media (max-width: 759px) {
  .InspirationCasePopup-descriptionText_mobile {
    display: block;
    margin-top: 16.25vw;
    color: #6F7380;
  }
}
