.LayoutsAllServices-title {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
@media (max-width: 759px) {
  .LayoutsAllServices-title {
    width: 87.5vw;
    margin: 0 auto;
    padding-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-title {
    width: 100%;
    margin: 0 auto;
    padding-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-title {
    width: 100%;
    margin: 0 auto;
    padding-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-title {
    width: 100%;
    margin: 0 auto;
    padding-top: 46.66666667px;
  }
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabs {
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-tabs {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-tabs {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-tabs {
    margin-top: 53.33333333px;
  }
}
.LayoutsAllServices-tabWrapper {
  text-decoration: none;
  position: relative;
}
.LayoutsAllServices-tabWrapper:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabWrapper:not(:last-child) {
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-tabWrapper:not(:last-child) {
    margin-bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-tabWrapper:not(:last-child) {
    margin-bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-tabWrapper:not(:last-child) {
    margin-bottom: 53.33333333px;
  }
}
.LayoutsAllServices-tabWrapperArrow {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  top: 50%;
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabWrapperArrow {
    margin-top: 0;
    right: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-tabWrapperArrow {
    margin-top: -0.80645161vw;
    right: 0.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-tabWrapperArrow {
    margin-top: -0.69444444vw;
    right: 0.55555556vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-tabWrapperArrow {
    margin-top: -13.33333333px;
    right: 10.66666667px;
  }
}
.LayoutsAllServices-tabWrapperArrow:before {
  content: '';
  display: inline-block;
  position: relative;
  background: url('/static/img/arrow-dark.svg') no-repeat;
  z-index: 2;
  will-change: transform;
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabWrapperArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .LayoutsAllServices-tabWrapperArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabWrapperArrow:before {
    width: 8.125vw;
    height: 5.625vw;
    background-size: 8.125vw 5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-tabWrapperArrow:before {
    width: 3.70967742vw;
    height: 2.01612903vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-tabWrapperArrow:before {
    width: 3.19444444vw;
    height: 1.73611111vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-tabWrapperArrow:before {
    width: 61.33333333px;
    height: 33.33333333px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.isDesktop .LayoutsAllServices-tabWrapper:hover .LayoutsAllServices-tabWrapperArrow:before,
.isNotDesktop .LayoutsAllServices-tabWrapper:active .LayoutsAllServices-tabWrapperArrow:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .LayoutsAllServices-tabWrapper:hover .LayoutsAllServices-tabWrapperArrow:before,
  .isNotDesktop .LayoutsAllServices-tabWrapper:active .LayoutsAllServices-tabWrapperArrow:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.LayoutsAllServices-tabHref {
  text-decoration: none;
  color: #111112;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabTitle {
    margin-top: 6.25vw;
    width: 87.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-tabTitle {
    margin-top: 2.41935484vw;
    width: 40.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-tabTitle {
    margin-top: 2.08333333vw;
    width: 34.72222222vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-tabTitle {
    margin-top: 40px;
    width: 666.66666667px;
  }
}
.LayoutsAllServices-tabDescription {
  color: #6F7380;
}
@media (max-width: 759px) {
  .LayoutsAllServices-tabDescription {
    margin-top: 6.25vw;
    width: 65.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsAllServices-tabDescription {
    margin-top: 1.61290323vw;
    width: 40.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsAllServices-tabDescription {
    margin-top: 1.38888889vw;
    width: 34.72222222vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsAllServices-tabDescription {
    margin-top: 26.66666667px;
    width: 666.66666667px;
  }
}
