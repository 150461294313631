
.c-dark {
  color: #111112;
}
.c-gray {
  color: #6F7380;
}
.c-light {
  color: #FFFFFF;
}
.text-list {
  margin-left: 10px;
  padding-left: 20px;
  list-style-type: disc;
}
.s-title-big {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .s-title-big {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-big {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-big {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .s-title-big {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .s-title-big {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.s-title-medium {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.04666667em;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .s-title-medium {
    font-size:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-medium {
    font-size:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-medium {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .s-title-medium {
    font-size:  5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-medium {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
.s-title-normal {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .s-title-normal {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-normal {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-normal {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-title-normal {
    font-size: 26.66666667px;
  }
}
.s-title-small {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .s-title-small {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-small {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-small {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .s-title-small {
    font-size: 18.66666667px;
  }
}
.s-title-tiny {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .s-title-tiny {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-tiny {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-tiny {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .s-title-tiny {
    font-size: 16px;
  }
}
.s-quote-normal {
  color: #6F7380;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .s-quote-normal {
    font-size: 6.25vw;
    letter-spacing: -0.4px;
    line-height: 1.5;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-quote-normal {
    font-size: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-quote-normal {
    font-size: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .s-quote-normal {
    font-size: 40px;
  }
}
.s-quote-author {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #6F7380;
}
@media (max-width: 759px) {
  .s-quote-author {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-quote-author {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-quote-author {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-quote-author {
    font-size: 26.66666667px;
  }
}
.s-rich-title {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0465em;
  line-height: 1;
  text-transform: uppercase;
  color: #6F7380;
  text-transform: inherit;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .s-rich-title {
    font-size: 4.375vw;
    letter-spacing: -0.03357143em;
    line-height: 1.42857143;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-rich-title {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-rich-title {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-rich-title {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .s-rich-title {
    line-height: 1.71428571;
  }
}
.s-rich-text {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #6F7380;
}
@media (max-width: 759px) {
  .s-rich-text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-rich-text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-rich-text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-rich-text {
    font-size: 26.66666667px;
  }
}
.s-rich-text-small {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  color: #6F7380;
}
@media (max-width: 759px) {
  .s-rich-text-small {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-rich-text-small {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-rich-text-small {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .s-rich-text-small {
    font-size: 18.66666667px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-rich-text-small {
    line-height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-rich-text-small {
    line-height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-rich-text-small {
    line-height: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .s-rich-text-small {
    font-size: 4.375vw;
    line-height: 6.25vw !important;
    letter-spacing: -0.28px;
  }
}
.special-link {
  color: #111112;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: normal;
  white-space: nowrap;
}
@media (max-width: 759px) {
  .special-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    letter-spacing: -0.28px;
    padding-top: 3.125vw;
  }
  .isDesktop .special-link:hover,
  .isNotDesktop .special-link:active {
    text-decoration: underline;
  }
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .special-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/svg/product-download-icon.svg') no-repeat;
  }
  .isDesktop .special-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .special-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .special-link {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .special-link {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .special-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .special-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .special-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .special-link:before {
    width: 8.125vw;
    height: 6.25vw;
    background-size: 8.125vw 6.25vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .special-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 4.03225806vw;
  }
  .isDesktop .special-link:hover,
  .isNotDesktop .special-link:active {
    text-decoration: underline;
  }
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .special-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .special-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .special-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .special-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .special-link {
    letter-spacing: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .special-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (max-width: 759px) {
  .special-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) and (min-width: 1920px) {
  .special-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .special-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 3.47222222vw;
  }
  .isDesktop .special-link:hover,
  .isNotDesktop .special-link:active {
    text-decoration: underline;
  }
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .special-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .special-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .special-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1920px) {
  .special-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .special-link {
    letter-spacing: 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .special-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (max-width: 759px) {
  .special-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) and (min-width: 1920px) {
  .special-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .special-link {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    padding-top: 66.66666667px;
  }
  .isDesktop .special-link:hover,
  .isNotDesktop .special-link:active {
    text-decoration: underline;
  }
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .special-link:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .special-link:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .special-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 1920px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1920px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) and (min-width: 1920px) {
  .special-link {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .isDesktop .special-link:hover:before,
  .isNotDesktop .special-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .special-link {
    letter-spacing: 0;
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .special-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (min-width: 1920px) and (max-width: 759px) {
  .special-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 1920px) and (min-width: 760px) and (max-width: 1239px) {
  .special-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1920px) and (min-width: 1240px) and (max-width: 1919px) {
  .special-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) and (min-width: 1920px) {
  .special-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.isDesktop .special-link:hover,
.isNotDesktop .special-link:active {
  cursor: pointer;
}
.s-text-normal {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .s-text-normal {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-text-normal {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-text-normal {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-text-normal {
    font-size: 26.66666667px;
  }
}
.s-text-small {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
}
@media (max-width: 759px) {
  .s-text-small {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-text-small {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-text-small {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .s-text-small {
    font-size: 18.66666667px;
  }
}
.s-text-tiny {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
}
@media (max-width: 759px) {
  .s-text-tiny {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-text-tiny {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-text-tiny {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .s-text-tiny {
    font-size: 16px;
  }
}
.s-button {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  width: 100%;
  background-color: #111112;
  color: #FFFFFF;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
}
@media (max-width: 759px) {
  .s-button {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-button {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-button {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .s-button {
    font-size: 16px;
  }
}
.s-button:visited {
  color: #FFFFFF;
}
.isDesktop .s-button:hover,
.isNotDesktop .s-button:active {
  background-color: transparent;
  color: #111112;
  border: 1px solid #111112;
}
@media (max-width: 759px) {
  .s-button {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-button {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-button {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .s-button {
    height: 53.33333333px;
  }
}
.s-button.disabled {
  pointer-events: none;
  background-color: #A6A8B3;
  color: #FFFFFF;
  border: 1px solid #A6A8B3;
}
.s-button-white {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  width: 100%;
  background-color: #FFFFFF;
  color: #111112;
  padding: 2px 0 0 0;
  text-decoration: none;
  -webkit-transition: 0.15s background-color, 0.15s color, 0.15s border;
  transition: 0.15s background-color, 0.15s color, 0.15s border;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  border: none;
}
@media (max-width: 759px) {
  .s-button-white {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-button-white {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-button-white {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .s-button-white {
    font-size: 16px;
  }
}
.s-button-white:visited {
  color: #111112;
}
.isDesktop .s-button-white:hover,
.isNotDesktop .s-button-white:active {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media (max-width: 759px) {
  .s-button-white {
    width: 100%;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-button-white {
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-button-white {
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .s-button-white {
    height: 53.33333333px;
  }
}
.s-label-rounded {
  border: 1px solid #111112;
  color: #111112;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  text-transform: uppercase;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  pointer-events: none;
  letter-spacing: normal;
  font-weight: normal;
  text-align: center;
}
@media (min-width: 1920px) {
  .s-label-rounded {
    border-radius:  13.333333333333332px;
    font-size:  12px;
    max-width:  200px;
    padding:  6.666666666666666px 13.333333333333332px 5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-label-rounded {
    border-radius:  0.6944444444444444vw;
    font-size:  0.625vw;
    max-width:  10.416666666666668vw;
    padding:  0.3472222222222222vw 0.6944444444444444vw 0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-label-rounded {
    border-radius:  0.8064516129032258vw;
    font-size:  0.7258064516129032vw;
    max-width:  12.096774193548386vw;
    padding:  0.4032258064516129vw 0.8064516129032258vw 0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .s-label-rounded {
    border-radius:  3.125vw;
    font-size:  2.8125vw;
    max-width:  46.875vw;
    padding:  1.5625vw 3.125vw 1.25vw;
  }
}
.brand--thorn .s-label-rounded {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.s-arrow-item-bottom {
  position: relative;
}
.isDesktop .needHoverArrow:hover .s-arrow-item-bottom:before,
.isNotDesktop .needHoverArrow:active .s-arrow-item-bottom:before {
  background-position: 0 0;
}
.s-arrow-item-bottom:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  background: url('/static/img/arrow-big.svg') no-repeat;
  will-change: transform;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
.isDesktop .s-arrow-item-bottom:before {
  -webkit-transition: 0.15s background-position, 0.15s opacity;
  transition: 0.15s background-position, 0.15s opacity;
}
@media (min-width: 1920px) {
  .s-arrow-item-bottom:before {
    width:  46.666666666666664px;
    height:  24px;
    background-size:  46.666666666666664px 24px;
    background-position:  -10.666666666666666px 0;
    vertical-align:  -5.333333333333333px;
    margin-left:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-arrow-item-bottom:before {
    width:  2.430555555555556vw;
    height:  1.25vw;
    background-size:  2.430555555555556vw 1.25vw;
    background-position:  -0.5555555555555556vw 0vw;
    vertical-align:  -0.2777777777777778vw;
    margin-left:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-arrow-item-bottom:before {
    width:  2.82258064516129vw;
    height:  1.4516129032258065vw;
    background-size:  2.82258064516129vw 1.4516129032258065vw;
    background-position:  -0.6451612903225806vw 0vw;
    vertical-align:  -0.3225806451612903vw;
    margin-left:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .s-arrow-item-bottom:before {
    width:  8.125vw;
    height:  4.375vw;
    background-size:  8.125vw 4.375vw;
    vertical-align:  -0.9375vw;
    margin-left:  1.25vw;
    background-position:  -1.25vw 0vw;
  }
}
.s-card-link {
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
}
@media (min-width: 1920px) {
  .s-card-link {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-card-link {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-card-link {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .s-card-link {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .s-card-link:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .s-card-link-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .s-card-link:hover .s-card-link-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .s-card-link:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.s-card-link:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .s-card-link:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .s-card-link:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .s-card-link:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .s-card-link:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.s-card-link:active:after {
  opacity: 0.5;
}
.s-card-link:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .s-card-link:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .s-card-link:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-card-link:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-card-link:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .s-card-link:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.s-card-link:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .s-card-link:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.s-filter-select {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 2.91666667;
}
@media (max-width: 759px) {
  .s-filter-select {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-filter-select {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-filter-select {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .s-filter-select {
    font-size: 16px;
  }
}
.brand--thorn .s-filter-select {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .s-filter-select {
    padding-bottom: 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-filter-select {
    padding-bottom: 0.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-filter-select {
    padding-bottom: 0.13888889vw;
  }
}
@media (min-width: 1920px) {
  .s-filter-select {
    padding-bottom: 2.66666667px;
  }
}
.filterItemTwoLine .s-filter-select {
  line-height: 1.25;
}
@media (max-width: 759px) {
  .filterItemTwoLine .s-filter-select {
    padding-bottom: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .filterItemTwoLine .s-filter-select {
    padding-bottom: 0.40322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .filterItemTwoLine .s-filter-select {
    padding-bottom: 0.34722222vw;
  }
}
@media (min-width: 1920px) {
  .filterItemTwoLine .s-filter-select {
    padding-bottom: 6.66666667px;
  }
}
.filterItemTwoLine .s-filter-select p {
  width: 88%;
  text-overflow: unset;
  overflow: hidden;
  white-space: pre-wrap;
}
.isDesktop .s-filter-select:hover:after,
.isNotDesktop .s-filter-select:active:after {
  width: 0;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.isDesktop .s-filter-select:hover:before,
.isNotDesktop .s-filter-select:active:before {
  width: 100%;
  -webkit-transition: 0.15s width 0.12s ease-out;
  transition: 0.15s width 0.12s ease-out;
}
.s-filter-select:after {
  content: '';
  background: #111112;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.brand--thorn .s-filter-select:after {
  background: #FFFFFF;
}
.s-filter-select:before {
  content: '';
  background: #111112;
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 0;
  left: 0;
}
.brand--thorn .s-filter-select:before {
  background: #FFFFFF;
}
.s-filter-select > p {
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.s-filter-select span {
  position: absolute;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
@media (max-width: 759px) {
  .s-filter-select span {
    right: 1.875vw;
    border-left: 1.25vw solid transparent;
    border-right: 1.25vw solid transparent;
    border-top: 1.5625vw solid #111112;
    top: 4.375vw;
  }
  .filterItemTwoLine .s-filter-select span {
    top: 3.75vw;
  }
  .brand--thorn .s-filter-select span {
    border-top: 1.5625vw solid #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-filter-select span {
    right: 0.48387097vw;
    border-left: 0.32258065vw solid transparent;
    border-right: 0.32258065vw solid transparent;
    border-top: 0.40322581vw solid #111112;
    top: 1.12903226vw;
  }
  .filterItemTwoLine .s-filter-select span {
    top: 0.96774194vw;
  }
  .brand--thorn .s-filter-select span {
    border-top: 0.40322581vw solid #FFFFFF;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-filter-select span {
    right: 0.41666667vw;
    border-left: 0.27777778vw solid transparent;
    border-right: 0.27777778vw solid transparent;
    border-top: 0.34722222vw solid #111112;
    top: 0.97222222vw;
  }
  .filterItemTwoLine .s-filter-select span {
    top: 0.83333333vw;
  }
  .brand--thorn .s-filter-select span {
    border-top: 0.34722222vw solid #FFFFFF;
  }
}
@media (min-width: 1920px) {
  .s-filter-select span {
    right: 8px;
    border-left: 5.33333333px solid transparent;
    border-right: 5.33333333px solid transparent;
    border-top: 6.66666667px solid #111112;
    top: 18.66666667px;
  }
  .filterItemTwoLine .s-filter-select span {
    top: 16px;
  }
  .brand--thorn .s-filter-select span {
    border-top: 6.66666667px solid #FFFFFF;
  }
}
@-webkit-keyframes tab-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tab-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.s-slider-arrow {
  position: absolute;
  background: center / cover url('/static/img/arrow-card-slider.svg') no-repeat;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  cursor: pointer;
  z-index: 4;
}
@media (max-width: 759px) {
  .s-slider-arrow {
    height: 9.375vw;
    width: 6.5625vw;
    top: 70.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-slider-arrow {
    width: 3.38709677vw;
    height: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-slider-arrow {
    width: 2.91666667vw;
    height: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .s-slider-arrow {
    width: 56px;
    height: 80px;
  }
}
.s-slider-arrow[data-tp="prev"] {
  background: center / cover url('/static/img/arrow-card-slider-left.svg') no-repeat;
}
.isDesktop .s-slider-arrow:hover,
.isNotDesktop .s-slider-arrow:active {
  opacity: 0.7;
}
.s-slider-arrow--hidden {
  opacity: 0 !important;
  cursor: default;
}
.s-two-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (min-width: 1920px) {
  .s-two-columns.withPadding {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-two-columns.withPadding {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-two-columns.withPadding {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .s-two-columns.withPadding {
    padding:  0vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .s-two-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .s-two-columns > div {
    width: 100%;
  }
  .s-two-columns > div:nth-child(2) {
    margin-top: 23.75vw;
  }
}
.s-two-columns > div,
.s-two-columns section {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .s-two-columns > div,
  .s-two-columns section {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    width: 100%;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .s-two-columns > div:nth-child(2),
  .s-two-columns section:nth-child(2) {
    margin-top:  180px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .s-two-columns > div:nth-child(2),
  .s-two-columns section:nth-child(2) {
    margin-top:  9.375vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .s-two-columns > div:nth-child(2),
  .s-two-columns section:nth-child(2) {
    margin-top:  10.887096774193548vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .s-two-columns > div:nth-child(2),
  .s-two-columns section:nth-child(2) {
    margin-top:  25vw;
  }
}
.s-title-text-link {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.s-title-text-link:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  height: 1px;
  width: 100%;
  background-color: #FFFFFF;
}
.s-title-text-link--title {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .s-title-text-link--title {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-text-link--title {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-text-link--title {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .s-title-text-link--title {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .s-title-text-link--title {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 1920px) {
  .s-title-text-link--title {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-text-link--title {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-text-link--title {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .s-title-text-link--title {
    padding-top:  7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-text-link--title {
    font-size: 4.03225806vw;
  }
}
@media (max-width: 759px) {
  .s-title-text-link--title {
    padding-top: 3.125vw;
  }
}
.s-title-text-link--text {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .s-title-text-link--text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-text-link--text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-text-link--text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .s-title-text-link--text {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .s-title-text-link--text {
    margin-top:  40px;
    padding-bottom:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .s-title-text-link--text {
    margin-top:  2.0833333333333335vw;
    padding-bottom:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .s-title-text-link--text {
    margin-top:  2.4193548387096775vw;
    padding-bottom:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .s-title-text-link--text {
    margin-top:  9.375vw;
    padding-bottom:  15.625vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .s-title-text-link--text {
    margin-top:  26.666666666666664px;
    padding-bottom:  0;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .s-title-text-link--text {
    margin-top:  1.3888888888888888vw;
    padding-bottom:  0vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .s-title-text-link--text {
    margin-top:  1.6129032258064515vw;
    padding-bottom:  0vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .s-title-text-link--text {
    margin-top:  6.25vw;
    padding-bottom:  0vw;
  }
}
.u-Clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.u-Btn {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: inherit;
  outline: none;
  cursor: pointer;
}
.u-Btn--disabled {
  pointer-events: none;
  cursor: default;
}
.u-Link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.u-Link--underline {
  text-decoration: underline;
}
.isDesktop .u-Link--underline:hover,
.isNotDesktop .u-Link--underline:active {
  text-decoration: none;
}
.u-Grid:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.u-Col {
  float: left;
  height: 100%;
  height: inherit;
  min-height: 1px;
}
.u-InCol {
  display: inline-block;
  vertical-align: top;
}
.u-Table {
  display: table;
  height: 100%;
  width: 100%;
}
.u-Cell {
  display: table-cell;
  height: 100%;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.u-CenteredBlock:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.u-VerticallyCentered {
  display: inline-block;
  vertical-align: middle;
}
.u-ScreenCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.u-ScreenCenterX {
  position: absolute;
  left: 50%;
  min-width: 100%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.u-FocusPlaceholder:focus::-webkit-input-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus::-moz-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus:-ms-input-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus::-ms-input-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus::placeholder {
  color: transparent;
}
.u-absoluteSvg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: 'apercu-mono-regular-pro';
  src: url('/static/fonts/apercu-mono-regular-pro.eot');
  src: url('/static/fonts/apercu-mono-regular-pro.eot?#iefix') format('embedded-opentype'), url('/static/fonts/apercu-mono-regular-pro.woff2') format('woff2'), url('/static/fonts/apercu-mono-regular-pro.woff') format('woff'), url('/static/fonts/apercu-mono-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
img {
  max-width: 100%;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input,
select,
textarea,
button {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
}
html,
body {
  width: 100%;
  height: 100%;
}
html {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern";
  -ms-font-feature-settings: "kern" 1;
  font-feature-settings: "kern";
}
.FixedOnPopup {
  position: fixed !important;
  left: 0 !important;
  width: 100% !important;
}
@media (max-width: 759px) {
  body.search-shown [data-blur-on-search] {
    -webkit-filter: blur(3.125vw) !important;
            filter: blur(3.125vw) !important;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  body.search-shown [data-blur-on-search] {
    -webkit-filter: blur(0.80645161vw) !important;
            filter: blur(0.80645161vw) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  body.search-shown [data-blur-on-search] {
    -webkit-filter: blur(0.69444444vw) !important;
            filter: blur(0.69444444vw) !important;
  }
}
@media (min-width: 1920px) {
  body.search-shown [data-blur-on-search] {
    -webkit-filter: blur(13.33333333px) !important;
            filter: blur(13.33333333px) !important;
  }
}
@media (max-width: 759px) {
  body.PopupShow [data-blur-on-popup] {
    -webkit-filter: blur(3.125vw) !important;
            filter: blur(3.125vw) !important;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  body.PopupShow [data-blur-on-popup] {
    -webkit-filter: blur(0.80645161vw) !important;
            filter: blur(0.80645161vw) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  body.PopupShow [data-blur-on-popup] {
    -webkit-filter: blur(0.69444444vw) !important;
            filter: blur(0.69444444vw) !important;
  }
}
@media (min-width: 1920px) {
  body.PopupShow [data-blur-on-popup] {
    -webkit-filter: blur(13.33333333px) !important;
            filter: blur(13.33333333px) !important;
  }
}
*[data-blur-on-search] {
  -webkit-transition: 0.3s -webkit-filter;
  transition: 0.3s -webkit-filter;
  transition: 0.3s filter;
  transition: 0.3s filter, 0.3s -webkit-filter;
}
p:empty:after {
  content: "\00a0";
}
.hidden {
  display: none !important;
}
/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
  z-index: 27;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}
/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}
.Page-wrapper {
  position: relative;
  z-index: 0;
}
/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
::-ms-clear,
::-ms-reveal {
  display: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
