.FooterSlim {
  width: 100%;
  position: relative;
  z-index: 20;
}
.FooterSlim--hidden .FooterSlim-wrapper {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 759px) {
  .FooterSlim--hidden .FooterSlim-wrapper {
    opacity: 1;
  }
}
.FooterSlim-wrapper {
  -webkit-transition: 0.35s opacity;
  transition: 0.35s opacity;
  will-change: opacity;
  background: #111112;
  width: 100%;
}
@media (min-width: 1920px) {
  .FooterSlim-wrapper {
    padding:  80px 186.66666666666666px 44px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-wrapper {
    padding:  4.166666666666667vw 9.722222222222223vw 2.291666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-wrapper {
    padding:  4.838709677419355vw 11.29032258064516vw 2.661290322580645vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-wrapper {
    padding:  11.875vw 0vw 0vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}
.PageGlobal .FooterSlim-wrapper {
  padding-top: 0;
}
.FooterSlim-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.PageGlobal .FooterSlim-top {
  display: none;
}
@media (max-width: 759px) {
  .FooterSlim-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0 6.25vw 12.5vw;
  }
}
.FooterSlim-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 13.79310345%;
}
@media (max-width: 759px) {
  .FooterSlim-menu {
    margin-right: 0;
    width: 100%;
  }
}
.FooterSlim-menuLogo {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  background-color: #111112;
}
@media (min-width: 1920px) {
  .FooterSlim-menuLogo {
    width:  181.33333333333331px;
    height:  29.333333333333332px;
    margin-top:  -2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-menuLogo {
    width:  9.444444444444445vw;
    height:  1.527777777777778vw;
    margin-top:  -0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-menuLogo {
    width:  10.96774193548387vw;
    height:  1.7741935483870968vw;
    margin-top:  -0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-menuLogo {
    width:  42.5vw;
    height:  6.875vw;
    margin-top:  -0.625vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-menuLogo {
    margin-top: 0;
  }
}
.isDesktop .FooterSlim-menuLogo {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.isDesktop .FooterSlim-menuLogo:hover,
.isNotDesktop .FooterSlim-menuLogo:active {
  opacity: 0.7;
}
.FooterSlim-menuLogo:after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.brand--zumtobel .FooterSlim-menuLogo:after {
  background-image: url('/static/img/logo-zumtobel.png');
  left: 0;
  top: 0;
}
@media (min-width: 1920px) {
  .brand--zumtobel .FooterSlim-menuLogo:after {
    width:  181.33333333333331px;
    height:  29.333333333333332px;
    background-size:  181.33333333333331px 29.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--zumtobel .FooterSlim-menuLogo:after {
    width:  9.444444444444445vw;
    height:  1.527777777777778vw;
    background-size:  9.444444444444445vw 1.527777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--zumtobel .FooterSlim-menuLogo:after {
    width:  10.96774193548387vw;
    height:  1.7741935483870968vw;
    background-size:  10.96774193548387vw 1.7741935483870968vw;
  }
}
@media (max-width: 759px) {
  .brand--zumtobel .FooterSlim-menuLogo:after {
    width:  42.5vw;
    height:  6.875vw;
    background-size:  42.5vw 6.875vw;
  }
}
.brand--thorn .FooterSlim-menuLogo:after {
  background-image: url('/static/svg/logo-thorn.svg');
}
@media (min-width: 1920px) {
  .brand--thorn .FooterSlim-menuLogo:after {
    width:  109.33333333333333px;
    height:  21.333333333333332px;
    background-size:  109.33333333333333px 21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .FooterSlim-menuLogo:after {
    width:  5.694444444444445vw;
    height:  1.1111111111111112vw;
    background-size:  5.694444444444445vw 1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .FooterSlim-menuLogo:after {
    width:  6.612903225806451vw;
    height:  1.2903225806451613vw;
    background-size:  6.612903225806451vw 1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .brand--thorn .FooterSlim-menuLogo:after {
    width:  25.625vw;
    height:  5vw;
    background-size:  25.625vw 5vw;
  }
}
.FooterSlim-menuItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
@media (min-width: 1920px) {
  .FooterSlim-menuItems {
    margin-top:  46.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-menuItems {
    margin-top:  2.430555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-menuItems {
    margin-top:  2.82258064516129vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-menuItems {
    margin-top:  10.9375vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-menuItems {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (max-width: 759px) {
  .FooterSlim-menuItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
  }
}
.FooterSlim-menuItem a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  line-height: 2.85714286;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .FooterSlim-menuItem a {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-menuItem a {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-menuItem a {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-menuItem a {
    font-size: 18.66666667px;
  }
}
.isDesktop .FooterSlim-menuItem a:hover,
.isNotDesktop .FooterSlim-menuItem a:active {
  text-decoration: underline;
}
.FooterSlim-selectLinks {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  display: none;
  border: 1px solid #373739;
  width: 100%;
  text-transform: uppercase;
  color: #FFFFFF;
  line-height: 0.83333333;
  position: relative;
}
@media (max-width: 759px) {
  .FooterSlim-selectLinks {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-selectLinks {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-selectLinks {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-selectLinks {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-selectLinks {
    margin-top:  33.33333333333333px;
    padding:  21.333333333333332px 26.666666666666664px 18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-selectLinks {
    margin-top:  1.7361111111111112vw;
    padding:  1.1111111111111112vw 1.3888888888888888vw 0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-selectLinks {
    margin-top:  2.0161290322580645vw;
    padding:  1.2903225806451613vw 1.6129032258064515vw 1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-selectLinks {
    margin-top:  7.8125vw;
    padding:  5vw 6.25vw 4.375vw;
  }
}
.FooterSlim-selectLinks:after {
  content: '';
  width: 0;
  height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (min-width: 1920px) {
  .FooterSlim-selectLinks:after {
    right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-selectLinks:after {
    right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-selectLinks:after {
    right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-selectLinks:after {
    right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-selectLinks:after {
    border-left: 1.25vw solid transparent;
    border-right: 1.25vw solid transparent;
    border-top: 1.25vw solid #FFFFFF;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-selectLinks:after {
    border-left: 0.32258065vw solid transparent;
    border-right: 0.32258065vw solid transparent;
    border-top: 0.32258065vw solid #FFFFFF;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-selectLinks:after {
    border-left: 0.27777778vw solid transparent;
    border-right: 0.27777778vw solid transparent;
    border-top: 0.27777778vw solid #FFFFFF;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-selectLinks:after {
    border-left: 5.33333333px solid transparent;
    border-right: 5.33333333px solid transparent;
    border-top: 5.33333333px solid #FFFFFF;
  }
}
.FooterSlim-links {
  width: 86.20689655%;
  left: 3.44827586%;
  position: relative;
}
.FooterSlim-linksMobile {
  display: none;
}
.isMobile .FooterSlim-linksMobile {
  display: block;
  opacity: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 759px) {
  .FooterSlim-links {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    background: #111112;
    padding: 3.125vw 0;
    width: calc(100% - 43.75vw);
    overflow: hidden;
    z-index: 15;
    height: 60%;
    opacity: 0;
    visibility: hidden;
    border: 1px solid rgba(216, 216, 216, 0.1);
    -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
    transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  }
}
.FooterSlim-links.isOpenPopup {
  visibility: visible;
  opacity: 1;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.FooterSlim-linksWrapper {
  -webkit-column-count: 5;
     -moz-column-count: 5;
          column-count: 5;
}
@media (max-width: 759px) {
  .FooterSlim-linksWrapper {
    width: 100%;
    height: 100%;
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    overflow: auto;
  }
}
.FooterSlim-link {
  display: block;
}
@media (max-width: 759px) {
  .FooterSlim-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3.125vw;
  }
}
.FooterSlim-link a {
  text-decoration: none;
  color: inherit;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  font-weight: 700;
  color: #A6A8B3;
  line-height: 2.5;
  cursor: pointer;
  letter-spacing: -0.03333333em;
}
@media (max-width: 759px) {
  .FooterSlim-link a {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-link a {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-link a {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-link a {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-link a {
    font-size:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-link a {
    font-size:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-link a {
    font-size:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-link a {
    font-size:  3.75vw;
  }
}
.isDesktop .FooterSlim-link a:hover,
.isNotDesktop .FooterSlim-link a:active {
  text-decoration: underline;
  color: #FFFFFF;
}
.FooterSlim-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .FooterSlim-bottom {
    margin-top:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-bottom {
    margin-top:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-bottom {
    margin-top:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-bottom {
    margin-top:  25vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-bottom {
    margin-top: 0;
    z-index: 5;
  }
}
.PageGlobal .FooterSlim-bottom {
  margin-top: 0;
}
.FooterSlim-logos {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid rgba(216, 216, 216, 0.1);
}
.PageGlobal .FooterSlim-logos {
  border-top: none;
}
.PageGlobalIndex .FooterSlim-logos {
  display: none;
}
@media (min-width: 1920px) {
  .FooterSlim-logos {
    padding:  62.666666666666664px 32px 62.666666666666664px 8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-logos {
    padding:  3.263888888888889vw 1.6666666666666667vw 3.263888888888889vw 0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-logos {
    padding:  3.790322580645161vw 1.935483870967742vw 3.790322580645161vw 0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-logos {
    padding:  14.6875vw 7.5vw 14.6875vw 1.875vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-logos {
    padding: 0 6.25vw;
    border-top: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.FooterSlim-logosItem {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  opacity: 0.5;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media (min-width: 1920px) {
  .FooterSlim-logosItem {
    width:  240px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-logosItem {
    width:  12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-logosItem {
    width:  14.516129032258064vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-logosItem {
    width:  56.25vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-logosItem {
    height: 25vw;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    border-top: 1px solid rgba(216, 216, 216, 0.1);
  }
}
.isDesktop .FooterSlim-logosItem {
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.isDesktop .FooterSlim-logosItem:hover,
.isNotDesktop .FooterSlim-logosItem:active {
  opacity: 1;
}
.FooterSlim-logosItemLogo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.FooterSlim-nav {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid rgba(216, 216, 216, 0.1);
}
@media (min-width: 1920px) {
  .FooterSlim-nav {
    padding-top:  30.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-nav {
    padding-top:  1.5972222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-nav {
    padding-top:  1.8548387096774193vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-nav {
    padding-top:  7.1875vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-nav {
    border: none;
    padding-left: 6.25vw;
    padding-right: 6.25vw;
    padding-top: 0;
  }
}
.FooterSlim-nav.onMobile {
  display: none;
}
@media (max-width: 759px) {
  .FooterSlim-nav.onMobile {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 3.63636364;
    display: block;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    padding: 10.625vw 6.25vw 14.0625vw;
    border-top: 1px solid rgba(216, 216, 216, 0.1);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .FooterSlim-nav.onMobile {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-nav.onMobile {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-nav.onMobile {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .FooterSlim-nav.onMobile {
    font-size: 18.66666667px;
  }
}
.FooterSlim-navLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .FooterSlim-navLinks {
    display: none;
  }
}
@media (max-width: 759px) {
  .onMobile .FooterSlim-navLinks {
    display: block;
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
}
.FooterSlim-navCopyrights {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
@media (min-width: 1920px) {
  .FooterSlim-navCopyrights {
    padding-right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navCopyrights {
    padding-right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navCopyrights {
    padding-right:  0vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navCopyrights {
    padding-right:  3.125vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navCopyrights {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    padding: 10.625vw 6.25vw 25vw;
    border-top: 1px solid rgba(216, 216, 216, 0.1);
  }
}
.FooterSlim-navCopy {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  color: #4A4D55;
  font-weight: 500;
}
@media (max-width: 759px) {
  .FooterSlim-navCopy {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navCopy {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navCopy {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-navCopy {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-navCopy {
    margin-right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navCopy {
    margin-right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navCopy {
    margin-right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navCopy {
    margin-right:  3.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navCopy {
    font-size: 0.96774194vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navCopy {
    position: absolute;
    bottom: 12.5vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.FooterSlim-navLink {
  text-decoration: none;
  color: inherit;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .FooterSlim-navLink {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-navLink {
    font-size: 18.66666667px;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-navLink {
    margin-left:  13.333333333333332px;
    margin-right:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navLink {
    margin-left:  0.6944444444444444vw;
    margin-right:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink {
    margin-left:  0.8064516129032258vw;
    margin-right:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navLink {
    margin-left:  3.125vw;
    margin-right:  3.125vw;
  }
}
.FooterSlim-navLink:first-child {
  margin-left: 0;
}
.FooterSlim-navLink:last-child {
  margin-right: 0;
}
@media (max-width: 759px) {
  .FooterSlim-navLink {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    line-height: 3.63636364;
    margin-left: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .FooterSlim-navLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navLink {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .FooterSlim-navLink {
    font-size: 18.66666667px;
  }
}
.FooterSlim-navLink a {
  text-decoration: none;
  color: inherit;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.71428571;
  color: #FFFFFF;
  cursor: pointer;
  display: unset;
  font-weight: 700;
}
@media (max-width: 759px) {
  .FooterSlim-navLink a {
    font-size: 3.4375vw;
    line-height: 1.81818182;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink a {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navLink a {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .FooterSlim-navLink a {
    font-size: 18.66666667px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink a {
    font-size: 0.96774194vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navLink a {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    line-height: 3.63636364;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .FooterSlim-navLink a {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink a {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navLink a {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .FooterSlim-navLink a {
    font-size: 18.66666667px;
  }
}
.isDesktop .FooterSlim-navLink a:hover {
  text-decoration: underline;
}
.FooterSlim-navLink svg {
  display: inline-block;
  vertical-align: sub;
}
@media (min-width: 1920px) {
  .FooterSlim-navLink svg {
    width:  21.333333333333332px;
    height:  21.333333333333332px;
    margin-right:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-navLink svg {
    width:  1.1111111111111112vw;
    height:  1.1111111111111112vw;
    margin-right:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-navLink svg {
    width:  1.2903225806451613vw;
    height:  1.2903225806451613vw;
    margin-right:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-navLink svg {
    width:  5vw;
    height:  5vw;
    margin-right:  3.75vw;
  }
}
.FooterSlim-global {
  display: none;
  border-bottom: 1px solid rgba(216, 216, 216, 0.1);
}
.FooterSlim-global .Dropdown-linkList {
  margin-top: 0;
}
@media (max-width: 759px) {
  .FooterSlim-globalFollowUs {
    display: none;
  }
}
.FooterSlim-globalFollowUs_mobile {
  display: none;
}
@media (max-width: 759px) {
  .FooterSlim-globalFollowUs_mobile {
    display: block;
  }
}
.PageGlobalTemplate .FooterSlim-global,
.PageLanding .FooterSlim-global {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .PageGlobalTemplate .FooterSlim-global,
  .PageLanding .FooterSlim-global {
    height: 100vw;
    display: block;
    padding: 15.625vw 6.25vw 12.5vw;
  }
}
.PageAbout .FooterSlim-global,
.PageCampusLanding .FooterSlim-global {
  display: none;
}
@media (min-width: 1920px) {
  .FooterSlim-global {
    height:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-global {
    height:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-global {
    height:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-global {
    height:  37.5vw;
  }
}
.FooterSlim-globalLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80%;
          flex: 0 0 80%;
  position: relative;
}
@media (max-width: 759px) {
  .FooterSlim-globalLinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 9.375vw;
    height: 62.5vw;
  }
  .FooterSlim-globalLinks:after {
    content: '';
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
}
.FooterSlim-globalLogo {
  display: none;
}
@media (max-width: 759px) {
  .FooterSlim-globalLogo {
    display: block;
  }
  .FooterSlim-globalLogo svg {
    width: 45.9375vw;
    height: 4.375vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim .Dropdown-linkListOptions {
    left: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    max-height: none;
  }
}
.FooterSlim-extended {
  border-top: 1px solid rgba(216, 216, 216, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .FooterSlim-extended {
    padding-top:  80px;
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-extended {
    padding-top:  4.166666666666667vw;
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-extended {
    padding-top:  4.838709677419355vw;
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-extended {
    margin-top:  0vw;
    padding:  12.5vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-extended {
    display: block;
  }
}
.FooterSlim-extendedColumn {
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
}
@media (min-width: 1920px) {
  .FooterSlim-extendedColumn {
    padding-right:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-extendedColumn {
    padding-right:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-extendedColumn {
    padding-right:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-extendedColumn {
    padding-right:  12.5vw;
  }
}
.FooterSlim-extendedColumn:last-of-type {
  padding-right: 0;
}
@media (max-width: 759px) {
  .FooterSlim-extendedColumn {
    padding-right: 0;
  }
  .FooterSlim-extendedColumn:first-of-type {
    margin-top: 0;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .FooterSlim-extendedColumn {
    margin-top:  53.33333333333333px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-extendedColumn {
    margin-top:  2.7777777777777777vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-extendedColumn {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .FooterSlim-extendedColumn {
    margin-top:  12.5vw;
  }
}
.FooterSlim-extendedColumn_zumtobel .FooterSlim-extendedColumnLink {
  text-transform: uppercase;
}
.FooterSlim-extendedColumnTitle {
  color: #4A4D55;
}
@media (max-width: 759px) {
  .FooterSlim-extendedColumnTitle {
    line-height: 1;
  }
}
@media (max-width: 759px) {
  .FooterSlim-extendedColumnLinkWrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.FooterSlim-extendedColumnLink {
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  font-weight: 700;
}
@media (min-width: 1920px) {
  .FooterSlim-extendedColumnLink {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .FooterSlim-extendedColumnLink {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .FooterSlim-extendedColumnLink {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-extendedColumnLink {
    padding-right:  3.125vw;
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .FooterSlim-extendedColumnLink {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
  }
}
.FooterSlim-extendedColumnLink:visited {
  color: #FFFFFF;
}
.isDesktop .FooterSlim-extendedColumnLink:hover {
  text-decoration: underline;
}
