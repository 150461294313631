.PageInvestorsLanding .PageLanding-blockBg[data-id="1"] img,
.PageInvestorsLanding .PageLanding-blockBg_mobile[data-id="1"] img {
  opacity: 0.6;
}
.PageInvestorsLanding .PageLanding-blockPlaceholder {
  height: 70vh;
}
@media (max-width: 759px) {
  .PageInvestorsLanding .PageLanding-blockPlaceholder {
    height: 40vh;
  }
}
.PageInvestorsLanding .PageLanding-blockBg_mobile:before {
  content: '';
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.PageInvestorsLanding-offer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  margin: auto 0;
}
@media (max-width: 759px) {
  .PageInvestorsLanding-offer {
    padding-top: 31.25vw;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-offerStock {
    padding:  0 0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-offerStock {
    padding:  0vw 0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-offerStock {
    padding:  0vw 0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-offerStock {
    padding:  23.75vw 0vw 24.0625vw;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-offerReports {
    padding:  213.33333333333331px 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-offerReports {
    padding:  11.11111111111111vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-offerReports {
    padding:  12.903225806451612vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-offerReports {
    padding:  23.75vw 0vw 0vw;
  }
}
.PageInvestorsLanding-events {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-events {
    padding:  400px 186.66666666666666px 160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-events {
    padding:  20.833333333333336vw 9.722222222222223vw 8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-events {
    padding:  24.193548387096772vw 11.29032258064516vw 9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-events {
    padding:  0vw 6.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-events {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.PageInvestorsLanding-eventsBlock {
  border-top: 1px solid #FFFFFF;
  width: 100%;
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-eventsBlock {
    padding-top:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-eventsBlock {
    padding-top:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-eventsBlock {
    padding-top:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsBlock {
    padding-top:  3.75vw;
    margin-bottom:  30vw;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-eventsBlock:first-child {
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-eventsBlock:first-child {
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-eventsBlock:first-child {
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsBlock:first-child {
    margin-right:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-eventsBlock:last-child {
    margin-left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-eventsBlock:last-child {
    margin-left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-eventsBlock:last-child {
    margin-left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsBlock:last-child {
    margin-left:  6.25vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsBlock {
    width: 100%;
  }
  .PageInvestorsLanding-eventsBlock:last-child {
    margin-left: 0;
  }
}
.PageInvestorsLanding-eventsFooter {
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-eventsFooter {
    padding:  53.33333333333333px 0 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-eventsFooter {
    padding:  2.7777777777777777vw 0vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-eventsFooter {
    padding:  3.225806451612903vw 0vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsFooter {
    padding:  7.5vw 0vw 0vw;
  }
}
@media (min-width: 1920px) {
  .PageInvestorsLanding-eventsFooterWrapper {
    padding:  0 186.66666666666666px 106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageInvestorsLanding-eventsFooterWrapper {
    padding:  0vw 9.722222222222223vw 5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageInvestorsLanding-eventsFooterWrapper {
    padding:  0vw 11.29032258064516vw 6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsFooterWrapper {
    padding:  0vw 6.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .PageInvestorsLanding-eventsFooter {
    text-align: left;
  }
}
.PageInvestorsLanding-eventsFooter a {
  text-decoration: underline;
  color: #FFFFFF;
  font-weight: bold;
}
.isDesktop .PageInvestorsLanding-eventsFooter a:hover,
.PageInvestorsLanding-eventsFooter a:active {
  text-decoration: none;
}
