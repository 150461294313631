@media (min-width: 1920px) {
  .CommonText {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CommonText {
    margin-top:  9.375vw;
  }
}
.CommonText:first-child {
  margin-top: 0;
}
.CommonText:first-child .CommonText-text p {
  margin-top: 0;
}
.CommonText-quote {
  position: relative;
}
@media (min-width: 1920px) {
  .CommonText-quote {
    margin-top:  53.33333333333333px;
    margin-bottom:  66.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-quote {
    margin-top:  2.7777777777777777vw;
    margin-bottom:  3.4722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-quote {
    margin-top:  3.225806451612903vw;
    margin-bottom:  4.032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CommonText-quote {
    margin-top:  9.375vw;
    margin-bottom:  12.5vw;
  }
}
.CommonText-quoteText {
  quotes: "“" "”";
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #6F7380;
}
@media (max-width: 759px) {
  .CommonText-quoteText {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-quoteText {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-quoteText {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-quoteText {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .CommonText-quoteText {
    font-size:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-quoteText {
    font-size:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-quoteText {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CommonText-quoteText {
    font-size:  5.625vw;
    line-height:  7.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-quoteText {
    line-height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-quoteText {
    line-height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-quoteText {
    line-height: 53.33333333px;
  }
}
.CommonText-quoteText:before {
  content: open-quote;
  position: absolute;
  left: -15px;
}
@media (max-width: 759px) {
  .CommonText-quoteText:before {
    left: -10px;
  }
}
.CommonText-quoteText:after {
  content: close-quote;
}
.CommonText-quoteAuthor {
  color: #6F7380;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .CommonText-quoteAuthor {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-quoteAuthor {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-quoteAuthor {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-quoteAuthor {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .CommonText-quoteAuthor {
    font-size:  26.666666666666664px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-quoteAuthor {
    font-size:  1.3888888888888888vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-quoteAuthor {
    font-size:  1.6129032258064515vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonText-quoteAuthor {
    font-size:  4.375vw;
    line-height:  6.25vw;
    margin-top:  6.25vw;
  }
}
.CommonText-text {
  color: #6F7380;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .CommonText-text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-text {
    font-size: 26.66666667px;
  }
}
.CommonText-text a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .CommonText-text a:hover,
.isNotDesktop .CommonText-text a:active {
  text-decoration: none;
}
@media (max-width: 759px) {
  .CommonText-text ul {
    margin-top: 3.125vw;
  }
}
.CommonText-text ul li {
  position: relative;
}
@media (min-width: 1920px) {
  .CommonText-text ul li {
    padding-left:  37.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-text ul li {
    padding-left:  1.9444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-text ul li {
    padding-left:  2.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .CommonText-text ul li {
    padding-left:  6.25vw;
  }
}
.CommonText-text ul li:before {
  content: '•';
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #6F7380;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 759px) {
  .CommonText-text ul li:before {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-text ul li:before {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-text ul li:before {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-text ul li:before {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .CommonText-text ol {
    padding-left:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-text ol {
    padding-left:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-text ol {
    padding-left:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .CommonText-text ol {
    padding-left:  7.8125vw;
  }
}
.CommonText-text ol[type="I"] li {
  list-style-type: upper-roman;
}
.CommonText-text ol li {
  list-style-type: decimal;
}
@media (min-width: 1920px) {
  .CommonText-text ol li {
    padding-left:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-text ol li {
    padding-left:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-text ol li {
    padding-left:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CommonText-text ol li {
    padding-left:  3.125vw;
  }
}
.CommonText-text p {
  margin: 1em 0;
}
.CommonText-text p:first-of-type {
  margin-top: 0;
}
.CommonText-text h2 {
  font-size: 1em;
  font-weight: 600;
  color: #111112;
}
.CommonText-text h2.text-title-upper {
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .CommonText-text h2.text-title-upper {
    margin-bottom:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-text h2.text-title-upper {
    margin-bottom:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-text h2.text-title-upper {
    margin-bottom:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CommonText-text h2.text-title-upper {
    margin-bottom:  3.125vw;
  }
}
@media (max-width: 759px) {
  .CommonText-text h2 {
    font-size: 5.625vw;
  }
}
.CommonText-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .CommonText-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .CommonText-link:hover,
.isNotDesktop .CommonText-link:active {
  text-decoration: underline;
}
.isDesktop .CommonText-link:hover:before,
.isNotDesktop .CommonText-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CommonText-link:hover:before,
  .isNotDesktop .CommonText-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CommonText-link {
    letter-spacing: 0;
  }
}
.CommonText-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .CommonText-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CommonText-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CommonText-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CommonText-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CommonText-link:visited {
  color: #111112;
}
.CommonText-link.light {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .CommonText-link.light {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-link.light {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-link.light {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CommonText-link.light {
    font-size: 18.66666667px;
  }
}
.isDesktop .CommonText-link.light:hover,
.isNotDesktop .CommonText-link.light:active {
  text-decoration: underline;
}
.isDesktop .CommonText-link.light:hover:before,
.isNotDesktop .CommonText-link.light:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CommonText-link.light:hover:before,
  .isNotDesktop .CommonText-link.light:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CommonText-link.light {
    letter-spacing: 0;
  }
}
.CommonText-link.light:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .CommonText-link.light:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CommonText-link.light:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CommonText-link.light:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-link.light:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-link.light:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CommonText-link.light:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CommonText-link.light:visited {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .CommonText-link,
  .CommonText-link.light {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonText-link,
  .CommonText-link.light {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonText-link,
  .CommonText-link.light {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CommonText-link,
  .CommonText-link.light {
    margin-top:  5vw;
  }
}
@media (max-width: 759px) {
  .CommonText-link,
  .CommonText-link.light {
    line-height: 1;
  }
}
