.PageProductFamily-grayWrapper {
  background-color: #F6F6F6;
}
@media (max-width: 759px) {
  .PageProductFamily-grayWrapper {
    padding: 9.375vw 6.25vw 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProductFamily-grayWrapper {
    padding: 2.82258065vw 11.29032258vw 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProductFamily-grayWrapper {
    padding: 2.43055556vw 9.72222222vw 0;
  }
}
@media (min-width: 1920px) {
  .PageProductFamily-grayWrapper {
    padding: 46.66666667px 186.66666667px 0;
  }
}
.PageProductFamily-productFinderWrapper {
  position: relative;
}
@media (min-width: 1920px) {
  .PageProductFamily-productFinderWrapper {
    padding-bottom:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProductFamily-productFinderWrapper {
    padding-bottom:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProductFamily-productFinderWrapper {
    padding-bottom:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PageProductFamily-productFinderWrapper {
    padding-bottom:  12.5vw;
  }
}
.PageProductFamily-productFinderBg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(45, 50, 52, 0.5)), color-stop(79%, #FFFFFF), to(#FFFFFF));
  background-image: linear-gradient(180deg, rgba(45, 50, 52, 0.5) 0%, #FFFFFF 79%, #FFFFFF 100%);
  z-index: -1;
}
@media (min-width: 1920px) {
  .PageProductFamily-productFinderBg {
    height:  1066.6666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageProductFamily-productFinderBg {
    height:  55.55555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageProductFamily-productFinderBg {
    height:  64.51612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageProductFamily-productFinderBg {
    height:  250vw;
  }
}
@media (max-width: 759px) {
  .PageProductFamily-productFinderBg {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(45, 50, 52, 0.5)), color-stop(30%, #FFFFFF), to(#FFFFFF));
    background-image: linear-gradient(180deg, rgba(45, 50, 52, 0.5) 0%, #FFFFFF 30%, #FFFFFF 100%);
  }
}
.brand--thorn .PageProductFamily-productFinderBg {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(173, 179, 188, 0.5)), color-stop(79%, #FFFFFF), to(#FFFFFF));
  background-image: linear-gradient(180deg, rgba(173, 179, 188, 0.5) 0%, #FFFFFF 79%, #FFFFFF 100%);
}
@media (max-width: 759px) {
  .brand--thorn .PageProductFamily-productFinderBg {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(173, 179, 188, 0.5)), color-stop(30%, #FFFFFF), to(#FFFFFF));
    background-image: linear-gradient(180deg, rgba(173, 179, 188, 0.5) 0%, #FFFFFF 30%, #FFFFFF 100%);
  }
}
