@media (min-width: 1920px) {
  .LinkListSmall {
    padding:  0 186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkListSmall {
    padding:  0vw 9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkListSmall {
    padding:  0vw 11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall {
    padding:  0vw 6.25vw;
  }
}
.LinkListSmall-titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #DEDEDE;
}
@media (min-width: 1920px) {
  .LinkListSmall-titleBlock {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkListSmall-titleBlock {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkListSmall-titleBlock {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall-titleBlock {
    padding-top:  3.125vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall-titleBlock {
    display: block;
  }
}
.LinkListSmall-title {
  color: #111112;
  text-transform: none;
}
@media (min-width: 1920px) {
  .LinkListSmall-linkList {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkListSmall-linkList {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkListSmall-linkList {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall-linkList {
    margin-top:  3.125vw;
  }
}
.LinkListSmall-link {
  color: #111112;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 1px solid #E7E7E7;
  width: 100%;
  display: block;
  position: relative;
}
.LinkListSmall-link.hide {
  display: none;
}
.LinkListSmall-link:visited {
  color: #111112;
}
@media (min-width: 1920px) {
  .LinkListSmall-link {
    padding:  20px 80px 20px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkListSmall-link {
    padding:  1.0416666666666667vw 4.166666666666667vw 1.0416666666666667vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkListSmall-link {
    padding:  1.2096774193548387vw 4.838709677419355vw 1.2096774193548387vw 0vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall-link {
    padding:  4.6875vw 18.75vw 4.6875vw 0vw;
  }
}
.isDesktop .LinkListSmall-link:hover {
  text-decoration: underline;
}
.isDesktop .LinkListSmall-link:hover .LinkListSmall-linkArrow:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.LinkListSmall-linkArrow {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  top: 50%;
}
@media (min-width: 1920px) {
  .LinkListSmall-linkArrow {
    right:  10.666666666666666px;
    margin-top:  -13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkListSmall-linkArrow {
    right:  0.5555555555555556vw;
    margin-top:  -0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkListSmall-linkArrow {
    right:  0.6451612903225806vw;
    margin-top:  -0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall-linkArrow {
    right:  2.5vw;
    margin-top:  0vw;
  }
}
.LinkListSmall-linkArrow:before {
  content: '';
  display: inline-block;
  position: relative;
  background: url('/static/img/arrow-dark.svg') no-repeat;
  z-index: 2;
  will-change: transform;
  -webkit-transform: translate3d(-20%, 0, 0);
          transform: translate3d(-20%, 0, 0);
}
.isDesktop .LinkListSmall-linkArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (min-width: 1920px) {
  .LinkListSmall-linkArrow:before {
    width:  34.666666666666664px;
    height:  24px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LinkListSmall-linkArrow:before {
    width:  1.8055555555555556vw;
    height:  1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LinkListSmall-linkArrow:before {
    width:  2.096774193548387vw;
    height:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) {
  .LinkListSmall-linkArrow:before {
    width:  8.125vw;
    height:  5.625vw;
    background-size:  8.125vw 5.625vw;
  }
}
