.ProductMasterpieceDescription {
  background-color: #FFFFFF;
  width: 100%;
  position: relative;
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription.js-is-sticky {
    right:  26.666666666666664px;
    bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription.js-is-sticky {
    right:  1.3888888888888888vw;
    bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription.js-is-sticky {
    right:  1.6129032258064515vw;
    bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription.js-is-sticky {
    right:  6.25vw;
    bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription.js-is-sticky {
    width: calc(50% - 26.66666667px);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription.js-is-sticky {
    width: calc(50% - 1.38888889vw);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription.js-is-sticky {
    width: calc(50% - 1.61290323vw);
  }
}
.ProductMasterpieceDescription.js-is-stuck {
  width: 50%;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription {
    width: 100%;
    height: auto;
    padding-bottom: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription {
    min-height: 40.32258065vw;
    padding-bottom: 6.4516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription {
    min-height: 34.72222222vw;
    padding-bottom: 5.55555556vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription {
    min-height: 666.66666667px;
    padding-bottom: 106.66666667px;
  }
}
.ProductMasterpieceDescription-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-head {
    width: 100%;
    height: auto;
    margin-top: 12.5vw;
    padding: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-head {
    min-height: 32.25806452vw;
    padding: 3.5483871vw 9.67741935vw 3.22580645vw;
    height: calc(100vh - 3.06451613vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-head {
    min-height: 27.77777778vw;
    padding: 3.05555556vw 8.33333333vw 2.77777778vw;
    height: calc(100vh - 2.63888889vw);
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-head {
    min-height: 533.33333333px;
    padding: 58.66666667px 160px 53.33333333px;
    height: calc(100vh - 50.66666667px);
  }
}
.ProductMasterpieceDescription-headTitleWrapper {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headTitleWrapper {
    padding: 7.8125vw 0 6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headTitle {
    font-size: 5.625vw;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headAuthor {
    color: #6F7380;
    margin-top: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-headAuthor {
    margin-top: 1.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-headAuthor {
    margin-top: 1.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-headAuthor {
    margin-top: 21.33333333px;
  }
}
.ProductMasterpieceDescription-headDescription {
  text-align: center;
  color: #6F7380;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headDescription {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-headDescription {
    margin-top: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-headDescription {
    margin-top: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-headDescription {
    margin-top: 18.66666667px;
  }
}
.ProductMasterpieceDescription-headButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headButtons {
    display: block;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 0 6.25vw;
    margin: 6.25vw 0 0;
    width: 100%;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-headButtons {
    width: 24.19354839vw;
    bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-headButtons {
    width: 20.83333333vw;
    bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-headButtons {
    width: 400px;
    bottom: 53.33333333px;
  }
}
.ProductMasterpieceDescription-headButton {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  text-transform: uppercase;
}
.ProductMasterpieceDescription-headButton:first-child {
  margin-right: auto;
}
.ProductMasterpieceDescription-headButton:last-child {
  margin-right: 0;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headButton {
    margin-bottom: 6.25vw;
  }
  .ProductMasterpieceDescription-headButton:last-of-type {
    margin-bottom: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-headButton {
    min-width: 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-headButton {
    min-width: 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-headButton {
    min-width: 186.66666667px;
  }
}
.ProductMasterpieceDescription-headSlider {
  display: none;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-headSlider {
    display: block;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-content {
    padding: 0 6.25vw 20.3125vw;
    margin-top: 13.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-content {
    padding: 0 9.67741935vw;
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-content {
    padding: 0 8.33333333vw;
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-content {
    padding: 0 160px;
    margin-top: 53.33333333px;
  }
}
.ProductMasterpieceDescription-section:first-of-type {
  margin-top: 0;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-section {
    margin-top: 13.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-section {
    margin-top: 5.64516129vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-section {
    margin-top: 4.86111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-section {
    margin-top: 93.33333333px;
  }
}
.ProductMasterpieceDescription-sectionDescription {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-sectionDescription {
    margin-top: 6.5625vw;
    line-height: 1.71428571;
    font-size: 4.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-sectionDescription {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-sectionDescription {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-sectionDescription {
    margin-top: 40px;
  }
}
@media (min-width: 1920px) {
  .ProductMasterpieceDescription-sectionImage {
    margin-top:  61.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductMasterpieceDescription-sectionImage {
    margin-top:  3.1944444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductMasterpieceDescription-sectionImage {
    margin-top:  3.7096774193548385vw;
  }
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-sectionImage {
    margin-top:  9.0625vw;
  }
}
.ProductMasterpieceDescription-sectionImage img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
}
.ProductMasterpieceDescription-slider {
  background-color: #E9EAEE;
  position: relative;
  display: none;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductMasterpieceDescription-slider {
    display: block;
  }
}
.ProductMasterpieceDescription-sliderImage {
  width: 100vw;
}
.ProductMasterpieceDescription-sliderButtonWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: -12.5vw;
  visibility: hidden;
}
.ProductMasterpieceDescription-sliderButtonWrapper--1 {
  visibility: visible;
}
.ProductMasterpieceDescription-slider .slick-slide {
  position: relative;
}
.ProductMasterpieceDescription-slider .slick-slide:first-child .ProductMasterpieceDescription-sliderImage {
  height: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ProductMasterpieceDescription-slider .slick-slide:first-child .ProductMasterpieceDescription-sliderImage img {
  height: 100%;
  margin: 0 auto;
}
.ProductMasterpieceDescription-slider .slick-dots {
  margin: 6.25vw auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 5.625vw;
  width: 100%;
}
.ProductMasterpieceDescription-slider .slick-dots li {
  width: 5.625vw;
  height: 1px;
  background-color: #C6C6C6;
  margin-left: 0.3125vw;
  margin-right: 0.3125vw;
}
.ProductMasterpieceDescription-slider .slick-dots li:last-of-type {
  margin-right: 0;
}
.ProductMasterpieceDescription-slider .slick-dots li:first-of-type {
  margin-left: 0;
}
.ProductMasterpieceDescription-slider .slick-dots li.slick-active {
  background-color: #111112;
  height: 4px;
}
.ProductMasterpieceDescription-slider .slick-dots button {
  text-indent: -9999px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  outline: none;
}
.ProductMasterpieceDescription-sliderArrowLeft,
.ProductMasterpieceDescription-sliderArrowRight {
  position: absolute;
  width: 6.25vw;
  height: 10.9375vw;
  top: 40%;
  cursor: pointer;
  z-index: 5;
  display: none;
}
.isDesktop .ProductMasterpieceDescription-sliderArrowLeft,
.isDesktop .ProductMasterpieceDescription-sliderArrowRight {
  display: block;
}
.isDesktop .ProductMasterpieceDescription-sliderArrowLeft:hover svg,
.isDesktop .ProductMasterpieceDescription-sliderArrowRight:hover svg,
.isNotDesktop .ProductMasterpieceDescription-sliderArrowLeft:active svg,
.isNotDesktop .ProductMasterpieceDescription-sliderArrowRight:active svg {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.ProductMasterpieceDescription-sliderArrowLeft svg,
.ProductMasterpieceDescription-sliderArrowRight svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.ProductMasterpieceDescription-sliderArrowLeft {
  left: 3.125vw;
}
.ProductMasterpieceDescription-sliderArrowRight {
  right: 3.125vw;
}
