.Trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Trigger-trigger {
  background-color: #FFFFFF;
  border: 1px solid #E7E7E7;
  position: relative;
  cursor: pointer;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .Trigger-trigger:hover {
  opacity: 0.7;
}
@media (min-width: 1920px) {
  .Trigger-trigger {
    width:  80px;
    height:  40px;
    border-radius:  20px;
    margin:  0 12px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Trigger-trigger {
    width:  4.166666666666667vw;
    height:  2.0833333333333335vw;
    border-radius:  1.0416666666666667vw;
    margin:  0vw 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Trigger-trigger {
    width:  4.838709677419355vw;
    height:  2.4193548387096775vw;
    border-radius:  1.2096774193548387vw;
    margin:  0vw 0.7258064516129032vw;
  }
}
@media (max-width: 759px) {
  .Trigger-trigger {
    width:  18.75vw;
    height:  9.375vw;
    border-radius:  4.6875vw;
    margin:  0vw 2.8125vw;
  }
}
.Trigger-trigger:before {
  content: '';
  position: absolute;
  background-color: #111112;
  opacity: 0.2;
  border: 1px solid #979797;
  top: 1px;
  -webkit-transition: 0.15s left, 0.15s opacity;
  transition: 0.15s left, 0.15s opacity;
  border-radius: 50%;
}
@media (min-width: 1920px) {
  .Trigger-trigger:before {
    width:  34.666666666666664px;
    height:  34.666666666666664px;
    left:  42.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Trigger-trigger:before {
    width:  1.8055555555555556vw;
    height:  1.8055555555555556vw;
    left:  2.2222222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Trigger-trigger:before {
    width:  2.096774193548387vw;
    height:  2.096774193548387vw;
    left:  2.5806451612903225vw;
  }
}
@media (max-width: 759px) {
  .Trigger-trigger:before {
    width:  8.125vw;
    height:  8.125vw;
    left:  10vw;
  }
}
.Trigger-trigger.active:before {
  left: 1px;
  right: auto;
  opacity: 1;
  border: 1px solid #111112;
  background-color: #111112;
}
.Trigger-label {
  text-transform: uppercase;
}
