@media (max-width: 759px) {
  .RapidluxCalculator {
    margin-top: 14.0625vw;
  }
}
.RapidluxCalculator-arrowBack {
  display: none;
}
@media (max-width: 759px) {
  .RapidluxCalculator-arrowBack {
    display: block;
    position: absolute;
    left: -0.625vw;
    width: 5.3125vw;
    height: 7.1875vw;
    margin-left: 7.8125vw;
    margin-top: 7.1875vw;
  }
  .RapidluxCalculator-arrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
