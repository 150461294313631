.UpcomingEvent {
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.15s color, 0.15s background-color;
  transition: 0.15s color, 0.15s background-color;
}
.isDesktop .UpcomingEvent:hover {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.4);
}
.isDesktop .UpcomingEvent:hover .UpcomingEvent-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isDesktop .UpcomingEvent:hover .UpcomingEvent-link span {
  text-decoration: underline;
}
.isDesktop .UpcomingEvent.isUpcomingEventDark:hover {
  background-color: #E9EAEE;
}
@media (min-width: 1920px) {
  .UpcomingEvent {
    padding:  26.666666666666664px;
    -webkit-box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent {
    padding:  1.3888888888888888vw;
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent {
    padding:  1.6129032258064515vw;
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .UpcomingEvent {
    padding:  6.25vw;
    -webkit-box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
            box-shadow:  0vw 3.125vw 12.5vw 3.125vw;
  }
}
.UpcomingEvent.isUpcomingEventDark {
  background: #F6F6F6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.UpcomingEvent-url {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.UpcomingEvent-title {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .UpcomingEvent-title {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent-title {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent-title {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .UpcomingEvent-title {
    margin-top:  1.5625vw;
  }
}
.isUpcomingEventDark .UpcomingEvent-title {
  color: #111112;
}
.UpcomingEvent-description {
  color: #FFFFFF;
  text-transform: uppercase;
}
.isUpcomingEventDark .UpcomingEvent-description {
  color: #111112;
}
.UpcomingEvent-type {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.15s background-color, 0.15s color;
  transition: 0.15s background-color, 0.15s color;
  cursor: pointer;
  z-index: 2;
}
.isUpcomingEventDark .UpcomingEvent-type {
  border: 1px solid #111112;
  color: #111112;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .UpcomingEvent-type {
    padding:  4px 20px 1.3333333333333333px;
    border-radius:  26.666666666666664px;
    margin-top:  17.333333333333332px;
    height:  26.666666666666664px;
    margin-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent-type {
    padding:  0.20833333333333334vw 1.0416666666666667vw 0.06944444444444445vw;
    border-radius:  1.3888888888888888vw;
    margin-top:  0.9027777777777778vw;
    height:  1.3888888888888888vw;
    margin-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent-type {
    padding:  0.24193548387096775vw 1.2096774193548387vw 0.08064516129032258vw;
    border-radius:  1.6129032258064515vw;
    margin-top:  1.0483870967741935vw;
    height:  1.6129032258064515vw;
    margin-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .UpcomingEvent-type {
    padding:  0.9375vw 4.6875vw 0.3125vw;
    border-radius:  6.25vw;
    margin-top:  4.0625vw;
    height:  6.25vw;
    margin-bottom:  6.25vw;
  }
}
.isDesktop .UpcomingEvent-type:hover {
  background-color: #FFFFFF;
  color: #111112;
}
.isDesktop .isUpcomingEventDark .UpcomingEvent-type:hover {
  background-color: #111112;
  color: #FFFFFF;
}
.UpcomingEvent-type_tag {
  background-color: #FFFFFF;
  color: #111112;
  cursor: auto;
  margin: 0;
}
.isUpcomingEventDark .UpcomingEvent-type_tag {
  color: #FFFFFF;
  background-color: #111112;
}
.UpcomingEvent-bottom {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.UpcomingEvent-link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
}
.UpcomingEvent-link:visited {
  color: #FFFFFF;
}
.isUpcomingEventDark .UpcomingEvent-link {
  color: #111112;
}
.isUpcomingEventDark .UpcomingEvent-link:visited {
  color: #111112;
}
.UpcomingEvent-linkArrow {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1920px) {
  .UpcomingEvent-linkArrow {
    width:  24px;
    height:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent-linkArrow {
    width:  1.25vw;
    height:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent-linkArrow {
    width:  1.4516129032258065vw;
    height:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .UpcomingEvent-linkArrow {
    width:  5.625vw;
    height:  3.75vw;
  }
}
.UpcomingEvent-linkArrow svg {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  stroke: #FFFFFF;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.isUpcomingEventDark .UpcomingEvent-linkArrow svg {
  stroke: #111112;
}
@media (min-width: 1920px) {
  .UpcomingEvent-linkArrow svg {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent-linkArrow svg {
    -webkit-transform: translate3d(-0.20833333vw, 0, 0);
            transform: translate3d(-0.20833333vw, 0, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent-linkArrow svg {
    -webkit-transform: translate3d(-0.24193548vw, 0, 0);
            transform: translate3d(-0.24193548vw, 0, 0);
  }
}
@media (max-width: 759px) {
  .UpcomingEvent-linkArrow svg {
    -webkit-transform: translate3d(-0.9375vw, 0, 0);
            transform: translate3d(-0.9375vw, 0, 0);
  }
}
.UpcomingEvent-link span {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  line-height: 1;
}
@media (max-width: 759px) {
  .UpcomingEvent-link span {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent-link span {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent-link span {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .UpcomingEvent-link span {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .UpcomingEvent-link span {
    line-height: 1;
  }
}
@media (min-width: 1920px) {
  .UpcomingEvent-link span {
    padding-top:  4px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpcomingEvent-link span {
    padding-top:  0.20833333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpcomingEvent-link span {
    padding-top:  0.24193548387096775vw;
  }
}
@media (max-width: 759px) {
  .UpcomingEvent-link span {
    padding-top:  0.9375vw;
  }
}
