.CampusCenterPopupHead {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 1920px) {
  .CampusCenterPopupHead {
    height:  853.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopupHead {
    height:  44.44444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopupHead {
    height:  51.61290322580645vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopupHead {
    height:  100vw;
  }
}
.CampusCenterPopupHead-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.CampusCenterPopupHead-background img {
  height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.CampusCenterPopupHead-textWrapper {
  color: #FFFFFF;
  z-index: 1;
  margin-top: auto;
}
@media (min-width: 1920px) {
  .CampusCenterPopupHead-textWrapper {
    padding:  0 186.66666666666666px 80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopupHead-textWrapper {
    padding:  0vw 9.722222222222223vw 4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopupHead-textWrapper {
    padding:  0vw 11.29032258064516vw 4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopupHead-textWrapper {
    padding:  0vw 6.25vw 6.25vw;
  }
}
.CampusCenterPopupHead-title {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media (min-width: 1920px) {
  .CampusCenterPopupHead-title {
    padding-top:  26.666666666666664px;
    margin-top:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopupHead-title {
    padding-top:  1.3888888888888888vw;
    margin-top:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopupHead-title {
    padding-top:  1.6129032258064515vw;
    margin-top:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopupHead-title {
    margin-top:  3.125vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopupHead-title {
    border-top: none;
  }
}
.CampusCenterPopupHead-location {
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .CampusCenterPopupHead-location {
    line-height: 1;
  }
}
@media (min-width: 1920px) {
  .CampusCenterPopupHead-description {
    margin-top:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CampusCenterPopupHead-description {
    margin-top:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CampusCenterPopupHead-description {
    margin-top:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .CampusCenterPopupHead-description {
    margin-top:  3.75vw;
  }
}
