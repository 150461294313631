.ProductFamilyItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #E7E7E7;
  -webkit-transition: 0.15s background ease-out;
  transition: 0.15s background ease-out;
}
.ProductFamilyItem.hide {
  display: none;
}
.ProductFamilyItem:last-child {
  border-bottom: 1px solid transparent;
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ProductFamilyItem {
    padding:  20px 0 17.333333333333332px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem {
    padding:  1.0416666666666667vw 0vw 0.9027777777777778vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem {
    padding:  1.2096774193548387vw 0vw 1.0483870967741935vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyItem {
    padding:  4.6875vw 0vw 4.0625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem {
    min-height: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem {
    min-height: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem {
    min-height: 80px;
  }
}
.ProductFamilyItem:first-child {
  border-top: 1px solid #E7E7E7;
}
.isDesktop .ProductFamilyItem:hover {
  border-bottom: 1px solid #111112;
}
.isDesktop .ProductFamilyItem:hover .ProductFamilyItem-infoItem {
  color: #111112;
}
.isDesktop .ProductFamilyItem:hover .ProductFamilyItem-infoItemArrow {
  text-decoration: underline;
}
.isDesktop .ProductFamilyItem:hover .ProductFamilyItem-infoItemArrow:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyItem:hover .ProductFamilyItem-infoItemArrow:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.isDesktop .ProductFamilyItem:hover .ProductFamilyItem-moreIcon {
  opacity: 1;
  pointer-events: all;
}
.ProductFamilyItem-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
}
.ProductFamilyItem-wrapper {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .ProductFamilyItem-wrapper {
    display: none;
  }
}
.ProductFamilyItem-tip {
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: 100%;
  opacity: 0;
  z-index: 11;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
@media (max-width: 759px) {
  .ProductFamilyItem-tip {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-tip {
    width: 12.90322581vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-tip {
    width: 11.11111111vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-tip {
    width: 213.33333333px;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-tip {
    left:  413.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-tip {
    left:  21.52777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-tip {
    left:  25vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-tip {
    left:  96.875vw;
  }
}
.ProductFamilyItem-tipWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.ProductFamilyItem-tipIcon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 759px) {
  .ProductFamilyItem-tipIcon {
    width: 5vw;
    height: 5vw;
    margin: 0 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-tipIcon {
    width: 1.29032258vw;
    height: 1.29032258vw;
    margin: 0 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-tipIcon {
    width: 1.11111111vw;
    height: 1.11111111vw;
    margin: 0 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-tipIcon {
    width: 21.33333333px;
    height: 21.33333333px;
    margin: 0 16px;
  }
}
.ProductFamilyItem-tipIcon svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  fill: #A6A8B3;
}
.ProductFamilyItem-tipLetter {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  text-transform: uppercase;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #111112;
  border-radius: 2px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  color: #FFFFFF;
  line-height: 0.83333333;
}
@media (max-width: 759px) {
  .ProductFamilyItem-tipLetter {
    font-size: 3.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-tipLetter {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-tipLetter {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-tipLetter {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-tipLetter {
    width: 6.25vw;
    height: 6.25vw;
    margin-right: 1.25vw;
    padding-top: 0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-tipLetter {
    width: 1.29032258vw;
    height: 1.29032258vw;
    margin-right: 0.32258065vw;
    padding-top: 0.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-tipLetter {
    width: 1.38888889vw;
    height: 1.38888889vw;
    margin-right: 0.27777778vw;
    padding-top: 0.13888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-tipLetter {
    width: 26.66666667px;
    height: 26.66666667px;
    margin-right: 5.33333333px;
    padding-top: 2.66666667px;
  }
}
.ProductFamilyItem-tipLetter:last-child {
  margin-right: 0;
}
.isDesktop .ProductFamilyItem-tipLetter:hover,
.isNotDesktop .ProductFamilyItem-tipLetter:active {
  background-color: #6F7380;
}
.ProductFamilyItem-tipLetter a {
  text-decoration: none;
  color: #FFFFFF;
}
.ProductFamilyItem-infoItem {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  position: relative;
  color: #6F7380;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
  -webkit-box-flex: 0;
      -ms-flex: 0 0;
          flex: 0 0;
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem {
    -ms-flex-preferred-size:  133.33333333333331px;
        flex-basis:  133.33333333333331px;
    padding-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem {
    -ms-flex-preferred-size:  6.944444444444445vw;
        flex-basis:  6.944444444444445vw;
    padding-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem {
    -ms-flex-preferred-size:  8.064516129032258vw;
        flex-basis:  8.064516129032258vw;
    padding-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem {
    -ms-flex-preferred-size:  31.25vw;
        flex-basis:  31.25vw;
    padding-right:  6.25vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem {
    line-height: 1.55555556;
    -webkit-box-flex: 0;
        -ms-flex: 0 0;
            flex: 0 0;
    padding: 0;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ProductFamilyItem-infoItem:first-child {
    -ms-flex-preferred-size:  246.66666666666666px;
        flex-basis:  246.66666666666666px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem:first-child {
    -ms-flex-preferred-size:  12.847222222222223vw;
        flex-basis:  12.847222222222223vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem:first-child {
    -ms-flex-preferred-size:  14.919354838709676vw;
        flex-basis:  14.919354838709676vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyItem-infoItem:first-child {
    -ms-flex-preferred-size:  57.8125vw;
        flex-basis:  57.8125vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .ProductFamilyItem-infoItem:last-child {
    -ms-flex-preferred-size:  74.66666666666666px;
        flex-basis:  74.66666666666666px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem:last-child {
    -ms-flex-preferred-size:  3.8888888888888893vw;
        flex-basis:  3.8888888888888893vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem:last-child {
    -ms-flex-preferred-size:  4.516129032258064vw;
        flex-basis:  4.516129032258064vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .ProductFamilyItem-infoItem:last-child {
    -ms-flex-preferred-size:  17.5vw;
        flex-basis:  17.5vw;
  }
}
.ProductFamilyItem-infoItem--title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
  -webkit-box-flex: 0;
      -ms-flex: 0 0;
          flex: 0 0;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--title {
    -ms-flex-preferred-size:  400px;
        flex-basis:  400px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--title {
    -ms-flex-preferred-size:  20.833333333333336vw;
        flex-basis:  20.833333333333336vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--title {
    -ms-flex-preferred-size:  24.193548387096772vw;
        flex-basis:  24.193548387096772vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--title {
    -ms-flex-preferred-size:  93.75vw;
        flex-basis:  93.75vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--title {
    display: block;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--category {
    width: 8.87096774vw;
    margin-right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--category {
    width: 7.63888889vw;
    margin-right: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--category {
    width: 146.66666667px;
    margin-right: 53.33333333px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--lm {
    margin-right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--lm {
    margin-right: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--lm {
    margin-right: 53.33333333px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--temp {
    margin-right: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--temp {
    margin-right: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--temp {
    margin-right: 26.66666667px;
  }
}
.ProductFamilyItem-infoItem--price {
  margin-right: 0;
  display: block;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--price {
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--price {
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--price {
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--price {
    margin-right:  6.25vw;
    width:  15.625vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItemAvailability {
    font-size:  12px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItemAvailability {
    font-size:  0.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItemAvailability {
    font-size:  0.7258064516129032vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemAvailability {
    font-size:  2.8125vw;
  }
}
.ProductFamilyItem-infoItem--available {
  border: 1px solid #111112;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  text-transform: uppercase;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  pointer-events: none;
  letter-spacing: normal;
  font-weight: normal;
  position: absolute;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-right: 0;
  width: auto;
  border-color: #111112;
  color: #111112;
  text-align: center;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--available {
    border-radius:  13.333333333333332px;
    font-size:  12px;
    max-width:  200px;
    padding:  6.666666666666666px 13.333333333333332px 5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--available {
    border-radius:  0.6944444444444444vw;
    font-size:  0.625vw;
    max-width:  10.416666666666668vw;
    padding:  0.3472222222222222vw 0.6944444444444444vw 0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--available {
    border-radius:  0.8064516129032258vw;
    font-size:  0.7258064516129032vw;
    max-width:  12.096774193548386vw;
    padding:  0.4032258064516129vw 0.8064516129032258vw 0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--available {
    border-radius:  3.125vw;
    font-size:  2.8125vw;
    max-width:  46.875vw;
    padding:  1.5625vw 3.125vw 1.25vw;
  }
}
.brand--thorn .ProductFamilyItem-infoItem--available {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.brand--thorn .ProductFamilyItem-infoItem--available {
  color: #111112;
  border-color: #111112;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--available {
    right:  186.66666666666666px;
    max-width:  200px;
    padding:  2.6666666666666665px 17.333333333333332px 1.3333333333333333px 17.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--available {
    right:  9.722222222222223vw;
    max-width:  10.416666666666668vw;
    padding:  0.1388888888888889vw 0.9027777777777778vw 0.06944444444444445vw 0.9027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--available {
    right:  11.29032258064516vw;
    max-width:  12.096774193548386vw;
    padding:  0.16129032258064516vw 1.0483870967741935vw 0.08064516129032258vw 1.0483870967741935vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--available {
    right:  43.75vw;
    max-width:  46.875vw;
    padding:  0.625vw 4.0625vw 0.3125vw 4.0625vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--available {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItem--available {
    width: auto;
    height: auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItem--available {
    width: auto;
    height: auto;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItem--available {
    width: auto;
    height: auto;
  }
}
.ProductFamilyItem-infoItem--availableMobile {
  display: none;
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItem--availableMobile {
    display: block;
  }
}
.ProductFamilyItem-infoItemCri {
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.ProductFamilyItem-infoItemPrice {
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemPrice {
    position: relative;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
    left: auto;
    top: auto;
    color: #6F7380;
  }
}
.ProductFamilyItem-infoItemArrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 3px;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: hidden;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItemArrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItemArrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItemArrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .ProductFamilyItem-infoItemArrow:hover,
.isNotDesktop .ProductFamilyItem-infoItemArrow:active {
  text-decoration: underline;
}
.isDesktop .ProductFamilyItem-infoItemArrow:hover:before,
.isNotDesktop .ProductFamilyItem-infoItemArrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .ProductFamilyItem-infoItemArrow:hover:before,
  .isNotDesktop .ProductFamilyItem-infoItemArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemArrow {
    letter-spacing: 0;
  }
}
.ProductFamilyItem-infoItemArrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .ProductFamilyItem-infoItemArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItemArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItemArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItemArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemArrow {
    display: none;
  }
}
.ProductFamilyItem-infoItemArrow_mobile {
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (min-width: 1920px) {
  .ProductFamilyItem-infoItemArrow_mobile {
    width:  10.666666666666666px;
    height:  18.666666666666664px;
    right:  -21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-infoItemArrow_mobile {
    width:  0.5555555555555556vw;
    height:  0.9722222222222223vw;
    right:  -1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-infoItemArrow_mobile {
    width:  0.6451612903225806vw;
    height:  1.129032258064516vw;
    right:  -1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-infoItemArrow_mobile {
    width:  2.5vw;
    height:  4.375vw;
    right:  -5vw;
  }
}
.ProductFamilyItem-infoItemArrow_mobile svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ProductFamilyItem-wrapper_mobile {
  display: none;
  position: relative;
  width: 100%;
}
@media (max-width: 759px) {
  .ProductFamilyItem-wrapper_mobile {
    display: block;
  }
}
.ProductFamilyItem-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-line {
    margin-top:  10.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-line {
    margin-top:  0.5555555555555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-line {
    margin-top:  0.6451612903225806vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-line {
    margin-top:  2.5vw;
  }
}
.ProductFamilyItem-line:first-child {
  margin-top: 0;
}
.ProductFamilyItem-moreIcon {
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  opacity: 0;
  pointer-events: none;
  z-index: 11;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-moreIcon {
    right:  80px;
    width:  26.666666666666664px;
    height:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-moreIcon {
    right:  4.166666666666667vw;
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-moreIcon {
    right:  4.838709677419355vw;
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-moreIcon {
    right:  18.75vw;
    width:  6.25vw;
    height:  6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-moreIcon {
    opacity: 1;
    pointer-events: all;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-moreIcon {
    display: none;
  }
}
.ProductFamilyItem-moreIcon--default,
.ProductFamilyItem-moreIcon--hovered {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.ProductFamilyItem-moreIcon--default svg,
.ProductFamilyItem-moreIcon--hovered svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ProductFamilyItem-moreIcon--hovered {
  opacity: 0;
}
.isDesktop .ProductFamilyItem-moreIcon:hover .ProductFamilyItem-moreIcon--hovered,
.ProductFamilyItem-moreIcon.hovered .ProductFamilyItem-moreIcon--hovered {
  opacity: 1;
}
.isDesktop .ProductFamilyItem-moreIcon:hover .ProductFamilyItem-moreIcon--default,
.ProductFamilyItem-moreIcon.hovered .ProductFamilyItem-moreIcon--default {
  opacity: 0;
}
.isDesktop .ProductFamilyItem-moreIcon:hover + .ProductFamilyItem-dropdown,
.ProductFamilyItem-moreIcon.hovered + .ProductFamilyItem-dropdown {
  opacity: 1;
  pointer-events: all;
}
.ProductFamilyItem-dropdown {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  z-index: 20;
}
.isDesktop .ProductFamilyItem-dropdown:hover {
  opacity: 1;
  pointer-events: all;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdown {
    right:  0;
    top:  26.666666666666664px;
    padding:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdown {
    right:  0vw;
    top:  1.3888888888888888vw;
    padding:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdown {
    right:  0vw;
    top:  1.6129032258064515vw;
    padding:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdown {
    right:  0vw;
    top:  6.25vw;
    padding:  6.25vw;
  }
}
.ProductFamilyItem-dropdownWrapper {
  background: #111112;
  width: 100%;
  height: 100%;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownWrapper {
    padding-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownWrapper {
    padding-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownWrapper {
    padding-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownWrapper {
    padding-bottom:  6.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownFiles {
    margin-top:  26.666666666666664px;
    padding:  0 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownFiles {
    margin-top:  1.3888888888888888vw;
    padding:  0vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownFiles {
    margin-top:  1.6129032258064515vw;
    padding:  0vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownFiles {
    margin-top:  6.25vw;
    padding:  0vw 6.25vw;
  }
}
.ProductFamilyItem-dropdownFile {
  border: 1px solid #6F7380;
  border-radius: 2px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
}
.isDesktop .ProductFamilyItem-dropdownFile:hover {
  background: #FFFFFF;
  border-color: #111112;
}
.isDesktop .ProductFamilyItem-dropdownFile:hover .ProductFamilyItem-dropdownFileTitle {
  color: #111112;
}
.isDesktop .ProductFamilyItem-dropdownFile:hover .ProductFamilyItem-dropdownFileIcon svg {
  fill: #111112;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownFile {
    padding:  6.666666666666666px 13.333333333333332px;
    margin-top:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownFile {
    padding:  0.3472222222222222vw 0.6944444444444444vw;
    margin-top:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownFile {
    padding:  0.4032258064516129vw 0.8064516129032258vw;
    margin-top:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownFile {
    padding:  1.5625vw 3.125vw;
    margin-top:  3.125vw;
  }
}
.ProductFamilyItem-dropdownFile:first-of-type {
  margin-top: 0;
}
.ProductFamilyItem-dropdownFileIcon {
  position: relative;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownFileIcon {
    width:  26.666666666666664px;
    height:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownFileIcon {
    width:  1.3888888888888888vw;
    height:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownFileIcon {
    width:  1.6129032258064515vw;
    height:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownFileIcon {
    width:  6.25vw;
    height:  6.25vw;
  }
}
.ProductFamilyItem-dropdownFileIcon svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  fill: #6F7380;
  -webkit-transition: 0.15s fill;
  transition: 0.15s fill;
}
.ProductFamilyItem-dropdownFileLink {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.ProductFamilyItem-dropdownFileTitle {
  color: #FFFFFF;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownFileTitle {
    margin-left:  13.333333333333332px;
    padding-top:  4px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownFileTitle {
    margin-left:  0.6944444444444444vw;
    padding-top:  0.20833333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownFileTitle {
    margin-left:  0.8064516129032258vw;
    padding-top:  0.24193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownFileTitle {
    margin-left:  3.125vw;
    padding-top:  0.9375vw;
  }
}
.ProductFamilyItem-dropdownLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #6F7380;
  width: 100%;
  position: relative;
}
.isDesktop .ProductFamilyItem-dropdownLink:hover .ProductFamilyItem-dropdownLinkTitle {
  opacity: 0.7;
}
.isDesktop .ProductFamilyItem-dropdownLink:hover .ProductFamilyItem-dropdownLinkIcon svg {
  fill: rgba(255, 255, 255, 0.7);
}
.ProductFamilyItem-dropdownLinkIcon {
  position: relative;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownLinkIcon {
    width:  16px;
    height:  16px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownLinkIcon {
    width:  0.8333333333333334vw;
    height:  0.8333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownLinkIcon {
    width:  0.967741935483871vw;
    height:  0.967741935483871vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownLinkIcon {
    width:  3.75vw;
    height:  3.75vw;
  }
}
.ProductFamilyItem-dropdownLinkIcon svg {
  height: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  fill: #FFFFFF;
  -webkit-transition: 0.15s fill;
  transition: 0.15s fill;
}
.ProductFamilyItem-dropdownLinkUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}
.ProductFamilyItem-dropdownLinkTitle {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownLinkTitle {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownLinkTitle {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownLinkTitle {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownLinkTitle {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownLinkTitle {
    margin-left:  13.333333333333332px;
    padding-top:  5.333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownLinkTitle {
    margin-left:  0.6944444444444444vw;
    padding-top:  0.2777777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownLinkTitle {
    margin-left:  0.8064516129032258vw;
    padding-top:  0.3225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownLinkTitle {
    margin-left:  3.125vw;
    padding-top:  1.25vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownLink {
    padding:  20px 29.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownLink {
    padding:  1.0416666666666667vw 1.527777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownLink {
    padding:  1.2096774193548387vw 1.7741935483870968vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownLink {
    padding:  4.6875vw 6.875vw;
  }
}
.ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkIcon {
  -webkit-animation: 0.5s rotation linear infinite;
          animation: 0.5s rotation linear infinite;
}
@media (min-width: 1920px) {
  .ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkIcon {
    width:  21.333333333333332px;
    height:  21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkIcon {
    width:  1.1111111111111112vw;
    height:  1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkIcon {
    width:  1.2903225806451613vw;
    height:  1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkIcon {
    width:  5vw;
    height:  5vw;
  }
}
.ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkIcon img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.ProductFamilyItem-dropdownLink_preparing .ProductFamilyItem-dropdownLinkTitle {
  color: #6F7380;
}
