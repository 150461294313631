.SearchResultInspirationItem {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  will-change: transform, color;
  -webkit-transition: 0.25s color ease;
  transition: 0.25s color ease;
  color: rgba(0, 0, 0, 0.25);
}
@media (min-width: 1920px) {
  .SearchResultInspirationItem {
    -webkit-box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
            box-shadow:  0px 13.333333333333332px 53.33333333333333px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultInspirationItem {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
            box-shadow:  0vw 0.6944444444444444vw 2.7777777777777777vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultInspirationItem {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
            box-shadow:  0vw 0.8064516129032258vw 3.225806451612903vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultInspirationItem {
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.isDesktop .SearchResultInspirationItem:hover {
  color: rgba(0, 0, 0, 0.6);
}
.isDesktop .SearchResultInspirationItem-image {
  -webkit-transition: 0.25s -webkit-transform ease;
  transition: 0.25s -webkit-transform ease;
  transition: 0.25s transform ease;
  transition: 0.25s transform ease, 0.25s -webkit-transform ease;
}
.isDesktop .SearchResultInspirationItem:hover .SearchResultInspirationItem-image {
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
          transform: scale(1.05) translate3d(0, 0, 0);
}
.isDesktop .SearchResultInspirationItem:hover:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
.SearchResultInspirationItem:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isDesktop .SearchResultInspirationItem:active:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s transform;
  transition: 0.15s opacity, 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .SearchResultInspirationItem:active:before {
    -webkit-transform: translate3d(0.64516129vw, 0, 0) !important;
            transform: translate3d(0.64516129vw, 0, 0) !important;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .SearchResultInspirationItem:active:before {
    -webkit-transform: translate3d(0.55555556vw, 0, 0) !important;
            transform: translate3d(0.55555556vw, 0, 0) !important;
  }
}
@media (min-width: 1920px) {
  .isDesktop .SearchResultInspirationItem:active:before {
    -webkit-transform: translate3d(10.66666667px, 0, 0) !important;
            transform: translate3d(10.66666667px, 0, 0) !important;
  }
}
.SearchResultInspirationItem:active:after {
  opacity: 0.5;
}
.SearchResultInspirationItem:before {
  content: '';
  display: block;
  position: absolute;
  background: center / cover url('/static/img/arrow.svg') no-repeat;
  opacity: 0;
  z-index: 2;
  will-change: transform;
}
.isDesktop .SearchResultInspirationItem:before {
  -webkit-transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s -webkit-transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s;
  transition: 0.15s opacity, 0s transform 0.15s, 0s -webkit-transform 0.15s;
}
@media (max-width: 759px) {
  .SearchResultInspirationItem:before {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultInspirationItem:before {
    width: 2.09677419vw;
    height: 1.4516129vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultInspirationItem:before {
    width: 1.80555556vw;
    height: 1.25vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .SearchResultInspirationItem:before {
    width: 34.66666667px;
    height: 24px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.SearchResultInspirationItem:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: #111112;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.isDesktop .SearchResultInspirationItem:after {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .SearchResultInspirationItem {
    margin-bottom:  50.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultInspirationItem {
    margin-bottom:  2.638888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultInspirationItem {
    margin-bottom:  3.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .SearchResultInspirationItem {
    margin-bottom:  6.25vw;
  }
}
.SearchResultInspirationItem a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
@media (min-width: 1920px) {
  .SearchResultInspirationItem:before {
    bottom:  41.33333333333333px;
    right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultInspirationItem:before {
    bottom:  2.1527777777777777vw;
    right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultInspirationItem:before {
    bottom:  2.5vw;
    right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultInspirationItem:before {
    bottom:  9.6875vw;
    right:  6.25vw;
  }
}
.SearchResultInspirationItem:last-of-type {
  margin-bottom: 0;
}
.SearchResultInspirationItem-image {
  width: 100%;
  padding-bottom: 58%;
  position: relative;
  background: #000000;
  cursor: pointer;
  overflow: hidden;
}
.isDesktop .SearchResultInspirationItem-image:hover img,
.isNotDesktop .SearchResultInspirationItem-image:active img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.SearchResultInspirationItem-image img {
  top: 0;
  left: 0;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  position: absolute;
  width: 100%;
  -webkit-transition: 0.15s -webkit-transform linear;
  transition: 0.15s -webkit-transform linear;
  transition: 0.15s transform linear;
  transition: 0.15s transform linear, 0.15s -webkit-transform linear;
}
.SearchResultInspirationItem-text {
  position: absolute;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .SearchResultInspirationItem-text {
    bottom:  26.666666666666664px;
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultInspirationItem-text {
    bottom:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultInspirationItem-text {
    bottom:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .SearchResultInspirationItem-text {
    bottom:  6.25vw;
    left:  6.25vw;
  }
}
.SearchResultInspirationItem-title {
  color: #FFFFFF;
  letter-spacing: normal;
  pointer-events: none;
}
@media (max-width: 759px) {
  .SearchResultInspirationItem-title {
    font-size: 3.4375vw;
  }
}
.SearchResultInspirationItem-subtitle {
  color: #FFFFFF;
  letter-spacing: normal;
  text-transform: none;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .SearchResultInspirationItem-subtitle {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .SearchResultInspirationItem-subtitle {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .SearchResultInspirationItem-subtitle {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .SearchResultInspirationItem-subtitle {
    margin-top:  1.5625vw;
  }
}
@media (max-width: 759px) {
  .SearchResultInspirationItem-subtitle {
    font-size: 2.8125vw;
    line-height: normal;
    margin-top: 3.125vw;
  }
}
