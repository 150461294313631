@media (min-width: 1920px) {
  .CareersWhyZumtobel {
    padding:  0 186.66666666666666px;
    margin-top:  466.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersWhyZumtobel {
    padding:  0vw 9.722222222222223vw;
    margin-top:  24.305555555555557vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersWhyZumtobel {
    padding:  0vw 11.29032258064516vw;
    margin-top:  28.225806451612904vw;
  }
}
@media (max-width: 759px) {
  .CareersWhyZumtobel {
    padding:  0vw 6.25vw;
    margin-top:  31.25vw;
  }
}
.CareersWhyZumtobel-wrapper {
  border-top: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .CareersWhyZumtobel-wrapper {
    padding-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersWhyZumtobel-wrapper {
    padding-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersWhyZumtobel-wrapper {
    padding-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CareersWhyZumtobel-wrapper {
    padding-top:  4.6875vw;
  }
}
.CareersWhyZumtobel-titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .CareersWhyZumtobel-titleBlock {
    display: block;
  }
}
.CareersWhyZumtobel-title {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
.CareersWhyZumtobel-description {
  color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
}
@media (max-width: 759px) {
  .CareersWhyZumtobel-description {
    margin-top: 6.25vw;
  }
}
.CareersWhyZumtobel-gallery {
  width: 100%;
}
@media (min-width: 1920px) {
  .CareersWhyZumtobel-gallery {
    margin-top:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersWhyZumtobel-gallery {
    margin-top:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersWhyZumtobel-gallery {
    margin-top:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CareersWhyZumtobel-gallery {
    margin-top:  9.375vw;
  }
}
@media (min-width: 1920px) {
  .CareersWhyZumtobel-advantages {
    margin-top:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CareersWhyZumtobel-advantages {
    margin-top:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CareersWhyZumtobel-advantages {
    margin-top:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .CareersWhyZumtobel-advantages {
    margin-top:  9.375vw;
  }
}
.CareersWhyZumtobel .CommonGallery-descriptionItem {
  color: #FFFFFF;
}
.CareersWhyZumtobel .CommonGallery-descriptionWrapper {
  padding-left: 0;
}
.CareersWhyZumtobel .CommonGallery-itemsNavItem:after {
  background: rgba(255, 255, 255, 0.2);
}
.isDesktop .CareersWhyZumtobel .CommonGallery-itemsNavItem:hover:after,
.isNotDesktop .CareersWhyZumtobel .CommonGallery-itemsNavItem:active:after {
  height: 4px;
  background: #FFFFFF;
}
.CareersWhyZumtobel .CommonGallery-itemsNavItem.active.active {
  pointer-events: none;
}
.CareersWhyZumtobel .CommonGallery-itemsNavItem.active.active:after {
  height: 4px;
  background: #FFFFFF;
}
