.LayoutServiceVideo {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .LayoutServiceVideo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.LayoutServiceVideo-titleBlock {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          flex: 0 0 35%;
}
@media (max-width: 759px) {
  .LayoutServiceVideo-titleBlock {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutServiceVideo-titleBlock {
    margin-bottom: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutServiceVideo-titleBlock {
    margin-bottom: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutServiceVideo-titleBlock {
    margin-bottom: 53.33333333px;
  }
}
.LayoutServiceVideo-title {
  color: #111112;
}
.LayoutServiceVideo-contentBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 65%;
          flex: 0 0 65%;
}
@media (max-width: 759px) {
  .LayoutServiceVideo-contentBlock {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 6.25vw;
  }
}
.LayoutServiceVideo-video {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 47%;
          flex: 0 0 47%;
  cursor: pointer;
}
.LayoutServiceVideo-video:only-of-type {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
@media (max-width: 759px) {
  .LayoutServiceVideo-video {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    width: 100%;
  }
}
@media (max-width: 759px) {
  .isDesktop .LayoutServiceVideo-video:hover .LayoutServiceVideo-videoCover,
  .isMobile .LayoutServiceVideo-video:active .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
            box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .LayoutServiceVideo-video:hover .LayoutServiceVideo-videoCover,
  .isMobile .LayoutServiceVideo-video:active .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.4);
            box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .LayoutServiceVideo-video:hover .LayoutServiceVideo-videoCover,
  .isMobile .LayoutServiceVideo-video:active .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.4);
            box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 1920px) {
  .isDesktop .LayoutServiceVideo-video:hover .LayoutServiceVideo-videoCover,
  .isMobile .LayoutServiceVideo-video:active .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
            box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
  }
}
.isDesktop .LayoutServiceVideo-video:hover .LayoutServiceVideo-videoCover img,
.isMobile .LayoutServiceVideo-video:active .LayoutServiceVideo-videoCover img {
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
.LayoutServiceVideo-videoCover {
  position: relative;
  -webkit-transition: 0.15s -webkit-box-shadow ease;
  transition: 0.15s -webkit-box-shadow ease;
  transition: 0.15s box-shadow ease;
  transition: 0.15s box-shadow ease, 0.15s -webkit-box-shadow ease;
  overflow: hidden;
}
@media (max-width: 759px) {
  .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.2);
            box-shadow: 0 4.6875vw 4.6875vw 1.5625vw rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.3);
            box-shadow: 0 1.20967742vw 3.22580645vw 0.80645161vw rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.3);
            box-shadow: 0 1.04166667vw 2.77777778vw 0.69444444vw rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 1920px) {
  .LayoutServiceVideo-videoCover {
    -webkit-box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
            box-shadow: 0 20px 53.33333333px 13.33333333px rgba(0, 0, 0, 0.4);
  }
}
.LayoutServiceVideo-videoCover img {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.LayoutServiceVideo-videoPlayButton {
  background-color: #FFFFFF;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 759px) {
  .LayoutServiceVideo-videoPlayButton {
    width: 12.5vw;
    height: 8.125vw;
    bottom: 6.25vw;
    left: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutServiceVideo-videoPlayButton {
    width: 4.83870968vw;
    height: 3.22580645vw;
    bottom: 1.61290323vw;
    left: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutServiceVideo-videoPlayButton {
    width: 4.16666667vw;
    height: 2.77777778vw;
    bottom: 1.38888889vw;
    left: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutServiceVideo-videoPlayButton {
    width: 80px;
    height: 53.33333333px;
    bottom: 26.66666667px;
    left: 26.66666667px;
  }
}
.LayoutServiceVideo-videoPlayButton svg {
  width: 50%;
  height: 50%;
}
@media (max-width: 759px) {
  .LayoutServiceVideo-videoDescription {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.LayoutServiceVideo-videoTitle {
  color: #111112;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutServiceVideo-videoTitle {
    margin-top: 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutServiceVideo-videoTitle {
    margin-top: 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .LayoutServiceVideo-videoTitle {
    margin-top: 20px;
  }
}
.LayoutServiceVideo-videoTiming {
  color: #6F7380;
}
