.ProductDetailsPopup-content {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.04666667em;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 1920px) {
  .ProductDetailsPopup-content {
    font-size:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsPopup-content {
    font-size:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsPopup-content {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsPopup-content {
    font-size:  5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsPopup-content {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .ProductDetailsPopup-content {
    width: 100%;
  }
}
.ProductDetailsPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isIE .ProductDetailsPopup-contentWrapper {
  width: 100%;
}
.ProductDetailsPopup-cardWrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
@media (max-width: 759px) {
  .ProductDetailsPopup-cardWrapper {
    display: none;
  }
}
.isIE .ProductDetailsPopup-cardWrapper {
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsPopup-quickcalc {
    display: none;
  }
}
