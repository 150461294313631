.LayoutsServicesHeader {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #FFFFFF;
}
@media (max-width: 759px) {
  .LayoutsServicesHeader {
    margin-top: 12.5vw;
    display: block;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsServicesHeader {
    width: 100%;
    height: 45.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsServicesHeader {
    width: 100%;
    height: 38.88888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsServicesHeader {
    width: 100%;
    height: 746.66666667px;
  }
}
.LayoutsServicesHeader-wrapper {
  position: relative;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsServicesHeader-wrapper {
    width: 100%;
    height: 45.16129032vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsServicesHeader-wrapper {
    width: 100%;
    height: 38.88888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsServicesHeader-wrapper {
    width: 100%;
    height: 746.66666667px;
  }
}
.LayoutsServicesHeader-image {
  height: 100%;
}
@media (max-width: 759px) {
  .LayoutsServicesHeader-image {
    position: relative;
    height: 100vw;
  }
}
.LayoutsServicesHeader-image img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.LayoutsServicesHeader-imageShadow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, rgba(255, 255, 255, 0)), to(rgba(0, 0, 0, 0.5)));
  background: linear-gradient(rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.5));
}
@media (max-width: 759px) {
  .LayoutsServicesHeader-imageShadow {
    display: none;
  }
}
.LayoutsServicesHeader-titleWrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}
@media (max-width: 759px) {
  .LayoutsServicesHeader-titleWrapper {
    width: 87.5vw;
    bottom: 9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsServicesHeader-titleWrapper {
    width: 61.29032258vw;
    bottom: 7.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsServicesHeader-titleWrapper {
    width: 52.77777778vw;
    bottom: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsServicesHeader-titleWrapper {
    width: 1013.33333333px;
    bottom: 120px;
  }
}
.LayoutsServicesHeader-office {
  color: #FFFFFF;
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .LayoutsServicesHeader-office {
    text-transform: inherit;
    padding-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsServicesHeader-office {
    padding-bottom: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsServicesHeader-office {
    padding-bottom: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsServicesHeader-office {
    padding-bottom: 26.66666667px;
  }
}
.LayoutsServicesHeader-title {
  color: #FFFFFF;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
}
@media (max-width: 759px) {
  .LayoutsServicesHeader-title {
    text-transform: inherit;
    border-top: unset;
    padding-top: unset;
    padding-bottom: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .LayoutsServicesHeader-title {
    padding-top: 2.82258065vw;
    padding-bottom: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .LayoutsServicesHeader-title {
    padding-top: 2.43055556vw;
    padding-bottom: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .LayoutsServicesHeader-title {
    padding-top: 46.66666667px;
    padding-bottom: 26.66666667px;
  }
}
.LayoutsServicesHeader-description {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .LayoutsServicesHeader-description {
    letter-spacing: -0.28px;
  }
}
