@media (min-width: 1920px) {
  .CommonDonut {
    margin-top:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut {
    margin-top:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut {
    margin-top:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut {
    margin-top:  9.375vw;
  }
}
.CommonDonut:first-child {
  margin-top: 0;
}
.CommonDonut-text {
  color: #6F7380;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
}
@media (max-width: 759px) {
  .CommonDonut-text {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-text {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-text {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonDonut-text {
    font-size: 26.66666667px;
  }
}
@media (max-width: 759px) {
  .CommonDonut-text ul {
    margin-top: 3.125vw;
  }
}
.CommonDonut-text ul li {
  position: relative;
}
@media (min-width: 1920px) {
  .CommonDonut-text ul li {
    padding-left:  37.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-text ul li {
    padding-left:  1.9444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-text ul li {
    padding-left:  2.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-text ul li {
    padding-left:  6.25vw;
  }
}
.CommonDonut-text ul li:before {
  content: '•';
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: #6F7380;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 759px) {
  .CommonDonut-text ul li:before {
    font-size: 4.375vw;
    letter-spacing: 0;
    line-height: 1.71428571;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-text ul li:before {
    font-size: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-text ul li:before {
    font-size: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .CommonDonut-text ul li:before {
    font-size: 26.66666667px;
  }
}
@media (min-width: 1920px) {
  .CommonDonut-text ol {
    padding-left:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-text ol {
    padding-left:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-text ol {
    padding-left:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-text ol {
    padding-left:  7.8125vw;
  }
}
.CommonDonut-text ol li {
  list-style-type: decimal;
}
@media (min-width: 1920px) {
  .CommonDonut-text ol li {
    padding-left:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-text ol li {
    padding-left:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-text ol li {
    padding-left:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-text ol li {
    padding-left:  3.125vw;
  }
}
.CommonDonut-donut {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 47.36842105%;
}
.CommonDonut-donut:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
@media (max-width: 759px) {
  .CommonDonut-donut {
    width: 85.71428571%;
  }
}
.CommonDonut-donut svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  overflow: visible;
  -webkit-transform: rotate(-90deg) translateZ(0);
          transform: rotate(-90deg) translateZ(0);
}
.CommonDonut-donut svg circle {
  border: 1px solid #FFFFFF;
}
@media (min-width: 1920px) {
  .CommonDonut-donut svg circle {
    stroke-width:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-donut svg circle {
    stroke-width:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-donut svg circle {
    stroke-width:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-donut svg circle {
    stroke-width:  15.625vw;
  }
}
.CommonDonut-donutWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .CommonDonut-donutWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 1920px) {
  .CommonDonut-donutWrapper {
    margin-top:  86.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-donutWrapper {
    margin-top:  4.513888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-donutWrapper {
    margin-top:  5.241935483870968vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-donutWrapper {
    margin-top:  12.5vw;
  }
}
.CommonDonut-donutLegend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 47.36842105%;
  margin-left: 5.26315789%;
}
@media (max-width: 759px) {
  .CommonDonut-donutLegend {
    width: 100%;
  }
}
@media (max-width: 759px) {
  .CommonDonut-donutLegend {
    margin-top:  6.25vw;
    margin-left:  0vw;
  }
}
.CommonDonut-donutLegendItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border-top: 1px solid #E7E7E7;
}
.CommonDonut-donutLegendItem:first-child {
  border-top: none;
  padding-top: 0;
}
.CommonDonut-donutLegendItem:last-child {
  padding-bottom: 0;
}
@media (min-width: 1920px) {
  .CommonDonut-donutLegendItem {
    padding-top:  26.666666666666664px;
    padding-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-donutLegendItem {
    padding-top:  1.3888888888888888vw;
    padding-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-donutLegendItem {
    padding-top:  1.6129032258064515vw;
    padding-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-donutLegendItem {
    padding-top:  6.25vw;
    padding-bottom:  6.25vw;
  }
}
.CommonDonut-donutLegendItemColor {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.CommonDonut-donutLegendItemColor:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
@media (min-width: 1920px) {
  .CommonDonut-donutLegendItemColor {
    width:  26.666666666666664px;
    margin-right:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-donutLegendItemColor {
    width:  1.3888888888888888vw;
    margin-right:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-donutLegendItemColor {
    width:  1.6129032258064515vw;
    margin-right:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-donutLegendItemColor {
    width:  6.25vw;
    margin-right:  6.25vw;
  }
}
.CommonDonut-donutLegendItemInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1920px) {
  .CommonDonut-donutLegendItemInfo {
    margin-top:  8px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CommonDonut-donutLegendItemInfo {
    margin-top:  0.4166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CommonDonut-donutLegendItemInfo {
    margin-top:  0.4838709677419355vw;
  }
}
@media (max-width: 759px) {
  .CommonDonut-donutLegendItemInfo {
    margin-top:  1.875vw;
  }
}
.CommonDonut-donutLegendItemInfoTitle {
  color: #111112;
  line-height: 1;
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}
.CommonDonut-donutLegendItemInfoCaption {
  color: #6F7380;
}
