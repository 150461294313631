.PagePressLanding .PageLanding-blockBg[data-id="1"] img,
.PagePressLanding .PageLanding-blockBg_mobile[data-id="1"] img {
  opacity: 1;
}
@media (max-width: 759px) {
  .PagePressLanding .PageLanding-blockPlaceholder {
    height: 80vh;
  }
  .PagePressLanding .PageLanding-blockPlaceholder:last-of-type {
    height: 30vh;
  }
}
.PagePressLanding .PageLanding-blockBg_mobile:before {
  content: '';
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #111112;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.PagePressLanding-news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .PagePressLanding-news {
    padding:  0px 186.66666666666666px 160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-news {
    padding:  0vw 9.722222222222223vw 8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-news {
    padding:  0vw 11.29032258064516vw 9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-news {
    padding:  0vw 6.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-news {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.PagePressLanding-newsBlock {
  border-top: 1px solid #FFFFFF;
  width: 48.27586207%;
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlock {
    padding-top:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlock {
    padding-top:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlock {
    padding-top:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlock {
    padding-top:  3.75vw;
    margin-bottom:  25vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlock {
    width: 100%;
  }
}
.PagePressLanding-newsBlockBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockBody {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: unset;
    margin-top: 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockBody {
    margin-bottom: 0.40322581vw;
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockBody {
    margin-bottom: 0.34722222vw;
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockBody {
    margin-bottom: 6.66666667px;
    margin-top: 46.66666667px;
  }
}
.PagePressLanding-newsBlockItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 46.42857143%;
  position: relative;
  text-decoration: none;
  -webkit-box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.4);
          box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.4);
}
.PagePressLanding-newsBlockItem:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 107.69230769%;
}
.isDesktop .PagePressLanding-newsBlockItem:hover .PagePressLanding-newsBlockItemBg,
.isNotDesktop .PagePressLanding-newsBlockItem:active .PagePressLanding-newsBlockItemBg {
  opacity: 0.4;
}
.isDesktop .PagePressLanding-newsBlockItem:hover .PagePressLanding-newsBlockItemLink,
.isNotDesktop .PagePressLanding-newsBlockItem:active .PagePressLanding-newsBlockItemLink {
  text-decoration: underline;
}
.isDesktop .PagePressLanding-newsBlockItem:hover .PagePressLanding-newsBlockItemLink:before,
.isNotDesktop .PagePressLanding-newsBlockItem:active .PagePressLanding-newsBlockItemLink:before {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PagePressLanding-newsBlockItem:hover .PagePressLanding-newsBlockItemLink:before,
  .isNotDesktop .PagePressLanding-newsBlockItem:active .PagePressLanding-newsBlockItemLink:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItem {
    padding:  29.333333333333332px 26.666666666666664px 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItem {
    padding:  1.527777777777778vw 1.3888888888888888vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItem {
    padding:  1.7741935483870968vw 1.6129032258064515vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItem {
    padding:  5.625vw 6.25vw 6.875vw;
    margin-top:  6.25vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItem {
    position: relative;
    width: 100%;
    -webkit-box-shadow: 0 3.125vw 12.5vw 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 3.125vw 12.5vw 0 rgba(0, 0, 0, 0.4);
  }
  .PagePressLanding-newsBlockItem:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 71.42857143%;
  }
}
.PagePressLanding-newsBlockItem:first-child {
  margin-top: 0;
}
.PagePressLanding-newsBlockItem:nth-child(2n) {
  margin-left: 7.14285714%;
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItem:nth-child(2n) {
    margin-left: 0;
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItem:nth-child(n + 3) {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItem:nth-child(n + 3) {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItem:nth-child(n + 3) {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItem:nth-child(n + 3) {
    margin-top:  6.25vw;
  }
}
.PagePressLanding-newsBlockItemBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  opacity: 0.2;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.PagePressLanding-newsBlockItemInfo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItemInfo {
    padding:  29.333333333333332px 26.666666666666664px 26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItemInfo {
    padding:  1.527777777777778vw 1.3888888888888888vw 1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItemInfo {
    padding:  1.7741935483870968vw 1.6129032258064515vw 1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemInfo {
    padding:  5.625vw 6.25vw 6.875vw;
  }
}
.PagePressLanding-newsBlockItemTitle {
  color: #FFFFFF;
}
.PagePressLanding-newsBlockItemText {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItemText {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItemText {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItemText {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemText {
    margin-top:  5vw;
  }
}
.PagePressLanding-newsBlockItemText a {
  color: #FFFFFF;
}
.PagePressLanding-newsBlockItemLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  position: absolute;
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItemLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItemLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItemLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .PagePressLanding-newsBlockItemLink:hover,
.isNotDesktop .PagePressLanding-newsBlockItemLink:active {
  text-decoration: underline;
}
.isDesktop .PagePressLanding-newsBlockItemLink:hover:before,
.isNotDesktop .PagePressLanding-newsBlockItemLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .PagePressLanding-newsBlockItemLink:hover:before,
  .isNotDesktop .PagePressLanding-newsBlockItemLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemLink {
    letter-spacing: 0;
  }
}
.PagePressLanding-newsBlockItemLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .PagePressLanding-newsBlockItemLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItemLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItemLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItemLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-newsBlockItemLink {
    bottom:  26.666666666666664px;
    left:  26.666666666666664px;
    margin-top:  120px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-newsBlockItemLink {
    bottom:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
    margin-top:  6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-newsBlockItemLink {
    bottom:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
    margin-top:  7.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-newsBlockItemLink {
    bottom:  6.25vw;
    left:  6.25vw;
    margin-top:  28.125vw;
  }
}
@media (min-width: 1920px) {
  .PagePressLanding-downloads {
    padding:  400px 186.66666666666666px 133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PagePressLanding-downloads {
    padding:  20.833333333333336vw 9.722222222222223vw 6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PagePressLanding-downloads {
    padding:  24.193548387096772vw 11.29032258064516vw 8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .PagePressLanding-downloads {
    padding:  0vw;
  }
}
