.InspirationCaseDetails {
  border-top: 1px solid #111112;
}
@media (min-width: 1920px) {
  .InspirationCaseDetails {
    padding-top: 40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseDetails {
    padding-top: 2.08333333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseDetails {
    padding-top: 2.41935484vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseDetails {
    padding-top: 6.25vw;
  }
}
.InspirationCaseDetails-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #E7E7E7;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .InspirationCaseDetails-detail {
    padding: 21.33333333px 0 20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseDetails-detail {
    padding: 1.11111111vw 0 1.04166667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseDetails-detail {
    padding: 1.29032258vw 0 1.20967742vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseDetails-detail {
    padding: 5vw 0 4.0625vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.InspirationCaseDetails-detail:last-of-type {
  border-bottom: none;
}
.InspirationCaseDetails-detail:first-of-type {
  padding-top: 0;
}
.InspirationCaseDetails-detailTitle {
  color: #6F7380;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33%;
          flex: 0 0 33%;
}
@media (max-width: 759px) {
  .InspirationCaseDetails-detailTitle {
    font-size: 3.75vw;
    line-height: 1.66666667;
  }
}
.InspirationCaseDetails-detailValue {
  color: #111112;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 65%;
          flex: 0 0 65%;
}
.InspirationCaseDetails-detailValue a {
  color: #111112;
}
.InspirationCaseDetails-detailValue a:visited {
  color: #111112;
}
.isDesktop .InspirationCaseDetails-detailValue a:hover,
.isMobile .InspirationCaseDetails-detailValue a:active {
  text-decoration: none;
}
@media (min-width: 1920px) {
  .InspirationCaseDetails-detailValue {
    padding-right: 40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InspirationCaseDetails-detailValue {
    padding-right: 2.08333333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InspirationCaseDetails-detailValue {
    padding-right: 2.41935484vw;
  }
}
@media (max-width: 759px) {
  .InspirationCaseDetails-detailValue {
    font-size: 3.75vw;
    line-height: 1.66666667;
  }
}
