@media (max-width: 759px) {
  .CompanyHistoryItems-desktop {
    display: none;
  }
}
.CompanyHistoryItems-mobile {
  display: none;
}
@media (max-width: 759px) {
  .CompanyHistoryItems-mobile {
    display: block;
  }
}
.CompanyHistoryItems-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  cursor: pointer;
}
.isDesktop .CompanyHistoryItems-item:hover .CompanyHistoryItems-itemLink::before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isDesktop .CompanyHistoryItems-item:hover .CompanyHistoryItems-itemImage img {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
@media (max-width: 759px) {
  .CompanyHistoryItems-item {
    display: block;
  }
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-item {
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-item {
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-item {
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CompanyHistoryItems-item {
    padding-top:  4.6875vw;
  }
}
.CompanyHistoryItems-item:first-of-type {
  margin-top: 0;
}
.CompanyHistoryItems-itemUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.CompanyHistoryItems-itemImage {
  background: #111112;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-itemImage {
    -ms-flex-preferred-size:  480px;
        flex-basis:  480px;
    height:  266.66666666666663px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-itemImage {
    -ms-flex-preferred-size:  25vw;
        flex-basis:  25vw;
    height:  13.88888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-itemImage {
    -ms-flex-preferred-size:  29.032258064516128vw;
        flex-basis:  29.032258064516128vw;
    height:  16.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemImage {
    width:  81.25vw;
    height:  43.75vw;
    -webkit-box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
            box-shadow:  0vw 1.5625vw 4.6875vw 1.5625vw;
  }
}
.CompanyHistoryItems-itemImage img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-itemDetails {
    margin-left:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-itemDetails {
    margin-left:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-itemDetails {
    margin-left:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemDetails {
    margin-left:  0vw;
    margin-top:  6.25vw;
  }
}
.CompanyHistoryItems-itemTitle {
  text-transform: none;
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemTitle {
    font-size: 9.375vw;
  }
}
.CompanyHistoryItems-itemDescription {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-itemDescription {
    margin-top:  6.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-itemDescription {
    margin-top:  0.3472222222222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-itemDescription {
    margin-top:  0.4032258064516129vw;
  }
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemDescription {
    margin-top:  1.5625vw;
  }
}
.CompanyHistoryItems-itemLink {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemLink {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-itemLink {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-itemLink {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-itemLink {
    font-size: 18.66666667px;
  }
}
.isDesktop .CompanyHistoryItems-itemLink:hover,
.isNotDesktop .CompanyHistoryItems-itemLink:active {
  text-decoration: underline;
}
.isDesktop .CompanyHistoryItems-itemLink:hover:before,
.isNotDesktop .CompanyHistoryItems-itemLink:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .CompanyHistoryItems-itemLink:hover:before,
  .isNotDesktop .CompanyHistoryItems-itemLink:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemLink {
    letter-spacing: 0;
  }
}
.CompanyHistoryItems-itemLink:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big-dark.svg') no-repeat;
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemLink:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .CompanyHistoryItems-itemLink:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemLink:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-itemLink:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-itemLink:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-itemLink:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
.CompanyHistoryItems-itemLink:visited {
  color: #111112;
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemLink {
    line-height: 1;
  }
}
@media (min-width: 1920px) {
  .CompanyHistoryItems-itemLink {
    margin-top:  48px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyHistoryItems-itemLink {
    margin-top:  2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyHistoryItems-itemLink {
    margin-top:  2.903225806451613vw;
  }
}
@media (max-width: 759px) {
  .CompanyHistoryItems-itemLink {
    margin-top:  5.625vw;
  }
}
