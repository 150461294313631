@media (max-width: 759px) {
  .ProductDetailsDownloads {
    padding: 6.25vw 6.25vw;
    margin-top: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDownloads {
    padding: 0 9.67741935vw;
    margin-top: 8.06451613vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDownloads {
    padding: 0 8.33333333vw;
    margin-top: 6.94444444vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDownloads {
    padding: 0 160px;
    margin-top: 133.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-title {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.ProductDetailsDownloads-arrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-arrowBack {
    display: block;
    position: absolute;
    left: -0.625vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ProductDetailsDownloads-arrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-downloads {
    margin-top: 10.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDownloads-downloads {
    margin-top: 2.82258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDownloads-downloads {
    margin-top: 2.43055556vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDownloads-downloads {
    margin-top: 46.66666667px;
  }
}
.ProductDetailsDownloads-downloadsItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  letter-spacing: normal;
  color: #111112;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-downloadsItem {
    padding-bottom: 3.125vw;
    padding-top: 2.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDownloads-downloadsItem {
    padding-bottom: 1.29032258vw;
    padding-top: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDownloads-downloadsItem {
    padding-bottom: 1.11111111vw;
    padding-top: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDownloads-downloadsItem {
    padding-bottom: 21.33333333px;
    padding-top: 18.66666667px;
  }
}
.ProductDetailsDownloads-downloadsItem:last-of-type {
  border-bottom-color: #FFFFFF;
}
.isDesktop .ProductDetailsDownloads-downloadsItem:hover,
.isNotDesktop .ProductDetailsDownloads-downloadsItem:active {
  border-bottom: 1px solid #111112;
}
.ProductDetailsDownloads-downloadsItem:visited {
  text-decoration: none;
}
.ProductDetailsDownloads-downloadsItemTitle {
  color: #111112;
  text-decoration: none;
  text-transform: none;
}
.ProductDetailsDownloads-downloadsItemTitle:visited {
  color: #111112;
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-downloadsItemTitle {
    font-size: 4.375vw;
    line-height: normal;
  }
}
.ProductDetailsDownloads-downloadsItemDownload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-downloadsItemDownload {
    width: 10.9375vw;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    padding-bottom: 0.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDownloads-downloadsItemDownload {
    width: 3.62903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDownloads-downloadsItemDownload {
    width: 3.125vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDownloads-downloadsItemDownload {
    width: 60px;
  }
}
.ProductDetailsDownloads-downloadsItemDownloadExtension {
  text-decoration: none;
  color: #111112;
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-downloadsItemDownloadExtension {
    font-size: 2.8125vw;
    line-height: normal;
  }
}
.ProductDetailsDownloads-downloadsItemDownloadIcon {
  position: relative;
  display: block;
}
@media (max-width: 759px) {
  .ProductDetailsDownloads-downloadsItemDownloadIcon {
    width: 3.75vw;
    height: 4.6875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDownloads-downloadsItemDownloadIcon {
    width: 1.29032258vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDownloads-downloadsItemDownloadIcon {
    width: 1.11111111vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDownloads-downloadsItemDownloadIcon {
    width: 21.33333333px;
    height: 26.66666667px;
  }
}
.ProductDetailsDownloads-downloadsItemDownloadIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
