.InputField {
  width: 100%;
  position: relative;
}
.InputField.required:before {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  content: '*';
  color: #6F7380;
  position: absolute;
}
@media (max-width: 759px) {
  .InputField.required:before {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InputField.required:before {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InputField.required:before {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .InputField.required:before {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .InputField.required:before {
    left:  -20px;
    top:  1.3333333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InputField.required:before {
    left:  -1.0416666666666667vw;
    top:  0.06944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InputField.required:before {
    left:  -1.2096774193548387vw;
    top:  0.08064516129032258vw;
  }
}
@media (max-width: 759px) {
  .InputField.required:before {
    top:  0.3125vw;
    left:  -3.125vw;
  }
}
.InputField.invalid .InputField-input {
  border-color: #EA0101;
}
.InputField.invalid .InputField-errorMessage {
  display: block;
}
.InputField-label {
  display: block;
  color: #6F7380;
  text-transform: uppercase;
}
.InputField-input {
  outline: none;
  border: 1px solid #E7E7E7;
  background-color: #FFFFFF;
  color: #111112;
  width: 100%;
  line-height: 1.2;
}
.InputField-input:focus {
  border-color: #111112;
  outline: none;
}
@media (min-width: 1920px) {
  .InputField-input {
    font-size:  26.666666666666664px;
    padding:  10.666666666666666px 13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .InputField-input {
    font-size:  1.3888888888888888vw;
    padding:  0.5555555555555556vw 0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .InputField-input {
    font-size:  1.6129032258064515vw;
    padding:  0.6451612903225806vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .InputField-input {
    font-size:  3.75vw;
    padding:  2.5vw 3.125vw;
  }
}
.InputField-input[disabled] {
  color: #A6A8B3;
}
.InputField-errorMessage {
  color: #EA0101;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}
