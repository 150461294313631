.Search {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1300;
  -webkit-transition: visibility 0.3s step-start;
  transition: visibility 0.3s step-start;
}
@media (max-width: 759px) {
  .Search {
    top: 12.5vw;
    z-index: 1000;
  }
}
.Search--withcontent .Search-inputTip,
.Search--isFocus .Search-inputTip {
  opacity: 0;
}
.Search--withcontent .Search-icon svg circle,
.Search--isFocus .Search-icon svg circle {
  stroke: #111112;
}
.Search--withcontent .Search-icon svg path,
.Search--isFocus .Search-icon svg path {
  fill: #111112;
}
.Search--hide {
  visibility: hidden;
  -webkit-transition: visibility 0.3s step-end;
  transition: visibility 0.3s step-end;
}
.Search--hide .Search-bg {
  opacity: 0;
}
.Search--hide .Search-inputWrapper {
  opacity: 0;
  -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
  will-change: transform;
  -webkit-transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow;
  transition: 0.3s opacity 0.15s, 0.3s transform 0.15s, 0.3s box-shadow, 0.3s -webkit-transform 0.15s, 0.3s -webkit-box-shadow;
}
@media (max-width: 759px) {
  .Search--hide .Search-inputWrapper {
    -webkit-transform: translate3d(0, -12.5vw, 0);
            transform: translate3d(0, -12.5vw, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search--hide .Search-inputWrapper {
    -webkit-transform: translate3d(0, -3.22580645vw, 0);
            transform: translate3d(0, -3.22580645vw, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search--hide .Search-inputWrapper {
    -webkit-transform: translate3d(0, -2.77777778vw, 0);
            transform: translate3d(0, -2.77777778vw, 0);
  }
}
@media (min-width: 1920px) {
  .Search--hide .Search-inputWrapper {
    -webkit-transform: translate3d(0, -53.33333333px, 0);
            transform: translate3d(0, -53.33333333px, 0);
  }
}
.Search-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(21, 25, 40, 0.5);
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
}
@media (max-width: 759px) {
  .Search-bg {
    background: #F6F6F6;
  }
}
.Search-inputWrapper {
  position: absolute;
  will-change: transform;
  -webkit-transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s;
  transition: 0.3s opacity, 0.3s transform, 0.3s box-shadow 0.15s, 0.3s -webkit-transform, 0.3s -webkit-box-shadow 0.15s;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
}
@media (max-width: 759px) {
  .Search-inputWrapper {
    right: 0;
    top: 0;
    left: 0;
    height: 18.75vw;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid #E7E7E7;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-inputWrapper {
    right: 1.61290323vw;
    top: 1.61290323vw;
    left: 1.61290323vw;
    height: 9.67741935vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-inputWrapper {
    right: 1.38888889vw;
    top: 1.38888889vw;
    left: 1.38888889vw;
    height: 8.33333333vw;
  }
}
@media (min-width: 1920px) {
  .Search-inputWrapper {
    right: 26.66666667px;
    top: 26.66666667px;
    left: 26.66666667px;
    height: 160px;
  }
}
.Search-icon {
  position: absolute;
}
@media (max-width: 759px) {
  .Search-icon {
    top: 13.125vw;
    left: 12.5vw;
    width: 12.5vw;
    height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-icon {
    top: 3.38709677vw;
    left: 3.22580645vw;
    width: 3.22580645vw;
    height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-icon {
    top: 2.91666667vw;
    left: 2.77777778vw;
    width: 2.77777778vw;
    height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .Search-icon {
    top: 56px;
    left: 53.33333333px;
    width: 53.33333333px;
    height: 53.33333333px;
  }
}
.Search-icon svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.Search-icon svg circle {
  stroke: #A6A8B3;
}
.Search-icon svg path {
  fill: #A6A8B3;
}
@media (max-width: 759px) {
  .Search-icon svg path {
    stroke: #111112;
  }
}
@media (max-width: 759px) {
  .Search-icon {
    display: none;
  }
}
.Search-icon-mobile {
  display: none;
}
@media (max-width: 759px) {
  .Search-icon-mobile {
    display: block;
    position: absolute;
    top: 5.9375vw;
    left: 5.9375vw;
    width: 6.875vw;
    height: 6.875vw;
    background-size: 6.875vw 6.875vw;
    background-image: url('/static/svg/icon-search-dark.svg');
  }
}
.Search-input {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  line-height: 1.5;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  border: none;
  outline: none;
  text-transform: none;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
@media (min-width: 1920px) {
  .Search-input {
    font-size:  80px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-input {
    font-size:  4.166666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-input {
    font-size:  4.838709677419355vw;
  }
}
@media (max-width: 759px) {
  .Search-input {
    font-size:  9.375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px), (max-width: 759px) {
  .Search-input {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .Search-input {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    letter-spacing: -0.04666667em;
    line-height: 1;
    text-transform: uppercase;
    text-transform: none;
    line-height: 1.628;
    top: 4.375vw;
    left: 18.75vw;
    width: calc(100% - 21.875vw);
    height: 9.375vw;
    font-size: 9.375vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .Search-input {
    font-size:  40px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .Search-input {
    font-size:  2.0833333333333335vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .Search-input {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .Search-input {
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .Search-input {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-input {
    top: 1.0483871vw;
    left: 8.06451613vw;
    width: calc(100% - 14.51612903vw);
    height: 6.85483871vw;
    font-size: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-input {
    top: 0.90277778vw;
    left: 6.94444444vw;
    width: calc(100% - 12.5vw);
    height: 5.90277778vw;
    font-size: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .Search-input {
    top: 17.33333333px;
    left: 133.33333333px;
    width: calc(100% - 240px);
    height: 113.33333333px;
    font-size: 80px;
  }
}
.Search-input::-webkit-input-placeholder {
  color: #A6A8B3;
  -webkit-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.Search-input::-moz-placeholder {
  color: #A6A8B3;
  opacity: 1;
  -moz-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.Search-input:-ms-input-placeholder {
  color: #A6A8B3;
  -ms-transition: 0.15s color ease-out;
  transition: 0.15s color ease-out;
}
.Search-input:focus::-webkit-input-placeholder {
  color: transparent;
}
.Search-input:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.Search-input:focus:-ms-input-placeholder {
  color: transparent;
}
.Search-inputTip {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  position: absolute;
  pointer-events: none;
  color: #A6A8B3;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
  line-height: 1.4;
}
@media (max-width: 759px) {
  .Search-inputTip {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-inputTip {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-inputTip {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Search-inputTip {
    font-size: 16px;
  }
}
@media (max-width: 759px) {
  .Search-inputTip {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-inputTip {
    bottom: 2.58064516vw;
    left: 24.19354839vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-inputTip {
    bottom: 2.22222222vw;
    left: 20.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Search-inputTip {
    bottom: 42.66666667px;
    left: 400px;
  }
}
.Search-close {
  position: absolute;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
}
@media (max-width: 759px) {
  .isDesktop .Search-close:hover .Search-closeItem,
  .isNotDesktop .Search-close:active .Search-closeItem {
    width: 10vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .Search-close:hover .Search-closeItem,
  .isNotDesktop .Search-close:active .Search-closeItem {
    width: 2.58064516vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .Search-close:hover .Search-closeItem,
  .isNotDesktop .Search-close:active .Search-closeItem {
    width: 2.22222222vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .Search-close:hover .Search-closeItem,
  .isNotDesktop .Search-close:active .Search-closeItem {
    width: 42.66666667px;
  }
}
@media (max-width: 759px) {
  .Search-close {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-close {
    width: 2.58064516vw;
    height: 2.09677419vw;
    right: 1.93548387vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-close {
    width: 2.22222222vw;
    height: 1.80555556vw;
    right: 1.66666667vw;
  }
}
@media (min-width: 1920px) {
  .Search-close {
    width: 42.66666667px;
    height: 34.66666667px;
    right: 32px;
  }
}
.Search-closeItem {
  position: absolute;
  height: 2px;
  background: #111112;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.Search-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.Search-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (max-width: 759px) {
  .Search-closeItem {
    width: 8.125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-closeItem {
    width: 2.09677419vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-closeItem {
    width: 1.80555556vw;
  }
}
@media (min-width: 1920px) {
  .Search-closeItem {
    width: 34.66666667px;
  }
}
.Search-results {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  visibility: hidden;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
}
.Search--withcontent .Search-results {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
.Search--hide .Search-results {
  opacity: 0;
}
@media (max-width: 759px) {
  .Search-results {
    background: #FFFFFF;
    top: 18.75vw;
    left: 0;
    right: 0;
    height: 85vh;
    overflow: scroll;
    padding-bottom: 31.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-results {
    top: 12.90322581vw;
    left: 3.22580645vw;
    right: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-results {
    top: 11.11111111vw;
    left: 2.77777778vw;
    right: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .Search-results {
    top: 213.33333333px;
    left: 53.33333333px;
    right: 53.33333333px;
  }
}
.Search-resultsItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (max-width: 759px) {
  .Search-resultsItems {
    display: block;
  }
}
.Search-resultsItem {
  color: inherit;
  cursor: pointer;
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  -webkit-transition: 0.15s background-color ease-out;
  transition: 0.15s background-color ease-out;
}
@media (max-width: 759px) {
  .Search-resultsItem {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItem {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItem {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .Search-resultsItem {
    font-size: 18.66666667px;
  }
}
.isDesktop .Search-resultsItem:hover,
.isNotDesktop .Search-resultsItem:active {
  text-decoration: underline;
}
.isDesktop .Search-resultsItem:hover:before,
.isNotDesktop .Search-resultsItem:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .Search-resultsItem:hover:before,
  .isNotDesktop .Search-resultsItem:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .Search-resultsItem {
    letter-spacing: 0;
  }
}
.Search-resultsItem:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .Search-resultsItem:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .Search-resultsItem:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .Search-resultsItem:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItem:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItem:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .Search-resultsItem:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .Search-resultsItem {
    min-height:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItem {
    min-height:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItem {
    min-height:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItem {
    min-height:  25vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItem {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0 6.25vw;
  }
}
.Search-resultsItem:before {
  opacity: 0;
  position: absolute;
  top: 50%;
}
@media (max-width: 759px) {
  .Search-resultsItem:before {
    right: 7.8125vw;
    -webkit-transform: translate3d(-1.25vw, -50%, 0);
            transform: translate3d(-1.25vw, -50%, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItem:before {
    right: 2.01612903vw;
    -webkit-transform: translate3d(-0.64516129vw, -50%, 0);
            transform: translate3d(-0.64516129vw, -50%, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItem:before {
    right: 1.73611111vw;
    -webkit-transform: translate3d(-0.55555556vw, -50%, 0);
            transform: translate3d(-0.55555556vw, -50%, 0);
  }
}
@media (min-width: 1920px) {
  .Search-resultsItem:before {
    right: 33.33333333px;
    -webkit-transform: translate3d(-10.66666667px, -50%, 0);
            transform: translate3d(-10.66666667px, -50%, 0);
  }
}
@media (max-width: 759px) {
  .Search-resultsItem:after {
    content: '';
    position: absolute;
    width: 84.375vw;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-bottom: 1px solid #E7E7E7;
  }
}
.isDesktop .Search-resultsItem:hover,
.isNotDesktop .Search-resultsItem:active {
  background: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}
.isDesktop .Search-resultsItem:hover:before,
.isNotDesktop .Search-resultsItem:active:before {
  opacity: 1;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (max-width: 759px) {
  .isDesktop .Search-resultsItem:hover:before,
  .isNotDesktop .Search-resultsItem:active:before {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
}
.Search-resultsItemIcon {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: block;
}
@media (min-width: 1920px) {
  .Search-resultsItemIcon {
    width:  106.66666666666666px;
    height:  106.66666666666666px;
    -ms-flex-preferred-size:  106.66666666666666px;
        flex-basis:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemIcon {
    width:  5.555555555555555vw;
    height:  5.555555555555555vw;
    -ms-flex-preferred-size:  5.555555555555555vw;
        flex-basis:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemIcon {
    width:  6.451612903225806vw;
    height:  6.451612903225806vw;
    -ms-flex-preferred-size:  6.451612903225806vw;
        flex-basis:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemIcon {
    width:  25vw;
    height:  25vw;
    -ms-flex-preferred-size:  25vw;
        flex-basis:  25vw;
  }
}
.Search-resultsItemIcon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
@media (min-width: 1920px) {
  .Search-resultsItemInfo {
    margin-left:  26.666666666666664px;
    padding-right:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemInfo {
    margin-left:  1.3888888888888888vw;
    padding-right:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemInfo {
    margin-left:  1.6129032258064515vw;
    padding-right:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemInfo {
    margin-left:  6.25vw;
    padding-right:  31.25vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemInfo {
    margin-left: 0;
  }
}
@media (min-width: 1920px) {
  .Search-resultsItemInfo.withoutImage {
    margin-left:  133.33333333333331px;
    padding-right:  133.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemInfo.withoutImage {
    margin-left:  6.944444444444445vw;
    padding-right:  6.944444444444445vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemInfo.withoutImage {
    margin-left:  8.064516129032258vw;
    padding-right:  8.064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemInfo.withoutImage {
    margin-left:  25vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemInfo {
    display: block;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .Search-resultsItemInfo {
    padding:  20px 0 20px 13.333333333333332px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemInfo {
    padding:  1.0416666666666667vw 0vw 1.0416666666666667vw 0.6944444444444444vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemInfo {
    padding:  1.2096774193548387vw 0vw 1.2096774193548387vw 0.8064516129032258vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .Search-resultsItemInfo {
    padding:  4.6875vw 0vw 4.6875vw 3.125vw;
  }
}
.Search-resultsItemName {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: -0.04666667em;
  line-height: 1;
  color: #FFFFFF;
  text-transform: uppercase;
  -webkit-hyphens: none !important;
      -ms-hyphens: none !important;
          hyphens: none !important;
}
@media (min-width: 1920px) {
  .Search-resultsItemName {
    font-size:  40px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemName {
    font-size:  2.0833333333333335vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemName {
    font-size:  2.4193548387096775vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemName {
    font-size:  5.625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemName {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemName {
    color: #111112;
    display: block;
  }
}
.Search-resultsItemBrand {
  font-family: 'apercu-mono-regular-pro', Droid Sans Mono, Roboto Mono, Monaco, Lucida Console, sans-serif;
  font-weight: 400;
  line-height: 1.66666667;
  color: #FFFFFF;
  line-height: 1;
}
@media (max-width: 759px) {
  .Search-resultsItemBrand {
    font-size: 2.8125vw;
    line-height: 2.22222222;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemBrand {
    font-size: 0.96774194vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemBrand {
    font-size: 0.83333333vw;
  }
}
@media (min-width: 1920px) {
  .Search-resultsItemBrand {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .Search-resultsItemBrand {
    margin-left:  13.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsItemBrand {
    margin-left:  0.6944444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsItemBrand {
    margin-left:  0.8064516129032258vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemBrand {
    margin-left:  3.125vw;
  }
}
@media (max-width: 759px) {
  .Search-resultsItemBrand {
    color: #6F7380;
    text-transform: capitalize;
    margin-top: 1.5625vw;
    margin-left: 0;
    display: block;
  }
}
@media (max-width: 759px) {
  .Search-resultsLink {
    height: 18.75vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsLink {
    margin-top: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsLink {
    margin-top: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .Search-resultsLink {
    margin-top: 53.33333333px;
  }
}
.Search-resultsLink span {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .Search-resultsLink span {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsLink span {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsLink span {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .Search-resultsLink span {
    font-size: 18.66666667px;
  }
}
.isDesktop .Search-resultsLink span:hover,
.isNotDesktop .Search-resultsLink span:active {
  text-decoration: underline;
}
.isDesktop .Search-resultsLink span:hover:before,
.isNotDesktop .Search-resultsLink span:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .Search-resultsLink span:hover:before,
  .isNotDesktop .Search-resultsLink span:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .Search-resultsLink span {
    letter-spacing: 0;
  }
}
.Search-resultsLink span:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .Search-resultsLink span:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .Search-resultsLink span:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .Search-resultsLink span:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsLink span:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsLink span:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .Search-resultsLink span:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .Search-resultsLink span {
    font-family: 'neue-haas-unica', Helvetica, sans-serif;
    font-weight: 600;
    line-height: 1.42857143;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    color: #111112;
    margin-top: 3.125vw;
    margin-left: 8.125vw;
  }
  .isDesktop .Search-resultsLink span:hover,
  .isNotDesktop .Search-resultsLink span:active {
    text-decoration: underline;
  }
  .isDesktop .Search-resultsLink span:hover:before,
  .isNotDesktop .Search-resultsLink span:active:before {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .Search-resultsLink span:before {
    content: '';
    display: inline-block;
    will-change: transform;
    background: url('/static/img/arrow-big-dark.svg') no-repeat;
  }
  .isDesktop .Search-resultsLink span:before {
    -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s opacity, 0.15s -webkit-transform;
    transition: 0.15s transform, 0.15s opacity;
    transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .Search-resultsLink span {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsLink span {
    font-size: 1.12903226vw;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsLink span {
    font-size: 0.97222222vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .Search-resultsLink span {
    font-size: 18.66666667px;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .isDesktop .Search-resultsLink span:hover:before,
  .isNotDesktop .Search-resultsLink span:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .Search-resultsLink span {
    letter-spacing: 0;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .Search-resultsLink span:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .Search-resultsLink span:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .Search-resultsLink span:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .Search-resultsLink span:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .Search-resultsLink span:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
