.ContactInvestor {
  width: 100%;
  height: 100%;
}
.ContactInvestor-mobileTitle {
  display: none;
}
@media (max-width: 759px) {
  .ContactInvestor-mobileTitle {
    width: 100%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6.25vw;
    background: #F6F6F6;
  }
}
.ContactInvestor-mobileArrowBack {
  display: none;
}
@media (max-width: 759px) {
  .ContactInvestor-mobileArrowBack {
    display: block;
    position: absolute;
    left: 6.25vw;
    width: 5.9375vw;
    height: 3.75vw;
  }
  .ContactInvestor-mobileArrowBack svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}
.ContactInvestor-text {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactInvestor-text {
    margin-top: 6.875vw;
    padding: 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactInvestor-text {
    margin-top: 1.77419355vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactInvestor-text {
    margin-top: 1.52777778vw;
  }
}
@media (min-width: 1920px) {
  .ContactInvestor-text {
    margin-top: 29.33333333px;
  }
}
.ContactInvestor-infoWrapper {
  color: #6F7380;
}
@media (max-width: 759px) {
  .ContactInvestor-infoWrapper {
    margin-top: 9.375vw;
    padding: 0 6.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ContactInvestor-infoWrapper {
    margin-top: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ContactInvestor-infoWrapper {
    margin-top: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ContactInvestor-infoWrapper {
    margin-top: 40px;
  }
}
.ContactInvestor-infoWrapper a {
  color: #111112;
  text-decoration: underline;
}
.isDesktop .ContactInvestor-infoWrapper a:hover,
.isNotDesktop .ContactInvestor-infoWrapper a:active {
  text-decoration: none;
}
.ContactInvestor-infoWrapperName {
  color: #111112;
  font-weight: bold;
}
