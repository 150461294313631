@media (min-width: 1920px) {
  .UpdateInterests {
    padding-top:  33.33333333333333px;
    margin-top:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateInterests {
    padding-top:  1.7361111111111112vw;
    margin-top:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateInterests {
    padding-top:  2.0161290322580645vw;
    margin-top:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .UpdateInterests {
    padding-top:  5.3125vw;
    margin-top:  12.5vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .UpdateInterests-title {
    font-size:  24px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .UpdateInterests-title {
    font-size:  1.25vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .UpdateInterests-title {
    font-size:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .UpdateInterests-title {
    font-size:  5.625vw;
  }
}
.UpdateInterests-description {
  color: #6F7380;
}
@media (min-width: 1920px) {
  .UpdateInterests-description {
    margin-top:  18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateInterests-description {
    margin-top:  0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateInterests-description {
    margin-top:  1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .UpdateInterests-description {
    margin-top:  5vw;
  }
}
.UpdateInterests-fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .UpdateInterests-fields {
    margin-top:  73.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateInterests-fields {
    margin-top:  3.8194444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateInterests-fields {
    margin-top:  4.435483870967742vw;
  }
}
@media (max-width: 759px) {
  .UpdateInterests-fields {
    margin-top:  4.6875vw;
  }
}
.UpdateInterests-field {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
          flex: 0 0 33.33333333%;
  position: relative;
}
@media (max-width: 759px) {
  .UpdateInterests-field {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
@media (min-width: 1920px) {
  .UpdateInterests-field {
    margin-top:  26.666666666666664px;
    padding-bottom:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .UpdateInterests-field {
    margin-top:  1.3888888888888888vw;
    padding-bottom:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .UpdateInterests-field {
    margin-top:  1.6129032258064515vw;
    padding-bottom:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .UpdateInterests-field {
    margin-top:  3.125vw;
    padding-bottom:  4.6875vw;
  }
}
.UpdateInterests-field .Checkbox-inputLabel {
  text-transform: uppercase;
}
.UpdateInterests-field:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #E7E7E7;
  bottom: 0;
  left: 0;
}
.UpdateInterests-field:nth-child(1),
.UpdateInterests-field:nth-child(2),
.UpdateInterests-field:nth-child(3) {
  margin-top: 0;
}
.UpdateInterests-field:nth-last-child(-n+3):after {
  display: none;
}
@media (max-width: 759px) {
  .UpdateInterests-field {
    border-bottom: 1px solid #E7E7E7;
  }
  .UpdateInterests-field:after {
    display: none;
  }
  .UpdateInterests-field:last-child {
    border-bottom: none;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .UpdateInterests-field:nth-child(n) {
    margin-top:  26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .UpdateInterests-field:nth-child(n) {
    margin-top:  1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .UpdateInterests-field:nth-child(n) {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .UpdateInterests-field:nth-child(n) {
    margin-top:  6.25vw;
  }
}
