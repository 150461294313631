.TopPageButtons {
  z-index: 2;
  position: absolute;
  top: 0;
}
@media (max-width: 759px) {
  .TopPageButtons {
    display: none;
  }
}
.TopPageButtons-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  letter-spacing: normal;
  position: relative;
}
@media (min-width: 1920px) {
  .TopPageButtons-wrapper {
    padding-top:  34.666666666666664px;
    margin-left:  44px;
    width:  200px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopPageButtons-wrapper {
    padding-top:  1.8055555555555556vw;
    margin-left:  2.291666666666667vw;
    width:  10.416666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopPageButtons-wrapper {
    padding-top:  2.096774193548387vw;
    margin-left:  2.661290322580645vw;
    width:  12.096774193548386vw;
  }
}
@media (max-width: 759px) {
  .TopPageButtons-wrapper {
    padding-top:  8.125vw;
    margin-left:  10.3125vw;
    width:  46.875vw;
  }
}
@media (max-width: 759px) {
  .TopPageButtons-wrapper {
    display: none;
  }
}
.TopPageButtons-wrapper button {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  cursor: pointer;
}
.TopPageButtons-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #111112;
  text-decoration: none;
}
.TopPageButtons-button:visited {
  color: #111112;
}
.isDesktop .TopPageButtons-button:hover .TopPageButtons-buttonIcon,
.isNotDesktop .TopPageButtons-button:active .TopPageButtons-buttonIcon {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.TopPageButtons-buttonIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #111112;
  border-radius: 100%;
  -webkit-transition: 0.15s -webkit-transform ease-out;
  transition: 0.15s -webkit-transform ease-out;
  transition: 0.15s transform ease-out;
  transition: 0.15s transform ease-out, 0.15s -webkit-transform ease-out;
}
@media (max-width: 759px) {
  .TopPageButtons-buttonIcon {
    width: 6px;
    height: 6px;
    margin-right: 3.125vw;
    margin-top: -3px;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopPageButtons-buttonIcon {
    width: 4px;
    height: 4px;
    margin-right: 0.80645161vw;
    margin-top: -3px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopPageButtons-buttonIcon {
    width: 6px;
    height: 6px;
    margin-right: 0.69444444vw;
    margin-top: -4px;
  }
}
@media (min-width: 1920px) {
  .TopPageButtons-buttonIcon {
    width: 10.66666667px;
    height: 10.66666667px;
    margin-right: 13.33333333px;
    margin-top: -4px;
  }
}
.TopPageButtons-shareDropdown {
  visibility: hidden;
  opacity: 1;
  position: absolute;
  top: 130%;
  width: 100%;
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #E7E7E7;
  -webkit-transition: 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
  transition: 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
  transition: 0.15s transform ease-in-out, 0.15s opacity ease-in-out;
  transition: 0.15s transform ease-in-out, 0.15s opacity ease-in-out, 0.15s -webkit-transform ease-in-out;
}
.TopPageButtons-shareDropdown.active {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 759px) {
  .TopPageButtons-shareDropdown {
    -webkit-transform: translate3d(0, -3.125vw, 0);
            transform: translate3d(0, -3.125vw, 0);
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopPageButtons-shareDropdown {
    -webkit-transform: translate3d(0, -0.80645161vw, 0);
            transform: translate3d(0, -0.80645161vw, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopPageButtons-shareDropdown {
    -webkit-transform: translate3d(0, -0.69444444vw, 0);
            transform: translate3d(0, -0.69444444vw, 0);
  }
}
@media (min-width: 1920px) {
  .TopPageButtons-shareDropdown {
    -webkit-transform: translate3d(0, -13.33333333px, 0);
            transform: translate3d(0, -13.33333333px, 0);
  }
}
.TopPageButtons-shareDropdownOption {
  border-bottom: 1px solid #E7E7E7;
  letter-spacing: normal;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  color: #111112;
  text-decoration: none;
}
.TopPageButtons-shareDropdownOption:visited {
  color: #111112;
}
.TopPageButtons-shareDropdownOption:last-of-type {
  border-bottom: none;
}
@media (max-width: 759px) {
  .TopPageButtons-shareDropdownOption {
    font-size: 3.75vw;
    padding: 3.125vw 4.6875vw;
    line-height: normal;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopPageButtons-shareDropdownOption {
    padding: 0.80645161vw 1.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopPageButtons-shareDropdownOption {
    padding: 0.69444444vw 1.04166667vw;
  }
}
@media (min-width: 1920px) {
  .TopPageButtons-shareDropdownOption {
    padding: 13.33333333px 20px;
  }
}
.isDesktop .TopPageButtons-shareDropdownOption:hover,
.isNotDesktop .TopPageButtons-shareDropdownOption:active {
  opacity: 0.7;
}
.CampusEventPopup .TopPageButtons-buttonsPrint,
.AwardsPopup .TopPageButtons-buttonsPrint,
.HistoryPopup .TopPageButtons-buttonsPrint {
  display: none;
}
