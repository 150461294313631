.GlobalPageTitle {
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
  text-decoration: none;
  will-change: transform;
  display: block;
  -webkit-animation: 0.8s globalPageTitleFadeIn ease-in;
          animation: 0.8s globalPageTitleFadeIn ease-in;
}
.GlobalPageTitle:visited {
  text-decoration: none;
  color: #FFFFFF;
}
.GlobalPageTitle.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@-webkit-keyframes globalPageTitleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes globalPageTitleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .GlobalPageTitle {
    margin-top: 41.875vw;
  }
}
.GlobalPageTitle.hide {
  opacity: 0;
  -webkit-animation: none;
          animation: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.GlobalPageTitle.withLink {
  cursor: pointer;
}
.isDesktop .GlobalPageTitle.withLink:hover,
.isNotDesktop .GlobalPageTitle.withLink:active {
  opacity: 0.7;
}
.isDesktop .GlobalPageTitle.withLink:hover .GlobalPageTitle-link,
.isNotDesktop .GlobalPageTitle.withLink:active .GlobalPageTitle-link {
  text-decoration: underline;
}
.isDesktop .GlobalPageTitle.withLink:hover .GlobalPageTitle-link:before,
.isNotDesktop .GlobalPageTitle.withLink:active .GlobalPageTitle-link:before {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
@media (max-width: 759px) {
  .isDesktop .GlobalPageTitle.withLink:hover .GlobalPageTitle-link:before,
  .isNotDesktop .GlobalPageTitle.withLink:active .GlobalPageTitle-link:before {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.GlobalPageTitle-title {
  color: #FFFFFF;
  display: block;
}
@media (min-width: 1920px) {
  .GlobalPageTitle-title {
    margin-top: 26.66666667px;
    max-width: 1146.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalPageTitle-title {
    margin-top: 1.38888889vw;
    max-width: 59.72222222vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalPageTitle-title {
    margin-top: 1.61290323vw;
    max-width: 69.35483871vw;
  }
}
.GlobalPageTitle-date {
  color: #FFFFFF;
  text-transform: uppercase;
  display: block;
}
.GlobalPageTitle-link {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}
@media (max-width: 759px) {
  .GlobalPageTitle-link {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalPageTitle-link {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalPageTitle-link {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .GlobalPageTitle-link {
    font-size: 18.66666667px;
  }
}
.isDesktop .GlobalPageTitle-link:hover,
.isNotDesktop .GlobalPageTitle-link:active {
  text-decoration: underline;
}
.isDesktop .GlobalPageTitle-link:hover:before,
.isNotDesktop .GlobalPageTitle-link:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .GlobalPageTitle-link:hover:before,
  .isNotDesktop .GlobalPageTitle-link:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .GlobalPageTitle-link {
    letter-spacing: 0;
  }
}
.GlobalPageTitle-link:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .GlobalPageTitle-link:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .GlobalPageTitle-link:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .GlobalPageTitle-link:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalPageTitle-link:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalPageTitle-link:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .GlobalPageTitle-link:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (min-width: 1920px) {
  .GlobalPageTitle-link {
    margin-top: 61.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalPageTitle-link {
    margin-top: 3.19444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalPageTitle-link {
    margin-top: 3.70967742vw;
  }
}
@media (max-width: 759px) {
  .GlobalPageTitle-link {
    margin-top: 7.8125vw;
  }
}
