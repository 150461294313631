@media (min-width: 1920px) {
  .PageCampusLanding-approach {
    padding:  0 186.66666666666666px;
    margin-top:  200px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCampusLanding-approach {
    padding:  0vw 9.722222222222223vw;
    margin-top:  10.416666666666668vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCampusLanding-approach {
    padding:  0vw 11.29032258064516vw;
    margin-top:  12.096774193548386vw;
  }
}
@media (max-width: 759px) {
  .PageCampusLanding-approach {
    padding:  0vw 6.25vw;
    margin-top:  31.25vw;
  }
}
@media (min-width: 1920px) {
  .PageCampusLanding-blockSection {
    margin-top:  213.33333333333331px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCampusLanding-blockSection {
    margin-top:  11.11111111111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCampusLanding-blockSection {
    margin-top:  12.903225806451612vw;
  }
}
@media (max-width: 759px) {
  .PageCampusLanding-blockSection {
    margin-top:  23.75vw;
  }
}
.PageCampusLanding .CareersEmployeeExperience {
  display: block;
  min-height: 0;
  margin-top: 0;
}
@media (min-width: 1920px) {
  .PageCampusLanding .CareersEmployeeExperience {
    padding-bottom:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageCampusLanding .CareersEmployeeExperience {
    padding-bottom:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageCampusLanding .CareersEmployeeExperience {
    padding-bottom:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageCampusLanding .CareersEmployeeExperience {
    padding-bottom:  18.75vw;
  }
}
@media (max-width: 759px) and (min-width: 1920px) {
  .PageCampusLanding-videos {
    margin-top:  26.666666666666664px;
  }
}
@media (max-width: 759px) and (min-width: 1240px) and (max-width: 1919px) {
  .PageCampusLanding-videos {
    margin-top:  1.3888888888888888vw;
  }
}
@media (max-width: 759px) and (min-width: 760px) and (max-width: 1239px) {
  .PageCampusLanding-videos {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageCampusLanding-videos {
    margin-top:  6.25vw;
  }
}
