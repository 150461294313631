.ProductFamilySections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}
.ProductFamilySections-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ProductFamilySections-sections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 759px) {
  .ProductFamilySections-sections {
    padding: 6.25vw 0px 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductFamilySections-sections {
    padding: 3.22580645vw 3.22580645vw 11.29032258vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductFamilySections-sections {
    padding: 2.77777778vw 2.77777778vw 9.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductFamilySections-sections {
    padding: 53.33333333px 53.33333333px 186.66666667px;
  }
}
.ProductFamilySections-zeroResults {
  color: #E7E7E7;
  width: 100%;
  text-align: center;
}
@media (max-width: 759px) {
  .ProductFamilySections-zeroResults {
    margin-top: 25vh;
  }
}
