@media (max-width: 759px) {
  .PageLayoutsPopup .Popup-close {
    background-color: #111112;
  }
  .PageLayoutsPopup .Popup-closeItem {
    background-color: #FFFFFF;
  }
}
.PageLayoutsPopup-content {
  background: #F6F6F6;
  width: 100%;
}
.PageLayoutsPopup-items {
  background: #FFFFFF;
}
@media (min-width: 1920px) {
  .PageLayoutsPopup-items {
    padding-bottom:  106.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageLayoutsPopup-items {
    padding-bottom:  5.555555555555555vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageLayoutsPopup-items {
    padding-bottom:  6.451612903225806vw;
  }
}
@media (max-width: 759px) {
  .PageLayoutsPopup-items {
    padding-bottom:  12.5vw;
  }
}
.PageLayoutsPopup-contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 759px) {
  .PageLayoutsPopup-footer {
    width: 87.5vw;
    margin: 0 auto;
    padding-top: unset;
    padding-bottom: unset;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageLayoutsPopup-footer {
    width: 61.29032258vw;
    margin: 0 auto;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageLayoutsPopup-footer {
    width: 52.77777778vw;
    margin: 0 auto;
  }
}
@media (min-width: 1920px) {
  .PageLayoutsPopup-footer {
    width: 1013.33333333px;
    margin: 0 auto;
  }
}
