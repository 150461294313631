.TopNav {
  position: fixed;
  width: 100vw;
  z-index: 1200;
}
@media (max-width: 759px) {
  .TopNav {
    top: 0;
  }
}
.TopNav.hide .TopNav-wrapper {
  -webkit-transform: translate3d(0, -110%, 0);
          transform: translate3d(0, -110%, 0);
  -webkit-transition: 0.3s background-color ease, 0.4s -webkit-transform;
  transition: 0.3s background-color ease, 0.4s -webkit-transform;
  transition: 0.3s background-color ease, 0.4s transform;
  transition: 0.3s background-color ease, 0.4s transform, 0.4s -webkit-transform;
}
.TopNav.hide .TopNav-search {
  opacity: 0;
}
.PageNotFound .TopNav .GlobalTopNav,
.PageGlobalIndex .TopNav .GlobalTopNav,
.PageSitemap .TopNav .GlobalTopNav,
.PageCampusLanding .TopNav .GlobalTopNav,
.SubscribePreferences .TopNav .GlobalTopNav {
  display: none;
}
.PopupShow .TopNav-search {
  display: none;
}
@media (max-width: 759px) {
  .PopupShow .TopNav-search {
    display: block;
  }
}
.PopupShow .TopNav.showBackground:after {
  opacity: 0;
  pointer-events: none;
}
.PopupShow .TopNav.showBackground .TopNav-menu {
  background-color: #111112;
}
.PopupShow .TopNav.showBackground .TopNav-menuItem {
  background-color: #FFFFFF;
}
.PageGlobalTemplate .TopNav:after,
.PageCompanyLanding .TopNav:after,
.PageNewsLanding .TopNav:after,
.PagePressLanding .TopNav:after,
.PageInvestorsLanding .TopNav:after,
.PageCareersLanding .TopNav:after,
.PageSustainabilityLanding .TopNav:after,
.PageFaq .TopNav:after {
  content: '';
  pointer-events: none;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #111112;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
@media (min-width: 1920px) {
  .PageGlobalTemplate .TopNav:after,
  .PageCompanyLanding .TopNav:after,
  .PageNewsLanding .TopNav:after,
  .PagePressLanding .TopNav:after,
  .PageInvestorsLanding .TopNav:after,
  .PageCareersLanding .TopNav:after,
  .PageSustainabilityLanding .TopNav:after,
  .PageFaq .TopNav:after {
    height:  113.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobalTemplate .TopNav:after,
  .PageCompanyLanding .TopNav:after,
  .PageNewsLanding .TopNav:after,
  .PagePressLanding .TopNav:after,
  .PageInvestorsLanding .TopNav:after,
  .PageCareersLanding .TopNav:after,
  .PageSustainabilityLanding .TopNav:after,
  .PageFaq .TopNav:after {
    height:  5.902777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobalTemplate .TopNav:after,
  .PageCompanyLanding .TopNav:after,
  .PageNewsLanding .TopNav:after,
  .PagePressLanding .TopNav:after,
  .PageInvestorsLanding .TopNav:after,
  .PageCareersLanding .TopNav:after,
  .PageSustainabilityLanding .TopNav:after,
  .PageFaq .TopNav:after {
    height:  6.854838709677419vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate .TopNav:after,
  .PageCompanyLanding .TopNav:after,
  .PageNewsLanding .TopNav:after,
  .PagePressLanding .TopNav:after,
  .PageInvestorsLanding .TopNav:after,
  .PageCareersLanding .TopNav:after,
  .PageSustainabilityLanding .TopNav:after,
  .PageFaq .TopNav:after {
    height:  26.5625vw;
  }
}
@media (max-width: 759px) {
  .PageGlobalTemplate .TopNav:after,
  .PageCompanyLanding .TopNav:after,
  .PageNewsLanding .TopNav:after,
  .PagePressLanding .TopNav:after,
  .PageInvestorsLanding .TopNav:after,
  .PageCareersLanding .TopNav:after,
  .PageSustainabilityLanding .TopNav:after,
  .PageFaq .TopNav:after {
    display: none;
  }
}
.TopNav.showBackground:after {
  opacity: 1;
  pointer-events: all;
}
.OverlayShow .TopNav-wrapper {
  z-index: 1400;
}
.TopNav.TopNav--search-opened .TopNav-search {
  opacity: 0;
  pointer-events: none;
}
.TopNav.TopNav--search-opened .TopNav-search-close {
  opacity: 1;
  pointer-events: all;
}
.TopNav-search-label {
  text-transform: inherit;
}
.TopNav.TopNav--searchResults-opened .TopNav-search {
  opacity: 0;
  pointer-events: none;
}
.TopNav.TopNav--searchResults-opened .TopNav-search-close {
  opacity: 1;
}
.TopNav.TopNav--menu-opened .TopNav-logo,
.TopNav.TopNav--menu-opened .TopNav-separator,
.TopNav.TopNav--menu-opened .TopNav-search,
.TopNav.TopNav--menu-opened .TopNav-search-close {
  opacity: 0;
  pointer-events: none;
}
.TopNav.TopNav--menu-opened.showBackground:after {
  display: none;
}
@media (max-width: 759px) {
  .TopNav.TopNav--menu-opened .TopNav-wrapper {
    background-color: transparent !important;
  }
}
@media (max-width: 759px) {
  .TopNav.TopNav--menu-opened .TopNav-logo {
    background-color: #111112 !important;
  }
}
.TopNav.TopNav--menu-opened .TopNav-menu {
  background-color: transparent !important;
}
.TopNav.TopNav--menu-opened .TopNav-menuItem {
  top: 50%;
  background-color: #FFFFFF !important;
}
.isDesktop .TopNav.TopNav--menu-opened .TopNav-menuItem {
  -webkit-transition: 0.15s top ease-out, 0.15s width ease-out, 0.15s -webkit-transform 0.15s ease-out;
  transition: 0.15s top ease-out, 0.15s width ease-out, 0.15s -webkit-transform 0.15s ease-out;
  transition: 0.15s top ease-out, 0.15s width ease-out, 0.15s transform 0.15s ease-out;
  transition: 0.15s top ease-out, 0.15s width ease-out, 0.15s transform 0.15s ease-out, 0.15s -webkit-transform 0.15s ease-out;
}
.TopNav.TopNav--menu-opened .TopNav-menuItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.TopNav.TopNav--menu-opened .TopNav-menuItem:nth-child(2) {
  opacity: 0;
}
.TopNav.TopNav--menu-opened .TopNav-menuItem:nth-child(3) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
@media (min-width: 1920px) {
  .isDesktop .TopNav.TopNav--menu-opened .TopNav-menu:hover .TopNav-menuItem,
  .isNotDesktop .TopNav.TopNav--menu-opened .TopNav-menu:active .TopNav-menuItem {
    width:  29.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .TopNav.TopNav--menu-opened .TopNav-menu:hover .TopNav-menuItem,
  .isNotDesktop .TopNav.TopNav--menu-opened .TopNav-menu:active .TopNav-menuItem {
    width:  1.527777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .TopNav.TopNav--menu-opened .TopNav-menu:hover .TopNav-menuItem,
  .isNotDesktop .TopNav.TopNav--menu-opened .TopNav-menu:active .TopNav-menuItem {
    width:  1.7741935483870968vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .TopNav.TopNav--menu-opened .TopNav-menu:hover .TopNav-menuItem,
  .isNotDesktop .TopNav.TopNav--menu-opened .TopNav-menu:active .TopNav-menuItem {
    width:  6.875vw;
  }
}
.TopNav-wrapper {
  position: absolute;
  z-index: 1200;
}
@media (min-width: 1920px) {
  .TopNav-wrapper {
    top:  26.666666666666664px;
    left:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-wrapper {
    top:  1.3888888888888888vw;
    left:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-wrapper {
    top:  1.6129032258064515vw;
    left:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .TopNav-wrapper {
    top:  6.25vw;
    left:  6.25vw;
  }
}
@media (max-width: 759px) {
  .TopNav-wrapper {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    -webkit-transition: 0.3s background-color ease, 0.15s -webkit-transform;
    transition: 0.3s background-color ease, 0.15s -webkit-transform;
    transition: 0.3s background-color ease, 0.15s transform;
    transition: 0.3s background-color ease, 0.15s transform, 0.15s -webkit-transform;
    border-bottom: 1px solid #E7E7E7;
  }
  .brand--zumtobel .TopNav-wrapper,
  .PageGlobal .TopNav-wrapper {
    background-color: #111112;
    border-bottom: none;
  }
  .PageCampusLanding .TopNav-wrapper {
    background: transparent;
  }
  .brand--thorn .TopNav-wrapper {
    background-color: #ed1b2d;
    border-bottom: none;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageNotFound .TopNav-wrapper,
  .SubscribePreferences .TopNav-wrapper,
  .PageGlobalIndex .TopNav-wrapper,
  .PageCompanyLanding .TopNav-wrapper,
  .PagePressLanding .TopNav-wrapper,
  .PageInvestorsLanding .TopNav-wrapper,
  .PageNewsLanding .TopNav-wrapper,
  .PageGlobalTemplate .TopNav-wrapper,
  .PageCareersLanding .TopNav-wrapper,
  .PageSustainabilityLanding .TopNav-wrapper,
  .PageFaq .TopNav-wrapper {
    background-color: #FFFFFF;
  }
}
@media (max-width: 759px) and (max-width: 759px) {
  .PageCampusLanding.PopupShow .TopNav-wrapper {
    background: #111112;
  }
}
.TopNav-menu {
  position: relative;
  cursor: pointer;
  -webkit-transition: 0.3s background-color ease;
  transition: 0.3s background-color ease;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .TopNav-menu {
    width:  53.33333333333333px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-menu {
    width:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-menu {
    width:  3.225806451612903vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .TopNav-menu {
    width:  12.5vw;
    height:  12.5vw;
  }
}
@media (max-width: 759px) {
  .TopNav-menu {
    height: 12.5vw;
  }
}
.brand--zumtobel .TopNav-menu,
.PageGlobal .TopNav-menu {
  background-color: #111112;
}
@media (max-width: 759px) {
  .PageNotFound .TopNav-menu,
  .SubscribePreferences .TopNav-menu,
  .PageGlobalIndex .TopNav-menu,
  .PageCompanyLanding .TopNav-menu,
  .PagePressLanding .TopNav-menu,
  .PageInvestorsLanding .TopNav-menu,
  .PageNewsLanding .TopNav-menu,
  .PageGlobalTemplate .TopNav-menu,
  .PageCareersLanding .TopNav-menu,
  .PageSustainabilityLanding .TopNav-menu,
  .PageFaq .TopNav-menu {
    background-color: #FFFFFF;
  }
}
@media (max-width: 759px) {
  .PageCampusLanding .TopNav-menu {
    background: transparent;
  }
}
@media (max-width: 759px) {
  .PageCampusLanding.PopupShow .TopNav-menu {
    background: #111112;
  }
}
.brand--thorn .TopNav-menu {
  background-color: #ed1b2d;
}
@media (min-width: 1920px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(1),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(1) {
    top:  14.666666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(1),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(1) {
    top:  0.763888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(1),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(1) {
    top:  0.8870967741935484vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(1),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(1) {
    top:  3.4375vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(3),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(3) {
    top:  36px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(3),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(3) {
    top:  1.875vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(3),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(3) {
    top:  2.1774193548387095vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:hover .TopNav-menuItem:nth-child(3),
  .isNotDesktop .TopNav:not(.TopNav--menu-opened) .TopNav-menu:active .TopNav-menuItem:nth-child(3) {
    top:  8.4375vw;
  }
}
.PageGlobalTemplate .showBackground .TopNav-menu,
.PageCompanyLanding .showBackground .TopNav-menu,
.PagePressLanding .showBackground .TopNav-menu,
.PageInvestorsLanding .showBackground .TopNav-menu,
.PageNewsLanding .showBackground .TopNav-menu,
.PageCareersLanding .showBackground .TopNav-menu,
.PageSustainabilityLanding .showBackground .TopNav-menu,
.PageFaq .showBackground .TopNav-menu {
  background-color: #FFFFFF;
}
.TopNav-menuItem {
  height: 3px;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
@media (min-width: 1920px) {
  .TopNav-menuItem {
    width:  24px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-menuItem {
    width:  1.25vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-menuItem {
    width:  1.4516129032258065vw;
  }
}
@media (max-width: 759px) {
  .TopNav-menuItem {
    width:  5.625vw;
  }
}
.brand--zumtobel .TopNav-menuItem,
.PageGlobal .TopNav-menuItem {
  background-color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageNotFound .TopNav-menuItem,
  .SubscribePreferences .TopNav-menuItem,
  .PageGlobalIndex .TopNav-menuItem,
  .PageCompanyLanding .TopNav-menuItem,
  .PagePressLanding .TopNav-menuItem,
  .PageInvestorsLanding .TopNav-menuItem,
  .PageNewsLanding .TopNav-menuItem,
  .PageGlobalTemplate .TopNav-menuItem,
  .PageCareersLanding .TopNav-menuItem,
  .PageSustainabilityLanding .TopNav-menuItem,
  .PageFaq .TopNav-menuItem {
    background-color: #111112;
  }
}
.brand--thorn .TopNav-menuItem {
  background-color: #052137;
}
.isDesktop .TopNav-menuItem {
  -webkit-transition: 0.15s top ease-out;
  transition: 0.15s top ease-out;
}
@media (min-width: 1920px) {
  .TopNav-menuItem:nth-child(1) {
    top:  17.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-menuItem:nth-child(1) {
    top:  0.9027777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-menuItem:nth-child(1) {
    top:  1.0483870967741935vw;
  }
}
@media (max-width: 759px) {
  .TopNav-menuItem:nth-child(1) {
    top:  4.0625vw;
  }
}
@media (min-width: 1920px) {
  .TopNav-menuItem:nth-child(2) {
    top:  25.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-menuItem:nth-child(2) {
    top:  1.3194444444444444vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-menuItem:nth-child(2) {
    top:  1.532258064516129vw;
  }
}
@media (max-width: 759px) {
  .TopNav-menuItem:nth-child(2) {
    top:  5.9375vw;
  }
}
@media (min-width: 1920px) {
  .TopNav-menuItem:nth-child(3) {
    top:  33.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-menuItem:nth-child(3) {
    top:  1.7361111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-menuItem:nth-child(3) {
    top:  2.0161290322580645vw;
  }
}
@media (max-width: 759px) {
  .TopNav-menuItem:nth-child(3) {
    top:  7.8125vw;
  }
}
.PageGlobalTemplate .showBackground .TopNav-menuItem,
.PageCompanyLanding .showBackground .TopNav-menuItem,
.PagePressLanding .showBackground .TopNav-menuItem,
.PageInvestorsLanding .showBackground .TopNav-menuItem,
.PageNewsLanding .showBackground .TopNav-menuItem,
.PageCareersLanding .showBackground .TopNav-menuItem,
.PageSustainabilityLanding .showBackground .TopNav-menuItem,
.PageFaq .showBackground .TopNav-menuItem {
  background-color: #111112;
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
}
.TopNav-logo {
  position: absolute;
  top: 0;
  -webkit-transition: background-color 0.3s ease, opacity 0.3s ease;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}
@media (min-width: 1920px) {
  .TopNav-logo {
    left:  54.666666666666664px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-logo {
    left:  2.8472222222222223vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-logo {
    left:  3.3064516129032255vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .TopNav-logo {
    left:  12.8125vw;
    height:  12.5vw;
  }
}
@media (max-width: 759px) {
  .TopNav-logo {
    width: 43.4375vw;
  }
  .PageGlobal .TopNav-logo {
    width: 55.9375vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-logo {
    width: 11.20967742vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-logo {
    width: 9.65277778vw;
  }
}
@media (min-width: 1920px) {
  .TopNav-logo {
    width: 185.33333333px;
  }
}
.brand--zumtobel .TopNav-logo {
  background-color: #111112;
}
.brand--thorn .TopNav-logo {
  background-color: #ed1b2d;
}
.PageCampusLanding .TopNav-logo {
  display: none;
}
.TopNav-logo:after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.brand--zumtobel .TopNav-logo:after {
  background-image: url('/static/img/logo-zumtobel.png');
}
@media (min-width: 1920px) {
  .brand--zumtobel .TopNav-logo:after {
    left:  25.333333333333332px;
    top:  16px;
    width:  132px;
    height:  21.333333333333332px;
    background-size:  132px 21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--zumtobel .TopNav-logo:after {
    left:  1.3194444444444444vw;
    top:  0.8333333333333334vw;
    width:  6.875vw;
    height:  1.1111111111111112vw;
    background-size:  6.875vw 1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--zumtobel .TopNav-logo:after {
    left:  1.532258064516129vw;
    top:  0.967741935483871vw;
    width:  7.983870967741935vw;
    height:  1.2903225806451613vw;
    background-size:  7.983870967741935vw 1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .brand--zumtobel .TopNav-logo:after {
    left:  5.9375vw;
    top:  3.75vw;
    width:  30.9375vw;
    height:  5vw;
    background-size:  30.9375vw 5vw;
  }
}
.PageGlobal .TopNav-logo:after {
  background-image: url('/static/svg/zumtobel-group-logo.svg');
}
@media (min-width: 1920px) {
  .PageGlobal .TopNav-logo:after {
    left:  22.666666666666664px;
    top:  20px;
    width:  194.66666666666666px;
    height:  18.666666666666664px;
    background-size:  194.66666666666666px 18.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .PageGlobal .TopNav-logo:after {
    left:  1.1805555555555556vw;
    top:  1.0416666666666667vw;
    width:  10.13888888888889vw;
    height:  0.9722222222222223vw;
    background-size:  10.13888888888889vw 0.9722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .PageGlobal .TopNav-logo:after {
    left:  1.3709677419354838vw;
    top:  1.2096774193548387vw;
    width:  11.774193548387096vw;
    height:  1.2903225806451613vw;
    background-size:  11.774193548387096vw 1.129032258064516vw;
  }
}
@media (max-width: 759px) {
  .PageGlobal .TopNav-logo:after {
    left:  11.875vw;
    top:  4.375vw;
    width:  45.625vw;
    height:  4.375vw;
    background-size:  32.8125vw 4.375vw;
  }
}
.PageNotFound .TopNav-logo:after,
.SubscribePreferences .TopNav-logo:after,
.PageGlobalIndex .TopNav-logo:after,
.PageSitemap .TopNav-logo:after {
  background-image: url('/static/svg/logo-zum-group-dark.svg');
}
@media (max-width: 759px) {
  .PageSitemap .TopNav-logo:after {
    background-image: url('/static/svg/zumtobel-group-logo.svg');
  }
}
@media (max-width: 759px) {
  .PageCompanyLanding .TopNav-logo:after,
  .PagePressLanding .TopNav-logo:after,
  .PageInvestorsLanding .TopNav-logo:after,
  .PageNewsLanding .TopNav-logo:after,
  .PageGlobalTemplate .TopNav-logo:after,
  .PageCareersLanding .TopNav-logo:after,
  .PageSustainabilityLanding .TopNav-logo:after,
  .PageFaq .TopNav-logo:after {
    background-image: url('/static/svg/logo-zum-group-dark.svg');
  }
}
.brand--thorn .TopNav-logo:after {
  background-image: url('/static/svg/logo-thorn-nav.svg');
}
@media (min-width: 1920px) {
  .brand--thorn .TopNav-logo:after {
    left:  38.666666666666664px;
    top:  16px;
    width:  109.33333333333333px;
    height:  21.333333333333332px;
    background-size:  109.33333333333333px 21.333333333333332px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .brand--thorn .TopNav-logo:after {
    left:  2.013888888888889vw;
    top:  0.8333333333333334vw;
    width:  5.694444444444445vw;
    height:  1.1111111111111112vw;
    background-size:  5.694444444444445vw 1.1111111111111112vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .brand--thorn .TopNav-logo:after {
    left:  2.338709677419355vw;
    top:  0.967741935483871vw;
    width:  6.612903225806451vw;
    height:  1.2903225806451613vw;
    background-size:  6.612903225806451vw 1.2903225806451613vw;
  }
}
@media (max-width: 759px) {
  .brand--thorn .TopNav-logo:after {
    left:  9.0625vw;
    top:  3.75vw;
    width:  25.625vw;
    height:  5vw;
    background-size:  25.625vw 5vw;
  }
}
.PageGlobalTemplate .TopNav-logo.onWhiteBackground:after {
  background-image: url('/static/svg/logo-zum-group-dark.svg');
}
.showBackground .TopNav-logo.onWhiteBackground:after {
  background-image: url('/static/svg/zumtobel-group-logo.svg');
}
@media (max-width: 759px) {
  .TopNav-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.brand--zumtobel.isDesktop .TopNav-logo:hover,
.brand--zumtobel.isNotDesktop .TopNav-logo:active {
  background-color: #454647;
}
.brand--thorn.isDesktop .TopNav-logo:hover,
.brand--thorn.isNotDesktop .TopNav-logo:active {
  background-color: #ec5865;
}
.TopNav-separator {
  position: absolute;
  top: 0;
  width: 1px;
  background-color: transparent;
}
@media (min-width: 1920px) {
  .TopNav-separator {
    left:  53.33333333333333px;
    height:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-separator {
    left:  2.7777777777777777vw;
    height:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-separator {
    left:  3.225806451612903vw;
    height:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .TopNav-separator {
    left:  12.5vw;
    height:  12.5vw;
  }
}
@media (max-width: 759px) {
  .TopNav-separator {
    display: none;
  }
}
.TopNav-search {
  position: absolute;
  z-index: 1200;
  cursor: pointer;
  -webkit-transition: 0.15s opacity ease-out;
  transition: 0.15s opacity ease-out;
}
@media (min-width: 1920px) {
  .TopNav-search {
    top:  33.33333333333333px;
    right:  53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-search {
    top:  1.7361111111111112vw;
    right:  2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-search {
    top:  2.0161290322580645vw;
    right:  3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search {
    top:  2.8125vw;
    right:  1.25vw;
  }
}
.isDesktop .TopNav-search:hover .TopNav-search-label,
.isNotDesktop .TopNav-search:active .TopNav-search-label {
  text-decoration: underline;
}
.TopNav-search-icon {
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url('/static/svg/icon-search.svg');
  will-change: transform;
}
@media (min-width: 1920px) {
  .TopNav-search-icon {
    margin-right:  8px;
    width:  37.33333333333333px;
    height:  37.33333333333333px;
    background-size:  37.33333333333333px 37.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-search-icon {
    margin-right:  0.4166666666666667vw;
    width:  1.9444444444444446vw;
    height:  1.9444444444444446vw;
    background-size:  1.9444444444444446vw 1.9444444444444446vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-search-icon {
    margin-right:  0.4838709677419355vw;
    width:  2.258064516129032vw;
    height:  2.258064516129032vw;
    background-size:  2.258064516129032vw 2.258064516129032vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search-icon {
    margin-right:  1.875vw;
    width:  8.75vw;
    height:  8.75vw;
    background-size:  8.75vw 8.75vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search-icon {
    margin-right: 1.875vw;
    width: 6.875vw;
    height: 6.875vw;
    background-size: 6.875vw 6.875vw;
  }
  .brand--thorn .TopNav-search-icon {
    background-image: url('/static/svg/icon-search-thorn.svg');
  }
  .PageNotFound .TopNav-search-icon,
  .SubscribePreferences .TopNav-search-icon,
  .PageGlobalIndex .TopNav-search-icon,
  .PageCompanyLanding .TopNav-search-icon,
  .PagePressLanding .TopNav-search-icon,
  .PageInvestorsLanding .TopNav-search-icon,
  .PageNewsLanding .TopNav-search-icon,
  .PageGlobalTemplate .TopNav-search-icon,
  .PageSitemap .TopNav-search-icon,
  .PageCareersLanding .TopNav-search-icon,
  .PageSustainabilityLanding .TopNav-search-icon,
  .PageFaq .TopNav-search-icon {
    background-image: url('/static/svg/icon-search-dark.svg');
  }
}
.PageProducts .TopNav-search-icon,
.PageInspiration .TopNav-search-icon,
.SubscribePreferences .TopNav-search-icon,
.PageSitemap .TopNav-search-icon {
  background-image: url('/static/svg/icon-search-dark.svg');
}
.brand--thorn .PageProducts .TopNav-search-icon,
.brand--thorn .PageInspiration .TopNav-search-icon,
.brand--thorn .SubscribePreferences .TopNav-search-icon,
.brand--thorn .PageSitemap .TopNav-search-icon {
  background-image: url('/static/svg/icon-search.svg');
}
@media (max-width: 759px) {
  .PageProducts .TopNav-search-icon,
  .PageInspiration .TopNav-search-icon,
  .SubscribePreferences .TopNav-search-icon,
  .PageSitemap .TopNav-search-icon {
    background-image: url('/static/svg/icon-search.svg');
  }
  .brand--thorn .PageProducts .TopNav-search-icon,
  .brand--thorn .PageInspiration .TopNav-search-icon,
  .brand--thorn .SubscribePreferences .TopNav-search-icon,
  .brand--thorn .PageSitemap .TopNav-search-icon {
    background-image: url('/static/svg/icon-search-thorn.svg');
  }
}
.PageProductFamily .TopNav-search-icon {
  background-image: url('/static/svg/icon-search.svg');
}
@media (max-width: 759px) {
  .PageProductFamily .TopNav-search-icon {
    background-image: url('/static/svg/icon-search.svg');
  }
  .brand--thorn .PageProductFamily .TopNav-search-icon {
    background-image: url('/static/svg/icon-search-thorn.svg');
  }
}
.PageProductFamily.isDark .TopNav-search-icon {
  background-image: url('/static/svg/icon-search-dark.svg');
}
@media (max-width: 759px) {
  .PageProductFamily.isDark .TopNav-search-icon {
    background-image: url('/static/svg/icon-search.svg');
  }
  .brand--thorn .PageProductFamily.isDark .TopNav-search-icon {
    background-image: url('/static/svg/icon-search-thorn.svg');
  }
}
.isDesktop .TopNav-search-icon {
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
.PageGlobalTemplate .TopNav-search-icon.onWhiteBackground {
  background-image: url('/static/svg/icon-search-dark.svg');
}
.showBackground .TopNav-search-icon.onWhiteBackground {
  background-image: url('/static/svg/icon-search.svg');
}
.TopNav-search-label {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #FFFFFF;
}
.PageProducts .TopNav-search-label,
.PageInspiration .TopNav-search-label,
.SubscribePreferences .TopNav-search-label,
.PageSitemap .TopNav-search-label {
  color: #111112;
}
.brand--thorn .PageProducts .TopNav-search-label,
.brand--thorn .PageInspiration .TopNav-search-label,
.brand--thorn .SubscribePreferences .TopNav-search-label,
.brand--thorn .PageSitemap .TopNav-search-label {
  color: #FFFFFF;
}
.PageProductFamily.isDark .TopNav-search-label {
  color: #111112;
}
@media (max-width: 759px) {
  .PageProductFamily.isDark .TopNav-search-label {
    color: #FFFFFF;
  }
  .brand--thorn .PageProductFamily.isDark .TopNav-search-label {
    color: #111112;
  }
}
.PageProductFamily .TopNav-search-label {
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .PageProductFamily .TopNav-search-label {
    color: #FFFFFF;
  }
  .brand--thorn .PageProductFamily .TopNav-search-label {
    color: #111112;
  }
}
.PageGlobalTemplate .TopNav-search-label.onWhiteBackground {
  color: #111112;
}
.showBackground .TopNav-search-label.onWhiteBackground {
  color: #FFFFFF;
}
@media (min-width: 1920px) {
  .TopNav-search-label {
    top:  2.6666666666666665px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-search-label {
    top:  0.1388888888888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-search-label {
    top:  0.16129032258064516vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search-label {
    top:  0.625vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search-label {
    display: none;
  }
  .brand--thorn .TopNav-search-label {
    color: #111112;
  }
}
.TopNav-search-close {
  display: none;
  position: fixed;
  cursor: pointer;
  z-index: 1200;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  opacity: 0;
  pointer-events: none;
}
.TopNav-search-close:before {
  content: '';
  display: block;
  position: absolute;
}
@media (max-width: 759px) {
  .TopNav-search-close:before {
    left: -3.125vw;
    right: -3.125vw;
    top: -3.125vw;
    bottom: -3.125vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search-close {
    display: block;
    width: 5vw;
    height: 5vw;
    right: 3.75vw;
    top: 3.75vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .TopNav-search-close:hover .TopNav-search-closeItem,
  .isNotDesktop .TopNav-search-close:active .TopNav-search-closeItem {
    width:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .TopNav-search-close:hover .TopNav-search-closeItem,
  .isNotDesktop .TopNav-search-close:active .TopNav-search-closeItem {
    width:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .TopNav-search-close:hover .TopNav-search-closeItem,
  .isNotDesktop .TopNav-search-close:active .TopNav-search-closeItem {
    width:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .TopNav-search-close:hover .TopNav-search-closeItem,
  .isNotDesktop .TopNav-search-close:active .TopNav-search-closeItem {
    width:  7.5vw;
  }
}
.TopNav-search-closeItem {
  position: absolute;
  height: 2px;
  top: 50%;
  left: 50%;
}
@media (min-width: 1920px) {
  .TopNav-search-closeItem {
    width:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-search-closeItem {
    width:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-search-closeItem {
    width:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .TopNav-search-closeItem {
    width:  6.25vw;
  }
}
.isDesktop .TopNav-search-closeItem {
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.brand--zumtobel .TopNav-search-closeItem {
  background-color: #FFFFFF;
}
.brand--thorn .TopNav-search-closeItem {
  background-color: #111112;
}
.brand--global .TopNav-search-closeItem {
  background-color: #111112;
}
.TopNav-search-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.TopNav-search-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
.TopNav-searchResults-close {
  position: fixed;
  cursor: pointer;
  z-index: 1200;
  -webkit-transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  transition: 0.15s opacity ease-out, 0s visibility 0.15s ease-out;
  opacity: 0;
  pointer-events: none;
}
.TopNav-searchResults-close.active {
  opacity: 1;
  pointer-events: all;
}
.TopNav-searchResults-close:before {
  content: '';
  display: block;
  position: absolute;
}
@media (max-width: 759px) {
  .TopNav-searchResults-close:before {
    left: -3.125vw;
    right: -3.125vw;
    top: -3.125vw;
    bottom: -3.125vw;
  }
}
@media (max-width: 759px) {
  .TopNav-searchResults-close {
    display: block;
    width: 5vw;
    height: 5vw;
    right: 3.75vw;
    top: 3.75vw;
  }
}
@media (min-width: 1920px) {
  .isDesktop .TopNav-searchResults-close:hover .TopNav-searchResults-closeItem,
  .isNotDesktop .TopNav-searchResults-close:active .TopNav-searchResults-closeItem {
    width:  32px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .isDesktop .TopNav-searchResults-close:hover .TopNav-searchResults-closeItem,
  .isNotDesktop .TopNav-searchResults-close:active .TopNav-searchResults-closeItem {
    width:  1.6666666666666667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .isDesktop .TopNav-searchResults-close:hover .TopNav-searchResults-closeItem,
  .isNotDesktop .TopNav-searchResults-close:active .TopNav-searchResults-closeItem {
    width:  1.935483870967742vw;
  }
}
@media (max-width: 759px) {
  .isDesktop .TopNav-searchResults-close:hover .TopNav-searchResults-closeItem,
  .isNotDesktop .TopNav-searchResults-close:active .TopNav-searchResults-closeItem {
    width:  7.5vw;
  }
}
.TopNav-searchResults-closeItem {
  position: absolute;
  height: 2px;
  top: 50%;
  left: 50%;
}
@media (min-width: 1920px) {
  .TopNav-searchResults-closeItem {
    width:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .TopNav-searchResults-closeItem {
    width:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .TopNav-searchResults-closeItem {
    width:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .TopNav-searchResults-closeItem {
    width:  6.25vw;
  }
}
.isDesktop .TopNav-searchResults-closeItem {
  -webkit-transition: 0.15s width ease-out;
  transition: 0.15s width ease-out;
}
.brand--zumtobel .TopNav-searchResults-closeItem {
  background-color: #FFFFFF;
}
.brand--thorn .TopNav-searchResults-closeItem {
  background-color: #111112;
}
.TopNav-searchResults-closeItem:nth-child(1) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.TopNav-searchResults-closeItem:nth-child(2) {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
