.GlobalIndexSidebar {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  margin-left: auto;
  top: 0;
  bottom: 0;
  background-color: #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 23.6%;
          flex: 0 0 23.6%;
  pointer-events: all;
}
.isIE .GlobalIndexSidebar {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  bottom: auto;
}
.isIE .GlobalIndexSidebar.js-is-stuck {
  bottom: 0;
  top: auto;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar {
    padding-top: 106.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar {
    padding-top: 5.55555556vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar {
    padding-top: 6.4516129vw;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
  }
  .isIE .GlobalIndexSidebar {
    width: 27.41935484vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar {
    position: relative;
    margin-top: 70vh;
    height: auto;
  }
}
.GlobalIndexSidebar-item {
  -webkit-transition: 0.15s background-color;
  transition: 0.15s background-color;
  border-top: 1px solid #29292A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  text-decoration: none;
}
.GlobalIndexSidebar-item:last-of-type {
  border-bottom: 1px solid #29292A;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-item {
    padding:  13.333333333333332px 53.33333333333333px;
    min-height:  186.66666666666666px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-item {
    padding:  0.6944444444444444vw 2.7777777777777777vw;
    min-height:  9.722222222222223vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-item {
    padding:  1.6129032258064515vw 3.225806451612903vw;
    min-height:  11.29032258064516vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item {
    padding:  6.25vw 12.5vw 6.25vw 6.25vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item {
    position: relative;
    display: block;
    min-height: auto;
  }
}
.GlobalIndexSidebar-itemLogo {
  pointer-events: none;
  position: relative;
}
.GlobalIndexSidebar-itemLogoImg {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  width: 100%;
  height: 100%;
}
.GlobalIndexSidebar-itemLogoImg_hover {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  opacity: 0;
  width: 100%;
  height: 100%;
}
.GlobalIndexSidebar-itemText {
  color: #6F7380;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-itemText {
    margin-top: 26.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-itemText {
    margin-top: 1.38888889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-itemText {
    margin-top: 1.61290323vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-itemText {
    margin-top: 4.6875vw;
  }
}
.GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogo {
  position: relative;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogo {
    width: 181.33333333px;
    height: 40px;
    margin-left: -8.66666667px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogo {
    width: 9.44444444vw;
    height: 2.08333333vw;
    margin-left: -0.45138889vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogo {
    width: 10.96774194vw;
    height: 2.41935484vw;
    margin-left: -0.52419355vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogo {
    width: 42.5vw;
    height: 9.375vw;
    -webkit-transform: translate3d(0, -2.5vw, 0);
            transform: translate3d(0, -2.5vw, 0);
    margin-left: -2.03125vw;
  }
}
.GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogoRegular,
.GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogoHovered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogoRegular {
  opacity: 1;
}
.GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemLogoHovered {
  opacity: 0;
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-itemText {
    margin-top: 2.1875vw;
  }
}
.GlobalIndexSidebar-item:nth-child(1) .GlobalIndexSidebar-linkArrow {
  top: 3.4375vw;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-item:nth-child(2) .GlobalIndexSidebar-itemLogo {
    width: 109.33333333px;
    height: 21.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-item:nth-child(2) .GlobalIndexSidebar-itemLogo {
    width: 5.69444444vw;
    height: 1.11111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-item:nth-child(2) .GlobalIndexSidebar-itemLogo {
    width: 6.61290323vw;
    height: 1.29032258vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item:nth-child(2) .GlobalIndexSidebar-itemLogo {
    width: 25.625vw;
    height: 5vw;
  }
}
.GlobalIndexSidebar-item:nth-child(2) svg {
  fill: #ED1B2D;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-item:nth-child(3) .GlobalIndexSidebar-itemLogo {
    width: 133.33333333px;
    height: 20px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-item:nth-child(3) .GlobalIndexSidebar-itemLogo {
    width: 6.94444444vw;
    height: 1.04166667vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-item:nth-child(3) .GlobalIndexSidebar-itemLogo {
    width: 8.06451613vw;
    height: 1.20967742vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item:nth-child(3) .GlobalIndexSidebar-itemLogo {
    width: 31.25vw;
    height: 4.6875vw;
  }
}
.GlobalIndexSidebar-item:nth-child(3) svg {
  fill: #3A3284;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-item:nth-child(4) .GlobalIndexSidebar-itemLogo {
    width: 86.66666667px;
    height: 28px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-item:nth-child(4) .GlobalIndexSidebar-itemLogo {
    width: 4.51388889vw;
    height: 1.45833333vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-item:nth-child(4) .GlobalIndexSidebar-itemLogo {
    width: 5.24193548vw;
    height: 1.69354839vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item:nth-child(4) .GlobalIndexSidebar-itemLogo {
    width: 20.3125vw;
    height: 6.5625vw;
  }
}
.GlobalIndexSidebar-item:nth-child(4) .GlobalIndexSidebar-linkArrow {
  top: 3.75vw;
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-item:nth-child(5) .GlobalIndexSidebar-itemLogo {
    width: 177.33333333px;
    height: 21.33333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-item:nth-child(5) .GlobalIndexSidebar-itemLogo {
    width: 9.23611111vw;
    height: 1.11111111vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-item:nth-child(5) .GlobalIndexSidebar-itemLogo {
    width: 10.72580645vw;
    height: 1.29032258vw;
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-item:nth-child(5) .GlobalIndexSidebar-itemLogo {
    width: 41.5625vw;
    height: 5vw;
  }
}
.GlobalIndexSidebar-item:nth-child(5) .GlobalIndexSidebar-linkArrow {
  top: 2.1875vw;
}
.GlobalIndexSidebar-item:nth-child(5) svg {
  fill: #ED1B2D;
}
.isDesktop .GlobalIndexSidebar-item:hover .GlobalIndexSidebar-itemText,
.isNotDesktop .GlobalIndexSidebar-item:active .GlobalIndexSidebar-itemText {
  color: #FFFFFF;
}
.isDesktop .GlobalIndexSidebar-item:hover .GlobalIndexSidebar-itemLogoImg.withHoverImg,
.isNotDesktop .GlobalIndexSidebar-item:active .GlobalIndexSidebar-itemLogoImg.withHoverImg {
  opacity: 0;
}
.isDesktop .GlobalIndexSidebar-item:hover .GlobalIndexSidebar-itemLogoImg_hover,
.isNotDesktop .GlobalIndexSidebar-item:active .GlobalIndexSidebar-itemLogoImg_hover {
  opacity: 1;
}
.isDesktop .GlobalIndexSidebar-item:hover:nth-child(1),
.isNotDesktop .GlobalIndexSidebar-item:active:nth-child(1) {
  background-color: #FFFFFF;
}
.isDesktop .GlobalIndexSidebar-item:hover:nth-child(1) .GlobalIndexSidebar-itemText,
.isNotDesktop .GlobalIndexSidebar-item:active:nth-child(1) .GlobalIndexSidebar-itemText {
  color: #111112;
}
.isDesktop .GlobalIndexSidebar-item:hover:nth-child(2),
.isNotDesktop .GlobalIndexSidebar-item:active:nth-child(2) {
  background-color: #ED1B2D;
}
.isDesktop .GlobalIndexSidebar-item:hover:nth-child(3),
.isNotDesktop .GlobalIndexSidebar-item:active:nth-child(3) {
  background-color: #3A3284;
}
.isDesktop .GlobalIndexSidebar-item:hover:nth-child(4),
.isNotDesktop .GlobalIndexSidebar-item:active:nth-child(4) {
  background-color: #6CDFE4;
}
.isDesktop .GlobalIndexSidebar-item:hover:nth-child(5),
.isNotDesktop .GlobalIndexSidebar-item:active:nth-child(5) {
  background-color: #ED1B2D;
}
.GlobalIndexSidebar-linkArrow {
  font-family: 'neue-haas-unica', Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.42857143;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  position: absolute;
  top: 1.5625vw;
  right: 1.5625vw;
  display: none;
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-linkArrow {
    font-size: 3.4375vw;
    letter-spacing: -0.096875vw;
    line-height: 12.5vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-linkArrow {
    font-size: 1.12903226vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-linkArrow {
    font-size: 0.97222222vw;
  }
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-linkArrow {
    font-size: 18.66666667px;
  }
}
.isDesktop .GlobalIndexSidebar-linkArrow:hover,
.isNotDesktop .GlobalIndexSidebar-linkArrow:active {
  text-decoration: underline;
}
.isDesktop .GlobalIndexSidebar-linkArrow:hover:before,
.isNotDesktop .GlobalIndexSidebar-linkArrow:active:before {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media (max-width: 759px) {
  .isDesktop .GlobalIndexSidebar-linkArrow:hover:before,
  .isNotDesktop .GlobalIndexSidebar-linkArrow:active:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-linkArrow {
    letter-spacing: 0;
  }
}
.GlobalIndexSidebar-linkArrow:before {
  content: '';
  display: inline-block;
  will-change: transform;
  background: url('/static/img/arrow-big.svg') no-repeat;
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-linkArrow:before {
    -webkit-transform: translate3d(-1.25vw, 0, 0);
            transform: translate3d(-1.25vw, 0, 0);
  }
}
.isDesktop .GlobalIndexSidebar-linkArrow:before {
  -webkit-transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s opacity, 0.15s -webkit-transform;
  transition: 0.15s transform, 0.15s opacity;
  transition: 0.15s transform, 0.15s opacity, 0.15s -webkit-transform;
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-linkArrow:before {
    width: 8.125vw;
    height: 4.375vw;
    background-size: 8.125vw 4.375vw;
    vertical-align: -0.9375vw;
    margin-right: 1.5625vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .GlobalIndexSidebar-linkArrow:before {
    width: 2.82258065vw;
    height: 1.4516129vw;
    background-size: 2.82258065vw 1.4516129vw;
    vertical-align: -0.32258065vw;
    margin-right: 0.48387097vw;
    -webkit-transform: translate3d(-0.64516129vw, 0, 0);
            transform: translate3d(-0.64516129vw, 0, 0);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .GlobalIndexSidebar-linkArrow:before {
    width: 2.43055556vw;
    height: 1.25vw;
    background-size: 2.43055556vw 1.25vw;
    vertical-align: -0.27777778vw;
    margin-right: 0.41666667vw;
    -webkit-transform: translate3d(-0.55555556vw, 0, 0);
            transform: translate3d(-0.55555556vw, 0, 0);
  }
}
@media (min-width: 1920px) {
  .GlobalIndexSidebar-linkArrow:before {
    width: 46.66666667px;
    height: 24px;
    background-size: 46.66666667px 24px;
    vertical-align: -5.33333333px;
    margin-right: 8px;
    -webkit-transform: translate3d(-10.66666667px, 0, 0);
            transform: translate3d(-10.66666667px, 0, 0);
  }
}
@media (max-width: 759px) {
  .GlobalIndexSidebar-linkArrow {
    display: block;
  }
}
