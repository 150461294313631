.CompanyAnnualReportsList-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #E7E7E7;
  text-decoration: none;
  color: #111112;
}
.CompanyAnnualReportsList-item:visited {
  color: #111112;
}
@media (min-width: 1920px) {
  .CompanyAnnualReportsList-item {
    padding-bottom:  28px;
    padding-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyAnnualReportsList-item {
    padding-bottom:  1.4583333333333335vw;
    padding-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyAnnualReportsList-item {
    padding-bottom:  1.6935483870967742vw;
    padding-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-item {
    padding-bottom:  6.5625vw;
    padding-top:  6.25vw;
  }
}
.CompanyAnnualReportsList-item:first-of-type {
  padding-top: 0;
}
.isDesktop .CompanyAnnualReportsList-item:hover .CompanyAnnualReportsList-cover,
.isNotDesktop .CompanyAnnualReportsList-item:active .CompanyAnnualReportsList-cover,
.isDesktop .CompanyAnnualReportsList-item:hover .CompanyAnnualReportsList-coverPlaceholder,
.isNotDesktop .CompanyAnnualReportsList-item:active .CompanyAnnualReportsList-coverPlaceholder {
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
          transform: scale(1.1) translate3d(0, 0, 0);
}
.isDesktop .CompanyAnnualReportsList-item:hover .CompanyAnnualReportsList-title,
.isNotDesktop .CompanyAnnualReportsList-item:active .CompanyAnnualReportsList-title {
  text-decoration: underline;
}
.isDesktop .CompanyAnnualReportsList-item:hover .CompanyAnnualReportsList-years,
.isNotDesktop .CompanyAnnualReportsList-item:active .CompanyAnnualReportsList-years,
.isDesktop .CompanyAnnualReportsList-item:hover .CompanyAnnualReportsList-author,
.isNotDesktop .CompanyAnnualReportsList-item:active .CompanyAnnualReportsList-author {
  color: #111112;
}
.CompanyAnnualReportsList-cover {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  will-change: transform;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
}
@media (min-width: 1920px) {
  .CompanyAnnualReportsList-cover {
    height:  160px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyAnnualReportsList-cover {
    height:  8.333333333333334vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyAnnualReportsList-cover {
    height:  9.67741935483871vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-cover {
    height:  25vw;
  }
}
.CompanyAnnualReportsList-cover img {
  color: rgba(0, 0, 0, 0.25);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  height: 100%;
}
@media (min-width: 1920px) {
  .CompanyAnnualReportsList-cover img {
    -webkit-box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
            box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyAnnualReportsList-cover img {
    -webkit-box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
            box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyAnnualReportsList-cover img {
    -webkit-box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
            box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-cover img {
    -webkit-box-shadow:  0vw 3.125vw 6.25vw 0vw;
            box-shadow:  0vw 3.125vw 6.25vw 0vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-cover img {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
.CompanyAnnualReportsList-coverPlaceholder {
  color: rgba(0, 0, 0, 0.25);
  will-change: transform;
  -webkit-transition: 0.15s -webkit-transform;
  transition: 0.15s -webkit-transform;
  transition: 0.15s transform;
  transition: 0.15s transform, 0.15s -webkit-transform;
  background-color: #D8D8D8;
}
@media (min-width: 1920px) {
  .CompanyAnnualReportsList-coverPlaceholder {
    width:  120px;
    height:  160px;
    -webkit-box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
            box-shadow:  0 13.333333333333332px 26.666666666666664px 0;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyAnnualReportsList-coverPlaceholder {
    width:  6.25vw;
    height:  8.333333333333334vw;
    -webkit-box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
            box-shadow:  0vw 0.6944444444444444vw 1.3888888888888888vw 0vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyAnnualReportsList-coverPlaceholder {
    width:  7.258064516129032vw;
    height:  9.67741935483871vw;
    -webkit-box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
            box-shadow:  0vw 0.8064516129032258vw 1.6129032258064515vw 0vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-coverPlaceholder {
    height:  25vw;
    width:  18.4375vw;
  }
}
.CompanyAnnualReportsList-textWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 79%;
          flex: 0 0 79%;
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-textWrapper {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 72%;
            flex: 0 0 72%;
  }
}
.CompanyAnnualReportsList-titleWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1920px) {
  .CompanyAnnualReportsList-title {
    max-width:  533.3333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyAnnualReportsList-title {
    max-width:  27.77777777777778vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyAnnualReportsList-title {
    max-width:  32.25806451612903vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-title {
    padding-right:  6.25vw;
    font-size:  4.375vw;
  }
}
.CompanyAnnualReportsList-years {
  color: #A6A8B3;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-yearsFrom,
  .CompanyAnnualReportsList-yearsTo {
    font-size: 4.375vw;
  }
}
.CompanyAnnualReportsList-author {
  color: #6F7380;
  -webkit-transition: 0.15s color;
  transition: 0.15s color;
}
@media (min-width: 1920px) {
  .CompanyAnnualReportsList-author {
    margin-top:  26.666666666666664px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .CompanyAnnualReportsList-author {
    margin-top:  1.3888888888888888vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .CompanyAnnualReportsList-author {
    margin-top:  1.6129032258064515vw;
  }
}
@media (max-width: 759px) {
  .CompanyAnnualReportsList-author {
    font-size:  2.8125vw;
    line-height:  4.6875vw;
  }
}
