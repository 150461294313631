.ProductDetailsDescription {
  background-color: #FFFFFF;
  width: 50%;
  position: relative;
}
@media (max-width: 759px) {
  .ProductDetailsDescription {
    width: 100%;
    height: auto;
    padding-bottom: 0;
    margin-top: 12.5vw;
    min-height: unset;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription {
    padding-bottom: 6.4516129vw;
    min-height: 40.32258065vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription {
    padding-bottom: 5.55555556vw;
    min-height: 34.72222222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription {
    padding-bottom: 106.66666667px;
    min-height: 666.66666667px;
  }
}
.ProductDetailsDescription-head {
  margin: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-head {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    min-height: unset;
    display: block;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-head {
    min-height: 60.48387097vw;
    height: calc(100vh - 3.22580645vw);
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-head {
    min-height: 52.08333333vw;
    height: calc(100vh - 2.77777778vw);
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-head {
    min-height: 1000px;
    height: calc(100vh - 53.33333333px);
  }
}
.ProductDetailsDescription-headTitle {
  text-align: center;
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-headTitle {
    padding:  0 53.33333333333333px;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-headTitle {
    padding:  0vw 2.7777777777777777vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-headTitle {
    padding:  0vw 3.225806451612903vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headTitle {
    padding-top:  7.5vw;
    font-size:  5.625vw;
  }
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headTitle {
    width: 100%;
  }
}
.ProductDetailsDescription-headModel {
  letter-spacing: normal;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headModel {
    width: 100%;
    text-align: center;
    padding-bottom: 6.25vw;
    margin-top: 0.9375vw;
    color: #6F7380;
    font-size: 2.8125vw;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-headModel {
    margin-top: 1.85483871vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-headModel {
    margin-top: 1.59722222vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-headModel {
    margin-top: 30.66666667px;
  }
}
.ProductDetailsDescription-headDescr {
  letter-spacing: normal;
  text-transform: none;
  color: #6F7380;
  line-height: 1.42857143;
  text-align: center;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headDescr {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-headDescr {
    margin-top: 1.61290323vw;
    width: 32.25806452vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-headDescr {
    margin-top: 1.38888889vw;
    width: 27.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-headDescr {
    margin-top: 26.66666667px;
    width: 533.33333333px;
  }
}
.ProductDetailsDescription-headCardWrapper {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headCardWrapper {
    display: block;
    width: 100%;
  }
}
.ProductDetailsDescription-headFlexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  width: auto;
  height: auto;
  min-height: 0;
}
.ProductDetailsDescription-anchors:after {
  content: '';
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-anchors {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-anchors {
    width: 21.77419355vw;
    margin-top: 4.83870968vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-anchors {
    width: 18.75vw;
    margin-top: 4.16666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-anchors {
    width: 360px;
    margin-top: 80px;
  }
}
.ProductDetailsDescription-anchor {
  border-bottom: 1px solid #111112;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  -webkit-transition: 0.15s opacity;
  transition: 0.15s opacity;
}
.isDesktop .ProductDetailsDescription-anchor:hover .ProductDetailsDescription-anchorText,
.isNotDesktop .ProductDetailsDescription-anchor:active .ProductDetailsDescription-anchorText {
  text-decoration: underline;
}
.ProductDetailsDescription-anchor:last-of-type {
  border-bottom: none;
}
.ProductDetailsDescription-anchor:visited .ProductDetailsDescription-anchorText {
  color: #111112;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-anchor {
    height: 5vw;
    padding: 1.93548387vw 3.22580645vw 1.93548387vw 3.38709677vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-anchor {
    height: 4.30555556vw;
    padding: 1.66666667vw 2.77777778vw 1.66666667vw 2.91666667vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-anchor {
    height: 82.66666667px;
    padding: 32px 53.33333333px 32px 56px;
  }
}
.ProductDetailsDescription-anchorIcon {
  position: relative;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-anchorIcon {
    width: 1.61290323vw;
    height: 1.61290323vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-anchorIcon {
    width: 1.38888889vw;
    height: 1.38888889vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-anchorIcon {
    width: 26.66666667px;
    height: 26.66666667px;
  }
}
.ProductDetailsDescription-anchorIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ProductDetailsDescription-anchorText {
  letter-spacing: normal;
  color: #111112;
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-anchorText {
    margin-left: 2.41935484vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-anchorText {
    margin-left: 2.08333333vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-anchorText {
    margin-left: 40px;
  }
}
.ProductDetailsDescription-headButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: absolute;
  text-decoration: none;
  left: 0;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headButtons {
    display: block;
    position: relative;
    margin-top: 12.5vw;
    padding: 0 6.25vw;
    bottom: 0;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-headButtons {
    padding: 0 9.67741935vw;
    bottom: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-headButtons {
    padding: 0 8.33333333vw;
    bottom: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-headButtons {
    padding: 0 160px;
    bottom: 53.33333333px;
  }
}
.ProductDetailsDescription-headButton {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-headButton {
    margin-bottom: 6.25vw;
  }
  .ProductDetailsDescription-headButton:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 759px) {
  .ProductDetailsDescription-flexSpacer {
    display: none;
  }
}
@media (min-width: 760px) and (max-width: 1239px) {
  .ProductDetailsDescription-flexSpacer {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    width: auto;
    height: auto;
    min-height: 3.22580645vw;
  }
}
@media (min-width: 1240px) and (max-width: 1919px) {
  .ProductDetailsDescription-flexSpacer {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    width: auto;
    height: auto;
    min-height: 2.77777778vw;
  }
}
@media (min-width: 1920px) {
  .ProductDetailsDescription-flexSpacer {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    width: auto;
    height: auto;
    min-height: 53.33333333px;
  }
}
@media (max-width: 759px) {
  .ProductDetailsDescription-specifications,
  .ProductDetailsDescription-downloads,
  .ProductDetailsDescription-inspiration,
  .ProductDetailsDescription-photometry,
  .ProductDetailsDescription-quickcalc {
    display: none;
  }
  .ProductDetailsDescription-specifications.active,
  .ProductDetailsDescription-downloads.active,
  .ProductDetailsDescription-inspiration.active,
  .ProductDetailsDescription-photometry.active,
  .ProductDetailsDescription-quickcalc.active {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 28;
    background-color: #FFFFFF;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.ProductDetailsDescription-quickcalc {
  display: none;
}
.ProductDetailsDescription-mobileAnchors {
  display: none;
}
@media (max-width: 759px) {
  .ProductDetailsDescription-mobileAnchors {
    display: block;
    padding: 6.25vw;
    background-color: #F6F6F6;
    margin-top: 25vw;
  }
}
.ProductDetailsDescription-mobileAnchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  position: relative;
  padding: 6.25vw 0 6.25vw;
  border-bottom: 1px solid #111112;
}
.ProductDetailsDescription-mobileAnchor:last-of-type {
  border-bottom: none;
}
.ProductDetailsDescription-mobileAnchorIcon {
  width: 6.25vw;
  height: 6.25vw;
  position: relative;
}
.ProductDetailsDescription-mobileAnchorIcon svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ProductDetailsDescription-mobileAnchorArrow {
  width: 5.9375vw;
  height: 3.75vw;
  position: absolute;
  right: 0;
}
.ProductDetailsDescription-mobileAnchorArrow svg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ProductDetailsDescription-mobileAnchorTitle {
  margin-left: 6.25vw;
  line-height: normal;
}
